import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export interface SectionOrdinal {
    id: string;
    ordinal: number;
}

export class UpdateSectionOrdinalsRequest {
    public sectionOrdinals: SectionOrdinal[] = [];

    constructor() {}
}

export class SectionConcurrenyResponse {
    public id: string = "";
    public concurrencyToken: string = "";

    constructor() {}
}

export class UpdateSectionOrdinalsEndpoint extends Endpoint<UpdateSectionOrdinalsRequest, SectionConcurrenyResponse[]> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportTemplate.UpsertSectionOrdinals);
    }

    public async HandleRequestAsync(model: SectionOrdinal[]): Promise<any> {
        let request = new UpdateSectionOrdinalsRequest();
        request.sectionOrdinals = model;
        return request;
    }

    public async HandleResponseAsync(response: SectionConcurrenyResponse[]): Promise<SectionConcurrenyResponse[]> {
        Logger.logInformation("Response", response.length);
        return response;
    }
}
