//editselect__menu-list

import styled from "@emotion/styled";
import { Box } from "Application";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";

export const AddDocumentWrapper = styled(ModalGenericWithHeader)`
    .editselect__menu-list {
        z-index: 9000;
    }
    &&.scrollableContent-99 {
        overflow-y: hidden !important;
    }

    &&.ms-Modal-scrollableContent {
        overflow-y: hidden !important;
    }
`;
