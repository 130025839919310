import { AppUrls } from "AppUrls";
import { Endpoint, Http } from "Application";
import { CustomerListItemModel } from "../List/CustomerListItemModel";
import { CustomersListViewModel } from "../List/CustomersListViewModel";
import { DropdownItem } from "Models/DropdownItem";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";

class Request {
    public orderBy: string = "";
    public columnName: string = "";
    public pageSize: number = 0;
    public pageNumber: number = 1;
    public filterByType: number[] = [];
    public filterByBdm: number[] = [];
    public filterByTsm: number[] = [];
    public filterByStatus: number[] = [];
    public searchString: string = "";
    public initialLoad: boolean = true;
    public searchPostcodeOnly: boolean = false;
}

export class CustomerListAndRelatedResponse {
    customerList: CustomerListItemModel[] = [];
    customerCount: number = 0;
    customerTypesList: CustomerTypeDropdownItem[] = [];
    tsmList: DropdownItem[] = [];
    bdmList: DropdownItem[] = [];
}

export class GetCustomerListAndRelatedEndpoint extends Endpoint<Request, CustomerListAndRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.GetCustomerListAndRelated);
    }

    public async HandleRequestAsync(viewModel: CustomersListViewModel): Promise<any> {
        let request = new Request();
        request.columnName = viewModel.columnName;
        request.filterByBdm = [];
        request.filterByTsm = [];
        request.filterByType = [];
        request.filterByStatus = [];
        request.orderBy = viewModel.orderBy;
        request.pageSize = viewModel.pageSize;
        request.pageNumber = viewModel.pageNumber;

        // request.userRolesForFiltering = this.viewModel.userRolesForFiltering;

        request.filterByType = viewModel.filterByType.map((item) => {
            return item;
        });

        request.filterByBdm = viewModel.filterByBdm.map((item: string) => {
            return parseInt(item);
        });

        request.filterByTsm = viewModel.filterByTsm.map((item: string) => {
            return parseInt(item);
        });

        request.filterByStatus = viewModel.filterByStatus.map((item: string) => {
            return parseInt(item);
        });

        request.searchString = viewModel.searchString;
        request.searchPostcodeOnly = viewModel.searchPostCodeOnly;

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: CustomerListAndRelatedResponse): Promise<CustomerListAndRelatedResponse> {
        return response;
    }
}
