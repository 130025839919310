import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { CustomerDetails } from "../ViewCustomer/Details/DetailsModel";

export class GetCustomerDetailsByIdEndpoint extends EndpointWithoutRequest<CustomerDetails> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(`/api/customer/getcustomerdetailsbyid/${id}`);
    }

    public async HandleResponseAsync(response: CustomerDetails): Promise<CustomerDetails> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
