import { makeObservable, action, runInAction } from "mobx";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { GetComplaintRelatedForMasterProjectEndpoint } from "../Endpoints/GetComplaintRelatedForMasterProject";
import { IComplaintRelatedResponse } from "Models/Complaints/IComplaintRelatedResponse";
import { AddEditBaseViewModel } from "./AddEditBaseViewModel";
import { ComplaintModel } from "Models/Complaints/ComplaintModel";
import { getBlankComplaintWithRelatedResponse, IComplaintWithRelatedResponse } from "Models/Complaints/ComplaintWithRelatedResponse";
import { MasterProjectComplaintGetWithRelatedEndpoint } from "../Endpoints/MasterProjectComplaintGetWithRelated";
import { MasterProjectComplaintUpsertEndpoint } from "../Endpoints/MasterProjectComplaintUpsert";

export class MasterProjectComplaintAddEditViewModel extends AddEditBaseViewModel {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super();

        makeObservable(this, {
            getComplaint: action,
            getComplaintRelatedForProject: action,
            upsertAsync: action,
        });
    }

    public upsertAsync = async (projectId: string): Promise<any> => {
        let retVal: ComplaintModel = this.model;

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: MasterProjectComplaintUpsertEndpoint = new MasterProjectComplaintUpsertEndpoint();
                this.isProcessing = true;
                this.setValue("projectId", projectId);
                let _ = await this.apiClient.sendAsync(endpoint, this.model);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: ComplaintModel = this.apiClient.Response();
                        this.model.fromResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Complaint");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to upsert complaint.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };

    public getComplaint = async (complaintId: string): Promise<IComplaintWithRelatedResponse> => {
        let retVal: IComplaintWithRelatedResponse = getBlankComplaintWithRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: MasterProjectComplaintGetWithRelatedEndpoint = new MasterProjectComplaintGetWithRelatedEndpoint(complaintId);
                this.isProcessing = true;

                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: IComplaintWithRelatedResponse = this.apiClient.Response();
                        this.processComplaintWithRelatedResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Complaint");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to get complaint and related data.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };

    public getComplaintRelatedForProject = async (projectId: string): Promise<any> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: GetComplaintRelatedForMasterProjectEndpoint = new GetComplaintRelatedForMasterProjectEndpoint(projectId);
                this.isProcessing = true;

                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: IComplaintRelatedResponse = this.apiClient.Response();
                        this.processRelatedResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Complaint");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to get complaint related data.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return Promise.resolve();
    };
}
