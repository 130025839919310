import { action, makeObservable, observable, IObservableArray, computed } from "mobx";
import { SectionRow } from "./SectionRow";
import { ConditionReportSectionQuestion } from "./ConditionReportSectionQuestion";

export class ConditionReportSection {
    public id: number | null = null;
    public pageId: number | null = null;
    public type: string = "";
    public label: string = "";
    public name: string = "";
    public questions: IObservableArray<ConditionReportSectionQuestion> = observable([]);
    public rows: IObservableArray<SectionRow> = observable([]);

    constructor() {
        makeObservable(this, {
            id: observable,
            pageId: observable,
            type: observable,
            label: observable,
            name: observable,
            clear: action,
            load: action,
            hasQuestionsOrRows: computed,
        });
    }

    public get hasQuestionsOrRows(): boolean {
        let retVal: boolean = false;

        for (const element of this.questions.slice()) {
            if (element.hasAnswersOrImages === true) {
                retVal = true;
                break;
            }
        }

        if (retVal === false) {
            // then also check the rows
            for (const element of this.rows.slice()) {
                if (element.hasPages === true) {
                    retVal = true;
                    break;
                }
            }
        }

        return retVal;
    }

    public load(section: ConditionReportSection) {
        this.clear();

        if (section !== undefined) {
            this.id = section.id;
            this.pageId = section.pageId;
            this.type = section.type;
            this.label = section.label;
            this.name = section.name;

            for (const element of section.questions) {
                let newQ: ConditionReportSectionQuestion = new ConditionReportSectionQuestion();
                newQ.load(element);
                this.questions.push(newQ);
            }

            for (const element of section.rows) {
                let newRow: SectionRow = new SectionRow();
                newRow.load(element);
                this.rows.push(newRow);
            }
        }
    }

    public clear = () => {
        this.id = null;
        this.pageId = null;
        this.type = "";
        this.label = "";
        this.name = "";
        this.questions.clear();
        this.rows.clear();
    };
}
