import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class PropertyLinkedProjectQuotesModel extends ModelBase<PropertyLinkedProjectQuotesModel> {
    public propertyId: Guid = "";
    constructor() {
        super();
        makeObservable(this, { propertyId: observable });
    }
}

export class PropertyLinkedProjectQuotesModelValidator extends Validator<PropertyLinkedProjectQuotesModel> {
    constructor() {
        super();
    }
}
