import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { ConditionReportTemplateAddEditViewModel } from "../ConditionReportTemplateAddEditViewModel";
import { ConditionReportTemplateSection } from "../ConditionReportTemplateSection";
import { IDraggableItem, Section } from "../Section";
import update from "immutability-helper";
import { EditConditionTemplate, EditConditionTemplateEditor, EditConditionTemplateSections, EditConditionTemplateWrapper } from "../ConditionReportTemplateAddEdit.style";
import { H1, H2, P } from "Components/Primitives/TextElements/TextElements";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TemplateSectionView } from "../TemplateSection";
import { ThemedLoader } from "Components";

export interface IRepeatableSectionProps {
    topLevelSectionId: string;
    parentViewModel: ConditionReportTemplateAddEditViewModel;

    onUpdate: () => void;
}

export const RepeatableSectionView: React.FC<IRepeatableSectionProps> = observer(({ topLevelSectionId, parentViewModel, onUpdate }) => {
    const [repeatableSections, setRepeatableSections] = useState<ConditionReportTemplateSection[]>([]);

    const [topLevelSection, setTopLevelSection] = useState<IDraggableItem[]>([]);
    const [repeatedsections, setRepeatedSections] = useState<IDraggableItem[]>([]);

    useEffect(() => {
        reloadSections();
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    const reloadSections = () => {
        const sections: ConditionReportTemplateSection[] = parentViewModel.getRepeatableSections(topLevelSectionId);
        setRepeatableSections(sections);

        let nonDraggableItems: IDraggableItem[] = [];
        let draggableItems: IDraggableItem[] = [];
        for (const element of sections) {
            if (element.hasRepeatableElements === true) {
                nonDraggableItems.push({ id: element.id!, text: element.name, isRepeatable: element.isRepeatable, hasRepeatableElements: element.hasRepeatableElements });
            } else if (element.isRepeatable) {
                draggableItems.push({ id: element.id!, text: element.name, isRepeatable: element.isRepeatable, hasRepeatableElements: element.hasRepeatableElements });
            }
        }
        if (nonDraggableItems.length > 0) {
            setTopLevelSection([nonDraggableItems[0]]);
        }

        setRepeatedSections(draggableItems);
    };

    parentViewModel.setRepeatedSectionBackup(repeatedsections);

    const moveSection = useCallback((dragIndex: number, hoverIndex: number, itemId: string) => {
        parentViewModel.setRepeatedSelectedSectionId(itemId);

        const temp = (prevCards: IDraggableItem[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            });

        setRepeatedSections(temp);
        parentViewModel.repeatedSectionsReordered();
    }, []);

    const onSectionClick = (id: string) => {
        parentViewModel.setRepeatedSelectedSectionId(id);
    };

    const onRemoveSection = (id: string) => {
        // Nothing to do in the repeatable section.
    };

    const renderSection = useCallback((card: IDraggableItem, index: number) => {
        return (
            <Section
                key={card.id}
                index={index}
                id={card.id}
                text={card.text}
                hasRepeatableElmements={card.hasRepeatableElements}
                moveCard={moveSection}
                onClickHandler={onSectionClick}
                onRemoveSection={onRemoveSection}
                additionalClasses={card.id === parentViewModel.selectedRepeatedSectionId ? "selected" : ""}
                showDelete={false}
            />
        );
    }, []);

    return (
        <>
            {parentViewModel.isProcessing === true ? (
                <ThemedLoader spinnerText="Upserting...." isOpen={true} />
            ) : (
                <EditConditionTemplateWrapper>
                    <H1>Repeated Template Setup</H1>
                    <p>Repeated sections have a single constant section at the top for general explanation, then the sections that are repeated per page from the json file.</p>
                    <EditConditionTemplate>
                        <EditConditionTemplateSections>
                            <H2 style={{ marginTop: "10px" }}>Introduction Section</H2>
                            <div style={{ padding: "10px 0", borderBottom: "1px solid #efefef", marginBottom: "10px" }}>
                                <DndProvider backend={HTML5Backend}>
                                    <div>
                                        {topLevelSection.slice().map((section, index) =>
                                            renderSection(
                                                {
                                                    id: section.id,
                                                    text: section.text,
                                                    isRepeatable: section.isRepeatable,
                                                    hasRepeatableElements: section.hasRepeatableElements,
                                                },
                                                index,
                                            ),
                                        )}
                                    </div>
                                </DndProvider>
                            </div>
                            <H2>Repeated Sections</H2>
                            <P>Click to edit a section, or drag the sections around to reorder.</P>
                            <div style={{ padding: "10px 0" }}>
                                <DndProvider backend={HTML5Backend}>
                                    <div>
                                        {repeatedsections.slice().map((section, index) =>
                                            renderSection(
                                                {
                                                    id: section.id,
                                                    text: section.text,
                                                    isRepeatable: section.isRepeatable,
                                                    hasRepeatableElements: section.hasRepeatableElements,
                                                },
                                                index,
                                            ),
                                        )}
                                    </div>
                                </DndProvider>
                            </div>
                        </EditConditionTemplateSections>

                        {repeatableSections.length === 0 && <EditConditionTemplateEditor></EditConditionTemplateEditor>}
                        {repeatableSections.length > 0 && (
                            <EditConditionTemplateEditor>
                                {/*                                 <p>selectedRepeatedSectionId? {parentViewModel.selectedRepeatedSectionId}</p>
                                <p>Processing? {parentViewModel.isProcessing.toString()}</p> */}
                                {parentViewModel.selectedRepeatedSectionId.length > 0 && parentViewModel.isProcessing === false && (
                                    <div>
                                        <TemplateSectionView
                                            section={parentViewModel.getRepeatedSelectedSection}
                                            isSubSection={false}
                                            placeHolderOptions={parentViewModel.getQuestionOptions()}
                                            repeatedSectionOptions={parentViewModel.getQuestionOptions()}
                                            setSectionDirty={parentViewModel.setRepeatableSectionDirty}
                                            onUpdate={onUpdate}
                                            templateCss={parentViewModel.getCss}
                                        />
                                    </div>
                                )}
                            </EditConditionTemplateEditor>
                        )}
                    </EditConditionTemplate>
                </EditConditionTemplateWrapper>
            )}
        </>
    );
});
