import styled from "@emotion/styled";
import { theme } from "Application";

//region styled components
export const CalloutInnerContainer = styled.div`
    width: 656px;
    box-shadow: 0px 0px 6px #00000029;
`;

export const CalloutHeader = styled.div`
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 5px;
    align-items: center;
    padding: 0px 30px 0px 10px;
    background-color: ${theme.palette.common.tertiary};
    height: 50px;

    position: relative;
`;

export const CalloutBody = styled.div`
    padding: 0px 10px;
`;

export const CalloutSubHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    row-gap: 10px;
    border-bottom: 1px dashed ${theme.palette.field.main};
    padding: 10px 0px;
    align-items: center;
`;

export const CalloutSubHeaderLabelGroup = styled.div`
    display: flex;
    align-items: center;
    & > :first-child {
        margin-right: 10px;
    }
`;

export const CalloutMainContent = styled.div`
    padding: 10px 0px;
    border-bottom: 1px solid ${theme.palette.field.main};

    & > :first-of-type {
        border-bottom: 1px dashed ${theme.palette.field.main};
    }
`;

export const CalloutMainContentRow = styled.div`
    padding-bottom: 50px;
`;

export const LinkedProjectItemGroup = styled.div`
    display: flex;
    align-items: center;
    & > :first-of-type {
        margin-right: 10px;
    }
`;

export const CustomerNoteItemGroup = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const CustomerNoteAuditContainer = styled.div`
    display: flex;
    align-items: center;
    & > :first-of-type {
        margin-right: 10px;
    }
`;

export const CreatedDateLabelGroup = styled.div`
    display: flex;
    align-items: center;
    & > :first-of-type {
        margin-right: 5px;
    }
    & > :nth-of-type(2) {
        margin-right: 5px;
    }
`;

export const CalloutFooter = styled.div`
    padding: 5px 10px;
`;

export const TransparentButton = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
`;
//end region styled components
