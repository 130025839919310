import { ModelBase } from "@shoothill/core";
import { action, makeObservable, observable, IObservableArray, computed } from "mobx";
import { Validator } from "Application/Validation";
import { ConditionReportListInformation } from "./ConditionReportListInformation";
import { ConditionReportDevice } from "./ConditionReportDevice";
import { ConditionReportUser } from "./ConditionReportUser";
import { ConditionReportAddressDetails } from "./ConditionReportAddressDetails";
import { ConditionReportForm } from "./ConditionReportForm";
import { ConditionReportPage } from "./ConditionReportPage";

export class ConditionReportModel extends ModelBase<ConditionReportModel> {
    public id: string | null = null;
    public createdBy: string | null = null;
    public createdByDisplayName: string = "";
    public jsonFileURL: string = "";
    public pdfURL: string = "";
    public dataState: string = "";
    public dispatcher: string = "";
    public name: string = "";
    public orphanedDispatch: boolean = false;
    public referenceNumber: string = "";
    public deviceSubmitDate: string = "";
    public serverReceiveDate: string = "";
    public state: string = "";
    public version: string = "";
    public zone: string = "";
    public listInformation: ConditionReportListInformation | undefined = undefined;
    public device: ConditionReportDevice | undefined = undefined;
    public user: ConditionReportUser | undefined = undefined;
    public address: ConditionReportAddressDetails | undefined = undefined;
    public form: ConditionReportForm | undefined = undefined;
    public pages: IObservableArray<ConditionReportPage> = observable([]);

    public pageCount: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            createdBy: observable,
            createdByDisplayName: observable,
            jsonFileURL: observable,
            pdfURL: observable,
            dataState: observable,
            dispatcher: observable,
            name: observable,
            orphanedDispatch: observable,
            referenceNumber: observable,
            deviceSubmitDate: observable,
            serverReceiveDate: observable,
            pageCount: observable,
            state: observable,
            version: observable,
            zone: observable,

            clear: action,
            load: action,

            getPageCount: computed,
            getPages: computed,
            getViewPages: computed,
        });
    }

    public load(response: ConditionReportModel) {
        this.clear();

        if (response !== undefined && response !== null) {
            this.id = response.id;
            this.createdBy = response.createdBy;
            this.createdByDisplayName = response.createdByDisplayName;
            this.jsonFileURL = response.jsonFileURL;
            this.pdfURL = response.pdfURL;
            this.dataState = response.dataState;
            this.dispatcher = response.dispatcher;
            this.name = response.name;
            this.orphanedDispatch = response.orphanedDispatch;
            this.referenceNumber = response.referenceNumber;
            this.deviceSubmitDate = response.deviceSubmitDate;
            this.serverReceiveDate = response.serverReceiveDate;
            this.state = response.state;
            this.version = response.version;
            this.zone = response.zone;
            this.pageCount = response.pages.length;

            this.listInformation = new ConditionReportListInformation();
            this.listInformation.load(response.listInformation);

            this.device = new ConditionReportDevice();
            this.device.load(response.device);

            this.user = new ConditionReportUser();
            this.user.load(response.user);

            this.address = new ConditionReportAddressDetails();
            this.address.load(response.address);

            this.form = new ConditionReportForm();
            this.form.load(response.form);

            for (const element of response.pages) {
                let page: ConditionReportPage = new ConditionReportPage();
                page.load(element);
                this.pages.push(page);
            }
        }
    }

    public get getPages() {
        return this.pages.slice();
    }

    public get getViewPages() {
        return this.pages.slice().filter((a) => a.hasSections === true);
    }

    public get getPageCount() {
        return this.pageCount;
    }

    public setPageCount(value: number) {
        this.pageCount = value;
    }

    public clear() {
        this.id = null;
        this.createdBy = null;
        this.createdByDisplayName = "";
        this.jsonFileURL = "";
        this.pdfURL = "";
        this.dataState = "";
        this.dispatcher = "";
        this.name = "";
        this.orphanedDispatch = false;
        this.referenceNumber = "";
        this.deviceSubmitDate = "";
        this.serverReceiveDate = "";
        this.state = "";
        this.version = "";
        this.zone = "";
        this.pageCount = 0;
        this.pages.clear();
    }
}

export class ConditionReportModelValidator extends Validator<ConditionReportModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
