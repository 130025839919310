import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import LoadingSpinnerSVG from "Assets/Spinners/LoadingSpinner.svg";
import { P } from "./Primitives/TextElements/TextElements";

interface ILoadingSpinner {
    heightOfSpinner?: any;
    message?: string;
}

export const LoadingSpinner: React.FC<ILoadingSpinner> = observer(({ heightOfSpinner, message }) => {
    return (
        <Box flexBox justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
            <img alt="loading spinner" aria-hidden src={LoadingSpinnerSVG} height={heightOfSpinner ?? "40px"}></img>
            {message && <P>{message}</P>}
        </Box>
    );
});
