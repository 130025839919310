import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { ComplaintDocumentsGetByComplaintIdRequest } from "./MasterProjectComplaintDocumentsGetByComplaintId";

export class ProjectQuoteComplaintDocumentsGetByComplaintIdEndpoint extends Endpoint<ComplaintDocumentsGetByComplaintIdRequest, IDocumentUploaded[]> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.ProjectQuoteComplaintDocumentsGetByComplaintId);
    }

    public async HandleRequestAsync(request: ComplaintDocumentsGetByComplaintIdRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: IDocumentUploaded[]): Promise<IDocumentUploaded[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
