export interface File64 {
    base64StringFile: string;
    fileName: string;
    fileType: string;
}

export interface ISharmansUploadFile {
    file: File;
    base64String: string;
}

export const getFileTo64 = (file: File): Promise<File64> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onerror = () => {
            reader.abort();
            reject(new Error("Error parsing file"));
        };
        reader.onloadend = function () {
            // use a regex to remove data url part
            const base64String: string = reader.result!.toString(); //.replace("data:", "").replace(/^.+,/, "");

            //Resolve the promise with your custom file structure
            let retVal: File64 = {
                base64StringFile: base64String,
                fileName: file.name,
                fileType: file.type,
            };

            resolve(retVal);
        };
        reader.readAsDataURL(file);
    });
};

export const getFilenameFromContentDisposition = (contentDisposition: string): string => {
    let retVal: string = "";

    contentDisposition = contentDisposition.replace("filename*=", "filename=");
    const filenameList = contentDisposition.split("filename=");
    retVal = filenameList[1].replaceAll('"', "").replaceAll(";", "").replace("/", "_");

    return retVal.trim();
};

// Use this to check that documents can be previewed
const acceptedFilesForPreview = ["bmp", "docx", "csv", "jpg", "jpeg", "png", "pdf", "xlsx", "xls", "odt"];

export const checkIsAcceptedFile = (file: any): boolean => {
    if (file.name) {
        const fileExtension = file.name.split(".").pop()?.toLowerCase(); // Extract file extension
        if (fileExtension) {
            return acceptedFilesForPreview.includes(fileExtension); // Check if the file extension is in the accepted list
        }
    }
    return false;
};

export const checkIsAcceptedFileByFileName = (file: any): boolean => {
    if (file) {
        const fileExtension = file.split(".").pop()?.toLowerCase(); // Extract file extension
        if (fileExtension) {
            return acceptedFilesForPreview.includes(fileExtension); // Check if the file extension is in the accepted list
        }
    }
    return false;
};
