import { InitialState } from "../Models";
import { makeObservable, observable } from "mobx";
import { isJWTValid, ViewModelBase } from "@shoothill/core";
import { APIClient } from "../Helpers/APIClient";
import { InitStateEndpoint } from "./InitialStateEndpoint";
import { AccountStore } from "Stores/Domain";
import { container } from "tsyringe";
export class InitViewModel extends ViewModelBase<any> {
    public apiClient = new APIClient();
    public DataReady: boolean = false;
    public InitData: InitialState = {} as InitialState;

    constructor() {
        super({});
        makeObservable(this, {
            DataReady: observable,
        });
    }

    public getInitialState = async (): Promise<void> => {
        if (!(await isJWTValid())) {
            this.logout();
        }
        const endPoint = new InitStateEndpoint(this);
        await this.apiClient.sendAsync(endPoint);
    };

    public logout = (): void => {
        const accountStore = container.resolve(AccountStore);
        const currentPath = window.location.pathname;

        if (currentPath.includes("confirmpassword")) {
            accountStore.setRedirectToLogin(false);
        }

        accountStore.Logout();
    };
}
