import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "Application";
import tick from "Assets/Icons/SmallTickCircleIcon.svg";
import DocumentIcon from "Assets/Icons/DocumentIcon.svg";
import { IDocumentType } from "Models/Documents/DocumentType";
import { IAddDocumentModalViewModel } from "Models/Documents/IAddDocumentModalViewModel";
import { AddDocumentWrapper } from "./AddDocumentModal.style";
import { DocumentUploader } from "Components/DocumentUploader/DocumentUploader";

interface IAddDocumentModal {
    parentViewModel: IAddDocumentModalViewModel;
    documentTypes: IDocumentType[];
}

export const AddDocumentModal: React.FC<IAddDocumentModal> = observer((props) => {
    useEffect(() => {
        if (props.documentTypes !== undefined) {
            props.parentViewModel.documentTypes = props.documentTypes;
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    return (
        <AddDocumentWrapper
            isOpen={props.parentViewModel.showAddDocumentModal}
            headerText="Add document"
            headerIcon={DocumentIcon}
            stickyBar={
                <>
                    <Button
                        displayName=" Add Document"
                        paletteColor="ButtonGreen"
                        command={props.parentViewModel.submitDocuments}
                        icon={<img src={tick} />}
                        mr="10px"
                        boxShadow={true}
                    />
                    <Button displayName=" Cancel" paletteColor="ButtonCancel" command={props.parentViewModel.closeNewDocumentModal} icon={<img src={tick} />} boxShadow={true} />
                </>
            }
            closeModalCommand={props.parentViewModel.closeNewDocumentModal}
        >
            <DocumentUploader parentViewModel={props.parentViewModel} documentTypes={props.documentTypes} />
        </AddDocumentWrapper>
    );
});
