import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import _get from "lodash/get";

export const arraysEqual = (a: any[], b: any[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

export type SortValueResolver<TItem = any, TValue = any> = (item: TItem, key: string) => TValue;
export const defaultValueResolver: SortValueResolver = (item, key) => _get(item, key);

export const createSorter = <TArray, TValue = any>(sortBy: string, sortDirection: SortOrderDirection, valueResolver: SortValueResolver<TArray, TValue> = defaultValueResolver) => {
    return (a: TArray, b: TArray) => {
        let valueA: TValue | string = valueResolver(a, sortBy);
        let valueB: TValue | string = valueResolver(b, sortBy);

        if (valueA !== null && typeof valueA === "string") {
            valueA = valueA.toLocaleLowerCase();
        }

        if (valueB !== null && typeof valueB === "string") {
            valueB = valueB.toLocaleLowerCase();
        }

        let sortValue = 0;

        if (valueA < valueB || (valueA === null && valueB !== null)) {
            sortValue = -1;
        } else if (valueA > valueB || (valueA !== null && valueB === null)) {
            sortValue = 1;
        }

        if (sortValue != 0 && sortDirection === SortOrderDirection.DESC) {
            sortValue *= -1;
        }

        return sortValue;
    };
};

export const sortBy = <TArray>(array: TArray[], sortBy: string, sortDirection: SortOrderDirection) => {
    const sorter = createSorter<TArray>(sortBy, sortDirection);
    const sortedArray = array.sort(sorter);

    return sortedArray;
};
