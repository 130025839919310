import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ComplaintModel } from "Models/Complaints/ComplaintModel";
import { AppUrls } from "AppUrls";

export class MasterProjectComplaintUpsertEndpoint extends Endpoint<ComplaintModel, ComplaintModel> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.MasterProjectComplaintUpsert);
    }

    public async HandleRequestAsync(request: ComplaintModel): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: ComplaintModel): Promise<ComplaintModel> {
        Logger.logInformation("Response", response);
        //Return your model
        return response;
    }
}
