import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ApiResult } from "@shoothill/core";
import { AppUrls } from "AppUrls";

class DeleteContactByIdRequest {
    id: Guid = "";
    customerId: Guid = "";
}

class DeleteContactByIdResponse {}

export class DeleteContactByIdEndpoint extends Endpoint<DeleteContactByIdRequest, ApiResult> {
    public id: Guid;
    public customerId: Guid;
    constructor(id: Guid, customerId: Guid) {
        super();
        this.id = id;
        this.customerId = customerId;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.DeleteContactById);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new DeleteContactByIdRequest();
        request.id = this.id;
        request.customerId = this.customerId;

        return request;
    }

    public async HandleResponseAsync(response: DeleteContactByIdResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
