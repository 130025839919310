import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { CondtionReportListItemModel } from "../CondtionReportListItemModel";
import { ISearchablePagedSortedTable } from "Models/TableInterfaces";

export class GetConditionReportsForListRequest implements ISearchablePagedSortedTable {
    public pageSize: number = 50;
    public pageNumber: number = 1;
    public searchString: string = "";
    public orderBy: string = "";
    public columnName: string = "";
    public editorsForFiltering: string[] = [];
    public constructor() {}
}

export interface ConditionReportListResponse {
    queryTotalCount: number;
    conditionReports: CondtionReportListItemModel[];
}

export class GetConditionReportsForListEndpoint extends Endpoint<GetConditionReportsForListRequest, ConditionReportListResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReport.GetAllForList);
    }

    public async HandleRequestAsync(request: GetConditionReportsForListRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: ConditionReportListResponse): Promise<ConditionReportListResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
