import { makeObservable, action, computed, observable, runInAction, IObservableArray } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { BlankModel } from "Application/Models/Domain/BlankModel";
import { DownloadNoParamaterReportEndpoint } from "./Endpoints/DownloadNoParamaterReportEndpoint";
import { GetUnmatchedSageRecordCountEndpoint, ReportListAndRelatedResponse } from "./Endpoints/GetUnmatchedSageRecordCountEndpoint";
import { SageNotFoundResetEndpoint } from "./Endpoints/SageNotFoundResetEndpoint";
import { AppUrls } from "AppUrls";
import { DownloadProjectMasterAndProjectQuotesReportEndpoint } from "./Endpoints/DownloadProjectMasterAndProjectQuotesReportEndpoint";
import { DownloadProjectDocumentCategoriesReportEndpoint } from "./Endpoints/DownloadProjectDocumentCategoriesReportEndpoint";
import { DownloadProjectSageContractorReportEndpoint } from "./Endpoints/DownloadProjectSageContractorReportEndpoint";
import { DownloadProjectCommunicationsReportEndpoint } from "./Endpoints/DownloadProjectCommunicationsReportEndpoint";
import { DownloadProjectComplaintsReportEndpoint } from "./Endpoints/DownloadProjectComplaintsReportEndpoint";
import { DownloadCustomerContactsReportEndpoint } from "./Endpoints/DownloadCustomerContactsReportEndpoint";
import { DownloadProjectAndCustomerReportEndpoint } from "./Endpoints/DownloadProjectAndCustomerReportEndpoint";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { DateTime } from "luxon";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { DownloadProjectJFFReportEndpoint, ReportJFFRequest } from "./Endpoints/DownloadProjectJFFReportEndpoint";
import { JFFCatagoryEnum, JFFCatagoryEnumHelper } from "Views/Work/Common/JFFCatagoryEnum";

export class ReportListViewModel extends ViewModelBase<BlankModel> {
    private static _instance: ReportListViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    public unmatchedSageCount: number = 0;
    public countdownTimer: number = 0;

    public filterByType: number[] = [];
    public customerTypesList: IObservableArray<CustomerTypeDropdownItem> = observable([]);

    public customerContactsSearchKeyword: string = "";
    public projectAndCustomerSearchKeyword: string = "";

    public tableStartDate: DateTime = DateTime.local().minus({ month: 1 }).startOf("day");
    public tableEndDate: DateTime = DateTime.local().endOf("day");

    /// JFF
    public jffStartDate: DateTime = DateTime.local().minus({ month: 1 }).startOf("day");
    public jffEndDate: DateTime = DateTime.local().endOf("day");
    public selectedCategory: JFFCatagoryEnum = JFFCatagoryEnum.Both;

    // Project Types
    public filterByProduct: string[] = [];
    public products: IObservableArray<KeyValuePairExtended> = observable([]);

    // BDM
    public bdms: IObservableArray<KeyValuePairExtended> = observable([]);
    public filterByBdm: string[] = [];

    //Task Type
    public filterTaskType: IObservableArray<KeyValuePairExtended> = observable([]);
    public filterByTasksType: string[] = [];

    private constructor() {
        super(new BlankModel());
        makeObservable(this, {
            countdownTimer: observable,
            isProcessing: observable,
            unmatchedSageCount: observable,
            filterByType: observable,
            customerContactsSearchKeyword: observable,

            projectAndCustomerSearchKeyword: observable,
            selectedCategory: observable,
            tableStartDate: observable,
            tableEndDate: observable,

            // JFF
            jffStartDate: observable,
            jffEndDate: observable,
            setSelectedCategory: action,
            getCategories: computed,

            // Project Types
            filterByProduct: observable,

            // BDM
            filterByBdm: observable,

            // Task Type
            filterByTasksType: observable,

            clear: action,
            downloadSageNotFoundCSV: action,
            recusiveCountDown: action,
            updateTypeFiltering: action,
            typeSelectAll: action,
            typeSelectNone: action,

            // Project Types
            updateProductFiltering: action,

            // BDM
            updateBdmFiltering: action,
            bdmSelectNone: action,
            bdmSelectAll: action,

            // Task Type
            updateTasksTypeFiltering: action,
            tasksTypeSelectAll: action,
            tasksTypeSelectNone: action,

            getCanResetTable: computed,
            getCountdownTimer: computed,
            getUnmatchedSageRecordCount: computed,
            getCustomerTypesForDropdown: computed,
            getTypeFilter: computed,

            // Project Types
            getProducts: computed,
            getProductFilter: computed,

            // BDM
            getBdmFilter: computed,

            // Task Type
            getTaskType: computed,
            getTasksFilter: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.unmatchedSageCount = 0;
        this.countdownTimer = 0;
    };

    public get getUnmatchedSageRecordCount(): string {
        return this.unmatchedSageCount.toString();
    }
    public get getCountdownTimer(): string {
        return this.countdownTimer.toString();
    }

    public get getCanResetTable(): boolean {
        return this.countdownTimer === 0;
    }

    public recusiveCountDown = (): void => {
        if (this.countdownTimer > 0) {
            this.countdownTimer--;
            setTimeout(this.recusiveCountDown, 1000);
        }
    };

    // JFF
    public get getCategories(): KeyValuePair<any>[] {
        return JFFCatagoryEnumHelper.getOptionsDropdown();
    }

    public setSelectedCategory = (key: JFFCatagoryEnum) => {
        this.selectedCategory = key;
    };

    public get getCatagorySelected(): string {
        return this.selectedCategory.toString();
    }

    public setJFFDateFrom: ICommand = new RelayCommand((value: moment.Moment) => {
        let temp: string = value.toISOString();
        this.jffStartDate = DateTime.fromISO(temp);
    });

    public get getJFFDateFrom(): Date {
        return new Date(this.jffStartDate.toString());
    }

    public setJFFDateTo: ICommand = new RelayCommand((value: moment.Moment) => {
        let temp: string = value.toISOString();
        this.jffEndDate = DateTime.fromISO(temp);
    });

    public get getJFFDateTo(): Date {
        return new Date(this.jffEndDate.toString());
    }

    // Project & Customer

    public updateTypeFiltering = (values: number[]) => {
        this.filterByType = values;
    };

    public get getCustomerTypesForDropdown() {
        let newArray = this.customerTypesList.map((type) => {
            return { key: type.id, text: type.name };
        });
        return newArray;
    }

    public get getTypeFilter(): number | number[] {
        return this.filterByType.slice();
    }

    public typeSelectAll = () => {
        this.filterByType = this.getCustomerTypesForDropdown.map((item) => {
            return item.key;
        });
    };

    public typeSelectNone = () => {
        this.filterByType = [];
    };

    public customerContactsSearchKeywordCommand: ICommand = new RelayCommand((value: string) => {
        this.customerContactsSearchKeyword = value;
    });

    public get customerContactsSearchValue(): string {
        return this.customerContactsSearchKeyword;
    }

    // End Project & Customer

    // Customer Contacts

    public projectAndCustomerSearchKeywordCommand: ICommand = new RelayCommand((value: string) => {
        this.projectAndCustomerSearchKeyword = value;
    });

    public get projectAndCustomerSearchValue(): string {
        return this.projectAndCustomerSearchKeyword;
    }

    public setTableDateFrom: ICommand = new RelayCommand((value: moment.Moment) => {
        let temp: string = value.toISOString();
        this.tableStartDate = DateTime.fromISO(temp);
    });

    public get getTableDateFrom(): Date {
        return new Date(this.tableStartDate.toString());
    }

    public setTableDateTo: ICommand = new RelayCommand((value: moment.Moment) => {
        let temp: string = value.toISOString();
        this.tableEndDate = DateTime.fromISO(temp);
    });

    public get getTableDateTo(): Date {
        return new Date(this.tableEndDate.toString());
    }

    // Project Types

    public updateProductFiltering = (values: string[]) => {
        this.filterByProduct = values;
    };

    public productSelectNone = () => {
        this.filterByProduct = [];
    };

    public productSelectAll = () => {
        this.filterByProduct = this.products.map((item) => {
            return item.key.toString();
        });
    };

    public get getProducts(): KeyValuePairExtended[] {
        return this.products.slice();
    }

    public get getProductFilter(): string | string[] {
        return this.filterByProduct.slice();
    }

    // End Project Types

    // BDM

    public get getBdms(): KeyValuePairExtended[] {
        return this.bdms.slice();
    }

    public get getBdmFilter(): string | string[] {
        return this.filterByBdm.slice();
    }

    public updateBdmFiltering = (values: string[]) => {
        this.filterByBdm = values;
    };

    public bdmSelectNone = () => {
        this.filterByBdm = [];
    };

    public bdmSelectAll = () => {
        this.filterByBdm = this.bdms.map((item) => {
            return item.key.toString();
        });
    };

    // End BDM

    // Tasks Type

    public get getTaskType(): KeyValuePairExtended[] {
        return this.filterTaskType.slice();
    }

    public get getTasksFilter(): string | string[] {
        return this.filterByTasksType.slice();
    }

    public updateTasksTypeFiltering = (values: string[]) => {
        this.filterByTasksType = values;
    };

    public tasksTypeSelectAll = () => {
        this.filterByTasksType = this.filterTaskType.map((item) => {
            return item.key;
        });
    };

    public tasksTypeSelectNone = () => {
        this.filterByTasksType = [];
    };

    // End Tasks

    public getUnmatchedSageNotFoundCount = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: GetUnmatchedSageRecordCountEndpoint = new GetUnmatchedSageRecordCountEndpoint();
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.unmatchedSageCount = this.apiClient.Response();

                    let response: ReportListAndRelatedResponse = this.apiClient.Response();
                    this.customerTypesList.replace(response.customerTypesList);

                    this.unmatchedSageCount = response.count;

                    this.bdms.clear();
                    this.filterTaskType.clear();

                    this.products.replace(
                        response.productTypeList.map((item) => {
                            if (item.parentId === 0) {
                                return { key: item.id.toString(), text: item.displayName, class: "parent" };
                            } else {
                                return { key: item.id.toString(), text: item.displayName, class: "child" };
                            }
                        }),
                    );

                    this.bdms.replace(
                        response.bdmList.map((item: any) => {
                            return { key: item.id.toString(), text: item.displayName, class: "" };
                        }),
                    );

                    this.filterTaskType.replace(
                        response.taskTypeList.map((item: any) => {
                            return { key: item.id.toString(), text: item.displayName, class: "" };
                        }),
                    );

                    const tempProduct: string[] = response.productTypeList.map((item) => {
                        return item.id.toString();
                    });

                    this.filterByProduct = tempProduct;

                    const tempBdm: string[] = response.bdmList.map((item) => {
                        return item.id.toString();
                    });

                    this.filterByBdm = tempBdm;

                    const tempTaskType: string[] = response.taskTypeList.map((item) => {
                        return item.id.toString();
                    });

                    this.filterByTasksType = tempTaskType;

                    this.typeSelectAll();

                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Sage Not Found CSV");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public SageNotFoundReset = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: SageNotFoundResetEndpoint = new SageNotFoundResetEndpoint();
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.unmatchedSageCount = this.apiClient.Response();
                    this.countdownTimer = 300;
                    this.recusiveCountDown();
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Sage Not Found CSV");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadSageNotFoundCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadNoParamaterReportEndpoint = new DownloadNoParamaterReportEndpoint(AppUrls.Server.Reports.DownloadSageNotFoundCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Sage Not Found CSV");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadCustomerCoatingCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadNoParamaterReportEndpoint = new DownloadNoParamaterReportEndpoint(AppUrls.Server.Reports.CustomerCoatingCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Sage Not Found CSV");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadCustomerPlygeneCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadNoParamaterReportEndpoint = new DownloadNoParamaterReportEndpoint(AppUrls.Server.Reports.CustomerPlygeneCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Sage Not Found CSV");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadProjectAndCustomerCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadProjectAndCustomerReportEndpoint = new DownloadProjectAndCustomerReportEndpoint(AppUrls.Server.Reports.ProjectCustomerCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project and Customer");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadProjectMasterAndProjectQuotesCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadProjectMasterAndProjectQuotesReportEndpoint = new DownloadProjectMasterAndProjectQuotesReportEndpoint(
                AppUrls.Server.Reports.ProjectMasterAndProjectQuote,
            );
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project Master and Project Quote");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadProjectDocumentCategoriesCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadProjectDocumentCategoriesReportEndpoint = new DownloadProjectDocumentCategoriesReportEndpoint(AppUrls.Server.Reports.ProjectDocumentCategoryCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project Document Categories");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadProjectSageContractorCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadProjectSageContractorReportEndpoint = new DownloadProjectSageContractorReportEndpoint(AppUrls.Server.Reports.ProjectSageContractorCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project Sage Contractor");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadProjectCommunicationsCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadProjectCommunicationsReportEndpoint = new DownloadProjectCommunicationsReportEndpoint(AppUrls.Server.Reports.ProjectCommunicationCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project Communications");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadProjectComplaintsCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadProjectComplaintsReportEndpoint = new DownloadProjectComplaintsReportEndpoint(AppUrls.Server.Reports.ProjectComplaintCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project Complaints");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadCustomerContactsCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadCustomerContactsReportEndpoint = new DownloadCustomerContactsReportEndpoint(AppUrls.Server.Reports.CustomerContactCSV);
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Customer Contacts");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public downloadProjectJFFCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadProjectJFFReportEndpoint = new DownloadProjectJFFReportEndpoint();
            this.isProcessing = true;

            let request: ReportJFFRequest = new ReportJFFRequest();
            request.startDate = this.jffStartDate.toISODate();

            request.endDate = this.jffEndDate.toISODate();

            if (this.selectedCategory == JFFCatagoryEnum.MasterOnly) {
                request.includeMasterProjects = true;
                request.includeProjectQuotes = false;
            } else if (this.selectedCategory == JFFCatagoryEnum.ProjectOnly) {
                request.includeMasterProjects = false;
                request.includeProjectQuotes = true;
            } else {
                // Both
                request.includeMasterProjects = true;
                request.includeProjectQuotes = true;
            }

            await this.apiClient.sendAsync(endpoint, request);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project JFF");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };
}
