import { action, makeObservable, observable } from "mobx";

export class ConditionReportForm {
    public id: number | null = null;
    public conditionReportId: string | null = null;
    public identifier: string = "";
    public versionIdentifier: string = "";
    public name: string = "";
    public version: number = 14;
    public spaceIdentifier: string = "";
    public spaceName: string = "";

    constructor() {
        makeObservable(this, {
            id: observable,
            conditionReportId: observable,
            identifier: observable,
            versionIdentifier: observable,
            name: observable,
            version: observable,
            spaceIdentifier: observable,
            spaceName: observable,
            clear: action,
            load: action,
        });
    }

    public load(form: ConditionReportForm | undefined) {
        this.clear();

        if (form !== undefined) {
            this.id = form.id;
            this.conditionReportId = form.conditionReportId;
            this.identifier = form.identifier;
            this.versionIdentifier = form.versionIdentifier;
            this.name = form.name;
            this.version = form.version;
            this.spaceIdentifier = form.spaceIdentifier;
            this.spaceName = form.spaceName;
        }
    }

    public clear = () => {
        this.id = -1;
        this.conditionReportId = null;
        this.identifier = "";
        this.versionIdentifier = "";
        this.name = "";
        this.version = 14;
        this.spaceIdentifier = "";
        this.spaceName = "";
    };
}
