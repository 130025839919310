export enum ContactStatusTypeEnum {
    Untrained = 10,
    CoatingsTrained = 20,
    PlygeneTrained = 30,
    TrainedBoth = 40,
}

export namespace ContactStatusTypeHelper {
    export const GetText = (status: ContactStatusTypeEnum): string => {
        let retVal: string = "";

        switch (status) {
            case ContactStatusTypeEnum.CoatingsTrained: {
                retVal = "Coatings trained";
                break;
            }
            case ContactStatusTypeEnum.PlygeneTrained: {
                retVal = "Plygene trained";
                break;
            }
            case ContactStatusTypeEnum.TrainedBoth: {
                retVal = "Trained (both)";
                break;
            }
            case ContactStatusTypeEnum.Untrained:
            default: {
                retVal = "Untrained";
                break;
            }
        }
        return retVal;
    };

    export const GetColor = (status: ContactStatusTypeEnum): string => {
        let retVal: string = "";

        switch (status) {
            case ContactStatusTypeEnum.CoatingsTrained: {
                retVal = "#76acde";
                break;
            }
            case ContactStatusTypeEnum.PlygeneTrained: {
                retVal = "#99c785";
                break;
            }
            case ContactStatusTypeEnum.TrainedBoth: {
                retVal = "#66b2b2";
                break;
            }
            case ContactStatusTypeEnum.Untrained:
            default: {
                retVal = "#636363";
                break;
            }
        }
        return retVal;
    };
}
