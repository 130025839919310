export interface ICustomerOption {
    id: number | null;
    customerId: Guid | null;
    leadTsmId: number;
    bdmId: number;
    sageRef: string;

    eORINumber: string;
    tSSNumber: string;
    approved: boolean;
    referral: boolean | null;
}

export class CustomerOption implements ICustomerOption {
    public id: number | null = null;
    public customerId: Guid | null = null;
    public leadTsmId: number = 6; // N/A
    public bdmId: number = 8; // N/A
    public sageRef: string = "";
    public eORINumber: string = "";
    public tSSNumber: string = "";
    public approved: boolean = false;
    public referral: boolean | null = null;
    public constructor() {}
}
