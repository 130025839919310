import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { Navigate, Route, Routes } from "react-router";
import { ComplaintsListRoutingView } from "./ComplaintsListRoutingView";
import { ComplaintsView } from "./View/ComplaintsView";

export const ComplaintsRoutes: React.FC = observer(() => {
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    return (
        <>
            <Routes>
                <Route path="*" element={<Navigate to="list" replace />} />
                <Route path={"list/*"} element={<ComplaintsListRoutingView />} />
                <Route path={"view/:complaintid/*"} element={<ComplaintsView />} />

                {/* <Route
                    path={"new/*"}
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <AddEditMultiSiteProjectView addOrEdit={ModalType.Add} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"edit/:multisiteprojectid/*"}
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <AddEditMultiSiteProjectView addOrEdit={ModalType.Edit} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"view/:multisiteprojectid/*"}
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <MultiSiteProjectView />
                        </React.Suspense>
                    }
                /> */}
            </Routes>
        </>
    );
});
