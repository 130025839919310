import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand, Input } from "Application";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import DocIcon from "Assets/Icons/DocumentIcon.svg";
import { DocumentModalGreyBox, TSMListHorizontalLine, TSMModalTableGrid, TSMModalWrapper, TSMTableSectionWrapper } from "../TSMModal.styles";
import Tick from "Assets/Icons/SmallTickCircleIcon.svg";
import Close from "Assets/Icons/RoundCloseIcon.svg";
import { P } from "Components/Primitives/TextElements/TextElements";
import { TSMDocumentsModalViewModel } from "./TSMDocumentsModalViewModel";
import { TSMListViewModel } from "Views/TSM/TSMListViewModel";
import { DocumentUploader } from "Components/DocumentUploader/DocumentUploader";
import { TSMDocumentListItem } from "./TSMDocumentListItemView";

export interface IModalProps {
    closeModalCommand: ICommand<any>;
    isOpen: boolean;
    parentViewModel: TSMListViewModel;
}

export const TSMDocumentsModalView: React.FC<IModalProps> = observer((props) => {
    const [viewModel] = useState(() => new TSMDocumentsModalViewModel(props.parentViewModel));
    useEffect(() => {
        viewModel.loadDocumentsList(props.parentViewModel.selectedRowId);
        viewModel.setValue("projectId", props.parentViewModel.selectedRowId);
        viewModel.setTsmId.execute(props.parentViewModel.tsmId);
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    return (
        <>
            <ModalGenericWithHeader
                isOpen={props.isOpen}
                closeModalCommand={props.closeModalCommand}
                headerIcon={DocIcon}
                headerText={viewModel.projectNumber}
                headerSubText={viewModel.projectName}
                stickyBar={<Button displayName=" Close" icon={<img src={Close} />} command={props.closeModalCommand} />}
                noBodyMargin={true}
            >
                <TSMModalWrapper>
                    <Box>
                        <Box style={{ flexGrow: 1 }} p="9px">
                            <DocumentUploader parentViewModel={viewModel} documentTypes={[]} />
                        </Box>
                        <DocumentModalGreyBox>
                            <Button displayName=" Add Document" command={viewModel.submitDocuments} icon={<img src={Tick} />} paletteColor="ButtonGreen" boxShadow={true} />
                        </DocumentModalGreyBox>
                    </Box>

                    <TSMTableSectionWrapper>
                        <TSMModalTableGrid>
                            {/* Table header... */}
                            <P>
                                <strong>Date</strong>
                            </P>
                            <P>
                                <strong>File name</strong>
                            </P>
                            <P>
                                <strong>Added by</strong>
                            </P>
                            <P>
                                <strong>Actions</strong>
                            </P>
                            <TSMListHorizontalLine />

                            {/* Pass in the items... */}
                            {viewModel.tsmDocumentList.map((item, index) => {
                                return (
                                    <>
                                        <TSMDocumentListItem key={index} item={item} parentViewModel={viewModel} />
                                    </>
                                );
                            })}
                        </TSMModalTableGrid>
                    </TSMTableSectionWrapper>
                </TSMModalWrapper>
            </ModalGenericWithHeader>
        </>
    );
});
