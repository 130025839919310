import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";

export class TSMSiteVisitModalModel extends ModelBase<TSMSiteVisitModalModel> {
    //public id: string = "";

    public siteVisitId: number | null = null;
    public projectId: Guid = ""; // Project Quote Id
    public propertyId: Guid = "";
    public visitDateTime: Date = new Date();
    public requestedBy: string = "";
    public tsmId: number = -1;
    public visitTypeId: number = -1;
    public note: string = "";
    public contactId: Guid | null = null;
    public rowVersion: string = ""; // ?

    constructor() {
        super();
        makeObservable(this, {
            //id: observable
            siteVisitId: observable,
            projectId: observable,
            propertyId: observable,
            visitDateTime: observable,
            requestedBy: observable,
            tsmId: observable,
            visitTypeId: observable,
            note: observable,
            contactId: observable,
            rowVersion: observable,

            clear: action,
        });
    }

    public clear = () => {
        //this.id = "";
    };
}

export class TSMSiteVisitModalModelValidator extends Validator<TSMSiteVisitModalModel> {
    constructor() {
        super();
        this.ruleFor("visitTypeId").greaterThanOrEqualTo(0).withMessage("Please select a visit type");
        this.ruleFor("contactId").notNull().withMessage("Please select a contact");
        //this.ruleFor("id").notEmpty();
    }
}
