import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { EditSelect, ICommand, RelayCommand } from "Application";
import { P } from "Components/Primitives/TextElements/TextElements";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import InfoIcon from "Assets/Icons/InfoRoundIcon.svg";
import { InnerBox, LeftBox, OuterBox, RightGreyBox } from "./SelectedItem.style";
import { ButtonLikeImage } from "Components/Primitives/Buttons/ButtonLikeImage";
import { KeyValuePair } from "@shoothill/core";

export interface ISelectedItemData {
    id: string;
    displayName?: string;
}

export interface ISelectedItem {
    data: ISelectedItemData;
    showInfoCommand: ICommand;
    removeItemCommand: ICommand;
    customerContactItemCommand?: ICommand;
    marginBottom?: string;
    showRemoveButton?: boolean;
    showEditSelected?: boolean;
    contactList?: KeyValuePair[];
    contactValue?: string;
    showModel?: () => void;
}

export const SelectedItem: React.FC<ISelectedItem> = observer((props) => {
    const [selectedItemId, setSelectedItemId] = useState("");

    useEffect(() => {
        if (props.data !== undefined) {
            setSelectedItemId("selecteditem-" + props.data.id);
        }

        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    const showInfo = () => {
        if (props.showInfoCommand.canExecute()) {
            props.showInfoCommand.execute(selectedItemId, props.data.id);
        }
    };

    const removeItem = () => {
        if (props.removeItemCommand.canExecute()) {
            props.removeItemCommand.execute(props.data.id);
        }
    };

    const customerContactCommand = new RelayCommand((itemToMove: KeyValuePair) => {
        if (props.customerContactItemCommand?.canExecute()) {
            props.customerContactItemCommand?.execute(props.data.id, itemToMove.key!);
        }
    });

    const showModel = () => {
        if (props.showModel) {
            props.showModel();
        }
    };

    //styles
    const marginBottom: string | undefined = props.marginBottom ?? undefined;

    return (
        <>
            {props.data !== undefined && (
                <OuterBox mb={marginBottom}>
                    <InnerBox>
                        <LeftBox>
                            <P>{props.data.displayName}</P>
                        </LeftBox>
                        {props.showEditSelected && (
                            <EditSelect
                                command={customerContactCommand}
                                options={props?.contactList!}
                                modelOpen={showModel}
                                value={() => props?.contactValue!}
                                showAddButton={true}
                                noHeader={true}
                            />
                        )}
                    </InnerBox>
                    <RightGreyBox>
                        <ButtonLikeImage id={selectedItemId} onClick={showInfo}>
                            <img src={InfoIcon} />
                        </ButtonLikeImage>
                        {props.showRemoveButton === null ||
                            props.showRemoveButton === undefined ||
                            (props.showRemoveButton === true && <DeleteButton id={props.data.id} onClick={removeItem} />)}
                    </RightGreyBox>
                </OuterBox>
            )}
        </>
    );
});
