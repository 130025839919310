import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { LinkedProjectQuotesViewModel } from "../LinkedProjectQuotesViewModel";
import { ProjectProductDropDown } from "Models/Project/ProjectProductDropDown";
import { LinkedProjectQuoteListItem } from "../LinkedProjectQuoteListItem";
import { AppUrls } from "AppUrls";

export class LinkedProjectQuotesRequest {
    public customerId: Guid = "";
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public filterByProduct: string[] = [];
    public filterByStatus: string[] = [];
    public initialLoad: boolean = true;
}

export class LinkedProjectQuotesResponse {
    public projectProduct: ProjectProductDropDown[] = [];
    public customerProjectQuoteListItem: LinkedProjectQuoteListItem[] = [];
    public customerCount: number = 0;
}

export class GetLinkedProjectQuotesProjectsEndpoint extends Endpoint<LinkedProjectQuotesRequest, LinkedProjectQuotesResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.GetProjectQuoteListItems);
    }

    public async HandleRequestAsync(viewModel: LinkedProjectQuotesViewModel): Promise<any> {
        let request = new LinkedProjectQuotesRequest();
        request.customerId = viewModel.model.customerId;
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageNumber = viewModel.pageNumber;
        request.pageSize = viewModel.pageSize;
        request.filterByProduct = [];
        request.filterByStatus = [];

        request.filterByProduct = viewModel.productFilter.map((item) => {
            return item;
        });

        request.filterByStatus = viewModel.statusFilter.map((item) => {
            return item;
        });

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: LinkedProjectQuotesResponse): Promise<LinkedProjectQuotesResponse> {
        return response;
    }
}
