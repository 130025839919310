import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable, IObservableArray } from "mobx";
import { Validator } from "Application/Validation";
import { GenericComplaintActionListItem } from "../Endpoints/GetCorrectiveActionForView";

export class CorrectiveActionModel extends ModelBase<CorrectiveActionModel> {
    //public id: string = "";

    // List
    public actions: IObservableArray<GenericComplaintActionListItem> = observable([]);

    // Adding & Editing
    public actionId: number | null = null;
    public correctiveAction: string = "";
    public rowVersion: string = "";
    public projectComplaintId: string = "";

    constructor() {
        super();
        makeObservable(this, {
            //id: observable
            actionId: observable,
            correctiveAction: observable,
            rowVersion: observable,
            projectComplaintId: observable,

            clear: action,
        });
    }

    public clear = () => {
        this.actionId = null;
        this.correctiveAction = "";
        this.rowVersion = "";
    };
}

export class CorrectiveActionModelValidator extends Validator<CorrectiveActionModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("correctiveAction")
            .notEmpty()
            .withMessage("Please enter a corrective action")
            .notNull()
            .withMessage("Please enter a corrective action")
            .maxLength(255)
            .withMessage("Please enter an correctiveAction less than 255 characters.");
    }
}
