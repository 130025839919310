import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class GenericNotesModel extends ModelBase<GenericNotesModel> {
    public note: string = "";
    public sourceId: Guid = "";
    public rowVersion: string = "";

    public docsCount: number = 0;
    // Table sorting
    public columnName: string = "createdDate";
    public orderBy: string = "DESC";

    constructor() {
        super();
        makeObservable(this, {
            note: observable,
            sourceId: observable,
            rowVersion: observable,
            docsCount: observable,
            columnName: observable,
            orderBy: observable,
        });
    }

    public clear = () => {
        this.note = "";
        this.docsCount = 0;
        this.sourceId = "";
        this.columnName = "createdDate";
        this.orderBy = "DESC";
        this.rowVersion = "";
    };
}

export class GenericNotesModelValidator extends Validator<GenericNotesModel> {
    constructor() {
        super();
        this.ruleFor("note").notEmpty().withMessage("Please add a note");
        this.ruleFor("sourceId").notEmpty().withMessage("Please add a link to the source");
    }
}
