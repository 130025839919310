import { action, makeObservable, observable } from "mobx";

export class ConditionReportDevice {
    public id: number = -1;
    public conditionReportId: string | null = null;
    public mobileAppVersion: string = "";
    public os: string = "";
    public osVersion: string = "";

    constructor() {
        makeObservable(this, {
            id: observable,
            conditionReportId: observable,
            mobileAppVersion: observable,
            os: observable,
            osVersion: observable,
            clear: action,
            load: action,
        });
    }

    public load(device: ConditionReportDevice | undefined) {
        this.clear();

        if (device !== undefined) {
            this.id = device.id;
            this.conditionReportId = device.conditionReportId;
            this.mobileAppVersion = device.mobileAppVersion;
            this.os = device.os;
            this.osVersion = device.osVersion;
        }
    }

    public clear = () => {
        this.id = -1;
        this.conditionReportId = null;
        this.mobileAppVersion = "";
        this.os = "";
        this.osVersion = "";
    };
}
