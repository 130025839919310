import { makeObservable, observable, runInAction, action, computed, IObservableArray } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { BlankModel, BlankModelValidator } from "Application/Models/Domain/BlankModel";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import {
    GetListItemsForConditionReportIdEndpoint,
    GetListItemsForConditionReportPDFListItem,
    GetListItemsForConditionReportResponse,
} from "./Endpoints/GetListItemsForConditionReportIdEndpoint";
import { Logger } from "index";
import { DownloadPdfEndpoint } from "./Endpoints/DownloadPDFEndpoint";
import { DeletePDFByIdEndpoint } from "./Endpoints/DeletePDFByIdEndpoint";

export class ConditionReportPdfListViewModel extends ViewModelBase<BlankModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.DESC;
    public columnName: string = "templateNumber";

    // Search String
    public searchString: string = "";

    // Pagination
    public pageItemCount: number = 0;
    public totalCount: number = 0;
    public pageCount: number = 0;

    public pageSize: number = 50;
    public pageNumber: number = 1;
    public ascOrder = true;

    public resetLoginAttemptsError: string = "";
    public isTableLoading: boolean = false;
    public isProcessing: boolean = false;

    public templatePdfs: IObservableArray<GetListItemsForConditionReportPDFListItem> = observable([]);

    public pdfToDeleteId: string = "";
    public conditionReportId: string = "";

    public spinnerText: string = "Loading....";

    constructor() {
        super(new BlankModel());
        this.setValidator(new BlankModelValidator());
        this.apiClient.setAPITimeout(240000);
        makeObservable(this, {
            pageItemCount: observable,
            ascOrder: observable,
            resetLoginAttemptsError: observable,
            isTableLoading: observable,
            isProcessing: observable,
            pageSize: observable,
            pageNumber: observable,
            searchString: observable,
            spinnerText: observable,
            //showDialog: observable,

            clear: action,
            deletePDF: action,
            downloadPDF: action,
            executeSearch: action,
            loadPdfDetailsForConditionReportAsync: action,
            set: action,
            setIsTableLoading: action,
            setOrderAsc: action,
            setSearchString: action,

            getOrderAsc: computed,
            getPageItemCount: computed,
            getPdfs: computed,
            getTotalItemCount: computed,
        });
    }

    public setSearchString(searchString: string) {
        this.searchString = searchString;
    }

    public executeSearch(): void {
        let promise = this.loadPdfDetailsForConditionReportAsync(this.conditionReportId);

        promise.then((result) => {});
    }

    public deletePDF = async (deleteSelectionId: string, conditionReportId: string) => {
        this.pdfToDeleteId = deleteSelectionId;
        this.conditionReportId = conditionReportId;
        this.isProcessing = true;
        this.spinnerText = "Deleting PDF...";
        const endpoint: DeletePDFByIdEndpoint = new DeletePDFByIdEndpoint();
        await this.apiClient.sendAsync(endpoint, this);
        Logger.logDebug("loadPdfDetailsForConditionReportAsync: ", this.apiClient.Response<GetListItemsForConditionReportResponse>());

        if (this.apiClient.IsRequestSuccessful === true) {
            let response: GetListItemsForConditionReportResponse = this.apiClient.Response<GetListItemsForConditionReportResponse>();

            runInAction(() => {
                this.totalCount = response.queryTotalCount;
                this.pageItemCount = response.conditionPDFReports.length;

                this.pageCount = 0;

                if (this.pageSize != 0) {
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                }

                this.templatePdfs.clear();
                this.templatePdfs.replace(response.conditionPDFReports);

                this.pdfToDeleteId = "";
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Condition Report");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to delete the pdf data.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    };

    public loadPdfDetailsForConditionReportAsync = async (conditionReportId: string) => {
        this.conditionReportId = conditionReportId;

        if (this.isProcessing === false && this.apiClient.IsBusy == false) {
            //this.isProcessing = true;
            this.spinnerText = "Loading...";
            const endpoint: GetListItemsForConditionReportIdEndpoint = new GetListItemsForConditionReportIdEndpoint();
            await this.apiClient.sendAsync(endpoint, this);
            Logger.logDebug("loadPdfDetailsForConditionReportAsync: ", this.apiClient.Response<GetListItemsForConditionReportResponse>());

            if (this.apiClient.IsRequestSuccessful === true) {
                let response: GetListItemsForConditionReportResponse = this.apiClient.Response<GetListItemsForConditionReportResponse>();

                runInAction(() => {
                    this.totalCount = response.queryTotalCount;
                    this.pageItemCount = response.conditionPDFReports.length;

                    this.pageCount = 0;

                    if (this.pageSize != 0) {
                        this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                    }

                    this.templatePdfs.clear();
                    this.templatePdfs.replace(response.conditionPDFReports);
                    //      this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    //    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the pdf list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public clear = () => {
        this.pageItemCount = 0;
        this.pageCount = 0;
        this.ascOrder = true;
        this.resetLoginAttemptsError = "";
        this.isTableLoading = false;
        this.isProcessing = false;

        this.orderBy = SortOrderDirection.DESC;
        this.columnName = "createdDate";

        // Pagination
        this.pageSize = 20;
        this.pageNumber = 1;

        this.searchString = "";

        this.conditionReportId = "";
        this.pdfToDeleteId = "";
        this.spinnerText = "Loading...";
    };

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value);
    }

    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    public get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    public get getPdfs(): any[] {
        console.log("getConditionReports");
        return this.templatePdfs.slice();
    }

    public setIsTableLoading = (state: boolean) => {
        this.isTableLoading = state;
    };

    public get getPageItemCount(): number {
        return this.pageItemCount;
    }

    public get getTotalItemCount(): number {
        return this.totalCount;
    }

    public downloadPDF = async (id: any): Promise<boolean> => {
        let retVal: boolean = true;
        this.isProcessing = true;
        this.spinnerText = "Downloading PDF...";
        let endpoint: DownloadPdfEndpoint = new DownloadPdfEndpoint(id);
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful === true) {
            let response: boolean = this.apiClient.Response<boolean>();

            runInAction(() => {
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Condition Report");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to download the pdf list.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }

        return retVal;
    };
}
