import { CoreStore } from "@shoothill/core";
import { container } from "tsyringe";
import { AccountStore } from "./Domain";
import { GlobalHistory } from "../index";
import { config } from "../config";

export class Stores {
    public CoreStore: CoreStore = new CoreStore(config.coreOptions);
    public AccountStore = container.resolve(AccountStore);

    constructor() {
        (window as any).stores = this;
    }

    public init = async () => {
        await this.AccountStore.init();
        this.CoreStore.Init();
        this.CoreStore.SetHistory(GlobalHistory);
    };
}
