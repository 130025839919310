import styled from "@emotion/styled";
import { Box } from "Components";
import { BaseWrapper } from "Components/BaseWrapper";

export const TSMListWrapper = styled(BaseWrapper)``;

export const TsmActionsCell = styled(Box)`
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 5px;
`;
