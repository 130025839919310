import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { DeleteMasterProjectByIdRequest } from "./DeleteMasterProjectByIdEndpoint";
import { PropertyProjectQuoteListItem } from "../ViewProperty/Models/PropertyProjectQuoteListItem";

export class DeleteProjectQuoteByIdEndpoint extends Endpoint<DeleteMasterProjectByIdRequest, PropertyProjectQuoteListItem[]> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.DeleteProjectQuoteById);
    }

    public async HandleRequestAsync(request: PropertyProjectQuoteListItem): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: PropertyProjectQuoteListItem[]): Promise<PropertyProjectQuoteListItem[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
