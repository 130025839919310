import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class GetTemplateCSSFileEndpoint extends EndpointWithoutRequest<string> {
    constructor(id: string) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.ConditionReportTemplate.GetTemplateCSSFile.replace("{:templateId}", id));
    }

    public async HandleResponseAsync(response: string): Promise<string> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
