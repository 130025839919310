import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { CustomerNoteCalloutItem } from "Models/Customer/CustomerNoteCalloutItem";
import { ProjectCalloutItem } from "Models/Project/ProjectCalloutItem";

export class ContactInfoModalModel extends ModelBase<ContactInfoModalModel> {
    public displayName: string = "";
    public addressLine1: string = "";
    public addressLine2: string = "";
    public townCity: string = "";
    public county: string = "";
    public postCode: string = "";
    public customerStatusId: number = 0;
    public phone: string = "";
    public phone2: string = "";
    public email: string = "";
    public customerNoteCalloutItems: CustomerNoteCalloutItem[] = [];
    public projectCalloutItems: ProjectCalloutItem[] = [];
    public addressFull: string = "";
    public plygeneTrained: boolean = false;
    public coatingsTrained: boolean = false;
    public coatingsCPD: boolean = false;
    public plygeneCPD: boolean = false;
    constructor() {
        super();
        makeObservable(this, {
            displayName: observable,
            addressLine1: observable,
            addressLine2: observable,
            townCity: observable,
            county: observable,
            postCode: observable,
            customerStatusId: observable,
            phone: observable,
            phone2: observable,
            email: observable,
            addressFull: observable,
            plygeneTrained: observable,
            coatingsTrained: observable,
            coatingsCPD: observable,
            plygeneCPD: observable,
        });
    }

    public clear = () => {};
}

export class ContactInfoModalModelValidator extends Validator<ContactInfoModalModel> {
    constructor() {
        super();
    }
}
