import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { CustomerNoteDocumentLight } from "../Common/CustomeNoteDocumentLight";
import { DeleteNoteByIdRequest } from "Models/Notes/DeleteNoteByIdRequest";

interface DeleteCustomerNoteByIdResponse {
    customerId: string;
    documents: CustomerNoteDocumentLight[];
}
export class DeleteCustomerNoteByIdEndpoint extends Endpoint<DeleteNoteByIdRequest, DeleteCustomerNoteByIdResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.DeleteCustomerNoteById);
    }

    public async HandleRequestAsync(id: string): Promise<any> {
        let request = new DeleteNoteByIdRequest();
        request.id = id;
        return request;
    }

    public async HandleResponseAsync(response: any): Promise<DeleteCustomerNoteByIdResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
