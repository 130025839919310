import styled from "@emotion/styled";
import { Button } from "Components";

export const DocViewerContainer = styled.div`
    height: 100%;
    border: 1px solid lightgray;
    img {
        width: 100%;
    }
    #header-bar {
        min-height: 0;
    }
    #proxy-renderer {
        /* max-height: calc(100vh - 280px); */
        thead {
            position: sticky;
            top: 0;
            background: #fff;
        }
        td:empty,
        th:empty {
            display: none;
        }
    }
`;

export const Container = styled.div`
    height: 80vh;
    width: 768px;
    /* aspect-ratio: 1/1.4142; // A4 aspect ratio */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    transition: width 0.3s ease-in-out;
`;
