import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Input, MaskedOptions } from "Application";
import { LatLongEditorViewModel } from "./LatLongEditorViewModel";
import { AddressGenericViewModel } from "./AddressGenericViewModel";
import { SpanTwoBox } from "Components/SpanBoxes";

interface ILatLongEditor {
    addressViewModel: AddressGenericViewModel;
}

export const LatLongEditor: React.FC<ILatLongEditor> = observer(({ addressViewModel }) => {
    const [viewModel] = useState(() => new LatLongEditorViewModel(addressViewModel));
    useEffect(() => {
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    useEffect(() => {
        viewModel.setValue("latitude", addressViewModel.model.latitude.toString());
        viewModel.setValue("longitude", addressViewModel.model.longitude.toString());
    }, [addressViewModel.model.latitude, addressViewModel.model.longitude]);

    return (
        <>
            <SpanTwoBox>
                <Input
                    type="number"
                    displayName="Latitude *"
                    placeholder="Enter Latitude"
                    validationMessage={() => viewModel.getError("latitude")}
                    command={viewModel.setLatitude}
                    value={() => viewModel.getValue("latitude")}
                    onBlur={viewModel.onBlurCommand}
                    autoFill={false}
                    maxLength={18}
                />
            </SpanTwoBox>
            <SpanTwoBox>
                <Input
                    type="number"
                    displayName="Longitude *"
                    placeholder="Enter Longitude"
                    validationMessage={() => viewModel.getError("longitude")}
                    command={viewModel.setLongitude}
                    value={() => viewModel.getValue("longitude")}
                    onBlur={viewModel.onBlurCommand}
                    autoFill={false}
                    maxLength={18}
                />
            </SpanTwoBox>
        </>
    );
});
