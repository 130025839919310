import { EndpointWithoutRequest } from "Application";
import { Logger } from "index";
import { Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

class GetConditionReportForViewByIdResponse {}

export class GetConditionReportForViewByIdEndpoint extends EndpointWithoutRequest<GetConditionReportForViewByIdResponse> {
    constructor(id: string) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.ConditionReport.GetGetConditionReportForViewById.replace("{:conditionReportId}", id));
    }

    public async HandleResponseAsync(response: GetConditionReportForViewByIdResponse): Promise<GetConditionReportForViewByIdResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
