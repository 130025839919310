import { KeyValuePair } from "@shoothill/core";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";

export enum CustomerStatus {
    Active = 100,
    Dissolved = 200,
    InActive = 300,
}

export module CustomerStatusHelpers {
    export const getCustomerStatusText = (value: CustomerStatus) => {
        let retVal: string = "Active";
        if (value == CustomerStatus.Active) {
            retVal = "Active";
        } else if (value == CustomerStatus.Dissolved) {
            retVal = "Dissolved";
        } else if (value == CustomerStatus.InActive) {
            retVal = "In-active";
        }

        return retVal;
    };

    export const getCustomerStatusClass = (value: CustomerStatus) => {
        let retVal: string = "Active";
        if (value == CustomerStatus.Active) {
            retVal = "customerstatus-active";
        } else if (value == CustomerStatus.Dissolved) {
            retVal = "customerstatus-dissolved";
        } else if (value == CustomerStatus.InActive) {
            retVal = "customerstatus-inactive";
        }

        return retVal;
    };

    export const getStatusForDropdown = (): KeyValuePair[] => {
        let retVal: KeyValuePair[] = [];

        for (const key in CustomerStatus) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as CustomerStatus;
                retVal.push({
                    text: CustomerStatusHelpers.getCustomerStatusText(item),
                    key: keyAsInt.toString(),
                });
            }
        }

        return retVal;
    };

    export const getStatusForStatusDropdown = (): KeyValuePairExtended[] => {
        let retVal: KeyValuePairExtended[] = [];

        for (const key in CustomerStatus) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as CustomerStatus;
                retVal.push({
                    text: CustomerStatusHelpers.getCustomerStatusText(item),
                    key: keyAsInt.toString(),
                    class: CustomerStatusHelpers.getCustomerStatusClass(item),
                });
            }
        }

        return retVal;
    };
}
