import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";

class GetContactDetailsModalByIdResponse {}

export class GetContactDetailsModalByIdEndpoint extends EndpointWithoutRequest<GetContactDetailsModalByIdResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(`/api/customer/getcontactModalDetailsbyid/${id}`);
    }

    public async HandleResponseAsync(response: GetContactDetailsModalByIdResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
