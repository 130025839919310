import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { ComplaintDocumentItemModel } from "../Documents/ComplaintDocumentItemModel";

export class ComplaintDocumentUpsertRequest {
    complaintId: Guid = "";
    documentCategoryId: number = 0;
    fileName: string = "";
    fileBase64: string = "";
    fileType: string = "";
}

export class MasterProjectComplaintDocumentUpsertEndpoint extends Endpoint<ComplaintDocumentUpsertRequest, IDocumentUploaded[]> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.MasterProjectComplaintDocumentUpsert);
    }

    public async HandleRequestAsync(model: ComplaintDocumentItemModel): Promise<any> {
        let request = new ComplaintDocumentUpsertRequest();
        request.complaintId = model.complaintId;
        request.documentCategoryId = model.documentCategoryId;
        request.fileName = model.filename;
        request.fileBase64 = model.fileBase64;
        request.fileType = model.fileType;
        return request;
    }

    public async HandleResponseAsync(response: IDocumentUploaded[]): Promise<IDocumentUploaded[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
