import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, EditSelect, ICommand, Input, RelayCommand } from "Application";
import { Modal } from "@fluentui/react";
import { AddSectionModalBody, AddSectionModalHeader, AddSectionModalWrapper, ModalFooter, ModalRow } from "./AddSectionModal.style";
import { P } from "Components/Primitives/TextElements/TextElements";
import AddIcon from "Assets/Icons/addRoundButtonIcon.svg";
import CloseIcon from "Assets/close_icon.svg";
import { ButtonBox, HeaderBox } from "Components/AreYouSureModal/AreYouSure.styles";
import { ControlType, ControlTypeHelpers } from "Views/ConditionReport/Common/ControlType";
import { KeyValuePair } from "@shoothill/core";
import { TemplateSourceModel } from "Views/ConditionReport/Common/Models/Source/TemplateSourceModel";
import { DEFAULTTEMPLATESECTIONNAME, generateSectionTypeOptions } from "Utils/TemplateSource";
import { AddSectionModalViewModel } from "./AddSectionModalViewModel";
export interface IAddSection {
    title: string;
    isOpen: boolean;

    templateSource: TemplateSourceModel;

    onConfirm: (sectionTite: string, sectionType: string, controlTypeId: ControlType, typeKey: string, subSections: KeyValuePair[], isRepeatable: boolean) => void;
    onCancel: () => void;
}

export const AddSectionModal: React.FC<IAddSection> = observer(({ title, isOpen, onCancel, onConfirm, templateSource }) => {
    const [viewModel] = useState<AddSectionModalViewModel>(new AddSectionModalViewModel());

    const clear = () => {
        viewModel.clear();
    };

    useEffect(() => {
        viewModel.setTemplateSource(templateSource);
    }, [templateSource]);

    const handleAddClick: ICommand = new RelayCommand(() => {
        if (viewModel.model.sectionName.length > 0) {
            if (viewModel.isModelValid() === false) {
                viewModel.setErrorMessage("Please select a question for the section");
            } else {
                let subSections: KeyValuePair[] = [];

                if (viewModel.isRepeatSection === true) {
                    if (templateSource !== undefined) {
                        subSections = viewModel.getOptionsforRepeatableSection(viewModel.model.typeKeyId, viewModel.model.controlTypeId);
                    }
                } else if (viewModel.model.controlTypeId === ControlType.ButtonGroup || viewModel.model.controlTypeId === ControlType.Multiselect) {
                    if (templateSource !== undefined) {
                        viewModel.model.sectionName = viewModel.getQuestionforSection(viewModel.model.typeKeyId, viewModel.model.controlTypeId);
                        subSections = viewModel.getOptionsforSection(viewModel.model.typeKeyId, viewModel.model.controlTypeId);
                    }
                }

                onConfirm(
                    viewModel.model.sectionName,
                    viewModel.model.sectionTypeKeyId,
                    viewModel.model.controlTypeId,
                    viewModel.model.typeKeyId,
                    subSections,
                    viewModel.isRepeatSection,
                );
                clear();
            }
        } else {
            viewModel.setErrorMessage("Please add a section name");
        }
    });

    const handleCancelClick: ICommand = new RelayCommand(() => {
        handleCancel();
    });

    const handleCancel = () => {
        clear();
        onCancel();
    };

    const updateSectionTypeKeyCommand: ICommand = new RelayCommand((value: KeyValuePair) => {
        viewModel.setSectionType(value.key);

        let isRepeatable: boolean = false;
        if (value.text.indexOf("- Repeat)") != -1) {
            isRepeatable = true;
        }
        viewModel.setIsRepeatableSection(isRepeatable);

        if (viewModel.model.isNameDirty === false || viewModel.model.sectionName.length === 0) {
            viewModel.setSectionName(value.text.replace("-", "").trim());
        }

        viewModel.setOriginalSectionName(value.text.replace("-", "").trim());
    });

    let sectionTypeOptions: KeyValuePair[] = [];

    if (templateSource !== undefined) {
        sectionTypeOptions = generateSectionTypeOptions(templateSource);
    }

    const setSectionNameCommand: ICommand = new RelayCommand((value: string) => {
        if (value !== viewModel.model.sectionName) {
            viewModel.setSectionName(value);
            viewModel.setErrorMessage("");
            viewModel.setIsDirty(true);
        }
    });

    let controlTypeOptions: KeyValuePair[] = ControlTypeHelpers.getKeyValuePairOptionsForAddSection();

    if (templateSource !== undefined) {
        controlTypeOptions = ControlTypeHelpers.getKeyValuePairOptionsForTemplate(templateSource, viewModel.getCurrentLevel, viewModel.model.sectionTypeKeyId);
    }

    const typeKeyOptions: KeyValuePair[] = templateSource === undefined ? [] : viewModel.generateTypeKeys(viewModel.model.controlTypeId, templateSource); //templateSource!.generateTypeKeys(controlTypeId);

    const updateControlTypeCommand: ICommand = new RelayCommand((value: KeyValuePair) => {
        let controlTypeId: ControlType = parseInt(value.key) as ControlType;
        viewModel.setControlType(controlTypeId);

        if (controlTypeId !== ControlType.Paragraph) {
            // then use the type key
        } else {
            // reset anything selected so far
            viewModel.setTypeKey("");
        }
    });

    const updateControlTypeKeyCommand: ICommand = new RelayCommand((value: KeyValuePair) => {
        viewModel.setTypeKey(value.key);
    });

    const disableSave: boolean = viewModel.model.sectionName.length === 0 ? true : false;

    return (
        <>
            <Modal isOpen={isOpen}>
                <AddSectionModalWrapper>
                    <AddSectionModalHeader>
                        <HeaderBox>
                            <img src={AddIcon} height="20px" />
                            <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                {title}
                            </P>
                        </HeaderBox>
                        <HeaderBox style={{ cursor: "pointer" }} onClick={() => handleCancel()}>
                            <img src={CloseIcon} />
                        </HeaderBox>
                    </AddSectionModalHeader>

                    <AddSectionModalBody>
                        <ModalRow>
                            <p>
                                <strong>Sections</strong> can be generic text (Generic), in which case, they will always be in the document, or you can lock a section to either a
                                page or section (Site Information, Gutter Information etc) within the json file. That way, if that section is empty, it can be automatically removed
                                from the final document.
                            </p>
                            <p>Sections can either be Flow or Repeated.</p>
                            <p>
                                <strong>Flow</strong> sections - Are displayed once in the document.
                            </p>
                            <p>
                                <strong>Repeat</strong> sections - Can be repeated, for example the Roof section, might cover multiple roofs, so all sub pages and sub sections will
                                be loaded into this section.
                            </p>
                        </ModalRow>
                        <ModalRow>
                            <EditSelect
                                displayName="Section Type"
                                options={sectionTypeOptions}
                                value={() => viewModel.model.sectionTypeKeyId.toString()}
                                command={updateSectionTypeKeyCommand}
                            />
                        </ModalRow>
                        <Input
                            displayName="Section name"
                            placeholder="Name of the section"
                            command={setSectionNameCommand}
                            value={() => viewModel.model.sectionName}
                            validationMessage={() => viewModel.getError("sectionName")}
                        />
                        {templateSource === undefined && <p>No template source so limiting to text only</p>}
                        {templateSource !== undefined && viewModel.model.sectionTypeKeyId != DEFAULTTEMPLATESECTIONNAME && (
                            <>
                                <ModalRow>
                                    <p>
                                        The <strong>Control Type</strong>, mimics the controls in the original app, so will add subsections to the sections for any options.
                                        Appropriate sections will then kept in the final document and unused options removed.
                                    </p>
                                    <p>
                                        For <strong>Repeat</strong> sections, a general text area is added followed by all suppages and sections.
                                    </p>
                                </ModalRow>
                                <ModalRow>
                                    <EditSelect
                                        displayName="Control Type"
                                        options={controlTypeOptions}
                                        value={() => viewModel.model.controlTypeId.toString()}
                                        command={updateControlTypeCommand}
                                    />
                                </ModalRow>
                                {typeKeyOptions !== undefined && typeKeyOptions.length > 0 && (
                                    <>
                                        <ModalRow>
                                            <p>
                                                <strong>Question</strong>, is to lock the section options to a specfic question.
                                            </p>
                                        </ModalRow>
                                        <ModalRow>
                                            <EditSelect
                                                displayName="Question"
                                                options={typeKeyOptions}
                                                value={() => viewModel.model.typeKeyId.toString()}
                                                command={updateControlTypeKeyCommand}
                                                validationMessage={() => viewModel.getError("typeKeyId")}
                                            />
                                        </ModalRow>
                                    </>
                                )}
                            </>
                        )}
                    </AddSectionModalBody>
                    <ModalFooter>
                        <ButtonBox pt="30px">
                            <Button command={handleAddClick} displayName="Add" boxShadow={true} paletteColor="ButtonGreen" isButtonDisabled={disableSave} />
                            <Button className="last-button" command={handleCancelClick} displayName="Cancel" boxShadow={true} paletteColor="ButtonBlue" />
                        </ButtonBox>
                    </ModalFooter>
                </AddSectionModalWrapper>
            </Modal>
        </>
    );
});
