import React, { useEffect, useRef } from "react";
// import mammoth from "mammoth";
import { renderAsync } from "docx-preview";
import { WordDocWrapper } from "./WordViewer.style";

interface DocxViewerProps {
    docxFile: File;
}

const DocxViewer: React.FC<DocxViewerProps> = ({ docxFile }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        // Create a sample Blob object (replace this with your actual Blob)
        const docData = new Blob([docxFile], { type: "text/html" });

        // Render the document asynchronously when the component mounts
        if (containerRef.current) {
            renderAsync(docData, containerRef.current)
                .then(() => console.log("docx: finished"))
                .catch((error) => console.error("docx: error", error));
        }
        // Clean up the Blob object when the component unmounts
        return () => URL.revokeObjectURL("");
    }, []); // Run this effect only once when the component mounts

    return (
        <>
            <div>
                <WordDocWrapper ref={containerRef}></WordDocWrapper>
            </div>
        </>
    );
};

export default DocxViewer;
