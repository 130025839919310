import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "../Helpers/BaseEndpoint";
import { InitViewModel } from "./InitViewModel";
import { AccountStatus } from "@shoothill/core";
import { AppSettings, InitialState } from "../Models";
import { AppUrls } from "AppUrls";

class Response {
    public appInsightsInstrumentationKey: string = "";
    public accountStatus: AccountStatusResponse = new AccountStatusResponse();
    public appSettings: AppSettingsResponse = new AppSettingsResponse();
    public errors: string[] = [];
}

class AppSettingsResponse extends AppSettings {}

class AccountStatusResponse {
    public id?: string = "";
    public displayName: string = "";
    public isLoggedIn: boolean = false;
    public permissions?: string[] = [];
    public jwt: string | undefined = "";
    public signalRAccessToken: string = "";
}

export class InitStateEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: InitViewModel;
    constructor(initViewModel: InitViewModel) {
        super();
        this.viewModel = initViewModel;
        this.verb(Http.Get);
        this.path(AppUrls.Server.InitialState.Get);
    }

    public async HandleResponseAsync(response: Response): Promise<InitialState> {
        Logger.logInformation("Response", response);
        let initialState = {} as InitialState;
        initialState.appInsightsInstrumentationKey = response.appInsightsInstrumentationKey;

        let accountStatus: AccountStatus = {} as AccountStatus;
        accountStatus.id = response.accountStatus.id;
        accountStatus.displayName = response.accountStatus.displayName;
        accountStatus.isLoggedIn = response.accountStatus.isLoggedIn;
        accountStatus.permissions = response.accountStatus.permissions;
        accountStatus.jwt = response.accountStatus.jwt;
        accountStatus.signalRAccessToken = response.accountStatus.signalRAccessToken;
        initialState.accountStatus = accountStatus;

        let appSettings: AppSettings = {} as AppSettings;
        appSettings.apiUrl = response.appSettings.apiUrl;
        appSettings.conditionReportsOnly = response.appSettings.conditionReportsOnly;
        appSettings.imageMinName = response.appSettings.imageMinName;
        appSettings.maxImageWidthHeight = response.appSettings.maxImageWidthHeight;

        initialState.appSettings = appSettings;

        initialState.errors = response.errors;

        this.viewModel.InitData = initialState;
        this.viewModel.DataReady = true;
        return initialState;
    }
}
