import { Box } from "Application";
import styled from "@emotion/styled";

export const ButtonUnderBoxDocUploader = styled(Box)`
    margin-top: -15px;
`;

export const PreviewButtonImg = styled.img`
    cursor: pointer;
`;

export const ExcelTableWrapper = styled.div`
    table,
    th,
    td {
        border-collapse: collapse;
        border: 1px solid lightgray;
    }

    td {
        padding: 0px 5px;
    }
`;

export const DocPreviewPaddingBox = styled(Box)``;

export const DocPreviewBox = styled.div`
    padding: 6px;
    background-color: white;
    border: 6px solid lightgray;
    flex: 1;
    overflow: hidden;

    #react-doc-viewer {
        max-width: 100%;
        overflow-x: hidden;
    }

    #proxy-renderer {
        max-width: 100%;
        overflow-x: hidden;
    }

    #proxy-renderer iframe {
        max-width: 100%;
        width: 100%;
    }

    #header-bar {
        display: none;
    }

    #pdf-renderer {
        overflow-x: auto;
        max-width: 100%;

        .pdf-page {
            max-width: 100%;
            margin: 0 auto;
        }
    }
`;
