import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConditionReportTemplateSection } from "Views/ConditionReport/Setup/AddEdit/ConditionReportTemplateSection";

export interface ConditionReportTemplate {
    id: string | null;
    version: number;
    jsonVersionMin: number;
    jsonVersionMax: number;
    createdDate: string;
    createdBy: string;
    createdByName: string;
    isDeleted: boolean;
    deletedDate: string | null;
    deletedBy: string | null;
    deletedByName: string;
    lastUpdateDate: string | null;
    lastUpdatedBy: string | null;
    lastUpdatedByName: string;
    stateId: number;
    conditionReportTemplateSourceId: number;
    concurrencyToken: string;
    templateNumber: string;
    sections: ConditionReportTemplateSection[];
    css: string;
    source: any;
}

export class GetTemplateByIdResponse {
    public template: ConditionReportTemplate | undefined = undefined;

    public constructor() {}
}

export class GetTemplateByIdEndpoint extends EndpointWithoutRequest<GetTemplateByIdResponse> {
    constructor(id: string) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.ConditionReportTemplate.GetConditionReportTemplateById.replace("{:templateId}", id));
    }

    public async HandleResponseAsync(response: GetTemplateByIdResponse): Promise<ConditionReportTemplate | undefined> {
        Logger.logInformation("Response", response);

        //Return your model
        return response.template;
    }
}
