import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ApiResult } from "@shoothill/core";
import { CustomerContactsModel } from "../NewCustomer/CustomerContacts/CustomerContactsModel";
import { AppUrls } from "AppUrls";

class UpsertContactRequest {
    id: Guid | null = null;
    firstName: string = "";
    lastName: string = "";
    phone: string = "";
    phone2: string = "";
    email: string = "";
    jobTitle: string = "";
    status: number = 0;
    primaryContact: boolean = false;
    marketingConsent: boolean = true;
    plygeneTrained: boolean = false;
    datePlygeneTrained: Date | null = null;
    coatingsTrained: boolean = false;
    dateCoatingsTrained: Date | null = null;

    coatingsCPD: boolean = false;
    plygeneCPD: boolean = false;
    dateCoatingsCPD: Date | null = null;
    datePlygeneCPD: Date | null = null;

    note: string = "";
    customerId: Guid = "";
    isDeleted: boolean = false;
}

class UpsertContactResponse {}

export class UpsertContactEndpoint extends Endpoint<UpsertContactRequest, ApiResult> {
    private model: CustomerContactsModel;
    constructor(model: CustomerContactsModel) {
        super();
        this.model = model;
        this.verb(Http.Put);
        this.path(AppUrls.Server.Customer.UpsertContact);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new UpsertContactRequest();
        request.id = this.model.id;
        request.firstName = this.model.firstName;
        request.lastName = this.model.lastName;
        request.phone = this.model.phone;
        request.phone2 = this.model.phone2;
        request.email = this.model.email;
        request.jobTitle = this.model.jobTitle;
        request.status = this.model.status;
        request.primaryContact = this.model.primaryContact;
        request.marketingConsent = this.model.marketingConsent;
        request.plygeneTrained = this.model.plygeneTrained;
        request.datePlygeneTrained = this.model.datePlygeneTrained;
        request.coatingsTrained = this.model.coatingsTrained;
        request.dateCoatingsTrained = this.model.dateCoatingsTrained;

        request.plygeneCPD = this.model.plygeneCPD;
        request.datePlygeneCPD = this.model.datePlygeneCPD;
        request.coatingsCPD = this.model.coatingsCPD;
        request.dateCoatingsCPD = this.model.dateCoatingsCPD;

        request.note = this.model.note;
        request.customerId = this.model.customerId;
        request.isDeleted = this.model.isDeleted;
        return request;
    }

    public async HandleResponseAsync(response: UpsertContactResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
