import { Box } from "Application";
import styled from "@emotion/styled";

export const ConditionReportWrapper = styled(Box)`
    display: flex;
    flex: 1 0 0;
    width: 100%;
    padding: 15px;
    margin-top: 30px;
`;

export const ConditionReportHeader = styled(Box)`
    display: flex;
    flex: 1 0 0;
    width: 100%;
    background-color: rgb(212, 212, 212);
    color: black;
    justify-content: left;
    padding: 15px;
    margin-bottom: 5px;
`;

export const ConditionReportSubHeader = styled(Box)`
    display: flex;
    flex: 1 0 0;
    width: 100%;
    background-color: rgb(212, 212, 212);
    color: black;
    justify-content: left;
    padding: 15px;
    margin-bottom: 5px;
`;

export const ConditionReportPageWrapper = styled(Box)`
    padding-left: 15px;
    margin-bottom: 20px;

    .imgpreview {
        max-width: 480px;
        padding: 8px;
    }
`;

export const QuestionAnswerRow = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 15px;
`;

export const QuestionAnswerCell = styled(Box)`
    &.question {
        font-weight: 700;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;

        .comment {
            display: flex;
            flex: 1 0 0;
            flex-direction: row;
            justify-content: flex-start;
            word-wrap: break-word;
            .arrow {
                font-size: 18px;
                max-width: 30px;
                padding: 0 30px;
            }
        }
    }

    &.answer {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
    }

    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: flex-start;
    vertical-align: middle;
    line-height: 30px;

    font-weight: 300;
`;
