import styled from "@emotion/styled";
import { EditSelect, theme } from "Application";

export const CustomerStatusSelect = styled(EditSelect)`
    margin-top: -5px;

    .editselect__placeholder {
        font-size: ${theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize};
        line-height: 40px;
    }

    .editselect__control {
        /* 
        background-color: yellow;*/
        border: none !important;
        width: 200px;
        padding-top: 10px;
    }

    .input {
        display: none;
    }

    .editselect__indicator-separator {
        display: none;
    }

    .editselect__dropdown-indicator {
        margin-left: -35px;
        width: 16px;
        height: 16px;
        background-color: white !important;
        border-radius: 8px;
        padding: 0;
        z-index: 10;
        position: relative;
    }

    .status-dropdown {
        position: absolute;
        left: 3px;
        top: 6px;
        width: 9px;
    }

    .option-label {
    }
`;

export const CustomerStatusBox = styled.div`
    /* width: 98px; */
    width: 100%;
    height: 20px;
    border-radius: 10px;
    justify-content: middle;
    text-align: center;

    > div {
        width: 100%;
        line-height: 20px;
    }

    &.customerstatus-active {
        background-color: ${theme.palette.customerStatus.active.main};
        color: ${theme.palette.customerStatus.active.text};
    }

    &.customerstatus-dissolved {
        background-color: ${theme.palette.customerStatus.dissolved.main};
        color: ${theme.palette.customerStatus.dissolved.text};
    }

    &.customerstatus-inactive {
        background-color: ${theme.palette.customerStatus.inactive.main};
        color: ${theme.palette.customerStatus.inactive.text};
    }
`;
