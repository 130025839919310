import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ApiResult } from "@shoothill/core";
import { AppUrls } from "AppUrls";

class GetOccupierByIdForModalRequest {
    id: Guid = "";
}

export class GetOccupierByIdForModalEndpoint extends Endpoint<GetOccupierByIdForModalRequest, ApiResult> {
    public id: Guid = "";
    constructor(id: string) {
        super();
        this.id = id;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.GetOccupierByIdForModal);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new GetOccupierByIdForModalRequest();
        request.id = this.id;
        return request;
    }

    public async HandleResponseAsync(response: ApiResult): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
