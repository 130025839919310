import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TSMNotesModalViewModel } from "../TSMNotesModalViewModel";
import { TSMProjectDetails } from "../../ModalCommon/TSMModalCommon";

export class TSMNotesGetListByIdResponse {
    public tsmNoteList: TsmNoteListItem[] = [];
    public projectDetails: TSMProjectDetails = new TSMProjectDetails();
}

export class TSMNotesGetListByIdEndpoint extends EndpointWithoutRequest<TSMNotesGetListByIdResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Tsm.GetNotesListById.replace(":id", String(id)));
    }

    public async HandleResponseAsync(response: TSMNotesGetListByIdResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}

export interface TsmNoteListItem {
    id: number;
    createdDate: Date;
    note: string;
    author: string;
    rowVersion: string;
}
