import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";
import { ExcelViewer } from "Components/DocumentUploader/OtherFileTypes/ExcelViewer";
import DocxViewer from "Components/DocumentUploader/OtherFileTypes/WordViewer";
import { Container, DocViewerContainer } from "./DocumentViewerView.styles";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { LoadingSpinner } from "Components/LoadingSpinner";
import { DocumentViewerViewModel } from "./DocumentViewerViewModel";

interface IDocumentViewerViewProps {
    documentToView: IDocumentUploaded;
}

export const DocumentViewerView: React.FC<IDocumentViewerViewProps> = observer(({ documentToView }) => {
    const [viewModel] = useState(new DocumentViewerViewModel());

    useEffect(() => {
        viewModel.initialize(documentToView);
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const renderDocViewer = () => {
        if (!viewModel.canShowDocViewer) return;
        const file: File = viewModel.model.file!;

        switch (viewModel.activeDocViewer) {
            case "excel":
                return <ExcelViewer file={file} />;
            case "docx":
                return <DocxViewer docxFile={file} />;
            default: {
                const document: IDocument = {
                    uri: window.URL.createObjectURL(file),
                    fileName: file.name,
                    fileType: file.type,
                };
                return (
                    <DocViewer
                        documents={[document]}
                        pluginRenderers={DocViewerRenderers}
                        config={{
                            header: {
                                disableHeader: false,
                                disableFileName: true,
                                retainURLParams: true,
                            },
                            pdfZoom: {
                                defaultZoom: 1.0,
                                zoomJump: 0.2,
                            },
                            pdfVerticalScrollByDefault: true,
                            loadingRenderer: { showLoadingTimeout: false },
                            noRenderer: {
                                overrideComponent: ({ fileName }) => <div>No preview available for {fileName}</div>,
                            },
                        }}
                    />
                );
            }
        }
    };

    return (
        <Container>
            {viewModel.showLoading && <LoadingSpinner message="Loading document..." />}
            {!viewModel.showLoading && <DocViewerContainer>{renderDocViewer()}</DocViewerContainer>}
        </Container>
    );
});
