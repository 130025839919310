import { action, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { BlankModel } from "Application/Models/Domain/BlankModel";
import { GetPdfHtmlEndpoint } from "../Endpoints/GetPDFHtmlEndpoint";
import { GetPdfForViewingByIdEndpoint } from "../Endpoints/GetPDFForViewingByIdEndpoint";

export class ShowPDFModalViewModel extends ViewModelBase<BlankModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public html: string = "";
    public isProcessing: boolean = false;

    public pdfData: any = null;

    constructor() {
        super(new BlankModel());
        this.apiClient.setAPITimeout(240000);
        makeObservable(this, { html: observable, isProcessing: observable, pdfData: observable, loadPDF: action, getPDFHtml: action, clear: action });
    }

    public clear = () => {
        this.html = "";
        this.isProcessing = false;
    };

    public getPDFHtml = async (id: any): Promise<string> => {
        let retVal: string = "";
        this.isProcessing = true;
        let endpoint: GetPdfHtmlEndpoint = new GetPdfHtmlEndpoint(id);
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful === true) {
            retVal = this.apiClient.Response<string>();
            runInAction(() => {
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Condition Report");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to download the pdf html.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }

        return retVal;
    };

    public loadPDF = async (id: any) => {
        let retVal: any = null;
        this.isProcessing = true;
        let endpoint: GetPdfForViewingByIdEndpoint = new GetPdfForViewingByIdEndpoint(id);
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful === true) {
            retVal = this.apiClient.Response<any>();
            runInAction(() => {
                this.pdfData = retVal;
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Condition Report");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to download the pdf html.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }

        return retVal;
    };
}
