import { lastIndexOf } from "lodash-es";
import { action, makeObservable, observable } from "mobx";

export class ConditionReportListInformation {
    public id: number | null = null;
    public conditionReport: string | null = null;
    public dateOfReport: string | null = null;
    public sharmansRep: string = "";
    public howGenerated: string = "";
    public companyName: string = "";
    public clientRepresentative: string = "";
    public projectName: string = "";
    public siteAddress: string = "";

    constructor() {
        makeObservable(this, {
            id: observable,
            conditionReport: observable,
            dateOfReport: observable,
            sharmansRep: observable,
            howGenerated: observable,
            companyName: observable,
            clientRepresentative: observable,
            projectName: observable,
            siteAddress: observable,
            load: action,
            clear: action,
        });
    }

    public load(listInformation: ConditionReportListInformation | undefined) {
        this.clear();

        if (listInformation !== null && listInformation !== undefined) {
            this.id = listInformation.id;
            this.conditionReport = listInformation.conditionReport;
            this.dateOfReport = listInformation.dateOfReport;
            this.sharmansRep = listInformation.sharmansRep;
            this.howGenerated = listInformation.howGenerated;
            this.companyName = listInformation.companyName;
            this.clientRepresentative = listInformation.clientRepresentative;
            this.projectName = listInformation.projectName;
            this.siteAddress = listInformation.siteAddress;
        }
    }

    public clear = () => {
        this.id = null;
        this.conditionReport = null;
        this.dateOfReport = null;
        this.sharmansRep = "";
        this.howGenerated = "";
        this.companyName = "";
        this.clientRepresentative = "";
        this.projectName = "";
        this.siteAddress = "";
    };
}
