import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate } from "react-router";
import { Header } from "Views/Header/Header";
import { setPageTitle } from "Application";
import { FallbackPageView } from "Components/FallbackPage";
import { ComplaintAddEditView } from "./Complaints/AddEdit/ComplaintAddEditView";
import { ComplaintsRoutes } from "./Complaints/ComplaintsRoutes";

export const AftersalesRoutes: React.FC = observer(() => {
    useEffect(() => {
        setPageTitle("Aftersales");
    }, []);

    return (
        <>
            <Header title="Aftersales" />

            <Routes>
                <Route path="*" element={<Navigate to="complaints" replace />} />
                <Route path={"complaints/*"} element={<ComplaintsRoutes />} />

                <Route
                    path={"addedit/:complainttype/:projectid/:complaintid"} /* No tab pages so don't need the * */
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <ComplaintAddEditView />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"addedit/:complainttype/:projectid/:complaintid/aftersales"} /* No tab pages so don't need the * */
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <ComplaintAddEditView />
                        </React.Suspense>
                    }
                />
            </Routes>
        </>
    );
});
