import { Box } from "Application";
import styled from "@emotion/styled";

export const HRDashedGrey = styled.hr`
    border: 1px dashed #d4d4d4;
    margin: 15px 0px 12px 0px;
`;

export const HRDashedGreyNoBottom = styled.hr`
    border: 1px dashed #d4d4d4;
    margin: 15px 0px 0px 0px;
`;

export const HRSolidGrey = styled.hr`
    border: 1px solid #d4d4d4;
`;
