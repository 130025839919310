import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, theme } from "Application";
import styled from "@emotion/styled";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { NavLink, useLocation } from "react-router-dom";
import clsx from "clsx";

//Images
import Arrow from "Assets/Icons/SideMenuArrow.svg";
import { useNavigate } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { AccountStore } from "Stores/Domain";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";

export const SideBarMenu: React.FC = observer(() => {
    const domainStore = container.resolve(DomainStore);
    const accountStore = container.resolve(AccountStore);
    const [isLogoutOpen, setIsLogoutOpen] = useState<boolean>(false);

    const isAdmin = () => accountStore.isInRole("ADMIN");
    const isCRUserOrAdmin = () => accountStore.isCRUserOrAdmin();
    const isTSMOrAdmin = () => accountStore.isTSMOrAdmin();
    const isCRMUserORAdmin = () => accountStore.isCRMUserOrAdmin();
    const isCRUserOrCRMUserOrAdmin = () => accountStore.isCRUserOrCRMUserOrAdmin();

    const location = useLocation();

    let navigate = useNavigate();

    const onClick = (path: string) => {
        domainStore.clearPathHistory();
        navigate(path);
        // domainStore.setSideBarClosed(true);
    };

    const closeLogoutModal = () => {
        setIsLogoutOpen(false);
    };

    const openConfirmLogoutModal = () => {
        onClick(AppUrls.Client.Account.Logout);
    };

    return (
        <SideBarMenuContainer style={{ left: domainStore.isSideBarClosed ? "-125px" : "0px", transition: "left 0.5s" }}>
            <SideBarMenuInnerWrapper>
                {accountStore.IsLoggedIn && isCRMUserORAdmin() && (
                    <>
                        <Box className={location.pathname.startsWith("/dashboard/") ? "selected" : ""}>
                            <MenuItemHeading mt="60px" onClick={() => onClick(AppUrls.Client.Dashboard.Default[AppUrlIndex.Long])}>
                                Dashboard
                            </MenuItemHeading>
                            <NavLink
                                to={AppUrls.Client.Dashboard.Tasks[AppUrlIndex.Long]}
                                className={(navData) => clsx("root", navData.isActive ? "current" : "default")}
                                onClick={() => domainStore.clearPathHistory()}
                            >
                                <SubHeadingWrapper p="0 10px 15px 10px">
                                    <img src={Arrow}></img>
                                    <MenuItemSubHeading>Tasks</MenuItemSubHeading>
                                </SubHeadingWrapper>
                            </NavLink>
                        </Box>
                        {/* <MenuItemHeading mb="25px" onClick={() => onClick(AppUrls.Client.Events.Default[AppUrlIndex.Long])}>
                                Events
                            </MenuItemHeading> */}

                        <Box className={location.pathname.startsWith("/directory/") ? "selected" : ""}>
                            <MenuItemHeading mt="25px" onClick={() => onClick(AppUrls.Client.Directory.Default[AppUrlIndex.Long])}>
                                Directory
                            </MenuItemHeading>
                            <NavLink
                                to={AppUrls.Client.Directory.Customers[AppUrlIndex.Long]}
                                className={(navData) => clsx("root", navData.isActive ? "current" : "default")}
                                onClick={() => domainStore.clearPathHistory()}
                            >
                                <SubHeadingWrapper>
                                    <img src={Arrow}></img>
                                    <MenuItemSubHeading>Customers</MenuItemSubHeading>
                                </SubHeadingWrapper>
                            </NavLink>
                            <NavLink
                                to={AppUrls.Client.Directory.Contacts[AppUrlIndex.Long]}
                                className={(navData) => clsx("root", navData.isActive ? "current" : "default")}
                                onClick={() => domainStore.clearPathHistory()}
                            >
                                <SubHeadingWrapper>
                                    <img src={Arrow}></img>
                                    <MenuItemSubHeading>Contacts</MenuItemSubHeading>
                                </SubHeadingWrapper>
                            </NavLink>
                            {/* <SubHeadingWrapper>
                                <img src={Arrow}></img>
                                <MenuItemSubHeading onClick={() => onClick(AppUrls.Client.Directory.Clients[AppUrlIndex.Long])}>Clients</MenuItemSubHeading>
                            </SubHeadingWrapper> */}
                            <NavLink
                                to={AppUrls.Client.Directory.Properties[AppUrlIndex.Long]}
                                className={(navData) => clsx("root", navData.isActive ? "current" : "default")}
                                onClick={() => domainStore.clearPathHistory()}
                            >
                                <SubHeadingWrapper mb="15px">
                                    <img src={Arrow}></img>
                                    <MenuItemSubHeading>Properties</MenuItemSubHeading>
                                </SubHeadingWrapper>
                            </NavLink>
                        </Box>

                        <Box className={location.pathname.startsWith("/work/") ? "selected" : ""}>
                            <MenuItemHeading onClick={() => onClick(AppUrls.Client.Work.Default[AppUrlIndex.Long])}>Work</MenuItemHeading>
                            <NavLink
                                to={AppUrls.Client.Work.Multi.Default[AppUrlIndex.Long]}
                                className={(navData) => clsx("root", navData.isActive ? "current" : "default")}
                                onClick={() => domainStore.clearPathHistory()}
                            >
                                <SubHeadingWrapper>
                                    <img src={Arrow}></img>
                                    <MenuItemSubHeading>Multi</MenuItemSubHeading>
                                </SubHeadingWrapper>
                            </NavLink>
                            <NavLink
                                to={AppUrls.Client.Work.Master.Default[AppUrlIndex.Long]}
                                className={(navData) => clsx("root", navData.isActive ? "current" : "default")}
                                onClick={() => domainStore.clearPathHistory()}
                            >
                                <SubHeadingWrapper>
                                    <img src={Arrow}></img>
                                    <MenuItemSubHeading>Master</MenuItemSubHeading>
                                </SubHeadingWrapper>
                            </NavLink>
                            <NavLink
                                to={AppUrls.Client.Work.Quotes.Default[AppUrlIndex.Long]}
                                className={(navData) => clsx("root", navData.isActive ? "current" : "default")}
                                onClick={() => domainStore.clearPathHistory()}
                            >
                                <SubHeadingWrapper mb="15px">
                                    <img src={Arrow}></img>
                                    <MenuItemSubHeading>Project</MenuItemSubHeading>
                                </SubHeadingWrapper>
                            </NavLink>
                        </Box>
                    </>
                )}

                {accountStore.IsLoggedIn && isTSMOrAdmin() && (
                    <NavLink
                        to={AppUrls.Client.TSM.Tsm[AppUrlIndex.Long]}
                        className={(navData) => clsx("root", navData.isActive ? "currentMainHeading" : "default")}
                        onClick={() => domainStore.clearPathHistory()}
                    >
                        <MenuItemHeading>TSM</MenuItemHeading>
                    </NavLink>
                )}

                {accountStore.IsLoggedIn && isCRMUserORAdmin() && (
                    <Box className={location.pathname.startsWith("/aftersales/") ? "selected" : ""}>
                        <MenuItemHeading onClick={() => onClick(AppUrls.Client.Aftersales.Complaints[AppUrlIndex.Long] + "/list/complaints")}>Aftersales</MenuItemHeading>
                        <NavLink
                            // to={AppUrls.Client.Aftersales.Complaints[AppUrlIndex.Long] + "/list/complaints"}
                            to={AppUrls.Client.Aftersales.Complaints[AppUrlIndex.Long]}
                            className={(navData) => clsx("root", navData.isActive ? "current" : "default")}
                            onClick={() => domainStore.clearPathHistory()}
                        >
                            <SubHeadingWrapper mb="15px">
                                <img src={Arrow}></img>
                                <MenuItemSubHeading>Complaints</MenuItemSubHeading>
                            </SubHeadingWrapper>
                        </NavLink>
                    </Box>
                )}

                {accountStore.IsLoggedIn && isCRUserOrCRMUserOrAdmin() && (
                    <NavLink
                        to={AppUrls.Client.Reports.Reports[AppUrlIndex.Long]}
                        className={(navData) => clsx("root", navData.isActive ? "currentMainHeading" : "default")}
                        onClick={() => domainStore.clearPathHistory()}
                    >
                        <MenuItemHeading>Reports</MenuItemHeading>
                    </NavLink>
                )}

                {accountStore.IsLoggedIn && isAdmin() && (
                    <NavLink
                        to={AppUrls.Client.Admin.AdminHome}
                        className={(navData) => clsx("root", navData.isActive ? "currentMainHeading" : "default")}
                        onClick={() => domainStore.clearPathHistory()}
                    >
                        <MenuItemHeading>Admin</MenuItemHeading>
                    </NavLink>
                )}

                {accountStore.IsLoggedIn && isCRUserOrAdmin() && (
                    <NavLink
                        to={AppUrls.Client.ConditionReport.List[AppUrlIndex.Long]}
                        className={(navData) => clsx("root", navData.isActive ? "currentMainHeading" : "default")}
                        onClick={() => domainStore.clearPathHistory()}
                    >
                        <MenuItemHeading>Condition report</MenuItemHeading>
                    </NavLink>
                )}

                {isLogoutOpen === true && (
                    <AreYouSureModal
                        title="Logout"
                        message="Are you sure you want to logout?"
                        isOpen={isLogoutOpen}
                        onCancel={closeLogoutModal}
                        onConfirm={openConfirmLogoutModal}
                    />
                )}

                <MenuItemHeading
                    mt="30px"
                    onClick={() => {
                        setIsLogoutOpen(true);
                    }}
                    mb="20px"
                >
                    Log out
                </MenuItemHeading>
            </SideBarMenuInnerWrapper>
        </SideBarMenuContainer>
    );
});

const SideBarMenuContainer = styled(Box)`
    top: 0;
    left: -125px;
    bottom: 0;
    width: 125px;
    height: 100%;
    position: fixed;
    height: 100vh;
    background-color: #d4d4d4;
    transition: left 0.5s;
`;
const SideBarMenuInnerWrapper = styled(Box)`
    /* padding: 30px 10px; */
    .current {
        div {
            background-color: #fcfcfc;
        }
    }

    .currentMainHeading {
        h5 {
            background-color: #fcfcfc;
        }
    }
    a {
        text-decoration: none !important;
    }

    .selected {
        background-color: #e0e0e0;
    }
`;

const MenuItemHeading = styled(P)`
    padding: 5px 10px;
    cursor: pointer;
    font-size: ${theme.fontStyles.sidebarMain.fontSize};
    font-weight: ${theme.fontStyles.sidebarMain.fontWeight};
`;

const MenuItemSubHeading = styled.h5`
    font-size: ${theme.fontStyles.sidebarSecondary.fontSize};
    cursor: pointer;
    margin-left: 5px;
    color: black;
    &:hover {
    }
`;

const SubHeadingWrapper = styled(Box)`
    display: flex;
    padding: 5px 10px 5px 10px;

    &.active {
        background-color: #fcfcfc;
    }
`;
