import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { EditDate, ICommand, RadioButton, RelayCommand, ThemedLoader } from "Application";
import { ComplaintDateWrapper, ComplaintsListWrapper, ComplaintsReportRadio, ComplaintsReportRow, ComplaintsReportWrapper, LeftDatePicker } from "../ComplaintsList.styles";
import { H2, H3 } from "Components/Primitives/TextElements/TextElements";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { ComplaintsViewModel } from "./ComplaintsReportViewModel";
import { useNavigate } from "react-router";
import { HRDashedGreyNoBottom } from "Components/HorizontalRules";
import { DatePickerBox } from "Components/Primitives/DatePickerBox";

export const ComplaintsReportView: React.FC = observer(() => {
    const [viewModel] = useState(new ComplaintsViewModel());
    let navigate = useNavigate();

    const handlePeriodSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadComplaintsBetweenDates();
        }
    });

    const handleCategorySubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Download) {
            let promise = viewModel.downloadComplaintWithCategoryCSV();
        }
    });

    return (
        <>
            {viewModel.isProcessing ? (
                <ThemedLoader isOpen={true} spinnerText="Downloading..." delayBeforeOpen={200} />
            ) : (
                <ComplaintsListWrapper>
                    <H2 pb="15px">Complaint Reports</H2>
                    <p></p>
                    <HRDashedGreyNoBottom />
                    <ComplaintsReportWrapper>
                        <SubHeaderView relativePosition={true} buttonClick={handlePeriodSubHeadingButtonClicks} downloadButtonText="Download Period Complaint PDF" />
                        <H3 pb="15px" mt="15px">
                            Complaint Period Report
                        </H3>
                        <ComplaintsReportRow>
                            <ComplaintDateWrapper>
                                <LeftDatePicker>
                                    <DatePickerBox>
                                        <EditDate
                                            command={viewModel.setReportDateFrom}
                                            value={viewModel.getReportDateFrom}
                                            displayName="Generate report from"
                                            style={{ marginRight: "10px" }}
                                            includeTime={false}
                                        />
                                    </DatePickerBox>
                                </LeftDatePicker>
                                <LeftDatePicker>
                                    <DatePickerBox>
                                        <EditDate
                                            command={viewModel.setReportDateTo}
                                            value={viewModel.getReportDateTo}
                                            displayName="Generate report to"
                                            style={{ marginRight: "10px" }}
                                            includeTime={false}
                                        />
                                    </DatePickerBox>
                                </LeftDatePicker>
                            </ComplaintDateWrapper>
                            <ComplaintsReportRadio>
                                <RadioButton
                                    key="crrrb"
                                    className="complaintRadioButton"
                                    horizontal={false}
                                    command={viewModel.setReportComplaintStatus}
                                    displayName="Complaint Status:"
                                    options={[
                                        { key: "1", text: "Unresolved" },
                                        { key: "2", text: "Resolved" },
                                        { key: "3", text: "Both" },
                                    ]}
                                    value={() => viewModel.getReportComplaintStatus}
                                    uniqueId="reportComplaintStatus"
                                />
                            </ComplaintsReportRadio>
                        </ComplaintsReportRow>
                    </ComplaintsReportWrapper>
                    <HRDashedGreyNoBottom />
                    <ComplaintsReportWrapper>
                        <SubHeaderView relativePosition={true} buttonClick={handleCategorySubHeadingButtonClicks} downloadButtonText="Download Complaint Category CSV" />
                        <H3 pb="15px" mt="15px">
                            Complaint Category Report
                        </H3>
                    </ComplaintsReportWrapper>
                </ComplaintsListWrapper>
            )}
        </>
    );
});
