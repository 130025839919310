import { BaseStore } from "@shoothill/core";
import { singleton } from "tsyringe";
import { action, computed, makeObservable, observable, runInAction, IObservableArray } from "mobx";
import { APIClient } from "Application/Helpers";
import { CustomerOptionMatrixModel } from "Models/Customer/CustomerOptionMatrix";
import { GetCustomerMatrixEndpoint } from "Endpoint/GetCustomerMatrixEndpoint";

@singleton()
export class CustomerStore extends BaseStore {
    public matrixOptions: IObservableArray<CustomerOptionMatrixModel> = observable([]);
    private apiClient = new APIClient();

    public DefaultCustomerTypeId: number = 1; // 1 = Contractor

    public dataLoaded: boolean = false;

    public constructor() {
        super();
        makeObservable(this, {
            dataLoaded: observable,

            clearMatrixOptions: action,
            matrixOptions: observable,
            isDataLoaded: computed,
        });

        // const _ = this.doAsync();
    }

    public doAsync = async () => {
        if (this.matrixOptions.slice().length === 0) {
            let _ = await this.apiClient.sendAsync(new GetCustomerMatrixEndpoint());
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.matrixOptions.replace(this.apiClient.Response<CustomerOptionMatrixModel[]>());
                    this.dataLoaded = true;
                });
            } else {
                runInAction(() => {
                    this.dataLoaded = false;
                    const errorMsg = this.apiClient.ValidationMessage;
                });
            }
        }
    };

    ///
    // clearMatrixOptions
    // should only call when adding a new Customer Type (in the future)
    ///
    public clearMatrixOptions = () => {
        this.matrixOptions.clear();
        this.dataLoaded = false;
    };

    public get isMatrixOptions(): CustomerOptionMatrixModel[] {
        return this.matrixOptions.slice();
    }

    public getMatrixOptionsForCustomerType(customerType: number): CustomerOptionMatrixModel | undefined {
        if (this.dataLoaded === true && this.matrixOptions.length > 0 && customerType > 0) {
            const retVal = this.matrixOptions.slice().filter((a) => a.customerTypeId === customerType);
            if (retVal.length > 0) {
                return retVal[0];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    public get isDataLoaded(): boolean {
        return this.dataLoaded;
    }

    public getCustomerTypeText(customerType: number): string {
        if (this.dataLoaded === true && this.matrixOptions.length > 0 && customerType > 0) {
            const retVal = this.matrixOptions.slice().filter((a) => a.customerTypeId === customerType);
            if (retVal.length > 0) {
                return retVal[0].customerType;
            } else {
                return "Unknown";
            }
        } else {
            return "Unknown";
        }
    }
}
