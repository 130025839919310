import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConditionReportPdfAndRelated } from "../Edit/ConditionReportPdfModel";

export class GetPdfByIdRequest {
    public id: string = "";

    public constructor() {
        // Constructor
    }
}

export class GetPdfByIdEndpoint extends Endpoint<GetPdfByIdRequest, ConditionReportPdfAndRelated> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportPdf.GetPdfById);
    }

    public async HandleRequestAsync(request: GetPdfByIdRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: ConditionReportPdfAndRelated): Promise<ConditionReportPdfAndRelated> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
