// Importing necessary React hooks and the React component itself
import React, { useEffect, useState } from "react";

// Importing observer from mobx-react-lite for state management observation
import { observer } from "mobx-react-lite";

// Importing UI components and command interfaces from the Application module
import { Box, Button, ICommand, Input, RelayCommand } from "Application";

// Importing a styled paragraph component for text elements
import { P } from "../Primitives/TextElements/TextElements";

// Importing an icon asset for the magnifying glass
import spyScope from "Assets/Icons/magnifyingGlass.svg";

// Importing a styled container box for list items
import { ListItemContainerBox } from "./ListItemContainerBox";

// Importing a utility function for generating unique identifiers
import { generateID } from "@shoothill/core";
import { ColouredBox, ScrollBox, TopBox } from "./ScrollingListWithSearch.style";

import NewIcon from "Assets/Icons/newRoundButtonIcon.svg";

// Interface defining the shape of props for ScrollingListWithSearch component
export interface IScrollingListWithSearch {
    title?: string;
    searchInputPlaceholderText?: string;
    list: ScrollListItem[];
    showInfoCommand: ICommand;
    addItemCommand: ICommand;
    validationMessage?: string;
    addOrEdit?: boolean;

    boxClassName?: string;
    isShowButton?: boolean;
    openModelCommand?: ICommand;
}

// The main component for ScrollingListWithSearch, observed for reactive state changes
export const ScrollingListWithSearch: React.FC<IScrollingListWithSearch> = observer((props) => {
    // Destructuring props for easier access
    const { title, searchInputPlaceholderText, showInfoCommand, addItemCommand, validationMessage } = props;

    // useState hooks to manage the state of the updated list and the search string
    const [updatedList, setUpdatedList] = useState<ScrollListItem[]>([]);
    const [searchString, setSearchString] = useState("");

    // useEffect hook to perform side effects
    useEffect(() => {
        // Logic to be executed on component mount or when props.list changes
        // Uncomment the below line to set the updatedList based on props
        if (searchString.length > 2) {
            setUpdatedList(props.list.filter((item) => item.displayName.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())));
        } else if (searchString.length < 3) {
            setUpdatedList([]);
        }

        // Cleanup function to be executed when the component is unmounted
        return () => {
            // Perform any cleanup activities here
            // viewModel.clear();
        };
    }, [props.list]);

    // Command for handling search, triggering on input changes
    const searchCommand: ICommand = new RelayCommand((value: string) => {
        // Logic to filter list based on the search value
        setSearchString(value);
        if (value.length > 2) {
            setUpdatedList(props.list.filter((item) => item.displayName.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
        } else if (value.length < 3) {
            setUpdatedList([]);
        }
    });

    const inputPlaceholder = searchInputPlaceholderText || "Search";

    const listItemNameNormalized = (index: number) => {
        let rootName = "genericListItem-" + `${index}`;

        if (title) {
            rootName =
                title
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                    .join("") +
                "ListItem-" +
                `${index}`;
        }

        return rootName;
    };

    const openModelCommand: ICommand = new RelayCommand(() => {
        if (props.openModelCommand) {
            props.openModelCommand?.execute();
        }
    });

    // The render method for the component
    return (
        <>
            {/* Wrapping the component in a Box container */}
            <Box>
                {/* Title for the ScrollingListWithSearch component */}
                <P pb="10px">
                    <strong>{title}</strong>
                </P>
                {/* Search box and list container */}
                <ColouredBox className={props.boxClassName === undefined ? "" : props.boxClassName}>
                    <TopBox>
                        {/* Input field for search with attached command and icon */}
                        <Input command={searchCommand} placeholder={inputPlaceholder} prefix={<img src={spyScope} style={{ cursor: "pointer" }} />} value={() => searchString} />
                        {/* Validation message display, if any */}
                        <P color="error">{validationMessage}</P>
                    </TopBox>
                    {/* Container for the scrolling list */}
                    <ScrollBox>
                        {/* Mapping updatedList to render ListItemContainerBox components */}

                        {updatedList.length === 0 && searchString.length > 0 && props.isShowButton && (
                            <Button command={openModelCommand} displayName=" Add property" icon={<img src={NewIcon} />} boxShadow={true} paletteColor="ButtonBlue" />
                        )}

                        {updatedList.map((item, index) => {
                            return (
                                <ListItemContainerBox
                                    key={generateID()}
                                    item={item}
                                    infoCalloutTargetId={listItemNameNormalized(index)}
                                    addCommand={addItemCommand}
                                    showInfoCommand={showInfoCommand}
                                />
                            );
                        })}
                    </ScrollBox>
                </ColouredBox>
            </Box>
        </>
    );
});

// Interface defining the shape of items in the scrolling list
export interface ScrollListItem {
    id: string;
    displayName: string;
}
