import { ApiResult } from "@shoothill/core";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class GetPdfHtmlEndpoint extends EndpointWithoutRequest<string> {
    constructor(id: string) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.ConditionReportPdf.GetPdfHtml + `/${id}`);
        this.responseIsBlob(false);
        this.SetContentType("application/pdf");
    }

    public async HandleResponseAsync(response: string): Promise<string> {
        return response;
    }
}
