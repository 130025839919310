import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { TaskListItem } from "../TaskModel";
import { AppUrls } from "AppUrls";
import { SharmanTaskTypeEnum } from "Models/Tasks/TaskTypeEnum";
import { TaskViewModel } from "../TaskViewModel";
import { DefaultPageSize } from "Globals/GlobalSettings";

class GetTaskRequest {
    public sourceId: Guid = "";
    public taskTypeId: number = 0;
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public filterByCompleted: string[] = [];
}

class GetTasksListResponse {
    public taskList: TaskListItem[] = [];
    public taskCount: number = 0;
}

export class GetTaskListEndpoint extends Endpoint<GetTaskRequest, GetTasksListResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.GetAllTask);
    }

    public async HandleRequestAsync(viewModel: TaskViewModel): Promise<any> {
        let request = new GetTaskRequest();
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageNumber = viewModel.pageNumber;
        request.pageSize = viewModel.pageSize;
        request.sourceId = viewModel.customerId;
        request.taskTypeId = SharmanTaskTypeEnum.Customer;
        request.filterByCompleted = viewModel.filterByCompleted;
        return request;
    }

    public async HandleResponseAsync(response: GetTasksListResponse): Promise<any> {
        return response;
    }
}
