import { AppUrls } from "AppUrls";
import { Endpoint, Http } from "Application";
import { CustomerListItemModel } from "../List/CustomerListItemModel";
import { CustomersListViewModel } from "../List/CustomersListViewModel";
import { getFilenameFromContentDisposition } from "Utils/File";

class Request {
    orderBy: string = "";
    columnName: string = "";
    filterByType: number[] = [];
    filterByBdm: number[] = [];
    filterByTsm: number[] = [];
    filterByStatus: number[] = [];
    searchString: string = "";
    public initialLoad: boolean = true;
}

class Response {
    customerList: CustomerListItemModel[] = [];
}

export class DownloadCustomerCSVEndpoint extends Endpoint<Request, Response> {
    private viewModel: CustomersListViewModel;

    constructor(viewModel: CustomersListViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.DownloadCSV);
        this.responseIsBlob(true);
        this.SetContentType("text/csv");
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new Request();
        request.filterByBdm = [];
        request.filterByTsm = [];
        request.columnName = this.viewModel.columnName;
        request.orderBy = this.viewModel.orderBy;
        // request.userRolesForFiltering = this.viewModel.userRolesForFiltering;

        request.filterByType = this.viewModel.filterByType.map((item) => {
            return item;
        });

        request.filterByBdm = this.viewModel.filterByBdm.map((item) => {
            return parseInt(item);
        });

        request.filterByTsm = this.viewModel.filterByTsm.map((item) => {
            return parseInt(item);
        });

        request.filterByStatus = this.viewModel.filterByStatus.map((item) => {
            return parseInt(item);
        });
        request.initialLoad = this.viewModel.initialLoad;
        request.searchString = this.viewModel.searchString;
        return request;
    }

    public async HandleResponseAsync(response: any): Promise<boolean> {
        let retVal: boolean = true;
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        //link.href = window.URL.createObjectURL(new Blob([response]));
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        //Return your model
        return retVal;
    }
}
