import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { EditConditionTemplateSection, EditConditionTemplateSectionWrapper, SectionRow } from "./ConditionReportTemplateAddEdit.style";
import Bin from "Assets/Icons/delete.svg";

export const ItemTypes = {
    CARD: "section",
};

export interface IDraggableItem {
    id: string;
    text: string;
    isRepeatable: boolean;
    hasRepeatableElements: boolean;
}

export interface ISectionProps {
    id: string;
    text: string;
    index: number;
    hasRepeatableElmements: boolean;
    additionalClasses: string;
    showDelete?: boolean;
    moveCard: (dragIndex: number, hoverIndex: number, itemId: string) => void;
    onClickHandler: (id: string) => void;
    onRemoveSection: (id: string) => void;
}

interface IDragItem {
    index: number;
    id: string;
    type: string;
}

export const Section: FC<ISectionProps> = ({ id, text, hasRepeatableElmements, index, moveCard, onClickHandler, onRemoveSection, additionalClasses, showDelete }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<IDragItem, void, { handlerId: Identifier | null }>({
        accept: ItemTypes.CARD,
        options: {},
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: IDragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex, item.id);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.3 : 1;

    drag(drop(ref));

    let isRepeatableString: string = hasRepeatableElmements === false ? "" : "(Repeatable)";

    const showDeleteButton: boolean = showDelete === undefined ? true : showDelete;

    return (
        <EditConditionTemplateSection ref={ref} className={additionalClasses} style={{ opacity }} data-handler-id={handlerId}>
            <EditConditionTemplateSectionWrapper>
                <div onClick={(e: any) => onClickHandler(id)}>
                    <SectionRow>
                        <div>{isRepeatableString}&nbsp; &nbsp;</div>
                        {showDeleteButton === true && (
                            <div className="delete-button" onClick={(e: any) => onRemoveSection(id)}>
                                <img src={Bin} alt="Delete section" />
                            </div>
                        )}
                        {showDeleteButton === false && <div>&nbsp;</div>}
                    </SectionRow>
                    <SectionRow>
                        <div className="section-title">{text}</div>
                    </SectionRow>
                </div>
            </EditConditionTemplateSectionWrapper>
        </EditConditionTemplateSection>
    );
};
