import { makeObservable, action, computed, observable, runInAction, IObservableArray } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { PropertyProjectQuoteListItem } from "../Models/PropertyProjectQuoteListItem";
import { ProjectStatusEnumHelper } from "Models/Project/ProjectStatusEnum";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { IParentChildDropdownItem } from "Models/IParentChildDropdownItem";
import { PropertyLinkedProjectQuotesModel } from "./PropertyLinkedProjectQuotesModel";
import { GetLinkedProjectQuotesEndpoint, LinkedProjectQuotesResponse } from "./Endpoints/GetLinkedProjectQuotesProjectsEndpoint";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { DeletePropertyProjectQuoteByIdEndpoint } from "./Endpoints/DeletePropertyProjectQuoteByIdEndpoint";

export class ProjectQuoteViewModel extends ViewModelBase<PropertyLinkedProjectQuotesModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;
    public initialLoad: boolean = true;

    public projectQuotes: IObservableArray<PropertyProjectQuoteListItem> = observable.array([]);
    public projectProducts: IObservableArray<IParentChildDropdownItem> = observable.array([]);

    public statusDropdown: IObservableArray<KeyValuePairExtended> = observable(ProjectStatusEnumHelper.getAllProjectStatusOptions());
    public statusFilter: string[] = ProjectStatusEnumHelper.getAllProjectStatuses();

    public productFilter: string[] = [];

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "displayName";

    // Pagination
    public customerCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;
    public totalCount: number = 0;

    // Deleting property

    public areYouSureModelOpen: boolean = false;
    public propertyProjectQuoteToDeleteId: Guid = "";

    private projectQuoteCountCallBack: (newCount: number) => void;

    constructor(propertyId: string, projectQuoteCountCallBack: (newCount: number) => void) {
        super(new PropertyLinkedProjectQuotesModel());
        this.model.propertyId = propertyId!;
        this.projectQuoteCountCallBack = projectQuoteCountCallBack;
        this.productFilter = this.getAllProductFiltered();
        makeObservable(this, {
            productFilter: observable,
            statusFilter: observable,
            isProcessing: observable,
            pageSize: observable,
            pageNumber: observable,
            totalCount: observable,
            areYouSureModelOpen: observable,
            propertyProjectQuoteToDeleteId: observable,

            clear: action,
            setProjects: action,
            statusSelectAll: action,
            statusSelectNone: action,
            updateProductFiltering: action,
            updateStatusFiltering: action,
            confirmDeleteProperty: action,
            deletePropertyCheck: action,

            getProductTypesForDropdown: computed,
            getProductFilter: computed,
            getProjects: computed,
            getStatuses: computed,
            getStatusFilter: computed,
        });
    }

    public clear = () => {
        this.projectQuotes.clear();
        this.projectProducts.clear();
        this.isProcessing = false;
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.pageSize = 10;
        this.pageNumber = 1;
        this.totalCount = 0;
        this.propertyProjectQuoteToDeleteId = "";
    };

    private getAllProductFiltered = () => {
        return this.projectProducts.slice().map((a) => a.id.toString());
    };

    public setProjects = (projectQuotes: PropertyProjectQuoteListItem[]) => {
        this.projectQuotes.replace(projectQuotes);
    };

    public get getProjects() {
        const filterByStatus = (project: PropertyProjectQuoteListItem): boolean => {
            if (this.statusFilter.slice().length === this.statusDropdown.length) {
                return true;
            }

            let retVal = this.statusFilter.slice().indexOf(project.overallStatus.toString()) > -1;
            return retVal;
        };

        const filterByProduct = (project: PropertyProjectQuoteListItem): boolean => {
            if (this.productFilter.slice().length === this.projectProducts.length) {
                return true;
            }

            let retVal = this.productFilter.slice().indexOf(project.projectProductId.toString()) > -1;
            return retVal;
        };

        return this.projectQuotes
            .slice()
            .filter((a) => filterByProduct(a))
            .filter((a) => filterByStatus(a));
    }

    public get getStatuses(): KeyValuePairExtended[] {
        return this.statusDropdown;
    }

    public get getStatusFilter(): string[] {
        return this.statusFilter;
    }

    public updateStatusFiltering = (values: string[]) => {
        this.statusFilter = values;
    };

    public statusSelectNone = () => {
        this.statusFilter = [];
    };

    public statusSelectAll = () => {
        this.statusFilter = ProjectStatusEnumHelper.getAllProjectStatuses();
    };

    public get getProductFilter(): string[] {
        return this.productFilter;
    }

    public get getProductTypesForDropdown() {
        let retVal: KeyValuePairExtended[] = [];
        let newArray = this.projectProducts.map((item) => {
            let classItem: string = "parent";
            if (item.parentId !== null && item.parentId !== undefined) {
                classItem = "child";
            }
            return { key: item.id.toString(), text: item.displayName, class: classItem };
        });
        retVal = [...retVal, ...newArray];
        return retVal;
    }

    public updateProductFiltering = (values: string[]) => {
        this.productFilter = values;
    };

    public productSelectNone = () => {
        this.productFilter = [];
    };

    public productSelectAll = () => {
        this.productFilter = this.getAllProductFiltered();
    };

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.getLinkedProjectsQuotes();
    };

    public getLinkedProjectsQuotes = async (): Promise<LinkedProjectQuotesResponse> => {
        const endpoint = new GetLinkedProjectQuotesEndpoint();
        let retVal: LinkedProjectQuotesResponse = new LinkedProjectQuotesResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: LinkedProjectQuotesResponse = this.apiClient.Response();
                    this.projectProducts.replace(response.projectProduct);
                    this.projectQuotes.replace(response.projectQuoteListItem);
                    this.projectQuoteCountCallBack(response.projectQuoteListItem.length);
                    this.customerCount = response.propertyCount;
                    this.pageCount = Math.ceil(this.customerCount / this.pageSize);
                    if (this.initialLoad === true) {
                        this.productSelectAll();
                        this.initialLoad = false;
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View Linked project quotes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the Linked project quotes.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    // delete Property

    public deletePropertyCheck = (e: any, id: string): void => {
        this.propertyProjectQuoteToDeleteId = id;
        this.areYouSureModelOpen = true;
    };

    public confirmDeleteProperty = () => {
        this.deleteProjectQuote();
    };

    public closeAreYouSureModal = () => {
        this.areYouSureModelOpen = false;
    };

    public async deleteProjectQuote(): Promise<void> {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            const endpoint = new DeletePropertyProjectQuoteByIdEndpoint();
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.pageCount = Math.ceil(this.customerCount / this.pageSize);
                    this.isProcessing = false;
                    this.areYouSureModelOpen = false;
                    this.getLinkedProjectsQuotes();
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project Quote");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the project quote.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    // end delete Property

    public clearFiltering: ICommand = new RelayCommand(() => {
        this.initialLoad = true;
        this.productFilter = this.getAllProductFiltered();
        this.statusSelectAll();
        this.getLinkedProjectsQuotes();
    });
}
