import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate } from "react-router";
import { Header } from "Views/Header/Header";
import { TasksRoutes } from "./Tasks/TasksRoutes";
import { setPageTitle } from "Application";

export const DashboardRoutes: React.FC = observer(() => {
    useEffect(() => {
        setPageTitle("Dashboard");
    }, []);
    return (
        <>
            <Header title="Dashboard" />

            <Routes>
                <Route path="*" element={<Navigate to="tasks" replace />} />
                <Route path={"tasks/*"} element={<TasksRoutes />} />
            </Routes>
        </>
    );
});
