import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { LoginViewModel } from "./LoginViewModel";
import { Box, Button, Input, Label, setPageTitle, ThemedLoader } from "Application";
import { LoginHomeViewModel } from "../LoginHomeViewModel";
import { useNavigate } from "react-router";
import LoginProceed from "Assets/Icons/LoginProceed.svg";
import { AppUrlIndex, AppUrls } from "AppUrls";

/**
 * Login page view.
 */
const LoginView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    const navigate = useNavigate();

    const loginCRCallback = () => {
        navigate(AppUrls.Client.ConditionReport.List[AppUrlIndex.Long]);
    };

    // #region Code Behind
    const [viewModel] = useState(() => new LoginViewModel(loginCRCallback));
    useEffect(() => {
        setPageTitle("Sign In");
    }, []);
    // #endregion Code Behind

    const renderForm = () => (
        <Box grid alignContent={"center"} dc="1fr" rowGap={"30px"} minWidth={"241px"}>
            <Label fontStyle={"error"} color={"error"}>
                {viewModel.apiClient.ValidationMessage}
            </Label>
            <Input
                command={viewModel.updateEmailCommand}
                displayName="Login"
                autoFocus={true}
                value={() => viewModel.getValue("emailAddress")}
                validationMessage={() => viewModel.getError("emailAddress")}
                cy={"emailAddress"}
                placeholder={"Enter email address"}
            />
            <Input
                command={viewModel.updatePasswordCommand}
                displayName="Password"
                type="password"
                value={() => viewModel.getValue("password")}
                validationMessage={() => viewModel.getError("password")}
                cy={"password"}
                placeholder={"Enter password"}
            />
            <Box>
                <Box onClick={() => loginHomeViewModel.navigateToForgotPasswordCommand.execute()}>
                    <Label fontStyle={"h7"} pointer style={{ textDecoration: "underline", color: "#71B3FC" }}>
                        Oops, I've forgotten my password
                    </Label>
                </Box>
            </Box>
            <Button
                cy={"login"}
                icon={<img src={LoginProceed} />}
                displayName=" Login"
                boxShadow={true}
                command={viewModel.loginCommand}
                paletteColor="ButtonGreen"
                type="submit"
            />
        </Box>
    );

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    return (
        <React.Fragment>
            {renderForm()}
            {renderBusy()}
        </React.Fragment>
    );
});
export default LoginView;
