import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { DropdownItem } from "Models/DropdownItem";

class GetSiteVisitModalRelatedRequest {}

export class GetSiteVisitModalRelatedResponse {
    public visitTypes: DropdownItem[] = [];
    public contacts: DropdownItem[] = [];
}

export class GetSiteVisitModalRelatedEndpoint extends Endpoint<GetSiteVisitModalRelatedRequest, GetSiteVisitModalRelatedResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(`/api/tsm/getsitevisitmodalrelated/${id}`);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new GetSiteVisitModalRelatedRequest();
        return request;
    }

    public async HandleResponseAsync(response: GetSiteVisitModalRelatedResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
