import { ConditionReportTemplateSection } from "Views/ConditionReport/Setup/AddEdit/ConditionReportTemplateSection";
import { makeObservable, observable, runInAction, computed, action } from "mobx";
import { KeyValuePair, ViewModelBase, generateID } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ConditionReportTemplateModel, CondtionReportTemplateModelValidator } from "./CondtionReportTemplateModel";
import { File64, getFileTo64 } from "Utils/File";
import { UploadJSONFileForTemplateEndpoint } from "../Endpoints/UploadJSONFileForTemplateEndpoint";
import { GetTemplateByIdEndpoint } from "../Endpoints/GetTemplateByIdEndpoint";
import { UpsertConditionReportTemplateSectionEndpoint, UpsertConditionReportTemplateSectionRequest } from "../Endpoints/UpsertConditionReportTemplateSectionEndpoint";
import { SetSectionIsDeletedEndpoint, SetSectionIsDeletedRequest } from "../Endpoints/SetSectionIsDeletedEndpoint";
import { ControlType, IExtendedSelectOption } from "Views/ConditionReport/Common/ControlType";
import { IDraggableItem } from "./Section";
import { arraysEqual } from "Utils/arrays";
import { SectionConcurrenyResponse, SectionOrdinal, UpdateSectionOrdinalsEndpoint } from "../Endpoints/UpdateSectionOrdinalsEndpoint";
import { SourceSectionModel } from "Views/ConditionReport/Common/Models/Source/SourceSectionModel";
import { SourceRowModel } from "Views/ConditionReport/Common/Models/Source/SourceRowModel";
import { SourcePageModel } from "Views/ConditionReport/Common/Models/Source/SourcePageModel";
import { UpsertConditionReportTemplateSectionsEndpoint, UpsertConditionReportTemplateSectionsRequest } from "../Endpoints/UpsertConditionReportTemplateSectionsEndpoint";
import { SourceQuestion } from "Views/ConditionReport/Common/Models/Source/SourceQuestionModel";

export class ConditionReportTemplateAddEditViewModel extends ViewModelBase<ConditionReportTemplateModel> {
    public isProcessing: boolean = false;
    public jsonFile: File | undefined = undefined;

    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public selectedSectionId: string = "";
    public isSelectedSectionDirty: boolean = false;
    public hasSectionsBeenReordered: boolean = false;
    public sectionsBackup: IDraggableItem[] = [];

    public repeatedSectionsBackup: IDraggableItem[] = [];
    public selectedRepeatedSectionId: string = "";
    public isRepeatedSelectedSectionDirty: boolean = false;
    public hasRepeatedSectionsBeenReordered: boolean = false;

    public spinnerText: string = "Loading...";

    constructor() {
        super(new ConditionReportTemplateModel());
        this.setValidator(new CondtionReportTemplateModelValidator());

        this.apiClient.setAPITimeout(240000);
        makeObservable(this, {
            /* Observables */
            hasSectionsBeenReordered: observable,
            isProcessing: observable,
            isSelectedSectionDirty: observable,
            isRepeatedSelectedSectionDirty: observable,
            jsonFile: observable,
            selectedSectionId: observable,
            selectedRepeatedSectionId: observable,
            spinnerText: observable,
            /* Actions */
            addJsonFile: action,
            addNewSection: action,
            addNewFlowSection: action,
            addNewRepeatedSection: action,
            clear: action,
            deleteSection: action,
            loadConditionReportTemplateAsync: action,
            sectionsReordered: action,
            setRepeatedSelectedSectionId: action,
            setRepeatableSectionDirty: action,
            setRepeatedSectionBackup: action,
            setSectionDirty: action,
            setSectionName: action,
            setSelectedSection: action,
            setSections: action,
            updateDBOrdinals: action,
            upsertSections: action,
            upsertSelectedSelection: action,
            upsertSingleSection: action,
            /* Computeds */
            getCss: computed,
            getSelectedSectionText: computed,
            getSelectedSectionId: computed,
            getRepeatedSelectedSection: computed,
            getRepeatedSelectedSectionId: computed,
            getSpinnerText: computed,
            getSelectedSection: computed,

            getTopLevelSections: computed,
        });
    }

    public get getCss(): string {
        return this.model.css;
    }

    public setRepeatableSectionDirty = () => {
        this.isSelectedSectionDirty = true;
        this.isRepeatedSelectedSectionDirty = true;
    };

    public get getRepeatedSelectedSectionId(): string {
        return this.selectedRepeatedSectionId;
    }

    public setRepeatedSectionBackup(repeatedsections: IDraggableItem[]) {
        this.repeatedSectionsBackup = repeatedsections;
    }

    public get getRepeatedSelectedSection(): ConditionReportTemplateSection {
        let retVal: ConditionReportTemplateSection = new ConditionReportTemplateSection();

        const id: string = this.selectedRepeatedSectionId;
        if (id.length > 0) {
            const section = this.model.sections.slice().find((a) => a.id === id);

            if (section !== undefined) {
                retVal = section;
            }
        }

        return retVal;
    }

    public setRepeatedSelectedSectionId = (id: string) => {
        if (this.isRepeatedSelectedSectionDirty === true) {
            this.isSelectedSectionDirty = true;
            this.selectedRepeatedSectionId = id;
        } else {
            // Finally set the new selected item when not dirty.
            this.selectedRepeatedSectionId = id;
        }
    };

    public get getSpinnerText(): string {
        return this.spinnerText;
    }

    public isSectionTopLevelRepeatable(deleteSectionId: string): boolean {
        let retVal: boolean = false;

        const section: ConditionReportTemplateSection | undefined = this.model.sections.find((a) => a.id === deleteSectionId);

        if (section !== undefined && section.hasRepeatableElements === true) {
            retVal = true;
        }

        return retVal;
    }

    public getRepeatableSections(topLevelSectionId: string): ConditionReportTemplateSection[] {
        let retVal: ConditionReportTemplateSection[] = [];

        const section: ConditionReportTemplateSection | undefined = this.model.sections.find((a) => a.id === topLevelSectionId);

        if (section !== undefined && section.hasRepeatableElements === true) {
            retVal.push(section);

            if (section.repeatedGroupId !== null && section.repeatedGroupId !== undefined && section.repeatedGroupId.length > 0) {
                const related: ConditionReportTemplateSection[] = this.model.sections.filter((a) => a.isRepeatable === true && a.repeatedGroupId === section.repeatedGroupId);

                if (related.length > 0) {
                    retVal.push(...related);
                }
            }
        }

        return retVal;
    }

    public get getTopLevelSections(): ConditionReportTemplateSection[] {
        /// Repeatable sections are shown with its corresponding hasRepeatableElements record
        return this.model.sections.slice().filter((a) => a.isRepeatable === false);
    }

    public setSections(sections: IDraggableItem[]) {
        if (sections.length !== this.model.sections.length) {
            // then we have added or removed a section.  So let
            // the normal flow sort that shizzle out
        } else {
            // nothing has changed and the page has re-rendered, or
            // the order has changed.
            // Both have to be non zero, so we ignore the first write
            if (sections.length > 0 && this.sectionsBackup.length > 0) {
                let isSame: boolean = arraysEqual(sections, this.sectionsBackup);

                if (isSame === false) {
                    this.hasSectionsBeenReordered = true;
                    this.model.updateOrdinals(sections);
                    this.updateDBOrdinals();
                }
            }
        }

        // update with the latest!
        this.sectionsBackup = sections;
    }

    public sectionsReordered() {
        this.hasSectionsBeenReordered = true;
    }

    public repeatedSectionsReordered() {
        this.hasRepeatedSectionsBeenReordered = true;
        this.hasSectionsBeenReordered = true;
    }

    public updateDBOrdinals = async (): Promise<boolean> => {
        this.isProcessing = true;

        if (this.hasSectionsBeenReordered === true) {
            this.model.updateOrdinals(this.sectionsBackup);
        }

        let endPointModel: SectionOrdinal[] = this.model.getSectionOrdinals();

        this.spinnerText = "Updating...";

        let retVal: boolean = false;
        let endpoint: UpdateSectionOrdinalsEndpoint = new UpdateSectionOrdinalsEndpoint();
        let _ = await this.apiClient.sendAsync(endpoint, endPointModel);
        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                let response: SectionConcurrenyResponse[] = this.apiClient.Response();
                this.model.updateConcurrencyTokens(response);
                this.isProcessing = false;
                retVal = true;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Condition Report Template");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to upload section ordinals.  Please try again");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }

        return retVal;
    };

    public setSectionDirty = () => {
        this.isSelectedSectionDirty = true;
    };

    public setSectionName = (value: string) => {
        const id: string = this.selectedSectionId;

        if (id.length > 0) {
            const section: ConditionReportTemplateSection | undefined = this.model.sections.find((a) => a.id === id);

            if (section !== null && section !== undefined && section.name != value) {
                section.name = value;
                this.isSelectedSectionDirty = true;
            }
        }
        return "";
    };

    public get getSelectedSectionName(): string {
        const id: string = this.selectedSectionId;

        if (id.length > 0) {
            const section = this.model.sections.find((a) => a.id === id);

            if (section !== null && section !== undefined) {
                return section.name;
            }
        }
        return "";
    }

    public get getSelectedSectionText(): string {
        const id: string = this.selectedSectionId;

        if (id.length > 0) {
            const section = this.model.sections.find((a) => a.id === id);

            if (section !== undefined) {
                if (section.html.length === 0) {
                    section.html = "<div>" + section.name + "</div>";
                }

                return section.html;
            }
        }
        return "";
    }

    public get getSelectedSectionControlType(): ControlType {
        let retVal: ControlType = ControlType.Paragraph;
        const id: string = this.selectedSectionId;
        if (id.length > 0) {
            const section = this.model.sections.find((a) => a.id === id);
            if (section !== undefined) {
                retVal = section.controlTypeId as ControlType;
            }
        }
        return retVal;
    }

    public get getSelectedSectionId(): string {
        return this.selectedSectionId;
    }

    public setSelectedSection(id: string) {
        if (this.isSelectedSectionDirty === true) {
            this.isProcessing = true;
            // Upsert the currrent changed section, before selecting the new section
            let promise = this.upsertSelectedSelection();

            promise.then((response: any) => {
                runInAction(() => {
                    this.isSelectedSectionDirty = false;
                    // Finally set the new selected item.
                    this.selectedSectionId = id;

                    if (this.isSectionTopLevelRepeatable(id) === true) {
                        this.selectedRepeatedSectionId = id;
                    }

                    setTimeout(() => {
                        runInAction(() => {
                            this.isProcessing = false;
                        });
                    }, 100);
                });
            });
        } else {
            // Finally set the new selected item when not dirty.
            this.isProcessing = true;
            this.selectedSectionId = id;

            if (this.isSectionTopLevelRepeatable(id) === true) {
                this.selectedRepeatedSectionId = id;
            }
            setTimeout(() => {
                runInAction(() => {
                    this.isProcessing = false;
                });
            }, 100);
        }
    }

    public updateSelectedSectionText = (value: string) => {
        const id: string = this.selectedSectionId;
        if (id.length > 0) {
            const section = this.model.sections.find((a) => a.id === this.selectedSectionId);

            if (section !== null && section !== undefined && section.html !== value) {
                this.isSelectedSectionDirty = true;
                this.model.setSectionText(id, value);
            }
        }
    };

    public clear = () => {
        this.isProcessing = false;
        this.jsonFile = undefined;
        this.selectedSectionId = "";
        this.isSelectedSectionDirty = false;
        this.model.clear();
        this.hasSectionsBeenReordered = false;
    };

    public addJsonFile = async (jsonFile: File): Promise<ConditionReportTemplateModel> => {
        // CMR SInce 18mb + files take a couple minutes, change the API timeout
        this.isProcessing = true;
        this.apiClient.setAPITimeout(240000);
        this.jsonFile = jsonFile;

        let uploadaleFile: File64 = await getFileTo64(this.jsonFile);

        let retVal: ConditionReportTemplateModel = new ConditionReportTemplateModel();

        let endpoint: UploadJSONFileForTemplateEndpoint = new UploadJSONFileForTemplateEndpoint();

        this.spinnerText = "Uploading Json File...";
        let _ = await this.apiClient.sendAsync(endpoint, uploadaleFile);
        runInAction(() => {
            if (this.apiClient.IsRequestSuccessful) {
                this.isProcessing = false;
                let response = this.apiClient.Response();
                this.model.loadTemplate(response);

                if (this.selectedSectionId.length === 0 && this.model.sections.length > 0) {
                    let toplevelSections: ConditionReportTemplateSection[] = this.model.sections.filter((a) => a.isRepeatable === false);

                    if (toplevelSections.length > 0) {
                        this.selectedSectionId = toplevelSections[0].id!;
                    }
                }
                retVal = response;
            } else {
                this.isProcessing = false;
                this.errorStore.setHeaderText("Condition Report Template");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to upload the json file for .  Please correct the file and try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        });
        return retVal;
    };

    public loadConditionReportTemplateAsync = async (templateId: any): Promise<ConditionReportTemplateModel> => {
        let retVal: ConditionReportTemplateModel = new ConditionReportTemplateModel();
        let endpoint: GetTemplateByIdEndpoint = new GetTemplateByIdEndpoint(templateId);
        this.selectedSectionId = "";
        this.isSelectedSectionDirty = false;

        this.spinnerText = "Loading Template...";
        this.isProcessing = true;

        let _ = await this.apiClient.sendAsync(endpoint, templateId);
        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
                let response = this.apiClient.Response();
                this.model.loadTemplate(response);

                if (this.selectedSectionId.length === 0 && this.model.sections.length > 0) {
                    let toplevelSections: ConditionReportTemplateSection[] = this.model.sections.filter((a) => a.isRepeatable === false);

                    if (toplevelSections.length > 0) {
                        this.selectedSectionId = toplevelSections[0].id!;

                        if (toplevelSections[0].hasRepeatableElements === true) {
                            this.selectedRepeatedSectionId = this.selectedSectionId;
                        }
                    }
                }

                retVal = this.model;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Condition Report Template");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to load the template.  Please try again");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }

        return retVal;
    };

    public get getSelectedSection(): ConditionReportTemplateSection {
        let retVal: ConditionReportTemplateSection = new ConditionReportTemplateSection();

        const id: string = this.selectedSectionId;
        if (id.length > 0) {
            const section = this.model.sections.slice().find((a) => a.id === id);

            if (section !== undefined) {
                retVal = section;
            }
        }

        return retVal;
    }

    public getQuestionOptions = (): IExtendedSelectOption[] => {
        let retVal: IExtendedSelectOption[] = [];

        if (this.model !== null && this.model !== undefined && this.model.source !== null && this.model.source !== undefined) {
            // TODO WHY DOES IT THINK generateTypeKeysForControlTypeList IS NOT A FUNCTION??????????
            // TODO retVal = this.model.source.generateTypeKeysForControlTypeList([ControlType.Dropdown, ControlType.LargeTextBox, ControlType.SmallTextBox]);

            const source = this.model.source;
            const controlTypes = [ControlType.Dropdown, ControlType.LargeTextBox, ControlType.SmallTextBox];

            for (const page of source.pages) {
                for (const section of page.sections) {
                    for (const question of section.questions) {
                        if (
                            controlTypes.indexOf(question.controlTypeId) != -1 ||
                            (question.controlTypeId === ControlType.ButtonGroup &&
                                question.commentQuestionText !== null &&
                                question.commentQuestionText !== undefined &&
                                question.commentQuestionText.length > 0)
                        ) {
                            retVal.push({ display: question.question + "(Question)", className: "", value: question.questionTypeKey });
                            retVal.push({ display: question.question + "(Answer)", className: "", value: question.answerTypeKey });

                            if (question.controlTypeId === ControlType.ButtonGroup) {
                                if (question.commentQuestionText !== null && question.commentQuestionText !== undefined && question.commentQuestionText.length > 0) {
                                    let commentQuestionText: string = question.commentQuestionText;
                                    if (commentQuestionText.toLocaleLowerCase() === "placeholder") {
                                        commentQuestionText = question.question;
                                    } else {
                                        commentQuestionText = question.question + " - " + question.commentQuestionText;
                                    }

                                    retVal.push({ display: commentQuestionText + "(Question Comment)", className: "", value: question.commentQuestionTextKey });
                                    retVal.push({ display: commentQuestionText + "(Answer Comment)", className: "", value: question.commentQuestionTextKeyAnswer });
                                }
                            }
                        }
                    }

                    for (const row of section.rows) {
                        for (const subPage of row.pages) {
                            for (const subSection of subPage.sections) {
                                for (const subQuestion of subSection.questions) {
                                    if (
                                        controlTypes.indexOf(subQuestion.controlTypeId) != -1 ||
                                        (subQuestion.controlTypeId === ControlType.ButtonGroup &&
                                            subQuestion.commentQuestionText !== null &&
                                            subQuestion.commentQuestionText !== undefined &&
                                            subQuestion.commentQuestionText.length > 0)
                                    ) {
                                        retVal.push({ display: subQuestion.question + "(Question)", className: "", value: subQuestion.questionTypeKey });
                                        retVal.push({ display: subQuestion.question + "(Answer)", className: "", value: subQuestion.answerTypeKey });

                                        if (subQuestion.controlTypeId === ControlType.ButtonGroup) {
                                            if (
                                                subQuestion.commentQuestionText !== null &&
                                                subQuestion.commentQuestionText !== undefined &&
                                                subQuestion.commentQuestionText.length > 0
                                            ) {
                                                let commentQuestionText: string = subQuestion.commentQuestionText;
                                                if (commentQuestionText.toLocaleLowerCase() === "placeholder") {
                                                    commentQuestionText = subQuestion.question;
                                                } else {
                                                    commentQuestionText = subQuestion.question + " - " + subQuestion.commentQuestionText;
                                                }

                                                retVal.push({
                                                    display: commentQuestionText + "(Question Comment)",
                                                    className: "",
                                                    value: subQuestion.commentQuestionTextKey,
                                                });
                                                retVal.push({
                                                    display: commentQuestionText + "(Answer Comment)",
                                                    className: "",
                                                    value: subQuestion.commentQuestionTextKeyAnswer,
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return retVal;
    };

    public addNewFlowSection = async (
        sectionTitle: string,
        sectionTypeKey: string,
        controlTypeId: ControlType,
        typeKey: string,
        subSections: KeyValuePair[],
        sendToServer: boolean,
    ): Promise<ConditionReportTemplateSection> => {
        let retVal: ConditionReportTemplateSection = new ConditionReportTemplateSection();

        if (this.apiClient.IsBusy === false) {
            let endpoint: UpsertConditionReportTemplateSectionEndpoint = new UpsertConditionReportTemplateSectionEndpoint();

            let request: UpsertConditionReportTemplateSectionRequest = new UpsertConditionReportTemplateSectionRequest();
            request.templateId = this.model.id!;
            request.section = new ConditionReportTemplateSection();
            request.section.id = null;
            request.section.parentId = null;
            request.section.templateId = this.model.id!;
            request.section.jsonOptions = ""; // TODO CMR
            request.section.ordinal = this.model.getNextOrdinal();
            request.section.name = sectionTitle;
            request.section.typeKey = typeKey;
            request.section.sectionTypeKey = sectionTypeKey;
            request.section.controlTypeId = controlTypeId;

            // Now work out any subsections

            if (controlTypeId === ControlType.ButtonGroup || controlTypeId === ControlType.Multiselect) {
                let foundSection: SourceSectionModel | undefined = undefined;

                for (const page of this.model.source.pages) {
                    const section: SourceSectionModel | undefined = page.sections.find((a) => a.typeKey.toLocaleLowerCase() === sectionTypeKey.toLocaleLowerCase());

                    if (section !== undefined) {
                        foundSection = section;
                        break;
                    }
                }

                //Find the question.
                let foundQuestion: SourceQuestion | undefined = undefined;
                if (foundSection !== null) {
                    for (const question of foundSection!.questions) {
                        if (question.questionTypeKey === typeKey) {
                            foundQuestion = question;
                            break;
                        }
                    }
                }

                if (foundQuestion !== null) {
                    // #11882 Auto Add the tables
                    let htmlTable: string = "<h2>" + sectionTitle + "</h2>";

                    htmlTable += "<div class='question-answer-table-wrapper'><table class='question-answer-table'><tr>";

                    for (const element of foundQuestion!.options) {
                        htmlTable += "<td class='answer-typekey'>";
                        htmlTable += element.typeKey;
                        htmlTable += "</td>";
                        htmlTable += "<td class='answer-value'> ";
                        htmlTable += element.value;
                        htmlTable += "</td>";
                    }

                    htmlTable += "</tr></table></div><p>&nbsp;</p><p>&nbsp;</p>";
                    request.section.html += htmlTable;
                }

                for (const element of subSections) {
                    let subSection = new ConditionReportTemplateSection();
                    subSection.id = null;
                    subSection.templateId = this.model.id!;
                    subSection.parentId = null; // TODO SORT OUT IN THE DAL
                    subSection.name = element.text;
                    subSection.typeKey = element.key;
                    subSection.sectionTypeKey = sectionTypeKey;
                    request.section.jsonOptions = ""; // TODO CMR
                    request.section.ordinal = this.model.getNextOrdinal();
                    request.section.controlTypeId = controlTypeId;
                    request.section.subSections.push(subSection);
                }
            }

            if (sendToServer === true) {
                this.isProcessing = true;
                this.spinnerText = "Adding new section...";

                let _ = await this.apiClient.sendAsync(endpoint, request);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: ConditionReportTemplateSection = this.apiClient.Response();
                        this.model.sections.push(response);
                        this.selectedSectionId = response.id!;

                        if (this.isSectionTopLevelRepeatable(response.id!) === true) {
                            this.selectedRepeatedSectionId = response.id!;
                        }

                        retVal = response;
                        this.isSelectedSectionDirty = false;
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Condition Report Template");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to update the section.  Please try again");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            } else {
                retVal = request.section;
            }
        }
        return retVal;
    };

    ///
    /// addNewRepeatedSection
    /// So the common section (Main section) should be added.  So now we add the Sub section, Questions and options
    ///
    public addNewRepeatedSection = (sectionTypeKey: string, controlTypeId: ControlType, repeatedGroupId: string): ConditionReportTemplateSection[] => {
        let retVal: ConditionReportTemplateSection[] = [];

        if (this.apiClient.IsBusy === false) {
            // this is a repeatable section.
            let foundSection: SourceSectionModel | undefined = undefined;

            for (const page of this.model.source.pages) {
                const section: SourceSectionModel | undefined = page.sections.find((a) => a.typeKey.toLocaleLowerCase() === sectionTypeKey.toLocaleLowerCase());

                if (section !== undefined) {
                    foundSection = section;
                    break;
                }
            }

            if (foundSection !== undefined) {
                if (foundSection.rows.length > 0) {
                    // Only want the first row.
                    let row: SourceRowModel = foundSection.rows[0];
                    if (foundSection.rows[0].pages.length > 0) {
                        // There should only be one page, so use that.
                        let subPage: SourcePageModel = row.pages[0];

                        // Issue is, there can be more than one section here.
                        // So we need to group the items together.
                        for (const subPageSection of subPage.sections) {
                            // Need at least one of these per subsection.
                            this.isProcessing = true;
                            this.spinnerText = "Adding sub section ...";

                            let subSection = new ConditionReportTemplateSection();
                            subSection.repeatedGroupId = repeatedGroupId;
                            subSection.id = null;
                            subSection.templateId = this.model.id!;
                            subSection.parentId = null; // TODO SORT OUT IN THE DAL
                            subSection.name = subPageSection.name;
                            subSection.typeKey = subPageSection.typeKey;
                            subSection.sectionTypeKey = sectionTypeKey;
                            subSection.jsonOptions = ""; // TODO CMR
                            subSection.ordinal = this.model.getNextOrdinal();
                            subSection.controlTypeId = controlTypeId;
                            subSection.isRepeatable = true;

                            // TODO CMR DO WE HAVE COMMENTS AT THIS LEVEL?????????????

                            let subOrdinal: number = 1;

                            for (const subQuestion of subPageSection.questions) {
                                if (subQuestion.controlTypeId === ControlType.Camera || subQuestion.controlTypeId === ControlType.SketchPad) {
                                    let imageSection = new ConditionReportTemplateSection();
                                    imageSection.id = null;
                                    imageSection.templateId = this.model.id!;
                                    imageSection.parentId = null; // TODO SORT OUT IN THE DAL
                                    imageSection.name = subQuestion.question;
                                    imageSection.typeKey = subQuestion.questionTypeKey;
                                    imageSection.sectionTypeKey = sectionTypeKey;
                                    imageSection.jsonOptions = ""; // TODO CMR
                                    imageSection.ordinal = subOrdinal;
                                    imageSection.controlTypeId = subQuestion.controlTypeId;
                                    subSection.subSections.push(imageSection);

                                    if (subQuestion.commentQuestionText.length > 0) {
                                        imageSection.commentQuestionText = subQuestion.commentQuestionText;
                                        imageSection.commentQuestionTextTypeKey = subQuestion.commentQuestionTextKey;
                                        imageSection.commentTypeKey = subQuestion.commentQuestionTextKeyAnswer;
                                    } else {
                                        imageSection.commentQuestionText = "";
                                        imageSection.commentQuestionTextTypeKey = "";
                                        imageSection.commentTypeKey = "";
                                    }

                                    subOrdinal += 10;
                                } else if (subQuestion.controlTypeId === ControlType.ButtonGroup || subQuestion.controlTypeId === ControlType.Multiselect) {
                                    let questionSection = new ConditionReportTemplateSection();
                                    questionSection.id = null;
                                    questionSection.templateId = this.model.id!;
                                    questionSection.parentId = null; // TODO SORT OUT IN THE DAL
                                    questionSection.name = subQuestion.question;
                                    questionSection.typeKey = subQuestion.questionTypeKey;
                                    questionSection.sectionTypeKey = sectionTypeKey;
                                    questionSection.jsonOptions = ""; // TODO CMR
                                    questionSection.ordinal = subOrdinal;
                                    questionSection.controlTypeId = subQuestion.controlTypeId;

                                    if (subQuestion.commentQuestionText.length > 0) {
                                        questionSection.commentQuestionText = subQuestion.commentQuestionText;
                                        questionSection.commentQuestionTextTypeKey = subQuestion.commentQuestionTextKey;
                                        questionSection.commentTypeKey = subQuestion.commentQuestionTextKeyAnswer;
                                    } else {
                                        questionSection.commentQuestionText = "";
                                        questionSection.commentQuestionTextTypeKey = "";
                                        questionSection.commentTypeKey = "";
                                    }

                                    // #11882 Auto Add the tables
                                    let htmlTable: string = "<h2>" + subQuestion.question + "</h2>";

                                    htmlTable += "<div class='question-answer-table-wrapper'><table class='question-answer-table'><tr>";

                                    for (const element of subQuestion.options) {
                                        htmlTable += "<td class='answer-typekey'>";
                                        htmlTable += element.typeKey;
                                        htmlTable += "</td>";
                                        htmlTable += "<td class='answer-value'> ";
                                        htmlTable += element.value;
                                        htmlTable += "</td>";
                                    }

                                    htmlTable += "</tr></table></div>";
                                    questionSection.html += htmlTable;

                                    subSection.subSections.push(questionSection);
                                    subOrdinal += 10;

                                    let questionOrdinal: number = 1;

                                    for (const element of subQuestion.options) {
                                        let optionSection = new ConditionReportTemplateSection();
                                        optionSection.id = null;
                                        optionSection.templateId = this.model.id!;
                                        optionSection.parentId = null; // TODO SORT OUT IN THE DAL
                                        optionSection.name = element.value;
                                        optionSection.html = "";
                                        optionSection.typeKey = element.typeKey;
                                        optionSection.sectionTypeKey = sectionTypeKey;
                                        optionSection.jsonOptions = ""; // TODO CMR
                                        optionSection.ordinal = questionOrdinal;
                                        optionSection.controlTypeId = element.controlTypeId;
                                        questionSection.subSections.push(optionSection);
                                        questionOrdinal += 10;
                                    }
                                }
                            }

                            retVal.push(subSection);

                            // DEBUG stuff alert(subSection.name + ": " + subSection.subSections.length);
                        } // end for subsections.
                    }
                }
            }
        }
        return retVal;
    };

    public addNewSection = async (
        sectionTitle: string,
        sectionTypeKey: string,
        controlTypeId: ControlType,
        typeKey: string,
        subSections: KeyValuePair[],
        isRepeatableSection: boolean,
    ): Promise<ConditionReportTemplateSection[]> => {
        let retVal: ConditionReportTemplateSection[] = [];

        if (this.apiClient.IsBusy === false) {
            if (isRepeatableSection === false) {
                // Do a normal simple flow section
                let retSection: ConditionReportTemplateSection = await this.addNewFlowSection(sectionTitle, sectionTypeKey, controlTypeId, typeKey, subSections, true);
                retVal.push(retSection);
            } else {
                // We are doing a repeatable section.
                let sectionsToSend: ConditionReportTemplateSection[] = [];
                // step 1.  Add the section as a flow item, so there is a section that can explain the repeated sections
                let tempTitle: string = sectionTitle.replace(" - Repeat", " - Overview");
                let retSection: ConditionReportTemplateSection = await this.addNewFlowSection(tempTitle, sectionTypeKey, controlTypeId, typeKey, subSections, false);

                retSection.hasRepeatableElements = true;
                retSection.repeatedGroupId = generateID();

                sectionsToSend.push(retSection);
                this.isProcessing = true;

                // Step 2. Add the repeated sections / questions and answers
                let repeatedSectionsToSend: ConditionReportTemplateSection[] = this.addNewRepeatedSection(sectionTypeKey, controlTypeId, retSection.repeatedGroupId);
                sectionsToSend.push(...repeatedSectionsToSend);

                if (sectionsToSend.length > 0) {
                    // Step 3. Send the new sections to the database
                    retVal = await this.upsertSections(sectionsToSend, true);
                }
            }
        }
        return retVal;
    };

    public upsertSections = async (sections: ConditionReportTemplateSection[], addingNewRepeatedSection: boolean = false): Promise<ConditionReportTemplateSection[]> => {
        let retVal: ConditionReportTemplateSection[] = [];
        if (this.apiClient.IsBusy === false) {
            let endpoint: UpsertConditionReportTemplateSectionsEndpoint = new UpsertConditionReportTemplateSectionsEndpoint();
            let request: UpsertConditionReportTemplateSectionsRequest = new UpsertConditionReportTemplateSectionsRequest();
            request.templateId = this.model.id!;
            request.sections = sections;
            this.isProcessing = true;

            for (const element of request.sections) {
                if (element.templateId.length === 0) {
                    element.templateId = this.model.id!;
                }

                if (element.id?.toLocaleLowerCase().indexOf("tempid_") !== -1) {
                    element.id = null;
                }

                for (const subElement of element.subSections) {
                    if (subElement.templateId.length === 0) {
                        subElement.templateId = this.model.id!;
                    }

                    if (subElement.id?.toLocaleLowerCase().indexOf("tempid_") !== -1) {
                        subElement.id = null;
                    }

                    for (const subSubElement of subElement.subSections) {
                        if (subSubElement.templateId.length === 0) {
                            subSubElement.templateId = this.model.id!;
                        }
                        if (subSubElement.id?.toLocaleLowerCase().indexOf("tempid_") !== -1) {
                            subSubElement.id = null;
                        }
                    }
                }
            }

            let _ = await this.apiClient.sendAsync(endpoint, request);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: ConditionReportTemplateSection[] = this.apiClient.Response();
                    this.selectedSectionId = "";
                    for (const element of response) {
                        this.model.sections.push(element);

                        if (this.selectedSectionId.length === 0) {
                            this.selectedSectionId = element.id!;

                            if (addingNewRepeatedSection === true) {
                                if (this.isSectionTopLevelRepeatable(element.id!) === true) {
                                    this.selectedRepeatedSectionId = element.id!;
                                }
                            }
                        }
                    }
                    retVal = response;
                    this.isSelectedSectionDirty = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report Template");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to update the section.  Please try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public upsertSelectedSelection = async (): Promise<ConditionReportTemplateSection> => {
        let retVal: ConditionReportTemplateSection = new ConditionReportTemplateSection();

        if (this.apiClient.IsBusy === false) {
            const currentId: string = this.selectedSectionId;

            if (currentId.length === 0) {
                return retVal;
            }

            let currentSection: ConditionReportTemplateSection | null = this.model.findSection(currentId);

            if (currentSection !== null) {
                if (currentSection.hasRepeatableElements === false) {
                    // then we have a FLOW section so upsert it
                    retVal = await this.upsertSingleSection(currentSection, currentId);
                } else {
                    // else we have a Flow hasRepeatableElements section with repeated sections / subsections
                    retVal = await this.upsertRepeatableSection(currentSection, currentId);
                }
            }
        }

        return retVal;
    };

    public upsertRepeatableSection = async (currentSection: ConditionReportTemplateSection, currentId: string): Promise<ConditionReportTemplateSection> => {
        let retVal: ConditionReportTemplateSection = new ConditionReportTemplateSection();

        if (this.apiClient.IsBusy === false) {
            this.spinnerText = "Updating...";
            this.isProcessing = true;

            let repeatedSections: ConditionReportTemplateSection[] = [];
            repeatedSections.push(currentSection);

            let repeated: ConditionReportTemplateSection[] = this.model.sections.filter((a) => a.repeatedGroupId === currentSection?.repeatedGroupId && a.isRepeatable === true);

            if (repeated.length > 0) {
                if (this.hasRepeatedSectionsBeenReordered === true) {
                    // Then sort out the ordinals.
                    let nextOrdinal = currentSection.ordinal + 1;

                    for (const element of this.repeatedSectionsBackup) {
                        const item: ConditionReportTemplateSection | undefined = repeated.find((a) => a.id === element.id);

                        if (item !== undefined) {
                            item.ordinal = nextOrdinal++;
                        }
                    }

                    repeated = repeated.sort((a: ConditionReportTemplateSection, b: ConditionReportTemplateSection) => a.ordinal - b.ordinal);
                }

                repeatedSections.push(...repeated);
            }

            // Now upsert the sections and

            let endpoint: UpsertConditionReportTemplateSectionsEndpoint = new UpsertConditionReportTemplateSectionsEndpoint();
            let request: UpsertConditionReportTemplateSectionsRequest = new UpsertConditionReportTemplateSectionsRequest();
            request.templateId = this.model.id!;
            request.sections = repeatedSections;
            this.isProcessing = true;

            /*  Might need this later           for (const element of request.sections) {
                if (element.templateId.length === 0) {
                    element.templateId = this.model.id!;
                }

                if (element.id?.toLocaleLowerCase().indexOf("tempid_") !== -1) {
                    element.id = null;
                }

                for (const subElement of element.subSections) {
                    if (subElement.templateId.length === 0) {
                        subElement.templateId = this.model.id!;
                    }

                    if (subElement.id?.toLocaleLowerCase().indexOf("tempid_") !== -1) {
                        subElement.id = null;
                    }

                    for (const subSubElement of subElement.subSections) {
                        if (subSubElement.templateId.length === 0) {
                            subSubElement.templateId = this.model.id!;
                        }
                        if (subSubElement.id?.toLocaleLowerCase().indexOf("tempid_") !== -1) {
                            subSubElement.id = null;
                        }
                    }
                }
            } */

            let _ = await this.apiClient.sendAsync(endpoint, request);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: ConditionReportTemplateSection[] = this.apiClient.Response();
                    this.selectedSectionId = "";
                    for (const element of response) {
                        let existing: ConditionReportTemplateSection | undefined = this.model.sections.slice().find((a) => a.id === element.id);
                        console.log(element.id);
                        console.log(this.model.sections.length);

                        if (existing === undefined) {
                            this.model.sections.push(element);
                        } else {
                            existing.load(element);
                        }
                    }

                    if (response.length > 0) {
                        retVal = response[0];

                        this.model.sortList();

                        if (this.selectedSectionId.length === 0) {
                            this.selectedSectionId = response[0].id!;
                        }
                    }
                    this.isSelectedSectionDirty = false;
                    this.isRepeatedSelectedSectionDirty = false;
                    this.hasRepeatedSectionsBeenReordered = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report Template");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to update the section.  Please try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public upsertSingleSection = async (section: ConditionReportTemplateSection, currentId: string): Promise<ConditionReportTemplateSection> => {
        let retVal: ConditionReportTemplateSection = new ConditionReportTemplateSection();

        if (this.apiClient.IsBusy === false) {
            this.spinnerText = "Updating...";
            this.isProcessing = true;

            let endpoint: UpsertConditionReportTemplateSectionEndpoint = new UpsertConditionReportTemplateSectionEndpoint();
            let request: UpsertConditionReportTemplateSectionRequest = new UpsertConditionReportTemplateSectionRequest();

            request.templateId = this.model.id!;

            let isTempId: boolean = currentId.indexOf("TEMPID_") !== -1;

            request.section = section;

            let _ = await this.apiClient.sendAsync(endpoint, request);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: ConditionReportTemplateSection = this.apiClient.Response();
                    this.model.loadSection(response, currentId, isTempId);

                    const updatedsection: ConditionReportTemplateSection | null = this.model.findSection(this.selectedSectionId);

                    retVal = updatedsection ?? new ConditionReportTemplateSection();
                    this.isSelectedSectionDirty = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report Template");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to update the section.  Please try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }

        return retVal;
    };

    public deleteSection = async (sectionId: string): Promise<boolean> => {
        let retVal: boolean = false;
        let endpoint: SetSectionIsDeletedEndpoint = new SetSectionIsDeletedEndpoint();

        let request: SetSectionIsDeletedRequest = new SetSectionIsDeletedRequest();

        request.templateId = this.model.id!;

        if (this.selectedSectionId !== sectionId) {
            this.selectedSectionId = sectionId;
        }

        const section: ConditionReportTemplateSection | null = this.model.findSection(sectionId);

        if (section !== null) {
            request.concurrencyToken = section?.concurrencyToken;
            request.isDeleted = true; // TODO FOR NOW
            request.sectionId = sectionId;
        } else {
            this.errorStore.setHeaderText("Condition Report Template");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to find the section to delete.  Please refresh the page and try again");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
            return retVal;
        }

        this.spinnerText = "Deleting section...";
        this.isProcessing = true;

        let _ = await this.apiClient.sendAsync(endpoint, request);
        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
                retVal = this.apiClient.Response();
                if (retVal === true) {
                    this.model.deleteSection(sectionId);
                    this.selectedSectionId = "";
                }
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Condition Report Template");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to delete the section.  Please try again");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }

        return retVal;
    };
}
