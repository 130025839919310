import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { CustomerStatus } from "Application/Models/Domain/Customer/CustomerStatusEnum";
import { ViewCustomerModel } from "../ViewCustomer/ViewCustomerModel";

class SetCustomerStatusRequest {
    public id: string = "";
    customerStatusId: CustomerStatus = CustomerStatus.Active;
    rowVersion: string = "";
    constructor() {}
}

export class SetCustomerStatusResponse extends SetCustomerStatusRequest {
    constructor() {
        super();
    }
}

export class SetCustomerStatusEndpoint extends Endpoint<SetCustomerStatusRequest, SetCustomerStatusResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.SetCustomerStatus);
    }

    public async HandleRequestAsync(model: ViewCustomerModel): Promise<any> {
        let request = new SetCustomerStatusRequest();
        request.id = model.id!;
        request.rowVersion = model.rowVersion;
        request.customerStatusId = model.customerStatusId;
        return request;
    }

    public async HandleResponseAsync(response: SetCustomerStatusResponse): Promise<SetCustomerStatusResponse> {
        Logger.logInformation("Response", response);
        return response;
    }
}
