import { makeObservable } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { IAddedDocumentViewModel } from "Models/Documents/IAddedDocumentViewModel";
import { ComplaintDocumentItemModel, ComplaintDocumentItemModelValidator } from "./ComplaintDocumentItemModel";

export class ComplaintDocumentItemViewModel extends ViewModelBase<ComplaintDocumentItemModel> implements IAddedDocumentViewModel<ComplaintDocumentItemModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super(new ComplaintDocumentItemModel());
        this.setValidator(new ComplaintDocumentItemModelValidator());
        makeObservable(this, {});
    }

    public clear = () => {
        this.model.clear();
    };

    public setDocumentType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("documentCategoryId", Number(value.key));
    });
}
