import { ControlType, IExtendedSelectOption } from "../../ControlType";
import { SourcePageModel } from "./SourcePageModel";
import { KeyValuePair } from "@shoothill/core";

export class TemplateSourceModel {
    public id: number | null = null;
    public jsonFileURL: string = "";
    public fileVersion: number = 2;
    public formVersion: number = 14;
    public isDeleted: boolean = false;
    public createdDate: string = "";
    public createdBy: string | null = null;
    public identifier: number = -1;
    public referenceNumber: string = "";
    public name: string = "";
    public submitDate: string = "";
    public displayName: string = "";
    public pages: SourcePageModel[] = [];

    public constructor() {
        // empty constructor
    }

    /*     public fromDto = (model: TemplateSourceModel) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    }; */

    public getOptionsforSection(typeKeyId: string, controlTypeId: ControlType): KeyValuePair<string>[] {
        let retVal: KeyValuePair<string>[] = [];

        for (const page of this.pages) {
            for (const section of page.sections) {
                for (const question of section.questions) {
                    if (question.controlTypeId === controlTypeId && question.questionTypeKey === typeKeyId) {
                        for (const element of question.options) {
                            let option: KeyValuePair = { key: element.typeKey, text: element.value };
                            retVal.push(option);
                        }
                    }
                }

                for (const row of section.rows) {
                    for (const subPage of row.pages) {
                        for (const subSection of subPage.sections) {
                            for (const subQuestion of subSection.questions) {
                                if (subQuestion.controlTypeId === controlTypeId && subQuestion.questionTypeKey === typeKeyId) {
                                    for (const element of subQuestion.options) {
                                        let option: KeyValuePair = { key: element.typeKey, text: element.value };
                                        retVal.push(option);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return retVal;
    }

    public generateTypeKeysForControlType = (controlTypeId: ControlType): IExtendedSelectOption[] => {
        let retVal: IExtendedSelectOption[] = [];

        for (const page of this.pages) {
            for (const section of page.sections) {
                for (const question of section.questions) {
                    if (question.controlTypeId === controlTypeId) {
                        retVal.push({ display: question.question, className: "", value: question.questionTypeKey });
                    }
                }

                for (const row of section.rows) {
                    for (const subPage of row.pages) {
                        for (const subSection of subPage.sections) {
                            for (const subQuestion of subSection.questions) {
                                if (subQuestion.controlTypeId === controlTypeId) {
                                    retVal.push({ display: subQuestion.question, className: "", value: subQuestion.questionTypeKey });
                                }
                            }
                        }
                    }
                }
            }
        }

        return retVal;
    };

    public generateTypeKeysForControlTypeList = (controlTypes: ControlType[]): IExtendedSelectOption[] => {
        let retVal: IExtendedSelectOption[] = [];

        for (const page of this.pages) {
            for (const section of page.sections) {
                for (const question of section.questions) {
                    if (controlTypes.indexOf(question.controlTypeId) != -1) {
                        retVal.push({ display: question.question, className: "", value: question.questionTypeKey });
                    }
                }

                for (const row of section.rows) {
                    for (const subPage of row.pages) {
                        for (const subSection of subPage.sections) {
                            for (const subQuestion of subSection.questions) {
                                if (controlTypes.indexOf(subQuestion.controlTypeId) != -1) {
                                    retVal.push({ display: subQuestion.question, className: "", value: subQuestion.questionTypeKey });
                                }
                            }
                        }
                    }
                }
            }
        }

        return retVal;
    };
}
