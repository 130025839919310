import styled from "@emotion/styled";
import { Box, theme } from "Application";

export const BaseWrapper = styled(Box)`
    padding: 10px 10px 10px 10px;
    min-height: 100vh;
    background-color: #fcfcfc;
    .root {
        text-decoration: none;
        color: ${theme.palette.field.text};
        /* ...theme.fontStyles.h2; */
    }
`;

export const BaseWrapperNoMarginTop = styled(Box)`
    padding: 0px 10px 10px 10px;
    min-height: 100vh;
    background-color: #fcfcfc;
`;

export const BaseWrapperNoMargin = styled(Box)`
    padding: 0px;
    min-height: 100vh;
    background-color: #fcfcfc;
`;
