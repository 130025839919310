import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand } from "Application";
import styled from "@emotion/styled";
import BackButton from "Assets/Icons/BackIcon.svg";
import NextButton from "Assets/Icons/NextIcon.svg";

export interface IButtonNextPrev {
    nextCommand: ICommand<any>;
    prevCommand: ICommand<any>;
}

export const ButtonNextPrev: React.FC<IButtonNextPrev> = observer((props) => {
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    return (
        <>
            <NextPrevButtonBox>
                <Button command={props.prevCommand} style={{ backgroundColor: "transparent" }} icon={<img height="20px" src={BackButton} />}></Button>
                <Button command={props.nextCommand} style={{ backgroundColor: "transparent" }} icon={<img height="20px" src={NextButton} />}></Button>
            </NextPrevButtonBox>
        </>
    );
});

export const NextPrevButtonBox = styled(Box)`
    display: flex;
    border-radius: 10px;
    background-color: #337ab7;
    max-height: 40px;
`;
