import { KeyValuePair } from "@shoothill/core";
import { ControlType } from "Views/ConditionReport/Common/ControlType";
import { SectionType } from "Views/ConditionReport/Common/Models/BaseClasses/SectionType";
import { SourcePageModel } from "Views/ConditionReport/Common/Models/Source/SourcePageModel";
import { SourceSectionModel } from "Views/ConditionReport/Common/Models/Source/SourceSectionModel";
import { TemplateSourceModel } from "Views/ConditionReport/Common/Models/Source/TemplateSourceModel";

export const DEFAULTTEMPLATESECTIONNAME = "GENERAL";

export enum SectionLevel {
    GENERAL = 0,
    PAGE = 1,
    SECTION = 2,
    SUBPAGE = 3,
    SUBSECTION = 4,
}

export const addSpacing = (level: number) => {
    const spacing = Array.from(Array(4 * level), () => "\u00A0");
    return spacing.join("");
};

export const generateSectionTypeOptions = (templateSource: TemplateSourceModel): KeyValuePair[] => {
    let retVal: KeyValuePair[] = [];
    retVal.push({ text: "General", key: DEFAULTTEMPLATESECTIONNAME });

    for (const page of templateSource.pages) {
        retVal.push({ text: page.name + " (Page)", key: page.typeKey });

        for (const section of page.sections) {
            let sectionType: string = section.sectionType === SectionType.FLOW ? "Flow" : "Repeat";

            retVal.push({ text: addSpacing(1) + " - " + section.name + " (Section - " + sectionType + ")", key: section.typeKey });

            if (section.sectionType === SectionType.FLOW) {
                for (const row of section.rows) {
                    for (const subPage of row.pages) {
                        retVal.push({ text: addSpacing(2) + " - " + subPage.name + " (Sub Page)", key: subPage.typeKey });
                        for (const subSection of subPage.sections) {
                            let subSectionType: string = subSection.sectionType === SectionType.FLOW ? "Flow" : "Repeat";
                            retVal.push({ text: addSpacing(3) + "- " + subSection.name + " (Sub Section - " + subSectionType + ")", key: subSection.typeKey });
                        }
                    }
                }
            } // else REPEAT section type and we need to add ALL the sub pages / subsections into the selected repeat section.
        }
    }

    return retVal;
};

///
/// generateTypeKeysForPage
/// Get all questions for all sections / sub pages etc
///
export const generateTypeKeysForPage = (currentPage: SourcePageModel, controlTypeId: ControlType): KeyValuePair[] => {
    let retVal: KeyValuePair[] = [];

    for (const section of currentPage.sections) {
        for (const question of section.questions) {
            if (question.controlTypeId === controlTypeId) {
                retVal.push({ text: question.question, key: question.questionTypeKey });
            }
        }

        for (const row of section.rows) {
            for (const subPage of row.pages) {
                for (const subSection of subPage.sections) {
                    for (const subQuestion of subSection.questions) {
                        if (subQuestion.controlTypeId === controlTypeId) {
                            retVal.push({ text: subQuestion.question, key: subQuestion.questionTypeKey });
                        }
                    }
                }
            }
        }
    }

    return retVal;
};

///
/// generateTypeKeysForSection
/// Get all questions for current section
///
export const generateTypeKeysForSection = (currentSection: SourceSectionModel, controlTypeId: ControlType): KeyValuePair[] => {
    let retVal: KeyValuePair[] = [];

    for (const question of currentSection.questions) {
        if (question.controlTypeId === controlTypeId) {
            retVal.push({ text: question.question, key: question.questionTypeKey });
        }
    }

    return retVal;
};

///
/// generateTypeKeysForSubPage
/// Get all questions for all subSections
///
export const generateTypeKeysForSubPage = (currentSubPage: SourcePageModel, controlTypeId: ControlType): KeyValuePair[] => {
    let retVal: KeyValuePair[] = [];

    for (const section of currentSubPage.sections) {
        for (const question of section.questions) {
            if (question.controlTypeId === controlTypeId) {
                retVal.push({ text: question.question, key: question.questionTypeKey });
            }
        }
    }

    return retVal;
};

///
/// generateTypeKeysForSubSection
/// Get all questions for current subSections
///
export const generateTypeKeysForSubSection = (currentSubSection: SourceSectionModel, controlTypeId: ControlType): KeyValuePair[] => {
    let retVal: KeyValuePair[] = [];

    for (const question of currentSubSection.questions) {
        if (question.controlTypeId === controlTypeId) {
            retVal.push({ text: question.question, key: question.questionTypeKey });
        }
    }

    return retVal;
};

export const generateControlTypesForPage = (currentPage: SourcePageModel): ControlType[] => {
    let retVal: ControlType[] = [];

    /* Delete me since Pages have nothing to show other than general text intro sfor (const section of currentPage.sections) {
        for (const question of section.questions) {
            if (retVal.indexOf(question.controlTypeId) === -1 && question.controlTypeId !== ControlType.Dropdown) {
                retVal.push(question.controlTypeId);
            }
        }

        for (const row of section.rows) {
            for (const subPage of row.pages) {
                for (const subSection of subPage.sections) {
                    for (const subQuestion of subSection.questions) {
                        if (retVal.indexOf(subQuestion.controlTypeId) === -1 && subQuestion.controlTypeId !== ControlType.Dropdown) {
                            retVal.push(subQuestion.controlTypeId);
                        }
                    }
                }
            }
        }
    } */

    return retVal;
};

export const generateControlTypesSection = (currentSection: SourceSectionModel): ControlType[] => {
    let retVal: ControlType[] = [];

    for (const question of currentSection.questions) {
        if (retVal.indexOf(question.controlTypeId) === -1) {
            if (question.controlTypeId != ControlType.Dropdown) {
                retVal.push(question.controlTypeId);
            }
        }
    }

    return retVal;
};

export const generateControlTypesForSubPage = (currentSubPage: SourcePageModel): ControlType[] => {
    let retVal: ControlType[] = [];

    for (const section of currentSubPage.sections) {
        for (const subQuestion of section.questions) {
            if (retVal.indexOf(subQuestion.controlTypeId) === -1) {
                retVal.push(subQuestion.controlTypeId);
            }
        }
    }

    return retVal;
};

export const generateControlTypesForSubSection = (currentSubSection: SourceSectionModel): ControlType[] => {
    let retVal: ControlType[] = [];

    for (const subQuestion of currentSubSection.questions) {
        if (retVal.indexOf(subQuestion.controlTypeId) === -1) {
            retVal.push(subQuestion.controlTypeId);
        }
    }

    return retVal;
};
