import { action, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { BlankModel, BlankModelValidator } from "Application/Models/Domain/BlankModel";
import { UploadJsonFileEndpoint } from "./Endpoints/UploadJsonFileEndpoint";
import { File64, getFileTo64 } from "Utils/File";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { GlobalHistory } from "index";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";

export class ConditionReportAddEditViewModel extends ViewModelBase<BlankModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    public jsonFile: File | undefined = undefined;

    constructor() {
        super(new BlankModel());
        this.setValidator(new BlankModelValidator());
        this.apiClient.setAPITimeout(480000);
        makeObservable(this, {
            isProcessing: observable,
            clear: action,
            loadConditionReportAsync: action,
            addJsonFile: action,
        });
    }

    public clear() {
        this.jsonFile = undefined;
    }

    public loadConditionReportAsync(reportId: string) {
        alert("TODO load Condition Report Async");
    }

    public addJsonFile = async (jsonFile: File) => {
        // CMR SInce 18mb + files take a couple minutes, change the API timeout
        this.isProcessing = true;

        this.jsonFile = jsonFile;

        let uploadaleFile: File64 = await getFileTo64(this.jsonFile);

        let endpoint: UploadJsonFileEndpoint = new UploadJsonFileEndpoint();

        let _ = await this.apiClient.sendAsync(endpoint, jsonFile);
        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
            });
            const path: string = AppUrls.Client.ConditionReport.List[AppUrlIndex.Long];
            GlobalHistory.push(path);
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Condition Report");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to upload the condition report.  Please correct the file and try again");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    };
}
