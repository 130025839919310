import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, ICommand, RelayCommand, ThemedLoader } from "Application";
import { Modal } from "@fluentui/react";
import { P } from "Components/Primitives/TextElements/TextElements";
import AddIcon from "Assets/Icons/addRoundButtonIcon.svg";
import CloseIcon from "Assets/close_icon.svg";
import { AreYouSureModalHeader, AreYouSureModalWrapper, ButtonBox, HeaderBox } from "Components/AreYouSureModal/AreYouSure.styles";
import { ModalFooter, ViewPDFModalBody } from "Views/ConditionReport/Setup/AddEdit/Modals/AddSectionModal.style";
import { ShowPDFModalViewModel } from "./ShowPDFModalViewModel";

// react-pdf Stuff
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

export interface IShowPdfModalProps {
    id: string;
    title: string;
    isOpen: boolean;
    onCancel: () => void;
}

export const ShowPdfModal: React.FC<IShowPdfModalProps> = observer(({ title, isOpen, onCancel, id }) => {
    let [viewModel] = useState<ShowPDFModalViewModel>(new ShowPDFModalViewModel());
    let [pdfData, setPDF] = useState<string>("");

    // react-pdf stuff
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    useEffect(() => {
        // let promise = viewModel.getPDFHtml(id);

        // promise.then((html: string) => {
        //     if (html.length > 0) {
        //         setPDF(html);
        //     }
        // });

        viewModel.loadPDF(id);

        return () => {
            // Clean up after yourself
            setPDF("");
            clear();
        };
    }, [id]);

    useEffect(() => {}, [isOpen]);

    const clear = () => {
        viewModel.clear();
    };

    const handleCancelClick: ICommand = new RelayCommand(() => {
        handleCancel();
    });

    const handleCancel = () => {
        onCancel();
    };

    return (
        <>
            {viewModel.isProcessing === true ? (
                <ThemedLoader spinnerText={"Generating PDF"} isOpen={true} />
            ) : (
                <Modal
                    isOpen={isOpen}
                    styles={{
                        main: {
                            maxWidth: "794px",
                            width: "100%",
                        },
                    }}
                >
                    <AreYouSureModalWrapper style={{ margin: "0!important" }}>
                        <AreYouSureModalHeader>
                            <HeaderBox>
                                <img src={AddIcon} height="20px" />
                                <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                    {title}
                                </P>
                            </HeaderBox>
                            <HeaderBox style={{ cursor: "pointer" }} onClick={() => handleCancel()}>
                                <img src={CloseIcon} />
                            </HeaderBox>
                        </AreYouSureModalHeader>

                        <ViewPDFModalBody>
                            {/* <iframe id="pdfiframe" srcDoc={pdfData} height=" 640px" width="100%"></iframe> */}

                            <Document file={viewModel.pdfData} onLoadSuccess={onDocumentLoadSuccess} className="react-pdf-doc">
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>
                            {/* <p>
                                Page {pageNumber} of {numPages}
                            </p> */}
                        </ViewPDFModalBody>
                        <ModalFooter>
                            <ButtonBox pt="15px">
                                <Button command={handleCancelClick} displayName="Close" boxShadow={true} paletteColor="ButtonCancel" />
                            </ButtonBox>
                        </ModalFooter>
                    </AreYouSureModalWrapper>
                </Modal>
            )}
        </>
    );
});
