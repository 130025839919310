import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand, ThemedLoader, formatDate } from "Application";
import { BaseWrapper, BaseWrapperNoMargin, BaseWrapperNoMarginTop } from "Components/BaseWrapper";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { Route, Routes, useMatch, useNavigate, useParams } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ViewCustomerViewModel } from "./ViewCustomerViewModel";
import { H2, H5, P } from "Components/Primitives/TextElements/TextElements";
import { TabsWrapper } from "Components/TabsWrapper";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { DetailsView } from "./Details/DetailsView";
import DownArrow from "Assets/Icons/EditSelectArrow.svg";
import { ContactsView } from "./Contacts/ContactsView";
import { CommunicationView } from "./Communication/CommunicationView";
import { DocumentsView } from "./Documents/DocumentsView";
import { TasksView } from "./Tasks/TasksView";
import { LinkedMultiSiteView } from "./LinkedMultiSite/LinkedMultiSiteView";
import { LinkedMasterView } from "./LinkedMaster/LinkedMasterView";
import { LinkedProjectQuotesView } from "./LinkedProjectQuotes/LinkedProjectQuotesView";
import { CustomerStatusHelpers } from "Application/Models/Domain/Customer/CustomerStatusEnum";
import { components } from "react-select";
import { CustomerStatusBox, CustomerStatusSelect } from "../Common/CustomerStatusSelect";
import { KeyValuePair } from "@shoothill/core";
import { IReturnButtonDetails } from "Models/Routing/IReturnButtonDetails";
import { BackButtonNavigation, GetBackButtonText } from "Components/BackButtonNavigation";

export const ViewCustomerView: React.FC = observer(() => {
    const [viewModel] = useState(new ViewCustomerViewModel());
    const [backToTitle, setBackToTitle] = useState<IReturnButtonDetails>({ text: "Back to customers", url: AppUrls.Client.Directory.Default[AppUrlIndex.Long] });
    const navigate = useNavigate();
    let params = useParams();
    const [editUrl, setEditUrl] = useState<string>(AppUrls.Client.Directory.CustomersViewEdit[AppUrlIndex.Long].replace(":customerId", params.customerId!));

    //const location = useLocation();
    const matches = useMatch(AppUrls.Client.Directory.CustomersView[AppUrlIndex.Long] + "/*");
    let matchedPath = matches == null ? useMatch(AppUrls.Client.Directory.ContactCustomerView[AppUrlIndex.Long] + "/*") : matches;
    matchedPath = matchedPath == null ? useMatch(AppUrls.Client.Work.Quotes.CustomersView[AppUrlIndex.Long] + "/*") : matches;
    // Can't use this for more than two paths..  too long winded but I'm going to do it anyway!

    useEffect(() => {
        viewModel.getHeader(params.customerId);

        if (matchedPath !== null) {
            switch (matchedPath?.pattern.path) {
                case AppUrls.Client.Directory.ContactCustomerView[AppUrlIndex.Long] + "/*": {
                    setBackToTitle({ text: "Back to contacts", url: AppUrls.Client.Directory.Contacts[AppUrlIndex.Long] });
                    AppUrls.Client.Directory.CustomersViewEdit[AppUrlIndex.Long].replace(":customerId", params.customerId!);
                    break;
                }
                case AppUrls.Client.Work.Quotes.CustomersView[AppUrlIndex.Long] + "/*": {
                    const projectQuoteId: string | undefined = matchedPath.params.projectquoteid;
                    if (projectQuoteId !== undefined) {
                        setBackToTitle({
                            text: "Back to project quote",
                            url: AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", projectQuoteId) + "/details",
                        });
                        setEditUrl(
                            AppUrls.Client.Work.Quotes.CustomersViewEdit[AppUrlIndex.Long].replace(":projectquoteid", projectQuoteId).replace(":customerId", params.customerId!),
                        );
                    }
                    break;
                }

                case AppUrls.Client.Directory.CustomersView[AppUrlIndex.Long] + "/*":
                default: {
                    setBackToTitle({ text: "Back to customers", url: AppUrls.Client.Directory.Default[AppUrlIndex.Long] });
                    setEditUrl(AppUrls.Client.Directory.CustomersViewEdit[AppUrlIndex.Long].replace(":customerId", params.customerId!));
                    break;
                }
            }
        }

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        switch (value) {
            case subHeadingButton.Back:
                //navigate(backToTitle.url);
                navigate(BackButtonNavigation(AppUrls.Client.Directory.Default[AppUrlIndex.Long]));
                break;
            case subHeadingButton.Edit:
                navigate(editUrl);
                break;

            default:
            // code block
        }
    });

    const formatOptionLabel = ({ value, label, cls }: any) => (
        <CustomerStatusBox id={value} className={CustomerStatusHelpers.getCustomerStatusClass(value)}>
            <div className="option-label">{label}</div>
        </CustomerStatusBox>
    );

    const statusDropdownIndicator = (dropdownProps: any): JSX.Element => {
        return (
            <components.DropdownIndicator {...dropdownProps}>
                <img className="status-dropdown" src={DownArrow} style={{ transform: "rotate(180deg)" }} />
            </components.DropdownIndicator>
        );
    };

    const setCustomerStatus: ICommand = new RelayCommand((value: KeyValuePair) => {
        viewModel.setCustomerStatus(value);
    });

    const documentCountCallBack = (newCount: number): void => {
        viewModel.setValue("documentCount", newCount);
    };

    const tasksCountCallBack = (newCount: number): void => {
        viewModel.setValue("taskCount", newCount);
    };

    const noteCountCallBack = (newCount: number): void => {
        viewModel.setValue("noteCount", newCount);
    };

    const contactCountCallBack = (newCount: number): void => {
        viewModel.setValue("contactCount", newCount);
    };

    const multiSiteCountCallBack = (newCount: number): void => {
        viewModel.setValue("multiSiteCount", newCount);
    };

    const masterProjectCountCallBack = (newCount: number): void => {
        viewModel.setValue("masterProjectCount", newCount);
    };

    const projectQuoteCountCallBack = (newCount: number): void => {
        viewModel.setValue("projectQuoteCount", newCount);
    };

    return (
        <>
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText={"Back " + GetBackButtonText("to customers")} editButtonText="Edit customer details" />

            <BaseWrapperNoMargin>
                {viewModel.isProcessing ? (
                    <ThemedLoader isOpen={true} />
                ) : (
                    <>
                        <Box flexBox pt="40px" pb="10px" style={{ alignItems: "end", gap: "20px" }}>
                            <Box>
                                <CustomerStatusSelect
                                    className="" /* Totally breaks if this line isn't in */
                                    formatOptionLabel={formatOptionLabel}
                                    command={setCustomerStatus}
                                    options={CustomerStatusHelpers.getStatusForStatusDropdown()}
                                    value={() => viewModel.getCustomerIdString}
                                    dropdownIndicator={statusDropdownIndicator}
                                    isSearchable={false}
                                />
                            </Box>

                            <H2 style={{ lineHeight: 1 }}>{viewModel.getValue("name")}</H2>
                            <P>
                                Created by {viewModel.getValue("createdByName")} on {formatDate(new Date(viewModel.getValue("createdDate")))}
                            </P>
                        </Box>
                        <TabsWrapper>
                            <NavLink to={"details"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Details</H5>
                            </NavLink>
                            <NavLink to={"notes"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Notes ({viewModel.getValue("noteCount")})</H5>
                            </NavLink>
                            <NavLink to={"documents"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Documents ({viewModel.getValue("documentCount")})</H5>
                            </NavLink>
                            {matchedPath && (
                                <NavLink to={"tasks"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                    <H5>Tasks ({viewModel.getValue("taskCount")})</H5>
                                </NavLink>
                            )}
                            <NavLink to={"contacts"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Contacts ({viewModel.getValue("contactCount")})</H5>
                            </NavLink>
                            <NavLink to={"multi"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Linked multi site ({viewModel.getValue("multiSiteCount")})</H5>
                            </NavLink>
                            <NavLink to={"master"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Linked master ({viewModel.getValue("masterProjectCount")})</H5>
                            </NavLink>
                            <NavLink to={"quotes"} className={(navData) => clsx("root", navData.isActive ? "current long" : "default long")}>
                                <H5>Linked project quotes ({viewModel.getValue("projectQuoteCount")})</H5>
                            </NavLink>
                        </TabsWrapper>

                        <BaseWrapperNoMarginTop>
                            <Routes>
                                <Route path={"details"} element={<DetailsView id={params.customerId} />} />

                                <Route
                                    path={"notes"}
                                    element={<CommunicationView id={params.customerId} documentTypes={viewModel.documentTypes} noteCountCallBack={noteCountCallBack} />}
                                />

                                <Route
                                    path={"Documents"}
                                    element={<DocumentsView id={params.customerId} documentTypes={viewModel.documentTypes} documentCountCallBack={documentCountCallBack} />}
                                />

                                <Route path={"Tasks"} element={<TasksView id={params.customerId} taskCountCallBack={tasksCountCallBack} />} />

                                <Route
                                    path={"Contacts"}
                                    element={
                                        <ContactsView
                                            id={params.customerId}
                                            contactStatusTypes={viewModel.contactStatusTypes}
                                            documentTypes={viewModel.documentTypes}
                                            contactCountCallBack={contactCountCallBack}
                                        />
                                    }
                                />

                                <Route path={"multi"} element={<LinkedMultiSiteView multiSiteCountCallBack={multiSiteCountCallBack} id={params.customerId} />} />

                                <Route path={"master"} element={<LinkedMasterView masterProjectCountCallBack={masterProjectCountCallBack} id={params.customerId} />} />

                                <Route path={"quotes"} element={<LinkedProjectQuotesView projectQuoteCountCallBack={projectQuoteCountCallBack} id={params.customerId} />} />
                            </Routes>
                        </BaseWrapperNoMarginTop>
                    </>
                )}
            </BaseWrapperNoMargin>
        </>
    );
});
