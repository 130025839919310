import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

class GetOccupierListAndRelatedResponse {}

export class GetOccupierListAndRelatedEndpoint extends EndpointWithoutRequest<GetOccupierListAndRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Property.GetOccupierListAndRelated);
    }

    public async HandleResponseAsync(response: GetOccupierListAndRelatedResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
