import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ContactsListModel } from "../ContactsListModel";

class GetcustomerDropdownRequest {
    customerTypeId: number = 0;
    searchString: string = "";
}

class GetcustomerDropdownResponse {}

export class GetcustomerDropdownEndpoint extends Endpoint<GetcustomerDropdownRequest, GetcustomerDropdownResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Contact.GetCustomersForDropdown);
    }

    public async HandleRequestAsync(model: ContactsListModel): Promise<any> {
        let request = new GetcustomerDropdownRequest();
        request.customerTypeId = model.customerType;
        request.searchString = model.searchString;
        return request;
    }

    public async HandleResponseAsync(response: GetcustomerDropdownResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
