import { SVGProps } from "react";
import { boxShadow } from "styled-system";

export interface IInfoRoundIconProps extends SVGProps<SVGSVGElement> {
    backgroundColor?: string;
    boxShadow?: string;
    borderColor?: string;
    contentColor?: string;
}

export const InfoRoundIcon = (props: IInfoRoundIconProps) => {
    const backgroundColor = props.backgroundColor ? props.backgroundColor : "#337ab7";
    const borderColor = props.borderColor ? props.borderColor : "#fff";
    const contentColor = props.contentColor ? props.contentColor : "#fff";
    const boxShadow = props.boxShadow ? props.borderColor : "0px 0px 6px #00000029";

    return (
        <div
            style={{
                height: 20,
                width: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: borderColor,
                boxShadow: boxShadow,
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
                <g id="Group_9638" data-name="Group 9638" transform="translate(-94 -437)">
                    <path
                        id="Path_8727"
                        data-name="Path 8727"
                        d="M1.118-5.614a.813.813,0,0,1,.239-.605.84.84,0,0,1,.616-.236.943.943,0,0,1,.67.25.757.757,0,0,1,.267.564,1.113,1.113,0,0,1-.106.465.839.839,0,0,1-.335.373A.887.887,0,0,1,2-4.663a.838.838,0,0,1-.605-.28A.92.92,0,0,1,1.118-5.614ZM.16,2.545.146,2.1.919,2a.413.413,0,0,0,.236-.14.835.835,0,0,0,.065-.414V1.369q0-.082.01-.3t.01-.38q0-.13-.021-.5L1.23-.15,1.22-.471l.021-1.115A2.427,2.427,0,0,0,1.2-2.023.6.6,0,0,0,.924-2.2a2.757,2.757,0,0,0-.7-.109v-.383a3.317,3.317,0,0,0,1.238-.294,2.857,2.857,0,0,0,.786-.581h.458q.027.848.027,2.2l-.048,2.79a4.478,4.478,0,0,0,.027.52,4.26,4.26,0,0,0,.691.15,1.485,1.485,0,0,1,.294.068l-.014.376q-.184.007-.273.007T3.1,2.531Q2.406,2.5,1.239,2.5.9,2.5.59,2.521T.16,2.545Z"
                        transform="translate(102 448)"
                        fill={contentColor}
                        stroke={borderColor}
                        stroke-width="0.25"
                    />
                </g>
            </svg>
        </div>
    );
};
