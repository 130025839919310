import { Endpoint, Http } from "Application";
import { TasksListItemModel } from "../TasksListItemModel";
import { TasksListViewModel } from "../TasksListViewModel";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { Urgency } from "../UrgencyEnum";
import { DropdownItem } from "Models/DropdownItem";
import { AppUrls } from "AppUrls";
class Request {
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public searchString: string = "";
    public showFrom: string = "";
    public showTo: string = "";
    public filterByTaskType: string[] = [];
    public filterByCategory: string[] = [];
    public filterByAssigned: string[] = [];
    public filterByUrgency: Urgency[] = [];
    public filterByCompleted: string[] = [];
    public initialLoad: boolean = true;
}

export class AssignedDropdownItem {
    public id: string = "";
    public userName: string = "";
}

export class TasksListAndRelatedResponse {
    tasks: TasksListItemModel[] = [];
    taskTypeDropdown: DropdownItem[] = [];
    categoryDropdown: DropdownItem[] = [];
    assignedDropdown: AssignedDropdownItem[] = [];
    totalCount: number = 0;
}

export class GetTasksListAndRelatedEndpoint extends Endpoint<Request, TasksListAndRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/task/gettasklistandrelated");
    }

    public async HandleRequestAsync(viewModel: TasksListViewModel): Promise<any> {
        let request = new Request();
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageSize = viewModel.pageSize;
        request.pageNumber = viewModel.pageNumber;
        request.searchString = viewModel.keywordSearch;

        request.filterByTaskType = [];
        request.filterByCategory = [];
        request.filterByAssigned = [];
        request.filterByUrgency = [];
        request.filterByCompleted = [];

        let tempShowFrom: Date = new Date(viewModel.showFrom);
        tempShowFrom.setHours(23, 59, 59, 59);
        request.showFrom = tempShowFrom.toISOString();

        let tempDate: Date = new Date(viewModel.showTo);
        tempDate.setHours(23, 59, 59, 59);

        request.showTo = tempDate.toISOString();

        request.filterByTaskType = viewModel.filterByTasksType.map((item) => {
            return item;
        });

        request.filterByCategory = viewModel.filterByCategory.map((item) => {
            return item;
        });

        request.filterByAssigned = viewModel.filterByAssignedTo.map((item) => {
            return item;
        });

        request.filterByUrgency = viewModel.filterByUrgency.map((item) => {
            return parseInt(item);
        });

        request.filterByCompleted = viewModel.filterByCompleted;

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: TasksListAndRelatedResponse): Promise<TasksListAndRelatedResponse> {
        return response;
    }
}
