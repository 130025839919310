import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AddEditCustomerModel } from "../NewCustomer/AddEditCustomerModel";
import { AppUrls } from "AppUrls";
import { CustomerOption } from "Models/Customer/CustomerOption";

export class UpsertCustomerRequest {
    id: Guid | null = null;
    customerTypeId: number = 0;
    name: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    townCity: string = "";
    county: string = "";
    country: string = "";
    countryId: number = -1;
    postcode: string = "";
    isDeleted: boolean = false;
    email: string = "";
    phone: string = "";
    phone2: string = "";
    //  createdDate: string = "";
    createdBy: Guid | null = null;
    deletedDate: string = "";
    deletedBy: Guid | null = null;
    customerStatusId: number = 0;
    options: CustomerOption = new CustomerOption();
    websiteAddress: string = "";
    locationLatitude: number | null = null;
    locationLongitude: number | null = null;
    rowVersion: string = "";
    note: string = "";

    originalCustomerTypeId: number = 0;
    //canChangeCustomerType: boolean = true;
}

export class UpsertCustomerResponse {
    id: Guid | null = null;
    customerTypeId: number = 0;
    name: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    townCity: string = "";
    county: string = "";
    country: string = "";
    countryId: number = -1;
    postcode: string = "";
    isDeleted: boolean = false;
    email: string = "";
    phone: string = "";
    phone2: string = "";
    //  createdDate: string = "";
    createdBy: string = "";
    deletedDate: string = "";
    deletedBy: string = "";
    customerStatusId: number = 0;
    options: CustomerOption = new CustomerOption();
    websiteAddress: string = "";
    locationLatitude: number | null = null;
    locationLongitude: number | null = null;
    rowVersion: string = "";
    note: string = "";
    originalCustomerTypeId: number = 0;
    canChangeCustomerType: boolean = true;
}

export class UpsertCustomerEndpoint extends Endpoint<UpsertCustomerRequest, UpsertCustomerResponse> {
    constructor() {
        super();
        this.verb(Http.Put);
        this.path(AppUrls.Server.Customer.Upsert);
    }

    public async HandleRequestAsync(model: AddEditCustomerModel): Promise<UpsertCustomerRequest> {
        let request = new UpsertCustomerRequest();
        request.id = model.id;

        request.customerTypeId = model.customerTypeId;
        request.name = model.name;
        request.addressLine1 = model.address.addressLineOne;
        request.addressLine2 = model.address.addressLineTwo;
        request.townCity = model.address.townCity;
        request.county = model.address.county;
        request.country = model.address.country;
        request.countryId = model.address.countryId;
        request.postcode = model.address.postcode;
        request.isDeleted = model.isDeleted;
        request.email = model.email;
        request.phone = model.phone;
        request.phone2 = model.phone2;
        request.websiteAddress = model.websiteAddress;
        request.locationLatitude = model.address.latitude;
        request.locationLongitude = model.address.longitude;
        //request.createdDate = model.createdDate;
        request.createdBy = model.createdBy;
        request.deletedDate = model.deletedDate;
        request.deletedBy = model.deletedBy;
        request.customerStatusId = model.customerStatusId;
        request.customerStatusId = model.customerStatusId;
        request.rowVersion = model.rowVersion;
        request.note = model.note;

        request.originalCustomerTypeId = model.originalCustomerTypeId;

        request.options = {
            id: model.optionsId,
            customerId: model.id == null ? null : model.id,
            leadTsmId: model.leadTsmId == null ? 6 : model.leadTsmId,
            bdmId: model.bdmId == null ? 8 : model.bdmId,
            sageRef: model.sageRef == null ? "" : model.sageRef,
            eORINumber: model.eORINumber == null ? "" : model.eORINumber,
            tSSNumber: model.tSSNumber == null ? "" : model.tSSNumber,
            approved: model.approved,
            referral: model.referral,
        };

        return request;
    }

    public async HandleResponseAsync(response: UpsertCustomerResponse): Promise<AddEditCustomerModel> {
        Logger.logInformation("Response", response);
        let customerModel: AddEditCustomerModel = new AddEditCustomerModel();
        customerModel.id = response.id;
        customerModel.customerTypeId = response.customerTypeId;
        customerModel.name = response.name;
        customerModel.address.addressLineOne = response.addressLine1;
        customerModel.address.addressLineTwo = response.addressLine2;
        customerModel.address.townCity = response.townCity;
        customerModel.address.county = response.county;
        customerModel.address.country = response.country;
        customerModel.address.countryId = response.countryId;
        customerModel.address.postcode = response.postcode;
        customerModel.isDeleted = response.isDeleted;
        customerModel.email = response.email;
        customerModel.phone = response.phone;
        customerModel.phone2 = response.phone2;
        customerModel.createdBy = response.createdBy;
        customerModel.deletedDate = response.deletedDate;
        customerModel.deletedBy = response.deletedBy;
        customerModel.customerStatusId = response.customerStatusId;
        customerModel.rowVersion = response.rowVersion;

        customerModel.canChangeCustomerType = response.canChangeCustomerType;
        customerModel.originalCustomerTypeId = response.originalCustomerTypeId;

        // TODO - This needs to be CustomerOption class

        if (response.options !== null) {
            customerModel.optionsId = response.options.id;
            customerModel.customerId = response.id;
            customerModel.leadTsmId = response.options.leadTsmId;
            customerModel.bdmId = response.options.bdmId;
            customerModel.sageRef = response.options.sageRef;
            customerModel.eORINumber = response.options.eORINumber;
            customerModel.tSSNumber = response.options.tSSNumber;
            customerModel.approved = response.options.approved;
        }
        return customerModel;
    }
}
