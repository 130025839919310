import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router";
import { FallbackPageView } from "Components/FallbackPage";
import { CustomersListView } from "./List/CustomersListView";
import { AddEditCustomerView } from "./NewCustomer/AddEditCustomerView";
import { ViewCustomerView } from "./ViewCustomer/ViewCustomerView";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";

const CustomersRoutes: React.FC = observer(() => {
    return (
        <Routes>
            <Route
                path="*"
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <Navigate to="list" replace />
                    </React.Suspense>
                }
            />
            <Route
                path={"list/*"}
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <CustomersListView />
                    </React.Suspense>
                }
            />
            <Route
                path={"new/*"}
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <AddEditCustomerView addOrEdit={ModalType.Add} />
                    </React.Suspense>
                }
            />
            <Route
                path={"edit/:customerId"}
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <AddEditCustomerView addOrEdit={ModalType.Edit} />
                    </React.Suspense>
                }
            />
            <Route
                path={"viewedit/:customerId"}
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <AddEditCustomerView addOrEdit={ModalType.Edit} />
                    </React.Suspense>
                }
            />
            <Route
                path={"view/:customerId/*"}
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <ViewCustomerView />
                    </React.Suspense>
                }
            />
        </Routes>
    );
});

export default CustomersRoutes;
