import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";

export class ComplaintDocumentDeleteByIdRequest {
    id: Guid = "";
    complaintId: Guid = "";
}

export class MasterProjectComplaintDocumentDeleteByIdEndpoint extends Endpoint<ComplaintDocumentDeleteByIdRequest, IDocumentUploaded[]> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.MasterProjectComplaintDocumentDeleteById);
    }

    public async HandleRequestAsync(request: ComplaintDocumentDeleteByIdRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: IDocumentUploaded[]): Promise<IDocumentUploaded[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
