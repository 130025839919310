import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ComplaintsModel, ComplaintsModelValidator } from "./ComplaintsModel";

export class ComplaintsListRoutingViewModel extends ViewModelBase<ComplaintsModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;

    constructor() {
        super(new ComplaintsModel());
        this.setValidator(new ComplaintsModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            clear: action,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.model.clear();
    };
}
