import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { getFilenameFromContentDisposition } from "Utils/File";
import { ReportDownloadCSVResponse } from "Views/Reports/Models/ReportDownloadCSVResponse";
import { ReportListViewModel } from "../ReportListViewModel";

export class ProjectAndCustomerReportRequest {
    public searchString: string = "";
    public filterByProductType: number[] = [];
    public filterByBdm: number[] = [];
    public filterByTaskType: number[] = [];
    public startDate: string | null = null;
    public endDate: string | null = null;
}

export class DownloadProjectAndCustomerReportEndpoint extends Endpoint<ProjectAndCustomerReportRequest, ReportDownloadCSVResponse> {
    constructor(path: string) {
        super();
        this.verb(Http.Post);
        this.path(path);
        this.responseIsBlob(true);
        this.SetContentType("text/csv");
    }

    public async HandleRequestAsync(viewModel: ReportListViewModel): Promise<any> {
        let request = new ProjectAndCustomerReportRequest();

        request.searchString = viewModel.projectAndCustomerSearchKeyword;

        request.filterByProductType = [];

        request.filterByProductType = viewModel.filterByProduct.map((item) => {
            return parseInt(item);
        });

        request.filterByBdm = [];

        request.filterByBdm = viewModel.filterByBdm.map((item: string) => {
            return parseInt(item);
        });

        request.filterByTaskType = [];

        request.filterByTaskType = viewModel.filterByTasksType.map((item) => {
            return parseInt(item);
        });

        request.startDate = viewModel.tableStartDate.toISODate();

        request.endDate = viewModel.tableEndDate.toISODate();

        return request;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        let retVal: boolean = true;
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        //Return your model
        return retVal;
    }
}
