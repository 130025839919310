import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ViewContactModalModel extends ModelBase<ViewContactModalModel> {
    //public id: string = "";
    public id: Guid | null = null;
    public firstName: string = "";
    public lastName: string = "";
    public phone: string = "";
    public phone2: string = "";
    public email: string = "";
    public jobTitle: string = "";
    public status: number = 1;
    public primaryContact: boolean = false;
    public plygeneTrained: boolean = false;
    public coatingsTrained: boolean = false;
    public datePlygeneTrained: Date | null = null;
    public dateCoatingsTrained: Date | null = null;

    public coatingsCPD: boolean = false;
    public plygeneCPD: boolean = false;
    public dateCoatingsCPD: Date | null = null;
    public datePlygeneCPD: Date | null = null;

    public note: string = "";
    public customerId: Guid = "";
    public marketingConsent: boolean = true;

    constructor() {
        super();
        makeObservable(this, {
            //id: observable
            firstName: observable,
            lastName: observable,
            phone: observable,
            phone2: observable,
            email: observable,
            jobTitle: observable,
            status: observable,
            primaryContact: observable,
            marketingConsent: observable,
            plygeneTrained: observable,
            coatingsTrained: observable,
            datePlygeneTrained: observable,
            dateCoatingsTrained: observable,

            coatingsCPD: observable,
            plygeneCPD: observable,
            dateCoatingsCPD: observable,
            datePlygeneCPD: observable,

            note: observable,
            customerId: observable,

            clear: action,
        });
    }

    public clear = () => {
        //this.id = "";
        this.firstName = "";
        this.lastName = "";
        this.phone = "";
        this.phone2 = "";
        this.email = "";
        this.jobTitle = "";
        this.status = 1;
        this.primaryContact = false;
        this.marketingConsent = false;
        this.plygeneTrained = false;
        this.coatingsTrained = false;
        this.datePlygeneTrained = null;
        this.dateCoatingsTrained = null;

        this.coatingsCPD = false;
        this.plygeneCPD = false;
        this.dateCoatingsCPD = null;
        this.datePlygeneCPD = null;

        this.note = "";
        this.customerId = "";
    };
}

export class ViewContactModalModelValidator extends Validator<ViewContactModalModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
