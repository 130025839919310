import { AppUrls } from "AppUrls";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";

class GetTaskDetailsByIdResponse {}

export class GetTaskDetailsByIdEndpoint extends EndpointWithoutRequest<GetTaskDetailsByIdResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(`${AppUrls.Server.MasterProject.GetMasterProjectTasksAndRelated}/${id}`);
    }

    public async HandleResponseAsync(response: GetTaskDetailsByIdResponse): Promise<any> {
        return response;
    }
}
