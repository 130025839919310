import { makeObservable, action, computed } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { SectionLevel, generateTypeKeysForPage, generateTypeKeysForSection } from "Utils/TemplateSource";
import { ControlType } from "Views/ConditionReport/Common/ControlType";
import { SourcePageModel } from "Views/ConditionReport/Common/Models/Source/SourcePageModel";
import { SourceSectionModel } from "Views/ConditionReport/Common/Models/Source/SourceSectionModel";
import { TemplateSourceModel } from "Views/ConditionReport/Common/Models/Source/TemplateSourceModel";
import { AddSectionModel, AddSectionModelValidator } from "./AddSectionModel";

export class AddSectionModalViewModel extends ViewModelBase<AddSectionModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super(new AddSectionModel());

        this.setValidator(new AddSectionModelValidator());
        makeObservable(this, {
            clear: action,
            generateTypeKeys: action,
            setErrorMessage: action,
            setSectionName: action,
            setSectionType: action,
            setIsDirty: action,
            setTypeKey: action,
            setControlType: action,

            getCurrentLevel: computed,
            isRepeatSection: computed,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public setOriginalSectionName(value: string) {
        this.setValue("originalSectionName", value);
    }

    public setIsRepeatableSection(isRepeatable: boolean) {
        this.setValue("isRepeatableSection", isRepeatable);
    }

    public setTemplateSource = (templateSource: TemplateSourceModel) => {
        this.setValue("templateSource", templateSource);
    };

    public setErrorMessage = (value: string) => {
        this.setValue("errorMessage", value);
    };

    public setSectionName = (value: string) => {
        this.setValue("sectionName", value);
    };

    public setSectionType = (value: string) => {
        this.setValue("sectionTypeKeyId", value);
        this.setValue("controlTypeId", ControlType.Paragraph);
        this.setValue("typeKeyId", value);
    };

    public setIsDirty = (value: boolean) => {
        this.setValue("isNameDirty", value);
    };

    public setTypeKey = (value: string) => {
        this.setValue("typeKeyId", value);
    };

    public setControlType = (value: ControlType) => {
        this.setValue("controlTypeId", value);
        this.setValue("typeKeyId", "");
    };

    public get getCurrentLevel(): SectionLevel {
        // Use the originalSectionName not the section name, since that can change and remove the strings we are looking for
        let retVal: SectionLevel = SectionLevel.GENERAL;
        if (this.model.originalSectionName.indexOf("(Page)") != -1) {
            retVal = SectionLevel.PAGE;
        } else if (this.model.originalSectionName.indexOf("(Section") != -1) {
            retVal = SectionLevel.SECTION;
        } else if (this.model.originalSectionName.indexOf("(Sub Page)") != -1) {
            retVal = SectionLevel.SUBPAGE;
        } else if (this.model.originalSectionName.indexOf("(Sub Section") != -1) {
            retVal = SectionLevel.SUBSECTION;
        }

        return retVal;
    }

    public get isRepeatSection(): boolean {
        return this.model.isRepeatableSection;
    }

    public generateTypeKeys = (controlTypeId: ControlType, templateSource: TemplateSourceModel | undefined): KeyValuePair[] => {
        let retVal: KeyValuePair[] = [];

        let currentLevel: SectionLevel = this.getCurrentLevel;

        if (templateSource !== undefined) {
            if (currentLevel === SectionLevel.PAGE) {
                let currentPage: SourcePageModel | undefined = templateSource.pages.find((a) => a.typeKey === this.model.sectionTypeKeyId);

                if (currentPage !== undefined) {
                    retVal = generateTypeKeysForPage(currentPage, controlTypeId);
                }
            }

            if (currentLevel === SectionLevel.SECTION) {
                // Get all the sections and process them
                for (const page of templateSource.pages) {
                    let currentSection: SourceSectionModel | undefined = page.sections.find((a) => a.typeKey === this.model.sectionTypeKeyId);

                    if (currentSection !== undefined) {
                        retVal = generateTypeKeysForSection(currentSection, controlTypeId);
                        break;
                    }
                }
            }

            if (currentLevel === SectionLevel.SUBPAGE) {
                // Get all the subpages and process them
                for (const page of templateSource.pages) {
                    for (const section of page.sections) {
                        for (const row of section.rows) {
                            // Find the actual sub page
                            let currentSubPage: SourcePageModel | undefined = row.pages.find((a) => a.typeKey === this.model.sectionTypeKeyId);

                            if (currentSubPage !== undefined) {
                                retVal = generateTypeKeysForPage(currentSubPage, controlTypeId);
                                break;
                            }
                        }
                    }
                }
            }

            if (currentLevel === SectionLevel.SUBSECTION) {
                // Get all the subpages and process them
                for (const page of templateSource.pages) {
                    for (const section of page.sections) {
                        for (const row of section.rows) {
                            for (const supPage of row.pages) {
                                let currentSubSection: SourceSectionModel | undefined = supPage.sections.find((a) => a.typeKey === this.model.sectionTypeKeyId);

                                if (currentSubSection !== undefined) {
                                    retVal = generateTypeKeysForSection(currentSubSection, controlTypeId);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return retVal;
    };

    public getQuestionforSection = (typeKeyId: string, controlTypeId: ControlType): string => {
        let retVal: string = "";

        if (this.model.templateSource !== undefined) {
            for (const page of this.model.templateSource.pages) {
                for (const section of page.sections) {
                    for (const question of section.questions) {
                        if (question.controlTypeId === controlTypeId && question.questionTypeKey === typeKeyId) {
                            retVal = question.question;
                        }
                    }
                }
            }
        }

        return retVal;
    };

    public getOptionsforSection = (typeKeyId: string, controlTypeId: ControlType): KeyValuePair<string>[] => {
        let retVal: KeyValuePair<string>[] = [];

        if (this.model.templateSource !== undefined) {
            for (const page of this.model.templateSource.pages) {
                for (const section of page.sections) {
                    for (const question of section.questions) {
                        if (question.controlTypeId === controlTypeId && question.questionTypeKey === typeKeyId) {
                            for (const element of question.options) {
                                let option: KeyValuePair = { key: element.typeKey, text: element.value };
                                retVal.push(option);
                            }
                        }
                    }

                    for (const row of section.rows) {
                        for (const subPage of row.pages) {
                            for (const subSection of subPage.sections) {
                                for (const subQuestion of subSection.questions) {
                                    if (subQuestion.controlTypeId === controlTypeId && subQuestion.questionTypeKey === typeKeyId) {
                                        for (const element of subQuestion.options) {
                                            let option: KeyValuePair = { key: element.typeKey, text: element.value };
                                            retVal.push(option);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return retVal;
    };

    public getOptionsforRepeatableSection(typeKeyId: string, controlTypeId: ControlType): KeyValuePair<any>[] {
        let retVal: KeyValuePair<string>[] = [];
        // just send an empty list back.  Sort later.
        return retVal;
    }

    public isModelValid = (): boolean => {
        let retVal: boolean = true;

        if (this.model.controlTypeId !== ControlType.Paragraph) {
            if (this.model.typeKeyId === undefined || this.model.typeKeyId.length === 0) {
                retVal = false;
                this.setError("typeKeyId", "Please choose a question");
            }
        } else {
            this.setError("typeKeyId", "");
        }

        return retVal;
    };
}
