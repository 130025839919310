import { IExtendedSelectOption } from "Views/ConditionReport/Common/ControlType";
import { Editor } from "tinymce/tinymce";

export const setupQuestionPlaceholderPlugin = (editor: Editor, questions: IExtendedSelectOption[], isPDFEditor: boolean = false) => {
    const PLUGINNAME = "questionplaceholders";
    const MENUNAME = "Placeholders";

    const pdfplaceholders = [
        {
            title: "Standard date",
            value: "BASE_PLACEHOLDER_STDDATE",
        },
        {
            title: "Formatted date",
            value: "BASE_PLACEHOLDER_FORMATTEDDATE",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Page Break Before this point",
            value: "BASE_PLACEHOLDER_PAGEBREAK_BEFORE",
        },
        {
            title: "Page Break after this point",
            value: "BASE_PLACEHOLDER_PAGEBREAK_AFTER",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Unchecked Box ☐",
            value: "☐",
        },
        {
            title: "Checked Box ☒",
            value: "☒",
        },
    ];

    const templatePlaceholders = [
        {
            title: "Standard date",
            value: "BASE_PLACEHOLDER_STDDATE",
        },
        {
            title: "Formatted date",
            value: "BASE_PLACEHOLDER_FORMATTEDDATE",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Site Visit Date",
            value: "QU__Datereport_Answer", // Special Case Placeholder
        },
        {
            title: "Table of Contents",
            value: "BASE_PLACEHOLDER_TABLEOFCONTENTS",
        },
        {
            title: "Page Break Before this point",
            value: "BASE_PLACEHOLDER_PAGEBREAK_BEFORE",
        },
        {
            title: "Page Break after this point",
            value: "BASE_PLACEHOLDER_PAGEBREAK_AFTER",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Contact Name",
            value: "BASE_PLACEHOLDER_CONTACTNAME",
        },
        {
            title: "Contact Phone",
            value: "BASE_PLACEHOLDER_CONTACTPHONE",
        },
        {
            title: "Contact Email",
            value: "BASE_PLACEHOLDER_CONTACTEMAIL",
        },
        {
            title: "|",
            value: "",
        },
        {
            title: "Unchecked Box ☐",
            value: "☐",
        },
        {
            title: "Checked Box ☒",
            value: "☒",
        },
    ];

    const placeholders: any[] = isPDFEditor === true ? pdfplaceholders : templatePlaceholders;

    if (questions.length > 0) {
        for (const element of questions) {
            placeholders.push({ title: element.display, value: element.value });
        }
    }
    if (editor) {
        // For context menu
        editor.ui.registry.addNestedMenuItem(PLUGINNAME, {
            text: MENUNAME,
            getSubmenuItems: () =>
                placeholders.map((ph) => {
                    switch (ph.title) {
                        case "|": {
                            return {
                                type: "separator",
                            };
                        }

                        default:
                            return {
                                type: "menuitem",
                                text: ph.title,
                                onAction: () => {
                                    editor.insertContent(ph.value);
                                },
                            };
                    }
                }),
        });

        // For toolbar
        editor.ui.registry.addMenuButton(PLUGINNAME, {
            text: MENUNAME,
            fetch: (callback) => {
                callback(
                    placeholders.map((ph) => {
                        switch (ph.title) {
                            case "|": {
                                return {
                                    type: "separator",
                                };
                            }

                            default:
                                return {
                                    type: "menuitem",
                                    text: ph.title,
                                    onAction: () => {
                                        editor.insertContent(ph.value);
                                    },
                                };
                        }
                    }),
                );
            },
        });
    }
};
