import { useEffect, useState } from "react";
import { useTimeout } from "Application/Hooks";
import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "@emotion/styled";
import { LoadingSpinner } from "Components/LoadingSpinner";

export const Wrapper = styled.div`
    width: 100%;
    .loading-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .loading-icon {
        border: 5px solid #f3f3f3;
        border-top: 5px solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }

    .loading-text {
        font-size: 2em;
        margin-left: 1em;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

/**
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
interface Props {
    isOpen: boolean;
    delayBeforeOpen?: number;
    spinnerText?: string;
}
export const ThemedLoader: React.FC<Props> = observer((props: Props) => {
    const [show, setShow] = useState(false);
    const { isOpen, delayBeforeOpen } = props;

    const { start, clear } = useTimeout(() => {
        setShow(true);
    }, delayBeforeOpen);

    useEffect(() => {
        if (isOpen) {
            start();
        }
        return () => {
            clear();
        };
    }, []);

    return (
        <Wrapper>
            {show && (
                <div className="loading-page">
                    {/* <div className="loading-icon"></div> */}
                    <LoadingSpinner />
                    <h2 className="loading-text">{props.spinnerText ? props.spinnerText : "Loading..."}</h2>
                </div>
            )}
        </Wrapper>
    );
});
