import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { getFilenameFromContentDisposition } from "Utils/File";
import { ReportDownloadCSVResponse } from "Views/Reports/Models/ReportDownloadCSVResponse";
import { ReportListViewModel } from "../ReportListViewModel";

export class CustomerContactsReportRequest {
    public searchString: string = "";
    public filterByCustomerType: number[] = [];
}

export class DownloadCustomerContactsReportEndpoint extends Endpoint<CustomerContactsReportRequest, ReportDownloadCSVResponse> {
    constructor(path: string) {
        super();
        this.verb(Http.Post);
        this.path(path);
        this.responseIsBlob(true);
        this.SetContentType("text/csv");
    }

    public async HandleRequestAsync(viewModel: ReportListViewModel): Promise<any> {
        let request = new CustomerContactsReportRequest();

        request.searchString = viewModel.customerContactsSearchKeyword;

        request.filterByCustomerType = [];

        request.filterByCustomerType = viewModel.filterByType.map((item) => {
            return item;
        });

        return request;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        let retVal: boolean = true;
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        //Return your model
        return retVal;
    }
}
