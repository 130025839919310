import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ConditionReportTemplateListItemModel } from "../ConditionReportTemplateListItem";
import { TemplateListViewViewModel } from "../TemplateListViewViewModel";
import { AppUrls } from "AppUrls";

class GetTemplateListRequest {
    pageSize: number = 50;
    pageNumber: number = 1;
    searchString: string = "";
    orderBy: string = "";
    columnName: string = "";

    public constructor() {}
}

export class GetTemplateListResponse {
    public queryTotalCount: number = 0;
    public templates: ConditionReportTemplateListItemModel[] = [];

    constructor() {}
}

export class GetTemplateListEndpoint extends Endpoint<GetTemplateListRequest, GetTemplateListResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportTemplate.GetTemplateList);
    }

    public async HandleRequestAsync(model: TemplateListViewViewModel): Promise<any> {
        let request = new GetTemplateListRequest();
        request.columnName = model.columnName;
        request.orderBy = model.orderBy;
        request.pageNumber = model.pageNumber;
        request.pageSize = model.pageSize;
        request.searchString = model.searchString;
        return request;
    }

    public async HandleResponseAsync(response: GetTemplateListResponse): Promise<GetTemplateListResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response; //TODO CMR
    }
}
