import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ThemedLoader } from "Application";
import { ConditionReportAddEditViewModel } from "./ConditionReportAddEditViewModel";
import { useParams } from "react-router";
import { EditConditionReport, NewConditionReport, UploadImageBox } from "./ConditionReportAddEditView.styling";
import { useDropzone } from "react-dropzone";
import { H1, P } from "Components/Primitives/TextElements/TextElements";
import { BaseWrapper } from "Components/BaseWrapper";

export const ConditionReportAddEditView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ConditionReportAddEditViewModel());
    const [newConditionReport, setNewConditionReport] = useState(false);
    let params = useParams();

    let reportId: string = params.reportId ?? "new";

    useEffect(() => {
        if (reportId && reportId === "new") {
            setNewConditionReport(true);
        } else {
            let _ = viewModel.loadConditionReportAsync(reportId);
        }

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    function JsonDropzone() {
        const onDrop = useCallback((acceptedFiles: File[]) => {
            // Do something with the files
            if (acceptedFiles.length == 1) {
                viewModel.addJsonFile(acceptedFiles[0]);
            } else if (acceptedFiles.length > 1) {
                alert("only one json can be uploaded at a time");
            }
        }, []);
        const { getRootProps, getInputProps } = useDropzone({
            onDrop,
            accept: { "application/json": [] },
            maxFiles: 1,
        });
        return (
            <>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <UploadImageBox>
                        <P>Click or drag a condtion report file (.JSON)</P>
                    </UploadImageBox>
                </div>
            </>
        );
    }

    return (
        <BaseWrapper>
            {viewModel.isProcessing === true ? (
                <ThemedLoader spinnerText="Uploading..." isOpen={true} />
            ) : (
                <>
                    {newConditionReport === true && (
                        <NewConditionReport>
                            <H1>Add Condition Report</H1>
                            <Box>
                                <p>Click, or drop a json file on the box below to upload the condition report raw file:</p>
                                <p>Notes: </p>
                                <div style={{ paddingLeft: "15px" }}>
                                    <ul>
                                        <li>Depending on the number of images and the size of the file, this can take several minutes.</li>
                                        <li>Please ensure the json file contains the image data and not just the filename</li>
                                    </ul>
                                </div>
                                <JsonDropzone />
                            </Box>
                        </NewConditionReport>
                    )}
                </>
            )}
            {newConditionReport === false && (
                <EditConditionReport>
                    <Box>TODO Condition Report Edit View</Box>
                </EditConditionReport>
            )}
        </BaseWrapper>
    );
});
