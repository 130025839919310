import styled from "@emotion/styled";
import { Box, theme } from "Application";

export const ActionModalWrapper = styled(Box)``;

export const ActionModalHeader = styled(Box)`
    height: 50px;
    padding: 15px;
    background-color: ${theme.palette.common.sharmansBlue};
    display: flex;
    justify-content: space-between;
`;

export const ActionModalBody = styled(Box)`
    padding: 15px;
    min-width: 600px;

    textarea {
        white-space: normal !important;
        min-height: 200px;
    }
`;

export const HeaderBox = styled(Box)`
    display: flex;
    align-items: center;
`;

export const ButtonRow = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ButtonBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;

    .last-button {
        margin-left: 15px;
    }
`;
