import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ThemedLoader, formatDate } from "Application";
import { SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { createColumnHelper } from "@tanstack/react-table";
import {
    BooleanTickOrCrossCell,
    CenteredTextCell,
    ContactNamePrimaryContactCell,
    EmailWithIconCell,
    HyphenIfNoValueCell,
    PhoneNumberWithIconCell,
} from "Utils/CellFormatComponents";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import ContactIcon from "Assets/Icons/ContactIcon.svg";
import { AddContactModal } from "Views/Directory/Customers/NewCustomer/Modals/AddContactModalView";
import { CustomerContactsViewModel } from "Views/Directory/Customers/NewCustomer/CustomerContacts/CustomerContactsViewModel";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";
import { ContactStatusTypeEnum, ContactStatusTypeHelper } from "Models/Contacts/ContactStatusTypeEnun";
import { ContactStatusTableBox } from "Models/Contacts/ContactStatus.style";
import { ViewContactModal } from "Views/Directory/Contacts/Modals/ViewContactModal";
import { PageTabWrapper } from "Styles/BaseStyles";

interface IContactsView {
    id?: string;
    contactStatusTypes: ContactStatusDropdownItem[];
    documentTypes: IDocumentType[];
    contactCountCallBack: (newCount: number) => void;
}

export const ContactsView: React.FC<IContactsView> = observer(({ id, contactCountCallBack, contactStatusTypes, documentTypes }) => {
    // ViewModel same as for New Customer as the same logic is needed!
    const [viewModel] = useState(new CustomerContactsViewModel(contactCountCallBack, id));
    useEffect(() => {
        viewModel.SortContacts();
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.setValue("columnName", columnName);
        viewModel.setValue("orderBy", orderBy);
        viewModel.SortContacts();
    };

    function onRowClick(Id: string | number): void {
        viewModel.openViewContactModal(String(Id));
    }

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("name", {
            header: () => "Name",
            cell: (info) => <ContactNamePrimaryContactCell name={info.row.original.name} primaryContact={info.row.original.primaryContact} />,
        }),

        columnHelper.accessor("jobTitle", {
            header: () => "Job title",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("phone", {
            header: () => "Contact phone no.",
            cell: (info) => <PhoneNumberWithIconCell value={info.getValue()} />,
        }),
        columnHelper.accessor("email", {
            header: () => "Contact email address",
            cell: (info) => <EmailWithIconCell value={info.getValue()} />,
        }),
        columnHelper.accessor("datePlygeneTrained", {
            header: () => "Plygene trained",
            cell: (info) =>
                (info.row.original.status === ContactStatusTypeEnum.PlygeneTrained && info.renderValue() === null) ||
                (info.row.original.status === ContactStatusTypeEnum.TrainedBoth && info.renderValue() === null) ? (
                    <BooleanTickOrCrossCell value={true} />
                ) : (
                    <CenteredTextCell greenText={true} value={info.renderValue() !== null ? formatDate(new Date(info.renderValue())) : null} />
                ),
        }),
        columnHelper.accessor("dateCoatingsTrained", {
            header: () => "Coatings trained",
            cell: (info) =>
                (info.row.original.status === ContactStatusTypeEnum.CoatingsTrained && info.renderValue() === null) ||
                (info.row.original.status === ContactStatusTypeEnum.TrainedBoth && info.renderValue() === null) ? (
                    <BooleanTickOrCrossCell value={true} />
                ) : (
                    <CenteredTextCell greenText={true} value={info.renderValue() !== null ? formatDate(new Date(info.renderValue())) : null} />
                ),
        }),

        columnHelper.accessor("datePlygeneCPD", {
            header: () => "Plygene CPD",
            cell: (info) =>
                (info.row.original.status === ContactStatusTypeEnum.PlygeneTrained && info.renderValue() === null) ||
                (info.row.original.status === ContactStatusTypeEnum.TrainedBoth && info.renderValue() === null) ? (
                    <BooleanTickOrCrossCell value={true} />
                ) : (
                    <CenteredTextCell greenText={true} value={info.renderValue() !== null ? formatDate(new Date(info.renderValue())) : null} />
                ),
        }),

        columnHelper.accessor("dateCoatingsCPD", {
            header: () => "Coatings CPD",
            cell: (info) =>
                (info.row.original.status === ContactStatusTypeEnum.CoatingsTrained && info.renderValue() === null) ||
                (info.row.original.status === ContactStatusTypeEnum.TrainedBoth && info.renderValue() === null) ? (
                    <BooleanTickOrCrossCell value={true} />
                ) : (
                    <CenteredTextCell greenText={true} value={info.renderValue() !== null ? formatDate(new Date(info.renderValue())) : null} />
                ),
        }),

        columnHelper.accessor("createdDate", {
            header: () => "Date added",
            cell: (info) => <CenteredTextCell value={info.getValue() !== null ? formatDate(new Date(info.getValue())) : ""} />,
        }),
        // columnHelper.accessor("status", {
        //     header: () => "Status",
        //     cell: (info) => (
        //         <ContactStatusTableBox color={ContactStatusTypeHelper.GetColor(info.getValue())}>{ContactStatusTypeHelper.GetText(info.getValue())}</ContactStatusTableBox>
        //     ),
        // }),
        columnHelper.accessor("id", {
            header: () => "Actions",
            cell: (info) => (
                <Box className="action-cell">
                    <EditButton id={info.getValue().toString()} onClick={viewModel.openEditContactModal} />
                    <DeleteButton id={info.getValue().toString()} onClick={viewModel.deleteContactCheck} />
                </Box>
            ),
            enableSorting: false,
        }),
    ];

    return (
        <PageTabWrapper>
            <AddContactModal
                isOpen={viewModel.showAddContactModal}
                closeModal={viewModel.closeAddNewContactModal}
                viewModel={viewModel}
                parentViewModel={viewModel}
                addOrEdit={viewModel.modalType}
                contactStatusTypes={contactStatusTypes}
                documentTypes={documentTypes}
            />
            {viewModel.showViewContactModal && (
                <ViewContactModal isOpen={viewModel.showViewContactModal} contactId={viewModel.contactIdToView} closeModal={viewModel.closeViewContactModal} />
            )}
            <AreYouSureModal
                title="Delete Contact"
                message="Are you sure you want to delete this contact?"
                isOpen={viewModel.areYouSureModelOpen}
                onCancel={viewModel.closeAreYouSureModal}
                onConfirm={viewModel.confirmDeleteContact}
            />

            {viewModel.isProcessing === true ? (
                <ThemedLoader spinnerText="Loading..." isOpen={true} />
            ) : (
                <>
                    <Box className="tabheader" flexBox style={{ justifyContent: "space-between" }}>
                        <H5 pt="12px">
                            <strong>Contacts</strong>
                        </H5>
                        <ButtonUnder
                            displayName=" Add contact"
                            paletteColor="ButtonBlue" // TODO CMR{viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                            icon={<img src={ContactIcon} />}
                            boxShadow={true}
                            command={viewModel.openAddNewContactModal}
                        />
                    </Box>
                    <Box className="tabheaderbuffer"></Box>
                    <SharmansTable columns={columns} data={viewModel.getcontacts} onSortChange={updateSorting} onRowClick={onRowClick} />
                    {viewModel.contacts.length === 0 && (
                        <P pt="40px" style={{ textAlign: "center" }}>
                            This customer has no contacts
                        </P>
                    )}
                </>
            )}
        </PageTabWrapper>
    );
});
