import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { PreventativeActionViewModel } from "./PreventativeActionViewModel";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import { SharmansTable } from "Components/Primitives/DataTable/SharmansTable";
import { createColumnHelper } from "@tanstack/react-table";
import NewIcon from "Assets/Icons/newRoundButtonIcon.svg";
import { useParams } from "react-router";
import { AddEditActionModal } from "../Modals/AddEditActionModal";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { formatDate, formatDateTime } from "Utils/Formats";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { CenteredCell } from "Utils/CellFormatComponents";
import { PageTabWrapper } from "Styles/BaseStyles";

interface IPreventativeActionViewProps {
    preventativeActionCountCallBack: (newCount: number) => void;
}

export const PreventativeActionView: React.FC<IPreventativeActionViewProps> = observer(({ preventativeActionCountCallBack }) => {
    const [viewModel] = useState(() => new PreventativeActionViewModel());
    const params = useParams();

    useEffect(() => {
        viewModel.init(preventativeActionCountCallBack);
        if (params.complaintid) {
            viewModel.loadActionList(params.complaintid);
            viewModel.setComplaintId(params.complaintid);
        }
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("createdDate", {
            size: 100,
            header: () => "Created Date",
            cell: (info) => {
                return formatDate(new Date(info.getValue()));
            },
        }),

        columnHelper.accessor("action", {
            size: 600,
            header: () => "Action",
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("author", {
            size: 150,
            header: () => "Author",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("lastUpdated", {
            size: 250,
            header: () => "Last Updated",
            cell: (info) => {
                return formatDateTime(new Date(info.getValue()));
            },
        }),

        columnHelper.accessor("id", {
            header: () => "Actions",
            cell: (info) => (
                <CenteredCell style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <EditButton id={info.getValue()} onClick={viewModel.openEditActionModal} />
                    <DeleteButton id={info.getValue()} onClick={viewModel.deleteActionOpenAreYouSureModal} />
                </CenteredCell>
            ),
        }),
    ];

    return (
        <PageTabWrapper>
            {viewModel.isModalOpen === true && (
                <AddEditActionModal
                    title={viewModel.addOrEditModal === ModalType.Add ? "Add preventative action" : "Edit preventative action"}
                    inputCommand={viewModel.actionInputCommand}
                    buttonText={viewModel.addOrEditModal === ModalType.Add ? "Add action" : "Save action"}
                    inputValue={() => viewModel.getValue("preventativeAction")}
                    validationMessage={() => viewModel.getError("preventativeAction")}
                    isOpen={viewModel.isModalOpen}
                    onConfirm={viewModel.upsertAction}
                    onCancel={viewModel.closeModal}
                />
            )}
            {viewModel.isAreYouSureModalOpen === true && (
                <AreYouSureModal
                    title={"Delete action"}
                    message={"Are you sure you want to delete this action?"}
                    isOpen={viewModel.isAreYouSureModalOpen}
                    onConfirm={viewModel.proceedWithDelete}
                    onCancel={viewModel.cancelDelete}
                />
            )}
            <Box className="tabheader" flexBox style={{ justifyContent: "space-between" }}>
                <H5 pt="15px">Preventative Action</H5>
                <ButtonUnder
                    command={viewModel.openAddModal}
                    displayName=" Add preventative action"
                    paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                    boxShadow={true}
                    icon={<img src={NewIcon} />}
                />
            </Box>
            <Box className="tabheaderbuffer"></Box>
            <Box>
                <SharmansTable columns={columns} data={viewModel.getList} manualSorting={false} />
                {viewModel.getList.length === 0 && (
                    <P pt="40px" style={{ textAlign: "center" }}>
                        There are no preventative actions to display.
                    </P>
                )}
            </Box>
        </PageTabWrapper>
    );
});
