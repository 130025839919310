import { KeyValuePair } from "@shoothill/core/dist/Models/KeyValuePair";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";

export enum ComplaintStatusEnum {
    UnResolved = 1,
    Resolved = 2,
    Both = 3,
}

export module ComplaintStatusEnumHelpers {
    export const fromString = (value: string): ComplaintStatusEnum => {
        let retVal: ComplaintStatusEnum = ComplaintStatusEnum.Both;

        if (value.toLocaleLowerCase() === "unresolved" || value === "1") {
            retVal = ComplaintStatusEnum.UnResolved;
        } else if (value.toLocaleLowerCase() === "resolved" || value === "2") {
            retVal = ComplaintStatusEnum.Resolved;
        }

        return retVal;
    };

    export const getText = (value: ComplaintStatusEnum) => {
        let retVal: string = "UnResolved";
        if (value == ComplaintStatusEnum.UnResolved) {
            retVal = "UnResolved";
        } else if (value == ComplaintStatusEnum.Resolved) {
            retVal = "Resolved";
        } else if (value == ComplaintStatusEnum.Both) {
            retVal = "In-Both";
        }

        return retVal;
    };

    export const getForDropdown = (): KeyValuePair[] => {
        let retVal: KeyValuePair[] = [];

        for (const key in ComplaintStatusEnum) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ComplaintStatusEnum;
                retVal.push({
                    text: ComplaintStatusEnumHelpers.getText(item),
                    key: keyAsInt.toString(),
                });
            }
        }

        return retVal;
    };

    export const getStatusForStatusDropdown = (): KeyValuePairExtended[] => {
        let retVal: KeyValuePairExtended[] = [];

        for (const key in ComplaintStatusEnum) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ComplaintStatusEnum;
                retVal.push({
                    text: ComplaintStatusEnumHelpers.getText(item),
                    key: keyAsInt.toString(),
                    class: "complaintstatus-all",
                });
            }
        }

        return retVal;
    };
}
