import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ContactsListViewModel } from "../ContactsListViewModel";
import { ContactsListItemModel } from "../ContactsListItemModel";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";
import { AppUrls } from "AppUrls";
class DeleteContactByIdAndReturnListRequest {
    // Contact Id to delete
    Id: Guid = "";

    // For returning list after deletion
    orderBy: string = "";
    columnName: string = "";
    pageSize: number = 1;
    pageNumber: number = 1;
    searchString: string = "";
    filterByType: number[] | string[] = [];
    filterByCustomer: string | null = null;
    filterByPlygeneTrained: string[] = [];
    filterByCoatingsTrained: string[] = [];
}

class DeleteContactByIdResponse {
    contacts: ContactsListItemModel[] = [];
    contactCount: number = 0;
    customerTypesList: CustomerTypeDropdownItem[] = [];
    customerList: CustomerTypeDropdownItem[] = [];
    contactStatusTypes: ContactStatusDropdownItem[] = [];
}

export class DeleteContactByIdAndReturnListEndpoint extends Endpoint<DeleteContactByIdAndReturnListRequest, DeleteContactByIdResponse> {
    public viewModel: ContactsListViewModel;

    constructor(viewModel: ContactsListViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path("/api/contact/deletecontactbyid");
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new DeleteContactByIdAndReturnListRequest();

        request.Id = this.viewModel.contactToDeleteId;

        request.orderBy = this.viewModel.orderBy;
        request.columnName = this.viewModel.columnName;
        request.pageSize = this.viewModel.pageSize;
        request.pageNumber = this.viewModel.pageNumber;
        request.searchString = this.viewModel.searchString;
        request.filterByType = this.viewModel.filterByType;
        request.filterByCustomer = this.viewModel.filterBycustomer;
        request.filterByPlygeneTrained = this.viewModel.filterByPlygeneTrained;
        request.filterByCoatingsTrained = this.viewModel.filterByCoatingsTrained;

        return request;
    }

    public async HandleResponseAsync(response: DeleteContactByIdResponse): Promise<any> {
        Logger.logInformation("Response", response);
        this.viewModel.loadContacts(response.contacts);
        this.viewModel.loadCustomerTypesDropdown(response.customerTypesList);
        this.viewModel.customers.replace(response.customerList);
        this.viewModel.contactCount = response.contactCount;
        this.viewModel.contactStatusTypes.replace(response.contactStatusTypes);
        //Return your model
        return response;
    }
}
