import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class EditCSSModel extends ModelBase<EditCSSModel> {
    public id: string = "";
    public css: string = "";

    constructor() {
        super();
        makeObservable(this, {
            // Already in the bass id: observable,
            css: observable,
        });
    }

    public clear = () => {
        this.id = "";
    };
}

export class EditCSSModelValidator extends Validator<EditCSSModel> {
    constructor() {
        super();
        this.ruleFor("css").notEmpty();
    }
}
