import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { EditCSSModel } from "../AddEdit/Modals/EditCSSModel";

class UpsertTemplateCSSFileRequest {
    public templateId: string = "";
    public css: string = "";
}

export class UpsertTemplateCSSFileEndpoint extends Endpoint<UpsertTemplateCSSFileRequest, boolean> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportTemplate.UpsertTemplateCSSFile);
    }

    public async HandleRequestAsync(model: EditCSSModel): Promise<any> {
        let request = new UpsertTemplateCSSFileRequest();
        request.templateId = model.id;
        request.css = model.css;
        return request;
    }

    public async HandleResponseAsync(response: boolean): Promise<boolean> {
        Logger.logInformation("Response", response);
        //Return your response
        return response;
    }
}
