import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, Label, RelayCommand, formatDate } from "Application";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { TasksListWrapper } from "./TasksList.styles";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { TasksListViewModel } from "./TasksListViewModel";
import { FilteringTopSectionGenericView } from "Components/FilteringTopSectionGeneric";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair } from "@shoothill/core";
import { TimeScaleHelper } from "Models/Timescale";
import { UrgencyHelpers } from "./UrgencyEnum";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { BooleanTickOrCrossCell, CenteredCell, CenteredTextCell, ExpandEllipsis } from "Utils/CellFormatComponents";
import { ViewTasksModal } from "Views/Work/Master/View/Task/Modals/ViewTasksModal";
import { AppUrls } from "AppUrls";
import { useNavigate } from "react-router";
import moment from "moment";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";
import { TasksListItemModel } from "./TasksListItemModel";

export const TasksListView: React.FC = observer(() => {
    const [viewModel] = useState(() => TasksListViewModel.Instance);
    let debounceTimer: NodeJS.Timeout | null = null;
    const columnHelper = createColumnHelper<any>();
    const navigate = useNavigate();

    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        viewModel.loadTasksAsync();
        return () => {
            // Clean up after yourself
            // Don't clear, we want to keep this since using a singleton viewModel.clear();
            // viewModel.reset();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const getTrClasses = (id: any): string => {
        let retVal: string = "";
        const today = moment().startOf("day");
        if (viewModel.getTasks.length > 0) {
            const rowInfo: TasksListItemModel | undefined = viewModel.getTasks.find((x) => x.id === id);

            if (rowInfo !== undefined) {
                const dueDate = moment(rowInfo.dueDateTime);
                const isPastDue = dueDate.isBefore(today, "day");

                if (isPastDue === true && rowInfo.isComplete === false) {
                    retVal = "pastdue";
                }

                // Now sort out if the next item is overdue to sort out your bottom border
                if (isPastDue === false || rowInfo.isComplete === true) {
                    const index: number = viewModel.getTasks.findIndex((x) => x.id === id);

                    if (index < viewModel.getTasks.length - 1) {
                        const nextRowInfo: TasksListItemModel | undefined = viewModel.getTasks.find((x) => x.id === viewModel.getTasks[index + 1].id);
                        if (nextRowInfo !== undefined) {
                            const nextDueDate = moment(nextRowInfo.dueDateTime);
                            const isNextPastDue = nextDueDate.isBefore(today, "day");

                            if (isNextPastDue === true && nextRowInfo.isComplete === false) {
                                retVal = "pastduenext";
                            }
                        }
                    }
                }
            }
        }

        return retVal;
    };

    const columns = [
        columnHelper.accessor("subTaskTypeName", {
            header: () => "Category",
            size: 100,
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("description", {
            size: 380,
            header: () => "Description",
            cell: (info) => <ExpandEllipsis text={info.getValue()} />,
        }),

        columnHelper.accessor("assignedUserName", {
            header: () => "Assigned To",
            size: 50,
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("source", {
            header: () => "Source",
            size: 200,
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("startDateTime", {
            size: 75,
            header: () => "Date Set",
            cell: (info) => <CenteredTextCell value={info.getValue() !== null ? formatDate(new Date(info.getValue())) : " - "} />,
        }),

        columnHelper.accessor("dueDateTime", {
            size: 75,
            header: () => "Due Date",
            cell: (info: CellContext<any, any>) => {
                const dueDate = moment(info.getValue());
                const today = moment().startOf("day");
                const isPastDue: boolean = dueDate.isBefore(today, "day");
                const isComplete: boolean = info.row.original.isComplete;

                return (
                    <Box>
                        <Label style={{ color: isPastDue === true && isComplete === false ? "#CE0E2D" : "", fontWeight: isPastDue === true && isComplete === false ? 800 : 500 }}>
                            {info.getValue() !== null ? formatDate(dueDate.toDate()) : " - "}
                        </Label>
                    </Box>
                );
            },
        }),

        columnHelper.accessor("isUrgent", {
            size: 30,
            header: () => "Urgent",
            cell: (info) => (
                <CenteredCell>
                    <BooleanTickOrCrossCell value={info.renderValue()} alignLeft={false} />
                </CenteredCell>
            ),
        }),

        columnHelper.accessor("id", {
            size: 30,
            enableSorting: false,
            header: () => "Actions",
            cell: (info) => (
                <CenteredCell>
                    <EditButton id={info.getValue()} onClick={onEditClick} />
                </CenteredCell>
            ),
        }),
    ];

    const onEditClick = (e: any, id: string) => {
        navigate(AppUrls.Client.Work.Tasks.AddEdit[3].replace(":tasktype", "tsmlist").replace(":taskid", id!));
    };

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand(
        (value: subHeadingButton) => {
            switch (value) {
                case subHeadingButton.Download:
                    viewModel.downloadCSVAsync();
                    break;
                default:
            }
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const setShowFromDate: ICommand = new RelayCommand(
        (date: Date) => {
            viewModel.setShowFromDate(date);
            viewModel.loadTasksAsync();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const setShowToDate: ICommand = new RelayCommand(
        (date: Date) => {
            viewModel.setShowToDate(date);
            viewModel.loadTasksAsync();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const updateTimescale: ICommand = new RelayCommand(
        (timescale: KeyValuePair) => {
            viewModel.setTimeScale(timescale);
            viewModel.loadTasksAsync();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const FilteringTopSection = (
        <FilteringTopSectionGenericView
            searchValue={viewModel.keywordSearch}
            searchCommand={viewModel.updateKeywordSearch}
            timescaleOptions={TimeScaleHelper.getOptionsDropdown()}
            timescaleCommand={updateTimescale}
            timescaleValue={viewModel.getTimescale}
            dateFromCommand={setShowFromDate}
            dateFromValue={viewModel.getShowFrom}
            dateToCommand={setShowToDate}
            dateToValue={viewModel.getShowTo}
            isDisabled={!viewModel.getCanExecute}
            displayName="Start typing to search by category, assigned to, source etc..."
        />
    );

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.loadTasksAsync();
            setIsFiltering(false);
        }, 1200);
    };

    // Task Type

    const updateTaskTypeFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateTasksTypeFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const taskTypeSelectAll = (): void => {
        viewModel.tasksTypeSelectAll();
        viewModel.loadTasksAsync();
    };

    const taskTypeSelectNone = (): void => {
        viewModel.tasksTypeSelectNone();
        viewModel.loadTasksAsync();
    };

    // Category

    const updateCategoryFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateCategoryFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const categorySelectAll = (): void => {
        viewModel.categorySelectAll();
        viewModel.loadTasksAsync();
    };

    const categorySelectNone = (): void => {
        viewModel.categorySelectNone();
        viewModel.loadTasksAsync();
    };

    // Assigned to

    const updateAssignedToFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateAssignedToFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const assignedToSelectAll = (): void => {
        viewModel.assignedToSelectAll();
        viewModel.loadTasksAsync();
    };

    const assignedToSelectNone = (): void => {
        viewModel.assignedToSelectNone();
        viewModel.loadTasksAsync();
    };

    // Urgency

    const updateUrgencyFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateUrgencyFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const urgencySelectAll = (): void => {
        viewModel.urgencySelectAll();
        viewModel.loadTasksAsync();
    };

    const urgencySelectNone = (): void => {
        viewModel.urgencySelectNone();
        viewModel.loadTasksAsync();
    };

    // Completed

    const updateCompletedFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateCompletedFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const completedSelectAll = (): void => {
        viewModel.completedSelectAll();
        viewModel.loadTasksAsync();
    };

    const completedSelectNone = (): void => {
        viewModel.completedSelectNone();
        viewModel.loadTasksAsync();
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr 1fr"}>
            <MultiEditSelect
                command={updateTaskTypeFiltering}
                displayName="Task Type"
                options={viewModel.getTaskType}
                value={() => viewModel.getTasksFilter}
                showSelectAllNone={true}
                selectAll={taskTypeSelectAll}
                selectNone={taskTypeSelectNone}
                isDisabled={viewModel.isProcessing}
                isPreSticky={true}
            />

            <MultiEditSelect
                command={updateCategoryFiltering}
                displayName="Category"
                options={viewModel.getCategory}
                value={() => viewModel.getCategoryFilter}
                showSelectAllNone={true}
                selectAll={categorySelectAll}
                selectNone={categorySelectNone}
                isDisabled={viewModel.isProcessing}
                isPreSticky={true}
            />

            <MultiEditSelect
                command={updateAssignedToFiltering}
                displayName="Assigned to"
                options={viewModel.getAssignedTo}
                value={() => viewModel.getAssignedToFilter}
                showSelectAllNone={true}
                selectAll={assignedToSelectAll}
                selectNone={assignedToSelectNone}
                isDisabled={viewModel.isProcessing}
                isPreSticky={true}
            />

            <MultiEditSelect
                command={updateUrgencyFiltering}
                displayName="Urgency"
                options={UrgencyHelpers.getUrgencyForDropdown()}
                value={() => viewModel.getUrgencyFilter}
                showSelectAllNone={true}
                selectAll={urgencySelectAll}
                selectNone={urgencySelectNone}
                isDisabled={viewModel.isProcessing}
                isPreSticky={true}
            />
            {viewModel.IsAdmin && (
                <>
                    <MultiEditSelect
                        command={updateCompletedFiltering}
                        displayName="Completed"
                        options={viewModel.getCompletedDropdown}
                        value={() => viewModel.getCompletedFilter}
                        showSelectAllNone={true}
                        selectAll={completedSelectAll}
                        selectNone={completedSelectNone}
                        isDisabled={viewModel.isProcessing}
                        isPreSticky={true}
                    />
                </>
            )}
        </FilteringBottomSectionBox>
    );

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.loadTasksAsync();
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;
        viewModel.loadTasksAsync();
    };

    function onRowClick(Id: string | number): void {
        viewModel.openViewTasksModal(Id);
    }

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.loadTasksAsync();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.loadTasksAsync();
                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.loadTasksAsync();
                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.loadTasksAsync();
                break;
            default:
        }
    };

    return (
        <>
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} downloadButtonText="Download as csv" />
            <TasksListWrapper>
                <H2 pb="10px">Tasks</H2>
                {/* <SelectedTable> */}
                <SharmansTable
                    columns={columns}
                    data={viewModel.getTasks}
                    filteringElementsTopSection={FilteringTopSection}
                    filteringElementsBottomSection={FilteringBottomSection}
                    onSortChange={updateSorting}
                    showPagination={true}
                    totalRowCount={viewModel.tasksCount}
                    onChangePage={onChangePage}
                    onChangeRowPerPage={onChangeRowPerPage}
                    totalPageCount={viewModel.pageCount}
                    pageNumber={viewModel.pageNumber}
                    pageSize={viewModel.pageSize}
                    onRowClick={onRowClick}
                    clearFilteringCommand={viewModel.clearFiltering}
                    isProcessing={viewModel.isProcessing}
                    getTrClassesCallback={getTrClasses}
                />
                {/* </SelectedTable> */}
            </TasksListWrapper>

            {/* Tasks Info Callout */}
            <ViewTasksModal isOpen={viewModel.showViewTaskModal} closeModal={viewModel.closeViewTaskModal} viewModel={viewModel} />
        </>
    );
});
