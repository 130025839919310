import { action, makeObservable, observable } from "mobx";

export class SectionAnswer {
    public id: number | null = null;
    public questionId: number | null = null;
    public answer: string = "";

    constructor() {
        makeObservable(this, {
            id: observable,
            questionId: observable,
            answer: observable,
            clear: action,
            load: action,
        });
    }

    public load(answer: SectionAnswer) {
        this.clear();

        if (answer !== undefined) {
            this.id = answer.id;
            this.questionId = answer.questionId;
            this.answer = answer.answer;
        }
    }

    public clear = () => {
        this.id = null;
        this.questionId = null;
        this.answer = "";
    };
}
