import React from "react";
import { ICommand } from "Application";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import { observer } from "mobx-react-lite";

import { DocumentViewerView } from "./View/DocumentViewerView";
import { Footer } from "./View/Components/Footer";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
interface IDocumentViewerModalProps {
    documentToView: IDocumentUploaded | null;
    onDismissCommand: ICommand;
}
// Behaviour: This modal is used to preview a document.
// The DocumentViewerView will trigger a download of the document.
export const DocumentViewerModal: React.FC<IDocumentViewerModalProps> = observer(({ onDismissCommand, documentToView }) => {
    const isModalOpen = documentToView !== null;

    // Note: Short-circuiting the modal to remove unnecessary rendering.
    return (
        <>
            {isModalOpen && (
                <ModalGenericWithHeader isOpen stickyBar={<Footer onDismissCommand={onDismissCommand} />} closeModalCommand={onDismissCommand} noBodyMargin>
                    <DocumentViewerView documentToView={documentToView} />
                </ModalGenericWithHeader>
            )}
        </>
    );
});
