import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { LinkedMultiSiteListItem } from "./LinkedMultiSiteListItem";

export class LinkedMultiSiteModel extends ModelBase<LinkedMultiSiteModel> {
    public id: string = "";
    public customerId: Guid = "";
    public linkedMultiSiteList: IObservableArray<LinkedMultiSiteListItem> = observable([]);
    constructor() {
        super();
        makeObservable(this, { customerId: observable });
    }
}

export class LinkedMultiSiteModelValidator extends Validator<LinkedMultiSiteModel> {
    constructor() {
        super();
    }
}
