import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand, Input, RelayCommand, formatDate } from "Application";
import { TSMNoteListItemViewModel } from "./TSMNoteListItemViewModel";
import { TsmNoteListItem } from "./Endpoints/TSMNotesUpsertEndpoint";
import { TSMListHorizontalLineDashed, TSMModalListText } from "../TSMModal.styles";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { TSMNotesModalViewModel } from "./TSMNotesModalViewModel";
import Tick from "Assets/Icons/SmallTickCircleIcon.svg";
import { TSMNotesModalModel } from "./TSMNotesModalModel";
import { Spinner } from "Components/General/Spinner";
import { LoadingSpinner } from "Components/LoadingSpinner";
import { formatTime } from "Utils/Formats";

interface IProps {
    item: TsmNoteListItem;
    itemSelectedForEditing: number | null;
    parentViewModel: TSMNotesModalViewModel;
}

export const TSMNoteListIem: React.FC<IProps> = observer((props) => {
    const [viewModel] = useState(() => new TSMNoteListItemViewModel());
    useEffect(() => {
        viewModel.setValue("noteforEditing", props.item.note);
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const saveNoteCommand: ICommand = new RelayCommand(() => {
        if (viewModel.isModelValid()) {
            let editedModel = new TSMNotesModalModel();
            editedModel.noteId = props.item.id;
            editedModel.note = viewModel.getValue("noteforEditing");
            editedModel.rowVersion = props.item.rowVersion;
            props.parentViewModel.saveNote(editedModel);
        }
    });

    return (
        <>
            <TSMModalListText>{`${formatDate(new Date(props.item.createdDate))} @ ${formatTime(new Date(props.item.createdDate))}`}</TSMModalListText>
            {props.item.id === props.itemSelectedForEditing ? (
                <Box p="8px 5px 16px 0px">
                    <Input
                        command={viewModel.setNoteCommand}
                        multiline={true}
                        value={() => viewModel.getValue("noteforEditing")}
                        style={{ whiteSpace: "pre-line", overflow: "auto" }}
                        validationMessage={() => viewModel.getError("noteforEditing")}
                    />
                </Box>
            ) : (
                <TSMModalListText>{props.item.note}</TSMModalListText>
            )}
            <TSMModalListText>{props.item.author}</TSMModalListText>
            <Box p="16px 0px" flexBox style={{ justifyContent: "center" }}>
                {props.item.id === props.itemSelectedForEditing ? (
                    <Box>
                        {props.parentViewModel.isProcessing ? (
                            <LoadingSpinner />
                        ) : (
                            <Button command={saveNoteCommand} displayName=" Save" paletteColor="ButtonGreen" icon={<img src={Tick} />} />
                        )}
                    </Box>
                ) : (
                    <Box grid dc="1fr 1fr">
                        <DeleteButton id={props.item.id.toString()} onClick={props.parentViewModel.deleteNoteCommand} />
                        <EditButton id={props.item.id.toString()} onClick={props.parentViewModel.editNoteCommand} />
                    </Box>
                )}
            </Box>
            <TSMListHorizontalLineDashed />
        </>
    );
});
