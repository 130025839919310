import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { GetListItemsForConditionReportResponse } from "./GetListItemsForConditionReportIdEndpoint";
import { ConditionReportPdfListViewModel } from "../ConditionReportPdfListViewModel";

class DeletePDFByIdRequest {
    public id: string = "";
    public conditionReportId: string = "";
    pageSize: number = 50;
    pageNumber: number = 1;
    searchString: string = "";
    orderBy: string = "";
    columnName: string = "";

    public constructor() {}
}

export class DeletePDFByIdEndpoint extends Endpoint<DeletePDFByIdRequest, GetListItemsForConditionReportResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportPdf.DeleteById);
    }

    public async HandleRequestAsync(model: ConditionReportPdfListViewModel): Promise<any> {
        let request = new DeletePDFByIdRequest();
        request.id = model.pdfToDeleteId;
        request.conditionReportId = model.conditionReportId;
        request.columnName = model.columnName;
        request.orderBy = model.orderBy;
        request.pageNumber = model.pageNumber;
        request.pageSize = model.pageSize;
        request.searchString = model.searchString;
        return request;
    }

    public async HandleResponseAsync(response: GetListItemsForConditionReportResponse): Promise<GetListItemsForConditionReportResponse> {
        Logger.logInformation("Response", response.queryTotalCount);

        //Return your list
        return response;
    }
}
