import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { CustomerNotesViewModel } from "./CustomerNotesViewModel";
import CloseIcon from "Assets/close_icon_black.svg";
import AddProjectNoteIcon from "Assets/Icons/editButtonIcon.svg";
import { AddNoteModal } from "../../../../../Components/Modals/AddNoteModalView";
import { formatDateTime } from "Utils/Formats";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import FileIcon from "Assets/Icons/FileIcon.svg";
import { AddSectionsBox, ButtonBox, DeleteButton, NoteBox, NoteBoxSecondary } from "Components/CommonPageComponents";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ICustomerNote } from "../../Common/ICustomerNote";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";

interface INotesProps {
    // Incoming from edit view
    notes: ICustomerNote[];
    customerId: Guid;
    documentTypes: IDocumentType[];

    noteCountCallBack: (newCount: number) => void;
}

export const CustomerNotesView: React.FC<INotesProps> = observer((props) => {
    const [viewModel] = useState(new CustomerNotesViewModel(props.customerId, props.noteCountCallBack));
    const attachmentSectionRef = useRef<HTMLInputElement>(null);

    const scrollToAttachmentSection = () => {
        attachmentSectionRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        // Incoming from edit view
        if (props.notes) {
            viewModel.updateNotesForEditing(props.notes);
        }

        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, [props.notes]);

    useEffect(() => {
        viewModel.updateCustomerId(props.customerId);

        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, [props.customerId]);

    const hack = () => {
        return props.documentTypes;
    };

    return (
        <>
            {viewModel.showNewNoteModal === true && (
                <AddNoteModal
                    isOpen={viewModel.showNewNoteModal}
                    closeModal={viewModel.closeAddNoteModal}
                    customerId={viewModel.getValue("id")}
                    viewModel={viewModel}
                    documentTypes={hack()}
                    AddOrEdit={viewModel.modalType}
                />
            )}

            {viewModel.isAreYouSureModalOpen === true && (
                <AreYouSureModal
                    title="Delete note"
                    message="Are you sure you want to delete this note?"
                    isOpen={viewModel.isAreYouSureModalOpen}
                    onCancel={viewModel.cancelDelete}
                    onConfirm={viewModel.confirmDelete}
                />
            )}
            <AddSectionsBox ref={attachmentSectionRef}>
                <ButtonBox>
                    <ButtonUnder
                        displayName=" Add customer note"
                        paletteColor="ButtonBlue"
                        boxShadow={true}
                        icon={<img src={AddProjectNoteIcon} />}
                        command={viewModel.openAddNoteModal}
                    />
                </ButtonBox>
                <H5 p="20px 0px">Customer communication ({viewModel.customerNotes.length})</H5>

                {viewModel.customerNotes.length > 0 ? (
                    <>
                        {viewModel.customerNotes.map((Note) => {
                            return (
                                <NoteBoxSecondary key={Note.id}>
                                    <Box flexBox style={{ justifyContent: "space-between" }} p="10px">
                                        <P p="5px 0px 10px 0px">
                                            <strong>
                                                Note added by {Note.createdByName} on {formatDateTime(new Date(Note.createdDate))}
                                            </strong>
                                        </P>
                                        <EditButton id={Note.id ? Note.id : ""} onClick={viewModel.openEditNoteModal} />
                                    </Box>
                                    <Box flexBox p="0px 10px 10px 10px" style={{ justifyContent: "space-between" }}>
                                        <P>{Note.note}</P>
                                        <DeleteButton
                                            command={viewModel.openAreYouSureModal}
                                            value={String(Note.id)}
                                            style={{ cursor: "pointer" }}
                                            icon={<img src={CloseIcon} />}
                                        />
                                    </Box>

                                    {Note.documents.length > 0 && (
                                        <Box grid dc="1fr 1fr" p="15px 10px 10px 10px" backgroundColor={"#F5F5F5"}>
                                            {Note.documents.map((document) => {
                                                return (
                                                    <NoteBox key={document.id}>
                                                        <Box flexBox>
                                                            <img src={FileIcon} height="15.3px"></img>
                                                            <P pl="10px">{document.fileName}</P>
                                                        </Box>

                                                        <Box>
                                                            <P style={{ textTransform: "uppercase" }}>
                                                                <strong>{document.docType}</strong>
                                                            </P>
                                                        </Box>
                                                    </NoteBox>
                                                );
                                            })}
                                        </Box>
                                    )}
                                </NoteBoxSecondary>
                            );
                        })}
                    </>
                ) : (
                    <P>There currently are no notes added to this customer.</P>
                )}
            </AddSectionsBox>
        </>
    );
});
