import { action, makeObservable, observable } from "mobx";

export class ConditionReportAddressDetails {
    public id: number = -1;
    public conditionReportId: string | null = null;
    public source: string = "";
    public accuracy: number = 0;
    public altitude: number | null = null;
    public latitude: number = 0;
    public longitude: number = 0;
    public address: string = "";
    public errorMessage: string = "";
    public success: boolean = false;
    public addressLine1: string = "";
    public addressLine2: string = "";
    public town: string = "";
    public county: string = "";
    public country: string = "";
    public postCode: string = "";

    constructor() {
        makeObservable(this, {
            id: observable,
            conditionReportId: observable,
            source: observable,
            accuracy: observable,
            altitude: observable,
            latitude: observable,
            longitude: observable,
            address: observable,
            errorMessage: observable,
            success: observable,
            addressLine1: observable,
            addressLine2: observable,
            town: observable,
            county: observable,
            country: observable,
            postCode: observable,
            load: action,
        });
    }

    public load(address: ConditionReportAddressDetails | undefined) {
        this.clear();

        if (address !== undefined) {
            this.id = address.id;
            this.conditionReportId = address.conditionReportId;
            this.source = address.source;
            this.accuracy = address.accuracy;
            this.altitude = address.altitude;
            this.latitude = address.latitude;
            this.longitude = address.longitude;
            this.address = address.address;
            this.errorMessage = address.errorMessage;
            this.success = address.success;
            this.addressLine1 = address.addressLine1;
            this.addressLine2 = address.addressLine2;
            this.town = address.town;
            this.county = address.county;
            this.country = address.country;
            this.postCode = address.postCode;
        }
    }

    public clear = () => {
        this.id = -1;
        this.conditionReportId = null;
        this.source = "";
        this.accuracy = 0;
        this.altitude = null;
        this.latitude = 0;
        this.longitude = 0;
        this.address = "";
        this.errorMessage = "";
        this.success = false;
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.town = "";
        this.county = "";
        this.country = "";
        this.postCode = "";
    };
}
