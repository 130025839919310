import styled from "@emotion/styled";
import { Box } from "Application";

export const ProductTypeSelectWrapper = styled(Box)`
    .checkbox {
        &.parent {
            input {
                display: none;
            }
        }
    }
    .parent {
        font-weight: 600;
    }

    .child {
        padding-left: 15px;
    }
`;
