import { GreaterThanRule } from "./../../../../Application/Validation/rules/GreaterThanRule";
import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PropertiesListItemModel } from "./PropertiesListItemModel";

export class PropertiesListModel extends ModelBase<PropertiesListModel> {
    // public id: string = "";
    public Properties: IObservableArray<PropertiesListItemModel> = observable([]);

    public id: Guid | null = null;
    public addressLine1: string = "";
    public addressLine2: string = "";
    public townCity: string = "";
    public country: string = "";
    public countryId: number = -1;
    public postcode: string = "";
    public status: number = 1;
    public isDeleted: boolean = false;
    public attendedSite: boolean = false;
    public roofSubstrate: number | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
        });
    }
}

export class PropertiesListModelValidator extends Validator<PropertiesListModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("addressLine1")
            .notEmpty()
            .withMessage("Please enter address line 1")
            .notNull()
            .withMessage("Please enter address line 1")
            .maxLength(256)
            .withMessage("Enter address line 1 less than 256 characters");
        this.ruleFor("addressLine2")
            .notEmpty()
            .withMessage("Please enter address line 2")
            .notNull()
            .withMessage("Please enter address line 2")
            .maxLength(256)
            .withMessage("Enter address line 2 less than 256 characters");
        this.ruleFor("townCity")
            .notEmpty()
            .withMessage("Please enter a town/city")
            .notNull()
            .withMessage("Please enter a town/city")
            .maxLength(256)
            .withMessage("Enter town/city less than 256 characters");
        /*         this.ruleFor("country")
            .notEmpty()
            .withMessage("Please enter country")
            .notNull()
            .withMessage("Please enter country")
            .maxLength(256)
            .withMessage("Enter country name with less than 256 characters"); */

        this.ruleFor("countryId").notNull().withMessage("Please enter country").greaterThan(0).withMessage("Please enter country");

        this.ruleFor("postcode")
            .notEmpty()
            .withMessage("Please enter postcode")
            .notNull()
            .withMessage("Please enter postcode")
            .maxLength(16)
            .withMessage("Enter postcode with less than 16 characters");
        this.ruleFor("isDeleted").notNull();
        this.ruleFor("roofSubstrate").notNull().withMessage("Please select roof substrate").greaterThan(0).withMessage("Please select Roof Substrate");
    }
}
