import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { createColumnHelper } from "@tanstack/react-table";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { ContactsListWrapper } from "./ContactsList.styles";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { ContactsListViewModel } from "./ContactsListViewModel";
import { FilteringTopSectionGenericView } from "Components/FilteringTopSectionGeneric";
import {
    BooleanTickOrCrossCell,
    CenteredCell,
    CenteredTextCell,
    ContactNamePrimaryContactCell,
    EmailWithIconCell,
    HyphenIfNoValueCell,
    PhoneNumberWithIconCell,
} from "Utils/CellFormatComponents";
import { formatDate } from "Utils/Formats";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AddContactModal } from "../Customers/NewCustomer/Modals/AddContactModalView";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { ContactStatusTableBox } from "Models/Contacts/ContactStatus.style";
import { ContactStatusTypeEnum, ContactStatusTypeHelper } from "Models/Contacts/ContactStatusTypeEnun";
import { useNavigate } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair } from "@shoothill/core";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";
import { ViewContactModal } from "./Modals/ViewContactModal";

export const ContactsListView: React.FC = observer(() => {
    const [viewModel] = useState(() => ContactsListViewModel.Instance);
    let navigate = useNavigate();
    let debounceTimer: NodeJS.Timeout | null = null;
    const [isFiltering, setIsFiltering] = useState(false);
    const columnHelper = createColumnHelper<any>();

    useEffect(() => {
        viewModel.loadContactsAsync();

        return () => {
            // Clean up after yourself
            // Don't clear, we want to keep this since using a singleton viewModel.clear();
            // viewModel.reset();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.loadContactsAsync();
    };

    const columns = [
        columnHelper.accessor("name", {
            size: 250,
            header: () => "Name",
            cell: (info) => <ContactNamePrimaryContactCell name={info.row.original.name} primaryContact={info.row.original.primaryContact} />,
        }),

        columnHelper.accessor("customer", {
            size: 600,
            header: () => "Customer",
            cell: (info) => <Box onClick={(e: any) => onCustomerClick(e, info.row.original.id)}>{info.renderValue()}</Box>,
        }),
        columnHelper.accessor("type", {
            header: () => "Type",
            cell: (info) => info.renderValue(),
        }),
        columnHelper.accessor("jobTitle", {
            header: () => "Job title",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("phone", {
            header: () => "Phone",
            cell: (info) => <PhoneNumberWithIconCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("email", {
            header: () => "Email address",
            cell: (info) => <EmailWithIconCell value={info.renderValue()} />,
        }),

        columnHelper.accessor("plygeneTrained", {
            header: () => "Plygene trained",
            cell: (info) => {
                if (info.renderValue() !== null && info.renderValue() === true && info.row.original.datePlygeneTrained === null) {
                    return <BooleanTickOrCrossCell value={true} />;
                } else if (info.row.original.datePlygeneTrained !== null) {
                    return (
                        <CenteredTextCell
                            greenText={true}
                            value={info.row.original.datePlygeneTrained !== null ? formatDate(new Date(info.row.original.datePlygeneTrained)) : null}
                        />
                    );
                } else {
                    return <BooleanTickOrCrossCell value={false} />;
                }
            },
        }),

        columnHelper.accessor("coatingsTrained", {
            header: () => "Coatings trained",
            cell: (info) => {
                if (info.renderValue() !== null && info.renderValue() === true && info.row.original.dateCoatingsTrained === null) {
                    return <BooleanTickOrCrossCell value={true} />;
                } else if (info.row.original.dateCoatingsTrained !== null) {
                    return (
                        <CenteredTextCell
                            greenText={true}
                            value={info.row.original.dateCoatingsTrained !== null ? formatDate(new Date(info.row.original.dateCoatingsTrained)) : null}
                        />
                    );
                } else {
                    return <BooleanTickOrCrossCell value={false} />;
                }
            },
        }),

        columnHelper.accessor("plygeneCPD", {
            header: () => "Plygene CPD",
            cell: (info) => {
                if (info.renderValue() !== null && info.renderValue() === true && info.row.original.datePlygeneCPD === null) {
                    return <BooleanTickOrCrossCell value={true} />;
                } else if (info.row.original.datePlygeneCPD !== null) {
                    return <CenteredTextCell greenText={true} value={info.row.original.datePlygeneCPD !== null ? formatDate(new Date(info.row.original.datePlygeneCPD)) : null} />;
                } else {
                    return <BooleanTickOrCrossCell value={false} />;
                }
            },
        }),

        columnHelper.accessor("coatingsCPD", {
            header: () => "Coatings CPD",
            cell: (info) => {
                if (info.renderValue() !== null && info.renderValue() === true && info.row.original.dateCoatingsCPD === null) {
                    return <BooleanTickOrCrossCell value={true} />;
                } else if (info.row.original.dateCoatingsCPD !== null) {
                    return (
                        <CenteredTextCell greenText={true} value={info.row.original.dateCoatingsCPD !== null ? formatDate(new Date(info.row.original.dateCoatingsCPD)) : null} />
                    );
                } else {
                    return <BooleanTickOrCrossCell value={false} />;
                }
            },
        }),

        columnHelper.accessor("dateAdded", {
            header: () => "Date added",
            cell: (info) => <CenteredTextCell value={formatDate(new Date(info.renderValue()))} />,
        }),
        // columnHelper.accessor("status", {
        //     size: 250,
        //     header: () => "Status",
        //     cell: (info) => (
        //         <ContactStatusTableBox color={ContactStatusTypeHelper.GetColor(info.getValue())}>{ContactStatusTypeHelper.GetText(info.getValue())}</ContactStatusTableBox>
        //     ),
        // }),
        columnHelper.accessor("id", {
            header: () => "Actions",
            cell: (info) => (
                <CenteredCell style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <EditButton id={info.getValue().toString()} onClick={viewModel.openEditContactModal} />
                    <DeleteButton id={info.getValue().toString()} onClick={viewModel.deleteContactCheck} />
                </CenteredCell>
            ),
            enableSorting: false,
        }),
    ];

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.loadContactsAsync();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.loadContactsAsync();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.loadContactsAsync();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.loadContactsAsync();

                break;
            default:
        }
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;
        viewModel.loadContactsAsync();
    };

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        switch (value) {
            case subHeadingButton.Download:
                viewModel.downloadCSVAsync();
                break;
            case subHeadingButton.New:
                viewModel.openAddContactModal();
                break;
            default:
        }
    });

    const FilteringTopSection = (
        <FilteringTopSectionGenericView
            searchValue={viewModel.searchString}
            searchCommand={viewModel.updateKeywordSearch}
            timescaleOptions={[]}
            timescaleValue={""}
            displayName="Start typing to search by first name, last name, name, job title, phone, email etc... "
            isDisabled={!viewModel.getCanExecute}
        />
    );

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.loadContactsAsync();
            setIsFiltering(false);
        }, 1500);
    };

    const updateCustomerTypesFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateCustomerTypesFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        viewModel.pageNumber = 1;
        debounceFilter();
    });

    const customerTypesSelectAll = (): void => {
        viewModel.customerTypesSelectAll();
        viewModel.loadContactsAsync();
    };

    const customerTypesSelectNone = (): void => {
        viewModel.customerTypesSelectNone();
        viewModel.loadContactsAsync();
    };

    const updatePlygeneTrainedFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updatePlygeneTrainedFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        viewModel.pageNumber = 1;
        debounceFilter();
    });

    const plygeneTrainedSelectAll = (): void => {
        viewModel.plygeneTrainedSelectAll();
        viewModel.loadContactsAsync();
    };

    const plygeneTrainedSelectNone = (): void => {
        viewModel.plygeneTrainedSelectNone();
        viewModel.loadContactsAsync();
    };

    const updateCoatingsTrainedFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateCoatingsTrainedFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        viewModel.pageNumber = 1;
        debounceFilter();
    });

    const coatingsTrainedSelectAll = (): void => {
        viewModel.coatingsTrainedSelectAll();
        viewModel.loadContactsAsync();
    };

    const coatingsTrainedSelectNone = (): void => {
        viewModel.coatingsTrainedSelectNone();
        viewModel.loadContactsAsync();
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <MultiEditSelect
                command={updateCustomerTypesFiltering}
                displayName="Type"
                options={viewModel.getCustomerTypesForDropdown}
                value={() => viewModel.getCustomerTypesFilter}
                showSelectAllNone={true}
                selectAll={customerTypesSelectAll}
                selectNone={customerTypesSelectNone}
                isDisabled={viewModel.isProcessing}
            />
            <MultiEditSelect
                command={updatePlygeneTrainedFiltering}
                displayName="Plygene trained"
                options={viewModel.getBooleanOptionsForDropdown}
                value={() => viewModel.getPlygeneTrainedFilter}
                showSelectAllNone={true}
                selectAll={plygeneTrainedSelectAll}
                selectNone={plygeneTrainedSelectNone}
                isDisabled={viewModel.isProcessing}
            />
            <MultiEditSelect
                command={updateCoatingsTrainedFiltering}
                displayName="Coatings trained"
                options={viewModel.getBooleanOptionsForDropdown}
                value={() => viewModel.getCoatingsTrainedFilter}
                showSelectAllNone={true}
                selectAll={coatingsTrainedSelectAll}
                selectNone={coatingsTrainedSelectNone}
                isDisabled={viewModel.isProcessing}
            />
        </FilteringBottomSectionBox>
    );

    function onRowClick(rowId: string | number): void {
        viewModel.openViewcontactModal(String(rowId));
    }

    function onCustomerClick(e: any, rowId: string | number): void {
        e.stopPropagation();
        let custId: string | null = viewModel.getCustomerIdForRowId(rowId);
        if (custId !== null && custId !== undefined) {
            const path: string = AppUrls.Client.Directory.ContactCustomerView[AppUrlIndex.Long].replace(":customerId", custId) + "/details";
            navigate(path);
        }
    }

    return (
        <>
            {viewModel.showAddContactModal && (
                <AddContactModal
                    isOpen={viewModel.showAddContactModal}
                    closeModal={viewModel.closeEditContactModal}
                    viewModel={viewModel}
                    parentViewModel={viewModel}
                    addOrEdit={viewModel.model.addEditContactModalType}
                    enableCustomerDropdown={true}
                />
            )}

            <AreYouSureModal
                title={"Delete contact"}
                message={"Are you sure you want to delete this contact?"}
                isOpen={viewModel.areYouSureModelOpen}
                onConfirm={viewModel.confirmDeleteContact}
                onCancel={viewModel.closeAreYouSureModal}
            />
            {viewModel.showViewContactModal && (
                <ViewContactModal isOpen={viewModel.showViewContactModal} contactId={viewModel.contactIdToView} closeModal={viewModel.closeViewContactModal} />
            )}
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} downloadButtonText="Download as csv" newButtonText="New contact" />

            <ContactsListWrapper>
                <H2 pb="10px">Contacts</H2>
                <SharmansTable
                    columns={columns}
                    data={viewModel.getContacts}
                    filteringElementsBottomSection={FilteringBottomSection}
                    filteringElementsTopSection={FilteringTopSection}
                    onSortChange={updateSorting}
                    showPagination={true}
                    totalRowCount={viewModel.contactCount}
                    onChangePage={onChangePage}
                    onChangePageNumber={viewModel.changePageNumber}
                    onChangeRowPerPage={onChangeRowPerPage}
                    totalPageCount={viewModel.pageCount}
                    pageNumber={viewModel.pageNumber}
                    pageSize={viewModel.pageSize}
                    onRowClick={onRowClick}
                    isProcessing={viewModel.isProcessing}
                    clearFilteringCommand={viewModel.clearFiltering}
                />
            </ContactsListWrapper>
        </>
    );
});
