import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ProjectStatusEnum } from "Models/Project/ProjectStatusEnum";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { PropertyMultiSiteProjectListItem } from "../../Models/PropertyMultiSiteProjectListItem";
import { MultiSiteProjectViewModel } from "../MultiSiteProjectViewModel";
import { AppUrls } from "AppUrls";

export class GetPropertyLinkedMultiSiteAndRelatedRequest {
    public propertyId: Guid = "";
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public filterByStatus: ProjectStatusEnum[] = [];
    public initialLoad: boolean = true;
}

export class PropertyLinkedMultiSiteAndRelatedResponse {
    public multiSiteProjects: PropertyMultiSiteProjectListItem[] = [];
    public propertyCount: number = 0;
}

export class GetPropertyLinkedMultiSiteAndRelatedEndpoint extends Endpoint<GetPropertyLinkedMultiSiteAndRelatedRequest, PropertyLinkedMultiSiteAndRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/property/getMultisiteProjectListItems");
    }

    public async HandleRequestAsync(viewModel: MultiSiteProjectViewModel): Promise<any> {
        let request = new GetPropertyLinkedMultiSiteAndRelatedRequest();
        request.propertyId = viewModel.model.propertyId;
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageNumber = viewModel.pageNumber;
        request.pageSize = viewModel.pageSize;

        request.filterByStatus = [];

        request.filterByStatus = viewModel.filterByStatus.map((item: string) => {
            return parseInt(item) as ProjectStatusEnum;
        });

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: PropertyLinkedMultiSiteAndRelatedResponse): Promise<PropertyLinkedMultiSiteAndRelatedResponse> {
        return response;
    }
}
