import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate } from "react-router";
import { Header, MenuItemType } from "Views/Header/Header";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ReportListView } from "./ReportListView";
import { setPageTitle } from "Application";

export const ReportRoutes: React.FC = observer(() => {
    useEffect(() => {
        setPageTitle("Reports");
    }, []);

    return (
        <>
            <Header title="Reports" />

            <Routes>
                <Route path="*" element={<Navigate to="list" replace />} />
                <Route path={"list/*"} element={<ReportListView />} />
            </Routes>
        </>
    );
});
