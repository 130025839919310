import { CheckBox } from "Application";
import { action, makeObservable, observable } from "mobx";

export class SectionImage {
    public id: number | null = null;
    public questionId: number | null = null;
    public identifier: string = "";
    public fileName: string = "";
    public contentType: string = "";
    public orientation: string = "";
    public height: number = 0;
    public width: number = 0;
    public imageURL: string = "";

    constructor() {
        makeObservable(this, {
            id: observable,
            questionId: observable,
            identifier: observable,
            contentType: observable,
            fileName: observable,
            orientation: observable,
            height: observable,
            width: observable,
            imageURL: observable,
            clear: action,
            load: action,
        });
    }

    public load(image: SectionImage) {
        this.clear();

        if (image !== undefined) {
            this.id = image.id;
            this.questionId = image.questionId;
            this.identifier = image.identifier;
            this.contentType = image.contentType;
            this.fileName = image.fileName;
            this.orientation = image.orientation;
            this.height = image.height;
            this.width = image.width;
            this.imageURL = image.imageURL;
        }
    }

    public clear = () => {
        this.id = null;
        this.questionId = null;
        this.identifier = "";
        this.contentType = "";
        this.fileName = "";
        this.orientation = "";
        this.height = 0;
        this.width = 0;
        this.imageURL = "";
    };
}
