import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

class AddPropertyOccupierRequest {
    public occupierId: Guid = "";
    public propertyId: Guid = "";
}

class AddPropertyOccupierResponse {}

export class AddPropertyOccupierEndpoint extends Endpoint<AddPropertyOccupierRequest, AddPropertyOccupierResponse> {
    public occupierId: Guid = "";
    public propertyId: Guid = "";
    constructor(occupierId: Guid, propertyId: Guid) {
        super();
        this.occupierId = occupierId;
        this.propertyId = propertyId;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.AddPropertyOccupier);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new AddPropertyOccupierRequest();
        request.occupierId = this.occupierId;
        request.propertyId = this.propertyId;
        return request;
    }

    public async HandleResponseAsync(response: AddPropertyOccupierResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
