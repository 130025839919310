import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ApiResult } from "@shoothill/core";
import { GenericNotesModel } from "Models/Notes/GenericNotesModel";
import { AppUrls } from "AppUrls";

class UpsertCustomerNoteRequest {
    id: Guid | null = null;
    note: string = "";
    isDeleted: boolean = false;
    createdDate: string = "";
    createdBy: string | null = null;
    customerId: Guid = "";
}

export class UpsertCustomerNoteEndpoint extends Endpoint<UpsertCustomerNoteRequest, ApiResult> {
    constructor() {
        super();
        this.verb(Http.Put);
        this.path(AppUrls.Server.Customer.UpsertNote);
    }

    public async HandleRequestAsync(model: GenericNotesModel): Promise<any> {
        let request = new UpsertCustomerNoteRequest();
        request.id = model.id;
        request.note = model.note;
        request.isDeleted = model.isDeleted;
        request.createdBy = model.createdById;
        request.customerId = model.sourceId;

        return request;
    }

    public async HandleResponseAsync(response: ApiResult): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
