import { singleton } from "tsyringe";
import { KeyValuePair } from "@shoothill/core";

@singleton()
export class LookupStore {
    public dummyData = () => {
        return [
            {
                key: "John Doe",
                text: "John Doe",
            },
            {
                key: "Jane Doe",
                text: "Jane Doe",
            },
            {
                key: "Jack Doe",
                text: "Jack Doe",
            },
        ] as KeyValuePair[];
    };
    public YesNoOptions = () => {
        return [
            {
                key: "yes",
                text: "Yes",
            },
            {
                key: "no",
                text: "No",
            },
        ] as KeyValuePair[];
    };
}
