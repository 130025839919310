import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
class GetPropertyByIdWithRelatedRequest {}

class GetPropertyByIdWithRelatedResponse {}

export class GetPropertyByIdWithRelatedEndpoint extends Endpoint<GetPropertyByIdWithRelatedRequest, GetPropertyByIdWithRelatedResponse> {
    constructor(id: Guid) {
        const id1: string = id === null ? "" : id.toString();
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Property.GetPropertyByIdWithRelated.replace(":id", id1));
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new GetPropertyByIdWithRelatedRequest();
        return request;
    }

    public async HandleResponseAsync(response: GetPropertyByIdWithRelatedResponse): Promise<GetPropertyByIdWithRelatedResponse> {
        Logger.logInformation("Response", response);

        //Return your response
        return response;
    }
}
