import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ContactsListModel } from "../ContactsListModel";

class GetCustomerTypesForDropdownResponse {}

export class GetCustomerTypesForDropdownEndpoint extends EndpointWithoutRequest<GetCustomerTypesForDropdownResponse> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Contact.GetCustomerTypesForDropdown);
    }

    public async HandleResponseAsync(response: GetCustomerTypesForDropdownResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
