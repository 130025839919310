import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { DEFAULTTEMPLATESECTIONNAME } from "Utils/TemplateSource";
import { ControlType } from "Views/ConditionReport/Common/ControlType";
import { TemplateSourceModel } from "Views/ConditionReport/Common/Models/Source/TemplateSourceModel";

export class AddSectionModel extends ModelBase<AddSectionModel> {
    public sectionTypeKeyId: string = DEFAULTTEMPLATESECTIONNAME;

    public originalSectionName: string = ""; // Save the original name when a section is selected since we need to use it to calculate things
    public sectionName: string = "";
    public errorMessage: string = "";
    public controlTypeId: ControlType = ControlType.Paragraph;
    public typeKeyId: string = "";
    public isNameDirty: boolean = false;
    public isRepeatableSection = false;
    public templateSource: TemplateSourceModel | undefined = undefined;

    constructor() {
        super();
        makeObservable(this, {
            sectionTypeKeyId: observable,
            originalSectionName: observable,
            sectionName: observable,
            errorMessage: observable,
            controlTypeId: observable,
            typeKeyId: observable,
            isNameDirty: observable,
        });
    }

    public clear = () => {
        this.sectionTypeKeyId = "";
        this.sectionName = "";
        this.originalSectionName = "";
        this.errorMessage = "";
        this.controlTypeId = ControlType.Paragraph;
        this.typeKeyId = "";
        this.isNameDirty = false;
        this.isRepeatableSection = false;
    };
}

export class AddSectionModelValidator extends Validator<AddSectionModel> {
    constructor() {
        super();
        this.ruleFor("sectionName").notEmpty();
        this.ruleFor("typeKeyId")
            .notEmpty()
            .when((model) => model.controlTypeId !== ControlType.Paragraph);
    }
}
