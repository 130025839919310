import { StatusDropdownItem } from "Models/DropdownItem";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";

export enum StatusEnum {
    Open = 1,
    Complete = 100,
}

export namespace StatusEnumHelper {
    export const AvailibleStatusesByStatus: { [key: number]: StatusEnum[] } = {
        [StatusEnum.Open]: [StatusEnum.Open, StatusEnum.Complete],
        [StatusEnum.Complete]: [StatusEnum.Open, StatusEnum.Complete],
    };

    export const getText = (status: StatusEnum) => {
        switch (status) {
            case StatusEnum.Open: {
                return "Open";
            }
            case StatusEnum.Complete: {
                return "Complete";
            }
            default: {
                return "Unknown";
            }
        }
    };

    export const getClassName = (status: StatusEnum) => {
        switch (status) {
            case StatusEnum.Open:
                return "status-Open";

            case StatusEnum.Complete:
                return "status-Complete";

            default:
                return "projectstatus-unknown";
        }
    };

    // Use this one on the Project View
    export const getStatusOptions = (currentStatus: StatusEnum): KeyValuePairExtended[] => {
        let availibleProjectStatuses: StatusEnum[] = StatusEnumHelper.AvailibleStatusesByStatus[currentStatus];

        const retVal: KeyValuePairExtended[] = availibleProjectStatuses.map((projectStatus: StatusEnum) => ({
            text: StatusEnumHelper.getText(projectStatus),
            key: projectStatus,
            class: StatusEnumHelper.getClassName(projectStatus),
        }));

        return retVal;
    };

    // Use this one on the Project List
    export const getStatusOptionsForListView = (currentStatus: StatusEnum): KeyValuePairExtended[] => {
        let availibleProjectStatuses: StatusEnum[] = StatusEnumHelper.AvailibleStatusesByStatus[currentStatus];

        const retVal: KeyValuePairExtended[] = availibleProjectStatuses.map((projectStatus: StatusEnum) => ({
            text: StatusEnumHelper.getText(projectStatus),
            key: projectStatus.toString(),
            class: StatusEnumHelper.getClassName(projectStatus),
        }));

        return retVal;
    };

    export const getStatusDropdownOptions = (): StatusDropdownItem[] => {
        const retVal: StatusDropdownItem[] = [
            { id: StatusEnum.Open, displayName: "Open", className: "status-Open", statusId: StatusEnum.Open },
            { id: StatusEnum.Complete, displayName: "Complete", className: "status-Complete", statusId: StatusEnum.Complete },
        ];

        return retVal;
    };
}
