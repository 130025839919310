import { GenericComplaintActionListItem } from "./../Endpoints/GetCorrectiveActionForView";
import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ConsoleLogger, CoreStoreInstance, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { CorrectiveActionModel, CorrectiveActionModelValidator } from "./CorrectiveActionModel";
import { GetCorrectiveActionForViewEndpoint, GetCorrectiveActionForViewResponse } from "../Endpoints/GetCorrectiveActionForView";
import { UpsertCorrectiveActionEndpoint, UpsertCorrectiveActionResponse } from "../Endpoints/UpsertCorrectiveAction";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { DeleteCorrectiveActionByIdEndpoint, DeleteCorrectiveActionResponse } from "../Endpoints/DeleteCorrectiveActionById";

export class CorrectiveActionViewModel extends ViewModelBase<CorrectiveActionModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public isModalOpen: boolean = false;
    public addOrEditModal: ModalType = ModalType.Add;
    public actionToDeleteId: number = 0;
    public isAreYouSureModalOpen: boolean = false;

    public countCallBack: ((newCount: number) => void) | undefined = undefined;

    constructor() {
        super(new CorrectiveActionModel());
        this.setValidator(new CorrectiveActionModelValidator());
        makeObservable(this, {
            actionToDeleteId: observable,
            addOrEditModal: observable,
            isAreYouSureModalOpen: observable,
            isProcessing: observable,
            isModalOpen: observable,

            getActions: computed,

            cancelDelete: action,
            closeModal: action,
            clear: action,
            deleteAction: action,
            deleteActionOpenAreYouSureModal: action,
            init: action,
            openEditActionModal: action,
            proceedWithDelete: action,
            setComplaintId: action,
        });
    }

    public init = (countCallBack: (newCount: number) => void) => {
        this.countCallBack = countCallBack;
    };

    public clear = () => {
        this.model.clear();
    };

    public get getActions(): GenericComplaintActionListItem[] {
        return this.model.actions.slice();
    }

    public tempCommand: ICommand = new RelayCommand(() => {
        //alert("Coming soon");
    });

    public loadActionList = async (id: Guid): Promise<GetCorrectiveActionForViewResponse> => {
        const endpoint = new GetCorrectiveActionForViewEndpoint(id);
        let retVal: GetCorrectiveActionForViewResponse = new GetCorrectiveActionForViewResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            // Taken out as was stopping the table ordering from working
            // this.isProcessing = true;

            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: GetCorrectiveActionForViewResponse = this.apiClient.Response();
                    this.model.actions.replace(response.actions);

                    if (this.countCallBack !== undefined) {
                        this.countCallBack(response.actions.length);
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint actions");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the complaint actions.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        } else {
            //alert(this.isProcessing + " " + this.apiClient.IsBusy);
        }
        return retVal;
    };

    // Add Edit action

    public setComplaintId = (id: string) => {
        this.setValue("projectComplaintId", id);
    };

    public openAddModal: ICommand = new RelayCommand(() => {
        this.model.clear();
        this.addOrEditModal = ModalType.Add;
        this.isModalOpen = true;
    });

    public closeModal = () => {
        this.isModalOpen = false;
        this.setError("correctiveAction", "");
        CoreStoreInstance.HideInfoBar();
    };

    public actionInputCommand: ICommand = new RelayCommand((action: string) => {
        this.model.correctiveAction = action;
    });

    public upsertAction = async (): Promise<UpsertCorrectiveActionResponse> => {
        const endpoint = new UpsertCorrectiveActionEndpoint();
        let retVal: UpsertCorrectiveActionResponse = new UpsertCorrectiveActionResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false && this.isModelValid()) {
            // Taken out as was stopping the table ordering from working
            // this.isProcessing = true;

            await this.apiClient.sendAsync(endpoint, this.model);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: UpsertCorrectiveActionResponse = this.apiClient.Response();
                    this.model.actions.replace(response.actions);

                    if (this.countCallBack !== undefined) {
                        this.countCallBack(response.actions.length);
                    }
                    this.isModalOpen = false;
                    this.model.clear();
                    CoreStoreInstance.HideInfoBar();
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint actions");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to save the complaint action.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        } else {
            //alert(this.isProcessing + " " + this.apiClient.IsBusy);
        }

        return retVal;
    };

    // Editing

    public openEditActionModal = (e: any, id: string) => {
        this.addOrEditModal = ModalType.Edit;

        let actionToEdit = this.model.actions.find((item) => {
            return Number(item.id) === Number(id);
        });

        if (actionToEdit) {
            this.setValue("actionId", actionToEdit.id);
            this.setValue("correctiveAction", actionToEdit.action);
            this.setValue("rowVersion", actionToEdit.rowVersion);
        }

        this.isModalOpen = true;
    };

    // Deleting

    public deleteActionOpenAreYouSureModal = (e: any, id: string) => {
        this.actionToDeleteId = Number(id);
        this.isAreYouSureModalOpen = true;
    };

    public proceedWithDelete = () => {
        this.deleteAction();
    };

    public cancelDelete = () => {
        this.isAreYouSureModalOpen = false;
    };

    public deleteAction = async (): Promise<DeleteCorrectiveActionResponse> => {
        const endpoint = new DeleteCorrectiveActionByIdEndpoint(this.actionToDeleteId, this.getValue("projectComplaintId"));
        let retVal: DeleteCorrectiveActionResponse = new DeleteCorrectiveActionResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            // Taken out as was stopping the table ordering from working
            // this.isProcessing = true;

            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: DeleteCorrectiveActionResponse = this.apiClient.Response();
                    this.model.actions.replace(response.actions);

                    if (this.countCallBack !== undefined) {
                        this.countCallBack(response.actions.length);
                    }
                    this.isAreYouSureModalOpen = false;
                    this.actionToDeleteId = 0;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint actions");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the complaint action.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };
}
