import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

// App
export class UserModel extends ModelBase<UserModel> {
    public id: Guid = null;
    public firstName: string = "";
    public lastName: string = "";
    public emailAddress: string = "";
    public phoneNumber: string = "";
    public rowVersion: string = "";
    public lastLoginDate: Date | null = null;
    public createdDate: Date | null = null;
    public isDeleted: boolean = false;
    public lockoutEnabled: boolean = false;
    public accessFailedCount: number = 0;
    public userRoles = observable<string>([]);

    constructor(id: Guid) {
        super();
        this.id = id;
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            emailAddress: observable,
            phoneNumber: observable,
            rowVersion: observable,
            lastLoginDate: observable,
            userRoles: observable,
            accessFailedCount: observable,
            lockoutEnabled: observable,
        });
    }
}

export class UserModelValidator extends Validator<UserModel> {
    constructor() {
        super();
        this.ruleFor("emailAddress")
            .notNull()
            .withMessage("Please enter an email address")
            .notEmpty()
            .withMessage("Please enter an email address")
            .emailAddress()
            .withMessage("Please enter a valid email Address")
            .maxLength(255)
            .withMessage("Please enter an email less than 255 characters.");
        this.ruleFor("firstName")
            .notNull()
            .withMessage("Please enter a first name")
            .notEmpty()
            .withMessage("Please enter a first name")
            .maxLength(128)
            .withMessage("Please enter a first name less than 128 characters");
        this.ruleFor("lastName")
            .notNull()
            .withMessage("Please enter a last name")
            .notEmpty()
            .withMessage("Please enter a last name")
            .maxLength(128)
            .withMessage("Please enter a last name less than 128 characters");
        this.ruleFor("userRoles").must({ predicate: (item, model) => item.length > 0, message: (value, model) => "Please select a role" });
        this.ruleFor("phoneNumber")
            .matches(/^[+\d\s\/]{0,32}$/) // Allow numbers, "+", "/" and spaces, with max length of 32 characters
            .withMessage("Please enter a valid phone number")
            .maxLength(32)
            .withMessage("Enter a phone additional number less than 32 characters");
    }
}
