export const preprocessPaste = (node: Element | null) => {
    if (node !== null) {
        node.removeAttribute("id");
        node.removeAttribute("class");
        node.removeAttribute("lang");

        if (node.localName.toLocaleLowerCase() === "img") {
            node.removeAttribute("style");
        }

        if (node.children.length > 0) {
            for (let i: number = 0; i < node.children.length; i++) {
                const child: Element | null = node.children[i];
                preprocessPaste(child);
            }
        }
    }
};
