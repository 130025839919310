import { KeyValuePair, ModelBase } from "@shoothill/core";
import { IObservableArray, action, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { ContactsListItemModel } from "./ContactsListItemModel";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";

export class ContactsListModel extends ModelBase<ContactsListModel> {
    // public id: string = "";

    // List
    public contacts: IObservableArray<ContactsListItemModel> = observable([]);

    // Contact currently being edited/added
    public id: Guid | null = null;
    public firstName: string = "";
    public lastName: string = "";
    public phone: string = "";
    public phone2: string = "";
    public email: string = "";
    public jobTitle: string = "";
    public status: number = 1;
    public primaryContact: boolean = false;
    public plygeneTrained: boolean = false;
    public coatingsTrained: boolean = false;
    public datePlygeneTrained: Date | null = null;
    public dateCoatingsTrained: Date | null = null;

    public coatingsCPD: boolean = false;
    public plygeneCPD: boolean = false;
    public dateCoatingsCPD: Date | null = null;
    public datePlygeneCPD: Date | null = null;

    public note: string = "";
    public customerId: Guid = "";
    public marketingConsent: boolean = true;

    // For adding

    public customerType: number = 0;
    public selectedCustomer: Guid = "";
    public customers: KeyValuePair[] = [];
    public searchString: string = "";
    public modalIsAdding: boolean = false;

    // Modal options
    public addEditContactModalType: ModalType = ModalType.Edit;

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
            addEditContactModalType: observable,
            customerType: observable,
            selectedCustomer: observable,
            customers: observable,
            searchString: observable,
            firstName: observable,
            lastName: observable,
            phone: observable,
            phone2: observable,
            email: observable,
            jobTitle: observable,
            status: observable,
            primaryContact: observable,
            marketingConsent: observable,
            plygeneTrained: observable,
            coatingsTrained: observable,
            datePlygeneTrained: observable,
            dateCoatingsTrained: observable,

            coatingsCPD: observable,
            plygeneCPD: observable,
            dateCoatingsCPD: observable,
            datePlygeneCPD: observable,

            note: observable,
            customerId: observable,
            modalIsAdding: observable,
            clearContactModal: action,
        });
    }

    public clear = () => {
        this.contacts.clear();
        this.id = null;
        this.firstName = "";
        this.lastName = "";
        this.phone = "";
        this.phone2 = "";
        this.email = "";
        this.jobTitle = "";
        this.status = 1;
        this.primaryContact = false;
        this.marketingConsent = true;
        this.plygeneTrained = false;
        this.coatingsTrained = false;
        this.datePlygeneTrained = null;
        this.dateCoatingsTrained = null;

        this.plygeneCPD = false;
        this.coatingsCPD = false;
        this.datePlygeneCPD = null;
        this.dateCoatingsCPD = null;

        this.note = "";
        this.customerId = "";
    };

    public clearContactModal = () => {
        this.id = null;
        this.firstName = "";
        this.lastName = "";
        this.phone = "";
        this.phone2 = "";
        this.email = "";
        this.jobTitle = "";
        this.status = 1;
        this.primaryContact = false;
        this.marketingConsent = true;
        this.plygeneTrained = false;
        this.coatingsTrained = false;
        this.datePlygeneTrained = null;
        this.dateCoatingsTrained = null;

        this.plygeneCPD = false;
        this.coatingsCPD = false;
        this.datePlygeneCPD = null;
        this.dateCoatingsCPD = null;

        this.note = "";
        this.customerId = "";
        this.customerType = 0;
    };
}

export class ContactsListModelValidator extends Validator<ContactsListModel> {
    constructor() {
        super();
        // this.ruleFor("id").notEmpty();
        this.ruleFor("lastName")
            .notNull()
            .withMessage("Please enter a lastname")
            .notEmpty()
            .withMessage("Please enter a lastname")
            .maxLength(256)
            .withMessage("Please enter a lastname less than 256 characters.");
        this.ruleFor("firstName")
            .notNull()
            .withMessage("Please enter a firstname")
            .notEmpty()
            .withMessage("Please enter a firstname")
            .maxLength(256)
            .withMessage("Please enter a lastname less than 256 characters.");
        this.ruleFor("primaryContact").notNull().withMessage("Please enter a primary contact");
        this.ruleFor("coatingsTrained").notNull();
        this.ruleFor("plygeneTrained").notNull();
        this.ruleFor("coatingsTrained").notNull();
        this.ruleFor("customerId").notNull();
        this.ruleFor("marketingConsent").notNull();

        this.ruleFor("email")
            .notEmpty()
            .withMessage("Please enter an email or phone number")
            .when((model) => model.phone === "")
            .notNull()
            .withMessage("Please enter an email or phone number")
            .when((model) => model.phone === "");

        this.ruleFor("email")
            .emailAddress()
            .withMessage("Please enter a valid email Address")
            .when((model) => model.phone === "")
            .maxLength(256)
            .withMessage("Enter an email less than 256 characters")
            .when((model) => model.email !== "");

        this.ruleFor("phone")
            .maxLength(18)
            .withMessage("Enter a phone number less than 18 characters or Big change will fail")
            .notEmpty()
            .withMessage("Please enter a phone number or email")
            .when((model) => model.email === "")
            .notNull()
            .withMessage("Please enter a phone number or email")
            .when((model) => model.email === "");

        this.ruleFor("phone2")
            .matches(/^[+\d\s\/]{0,32}$/) // Allow numbers, "+", "/" and spaces, with max length of 32 characters
            .withMessage("Please enter a valid phone number")
            .maxLength(32)
            .withMessage("Enter a phone additional number less than 32 characters");

        this.ruleFor("phone")
            .matches(/^[+\d\s\/]{1,32}$/) // Allow numbers, "+", "/" and spaces, with max length of 32 characters
            .withMessage("Please enter a valid phone number")
            .when((model) => model.phone !== "");

        this.ruleFor("customerId").notNull().notEqual("").withMessage("Please select a customer for this contact");
        this.ruleFor("customerType")
            .greaterThan(0)
            .withMessage("Please select a customer type")
            .when((model) => model.addEditContactModalType === ModalType.Add);

        this.ruleFor("datePlygeneTrained")
            .must((value, model) => {
                if (model.plygeneTrained) {
                    return value !== null;
                }
                return value === null;
            })
            .withMessage("Please select a date for plygene trained")
            .when((model) => model.plygeneTrained === true);

        this.ruleFor("dateCoatingsTrained")
            .must((value, model) => {
                if (model.coatingsTrained) {
                    return value !== null;
                }
                return value === null;
            })
            .withMessage("Please select a date for Coating trained")
            .when((model) => model.coatingsTrained === true);

        this.ruleFor("dateCoatingsCPD")
            .must((value, model) => {
                if (model.coatingsCPD) {
                    return value !== null;
                }
                return value === null;
            })
            .withMessage("Please select a date for coating CPD")
            .when((model) => model.coatingsCPD === true);

        this.ruleFor("datePlygeneCPD")
            .must((value, model) => {
                if (model.plygeneCPD) {
                    return value !== null;
                }
                return value === null;
            })
            .withMessage("Please select a date for plygene CPD")
            .when((model) => model.plygeneCPD === true);
    }
}
