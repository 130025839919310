import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { GenericComplaintActionListItem } from "./GetCorrectiveActionForView";
import { CorrectiveActionModel } from "../CorrectiveAction/CorrectiveActionModel";

class UpsertCorrectiveActionRequest {
    id: number | null = null;
    action: string = "";
    rowVersion: string = "";
    projectComplaintId: string = "";
}

export class UpsertCorrectiveActionResponse {
    actions: GenericComplaintActionListItem[] = [];
}

export class UpsertCorrectiveActionEndpoint extends Endpoint<UpsertCorrectiveActionRequest, UpsertCorrectiveActionResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.CorrectiveActionUpsert);
    }

    public async HandleRequestAsync(model: CorrectiveActionModel): Promise<any> {
        let request = new UpsertCorrectiveActionRequest();
        request.id = model.actionId;
        request.action = model.correctiveAction;
        request.rowVersion = model.rowVersion;
        request.projectComplaintId = model.projectComplaintId;
        return request;
    }

    public async HandleResponseAsync(response: UpsertCorrectiveActionResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
