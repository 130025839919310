import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConditionReportPdfListViewModel } from "../ConditionReportPdfListViewModel";

class GetListItemsForConditionReportIdRequest {
    public id: string = "";
    pageSize: number = 50;
    pageNumber: number = 1;
    searchString: string = "";
    orderBy: string = "";
    columnName: string = "";

    public constructor() {}
}

export class GetListItemsForConditionReportPDFListItem {
    public id: string = "";
    public createdDate: string = "";
    public createdBy: string = "";
    public hasPdf: boolean = false;
    public templateNumber: string = "";
    public templateDate: string = "";
    public constructor() {}
}

export interface GetListItemsForConditionReportResponse {
    queryTotalCount: number;
    conditionPDFReports: GetListItemsForConditionReportPDFListItem[];
}

export class GetListItemsForConditionReportIdEndpoint extends Endpoint<GetListItemsForConditionReportIdRequest, GetListItemsForConditionReportResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportPdf.GetListItemsForConditionReportid);
    }

    public async HandleRequestAsync(model: ConditionReportPdfListViewModel): Promise<any> {
        let request = new GetListItemsForConditionReportIdRequest();
        request.id = model.conditionReportId;
        request.columnName = model.columnName;
        request.orderBy = model.orderBy;
        request.pageNumber = model.pageNumber;
        request.pageSize = model.pageSize;
        request.searchString = model.searchString;
        return request;
    }

    public async HandleResponseAsync(response: GetListItemsForConditionReportResponse): Promise<GetListItemsForConditionReportResponse> {
        Logger.logInformation("Response", response.queryTotalCount);

        //Return your model
        return response;
    }
}
