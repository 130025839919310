import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { TasksModel } from "Models/Tasks/TasksModel";
import { AppUrls } from "AppUrls";

export class MasterProjectTasksUpsertEndpoint extends Endpoint<TasksModel, TasksModel> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.MasterProject.UpsertMasterProjectNewTask);
    }

    public async HandleRequestAsync(request: TasksModel): Promise<any> {
        const convertTimeToDate = (value: any) => {
            const initialDate = new Date(value);
            return initialDate.toISOString();
        };

        request.dueDateTime = request.dueDateTime ? convertTimeToDate(request.dueDateTime) : request.dueDateTime;

        return request;
    }

    public async HandleResponseAsync(response: TasksModel): Promise<TasksModel> {
        return response;
    }
}
