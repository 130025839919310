import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ThemedLoader, formatDate } from "Application";
import { createColumnHelper } from "@tanstack/react-table";
import { CenteredTextCell, ExpandEllipsis } from "Utils/CellFormatComponents";
import { SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import AddNoteIcon from "Assets/Icons/editButtonIcon.svg";
import { AddNoteModal } from "../../../../../Components/Modals/AddNoteModalView";
import { CustomerNotesViewModel } from "../../NewCustomer/CustomerNotes/CustomerNotesViewModel";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { IDocumentType } from "Models/Documents/DocumentType";
import { formatDateTime } from "Utils/Formats";
import { PageTabWrapper } from "Styles/BaseStyles";

interface ICommunication {
    id?: string;
    documentTypes: IDocumentType[];

    noteCountCallBack: (newCount: number) => void;
}

export const CommunicationView: React.FC<ICommunication> = observer(({ id, documentTypes, noteCountCallBack }) => {
    // Shared viewModel/model with New Customer views
    const [viewModel] = useState(new CustomerNotesViewModel(id!, noteCountCallBack));
    useEffect(() => {
        viewModel.getNotes();
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.setValue("columnName", columnName);
        viewModel.setValue("orderBy", orderBy);
    };

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("createdDate", {
            size: 60,
            header: () => "Created date",
            cell: (info) => <CenteredTextCell greenText={false} value={info.getValue() !== null ? formatDate(new Date(info.getValue())) : ""} />,
        }),
        columnHelper.accessor("note", {
            size: 600,
            header: () => "Note",
            cell: (info) => <ExpandEllipsis text={info.getValue()} />,
        }),
        columnHelper.accessor("docsCount", {
            size: 30,
            header: () => "Docs",
            cell: (info) => info.renderValue(),
        }),
        columnHelper.accessor("createdByName", {
            size: 150,
            header: () => "Author",
            cell: (info) => info.renderValue(),
        }),
        columnHelper.accessor("updatedDate", {
            size: 100,
            header: () => "Last Updated",
            cell: (info) => <CenteredTextCell greenText={false} value={info.getValue() !== null ? formatDateTime(new Date(info.getValue())) : ""} />,
        }),
        columnHelper.accessor("id", {
            header: () => "Actions",
            cell: (info) => {
                return (
                    <Box className="action-cell">
                        <EditButton id={info.getValue().toString()} onClick={viewModel.openEditNoteModal} />
                        <DeleteButton id={info.getValue().toString()} onClick={viewModel.deleteNoteCheck} />
                    </Box>
                );
            },
            enableSorting: false,
        }),
    ];

    return (
        <>
            {viewModel.isProcessing === true ? (
                <ThemedLoader spinnerText="Loading..." isOpen={true} />
            ) : (
                <PageTabWrapper>
                    {viewModel.showNewNoteModal === true && (
                        <AddNoteModal
                            isOpen={viewModel.showNewNoteModal}
                            closeModal={viewModel.closeAddNoteModal}
                            customerId={viewModel.getValue("id")}
                            viewModel={viewModel}
                            AddOrEdit={viewModel.modalType}
                            documentTypes={documentTypes}
                        />
                    )}

                    {viewModel.areYouSureModelOpen === true && (
                        <AreYouSureModal
                            title="Delete Customer Note"
                            message="Are you sure you want to delete this note?"
                            isOpen={viewModel.areYouSureModelOpen}
                            onCancel={viewModel.closeAreYouSureModal}
                            onConfirm={viewModel.confirmDeleteNote}
                        />
                    )}

                    <Box className="tabheader" flexBox style={{ justifyContent: "space-between" }}>
                        <H5 pt="12px">
                            <strong>Notes</strong>
                        </H5>
                        <ButtonUnder displayName=" Add note" paletteColor="ButtonBlue" icon={<img src={AddNoteIcon} />} boxShadow={true} command={viewModel.openAddNoteModal} />
                    </Box>
                    <Box className="tabheaderbuffer"></Box>
                    <SharmansTable columns={columns} manualSorting={false} data={viewModel.getNotesForTable} onSortChange={updateSorting} />
                    {viewModel.customerNotes.length === 0 && (
                        <P pt="40px" style={{ textAlign: "center" }}>
                            This customer has no notes
                        </P>
                    )}
                </PageTabWrapper>
            )}
        </>
    );
});
