import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConditionReportTemplateSection } from "../AddEdit/ConditionReportTemplateSection";

export class UpsertConditionReportTemplateSectionRequest {
    public templateId: string = "";

    public section: ConditionReportTemplateSection | null = null;

    public constructor() {}
}

class UpsertConditionReportTemplateSectionResponse {
    public section: ConditionReportTemplateSection | null = null;

    public constructor() {}
}

export class UpsertConditionReportTemplateSectionEndpoint extends Endpoint<UpsertConditionReportTemplateSectionRequest, UpsertConditionReportTemplateSectionResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportTemplate.UpsertConditionReportTemplateSection);
    }

    public async HandleRequestAsync(request: UpsertConditionReportTemplateSectionRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: UpsertConditionReportTemplateSectionResponse): Promise<ConditionReportTemplateSection> {
        Logger.logInformation("Response", response);

        //Return your model
        return response.section!;
    }
}
