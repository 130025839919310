import { Endpoint, Http } from "Application";
import { TasksListViewModel } from "../TasksListViewModel";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { Urgency } from "../UrgencyEnum";
import { getFilenameFromContentDisposition } from "Utils/File";
import { AppUrls } from "AppUrls";
class Request {
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public searchString: string = "";
    public showFrom: string = "";
    public showTo: string = "";
    public filterByTaskType: string[] = [];
    public filterByCategory: string[] = [];
    public filterByAssigned: string[] = [];
    public filterByUrgency: Urgency[] = [];
    public filterByCompleted: string[] = [];
    public initialLoad: boolean = true;
}

export class DownloadTasksCSVEndpoint extends Endpoint<Request, Response> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/task/downloadcsv");
        this.responseIsBlob(true);
        this.SetContentType("text/csv");
    }

    public async HandleRequestAsync(model: TasksListViewModel): Promise<any> {
        let request = new Request();

        request.orderBy = model.orderBy;
        request.columnName = model.columnName;
        request.pageSize = model.pageSize;
        request.pageNumber = model.pageNumber;
        request.searchString = model.keywordSearch;

        request.filterByTaskType = [];
        request.filterByCategory = [];
        request.filterByAssigned = [];
        request.filterByUrgency = [];
        request.filterByCompleted = [];

        let tempShowFrom: Date = new Date(model.showFrom);
        tempShowFrom.setHours(23, 59, 59, 59);
        request.showFrom = tempShowFrom.toISOString();

        let tempDate: Date = new Date(model.showTo);
        tempDate.setHours(23, 59, 59, 59);

        request.showTo = tempDate.toISOString();

        request.filterByTaskType = model.filterByTasksType.map((item) => {
            return item;
        });

        request.filterByCategory = model.filterByCategory.map((item) => {
            return item;
        });

        request.filterByAssigned = model.filterByAssignedTo.map((item) => {
            return item;
        });

        request.filterByUrgency = model.filterByUrgency.map((item) => {
            return parseInt(item);
        });

        request.filterByCompleted = model.filterByCompleted;

        request.initialLoad = model.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: any): Promise<boolean> {
        let retVal: boolean = true;
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        //link.href = window.URL.createObjectURL(new Blob([response]));
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        //Return your model
        return retVal;
    }
}
