import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import DeletedTick from "Assets/Icons/TickIcon.svg";

interface IDeletedTickCentered {
    tickOrHyphen: string;
}

export const DeletedTickCentered: React.FC<IDeletedTickCentered> = observer((props) => {
    return (
        <Box flexBox justifyContent="center">
            {props.tickOrHyphen == "tick" ? <img src={DeletedTick} /> : <p>-</p>}
        </Box>
    );
});
