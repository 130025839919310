import { KeyValuePair } from "@shoothill/core";

export enum Urgency {
    Urgent = 1,
    NonUrgent = 0,
}

export module UrgencyHelpers {
    export const getUrgencyText = (value: Urgency) => {
        let retVal: string = "Active";
        if (value == Urgency.Urgent) {
            retVal = "Urgent";
        } else if (value == Urgency.NonUrgent) {
            retVal = "Non Urgent";
        }
        return retVal;
    };

    export const getUrgencyForDropdown = (): KeyValuePair[] => {
        let retVal: KeyValuePair[] = [];

        for (const key in Urgency) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as Urgency;
                retVal.push({
                    text: UrgencyHelpers.getUrgencyText(item),
                    key: keyAsInt.toString(),
                });
            }
        }

        return retVal;
    };
}
