import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { Header, MenuItemType } from "Views/Header/Header";
import { FallbackPageView } from "Components/FallbackPage";
import CustomersRoutes from "./Customers/CustomersRoutes";
import { setPageTitle } from "Application";
import { PropertiesRoutesView } from "./Properties/PropertiesRoutes";
import ClientRoutes from "./Contacts/ClientsRoutes";

export const DirectoryRoutes: React.FC = observer(() => {
    useEffect(() => {
        setPageTitle("Directory");
    }, []);

    const MenuItems: MenuItemType[] = [
        { text: "Customers", path: AppUrls.Client.Directory.Customers[AppUrlIndex.Long], highlightColour: "#269894" },
        { text: "Contacts", path: AppUrls.Client.Directory.Contacts[AppUrlIndex.Long], highlightColour: "#269894" },
        // { text: "Clients", path: AppUrls.Client.Directory.Clients[AppUrlIndex.Long], highlightColour: "#269894" },
        { text: "Properties", path: AppUrls.Client.Directory.Properties[AppUrlIndex.Long], highlightColour: "#269894" },
    ];

    return (
        <>
            <Header title="Directory" />

            <Routes>
                <Route
                    path="*"
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <Navigate to="customers" replace />
                        </React.Suspense>
                    }
                />
                <Route path={"customers/*"} element={<CustomersRoutes />} />
                <Route path={"contacts/*"} element={<ClientRoutes />} />
                <Route path={"properties/*"} element={<PropertiesRoutesView />} />
            </Routes>
        </>
    );
});
