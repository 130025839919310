import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { IAddress } from "Components/AddressGeneric/AddressGenericModel";
import { CustomerStatus } from "Application/Models/Domain/Customer/CustomerStatusEnum";
import { ICustomerNote } from "../Common/ICustomerNote";
import { container } from "tsyringe";
import { CustomerStore } from "Stores/Domain";

export class AddEditCustomerModel extends ModelBase<AddEditCustomerModel> {
    private customerStore = container.resolve(CustomerStore);

    // public id: Guid | null = "10518C49-DADC-4F63-AFF2-27620EEDD238"; // use to test without adding a new customer
    public id: Guid | null = null;
    public customerTypeId: number = this.customerStore.DefaultCustomerTypeId;
    public name: string = "";
    public address: IAddress = { postcode: "", latitude: 0, longitude: 0, addressLineOne: "", addressLineTwo: "", townCity: "", county: "", country: "", countryId: -1 };
    public email: string = "";
    public phone: string = "";
    public phone2: string = "";
    public websiteAddress: string = "";
    // public isDeleted: boolean = false;
    // public createdDate: string = "";
    public createdBy: Guid | null = null;
    public deletedDate: string = "";
    public deletedBy: Guid | null = null;
    public customerStatusId: CustomerStatus = CustomerStatus.Active;
    public rowVersion: string = "";
    public note: string = "";

    // Options
    public optionsId: number | null = null;
    public customerId: Guid | null = null;
    public leadTsmId: number | null = null;
    public sageRef: string | null = "";
    public eORINumber: string | null = "";
    public tSSNumber: string | null = "";
    public bdmId: number | null = null;
    public approved: boolean = false;
    public referral: boolean | null = null;

    public originalCustomerTypeId: number = 0;
    public canChangeCustomerType: boolean = true;

    // Notes
    public notes: IObservableArray<ICustomerNote> = observable([]);

    constructor() {
        super();
        makeObservable(this, {
            // id: observable,
            canChangeCustomerType: observable,
            customerTypeId: observable,
            name: observable,
            address: observable,
            email: observable,
            phone: observable,
            phone2: observable,
            websiteAddress: observable,
            // isDeleted: observable,
            // createdDate:observable,
            createdBy: observable,
            deletedDate: observable,
            deletedBy: observable,
            customerStatusId: observable,
            note: observable,

            customerId: observable,
            leadTsmId: observable,
            bdmId: observable,
            sageRef: observable,
            eORINumber: observable,
            tSSNumber: observable,
            approved: observable,
            referral: observable,
        });
    }

    public clear = () => {
        this.id = "";
        this.customerTypeId = this.customerStore.DefaultCustomerTypeId;
        this.name = "";
        this.address = { postcode: "", latitude: 0, longitude: 0, addressLineOne: "", addressLineTwo: "", townCity: "", county: "", country: "", countryId: -1 };
        this.email = "";
        this.phone = "";
        this.phone2 = "";
        this.websiteAddress = "";
        // public isDeleted: boolean = false;
        // public createdDate: string = "";
        this.createdBy = null;
        this.deletedDate = "";
        this.deletedBy = null;
        this.customerStatusId = CustomerStatus.Active;
        this.rowVersion = "";
        this.note = "";

        // Options
        this.optionsId = null;
        this.customerId = null;
        this.leadTsmId = null;
        this.sageRef = "";
        this.eORINumber = "";
        this.tSSNumber = "";
        this.bdmId = null;
        this.approved = false;
        this.referral = null;

        this.originalCustomerTypeId = 0;
        this.canChangeCustomerType= true;
    };
}

export class AddEditCustomerModelValidator extends Validator<AddEditCustomerModel> {
    constructor(customerStore: CustomerStore) {
        super();
        this.ruleFor("name")
            .notEmpty()
            .withMessage("Please enter a name")
            .notNull()
            .withMessage("Please enter a name")
            .maxLength(256)
            .withMessage("Enter a name less than 256 characters");

        this.ruleFor("note").maxLength(4000).withMessage("Enter a name less than 4000 characters");

        this.ruleFor("email")
            .notEmpty()
            .withMessage("Please enter an email or phone number")
            .when((model) => model.phone === "")
            .notNull()
            .withMessage("Please enter an email or phone number")
            .when((model) => model.phone === "");

        this.ruleFor("email")
            .emailAddress()
            .withMessage("Please enter a valid email Address")
            .when((model) => model.phone === "")
            .maxLength(256)
            .withMessage("Enter an email less than 256 characters")
            .when((model) => model.email !== "");

        this.ruleFor("phone")
            .maxLength(18)
            .withMessage("Enter a phone number less than 18 characters or Big change will fail")
            .notEmpty()
            .withMessage("Please enter a phone number or email")
            .when((model) => model.email === "")
            .notNull()
            .withMessage("Please enter a phone number or email")
            .when((model) => model.email === "");

        // .matches(/^$|^\d{10,16}$/)  // Old regex didn't allow spaces
        // .matches(/^\d{4,16}\s\d{6,16}$/)  // Works for most, but not + or multiple spaces
        // .matches(/^[+\d\s]+$/)

        this.ruleFor("phone2")
            .matches(/^[+\d\s\/]{0,32}$/) // Allow numbers, "+", "/" and spaces, with max length of 32 characters
            .withMessage("Please enter a valid phone number")
            .maxLength(32)
            .withMessage("Enter a phone additional number less than 32 characters");

        this.ruleFor("phone")
            .matches(/^[+\d\s\/]{1,32}$/) // Allow numbers, "+", "/" and spaces, with max length of 32 characters
            .withMessage("Please enter a valid phone number")
            .when((model) => model.phone !== "");

        this.ruleFor("address").notNull().withMessage("Please enter an Address");

        this.ruleFor("leadTsmId")
            .notNull()
            .withMessage("Please select Lead TSM")
            .greaterThan(0)
            .withMessage("Please select Lead TSM")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasTSM === true,
            );

        this.ruleFor("bdmId")
            .notNull()
            .greaterThan(0)
            .withMessage("Please select BDM")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasBDM === true,
            );

        this.ruleFor("customerTypeId").greaterThan(0).withMessage("Please select a customer type");

        this.ruleFor("sageRef")
            .maxLength(50)
            .withMessage("Enter a sage Reference less than 50 characters")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasSageRef === true,
            );
        this.ruleFor("eORINumber")
            .maxLength(50)
            .withMessage("Enter an EORINumber less than 50 characters")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasEORINumber === true,
            );
        this.ruleFor("tSSNumber")
            .maxLength(50)
            .withMessage("Enter an TSSNumber less than 50 characters")
            .when(
                (model) =>
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId) !== null &&
                    customerStore.getMatrixOptionsForCustomerType(model.customerTypeId)?.hasTSSNumber === true,
            );
    }
}
