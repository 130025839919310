import { ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";

export class CustomerContactsModel extends ModelBase<CustomerContactsModel> {
    // public id: string = "";
    public id: Guid | null = null;
    public firstName: string = "";
    public lastName: string = "";
    public phone: string = "";
    public phone2: string = "";
    public email: string = "";
    public jobTitle: string = "";
    public status: number = 1;
    public primaryContact: boolean = false;
    public plygeneTrained: boolean = false;
    public coatingsTrained: boolean = false;
    public datePlygeneTrained: Date | null = null;
    public dateCoatingsTrained: Date | null = null;

    public coatingsCPD: boolean = false;
    public plygeneCPD: boolean = false;
    public dateCoatingsCPD: Date | null = null;
    public datePlygeneCPD: Date | null = null;

    public note: string = "";
    public customerId: Guid = "";
    public marketingConsent: boolean = true;

    // Table sorting

    public columnName: string = "name";
    public orderBy: SortOrderDirection = SortOrderDirection.ASC;

    constructor() {
        super();
        makeObservable(this, {
            // id: observable,
            firstName: observable,
            lastName: observable,
            phone: observable,
            phone2: observable,
            email: observable,
            jobTitle: observable,
            status: observable,
            primaryContact: observable,
            marketingConsent: observable,
            plygeneTrained: observable,
            coatingsTrained: observable,
            datePlygeneTrained: observable,
            dateCoatingsTrained: observable,

            coatingsCPD: observable,
            plygeneCPD: observable,
            dateCoatingsCPD: observable,
            datePlygeneCPD: observable,

            note: observable,
            customerId: observable,

            columnName: observable,
            orderBy: observable,

            clear: action,
        });
    }

    public clear = () => {
        this.id = null;
        this.firstName = "";
        this.lastName = "";
        this.phone = "";
        this.phone2 = "";
        this.email = "";
        this.jobTitle = "";
        this.status = 0;
        this.primaryContact = false;
        this.marketingConsent = true;
        this.plygeneTrained = false;
        this.coatingsTrained = false;
        this.datePlygeneTrained = null;
        this.dateCoatingsTrained = null;

        this.plygeneCPD = false;
        this.coatingsCPD = false;
        this.datePlygeneCPD = null;
        this.dateCoatingsCPD = null;

        this.note = "";
        // this.customerId = null;
    };
}

export class CustomerContactsModelValidator extends Validator<CustomerContactsModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("firstName")
            .notEmpty()
            .withMessage("Please enter a first name")
            .notNull()
            .withMessage("Please enter a first name")
            .maxLength(256)
            .withMessage("Please enter an first name less than 256 characters.");

        this.ruleFor("lastName")
            .notEmpty()
            .withMessage("Please enter a last name")
            .notNull()
            .withMessage("Please enter a last name")
            .maxLength(256)
            .withMessage("Please enter an last name less than 256 characters.");

        this.ruleFor("email")
            .notEmpty()
            .withMessage("Please enter an email or phone number")
            .when((model) => model.phone === "")
            .notNull()
            .withMessage("Please enter an email or phone number")
            .when((model) => model.phone === "");

        this.ruleFor("email")
            .emailAddress()
            .withMessage("Please enter a valid email Address")
            .when((model) => model.phone === "")
            .maxLength(256)
            .withMessage("Enter an email less than 256 characters")
            .when((model) => model.email !== "");

        this.ruleFor("phone")
            .maxLength(18)
            .withMessage("Enter a phone number less than 18 characters or Big change will fail")
            .notEmpty()
            .withMessage("Please enter a phone number or email")
            .when((model) => model.email === "")
            .notNull()
            .withMessage("Please enter a phone number or email")
            .when((model) => model.email === "");

        this.ruleFor("phone2")
            .matches(/^[+\d\s\/]{0,32}$/) // Allow numbers, "+", "/" and spaces, with max length of 32 characters
            .withMessage("Please enter a valid phone number")
            .maxLength(32)
            .withMessage("Enter a phone additional number less than 32 characters");

        this.ruleFor("phone")
            .matches(/^[+\d\s\/]{1,32}$/) // Allow numbers, "+", "/" and spaces, with max length of 32 characters
            .withMessage("Please enter a valid phone number")
            .when((model) => model.phone !== "");

        this.ruleFor("datePlygeneTrained")
            .must((value, model) => {
                if (model.plygeneTrained) {
                    return value !== null;
                }
                return value === null;
            })
            .withMessage("Please select a date for plygene trained")
            .when((model) => model.plygeneTrained === true);

        this.ruleFor("dateCoatingsTrained")
            .must((value, model) => {
                if (model.coatingsTrained) {
                    return value !== null;
                }
                return value === null;
            })
            .withMessage("Please select a date for Coating trained")
            .when((model) => model.coatingsTrained === true);

        this.ruleFor("dateCoatingsCPD")
            .must((value, model) => {
                if (model.coatingsCPD) {
                    return value !== null;
                }
                return value === null;
            })
            .withMessage("Please select a date for coating CPD")
            .when((model) => model.coatingsCPD === true);

        this.ruleFor("datePlygeneCPD")
            .must((value, model) => {
                if (model.plygeneCPD) {
                    return value !== null;
                }
                return value === null;
            })
            .withMessage("Please select a date for plygene CPD")
            .when((model) => model.plygeneCPD === true);
    }
}

export interface ContactListItem {
    id: string;
    jobTitle: string;
    phone: string;
    phone2: string;
    phoneNumber: string;
    email: string;
    datePlygeneTrained: Date | null;
    dateCoatingsTrained: Date | null;
    datePlygeneCPD: Date | null;
    dateCoatingsCPD: Date | null;
    createdDate: string;
    status: number;
    primaryContact: boolean;
}
