import styled from "@emotion/styled";
import { theme } from "Application";

export const SelectContainer = styled.div`
    .editselect__placeholder {
        font-size: ${theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize};
    }
    .editselect__control {
        border: 1px solid ${theme.palette.field.light};
        border-radius: 0 !important;
    }
    .editselect__input {
        font-size: ${theme.fontStyles.select.fontSize}!important;
    }
    .editselect__control--is-focused {
        border: 1px solid ${theme.palette.common.focus} !important;
        box-shadow: none !important;
        border-radius: 0 !important;
    }
    .editselect__value-container {
        padding: 3px 8px;
        white-space: nowrap !important;
        font-size: ${theme.fontStyles.select.fontSize};
    }

    .virtualized-select-validation {
        flex-grow: 1;
    }

    .virtualized-select-validation &&.editSelect-presticky {
        .editselect__menu {
            font-size: ${theme.fontStyles.select.fontSize}!important;
            z-index: 9;
            position: absolute;
        }
    }

    .editselect__single-value {
        font-size: ${theme.fontStyles.select.fontSize}!important;
    }
    .editselect__menu-list {
        ::-webkit-scrollbar {
            width: 5px;
        }
    }

    .topmostmenu {
        .editselect__menu {
            z-index: 9999 !important;
        }
    }

    .editselect__menu {
        font-size: ${theme.fontStyles.select.fontSize}!important;
        z-index: 9;
    }

    .checkbox {
        margin-top: 2px;
        width: 25px;
        height: 20px;
        padding: 0 0 0 5px !important;

        input {
            width: 18px;
            height: 18px;
            accent-color: #337ab7 !important;
        }
    }

    .checkboxrow {
        width: 100%;
        display: flex;
        flex: 1 0 0;
        flex-direction: row;
        justify-content: space-between;
        width: 100% !important;

        .status-option {
            /*             display: flex;
            flex: 1 0 0;
            justify-content: center;
            text-align: center; */
            border-radius: 5px;
            padding-left: 10px;
            .placeholder {
            }

            &.checkbox {
                max-width: 25px;
                margin-right: -25px !important;
                background-color: transparent;
            }
        }
    }

    .multiselect-btn-row {
        display: flex;
        flex: 1 0 0;
        flex-direction: row;
        justify-content: space-around;
        button {
            padding: 5px;
            min-width: 50px;
        }
        padding: 5px;
    }
`;

export const AllNoneButton = styled.button`
    border-radius: 10px;
    font-size: ${theme.fontStyles.allnoneButton.fontSize};
    border: none;
    padding: 5px 15px !important;
    cursor: pointer;
    &.selected {
        background-color: #337ab7;
        color: white;
    }
    &.not-selected {
        background-color: white;
        color: #337ab7;
    }
`;
