import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand } from "Application";
import { ButtonUnderBoxDocUploader, DocPreviewBox, DocPreviewPaddingBox } from "../DocumentUploader.styles";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import { ExcelViewer } from "../OtherFileTypes/ExcelViewer";
import DocxViewer from "../OtherFileTypes/WordViewer";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import BackIcon from "Assets/Icons/backButtonIcon.svg";
import { IAddedDocumentViewModel } from "Models/Documents/IAddedDocumentViewModel";

interface IProps {
    documentList: IAddedDocumentViewModel<any>[];
    previewFileIndex: number;
    navBackToDocumentUploader?: ICommand<any>;
}

export const PreviewView: React.FC<IProps> = observer((props) => {
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    return (
        <>
            <Box p="0px 8px">
                {props.navBackToDocumentUploader && (
                    <ButtonUnderBoxDocUploader>
                        <ButtonUnder
                            displayName={" Back to document uploader"}
                            paletteColor="ButtonCancel"
                            boxShadow={true}
                            icon={<img src={BackIcon} />}
                            command={props.navBackToDocumentUploader}
                        />
                    </ButtonUnderBoxDocUploader>
                )}

                <DocPreviewPaddingBox>
                    <DocPreviewBox>
                        {props.documentList[props.previewFileIndex].model.filename.split(".").pop()?.toLowerCase() === "xlsx" ||
                        props.documentList[props.previewFileIndex].model.filename.split(".").pop()?.toLowerCase() === "xls" ? (
                            <ExcelViewer file={props.documentList[props.previewFileIndex].model.file} />
                        ) : props.documentList[props.previewFileIndex].model.filename.split(".").pop()?.toLowerCase() === "docx" ||
                          props.documentList[props.previewFileIndex].model.filename.split(".").pop()?.toLowerCase() === "odt" ? (
                            <DocxViewer docxFile={props.documentList[props.previewFileIndex].model.file} />
                        ) : (
                            <DocViewer
                                documents={[
                                    {
                                        uri: window.URL.createObjectURL(props.documentList[props.previewFileIndex].model.file),
                                        fileName: props.documentList[props.previewFileIndex].model.filename,
                                        fileType: props.documentList[props.previewFileIndex].model.filetype,
                                    },
                                ]}
                                pluginRenderers={DocViewerRenderers}
                                style={{ width: "768px" }}
                                config={{
                                    header: {
                                        disableFileName: true,
                                    },
                                }}
                            />
                        )}
                    </DocPreviewBox>
                </DocPreviewPaddingBox>

                {/* For using Mircrosoft Document Viewer without the NPM package - won't work unless the file is publically available */}
                {/* <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${convertedFile}`} width="500px" height="350px"></iframe> */}
            </Box>
        </>
    );
});
