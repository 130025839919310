import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TSMNotesModalViewModel } from "../TSMNotesModalViewModel";
import { TSMNotesModalModel } from "../TSMNotesModalModel";
import { TSMProjectDetails } from "../../ModalCommon/TSMModalCommon";

class TSMNotesUpsertRequest {
    id: number | null = null;
    tsmId: number = -1;
    note: string = "";
    rowVersion: string = "";
    projectId: string = "";
}

export class TSMNotesUpsertResponse {
    public tsmNoteList: TsmNoteListItem[] = [];
    public projectDetails: TSMProjectDetails = new TSMProjectDetails();
}

export class TSMNotesUpsertEndpoint extends Endpoint<TSMNotesUpsertRequest, TSMNotesUpsertResponse> {
    constructor() {
        super();
        this.verb(Http.Put);
        this.path(AppUrls.Server.Tsm.upsertNote);
    }

    public async HandleRequestAsync(model: TSMNotesModalModel): Promise<any> {
        let request = new TSMNotesUpsertRequest();
        request.id = model.noteId;
        request.tsmId = model.tsmId;
        request.note = model.note;
        request.rowVersion = model.rowVersion;
        request.projectId = model.projectId;

        return request;
    }

    public async HandleResponseAsync(response: TSMNotesUpsertResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}

export interface TsmNoteListItem {
    id: number;
    createdDate: Date;
    note: string;
    author: string;
    rowVersion: string;
}
