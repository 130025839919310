export enum SharmanTaskTypeEnum {
    ProjectQuote = 100,
    MasterProject = 120,
    MultiSiteProject = 140,
    Specifier = 200,
    Contractor = 300,
    Occupier = 400,
    FacilityManager = 500,
    ProjectQuoteComplaint = 1000,
    MasterProjectComplaint = 1020,
    MultiSiteProjectComplaint = 1040,
    Contact = 2000,
    Customer = 3000,
    Complaints = 1060,
    Tasks = 1080,
}

export module TasksTypeEnumHelpers {
    export const getText = (value: SharmanTaskTypeEnum) => {
        let retVal: string = "Project Quote";
        if (value == SharmanTaskTypeEnum.ProjectQuote) {
            retVal = "ProjectQuote";
        } else if (value == SharmanTaskTypeEnum.MasterProject) {
            retVal = "Master Project";
        }
        return retVal;
    };

    export const geturlComponent = (value: SharmanTaskTypeEnum) => {
        let retVal: string = "projectquote";
        if (value == SharmanTaskTypeEnum.ProjectQuote) {
            retVal = "projectquote";
        } else if (value == SharmanTaskTypeEnum.MasterProject) {
            retVal = "masterproject";
        }
        return retVal;
    };

    export const getFromUrlComponent = (value: string): SharmanTaskTypeEnum => {
        let retVal: SharmanTaskTypeEnum = SharmanTaskTypeEnum.ProjectQuote;
        if (value.toLocaleLowerCase().trim() == "masterproject") {
            retVal = SharmanTaskTypeEnum.MasterProject;
        } else if (value.toLocaleLowerCase().trim() == "customers") {
            retVal = SharmanTaskTypeEnum.Customer;
        } else if (value.toLocaleLowerCase().trim() == "complaints") {
            retVal = SharmanTaskTypeEnum.Complaints;
        } else if (value.toLocaleLowerCase().trim() == "tsmlist") {
            retVal = SharmanTaskTypeEnum.Tasks;
        }
        return retVal;
    };
}
