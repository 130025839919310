import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { LatLongModel, LatLongModelValidator } from "./LatLongModel";
import { AddressGenericViewModel } from "./AddressGenericViewModel";
import { isNumber } from "lodash-es";

export class LatLongEditorViewModel extends ViewModelBase<LatLongModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public addressViewModel: AddressGenericViewModel;

    constructor(addressViewModel: AddressGenericViewModel) {
        super(new LatLongModel());
        this.setValidator(new LatLongModelValidator());

        this.addressViewModel = addressViewModel;

        let lat: string = addressViewModel.model.latitude.toString();
        let dotIndex: number = lat.indexOf(".");
        if (lat.length - dotIndex > 7) {
            lat = lat.substring(0, dotIndex + 5);
        }

        this.setLatitude.execute(lat);
        this.setLongitude.execute(addressViewModel.model.longitude.toString());

        makeObservable(this, {
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public onBlurCommand = () => {
        if (this.isModelValid()) {
            this.addressViewModel.setLatitude.execute(this.model.latitude);
            this.addressViewModel.setLongitude.execute(this.model.longitude);
        }
    };

    //private latitudeRegex: RegExp = new RegExp("^(\\(+|-)?(?:90(?:(?:.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:.[0-9]{1,6})?))$");
    private latitudeRegex: RegExp = new RegExp("/^(-)?(?:90(?:(?:.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:.[0-9]{1,15})?))$/");
    //private latitudeRegex: RegExp = new RegExp(/^-?([1-8]?[1-9]|[1-9]0).{1}d{1,6}$/);
    private longitudeRegex: RegExp = new RegExp("/^(\\+|-)?(?:180(?:(?:.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:.[0-9]{1,6})?))$/");

    public setLatitude: ICommand = new RelayCommand((value: string) => {
        //  if (this.latitudeRegex.test(value) == true) {
        this.model.latitude = value;
        //   } else {
        //       alert("Invalid latitude value " + value);
        //    }
    });

    public setLongitude: ICommand = new RelayCommand((value: string) => {
        //if (this.longitudeRegex.test(value) == true) {
        this.model.longitude = value;
        //}
    });
}
