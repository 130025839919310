import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ComplaintsReportModel, ComplaintsReportModelValidator } from "./ComplaintsReportModel";
import { DownloadComplaintWithCategoryCSVEndpoint } from "../Endpoints/DownloadComplaintWithCategoryCSV";
import { DownloadComplaintsBetweenDatesPDFEndpoint } from "../Endpoints/DownloadComplaintsBetweenDatesPDF";
import { DateTime } from "luxon";

export class ComplaintsViewModel extends ViewModelBase<ComplaintsReportModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;

    constructor() {
        super(new ComplaintsReportModel());
        this.setValidator(new ComplaintsReportModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            clear: action,
            downloadComplaintWithCategoryCSV: action,

            getReportComplaintStatus: computed,
            getReportDateFrom: computed,
            getReportDateTo: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.model.clear();
    };

    /* Report */

    public get getReportComplaintStatus(): string {
        return this.model.reportComplaintStatus;
    }

    public setReportComplaintStatus: ICommand = new RelayCommand((value: string) => {
        this.setValue("reportComplaintStatus", value);
    });

    public setReportDateFrom: ICommand = new RelayCommand((value: moment.Moment) => {
        let temp: string = value.toISOString();
        this.setValue("reportStartDate", DateTime.fromISO(temp));
    });

    public get getReportDateFrom(): Date {
        return new Date(this.model.reportStartDate.toString());
    }

    public setReportDateTo: ICommand = new RelayCommand((value: moment.Moment) => {
        let temp: string = value.toISOString();
        this.setValue("reportEndDate", DateTime.fromISO(temp));
    });

    public get getReportDateTo(): Date {
        return new Date(this.model.reportEndDate.toString());
    }

    public downloadComplaintWithCategoryCSV = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadComplaintWithCategoryCSVEndpoint = new DownloadComplaintWithCategoryCSVEndpoint();
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint CSV");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the csv.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        } else {
        }
    };

    public downloadComplaintsBetweenDates = async (): Promise<void> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            let endpoint: DownloadComplaintsBetweenDatesPDFEndpoint = new DownloadComplaintsBetweenDatesPDFEndpoint();
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this.model);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint PDF");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to download the pdf.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        } else {
        }
    };
}
