import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { CustomerStore } from "Stores/Domain";
import { IAddress } from "Components/AddressGeneric/AddressGenericModel";
import { CoreStoreInstance } from "@shoothill/core";
import { AddressGenericViewModel } from "Components/AddressGeneric/AddressGenericViewModel";
import { DropdownItem } from "Models/DropdownItem";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";
import { ContactListItem } from "Views/Directory/Customers/NewCustomer/CustomerContacts/CustomerContactsModel";
import { UpsertCustomerEndpoint } from "Views/Directory/Customers/Endpoints/UpsertCustomer";
import { GetNewEditRelatedEndpoint, GetNewEditRelatedResponse } from "Views/Directory/Customers/Endpoints/GetNewEditRelated";
import { AddCustomerModel, AddCustomerModelValidator } from "./AddCustomerModel";

export class AddCustomerViewModel extends ViewModelBase<AddCustomerModel> {
    customerStore = container.resolve(CustomerStore);

    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public spinnerText: string = "Loading...";
    public contacts: IObservableArray<ContactListItem> = observable([]);

    public validateAddressForSubmission: boolean = false;
    public isAddressValid: boolean = false;
    public trigger: number = 0;

    public showAttachmentsSection: boolean = false;

    public addressViewModel: AddressGenericViewModel = new AddressGenericViewModel();

    // Related - Main form

    public customerTypes: IObservableArray<CustomerTypeDropdownItem> = observable([]);
    public tsms: IObservableArray<DropdownItem> = observable([]);
    public bdms: IObservableArray<DropdownItem> = observable([]);

    // Related - Documents

    public documentTypes: IObservableArray<IDocumentType> = observable([]);

    // Related - Contacts

    public contactStatusTypes: IObservableArray<ContactStatusDropdownItem> = observable([]);

    constructor() {
        super(new AddCustomerModel());
        this.setValidator(new AddCustomerModelValidator(this.customerStore));
        makeObservable(this, {
            isProcessing: observable,
            validateAddressForSubmission: observable,
            isAddressValid: observable,
            trigger: observable,
            showAttachmentsSection: observable,
            spinnerText: observable,
            addressViewModel: observable,

            checkIfAddressIsValid: action,
            checkIfModelIsValid: action,
            clear: action,
            getRelated: action,
            handleUpdatedAddress: action,
            setShowAttachmentsSectionTrue: action,
            upsertCustomer: action,

            getBDM: computed,
            getBdmsForDropdown: computed,
            getDocTypesForDropdown: computed,
            getEORINumber: computed,
            getLeadTsm: computed,
            getStatusTypesForDropdown: computed,
            getTssNumber: computed,
            getTypesForDropdown: computed,
            getSageRef: computed,
            getTsmsForDropdown: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.spinnerText = "Loading...";
        this.validateAddressForSubmission = false;
        this.isAddressValid = false;
        this.trigger = 0;
        this.showAttachmentsSection = false;
        this.addressViewModel = new AddressGenericViewModel();
        this.customerTypes.clear();
        this.tsms.clear();
        this.bdms.clear();
        this.documentTypes.clear();
        this.contactStatusTypes.clear();
        this.model.clear();
    };

    // First form section
    public setCompanyName: ICommand = new RelayCommand((value: string) => {
        this.model.name = value;
    });

    public setCustomerType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.customerTypeId = value.key;
    });

    public get getTypesForDropdown() {
        let retVal: KeyValuePair[] = [];

        retVal = this.customerTypes.slice().map((type) => {
            return { key: type.id, text: type.name };
        });

        return retVal;
    }

    public setSageRef: ICommand = new RelayCommand((value: string) => {
        this.model.sageRef = value;
    });

    public get getSageRef() {
        if (this.model.sageRef !== undefined) {
            return String(this.model.sageRef);
        } else {
            return "";
        }
    }

    public get getTsmsForDropdown() {
        let retVal: KeyValuePair[] = [];

        retVal = this.tsms.slice().map((tsm: DropdownItem) => {
            return { key: tsm.id, text: tsm.displayName };
        });

        return retVal;
    }

    public setLeadTSM: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.leadTsmId = value.key;
    });

    public get getLeadTsm() {
        if (this.model.leadTsmId !== undefined) {
            return this.model.leadTsmId;
        } else {
            return "";
        }
    }

    public get getBdmsForDropdown() {
        let retVal: KeyValuePair[] = [];

        retVal = this.bdms.slice().map((bdm: DropdownItem) => {
            return { key: bdm.id, text: bdm.displayName };
        });

        return retVal;
    }

    public setBDM: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.bdmId = value.key;
    });

    public get getBDM() {
        if (this.model.bdmId !== undefined) {
            return this.model.bdmId;
        } else {
            return "";
        }
    }

    public setEORINumber: ICommand = new RelayCommand((value: string) => {
        this.model.eORINumber = value;
    });

    public get getEORINumber() {
        if (this.model.eORINumber !== undefined) {
            return String(this.model.eORINumber);
        } else {
            return "";
        }
    }

    public setTSSNumber: ICommand = new RelayCommand((value: string) => {
        this.model.tSSNumber = value;
    });

    public setReferral: ICommand = new RelayCommand((value: boolean) => {
        this.model.referral = value;
    });

    public get getTssNumber() {
        if (this.model.tSSNumber !== undefined) {
            return String(this.model.tSSNumber);
        } else {
            return "";
        }
    }

    // Second form section

    public setCompanyContactEmail: ICommand = new RelayCommand((value: string) => {
        this.model.email = value;
    });

    public setCompanyContactPhone: ICommand = new RelayCommand((value: string) => {
        this.model.phone = value;
    });

    public setCompanyContactPhone2: ICommand = new RelayCommand((value: string) => {
        this.model.phone2 = value;
    });

    public setCompanyWebsiteAddress: ICommand = new RelayCommand((value: string) => {
        this.model.websiteAddress = value;
    });

    public handleUpdatedAddress = (address: IAddress) => {
        this.model.address = address;
    };

    public checkIfAddressIsValid = (isValid: boolean) => {
        this.validateAddressForSubmission = true;
        this.isAddressValid = isValid;
    };

    public checkIfModelIsValid = () => {
        this.validateAddressForSubmission = true;
        if (this.isModelValid() === true && this.isAddressValid === true) {
            CoreStoreInstance.HideInfoBar();
            return true;
        } else {
            return false;
        }
    };

    public async upsertCustomer(): Promise<void> {
        this.spinnerText = "Saving...";
        this.isProcessing = true;
        const endpoint = new UpsertCustomerEndpoint();
        await this.apiClient.sendAsync(endpoint, this.model);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                let response = this.apiClient.Response();
                this.setValue("id", response.id);
                this.model = response;
                this.isProcessing = false;
                this.spinnerText = "Loading...";
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("New/edit customer");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to add customer.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public async getRelated(): Promise<void> {
        this.isProcessing = true;
        const endpoint = new GetNewEditRelatedEndpoint();
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                let response: GetNewEditRelatedResponse = this.apiClient.Response();
                this.customerTypes.clear();
                this.tsms.clear();
                this.bdms.clear();
                this.documentTypes.clear();
                this.contactStatusTypes.clear();
                this.customerTypes.replace(response.customerType);
                this.tsms.replace(response.customerTsm);
                this.bdms.replace(response.customerBdm);
                this.documentTypes.replace(response.documentType);
                this.contactStatusTypes.replace(response.contactStatusType);
                this.model.customerTypeId = 1;
                this.model.referral = false;

                this.model.fromResponse(response);

                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("New/edit customer");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to get the dropdown lists.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public get getStatusTypesForDropdown() {
        let types: KeyValuePair[] = [];

        this.contactStatusTypes.map((type) => {
            types.push({ key: type.id, text: type.statusType });
        });
        return types;
    }

    public get getDocTypesForDropdown() {
        let types: KeyValuePair[] = [];

        this.documentTypes.map((type) => {
            types.push({ key: type.id, text: type.categoryName });
        });

        return types;
    }

    public setShowAttachmentsSectionTrue() {
        this.showAttachmentsSection = true;
    }
}
