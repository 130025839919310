import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { ITasksRelatedResponse } from "Models/Tasks/ITasksRelatedResponse";
import { AppUrls } from "AppUrls";

export class GetTasksRelatedForMasterProjectEndpoint extends EndpointWithoutRequest<ITasksRelatedResponse> {
    constructor(projectId: string) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.MasterProject.GetMasterProjectTasksAndRelated + `/${projectId}`);
    }

    public async HandleResponseAsync(response: ITasksRelatedResponse): Promise<ITasksRelatedResponse> {
        return response;
    }
}
