import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { CustomerMasterProjectistItem, LinkedMasterModel, LinkedMasterModelValidator } from "./LinkedMasterModel";
import { GetLinkedMasterProjectsEndpoint, GetLinkedMasterProjectsResponse } from "./Endpoints/GetLinkedMasterProjectsEndpoint";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { StatusEnum, StatusEnumHelper } from "Models/Project/StatusEnum";
import { StatusDropdownItem } from "Models/DropdownItem";

export class LinkedMasterViewModel extends ViewModelBase<LinkedMasterModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public initialLoad: boolean = true;
    public isProcessing: boolean = false;

    public filterByStatus: string[] = [];
    public statusDropdownItems: StatusDropdownItem[] = StatusEnumHelper.getStatusDropdownOptions();

    public projects: IObservableArray<CustomerMasterProjectistItem> = observable([]);

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.DESC;
    public columnName: string = "createdDate";

    //search string

    public filterStatuses: IObservableArray<KeyValuePairExtended> = observable([]);

    // Pagination
    public customerCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;
    public totalCount: number = 0;

    private masterProjectCountCallBack: (newCount: number) => void;

    constructor(customerId: string | undefined, masterProjectCountCallBack: (newCount: number) => void) {
        super(new LinkedMasterModel());
        this.masterProjectCountCallBack = masterProjectCountCallBack;
        this.model.customerId = customerId!;
        this.setValidator(new LinkedMasterModelValidator());
        this.filterStatuses.replace(StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open));
        this.statusSelectAll();
        makeObservable(this, {
            isProcessing: observable,
            statusDropdownItems: observable,
            filterByStatus: observable,
            clear: action,
            updateStatusFiltering: action,
            statusSelectAll: action,
            statusSelectNone: action,
            getStatusFilter: computed,
            getStatuses: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.pageNumber = 10;
        this.pageCount = 1;
        this.totalCount = 0;
    };

    public async sortProjects(): Promise<void> {
        this.isProcessing = true;

        const endpoint = new GetLinkedMasterProjectsEndpoint();
        await this.apiClient.sendAsync(endpoint);

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                let response = this.apiClient.Response();
                this.projects.replace(response);
                this.isProcessing = false;
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Sort Projects");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to sort projects.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public get getLinkedProjectListItem(): any[] {
        return this.model.linkedMasterProjectsList.slice();
    }

    public get getStatuses(): KeyValuePairExtended[] {
        return this.filterStatuses.slice();
    }
    public get getStatusFilter(): string | string[] {
        return this.filterByStatus.slice();
    }

    public updateStatusFiltering = (values: string[]) => {
        this.filterByStatus = values;
    };

    public statusSelectAll = () => {
        this.filterByStatus = this.filterStatuses.map((item) => {
            return item.key.toString();
        });
    };

    public statusSelectNone = () => {
        this.filterByStatus = [];
    };

    // end Data Table

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public loadLinkedMultiSiteList(linkedMasterProjectsList: CustomerMasterProjectistItem[]) {
        this.model.linkedMasterProjectsList.clear();
        this.customerCount = 0;
        this.model.linkedMasterProjectsList.replace(linkedMasterProjectsList);
    }

    public async getLinkedMaster(): Promise<GetLinkedMasterProjectsResponse> {
        const endpoint = new GetLinkedMasterProjectsEndpoint();
        let retVal: GetLinkedMasterProjectsResponse = new GetLinkedMasterProjectsResponse();
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: GetLinkedMasterProjectsResponse = this.apiClient.Response();
                    this.loadLinkedMultiSiteList(response.customerMasterProjectistItem);
                    this.masterProjectCountCallBack(this.model.linkedMasterProjectsList.length);
                    this.totalCount = response.customerCount;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                    if (this.initialLoad === true) {
                        this.initialLoad = false;
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View Linked master");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the Linked master details.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    }

    public clearFiltering: ICommand = new RelayCommand(() => {
        this.filterByStatus = [];
        this.initialLoad = true;
        this.filterStatuses.replace(StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open));
        this.statusSelectAll();
        this.getLinkedMaster();
    });

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.getLinkedMaster();
    };
}
