import { AppUrls } from "AppUrls";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { getFilenameFromContentDisposition } from "Utils/File";

export class DocumentDownloaderRequest {
    id: Guid = "";
    fileName: string = "";
    blobName: string = "";
    fileType: string = "";
    docType: string = "";
}

class DocumentDownloaderResponse {}

export class DocumentDownloaderEndpoint extends Endpoint<DocumentDownloaderRequest, DocumentDownloaderResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Document.Download);
        this.responseIsBlob(true);
    }

    public async HandleRequestAsync(model: DocumentDownloaderRequest): Promise<any> {
        return model;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.target = "_blank";
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        return Promise.resolve();
    }
}
