import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, EditDate, EditSelect, ICommand, Input, RelayCommand } from "Application";
import { TSMListViewModel } from "Views/TSM/TSMListViewModel";
import Tick from "Assets/Icons/SmallTickCircleIcon.svg";
import Close from "Assets/Icons/RoundCloseIcon.svg";
import SiteVisitIcon from "Assets/Icons/SiteVisitIcon.svg";
import { TSMSiteVisitViewModel } from "./TSMSiteVisitModalViewModel";
import { P } from "Components/Primitives/TextElements/TextElements";
import { CoreStoreInstance } from "@shoothill/core";
import { DatePickerBox } from "Components/Primitives/DatePickerBox";
import { DateWrapperBox, CustomModalInner, CustomModalWithEditableHeder, TSMModalWrapper, CustomModalFooter } from "../TSMModal.styles";
import { HeaderBox, HeaderBoxCloseButton, ModalBody, ModalHeader, ModalWrapper } from "Components/ModalGenericWithHeader/ModalGeneric.styles";
import CloseIcon from "Assets/close_icon.svg";
import moment from "moment";

export interface IModalProps {
    closeModalCommand: ICommand<any>;
    isOpen: boolean;
    parentViewModel: TSMListViewModel;
}

export const TsmSiteVisitModal: React.FC<IModalProps> = observer(({ parentViewModel, isOpen, closeModalCommand }) => {
    const [viewModel] = useState(() => new TSMSiteVisitViewModel(parentViewModel));
    useEffect(() => {
        if (parentViewModel.selectedRowId) {
            viewModel.loadDataFromParentViewModel(parentViewModel.selectedRowId);
            viewModel.LoadRelated(parentViewModel.selectedRowId);
        }
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    // Close the modal after upserting the Site Visit Request

    const upsertSiteVisitRequest: ICommand = new RelayCommand(() => {
        if (viewModel.isModelValid()) {
            let promise = viewModel.upsertSiteVisitRequest();
            promise.then(() => {
                closeModalCommand.execute();
                CoreStoreInstance.HideInfoBar();
            });
        }
    });

    return (
        <>
            <React.Fragment>
                <CustomModalWithEditableHeder>
                    <CustomModalInner>
                        <ModalWrapper>
                            <ModalHeader>
                                <HeaderBox>
                                    <img src={SiteVisitIcon} height="20px" />
                                    <Box>
                                        <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                            {"Request Site Visit"}
                                        </P>
                                    </Box>
                                </HeaderBox>
                                <HeaderBoxCloseButton style={{ cursor: "pointer" }} icon={<img src={CloseIcon} />} command={closeModalCommand}></HeaderBoxCloseButton>
                            </ModalHeader>
                        </ModalWrapper>
                        <ModalBody className="modalBody">
                            <TSMModalWrapper p="15px 9px">
                                <DateWrapperBox p="15px 0px" maxWidth="300px" grid dc="1fr 1fr" style={{ alignItems: "end" }}>
                                    <DatePickerBox>
                                        <EditDate
                                            command={viewModel.setSiteVisitDate}
                                            dateFormat={"DD/MM/YYYY HH:mm"}
                                            value={viewModel.getValue("visitDateTime")}
                                            displayName="Site visit date"
                                        />
                                    </DatePickerBox>
                                    <P pb="12px">at {moment(viewModel.getVisitDateTime).format("h:mm A")}</P>
                                </DateWrapperBox>
                                <Box p="15px 0px">
                                    <EditSelect
                                        displayName="Type of visit*:"
                                        command={viewModel.setTypeOfVisit}
                                        options={viewModel.getVisitTypesForDropdown}
                                        value={() => viewModel.getValue("visitTypeId")}
                                        validationMessage={() => viewModel.getError("visitTypeId")}
                                        errorIconRight={false}
                                    />
                                </Box>

                                <Box p="15px 0px">
                                    <EditSelect
                                        displayName="Contact*:"
                                        command={viewModel.setContact}
                                        options={viewModel.getContactsForDropdown}
                                        value={() => viewModel.getValue("contactId")}
                                        validationMessage={() => viewModel.getError("contactId")}
                                        errorIconRight={false}
                                    />
                                </Box>

                                <Box p="15px 0px">
                                    <Input displayName="Additional note:" command={viewModel.setNote} value={() => viewModel.getValue("note")} multiline={true} rows={6} />
                                </Box>
                            </TSMModalWrapper>
                        </ModalBody>
                        <CustomModalFooter>
                            <Button displayName=" Request site visit" command={upsertSiteVisitRequest} icon={<img src={Tick} />} paletteColor="ButtonGreen" boxShadow={true} />
                            <Button displayName=" Close" icon={<img src={Close} />} command={closeModalCommand} />{" "}
                        </CustomModalFooter>
                    </CustomModalInner>
                </CustomModalWithEditableHeder>
            </React.Fragment>
        </>
    );
});
