import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { MasterProjectViewModel } from "../MasterProjectViewModel";
import { AppUrls } from "AppUrls";

class DeletePropertyMasterProjectByIdRequest {
    Id: Guid = "";
    propertyId: Guid = "";
}

class DeletePropertyMasterProjectByIdResponse {}

export class DeletePropertyMasterProjectByIdEndpoint extends Endpoint<DeletePropertyMasterProjectByIdRequest, DeletePropertyMasterProjectByIdResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.DeleteMasterProjectById);
    }

    public async HandleRequestAsync(viewModel: MasterProjectViewModel): Promise<any> {
        let request = new DeletePropertyMasterProjectByIdRequest();
        request.Id = viewModel.propertyMasterToDeleteId;
        request.propertyId = viewModel.model.propertyId;
        return request;
    }

    public async HandleResponseAsync(response: DeletePropertyMasterProjectByIdResponse): Promise<any> {
        return response;
    }
}
