import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable } from "mobx";
import { Validator } from "Application/Validation";
import { CorrectiveAction } from "./CorrectiveAction";
import { PreventativeAction } from "./PreventativeAction";

export class ComplaintModel extends ModelBase<ComplaintModel> {
    public id: string | null = null;
    public referenceId: string = "";
    public reportedBy: string = "";
    public companyReportedBy: string = "";
    public isResolved: boolean = false;
    public resolvedDate: string | null = null;
    public siteAddress: string = "";
    public natureId: number = -1;
    public details: string = "";
    public assignedUserId: number = -1;
    public assessment: string = "";
    public isDeleted: boolean = false;
    public rowVersion: string = "";
    public categoryId: number | null = null;
    public resolvedCategoryId: number | null = null;
    public projectId: string = "";
    public installationContractorId: string | null = null;
    public installationContractorName: string = "";
    public orderNumber: string = "";
    public createdBy: string | null = null;
    public createdByName: string = "";
    public preventativeAcions: PreventativeAction[] = [];
    public correctiveActions: CorrectiveAction[] = [];

    public reportingCompanyId: string | null = "";
    public orderValue: string = "";
    constructor() {
        super();
        makeObservable(this, {
            referenceId: observable,
            reportedBy: observable,
            companyReportedBy: observable,
            isResolved: observable,
            resolvedDate: observable,
            siteAddress: observable,
            natureId: observable,
            details: observable,
            assignedUserId: observable,
            assessment: observable,
            rowVersion: observable,
            categoryId: observable,
            resolvedCategoryId: observable,
            projectId: observable,
            installationContractorId: observable,
            installationContractorName: observable,
            orderNumber: observable,
            createdBy: observable,
            createdByName: observable,

            preventativeAcions: observable,
            correctiveActions: observable,
            reportingCompanyId: observable,
            orderValue: observable,

            clear: action,
        });
    }

    public clear = () => {
        this.id = "";

        this.referenceId = "";
        this.reportedBy = "";
        this.companyReportedBy = "";
        this.isResolved = false;
        this.resolvedDate = null;

        this.siteAddress = "";
        this.natureId = -1;
        this.details = "";
        this.assignedUserId = -1;
        this.assessment = "";
        this.isDeleted = false;
        this.rowVersion = "";
        this.categoryId = -1;
        this.resolvedCategoryId = null;
        this.projectId = "";
        this.installationContractorId = "";
        this.installationContractorName = "";
        this.orderNumber = "";
        this.createdBy = null;
        this.createdByName = "";
        this.reportingCompanyId = "";
        this.orderValue = "";
        this.preventativeAcions = [];
        this.correctiveActions = [];
    };
}

export class ComplaintModelValidator extends Validator<ComplaintModel> {
    constructor() {
        super();

        this.ruleFor("reportedBy")
            .minLength(2)
            .withMessage("Please enter a minimum of 2 characters")
            .maxLength(256)
            .withMessage("Please enter the reportedBy details less than 256 characters");

        this.ruleFor("companyReportedBy").maxLength(256).withMessage("Please enter the companyReportedBy details less than 256 characters");

        this.ruleFor("siteAddress").notNull().withMessage("Please enter the site Address").notEmpty().withMessage("Please enter the site Address");

        this.ruleFor("details").notEmpty().withMessage("Please enter the complaint details").notNull().withMessage("Please enter the complaint details");

        this.ruleFor("natureId").greaterThan(0).withMessage("Please select a nature of complaint");

        this.ruleFor("assignedUserId").greaterThan(0).withMessage("Please select an assigned user");

        this.ruleFor("reportingCompanyId").notEmpty().withMessage("Please select a reporting company");

        this.ruleFor("installationContractorId").notNull().withMessage("Please select a installation company").notEmpty().withMessage("Please select a installation company");

        this.ruleFor("resolvedCategoryId")
            .greaterThan(0)
            .notNull()
            .withMessage("Please select a resolved category")
            .when((model) => model.isResolved === true);

        this.ruleFor("categoryId").notNull().withMessage("Please select a category");

        this.ruleFor("orderValue")
            .notEmpty()
            .withMessage("Please enter a order value")
            .matches(/^\d+(\.\d+)?$/)
            .withMessage("Please enter a valid number");
    }
}
