import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { TSMNoteListItemModel, TSMNoteListItemModelValidator } from "./TSMNoteListItemModel";

export class TSMNoteListItemViewModel extends ViewModelBase<TSMNoteListItemModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super(new TSMNoteListItemModel());
        this.setValidator(new TSMNoteListItemModelValidator());
        makeObservable(this, {
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public setNoteCommand: ICommand = new RelayCommand((value: string) => {
        this.setValue("noteforEditing", value);
    });
}
