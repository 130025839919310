import { action, computed, makeObservable, runInAction } from "mobx";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ConditionReportModel, ConditionReportModelValidator } from "./Models/ConditionReportModel";
import { GetConditionReportForViewByIdEndpoint } from "./Endpoints/GetConditionReportForViewByIdEndpoint";
import { Logger } from "index";

export class ConditionReportDetailViewModel extends ViewModelBase<ConditionReportModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public jsonFile: File | undefined = undefined;

    constructor() {
        super(new ConditionReportModel());
        this.setValidator(new ConditionReportModelValidator());
        makeObservable(this, {
            clear: action,
            loadConditionReportForIdAsync: action,

            getHasConditionReport: computed,
            getConditionReport: computed,
        });
    }

    public get getHasConditionReport() {
        return this.model.id !== null || this.model.id !== undefined;
    }

    public get getConditionReport() {
        return this.model;
    }

    public loadConditionReportForIdAsync = async (conditionReportId: string) => {
        const endpoint = new GetConditionReportForViewByIdEndpoint(conditionReportId);
        await this.apiClient.sendAsync(endpoint);
        Logger.logDebug("loadConditionReportForIdAsync: ", this.apiClient.Response<ConditionReportModel>());
        if (this.apiClient.IsRequestSuccessful === true) {
            let response: ConditionReportModel = this.apiClient.Response<ConditionReportModel>();

            runInAction(() => {
                this.model.load(response);
            });
        } else {
            this.errorStore.setHeaderText("Condition Report");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to download the condition report.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    };

    public clear = () => {
        this.model.clear();
    };
}
