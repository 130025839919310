import { ConditionReportPage } from "./ConditionReportPage";
import { action, makeObservable, observable, IObservableArray, computed } from "mobx";

export class SectionRow {
    public id: number | null = null;
    public sectionId: number | null = null;
    public pages: IObservableArray<ConditionReportPage> = observable([]);

    constructor() {
        makeObservable(this, {
            id: observable,
            sectionId: observable,

            clear: action,
            load: action,

            getPages: computed,
            hasPages: computed,
        });
    }

    public get hasPages(): boolean {
        let retVal: boolean = false;

        for (const element of this.pages.slice()) {
            if (element.hasSections === true) {
                retVal = true;
                break;
            }
        }
        return retVal;
    }

    public get getPages() {
        return this.pages.slice();
    }

    public load(row: SectionRow) {
        this.clear();

        if (row !== undefined) {
            this.id = row.id;
            this.sectionId = row.sectionId;

            for (let i: number = 0; i < row.pages.length; i++) {
                let newP: ConditionReportPage = new ConditionReportPage();
                newP.load(row.pages[i]);
                this.pages.push(newP);
            }
        }
    }

    public clear = () => {
        this.id = null;
        this.sectionId = null;
        this.pages.clear();
    };
}
