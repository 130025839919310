import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { TemplateListViewViewModel } from "../TemplateListViewViewModel";
import { AppUrls } from "AppUrls";
import { GetTemplateListResponse } from "./GetTemplateListEndpoint";

class DeleteTemplateByIdRequest {
    pageSize: number = 50;
    pageNumber: number = 1;
    searchString: string = "";
    orderBy: string = "";
    columnName: string = "";

    id: string = "";

    public constructor() {}
}

export class DeleteTemplateByIdEndpoint extends Endpoint<DeleteTemplateByIdRequest, GetTemplateListResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportTemplate.DeleteById);
    }

    public async HandleRequestAsync(model: TemplateListViewViewModel): Promise<any> {
        let request = new DeleteTemplateByIdRequest();
        request.columnName = model.columnName;
        request.orderBy = model.orderBy;
        request.pageNumber = model.pageNumber;
        request.pageSize = model.pageSize;
        request.searchString = model.searchString;
        request.id = model.templateToDeleteId;

        return request;
    }

    public async HandleResponseAsync(response: GetTemplateListResponse): Promise<GetTemplateListResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
