import { LoginModel } from "./LoginModel";
import { LoginViewModel } from "./LoginViewModel";
import { Logger } from "index";
import { Endpoint, Http } from "Application";
import { AppUrls } from "AppUrls";

class PostLoginRequest {
    public emailAddress: string = "";
    public password: string = "";
}

class PostLoginResponse {
    public jwt: string = "";
}

export class POSTLoginEndpoint extends Endpoint<PostLoginRequest, PostLoginResponse> {
    private viewModel: LoginViewModel;
    private rememberMe: boolean = false;

    constructor(viewModel: LoginViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Patch);
        this.path("/api/Account/Login");
        this._sendBearer = false;
    }

    public async HandleRequestAsync(model: LoginModel): Promise<any> {
        let request = new PostLoginRequest();
        request.emailAddress = model.emailAddress;
        request.password = model.password;
        this.rememberMe = model.rememberMe;
        return request;
    }

    public async HandleResponseAsync(response: PostLoginResponse): Promise<string> {
        Logger.logInformation("Response", response);
        this.viewModel.model.emailAddress = "";
        await this.viewModel.accountStore.getLoginState(response.jwt as string);
        if (this.rememberMe) {
            localStorage.setItem("rememberMe", "true");
        }
        let from = { pathname: "/" };
        if (from) {
            this.viewModel.history.push(from.pathname);
        }
        return response.jwt;
    }
}
