import { AppRoutes } from "./AppRoutes";
import React from "react";
import { GlobalHistory } from "index";
import { CustomThemeProvider } from "./Application";
import { CustomBrowserRouter } from "Components/CustomRouter";
import { GlobalStyles } from "./Styles/GlobalStyles";

export const App: React.FC = () => {
    return (
        <CustomThemeProvider>
            <GlobalStyles />
            <CustomBrowserRouter history={GlobalHistory}>
                <AppRoutes />
            </CustomBrowserRouter>
        </CustomThemeProvider>
    );
};
