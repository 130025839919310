import styled from "@emotion/styled";
import { Box, theme } from "Application";
import { BaseWrapper } from "Components/BaseWrapper";

export const ComplaintsListWrapper = styled(BaseWrapper)``;

export const ComplaintsReportWrapper = styled(Box)`
    padding: 0 15px 15px 15px;

    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    justify-content: flex-start;
`;

export const ComplaintsReportRow = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: flex-start;
`;

export const ComplaintsReportRadio = styled(Box)`
    max-width: 320px;
    label {
        font-size: ${theme.fontStyles.label.fontSize} !important;
        display: flex !important;
        align-items: center;
    }
`;

export const ComplaintsFilterWrapper = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    padding-top: 15px;
`;

export const ComplaintsFilterRow = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    background-color: #ededed;
    padding: 15px;
`;

export const ComplaintsFilterSearchRow = styled(Box)`
    display: grid;
    grid-template-columns: 3fr 3fr;
    margin-bottom: 20px;
`;

export const ComplaintDateWrapper = styled(Box)`
    max-height: 45px;
    display: grid;
    grid-template-columns: 3fr 3fr;
    column-gap: 15px;
    row-gap: 12px;
    margin-right: 30px;
`;

export const ComplaintTableDateWrapper = styled(Box)`
    margin-left: 30px;
    /* max-height: 45px; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 12px;
`;

export const ViewTopSection = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

export const LeftDatePicker = styled(Box)`
    .drop-to-the-right .rdtPicker {
        right: auto;
        left: 0;
    }
`;

export const Heading = styled(Box)`
    & > div > div {
        position: relative;
        & > div:first-child {
            position: absolute;
            left: 0;
            top: -17px;
        }
    }
    button {
        min-height: 35px;
        min-width: 60px;
        border-radius: 6px;
    }
`;
