import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { AppUrls } from "AppUrls";

class DeleteCustomerNoteDocumentByIdRequest {
    id: Guid = "";
    noteId: Guid = "";
}

export class DeleteCustomerNoteDocumentByIdEndpoint extends Endpoint<DeleteCustomerNoteDocumentByIdRequest, IDocumentUploaded[]> {
    public id: Guid = "";
    public noteId: Guid = "";
    constructor(id: Guid, noteId: Guid) {
        super();
        this.id = id;
        this.noteId = noteId;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.DeleteCustomerNoteDocumentById);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new DeleteCustomerNoteDocumentByIdRequest();
        request.id = this.id;
        request.noteId = this.noteId;
        return request;
    }

    public async HandleResponseAsync(response: IDocumentUploaded[]): Promise<IDocumentUploaded[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
