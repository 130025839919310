import { AppUrls } from "AppUrls";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { TasksModel } from "Models/Tasks/TasksModel";

export class ComplaintTasksUpsertEndpoint extends Endpoint<TasksModel, TasksModel> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.MasterProject.UpsertMasterProjectNewTask);
    }

    public async HandleRequestAsync(request: TasksModel): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: TasksModel): Promise<TasksModel> {
        return response;
    }
}
