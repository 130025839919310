export enum ComplaintTypeEnum {
    Unknown = 0,
    ProjectQuote = 1,
    MasterProject = 2,
}

export module ComplaintTypeEnumHelpers {
    export const getText = (value: ComplaintTypeEnum) => {
        let retVal: string = "Project Quote";
        if (value == ComplaintTypeEnum.ProjectQuote) {
            retVal = "ProjectQuote";
        } else if (value == ComplaintTypeEnum.MasterProject) {
            retVal = "Master Project";
        }
        return retVal;
    };

    export const geturlComponent = (value: ComplaintTypeEnum) => {
        let retVal: string = "projectquote";
        if (value == ComplaintTypeEnum.ProjectQuote) {
            retVal = "projectquote";
        } else if (value == ComplaintTypeEnum.MasterProject) {
            retVal = "masterproject";
        }
        return retVal;
    };

    export const getFromUrlComponent = (value: string): ComplaintTypeEnum => {
        let retVal: ComplaintTypeEnum = ComplaintTypeEnum.ProjectQuote;
        if (value.toLocaleLowerCase().trim() == "masterproject") {
            retVal = ComplaintTypeEnum.MasterProject;
        }
        return retVal;
    };
}
