import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { KeyValuePair } from "@shoothill/core/dist/Models/KeyValuePair";

export class GetConditionReportListFiltersResponse {
    public editors: KeyValuePair<string>[] = [];
}

export class GetConditionReportListFiltersEndpoint extends EndpointWithoutRequest<GetConditionReportListFiltersResponse> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.ConditionReport.GetListFilters);
    }

    public async HandleResponseAsync(response: GetConditionReportListFiltersResponse): Promise<GetConditionReportListFiltersResponse> {
        Logger.logInformation("Response", response);
        return response;
    }
}
