import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ViewCustomerModel extends ModelBase<ViewCustomerModel> {
    public name: string = "";
    public customerStatusId: number = 0;
    public createdByName: string = "";
    public noteCount: number = 0;
    public documentCount: number = 0;
    public taskCount: number = 0;
    public contactCount: number = 0;
    public multiSiteCount: number = 0;
    public masterProjectCount: number = 0;
    public projectQuoteCount: number = 0;
    public rowVersion: string = "";

    constructor() {
        super();
        makeObservable(this, {
            name: observable,
            customerStatusId: observable,
            createdByName: observable,

            noteCount: observable,
            documentCount: observable,
            taskCount: observable,
            contactCount: observable,
            multiSiteCount: observable,
            masterProjectCount: observable,
            projectQuoteCount: observable,
        });
    }

    public clear = () => {
        this.id = "";
        this.name = "";
        this.customerStatusId = 0;
        this.createdByName = "";
        this.createdDate = new Date();
        this.noteCount = 0;
        this.documentCount = 0;
        this.taskCount = 0;
        this.contactCount = 0;
        this.multiSiteCount = 0;
        this.masterProjectCount = 0;
        this.projectQuoteCount = 0;
        this.rowVersion = "";
    };
}

export class ViewCustomerModelValidator extends Validator<ViewCustomerModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
