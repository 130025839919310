import { makeObservable, action, computed, observable, runInAction, IObservableArray } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { PropertyMultiSiteProjectListItem } from "../Models/PropertyMultiSiteProjectListItem";
import { StatusEnum, StatusEnumHelper } from "Models/Project/StatusEnum";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { GetPropertyLinkedMultiSiteAndRelatedEndpoint, PropertyLinkedMultiSiteAndRelatedResponse } from "./Endpoints/GetLinkedMultiSiteAndRelatedEndpoint";
import { PropertyLinkedMultiSiteModel } from "./PropertyLinkedMultiSiteModel";
import { StatusDropdownItem } from "Models/DropdownItem";
import { DeletePropertyMultiSiteProjectByIdEndpoint } from "./Endpoints/DeletePropertyMultiSiteProjectByIdEndpoint";

export class MultiSiteProjectViewModel extends ViewModelBase<PropertyLinkedMultiSiteModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public filterStatuses: IObservableArray<KeyValuePairExtended> = observable([]);
    public statusDropdownItems: StatusDropdownItem[] = StatusEnumHelper.getStatusDropdownOptions();

    public isProcessing: boolean = false;
    public initialLoad: boolean = true;

    // Table ordering
    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "displayName";

    // Pagination
    public customerCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;
    public totalCount: number = 0;

    // Filtering top section

    public filterByStatus: string[] = [];

    // Deleting property

    public areYouSureModelOpen: boolean = false;
    public propertyMultiSiteToDeleteId: Guid = "";

    private multiSiteProjectCountCallBack: (newCount: number) => void;

    constructor(propertyId: string, multiSiteProjectCountCallBack: (newCount: number) => void) {
        super(new PropertyLinkedMultiSiteModel());
        this.model.propertyId = propertyId!;
        this.multiSiteProjectCountCallBack = multiSiteProjectCountCallBack;
        this.filterStatuses.replace(StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open));
        this.statusSelectAll();
        makeObservable(this, {
            filterByStatus: observable,
            isProcessing: observable,
            pageSize: observable,
            pageNumber: observable,
            totalCount: observable,
            filterStatuses: observable,
            statusDropdownItems: observable,
            areYouSureModelOpen: observable,
            propertyMultiSiteToDeleteId: observable,

            clear: action,
            updateStatusFiltering: action,
            statusSelectAll: action,
            statusSelectNone: action,
            confirmDeleteProperty: action,
            deletePropertyCheck: action,

            getStatuses: computed,
            getStatusFilter: computed,
            getLinkedMultiSiteList: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.pageSize = 10;
        this.pageNumber = 1;
        this.totalCount = 0;
        this.propertyMultiSiteToDeleteId = "";
    };

    // Status

    public updateStatusFiltering(values: string[]) {
        this.filterByStatus = values;
    }

    public statusSelectAll() {
        this.filterByStatus = this.filterStatuses.map((item) => {
            return item.key.toString();
        });
    }

    public statusSelectNone() {
        this.filterByStatus = [];
    }

    public get getStatuses(): KeyValuePairExtended[] {
        return this.filterStatuses.slice();
    }

    public get getStatusFilter(): string | string[] {
        return this.filterByStatus.slice();
    }

    // End Status

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public get getLinkedMultiSiteList(): PropertyMultiSiteProjectListItem[] {
        return this.model.linkedMultiSiteList.slice();
    }

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.getPropertyLinkedMultiSite();
    };

    public loadLinkedMultiSiteList(linkedMultiSiteList: PropertyMultiSiteProjectListItem[]) {
        this.model.linkedMultiSiteList.clear();
        this.customerCount = 0;
        this.model.linkedMultiSiteList.replace(linkedMultiSiteList);
    }

    public getPropertyLinkedMultiSite = async (): Promise<PropertyLinkedMultiSiteAndRelatedResponse> => {
        const endpoint = new GetPropertyLinkedMultiSiteAndRelatedEndpoint();
        let retVal: PropertyLinkedMultiSiteAndRelatedResponse = new PropertyLinkedMultiSiteAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.model.linkedMultiSiteList.clear();
                    this.isProcessing = false;
                    let response: PropertyLinkedMultiSiteAndRelatedResponse = this.apiClient.Response();
                    this.loadLinkedMultiSiteList(response.multiSiteProjects);
                    this.multiSiteProjectCountCallBack(this.model.linkedMultiSiteList.length);
                    this.totalCount = response.propertyCount;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                    if (this.initialLoad === true) {
                        this.initialLoad = false;
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View Linked MultiSite");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the Linked MultiSite.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    // delete Property

    public deletePropertyCheck = (e: any, id: string): void => {
        this.propertyMultiSiteToDeleteId = id;
        this.areYouSureModelOpen = true;
    };

    public confirmDeleteProperty = () => {
        this.deleteMultiSiteProject();
    };

    public closeAreYouSureModal = () => {
        this.areYouSureModelOpen = false;
    };

    public async deleteMultiSiteProject(): Promise<void> {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            const endpoint = new DeletePropertyMultiSiteProjectByIdEndpoint();
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.pageCount = Math.ceil(this.customerCount / this.pageSize);
                    this.isProcessing = false;
                    this.areYouSureModelOpen = false;
                    this.getPropertyLinkedMultiSite();
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Multi Site Project");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the multi site project.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    // end delete Property

    public clearFiltering: ICommand = new RelayCommand(() => {
        this.filterByStatus = [];
        this.initialLoad = true;
        this.filterStatuses.replace(StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open));
        this.statusSelectAll();
        this.getPropertyLinkedMultiSite();
    });
}
