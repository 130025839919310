import { action, makeObservable, observable, IObservableArray, computed } from "mobx";
import { ConditionReportSection } from "./ConditionReportSection";
export class ConditionReportPage {
    public id: number | null = null;
    public conditionReportId: string | null = null;
    public sectionRowId: number | null = null;
    public label: string = "";
    public name: string = "";
    public sections: IObservableArray<ConditionReportSection> = observable([]);

    constructor() {
        makeObservable(this, {
            id: observable,
            conditionReportId: observable,
            sectionRowId: observable,
            label: observable,
            name: observable,

            clear: action,
            load: action,

            hasSections: computed,
        });
    }

    public get hasSections(): boolean {
        let retVal: boolean = false;

        for (const element of this.sections.slice()) {
            if (element.hasQuestionsOrRows === true) {
                retVal = true;
                break;
            }
        }

        return retVal;
    }

    public load(page: ConditionReportPage) {
        this.clear();
        if (page !== undefined) {
            this.id = page.id;
            this.conditionReportId = page.conditionReportId;
            this.sectionRowId = page.sectionRowId;
            this.label = page.label;
            this.name = page.name;

            for (let i: number = 0; i < page.sections.length; i++) {
                let newSection: ConditionReportSection = new ConditionReportSection();
                newSection.load(page.sections[i]);
                this.sections.push(newSection);
            }
        }
    }

    public clear = () => {
        this.id = null;
        this.conditionReportId = null;
        this.sectionRowId = null;
        this.label = "";
        this.name = "";
        this.sections.clear();
    };
}
