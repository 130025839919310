import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class LinkedMasterModel extends ModelBase<LinkedMasterModel> {
    public id: string = "";
    public customerId: Guid = "";
    public linkedMasterProjectsList: IObservableArray<CustomerMasterProjectistItem> = observable([]);

    constructor() {
        super();
        makeObservable(this, {
            customerId: observable,
            linkedMasterProjectsList: observable,
        });
    }
}

export class LinkedMasterModelValidator extends Validator<LinkedMasterModel> {
    constructor() {
        super();
    }
}

export interface CustomerMasterProjectistItem {
    id: Guid;
    number: string;
    bdm: string;
    createdDate: string;
    projectName: string;
    postcode: string;
    netValue: number;
    wonValue: number;
    openValue: number;
    lostValue: number;
    status: number;
    customerId: Guid;
}
