import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class TaskModel extends ModelBase<TaskModel> {
    public id: string | null = null;
    public sourceId: string | null = null;
    public taskTypeId: string = "";
    public taskTypeName: string = "";
    public subTaskTypeId: string = "";
    public subTaskTypeName: string = "";
    public assignedUserId: string = "";
    public description: string = "";
    public startDateTime: Date = new Date();
    public dueDateTime: Date = new Date();
    public isUrgent: boolean = false;
    public isComplete: boolean = false;
    public assignedUserName: string = "";
    public completedDateTime: string | null = null;
    public projectName: string | null = "";
    public customerName: string | null = null;
    public customerType: string | null = null;
    public enumId: number = 0;
    constructor() {
        super();
        makeObservable(this, {
            sourceId: observable,
            taskTypeName: observable,
            taskTypeId: observable,
            subTaskTypeId: observable,
            subTaskTypeName: observable,
            assignedUserId: observable,
            description: observable,
            startDateTime: observable,
            dueDateTime: observable,
            isUrgent: observable,
            isComplete: observable,
            assignedUserName: observable,
            completedDateTime: observable,
            projectName: observable,
            customerName: observable,
            customerType: observable,
            enumId: observable,
        });
    }

    public clear = () => {
        this.id = null;
        this.sourceId = null;
        this.taskTypeId = "";
        this.taskTypeName = "";
        this.subTaskTypeId = "";
        this.subTaskTypeName = "";
        this.assignedUserId = "";
        this.description = "";
        this.startDateTime = new Date();
        this.dueDateTime = new Date();
        this.isUrgent = false;
        this.isComplete = false;
        this.enumId = 0;
        this.assignedUserName = "";
        this.completedDateTime = null;
        this.projectName = "";
        this.customerName = null;
        this.customerType = null;
    };
}

export interface TaskListItem {
    id: string;
    taskTypeId: string;
    taskTypeName: string;
    subTaskTypeId: string;
    subTaskTypeName: string;
    assignedUserId: string;
    description: string;
    startDateTime: Date;
    dueDateTime: Date;
    isUrgent: boolean;
    isComplete: boolean;
    assignedUserName: string;
}

export class TaskModelValidator extends Validator<TaskModel> {
    constructor() {
        super();
    }
}
