export enum Months {
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12,
}

export namespace MonthHelper {
    export const getText = (month: Months) => {
        let retVal = "";
        switch (month) {
            case Months.January:
                retVal = "January";
                break;
            case Months.February:
                retVal = "February";
                break;
            case Months.March:
                retVal = "March";
                break;
            case Months.April:
                retVal = "April";
                break;
            case Months.May:
                retVal = "May";
                break;
            case Months.June:
                retVal = "June";
                break;
            case Months.July:
                retVal = "July";
                break;
            case Months.August:
                retVal = "August";
                break;
            case Months.September:
                retVal = "September";
                break;
            case Months.October:
                retVal = "October";
                break;
            case Months.November:
                retVal = "November";
                break;
            case Months.December:
                retVal = "December";
                break;
            case null:
                retVal = "";
                break;
            default: {
                break;
            }
        }
        return retVal;
    };
}
