import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { DropdownItem } from "Models/DropdownItem";

export class GetTsmsForAdminDropdownEndpoint extends EndpointWithoutRequest<DropdownItem[]> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Tsm.GetTsmsForAdminDropdown);
    }

    public async HandleResponseAsync(response: DropdownItem[]): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
