import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";
import { isValidNumber } from "Utils/Compare";

export class LatLongModel extends ModelBase<LatLongModel> {
    public latitude: string = "0";
    public longitude: string = "0";

    constructor() {
        super();
        makeObservable(this, {
            latitude: observable,
            longitude: observable,

            clear: action,
        });
    }

    public clear = () => {
        this.latitude = "0";
        this.longitude = "0";
    };
}

export class LatLongModelValidator extends Validator<LatLongModel> {
    constructor() {
        super();
        this.ruleFor("latitude")
            .notEmpty()
            .withMessage("Latitude is required")
            .must((value) => {
                return this.isLatitudeValid(value);
            })
            .withMessage("Latitude must be between -90 and 90");
        this.ruleFor("longitude")
            .notEmpty()
            .withMessage("Longitude is required")
            .must((value) => {
                return this.isLatitudeValid(value);
            })
            .withMessage("Longitude must be between -90 and 90");
    }

    public isLatitudeValid(value: string): boolean {
        let retVal: boolean = false;

        if (isValidNumber(value) === true) {
            if (parseFloat(value) >= -90 && parseFloat(value) <= 90) {
                retVal = true;
            }
        }
        return retVal;
    }

    public isLongitudeValid(value: string): boolean {
        let retVal: boolean = false;

        if (isValidNumber(value) === true) {
            if (parseFloat(value) >= -180 && parseFloat(value) <= 180) {
                retVal = true;
            }
        }
        return retVal;
    }
}
