import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ConditionReportDetailViewModel } from "./ConditionReportDetailViewModel";
import { useNavigate, useParams } from "react-router";
import { BaseWrapper } from "Components/BaseWrapper";
import { ConditionReportHeader, ConditionReportWrapper, ConditionReportPageWrapper, QuestionAnswerRow, QuestionAnswerCell } from "./ConditionReportView.style";
import { ConditionReportPage } from "./Models/ConditionReportPage";
import { ConditionReportSection } from "./Models/ConditionReportSection";
import { ConditionReportSectionQuestion } from "./Models/ConditionReportSectionQuestion";
import { SectionImage } from "./Models/SectionImage";
import { SectionAnswer } from "./Models/SectionAnswer";
import { SectionRow } from "./Models/SectionRow";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { ICommand } from "Application/Commands/ICommand";
import { RelayCommand } from "Application";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { generateID } from "@shoothill/core";

export const ConditionReportDetailView: React.FC = observer(() => {
    const navigate = useNavigate();
    const [viewModel] = useState(() => new ConditionReportDetailViewModel());

    let params = useParams();

    let conditionReportId: string = params.reportId ?? "new";

    useEffect(() => {
        viewModel.loadConditionReportForIdAsync(conditionReportId);

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const showQuestionsImages = (section: ConditionReportSection) => {
        const questions: ConditionReportSectionQuestion[] | undefined = section.questions.filter((a) => a.controlTypeId !== 5 && a.hasAnswersOrImages === true); // 5 = label
        return (
            <>
                {questions !== undefined && (
                    <>
                        {questions.map((question: ConditionReportSectionQuestion) => (
                            <>
                                {question.images.length === 0 && question.answers.length > 0}
                                {
                                    <QuestionAnswerRow key={generateID()}>
                                        <QuestionAnswerCell className="question" key={generateID()}>
                                            <div>{question.question}</div>
                                            {question.commentQuestionText !== null &&
                                                question.commentQuestionText !== undefined &&
                                                question.commentQuestionText.length > 0 &&
                                                question.comment !== null &&
                                                question.comment !== undefined &&
                                                question.comment.length > 0 && (
                                                    <div className="comment">
                                                        <div className="arrow"> &#x21AA;</div>
                                                        <div>{question.commentQuestionText}</div>
                                                    </div>
                                                )}
                                        </QuestionAnswerCell>
                                        {question.answers.map((answer: SectionAnswer, index: number) => (
                                            <QuestionAnswerCell key={generateID()} className="answer">
                                                <div>{answer.answer}</div>
                                                {question.comment !== null && question.comment !== undefined && question.comment.length > 0 && index === 0 && (
                                                    <div className="comment">{question.comment}</div>
                                                )}
                                            </QuestionAnswerCell>
                                        ))}
                                    </QuestionAnswerRow>
                                }

                                {question.images.length > 0 && (
                                    <>
                                        {question.images.map((image: SectionImage) => (
                                            <img className="imgpreview" key={generateID()} src={image.imageURL} alt={image.fileName} />
                                        ))}
                                    </>
                                )}
                            </>
                        ))}
                    </>
                )}
            </>
        );
    };

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Back) {
            navigate(AppUrls.Client.ConditionReport.List[AppUrlIndex.Long]);
        }
    });

    return (
        <>
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText="Back to condition reports" />
            <BaseWrapper>
                <ConditionReportWrapper>
                    {viewModel.getHasConditionReport === true && (
                        <div>
                            {viewModel.model.pages.length > 0 && (
                                <>
                                    {viewModel.model.getViewPages.map((page: ConditionReportPage) => (
                                        <>
                                            <ConditionReportHeader key={generateID()}>{page.name}</ConditionReportHeader>
                                            <ConditionReportPageWrapper key={generateID()}>
                                                {page.sections.map((section: ConditionReportSection) => (
                                                    <>
                                                        {section.questions.slice().filter((a) => a.hasAnswersOrImages === true).length > 0 && (
                                                            <>
                                                                <ConditionReportHeader key={generateID()}>{section.name}</ConditionReportHeader>
                                                                {showQuestionsImages(section)}
                                                            </>
                                                        )}
                                                        {section.rows
                                                            .slice()
                                                            .filter((a) => a.hasPages === true)
                                                            .map((row: SectionRow) => (
                                                                <>
                                                                    {row.pages.map((subpage: ConditionReportPage) => (
                                                                        <>
                                                                            {subpage.sections.map((subsection: ConditionReportSection) => (
                                                                                <>
                                                                                    {section.questions.length > 0 && (
                                                                                        <>
                                                                                            <ConditionReportHeader key={generateID()}>{subsection.name}</ConditionReportHeader>
                                                                                        </>
                                                                                    )}
                                                                                    {showQuestionsImages(subsection)}
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    ))}
                                                                </>
                                                            ))}
                                                    </>
                                                ))}
                                            </ConditionReportPageWrapper>
                                        </>
                                    ))}
                                </>
                            )}
                        </div>
                    )}
                </ConditionReportWrapper>
            </BaseWrapper>
        </>
    );
});
