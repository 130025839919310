import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class LinkedProjectQuotesModel extends ModelBase<LinkedProjectQuotesModel> {
    public customerId: Guid = "";
    constructor() {
        super();
        makeObservable(this, { customerId: observable });
    }
}

export class LinkedProjectQuotesModelValidator extends Validator<LinkedProjectQuotesModel> {
    constructor() {
        super();
    }
}
