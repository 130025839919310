import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class IGetSASTokenRequest {
    public blobName: string = "";
    public fileName?: string;
}

export class IGetSASTokenResponse {
    public fullPath: string = "";
    public sasToken: string = "";
}

export class POST_GetSASTokenEndpoint extends Endpoint<IGetSASTokenRequest, IGetSASTokenResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Document.GetSASToken);
    }

    public async HandleRequestAsync(request: IGetSASTokenRequest): Promise<any> {
        return request;
    }

    private downloadFile(fullPath: string, sasToken: string) {
        const downloadUrl = `${fullPath}?${sasToken}`;
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = ""; // Optionally set a default file namodule.exports = name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public async HandleResponseAsync(response: IGetSASTokenResponse): Promise<any> {
        return response;
    }
}
