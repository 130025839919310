import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { IComplaintRelatedResponse } from "Models/Complaints/IComplaintRelatedResponse";

export class GetComplaintRelatedForProjectQuoteEndpoint extends EndpointWithoutRequest<IComplaintRelatedResponse> {
    constructor(projectId: string) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.AfterSales.Complaints.GetRelatedForProjectQuote + `/${projectId}`);
    }

    public async HandleResponseAsync(response: IComplaintRelatedResponse): Promise<IComplaintRelatedResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
