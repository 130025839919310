import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConditionReportPdfModel } from "../Edit/ConditionReportPdfModel";

class UpdateDocumentContactIdRequest {
    public id: string = "";
    public documentContactId: number | null = null;

    public constructor() {}
}

export class UpdateDocumentContactIdEndpoint extends Endpoint<UpdateDocumentContactIdRequest, number> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportPdf.UpdateDocumentContactId);
    }

    public async HandleRequestAsync(model: ConditionReportPdfModel): Promise<any> {
        let request = new UpdateDocumentContactIdRequest();
        request.id = model.id!;
        request.documentContactId = model.documentContactId;
        return request;
    }

    public async HandleResponseAsync(response: number): Promise<number> {
        Logger.logInformation("Response", response);

        //Return your list
        return response;
    }
}
