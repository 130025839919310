import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { DocumentViewerModel } from "./DocumentViewerModel";
import { IGetSASTokenRequest, IGetSASTokenResponse, POST_GetSASTokenEndpoint } from "Endpoint/POST_GetSASTokenEndpoint";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";

export class DocumentViewerViewModel extends ViewModelBase<DocumentViewerModel> {
    public apiClient = new APIClient();
    public errorStore = container.resolve(ErrorStore);
    //region observables
    public activeDocViewer: "excel" | "docx" | "default" | null = null;
    public isProcessing: boolean = false;
    constructor() {
        super(new DocumentViewerModel());

        makeObservable(this, {
            isProcessing: observable,
            clear: action,
            setIsProcessing: action,
            showLoading: computed,
        });
    }

    //region endregion
    public initialize = async (documentToView: IDocumentUploaded) => {
        if (this.apiClient.IsBusy) return;
        await this.get(documentToView);
        this.initializeDocActiveViewer();
    };

    public get = async (documentToView: IDocumentUploaded) => {
        if (this.apiClient.IsBusy) return;
        runInAction(async () => {
            this.setIsProcessing(true);
            const request: IGetSASTokenRequest = {
                blobName: documentToView.blobName,
                fileName: documentToView.fileName,
            };
            await this.apiClient.sendAsync(new POST_GetSASTokenEndpoint(), request);
            if (this.apiClient.IsRequestSuccessful) {
                const response: IGetSASTokenResponse = this.apiClient.Response();
                //download the file using the SAS URL
                const fileResponse = await fetch(response.fullPath);
                const blob = await fileResponse.blob();
                const file = new File([blob], documentToView.fileName, { type: blob.type });
                this.model.file = file;
            } else {
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load file.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
            this.setIsProcessing(false);
        });
    };

    //region properties

    public get canShowDocViewer() {
        return this.model.file != null;
    }

    public get showLoading(): boolean {
        return this.isProcessing;
    }

    //region actions

    public setIsProcessing = (isProcessing: boolean) => (this.isProcessing = isProcessing);

    public clear = () => {
        this.model.clear();
    };
    public setActiveDocViewer = (activeDocViewer: "excel" | "docx" | "default" | null) => {
        this.activeDocViewer = activeDocViewer;
    };

    public initializeDocActiveViewer = () => {
        if (!this.canShowDocViewer) return;

        const fileType = this.model.file!.type;
        const extension = this.model.file!.name.split(".").pop()?.toLowerCase();

        if (!fileType || !extension) return;

        const isExcelFile = fileType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") || ["xlsx", "xls"].includes(extension);

        const isWordFile = fileType.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") || ["docx", "odt"].includes(extension);

        if (isExcelFile) {
            this.setActiveDocViewer("excel");
        } else if (isWordFile) {
            this.setActiveDocViewer("docx");
        } else {
            this.setActiveDocViewer("default");
        }
    };
}
