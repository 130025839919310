import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class SageNotFoundResetEndpoint extends EndpointWithoutRequest<number> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Reports.SageNotFoundReset);
    }

    public async HandleResponseAsync(response: number): Promise<number> {
        //Return your model
        return response;
    }
}
