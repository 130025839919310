import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { IDocumentType } from "Models/Documents/DocumentType";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { TSMProjectDetails } from "../../ModalCommon/TSMModalCommon";

export class GetTsmDocumentsListAndRelatedResponse {
    public tsmDocumentList: IDocumentUploaded[] = [];
    public documentTypes: IDocumentType[] = [];
    public projectDetails: TSMProjectDetails = new TSMProjectDetails();
}

export class GetTsmDocumentsListAndRelatedEndpoint extends EndpointWithoutRequest<GetTsmDocumentsListAndRelatedResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Tsm.GetDocumentsListById.replace(":id", String(id)));
    }

    public async HandleResponseAsync(response: GetTsmDocumentsListAndRelatedResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
