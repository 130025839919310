import React, { useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ThemedLoader } from "Application";
import { ComplaintsListRoutingViewModel } from "./ComplaintsListRoutingViewModel";
import { Navigate, Route, Routes } from "react-router";
import { TabsWrapper } from "Components/TabsWrapper";
import { NavLink } from "react-router-dom";
import { ComplaintsListView } from "./List/ComplaintsListView";
import { ComplaintsReportView } from "./Report/ComplaintsReportView";
import { H5 } from "Components/Primitives/TextElements/TextElements";

export const ComplaintsListRoutingView: React.FC = observer(() => {
    const [viewModel] = useState(new ComplaintsListRoutingViewModel());

    return (
        <>
            {viewModel.isProcessing ? (
                <ThemedLoader isOpen={true} spinnerText="Searching..." delayBeforeOpen={200} />
            ) : (
                <>
                    <TabsWrapper>
                        <NavLink to={"complaints"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                            <H5>Complaints</H5>
                        </NavLink>
                        <NavLink to={"reports"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                            <H5>Reports </H5>
                        </NavLink>
                    </TabsWrapper>
                    <Routes>
                        <Route path="*" element={<Navigate to="complaints" replace />} />
                        <Route path={"complaints"} element={<ComplaintsListView />} />
                        <Route path={"reports"} element={<ComplaintsReportView />} />
                    </Routes>
                </>
            )}
        </>
    );
});
