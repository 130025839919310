import { ICoreOptions, LogLevel } from "@shoothill/core";

export interface IConfig {
    isDevMode: boolean;
    rootPageTitle: string;
    themeProvider: "materialui" | "emotion" | "fluentui";
    themeVersion: "StyleSheet1" | "StyleSheet2" | "StyleSheet3";
    logLevel: LogLevel;
    coreOptions: ICoreOptions;
}

export const config: IConfig = {
    isDevMode: process.env.NODE_ENV === "development",
    logLevel: process.env.NODE_ENV === "development" ? LogLevel.Debug : LogLevel.Information,
    rootPageTitle: "Sharmans",
    themeProvider: "fluentui",
    themeVersion: "StyleSheet1",
    coreOptions: {
        useLocalStorage: true,
        useSessionStorage: false,
        useAsyncStorage: false,
        logLevel: process.env.NODE_ENV === "development" ? LogLevel.Debug : LogLevel.Information,
    } as ICoreOptions,
};
