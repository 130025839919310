import styled from "@emotion/styled";
import { Box, theme } from "Application";

export const Main = styled.div`
    height: 40px;
    background-color: #ededed;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SocialsGrid = styled(Box)`
    @media (max-width: 767px) {
        justify-items: center;
    }
`;

export const ExternalLink = styled.a`
    color: ${theme.palette.secondary.main};
    font-size: 1em;
`;

export const LogoBox = styled(Box)`
    display: flex;
    align-items: center;
`;

export const ChangeLogTitle = styled.a`
    cursor: default;
    :hover {
        cursor: pointer;
    }
`;
