import React from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react-lite";
import { Button, ICommand, RelayCommand } from "Application";
import { Modal } from "@fluentui/react";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ButtonBox, ErrorModalBody, ErrorModalHeader, ErrorModalWrapper, HeaderBox } from "./ErrorModal.styles";
import { P } from "Components/Primitives/TextElements/TextElements";
import ErrorIcon from "Assets/Icons/warning_icon_1.svg";
import CloseIcon from "Assets/close_icon.svg";

export const ErrorModal: React.FC = observer(() => {
    const errorStore = container.resolve(ErrorStore);
    const navigate = useNavigate();

    const handleButtonClick: ICommand = new RelayCommand(() => {
        if (errorStore.redirectURLOnClose != "") {
            errorStore.setErrorModalOpen(false);
            navigate(errorStore.redirectURLOnClose);
        } else {
            errorStore.setErrorModalOpen(false);
        }
    });

    return (
        <>
            <Modal isOpen={errorStore.isErrorModalOpen}>
                <ErrorModalWrapper>
                    <ErrorModalHeader>
                        <HeaderBox>
                            <img src={ErrorIcon} height="20px" />
                            <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                {errorStore.headerText}
                            </P>
                        </HeaderBox>
                        <HeaderBox style={{ cursor: "pointer" }} onClick={() => errorStore.setErrorModalOpen(false)}>
                            <img src={CloseIcon} />
                        </HeaderBox>
                    </ErrorModalHeader>

                    <ErrorModalBody>
                        <P>{errorStore.errorMessageOne}</P>
                        {errorStore.errorMessageOne != "" && <P p="15px 0px">{errorStore.errorMessageTwo}</P>}
                        <ButtonBox pt="30px">
                            <Button command={handleButtonClick} displayName={errorStore.buttonText} boxShadow={true} />
                        </ButtonBox>
                    </ErrorModalBody>
                </ErrorModalWrapper>
            </Modal>
        </>
    );
});
