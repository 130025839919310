import { DateTime } from "luxon";
import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ComplaintsModel extends ModelBase<ComplaintsModel> {
    //public id: string = "";

    constructor() {
        super();
        makeObservable(this, {});
    }

    public clear = () => {};
}

export class ComplaintsModelValidator extends Validator<ComplaintsModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
