import { ComplaintModel } from "./ComplaintModel";
import { IComplaintRelatedResponse } from "./IComplaintRelatedResponse";

export interface IComplaintWithRelatedResponse extends IComplaintRelatedResponse {
    complaint: ComplaintModel;
}

export const getBlankComplaintWithRelatedResponse = (): IComplaintWithRelatedResponse => {
    return {
        complaint: new ComplaintModel(),
        reportingCompanies: [],
        installationCompanies: [],
        complaintAssignees: [],
        complaintNatures: [],
        complaintCategories: [],
        projectDetails: {
            projectId: "",
            siteAddress: "",
            orderAmount: 0,
        },
    };
};
