import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { AppUrls } from "AppUrls";
import { DropdownItem } from "Models/DropdownItem";

export interface ProductType {
    id: number;
    displayName: string;
    parentId: number;
    ordinal: number;
}

export class ReportListAndRelatedResponse {
    productTypeList: ProductType[] = [];
    customerTypesList: CustomerTypeDropdownItem[] = [];
    bdmList: DropdownItem[] = [];
    taskTypeList: DropdownItem[] = [];
    count: number = 0;
}

export class GetUnmatchedSageRecordCountEndpoint extends EndpointWithoutRequest<ReportListAndRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Reports.GetSageNotFoundCount);
    }

    public async HandleResponseAsync(response: ReportListAndRelatedResponse): Promise<any> {
        //Return your model
        return response;
    }
}
