import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { LinkedProjectQuotesModel, LinkedProjectQuotesModelValidator } from "./LinkedProjectQuotesModel";
import { GetLinkedProjectQuotesProjectsEndpoint, LinkedProjectQuotesResponse } from "./Endpoints/GetLinkedProjectQuotesProjectsEndpoint";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { IParentChildDropdownItem } from "Models/IParentChildDropdownItem";
import { ProjectStatusEnumHelper } from "Models/Project/ProjectStatusEnum";
import { LinkedProjectQuoteListItem } from "./LinkedProjectQuoteListItem";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { DefaultPageSize } from "Globals/GlobalSettings";

export class LinkedProjectQuotesViewModel extends ViewModelBase<LinkedProjectQuotesModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;
    public initialLoad: boolean = true;

    public projectProducts: IObservableArray<IParentChildDropdownItem> = observable.array([]);

    public statusDropdown: IObservableArray<KeyValuePairExtended> = observable(ProjectStatusEnumHelper.getAllProjectStatusOptions());
    public statusFilter: string[] = ProjectStatusEnumHelper.getAllProjectStatuses();
    public projectQuotes: IObservableArray<LinkedProjectQuoteListItem> = observable.array([]);

    public productFilter: string[] = [];

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.DESC;
    public columnName: string = "createdDate";

    // Pagination
    public customerCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;
    public totalCount: number = 0;

    private projectQuoteCountCallBack: (newCount: number) => void;

    constructor(customerId: string | undefined, projectQuoteCountCallBack: (newCount: number) => void) {
        super(new LinkedProjectQuotesModel());
        this.projectQuoteCountCallBack = projectQuoteCountCallBack;
        this.model.customerId = customerId!;
        this.setValidator(new LinkedProjectQuotesModelValidator());
        this.productFilter = this.getAllProductFiltered();
        makeObservable(this, {
            isProcessing: observable,
            statusFilter: observable,
            productFilter: observable,
            customerCount: observable,
            pageSize: observable,
            pageNumber: observable,
            totalCount: observable,

            clear: action,
            updateProductFiltering: action,
            updateStatusFiltering: action,
            statusSelectAll: action,
            statusSelectNone: action,
            productSelectAll: action,
            productSelectNone: action,

            getProductTypesForDropdown: computed,
            getProductFilter: computed,
            getStatusFilter: computed,
            getProjects: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.customerCount = 0;
        this.pageSize = 10;
        this.pageNumber = 1;
        this.totalCount = 0;
        this.projectProducts.clear();
    };

    // Status

    public updateStatusFiltering = (values: string[]) => {
        this.statusFilter = values;
    };

    public statusSelectNone = () => {
        this.statusFilter = [];
    };

    public statusSelectAll = () => {
        this.statusFilter = ProjectStatusEnumHelper.getAllProjectStatuses();
    };

    public get getStatuses(): KeyValuePairExtended[] {
        return this.statusDropdown;
    }

    public get getStatusFilter(): string[] {
        return this.statusFilter;
    }

    // End Status

    //Category

    private getAllProductFiltered = () => {
        return this.projectProducts.slice().map((a) => a.id.toString());
    };

    public updateProductFiltering = (values: string[]) => {
        this.productFilter = values;
    };

    public productSelectAll = () => {
        this.productFilter = this.getAllProductFiltered();
    };

    public productSelectNone = () => {
        this.productFilter = [];
    };

    public get getProductFilter(): string[] {
        return this.productFilter;
    }

    public get getProductTypesForDropdown() {
        let retVal: KeyValuePairExtended[] = [];

        let newArray = this.projectProducts.map((item) => {
            let classItem: string = "parent";

            if (item.parentId !== null && item.parentId !== undefined) {
                classItem = "child";
            }

            return { key: item.id.toString(), text: item.displayName, class: classItem };
        });

        retVal = [...retVal, ...newArray];

        return retVal;
    }

    //end Category

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    // data

    public get getProjects() {
        const filterByStatus = (project: LinkedProjectQuoteListItem): boolean => {
            if (this.statusFilter.slice().length === this.statusDropdown.length) {
                return true;
            }

            let retVal = this.statusFilter.slice().indexOf(project.overallStatus.toString()) > -1;
            return retVal;
        };

        const filterByProduct = (project: LinkedProjectQuoteListItem): boolean => {
            if (this.productFilter.slice().length === this.projectProducts.length) {
                return true;
            }

            let retVal = this.productFilter.slice().indexOf(project.projectProductId.toString()) > -1;
            return retVal;
        };

        return this.projectQuotes
            .slice()
            .filter((a) => filterByProduct(a))
            .filter((a) => filterByStatus(a));
    }

    // data

    public getLinkedProjectQuotes = async (): Promise<LinkedProjectQuotesResponse> => {
        const endpoint = new GetLinkedProjectQuotesProjectsEndpoint();
        let retVal: LinkedProjectQuotesResponse = new LinkedProjectQuotesResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: LinkedProjectQuotesResponse = this.apiClient.Response();
                    this.projectProducts.replace(response.projectProduct);
                    this.projectQuotes.replace(response.customerProjectQuoteListItem);
                    this.projectQuoteCountCallBack(response.customerProjectQuoteListItem.length);
                    this.customerCount = response.customerCount;
                    this.pageCount = Math.ceil(this.customerCount / this.pageSize);
                    if (this.initialLoad === true) {
                        this.productSelectAll();
                        this.initialLoad = false;
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View Linked project quotes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the Linked project quotes.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public clearFiltering: ICommand = new RelayCommand(() => {
        this.initialLoad = true;
        this.productFilter = this.getAllProductFiltered();
        this.statusSelectAll();
        this.getLinkedProjectQuotes();
    });

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.getLinkedProjectQuotes();
    };
}
