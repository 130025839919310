//libraries
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Callout } from "@fluentui/react";
//App
import { Box, ICommand, Label, NullCommand } from "Application";
import { InfoRoundIcon } from "Assets/Icons/InfoRoundIcon";
import { CloseIcon } from "Assets/Icons/CloseIcon";

import { P } from "Components/Primitives/TextElements/TextElements";
import { HRDashedGrey } from "Components/HorizontalRules";
import { formatDateTime } from "Utils/Formats";
import { ContactInfoModalViewModel } from "Components/ReusableModals/ContactInfoModal/ContactInfoModalViewModel";
import {
    CalloutBody,
    CalloutFooter,
    CalloutHeader,
    CalloutInnerContainer,
    CalloutMainContent,
    CalloutSubHeader,
    CalloutSubHeaderLabelGroup,
    CreatedDateLabelGroup,
    TransparentButton,
} from "Views/Work/Common/CallOuts/Callout.style";
import { BooleanTickOrCrossCell, CustomerStatusButtonCell, EmailWithIconCell, PhoneNumberWithIconCell } from "Utils/CellFormatComponents";
import { CustomerNoteCalloutItem } from "Models/Customer/CustomerNoteCalloutItem";
import { CustomerNoteAuditContainer, LinkedProjectItemGroup } from "Views/Work/Common/CallOuts/MasterProject/Callout.style";
import styled from "@emotion/styled";
import { CustomerStatusBox } from "Views/Directory/Customers/Common/CustomerStatusSelect";

export const CustomerNoteItem = styled.div`
    margin-bottom: 6px;
`;

export interface IContactInfoModalViewCalloutProps {
    onDismissCommand: ICommand<Event | React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined>;
    targetId: string;
    sourceId: string;
}

export interface ICalloutStateProps {
    isCalloutVisible: boolean;
    targetId: string;
}

export const ContactInfoModalView: React.FC<IContactInfoModalViewCalloutProps> = observer((props: IContactInfoModalViewCalloutProps) => {
    const { onDismissCommand, sourceId, targetId } = props;
    const [viewModel] = useState(new ContactInfoModalViewModel());

    useEffect(() => {
        viewModel.getOccupierByIdAsync(sourceId);
    }, []);

    const onDismiss = () => {
        if (onDismissCommand.canExecute()) {
            onDismissCommand.execute();
        }
    };

    const renderNotes = () => {
        const items = viewModel.model.customerNoteCalloutItems;

        if (items.length === 0) {
            return <Label>There are currently no notes for this Occupier</Label>;
        }

        return items.map((i: CustomerNoteCalloutItem) => (
            <CustomerNoteItem key={i.KEY}>
                <Label>{i.note}</Label>
                <CustomerNoteAuditContainer>
                    <Label>{i.createdByUserFullName}</Label>
                    <Label>{i.lastUserActionFormattedDate}</Label>
                </CustomerNoteAuditContainer>
            </CustomerNoteItem>
        ));
    };

    const renderLinkedProjects = () => {
        const items = viewModel.model.projectCalloutItems;

        if (items.length === 0) {
            return <Label>There are currently no linked projects for this property</Label>;
        }

        return items.map((i: any) => (
            <LinkedProjectItemGroup key={i.KEY}>
                <Label fontStyle="h5">{i.number}</Label>
                <Label>{i.projectName}</Label>
            </LinkedProjectItemGroup>
        ));
    };

    return (
        <Callout onDismiss={onDismiss} target={`#${targetId}`} directionalHint={12}>
            <CalloutInnerContainer>
                <CalloutHeader>
                    <Box>
                        <InfoRoundIcon borderColor={"#fff"} contentColor={"#fff"} backgroundColor="#fff" />
                    </Box>
                    <Box>
                        <Label color="white" fontStyle="h5">
                            {viewModel.getValue("displayName")}
                        </Label>
                        <Label color="white">{viewModel.getValue("addressFull")}</Label>
                    </Box>
                    <TransparentButton onClick={onDismiss}>
                        <CloseIcon color="#fff" size={15} />
                    </TransparentButton>
                </CalloutHeader>
                <CalloutBody>
                    <CalloutSubHeader>
                        <CalloutSubHeaderLabelGroup>
                            <Label fontStyle="h5">Status:</Label>
                            <CustomerStatusBox className={viewModel.customerStatusClass} style={{ width: "78px" }}>
                                <div className="option-label">{viewModel.customerStatusName}</div>
                            </CustomerStatusBox>
                        </CalloutSubHeaderLabelGroup>
                        <CalloutSubHeaderLabelGroup>
                            <Label fontStyle="h5">Phone:</Label>
                            <PhoneNumberWithIconCell value={viewModel.getValue("phone")} />
                        </CalloutSubHeaderLabelGroup>
                        <CalloutSubHeaderLabelGroup>
                            <Label fontStyle="h5">Phone Additional:</Label>
                            <PhoneNumberWithIconCell value={viewModel.getValue("phone2")} />
                        </CalloutSubHeaderLabelGroup>
                        <CalloutSubHeaderLabelGroup>
                            <Label fontStyle="h5">Email: </Label>
                            <EmailWithIconCell value={viewModel.getValue("email")} />
                        </CalloutSubHeaderLabelGroup>
                    </CalloutSubHeader>
                    <CalloutMainContent>
                        <CalloutSubHeader>
                            <CalloutSubHeaderLabelGroup>
                                <Label fontStyle="h5">Plygene Trained: </Label>
                                <BooleanTickOrCrossCell value={viewModel.getValue("plygeneTrained")} alignLeft={true} />
                            </CalloutSubHeaderLabelGroup>
                            <CalloutSubHeaderLabelGroup>
                                <Label fontStyle="h5">Coating Trained:</Label>
                                <BooleanTickOrCrossCell value={viewModel.getValue("coatingsTrained")} alignLeft={true} />
                            </CalloutSubHeaderLabelGroup>
                            <CalloutSubHeaderLabelGroup>
                                <Label fontStyle="h5">Plygene CPD: </Label>
                                <BooleanTickOrCrossCell value={viewModel.getValue("coatingsCPD")} alignLeft={true} />
                            </CalloutSubHeaderLabelGroup>
                            <CalloutSubHeaderLabelGroup>
                                <Label fontStyle="h5">Coatings CPD:</Label>
                                <BooleanTickOrCrossCell value={viewModel.getValue("plygeneCPD")} alignLeft={true} />
                            </CalloutSubHeaderLabelGroup>
                        </CalloutSubHeader>
                        <Box style={{ alignItems: "center" }}>
                            <Box flexBox style={{ alignItems: "center" }}>
                                <P pr="10px">
                                    <strong style={{ display: "block", marginBottom: "10px" }}>Occupier notes </strong>
                                    {renderNotes()}
                                </P>
                            </Box>
                        </Box>
                        <HRDashedGrey />
                        <Box flexBox style={{ alignItems: "center" }}>
                            <P pr="10px">
                                <strong style={{ display: "block", marginBottom: "10px" }}>Linked projects </strong>
                                {renderLinkedProjects()}
                            </P>
                        </Box>
                    </CalloutMainContent>
                </CalloutBody>
                <CalloutFooter>
                    <CreatedDateLabelGroup>
                        <Label fontStyle="h5">Created:</Label>
                        <Label>{viewModel.getValue("createdDate") !== null ? formatDateTime(new Date(viewModel.getValue("createdDate"))) : "-"}</Label>
                    </CreatedDateLabelGroup>
                </CalloutFooter>
            </CalloutInnerContainer>
        </Callout>
    );
});
