import styled from "@emotion/styled";
import { Box } from "Application";

export const MapPlaceholder = styled(Box)`
    height: 433px;
    width: 100%;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
`;
