import { Box, Button } from "Application";
import styled from "@emotion/styled";
import { P } from "Components/Primitives/TextElements/TextElements";

export const GridWrapper = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 30px;
    row-gap: 12px;
`;

export const RoundedInputStyle = {
    borderRadius: "0.125rem 10px 10px 0.125rem",
};

export const CenteredTextBox = styled(Box)`
    display: flex;
    align-items: end;
    padding-bottom: 14px;
`;

export const PAddress = styled(P)`
    font-weight: 300;
`;

export const ButtonBox = styled(Box)`
    position: absolute;
    top: 0;
    right: 0;
`;

export const AddSectionsBox = styled.div`
    position: relative;
    padding-bottom: 12px;
`;

export const NoteBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    opacity: 1;
    padding: 5px;
    margin-bottom: 5px;

    .documentType {
        font-weight: 700;
        text-transform: uppercase;
    }
`;

export const NoteBoxSecondary = styled(Box)`
    background: #fcfcfc 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    opacity: 1;

    margin-bottom: 5px;
`;

export const DocumentToUploadBox = styled(Box)`
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    opacity: 1;
    padding: 5px;
    margin-bottom: 5px;

    .editselect {
        min-width: 200px;
    }
`;

export const UploadedDocumentsBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    background: #fcfcfc 0% 0% no-repeat padding-box;
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    opacity: 1;
    padding: 5px;
    margin-bottom: 5px;

    .editselect {
        min-width: 200px;
    }
`;

export const PStyledDocuments = styled(P)`
    text-align: left;
    font: normal normal bold 10px/12px Inter;
    letter-spacing: -0.15px;
    color: #333333;
    opacity: 1;
`;

export const DeleteButton = styled(Button)`
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 4px;
    min-height: 0px;
`;

export const UploadImageBox = styled(Box)`
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 0.125rem;
    border: dashed 1px black;
    padding: 20px;
    margin: 10px 0px 10px 0px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    cursor: pointer;
`;

export const CreateCustomFooter = styled(Box)`
    min-height: 58px;
    background-color: white;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 10px;
    box-shadow: 3px 0px 3px #00000029;
`;
