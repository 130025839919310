import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { CustomerListItemModel } from "./CustomerListItemModel";

export class CustomersListModel extends ModelBase<CustomersListModel> {
    // public id: string = "";
    public Customers: IObservableArray<CustomerListItemModel> = observable([]);

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
        });
    }
}

export class CustomersListModelValidator extends Validator<CustomersListModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
