import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { TaskListItem, TaskModel, TaskModelValidator } from "./TaskModel";
import { GetTaskListEndpoint } from "./Endpoints/GetTaskListEndpoint";
import { GetTaskDetailsByIdEndpoint } from "./Endpoints/GetTaskDetailsByIdEndpoint";
import { MasterProjectTaskUpsertEndpoint } from "Views/Work/Master/View/Task/Endpoints/MasterProjectTaskUpsert";
import { SharmanTaskTypeEnum } from "Models/Tasks/TaskTypeEnum";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";

export class TaskViewModel extends ViewModelBase<TaskModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public customerId: string = "";
    public filterByCompleted: string[] = [];

    public isProcessing: boolean = false;
    public showViewTaskModal: boolean = false;

    public tasksList: IObservableArray<TaskListItem> = observable([]);

    private taskCountCallBack: (newCount: number) => void;

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "displayName";

    // Pagination
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;
    public totalCount: number = 0;

    constructor(customerId: string, taskCountCallBack: (newCount: number) => void) {
        super(new TaskModel());
        this.customerId = customerId;
        this.taskCountCallBack = taskCountCallBack;
        this.setValidator(new TaskModelValidator());
        this.loadNoCompletedSelected();
        makeObservable(this, {
            pageSize: observable,
            pageNumber: observable,
            totalCount: observable,
            isProcessing: observable,
            showViewTaskModal: observable,
            customerId: observable,
            filterByCompleted: observable,
            openViewTasksModal: action,
            getTaskDetailsModalById: action,
            updateCompletedFiltering: action,
            completedSelectAll: action,
            completedSelectNone: action,
            getCompletedFilter: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.showViewTaskModal = false;
        this.customerId = "";
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.pageSize = 10;
        this.pageNumber = 1;
        this.totalCount = 0;
    };

    //completed

    public get getCompletedFilter(): string[] {
        return this.filterByCompleted.slice();
    }

    public get getCompletedDropdown() {
        let retVal: KeyValuePair[] = [
            { key: true, text: "Completed" },
            { key: false, text: "Not Completed" },
        ];

        return retVal;
    }

    public updateCompletedFiltering = (values: string[]) => {
        this.filterByCompleted = values;
    };

    public completedSelectAll = () => {
        this.filterByCompleted = this.getCompletedDropdown.map((item) => {
            return item.key;
        });
    };

    public completedSelectNone = () => {
        this.filterByCompleted = [];
    };

    public loadNoCompletedSelected = () => {
        const noCompletedSelected: string[] = this.getCompletedDropdown.filter((item) => item.text === "Not Completed").map((item) => item.key);
        this.filterByCompleted = noCompletedSelected;
    };

    // End completed

    public openViewTasksModal = (Id: any) => {
        this.model.clear();
        this.getTaskDetailsModalById(Id!);
        this.showViewTaskModal = true;
    };

    public closeViewTaskModal: ICommand = new RelayCommand(() => {
        this.showViewTaskModal = false;
    });

    public updateIsCompleteCommand: ICommand = new RelayCommand((value: boolean) => {
        this.model.isComplete = value;
    });

    public addEditCommand: ICommand = new RelayCommand(() => {
        this.upsertAsync();
    });

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public upsertAsync = async (): Promise<any> => {
        let retVal: TaskModel = this.model;

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: MasterProjectTaskUpsertEndpoint = new MasterProjectTaskUpsertEndpoint();
                this.isProcessing = true;
                this.setValue("sourceId", this.customerId);
                this.setValue("taskTypeId", SharmanTaskTypeEnum.Customer);
                let _ = await this.apiClient.sendAsync(endpoint, this.model);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: TaskModel = this.apiClient.Response();
                        this.model.fromResponse(response);
                        this.showViewTaskModal = false;
                        this.getTaskListAsync();
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Task");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to save task.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };

    public async getTaskDetailsModalById(Id: Guid): Promise<void> {
        this.isProcessing = true;
        if (Id !== null) {
            const endpoint = new GetTaskDetailsByIdEndpoint(Id);
            await this.apiClient.sendAsync(endpoint);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.fromResponse(response.sharmanTask);
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Task");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load task.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public async getTaskListAsync(): Promise<void> {
        if (this.apiClient.IsBusy === false && this.isProcessing === false) {
            this.isProcessing = true;
            const endpoint = new GetTaskListEndpoint();
            let _ = await this.apiClient.sendAsync(endpoint, this);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response<TaskListItem[]>();
                    this.taskCountCallBack(response.taskList.length);
                    this.tasksList.replace(response.taskList);
                    this.isProcessing = false;
                    this.totalCount = response.taskCount;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Tasks");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load the Tasks.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.getTaskListAsync();
    };
}
