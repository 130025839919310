import { ChangeLog as ChangeLogData } from "Changelog/Changelog";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { ChangelogBody, ChangelogButton, ChangelogFooter, ChangelogHeader, ChangelogHeaderCloseX, ChangelogHeaderTitle, ChangelogPaper } from "./ChangelogModal.styles";
import { ICommand } from "Application";
export interface IChangeLogProps {
    onClose: ICommand;
}

export const ChangeLogView: React.FunctionComponent<IChangeLogProps> = observer((props) => {
    const { versions } = ChangeLogData;
    const validVersions = versions.filter((version) => version.items && version.items.length > 0);

    const formatDate = (date: string) => {
        if (date == null || date.length === 0) {
            return "Pre-Release";
        }
        return date;
    };

    return (
        <ChangelogPaper>
            <ChangelogHeader>
                <ChangelogHeaderTitle>
                    <h4>Change Log</h4>
                </ChangelogHeaderTitle>
                <ChangelogHeaderCloseX
                    onClick={() => {
                        props.onClose.execute();
                    }}
                >
                    X
                </ChangelogHeaderCloseX>
            </ChangelogHeader>
            <ChangelogBody>
                {validVersions.map((version) => {
                    return (
                        <div key={`${version.version}`} className="modal-changelog-body-version">
                            <h4>
                                v{version.version} (<small>{formatDate(version.date)}</small>)
                            </h4>
                            <ul>
                                {version.items.map((item, index) => {
                                    return <li key={`${version.version}_${index}`}>{item.description}</li>;
                                })}
                            </ul>
                        </div>
                    );
                })}
            </ChangelogBody>
            <ChangelogFooter>
                <ChangelogButton command={props.onClose} displayName={"Close"} />
            </ChangelogFooter>
        </ChangelogPaper>
    );
});
