import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router";
import { FallbackPageView } from "Components/FallbackPage";
import { TasksListView } from "./TasksListView";

export const TasksRoutes: React.FC = observer(() => {
    return (
        <>
            <Routes>
                <Route path="*" element={<Navigate to="list" replace />} />
                <Route
                    path={"list/*"}
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <TasksListView />
                        </React.Suspense>
                    }
                />
            </Routes>
        </>
    );
});
