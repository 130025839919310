import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PropertyMultiSiteProjectListItem } from "../Models/PropertyMultiSiteProjectListItem";

export class PropertyLinkedMultiSiteModel extends ModelBase<PropertyLinkedMultiSiteModel> {
    public id: string = "";
    public propertyId: Guid = "";
    public linkedMultiSiteList: IObservableArray<PropertyMultiSiteProjectListItem> = observable([]);
    constructor() {
        super();
        makeObservable(this, { propertyId: observable });
    }
}

export class PropertyLinkedMultiSiteModelValidator extends Validator<PropertyLinkedMultiSiteModel> {
    constructor() {
        super();
    }
}
