import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { ProjectProductDropDown } from "Models/Project/ProjectProductDropDown";
import { PropertyProjectQuoteListItem } from "../../Models/PropertyProjectQuoteListItem";
import { ProjectQuoteViewModel } from "../ProjectQuoteViewModel";

export class LinkedProjectQuotesRequest {
    public propertyId: Guid = "";
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public filterByProduct: string[] = [];
    public filterByStatus: string[] = [];
    public initialLoad: boolean = true;
}

export class LinkedProjectQuotesResponse {
    public projectProduct: ProjectProductDropDown[] = [];
    public projectQuoteListItem: PropertyProjectQuoteListItem[] = [];
    public propertyCount: number = 0;
}

export class GetLinkedProjectQuotesEndpoint extends Endpoint<LinkedProjectQuotesRequest, LinkedProjectQuotesResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("/api/property/getGetProjectQuoteListItems");
    }

    public async HandleRequestAsync(viewModel: ProjectQuoteViewModel): Promise<any> {
        let request = new LinkedProjectQuotesRequest();
        request.propertyId = viewModel.model.propertyId;
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageNumber = viewModel.pageNumber;
        request.pageSize = viewModel.pageSize;
        request.filterByProduct = [];
        request.filterByStatus = [];

        request.filterByProduct = viewModel.productFilter.map((item) => {
            return item;
        });

        request.filterByStatus = viewModel.statusFilter.map((item) => {
            return item;
        });

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: LinkedProjectQuotesResponse): Promise<LinkedProjectQuotesResponse> {
        return response;
    }
}
