import React, { useEffect, useRef } from "react";
import { UnsupportedBrowserView } from "../UnsupportedBrowser/UnsupportedBrowserView";
import { Footer } from "../Footer/FooterView";
import { InfoBar } from "Components/InfoBar";
import { Outlet, useLocation, useNavigate, useNavigation } from "react-router";
import { Box } from "Components";
import { DomainStore } from "Stores/Domain/DomainStore";
import { container } from "tsyringe";
import { observer } from "mobx-react-lite";
import { SideBarMenu } from "Views/Header/SideBarMenu";
import { ErrorModal } from "Components/ErrorModal/ErrorModal";

//EN: use empty generic type to get default react props
export const DefaultLayout: React.FC<{}> = observer((props) => {
    if (!props) return <>Props undefined</>;
    const domainStore = container.resolve(DomainStore);
    const { pathname } = useLocation();
    const ScrollDivRef = useRef<HTMLInputElement>(null);
    const nav = useNavigate();

    // Scroll to top of page each time route changes

    // useEffect(() => {
    //     GlobalHistory.listen((x) => console.log("HERE", x));
    // }, []);

    useEffect(() => {
        ScrollDivRef.current?.scrollTo(0, 0);
        domainStore.addToRouteHistory(pathname);
        if (domainStore.routeHistory.length > 1) {
            let arePathRootsTheSame = comparePaths(pathname, domainStore.routeHistory[domainStore.routeHistory.length - 2]);
            if (arePathRootsTheSame) {
                domainStore.popLastHistoryRoute();
            }
        }
        // console.log(domainStore.routeHistory);
    }, [pathname]);

    function comparePaths(path1: string, path2: string) {
        // Split paths by "/"
        const path1Split = path1.split("/");
        const path2Split = path2.split("/");

        // Extract the first three elements
        const path1FirstThree = path1Split.slice(0, 4);
        const path2FirstThree = path2Split.slice(0, 4);

        // Check if the first three elements are the same
        return JSON.stringify(path1FirstThree) === JSON.stringify(path2FirstThree);
    }

    return (
        <>
            {window.IE11 ? (
                <UnsupportedBrowserView />
            ) : (
                <>
                    {/* <Box style={{ left: domainStore.isSideBarClosed == true ? "0" : "125px", position: "relative", transition: "left 0.5s" }}> */}
                    <Box flexBox>
                        {/* {domainStore.isSideBarClosed == false && <SideBarMenu />} */}
                        <SideBarMenu />
                        <Box style={{ position: "fixed", left: domainStore.isSideBarClosed == true ? "0" : "125px", right: 0, transition: "left 0.5s" }}>
                            {/* <Header /> */}
                            {/*EN: Make sure you apply overflowContainer classname to all your body content*/}
                            <div className={"overflowContainer"} ref={ScrollDivRef}>
                                <InfoBar />
                                {/*{props && <Box>{props.children}</Box>}*/}
                                <Outlet />
                                <Footer />
                                <ErrorModal />
                            </div>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
});
