import "@emotion/react";
import { AccentColours, IShoothillTheme } from "./IShoothillTheme";
import chroma from "chroma-js";

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};
export const adobeXDToRem = (fontSize: number, letterSpacing: number): string => {
    return pxToRem((letterSpacing / 1000) * fontSize) + "rem";
};

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

const ThemeColours = (colour: string, text: string, scale = 0.2): AccentColours => {
    const retval = {
        lighter: chroma(colour)
            .brighten(scale * 2)
            .hex(),
        light: chroma(colour).brighten(scale).hex(),
        main: colour,
        dark: chroma(colour).darken(scale).hex(),
        darker: chroma(colour)
            .darken(scale * 2)
            .hex(),
        text: text,
    };

    return retval;
};

export const StyleSheet1: IShoothillTheme = {
    // Spacing in pixels.
    space: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],

    // Sizes. Keep numbers divisible by 2.
    sizes: {
        larger: pxToRem(40),
        large: pxToRem(30),
        medium: pxToRem(26),
        small: pxToRem(20),
    },
    dates: {
        default: "DD/MM/YYYY",
        short: "DD/MM",
        long: "MMMM Do, YYYY",
    },
    fontStyles: {
        h1: {
            fontSize: pxToRem(30),
            fontWeight: 600,
            letterSpacing: adobeXDToRem(30, -15),
            lineHeight: pxToRem(40),
        },
        h2: {
            fontSize: pxToRem(21),
            fontWeight: 700,
            letterSpacing: adobeXDToRem(24, -15),
            lineHeight: pxToRem(30),
        },
        h3: {
            fontSize: pxToRem(18),
            fontWeight: 600,
            letterSpacing: "0rem",
            lineHeight: pxToRem(18),
        },
        h4: {
            fontSize: pxToRem(14),
            fontWeight: 600,
            letterSpacing: "0rem",
            lineHeight: pxToRem(14),
        },
        h5: {
            fontSize: pxToRem(12), //10
            letterSpacing: pxToRem(-0.15),
            fontWeight: "bold",
        },
        h6: {
            fontSize: pxToRem(12), //10
            letterSpacing: "0rem",
            lineHeight: pxToRem(10),
            textTransform: "uppercase",
        },
        h7: {
            fontSize: pxToRem(12), //10
            fontWeight: 600,
            letterSpacing: "0rem",
            lineHeight: pxToRem(10),
        },
        p: {
            fontSize: pxToRem(12), //10
            letterSpacing: pxToRem(-0.15),
            lineHeight: pxToRem(14),
        },
        button: {
            fontSize: pxToRem(12), //10
            letterSpacing: pxToRem(0.3),
            textTransform: "none",
            fontWeight: 700,
        },

        error: {
            fontSize: pxToRem(14), //12
            letterSpacing: pxToRem(-0.15),
        },
        field: {
            fontSize: pxToRem(12), // 10
            letterSpacing: pxToRem(-0.18),
        },
        label: {
            fontSize: pxToRem(12), //10
            letterSpacing: pxToRem(-0.15),
            fontWeight: "bold",
        },
        labelLarge: {
            fontSize: pxToRem(14), //12
            letterSpacing: pxToRem(-0.15),
            lineHeight: pxToRem(16),
        },
        checkboxLabel: {
            fontSize: pxToRem(14), //12
            letterSpacing: pxToRem(0.36),
        },
        radioButtonLabel: {
            fontSize: pxToRem(14),
            letterSpacing: pxToRem(0.36),
        },
        headerOptionLabel: {
            fontSize: pxToRem(14), //12
            letterSpacing: pxToRem(0.6),
            textTransform: "uppercase",
        },
        calloutText: {
            fontSize: pxToRem(12), //10
        },
        tableHeader: {
            fontSize: pxToRem(14),
            fontWeight: 700,
            letterSpacing: pxToRem(-0.18),
            lineHeight: "42px",
        },
        datepickerTableHeader: {
            fontSize: pxToRem(12), //10
        },
        tableRow: {
            fontFamily: undefined,
            fontSize: pxToRem(12), //10
            fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined,
        },
        tablePaginationButton: {
            /*  fontFamily: undefined, */
            fontSize: pxToRem(14),
            /*             fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined, */
        },
        tablePaginationInput: {
            /* fontFamily: undefined, */
            fontSize: pxToRem(12), //10
            /*             fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined, */
        },
        tableGeneral: {
            /* fontFamily: undefined, */
            fontSize: pxToRem(14),
            /*             fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined, */
        },
        select: {
            fontSize: pxToRem(12), //10
            /* fontFamily: undefined,
            fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined, */
        },
        allnoneButton: {
            fontSize: pxToRem(12), //10
            /* fontFamily: undefined,
            fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined, */
        },
        changeLog: {
            fontSize: pxToRem(14),
            /* fontFamily: undefined,
            fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined, */
        },
        sidebarMain: {
            fontSize: pxToRem(12), //10
            letterSpacing: pxToRem(-0.15),
            fontWeight: "bold",
            /* fontFamily: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined */
        },
        sidebarSecondary: {
            fontSize: pxToRem(10), // 8
            /*             fontFamily: undefined,
            fontWeight: undefined,
            letterSpacing: undefined,
            lineHeight: undefined,
            textTransform: undefined */
        },
    },
    palette: {
        // Use this for your default text.
        common: {
            transparent: "transparent",
            white: "#fff",
            black: "#000",
            // FluentUI focus color. Do not edit!
            focus: "#000",
            // Off-black.
            default: "#171716",
            // Sharmans background gray
            primary: "#FCFCFC",
            // Sharmans light blue
            secondary: "#EEF4F9",
            // sharmans darker blue
            tertiary: "#337AB7",
            // Mid green.
            quarternary: "#23A73D",
            error: "#FF0000",
            success: "#23A73D",
            green: "#50AE41",
            purple: "#6679C0",
            blue: "#7CCBE1",
            darkPurple: "#25396F",
            sharmansRed: "#ce0e2d",
            sharmansBlue: "#337AB7",
            sharmansGreen: "#5bb458",
            grey: "#EDEDED",

            projectquotebackground: "#EDEDED",
            masterprojectbackground: "#d6e4f1",
            multisitebackground: "#f9eeee",
        },
        status: {
            unknown: ThemeColours("#FFFFFF", "#000"),
            open: ThemeColours("#FF9100", "#FFF"),
            complete: ThemeColours("#333333", "#FFF"),
        },
        projectStatus: {
            unknown: ThemeColours("#FFFFFF", "#000"),
            crconversion: ThemeColours("#FFB6C1", "#FFF"),
            crcheck: ThemeColours("#FAFAD2", "#000"),
            prospect: ThemeColours("#F29200", "#FFF"),
            prospectprogressed: ThemeColours("#0d8153", "#FFF"),
            didnotprogress: ThemeColours("#C99E67", "#FFF"),
            quote: ThemeColours("#94C120", "#FFF"),
            valueseparate: ThemeColours("#9C4210", "#FFF"),
            lost: ThemeColours("#F6F6F6", "#000"),
            orderplaced: ThemeColours("#086634", "#FFF"),
            complete: ThemeColours("#575556", "#FFF"),
            master: ThemeColours("#911da1", "#FFF"),
            mastercomplete: ThemeColours("#911eb4", "#FFF"),
            aftersalesopen: ThemeColours("#12A19B", "#FFF"),
            aftersalesclosed: ThemeColours("#E71873", "#FFF"),
        },

        customerStatus: {
            active: ThemeColours("#94C120", "#FFFFFF"),
            dissolved: ThemeColours("#FF9100", "#FFFFFF"),
            inactive: ThemeColours("#707070", "#FFFFFF"),
        },

        specifierType: {
            architect: "#12A19B",
            facilitiesmanager: "#E71873",
            surveyor: "#F29200",
            other: "#E71873",
            owner: "#0d8153",
        },

        // Use these for your controls - those that have a visual state - focused, hover, pressed etc.
        // Light defines the default state with main and dark being non-default states.

        // Light grey background with white text
        // disabled: { light: "#f3f2f1", main: "#f3f2f1", dark: "#f3f2f1", contrastText: "#a19f9d", text: "#a19f9d" },
        disabled: ThemeColours("#f3f2f1", "#a19f9d"),
        // Dark grey background with white text
        default: ThemeColours("#707070", "#fff"),
        // Mid blue background with white text
        primary: ThemeColours("#17A2FF", "#fff"),
        // Mid orange background with white text
        secondary: ThemeColours("#da8300", "#fff"),
        // Mid red background with white text
        tertiary: ThemeColours("#c52f3e", "#fff"),
        // Mid green background with white text
        quarternary: ThemeColours("#1f9537", "#fff"),
        // Dark transparent background with off-black text
        defaultTr: ThemeColours("#f3f2f1", "#171716"),
        // Green submit button with white text
        ButtonGreen: ThemeColours("#4CAF47", "#FFFFFF"),
        // Blue button with white text
        ButtonBlue: ThemeColours("#337AB7", "#FFFFFF"),
        // Blue button with white text
        ButtonCancel: ThemeColours("#707070", "#FFFFFF"),
        // Customer Status - Active
        ButtonActive: ThemeColours("#94C120", "#FFFFFF"),
        // Customer Status - Inactive
        ButtonInactive: ThemeColours("#707070", "#FFFFFF"),
        // Customer Status - Dissolved
        ButtonDissolved: ThemeColours("#FF9100", "#FFFFFF"),

        // Light grey border with dark grey for hover/focus and off-black text.
        field: ThemeColours("#D4D4D4", "#000"),
        tableHeader: ThemeColours("#4b4b4b", "#fff"),
        // Mid red background with mid red text
        error: ThemeColours("#c52f3e", "#fff"),
        warning: ThemeColours("#ff9800", "#fff"),
        info: ThemeColours("#2196f3", "#fff"),
        success: ThemeColours("#4caf50", "#fff"),
        hint: ThemeColours("#1b1b1b", "#fff"),
        delete: ThemeColours("#c52f3e", "#fff"),
        //You can still define your colours manually if you want to
        purpleAccent: { lighter: "rgb(198, 51, 255)", light: "rgb(198, 51, 255)", main: "#b800ff", dark: "rgb(128, 0, 178)", darker: "rgb(128, 0, 178)", text: "pink" }, //This is a custom colour
        red: { lighter: "rgb(206, 14, 45)", light: "rgb(206, 14, 45)", main: "rgb(206, 14, 45)", dark: "rgb(206, 14, 45)", darker: "rgb(206, 14, 45)", text: "pink" },
    },

    // Fonts and Text.
    defaultFontStyle: {
        fontFamily: `"inter", sans-serif`,
        fontSize: pxToRem(16),
        fontWeight: 400,
        letterSpacing: "normal",
        lineHeight: "1.5",
        textTransform: "none",
    },
};
