import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { TSMModalListText } from "../TSMModal.styles";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { formatDate, formatTime } from "Utils/Formats";
import { DownloadButton } from "Components/Primitives/Buttons/DownloadButton";
import { TSMDocumentsModalViewModel } from "./TSMDocumentsModalViewModel";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";

interface IProps {
    item: IDocumentUploaded;
    parentViewModel: TSMDocumentsModalViewModel;
}

export const TSMDocumentListItem: React.FC<IProps> = observer((props) => {
    //  const [viewModel] = useState(() => new TSMDocumentListItemViewModel());
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    return (
        <>
            <TSMModalListText>{`${formatDate(new Date(props.item.dateCreated))} @ ${formatTime(new Date(props.item.dateCreated))}`}</TSMModalListText>
            <TSMModalListText>{props.item.fileName}</TSMModalListText>
            <TSMModalListText>{props.item.uploadedBy}</TSMModalListText>
            <Box grid dc="1fr 1fr" p="16px 0px">
                <DownloadButton id={props.item.id} onClick={props.parentViewModel.downloadDocumentCommand} />
                <DeleteButton id={props.item.id} onClick={props.parentViewModel.deleteDocumentCommand} />
            </Box>
        </>
    );
});
