import { makeObservable, computed, observable, runInAction, action } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { CustomerDetails, DetailsModel, DetailsModelValidator } from "./DetailsModel";
import { GetCustomerDetailsByIdEndpoint } from "../../Endpoints/GetCustomerDetailsById";

export class DetailsViewModel extends ViewModelBase<DetailsModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    constructor() {
        super(new DetailsModel());
        this.setValidator(new DetailsModelValidator());
        makeObservable(this, {
            isProcessing: observable,

            clear: action,
            getCustomerDetails: action,

            getCustomerType: computed,
            getAddressForGoogleMaps: computed,
            getCustomerAddress: computed,
            showAddressForGoogleMaps: computed,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public get getCustomerAddress(): string {
        let retVal: string = "";

        if (this.model.addressLine1 !== "") {
            retVal += this.model.addressLine1.toUpperCase() + ", ";
        }

        if (this.model.addressLine2 !== "") {
            retVal += this.model.addressLine2.toUpperCase() + ", ";
        }

        if (this.model.townCity !== "") {
            retVal += this.model.townCity.toUpperCase() + ", ";
        }

        if (this.model.county !== "") {
            retVal += this.model.county.toUpperCase() + ", ";
        }

        if (this.model.country !== "") {
            retVal += this.model.country.toUpperCase() + ", ";
        }

        if (this.model.postCode !== "") {
            retVal += this.model.postCode.toUpperCase();
        }

        return retVal;
    }

    public get getCustomerType(): number {
        let retVal: number = this.model.customerTypeId;
        return retVal;
    }

    public async getCustomerDetails(id: any): Promise<void> {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;

            const endpoint = new GetCustomerDetailsByIdEndpoint(id);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: CustomerDetails = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.isProcessing = false;
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View customer");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the customer details.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public get showAddressForGoogleMaps(): boolean {
        return (
            this.getValue("locationLatitude") !== null &&
            this.getValue("locationLatitude") !== 0 &&
            this.getValue("locationLongitude") !== null &&
            this.getValue("locationLongitude") !== 0
        );
    }

    public get getAddressForGoogleMaps() {
        if (this.getValue("locationLatitude") !== null && this.getValue("locationLongitude") !== null) {
            return this.getValue("locationLatitude") + ", " + this.getValue("locationLongitude");
        } else {
            // This doesn't seem to work with google maps anymore, so will just have to be from the postcode
            // return this.getValue("addressLine1") + " " + this.getValue("postCode");
            return this.getValue("postCode");
        }
    }
}
