import { action, makeObservable, observable, IObservableArray, computed } from "mobx";
import { SectionAnswer } from "./SectionAnswer";
import { SectionImage } from "./SectionImage";

export class ConditionReportSectionQuestion {
    public id: number | null = null;
    public sectionId: number | null = null;
    public controlTypeId: number = 1;
    public dataTypeId: number = 1;
    public commentQuestionText: string = "";
    public comment: string = "";
    public label: string = "";
    public question: string = "";
    public formattedText: boolean = false;
    public answers: IObservableArray<SectionAnswer> = observable([]);
    public images: IObservableArray<SectionImage> = observable([]);

    public questionTypeKey: string = "";
    public answerTypeKey: string = "";
    public commentQuestionTextKey: string = "";
    public commentQuestionTextKeyAnswer: string = "";

    constructor() {
        makeObservable(this, {
            id: observable,
            sectionId: observable,
            controlTypeId: observable,
            dataTypeId: observable,
            commentQuestionText: observable,
            comment: observable,
            label: observable,
            question: observable,
            formattedText: observable,

            clear: action,
            load: action,

            hasAnswersOrImages: computed,
        });
    }

    public get hasAnswersOrImages(): boolean {
        let retVal: boolean = true;

        let hasAnswers: SectionAnswer[] = this.answers.filter((a) => a.answer !== null && a.answer !== undefined && a.answer.length > 0);
        if (hasAnswers.length === 0 && this.images.length === 0) {
            retVal = false;
        }

        return retVal;
    }

    public load(question: ConditionReportSectionQuestion) {
        this.clear();

        if (question !== undefined) {
            this.id = question.id;
            this.sectionId = question.sectionId;
            this.controlTypeId = question.controlTypeId;
            this.dataTypeId = question.dataTypeId;
            this.commentQuestionText = question.commentQuestionText;
            this.comment = question.comment;
            this.label = question.label;
            this.question = question.question;

            for (const element of question.answers) {
                let answer: SectionAnswer = new SectionAnswer();
                answer.load(element);
                this.answers.push(answer);
            }

            for (let i: number = 0; i < question.images.length; i++) {
                let image: SectionImage = new SectionImage();
                image.load(question.images[i]);
                this.images.push(image);
            }
        }
    }

    public clear = () => {
        this.id = null;
        this.sectionId = null;
        this.controlTypeId = 1;
        this.dataTypeId = 1;
        this.commentQuestionText = "";
        this.comment = "";
        this.label = "";
        this.question = "";

        this.answers.clear();
        this.images.clear();
    };
}
