import styled from "@emotion/styled";
import { Box } from "Application";

export const NewConditionReport = styled(Box)`
    padding: 30px;
`;

export const EditConditionReport = styled(Box)``;

export const UploadImageBox = styled(Box)`
    text-align: center;
    background-color: white;
    border-radius: 0.125rem;
    border: dashed 1px black;
    padding: 20px;
    margin: 10px 0px 10px 0px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    cursor: pointer;
`;
