import { makeObservable, action, computed, observable, IObservableArray } from "mobx";
import { CoreStoreInstance, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ComplaintModel, ComplaintModelValidator } from "Models/Complaints/ComplaintModel";
import { IComplaintAddEditViewModel } from "./IComplaintAddEditViewModel";
import { IComplaintRelatedResponse } from "Models/Complaints/IComplaintRelatedResponse";
import { InstallationCompanyDropdownItem } from "Models/Complaints/InstallationCompanyDropdownItem";
import { AssignedUserDropdownItem } from "Models/Complaints/AssignedUserDropdownItem";
import { ComplaintNatureDropdownItem } from "Models/Complaints/ComplaintNatureDropdownItem";
import { ComplaintCategoryDropdownItem } from "Models/Complaints/ComplaintCategoryDropdownItem";
import { IComplaintWithRelatedResponse } from "Models/Complaints/ComplaintWithRelatedResponse";
import { DateTime } from "luxon";
import { CompanyWhoReportedDropdownItem } from "Models/Complaints/CompanyWhoReportedDropdownItem";

export abstract class AddEditBaseViewModel extends ViewModelBase<ComplaintModel> implements IComplaintAddEditViewModel {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public showActionSection: boolean = false;
    public showNewCustomerModel: boolean = false;

    public companyWhoReported: IObservableArray<CompanyWhoReportedDropdownItem> = observable([]);
    public installationCompanies: IObservableArray<InstallationCompanyDropdownItem> = observable([]);
    public complaintAssignees: IObservableArray<AssignedUserDropdownItem> = observable([]);
    public complaintNatures: IObservableArray<ComplaintNatureDropdownItem> = observable([]);
    public complaintCategories: IObservableArray<ComplaintCategoryDropdownItem> = observable([]);

    public constructor() {
        super(new ComplaintModel());
        this.setValidator(new ComplaintModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            showActionSection: observable,
            showNewCustomerModel: observable,

            clear: action,
            setShowActionSection: action,
            processComplaintWithRelatedResponse: action,
            processRelatedResponse: action,

            getComplaintAssigneesDropdownItems: computed,
            getComplaintNaturesDropdownItems: computed,
            getInstallationCompaniesDropdownItems: computed,
            getCompanyWhoReportedDropdownItems: computed,
            getIsProcessing: computed,
            getShowNewCustomerModel: computed,
            getComplaintCategoryDropdown: computed,
        });
    }

    public setShowActionSection(value: boolean): void {
        this.showActionSection = value;
    }

    public abstract upsertAsync(projectId: string): Promise<any>;
    public abstract getComplaint(complaintId: string): Promise<any>;
    public abstract getComplaintRelatedForProject(projectId: string): Promise<any>;

    public openShowNewCustomerModelCommand: ICommand = new RelayCommand(() => {
        this.showNewCustomerModel = true;
    });

    public closeModelCommand = new RelayCommand(() => {
        this.showNewCustomerModel = false;
        CoreStoreInstance.HideInfoBar();
    });

    public setNature: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.natureId = parseInt(value.key);
    });

    public setCategory: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.categoryId = parseInt(value.key);
    });

    public setComplaintAssignee: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.assignedUserId = parseInt(value.key);
    });

    public setReportedBy: ICommand = new RelayCommand(
        (value: string) => {
            this.model.reportedBy = value;
        },
        () => {
            return !this.model.isResolved;
        },
    );

    public setCompanyWhoReportedBy: ICommand = new RelayCommand(
        (value: string) => {
            this.model.companyReportedBy = value;
        },
        () => {
            return !this.model.isResolved;
        },
    );

    public setSiteAddressdBy: ICommand = new RelayCommand(
        (value: string) => {
            this.model.siteAddress = value;
        },
        () => {
            return !this.model.isResolved;
        },
    );

    public setDetails: ICommand = new RelayCommand(
        (value: string) => {
            this.model.details = value;
        },
        () => {
            return !this.model.isResolved;
        },
    );

    public setOrderValue: ICommand = new RelayCommand(
        (value: string) => {
            this.model.orderValue = value;
        },
        () => {
            return !this.model.isResolved;
        },
    );

    public setAssessment: ICommand = new RelayCommand(
        (value: string) => {
            this.model.assessment = value;
        },
        () => {
            return !this.model.isResolved;
        },
    );

    public setInstallationCompany: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.model.installationContractorId = value.key;
        },
        () => {
            return !this.model.isResolved;
        },
    );

    public setCompanyWhoReported: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.model.reportingCompanyId = value.key;
        },
        () => {
            return !this.model.isResolved;
        },
    );

    public get getIsProcessing(): boolean {
        return this.isProcessing;
    }

    public get getShowNewCustomerModel(): boolean {
        return this.showNewCustomerModel;
    }

    public get getCompanyWhoReportedDropdownItems(): KeyValuePair[] {
        return this.companyWhoReported.map((x) => {
            return { key: x.id, text: x.name };
        });
    }

    public get getInstallationCompaniesDropdownItems(): KeyValuePair[] {
        return this.installationCompanies.map((x) => {
            return { key: x.id, text: x.name };
        });
    }

    public get getComplaintAssigneesDropdownItems(): KeyValuePair[] {
        return this.complaintAssignees.map((x) => {
            return { key: x.id, text: x.displayName };
        });
    }

    public get getComplaintNaturesDropdownItems(): KeyValuePair[] {
        return this.complaintNatures.map((x) => {
            return { key: x.id, text: x.nature };
        });
    }

    public get getComplaintCategoriesDropdownItems(): KeyValuePair[] {
        return this.complaintCategories.map((x) => {
            return { key: x.id, text: x.category };
        });
    }

    public get getComplaintCategoryDropdown(): ComplaintCategoryDropdownItem[] {
        return this.complaintCategories.map((x) => {
            return { id: x.id, category: x.category, parentId: x.parentId, ordinal: x.ordinal };
        });
    }

    public setIsResolved: ICommand = new RelayCommand((value: boolean) => {
        this.model.isResolved = value;
        if (value === false) {
            this.setValue("resolvedDate", null);
            this.setValue("resolvedCategoryId", null);
        }
    });

    public setConfirmComplaintType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.model.resolvedCategoryId = parseInt(value.key);
    });

    public setDateResolved: ICommand = new RelayCommand((value: moment.Moment) => {
        // Convert to luxon
        let temp: string = value.toISOString();
        this.setValue("resolvedDate", DateTime.fromISO(temp));
    });

    public get getDateResolved(): Date {
        if (this.model.resolvedDate) {
            return new Date(this.model.resolvedDate.toString());
        } else {
            return new Date();
        }
    }

    public clear = () => {
        this.isProcessing = false;
        this.showNewCustomerModel = false;
        this.model.clear();
    };

    public processRelatedResponse = (response: IComplaintRelatedResponse) => {
        this.installationCompanies.clear();
        this.complaintAssignees.clear();
        this.complaintNatures.clear();
        this.complaintCategories.clear();
        this.companyWhoReported.clear();

        this.companyWhoReported.replace(response.reportingCompanies);
        this.installationCompanies.replace(response.installationCompanies);
        this.complaintAssignees.replace(response.complaintAssignees);
        this.complaintNatures.replace(response.complaintNatures);
        this.complaintCategories.replace(response.complaintCategories);

        if (response.projectDetails !== undefined && response.projectDetails != null) {
            // Do additional checks since this is in the base class and we only want to write
            // these if the project details has been filled in. i.e. on Add only.
            if (response.projectDetails.siteAddress.length > 0) {
                this.model.siteAddress = response.projectDetails.siteAddress;
            }
            if (response.projectDetails.orderAmount > 0) {
                this.model.orderValue = response.projectDetails.orderAmount.toString();
            }
        }
    };

    public processComplaintWithRelatedResponse = (response: IComplaintWithRelatedResponse) => {
        this.model.fromResponse(response.complaint);
        this.processRelatedResponse(response);
    };
}
