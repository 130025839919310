import { KeyValuePair } from "@shoothill/core";
import { TemplateSourceModel } from "./Models/Source/TemplateSourceModel";
import { SectionLevel, generateControlTypesForPage, generateControlTypesSection } from "Utils/TemplateSource";
import { SourcePageModel } from "./Models/Source/SourcePageModel";
import { SourceSectionModel } from "./Models/Source/SourceSectionModel";

export interface IExtendedSelectOption {
    display: string;
    className: string;
    value: string;
}

export enum ControlType {
    Unknown = 1,
    ButtonGroup = 2,
    Camera = 3,
    Dropdown = 4,
    Label = 5,
    LargeTextBox = 6,
    Multiselect = 7,
    SketchPad = 8,
    SmallTextBox = 9,
    Paragraph = 10,
}

export namespace ControlTypeHelpers {
    export const getText = (type: ControlType) => {
        switch (type) {
            case ControlType.ButtonGroup: {
                return "Button Group";
            }
            case ControlType.Camera: {
                return "Camera";
            }

            case ControlType.Dropdown: {
                return "Dropdown";
            }

            case ControlType.Label: {
                return "Label";
            }

            case ControlType.LargeTextBox: {
                return "LargeTextBox";
            }

            case ControlType.Multiselect: {
                return "Multi select";
            }

            case ControlType.SketchPad: {
                return "Sketch Pad Images";
            }

            case ControlType.SmallTextBox: {
                return "Small Text Box";
            }

            case ControlType.Paragraph: {
                return "General Text Area";
            }

            case ControlType.Unknown:

            default: {
                return "Unknown";
            }
        }
    };

    export const getOptions = (): IExtendedSelectOption[] => {
        const options: IExtendedSelectOption[] = [];

        for (const key in ControlType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const type = keyAsInt as ControlType;
                if (type !== ControlType.Unknown) {
                    options.push({
                        className: "",
                        display: ControlTypeHelpers.getText(type),
                        value: keyAsInt.toString(),
                    });
                }
            }
        }

        return options;
    };

    export const getKeyValuePairOptions = (): KeyValuePair[] => {
        const options: KeyValuePair[] = [];

        for (const key in ControlType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const type = keyAsInt as ControlType;
                if (type !== ControlType.Unknown) {
                    options.push({
                        text: ControlTypeHelpers.getText(type),
                        key: keyAsInt.toString(),
                    });
                }
            }
        }

        return options;
    };

    export const getKeyValuePairOptionsForAddSection = (): KeyValuePair[] => {
        const options: KeyValuePair[] = [];

        for (const key in ControlType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const type = keyAsInt as ControlType;
                if (
                    type !== ControlType.Unknown &&
                    type !== ControlType.LargeTextBox &&
                    type !== ControlType.SmallTextBox &&
                    type !== ControlType.Label &&
                    type !== ControlType.Dropdown
                ) {
                    options.push({
                        text: ControlTypeHelpers.getText(type),
                        key: keyAsInt.toString(),
                    });
                }
            }
        }

        return options;
    };

    export const getKeyValuePairOptionsForTemplate = (templateSource: TemplateSourceModel, currentLevel: SectionLevel, sectionTypeKeyId: string): KeyValuePair[] => {
        // Always want generic paragraph
        const retVal: KeyValuePair[] = [
            {
                text: ControlTypeHelpers.getText(ControlType.Paragraph),
                key: (ControlType.Paragraph as number).toString(),
            },
        ];

        const usedControlTypes: ControlType[] = [ControlType.Paragraph];

        if (currentLevel === SectionLevel.PAGE) {
            let currentPage: SourcePageModel | undefined = templateSource.pages.find((a) => a.typeKey === sectionTypeKeyId);

            if (currentPage !== undefined) {
                const pageCT: ControlType[] = generateControlTypesForPage(currentPage);

                for (const element of pageCT) {
                    if (usedControlTypes.indexOf(element) === -1) {
                        retVal.push({
                            text: ControlTypeHelpers.getText(element),
                            key: (element as number).toString(),
                        });
                    }
                }
            }
        }

        if (currentLevel === SectionLevel.SECTION) {
            // Get all the sections and process them
            for (const page of templateSource.pages) {
                let currentSection: SourceSectionModel | undefined = page.sections.find((a) => a.typeKey === sectionTypeKeyId);

                if (currentSection !== undefined) {
                    const sectionCT: ControlType[] = generateControlTypesSection(currentSection);

                    for (const element of sectionCT) {
                        if (usedControlTypes.indexOf(element) === -1) {
                            retVal.push({
                                text: ControlTypeHelpers.getText(element),
                                key: (element as number).toString(),
                            });
                        }
                    }
                    break;
                }
            }
        }

        if (currentLevel === SectionLevel.SUBPAGE) {
            // Get all the subpages and process them
            for (const page of templateSource.pages) {
                for (const section of page.sections) {
                    for (const row of section.rows) {
                        // Find the actual sub page
                        let currentSubPage: SourcePageModel | undefined = row.pages.find((a) => a.typeKey === sectionTypeKeyId);

                        if (currentSubPage !== undefined) {
                            const subPageCT: ControlType[] = generateControlTypesForPage(currentSubPage);

                            for (const element of subPageCT) {
                                if (usedControlTypes.indexOf(element) === -1) {
                                    retVal.push({
                                        text: ControlTypeHelpers.getText(element),
                                        key: (element as number).toString(),
                                    });
                                }
                            }
                            break;
                        }
                    }
                }
            }
        }

        if (currentLevel === SectionLevel.SUBSECTION) {
            // Get all the subpages and process them
            for (const page of templateSource.pages) {
                for (const section of page.sections) {
                    for (const row of section.rows) {
                        for (const supPage of row.pages) {
                            let currentSubSection: SourceSectionModel | undefined = supPage.sections.find((a) => a.typeKey === sectionTypeKeyId);

                            if (currentSubSection !== undefined) {
                                const subSectionCT: ControlType[] = generateControlTypesSection(currentSubSection);

                                for (const element of subSectionCT) {
                                    if (usedControlTypes.indexOf(element) === -1) {
                                        retVal.push({
                                            text: ControlTypeHelpers.getText(element),
                                            key: (element as number).toString(),
                                        });
                                    }
                                }
                                break;
                            }
                        }
                    }
                }
            }
        }

        return retVal.filter(
            (a) =>
                a.key !== (ControlType.Label as number).toString() &&
                a.key !== (ControlType.LargeTextBox as number).toString() &&
                a.key !== (ControlType.SmallTextBox as number).toString() &&
                a.key !== (ControlType.Unknown as number).toString(),
        );
    };
}
