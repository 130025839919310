import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { PropertyMasterProjectListItem } from "../ViewProperty/Models/PropertyMasterProjectListItem";
import { AppUrls } from "AppUrls";
import { PropertyProjectQuoteListItem } from "../ViewProperty/Models/PropertyProjectQuoteListItem";

export class DeleteMasterProjectByIdRequest {
    public id: string = "";
    public propertyId: string = "";

    public constructor() {}
}

export interface DeleteMasterProjectResponse {
    masterProjects: PropertyMasterProjectListItem[];
    projectQuotes: PropertyProjectQuoteListItem[];
}

export class DeleteMasterProjectByIdEndpoint extends Endpoint<DeleteMasterProjectByIdRequest, DeleteMasterProjectResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.DeleteMasterProjectById);
    }

    public async HandleRequestAsync(request: DeleteMasterProjectByIdRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: DeleteMasterProjectResponse): Promise<DeleteMasterProjectResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
