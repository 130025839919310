import { makeObservable, observable, runInAction, action, IObservableArray, computed } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { BlankModel } from "Application/Models/Domain/BlankModel";
import { EligibleTemplateModel } from "Views/ConditionReport/ConditionReport/EligibleTemplateModel";
import { GetEligibleTemplatesForConditionReportEndpoint } from "Views/ConditionReport/ConditionReport/Endpoints/GetEligibleTemplatesForConditionReportEndpoint";

export class GetEligibleTemplateModalViewModel extends ViewModelBase<BlankModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public selectedTemplateId: string = "";
    public conditionReportId: string = "";
    public isProcessing: boolean = false;

    public templates: IObservableArray<EligibleTemplateModel> = observable([]);

    constructor(conditionReportId: string) {
        super(new BlankModel());
        this.conditionReportId = conditionReportId;
        this.apiClient.setAPITimeout(240000);

        makeObservable(this, {
            selectedTemplateId: observable,
            conditionReportId: observable,
            isProcessing: observable,

            clear: action,
            load: action,

            //setTemplate: action,

            getTemplatesForDropdown: computed,
        });
    }

    public clear() {
        this.selectedTemplateId = "";
        this.conditionReportId = "";
        this.isProcessing = false;
        this.templates.clear();
    }

    public get getTemplatesForDropdown(): KeyValuePair<any>[] {
        let retVal: KeyValuePair[] = [];

        for (const element of this.templates) {
            // retVal.push({ key: element.id, text: element.templateNumber + " (" + formatDate(element.createdDate!) + ")" });
            retVal.push({ key: element.id, text: element.templateNumber });
        }

        return retVal;
    }

    public setTemplate: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.selectedTemplateId = value.key;
    });

    public load = async (): Promise<EligibleTemplateModel[]> => {
        let retVal: EligibleTemplateModel[] = [];

        const endpoint: GetEligibleTemplatesForConditionReportEndpoint = new GetEligibleTemplatesForConditionReportEndpoint();

        this.isProcessing = true;
        await this.apiClient.sendAsync(endpoint, this.conditionReportId);

        if (this.apiClient.IsRequestSuccessful === true) {
            let response = this.apiClient.Response<EligibleTemplateModel[]>();

            runInAction(() => {
                this.templates.clear();
                this.templates.replace(response);
                this.isProcessing = false;
            });
        }

        return retVal;
    };
}
