import { Logger } from "index";
import { EditableCopy, EditableCopyRoute, EditableCopyStore, EndpointWithoutRequest, Http } from "Application";
import { AppUrls } from "AppUrls";
class Response {
    editableCopies: EditableCopy[] = [];
    editableCopyRoutes: EditableCopyRoute[] = [];
}

export class GetEditableCopyEndpoint extends EndpointWithoutRequest<Response> {
    private store: EditableCopyStore = {} as EditableCopyStore;

    constructor(store: EditableCopyStore) {
        super();
        this.verb(Http.Get);
        this.path("/api/editablecopy");
        this.cache(60 * 60 * 24); //One day
        this.store = store;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);
        this.store.editableCopies.clear();
        this.store.editableCopyRoutes.clear();
        if (response.editableCopies.length > 0) {
            response.editableCopies.forEach((editableCopy) => {
                let model: EditableCopy = {} as EditableCopy;
                model.id = editableCopy.id;
                model.routeId = editableCopy.routeId;
                model.placeholderNumber = editableCopy.placeholderNumber;
                model.copyValue = editableCopy.copyValue;
                model.createdDate = editableCopy.createdDate;
                model.updatedDate = editableCopy.updatedDate;
                model.updatedBy = editableCopy.updatedBy;
                this.store.editableCopies.push(editableCopy);
            });
        }
        if (response.editableCopyRoutes.length > 0) {
            response.editableCopyRoutes.forEach((editableCopyRoute) => {
                let model: EditableCopyRoute = {} as EditableCopyRoute;
                model.id = editableCopyRoute.id;
                model.route = editableCopyRoute.route;
                this.store.editableCopyRoutes.push(editableCopyRoute);
            });
        }
        //Return your model
        return {
            editableCopies: this.store.editableCopies,
            editableCopyRoutes: this.store.editableCopyRoutes,
        };
    }
}
