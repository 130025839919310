import { EndpointWithoutRequest, Http } from "Application";
import { CustomerOptionMatrixModel } from "Models/Customer/CustomerOptionMatrix";
import { AppUrls } from "AppUrls";

export class GetCustomerMatrixEndpoint extends EndpointWithoutRequest<CustomerOptionMatrixModel[]> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Customer.GetCustomerMatrix);
    }

    public async HandleResponseAsync(response: CustomerOptionMatrixModel[]): Promise<CustomerOptionMatrixModel[]> {
        return response;
    }
}
