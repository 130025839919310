import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { CustomersListModel, CustomersListModelValidator } from "./CustomersListModel";
import { container } from "tsyringe";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { CustomerListItemModel } from "./CustomerListItemModel";
import { CustomerListAndRelatedResponse, GetCustomerListAndRelatedEndpoint } from "../Endpoints/GetCustomerListAndRelatedEndpoint";
import { DownloadCustomerCSVEndpoint } from "../Endpoints/DownloadCustomerCSVEndpoint";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { CustomerStatusHelpers } from "Application/Models/Domain/Customer/CustomerStatusEnum";

export class CustomersListViewModel extends ViewModelBase<CustomersListModel> {
    // Singleton Patten - Uses instance and a private static variable to ensure only one instance is created and a private Constructor
    private static _instance: CustomersListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public initialLoad: boolean = true;
    // Related (dropdowns)

    public customerTypesList: IObservableArray<CustomerTypeDropdownItem> = observable([]);
    public tsmList: IObservableArray<KeyValuePairExtended> = observable([]);
    public bdmList: IObservableArray<KeyValuePairExtended> = observable([]);

    // Filtering top section

    // public keywordSearch: string = "";
    public filterByType: number[] = [];
    public filterByBdm: string[] = [];
    public filterByTsm: string[] = [];
    public filterByStatus: string[] = ["100", "200", "300"];

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "name";

    // Pagination

    public customerCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;

    // Filtering

    public userRolesForFiltering: string[] = [];

    // Search String
    public searchString: string = "";
    public searchPostCodeOnly: boolean = false;

    private constructor() {
        super(new CustomersListModel());
        this.setValidator(new CustomersListModelValidator());
        makeObservable(this, {
            // keywordSearch: observable,
            isProcessing: observable,
            searchPostCodeOnly: observable,
            searchString: observable,
            orderBy: observable,
            columnName: observable,
            pageSize: observable,
            pageNumber: observable,
            customerCount: observable,
            filterByType: observable,
            filterByBdm: observable,
            filterByTsm: observable,
            filterByStatus: observable,

            DownloadCSVAsync: action,
            loadCustomersAsync: action,
            loadCustomers: action,
            //setFilterByType: action,
            //updateKeywordSearch: action,

            getCanExecute: computed,
            getCustomers: computed,
            getCustomerTypesForDropdown: computed,
            getBdms: computed,
            getBdmFilter: computed,
            bdmSelectAll: action,
            bdmSelectNone: action,
            getTsms: computed,
            getTsmFilter: computed,
            tsmSelectAll: action,
            tsmSelectNone: action,
            updateTsmFiltering: action,
            updateBdmFiltering: action,
            getTypeFilter: computed,
            getFilterByStatusFilter: computed,
            getIsSearchPostCodeOnly: computed,
            typeSelectAll: action,
            typeSelectNone: action,
            filterByStatusSelectAll: action,
            filterByStatusSelectNone: action,
            updateTypeFiltering: action,
            updateFilterByStatusFiltering: action,
            changePageNumber: action,
        });
    }

    public clear() {
        this.isProcessing = false;
        this.searchPostCodeOnly = false;
        this.searchString = "";
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.customerCount = 0;
        this.pageSize = DefaultPageSize;
        this.pageCount = 0;
        this.pageNumber = 1;
    }

    public reset = () => {
        this.searchString = "";
        this.searchPostCodeOnly = false;
        this.typeSelectAll();
        this.bdmSelectAll();
        this.tsmSelectAll();
        this.filterByStatusSelectAll();
    };

    public get getIsSearchPostCodeOnly(): boolean {
        return this.searchPostCodeOnly;
    }

    public onPostCodeOnlySearchChange: ICommand = new RelayCommand(
        (value: boolean) => {
            this.searchPostCodeOnly = value;

            if (this.searchString.length > 0) {
                this.pageNumber = 1;
                this.loadCustomersAsync();
            }
        },
        () => {
            return this.getCanExecute;
        },
    );

    public get getCanExecute(): boolean {
        return !this.isProcessing;
    }

    public updateKeywordSearch: ICommand = new RelayCommand(
        (keyword: string) => {
            this.searchString = keyword;
            this.pageNumber = 1;
            this.loadCustomersAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public tempCommand: ICommand = new RelayCommand(
        () => {},
        () => {
            return this.getCanExecute;
        },
    );

    public updateFiltering: ICommand = new RelayCommand(
        () => {},
        () => {
            return this.getCanExecute;
        },
    );

    public clearFiltering: ICommand = new RelayCommand(
        () => {
            this.filterByType = [];
            this.filterByBdm = [];
            this.filterByTsm = [];
            this.filterByStatus = ["100", "200", "300"];
            this.initialLoad = true;
            this.loadCustomersAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public loadCustomersAsync = async (): Promise<CustomerListAndRelatedResponse> => {
        const endpoint = new GetCustomerListAndRelatedEndpoint();
        let retVal: CustomerListAndRelatedResponse = new CustomerListAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.model.Customers.clear();
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: CustomerListAndRelatedResponse = this.apiClient.Response();
                    this.loadCustomers(response.customerList);
                    this.customerCount = response.customerCount;
                    this.customerTypesList.replace(response.customerTypesList);
                    this.pageCount = Math.ceil(this.customerCount / this.pageSize);

                    if (this.initialLoad === true) {
                        this.bdmList.clear();
                        this.tsmList.clear();

                        this.bdmList.replace(
                            response.bdmList.map((item) => {
                                return { key: item.id.toString(), text: item.displayName, class: "" };
                            }),
                        );

                        this.tsmList.replace(
                            response.tsmList.map((item) => {
                                return { key: item.id.toString(), text: item.displayName, class: "" };
                            }),
                        );

                        const tempBdm: string[] = response.bdmList.map((item) => {
                            return item.id.toString();
                        });

                        const tempTsm: string[] = response.tsmList.map((item) => {
                            return item.id.toString();
                        });

                        this.filterByBdm = tempBdm;
                        this.filterByTsm = tempTsm;

                        // Reset the filters to ALL
                        this.typeSelectAll();
                        // this.filterByStatusSelectAll();

                        this.initialLoad = false;
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Customer list");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the customer list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public loadCustomers(Customers: CustomerListItemModel[]) {
        this.model.Customers.clear();
        this.customerCount = 0;
        this.model.Customers.replace(Customers);
    }

    public get getCustomers(): CustomerListItemModel[] {
        return this.model.Customers.slice();
    }

    public get getCustomerTypesForDropdown() {
        let newArray = this.customerTypesList.map((type) => {
            return { key: type.id, text: type.name };
        });

        return newArray;
    }

    public setFilterByType: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterByType = value.key;
            this.pageNumber = 1;
            this.loadCustomersAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public get getBdms(): KeyValuePairExtended[] {
        return this.bdmList.slice();
    }

    public get getBdmFilter(): string | string[] {
        return this.filterByBdm.slice();
    }

    public get getTypeFilter(): number | number[] {
        return this.filterByType.slice();
    }

    public get getFilterByStatusFilter(): string | string[] {
        return this.filterByStatus.slice();
    }

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public bdmSelectAll = () => {
        this.filterByBdm = this.bdmList.map((item) => {
            return item.key.toString();
        });
    };

    public bdmSelectNone = () => {
        this.filterByBdm = [];
    };

    public updateBdmFiltering = (values: string[]) => {
        this.filterByBdm = values;
    };

    public updateTsmFiltering = (values: string[]) => {
        this.filterByTsm = values;
    };

    public updateTypeFiltering = (values: number[]) => {
        this.filterByType = values;
    };

    public updateFilterByStatusFiltering = (values: string[]) => {
        this.filterByStatus = values;
    };

    public get getTsms(): KeyValuePairExtended[] {
        return this.tsmList.slice();
    }

    public get getTsmFilter(): string | string[] {
        return this.filterByTsm.slice();
    }

    public tsmSelectAll = () => {
        this.filterByTsm = this.tsmList.map((item) => {
            return item.key.toString();
        });
    };

    public tsmSelectNone = () => {
        this.filterByTsm = [];
    };

    public typeSelectAll = () => {
        this.filterByType = this.getCustomerTypesForDropdown.map((item) => {
            return item.key;
        });
    };

    public typeSelectNone = () => {
        this.filterByType = [];
    };

    public filterByStatusSelectAll = () => {
        this.filterByStatus = CustomerStatusHelpers.getStatusForDropdown().map((item) => {
            return item.key;
        });
    };

    public filterByStatusSelectNone = () => {
        this.filterByStatus = [];
    };

    public setFilterByTsm: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterByTsm = value.key;
            this.pageNumber = 1;
            this.loadCustomersAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public setFilterByStatus: ICommand = new RelayCommand(
        (value: KeyValuePair) => {
            this.filterByStatus = value.key;
            this.pageNumber = 1;
            this.loadCustomersAsync();
        },
        () => {
            return this.getCanExecute;
        },
    );

    public async DownloadCSVAsync(): Promise<void> {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            const endpoint = new DownloadCustomerCSVEndpoint(this);
            await this.apiClient.sendAsync(endpoint);
            this.isProcessing = true;

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Customer CSV");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the customer CSV.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.loadCustomersAsync();
    };
}
