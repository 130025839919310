import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { DropdownItem } from "Models/DropdownItem";

export class GetContractorsRequest {
    searchString: string = "";
}

export class GetContractorsResponse {
    contractors: DropdownItem[] = [];
}

export class GetContractorsForDropdownEndpoint extends Endpoint<GetContractorsRequest, GetContractorsResponse> {
    public searchString: string;

    constructor(searchString: string) {
        super();
        this.searchString = searchString;
        this.verb(Http.Post);
        this.path("/api/tsm/getcontractors");
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new GetContractorsRequest();
        request.searchString = this.searchString;
        return request;
    }

    public async HandleResponseAsync(response: GetContractorsResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
