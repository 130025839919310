import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { BaseWrapper } from "./BaseWrapper";

export const FallbackPageView: React.FC = observer(() => {
    return (
        <>
            <BaseWrapper></BaseWrapper>
        </>
    );
});
