import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { RoofSubstrateEnum } from "Models/Property/RoofSubstrateEnum";

export class ViewPropertyModel extends ModelBase<ViewPropertyModel> {
    //public id: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    townCity: string = "";
    county: string = "";
    postCode: string = "";
    createdByName: string = "";
    // createdDate: string = "";
    attendedSite: boolean | null = null;
    roofSubstrate: RoofSubstrateEnum | null = null;
    endOfLeaseMonth: number | null = null;
    endOfLeaseYear: number | null = null;
    locationLatitude: number | null = null;
    locationLongitude: number | null = null;

    projectQuoteCount: number = 0;
    masterProjectCount: number = 0;
    multiSiteProjectCount: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            //id: observable
            addressLine1: observable,
            addressLine2: observable,
            townCity: observable,
            county: observable,
            postCode: observable,
            createdByName: observable,
            // createdDate: string = "";
            attendedSite: observable,
            roofSubstrate: observable,
            endOfLeaseMonth: observable,
            endOfLeaseYear: observable,
            locationLatitude: observable,
            locationLongitude: observable,

            projectQuoteCount: observable,
            multiSiteProjectCount: observable,
            masterProjectCount: observable,
        });
    }

    public clear = () => {
        //this.id = "";
    };
}

export class ViewPropertyModelValidator extends Validator<ViewPropertyModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
