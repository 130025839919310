import { isEmptyOrWhitespace } from "@shoothill/core";
import clsx from "clsx";
import { CSSProperties, forwardRef } from "react";
import {
    AccentColours,
    getThemePaletteOption,
    getThemeShapeOption,
    getThemeSizeOption,
    ICommand,
    theme,
    themePaletteOptions,
    themeShapeOptions,
    themeSizeOptions,
} from "Application";
import styled from "@emotion/styled";
import { SpaceProps } from "styled-system";

type IButtonBaseProps = {
    size: string;
    paletteColour: AccentColours;
    shape: string;
    boxShadow: string;
} & SpaceProps;

const StyledButton = styled.button<IButtonBaseProps>`
    min-height: 40px;
    padding: 8px 10px;
    box-shadow: ${(p) => p.boxShadow};
    text-transform: ${theme.fontStyles.button.textTransform ?? theme.defaultFontStyle.textTransform};
    font-family: ${theme.fontStyles.button.fontFamily ?? theme.defaultFontStyle.fontFamily};
    font-size: ${theme.fontStyles.button.fontSize ?? theme.defaultFontStyle.fontSize};
    font-weight: ${theme.fontStyles.button.fontWeight ?? theme.defaultFontStyle.fontWeight};
    letter-spacing: ${theme.fontStyles.button.letterSpacing ?? theme.defaultFontStyle.letterSpacing};
    line-height: ${theme.fontStyles.button.lineHeight ?? theme.defaultFontStyle.lineHeight};
    background-color: ${(p) => p.paletteColour.main};
    color: ${(p) => p.paletteColour.text};
    border-radius: 10px;
    border: 0;
    cursor: pointer;
    flex-basis: 0;
    white-space: nowrap;
    &:hover {
        background-color: ${(p) => p.paletteColour.dark};
    }
    &:active {
        background-color: ${(p) => p.paletteColour.light};
    }
    &:focus {
        outline: 0;
    }
`;

/**
 * Button interface.
 */
type IButtonProps = {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the button. Will be passed back by the command.
     */
    value?: string | number | boolean;
    /**
     * Element to display on the button.
     */
    displayName?: JSX.Element | string;
    /**
     * An icon to display on the button.
     */
    icon?: JSX.Element;
    /**
     * Styling of the button.
     */
    style?: CSSProperties | undefined;
    /**
     * The size of the button - use this if using generic button.
     */
    size?: themeSizeOptions;
    /**
     * The color of the button - use this if using the generic button.
     */
    paletteColor?: themePaletteOptions;
    /**
     * The shape of the button - use this if using the generic button.
     */
    shape?: themeShapeOptions;
    /**
     * The button type.
     */
    type?: "button" | "submit" | "reset";
    /**
     * Use to make cypress testing easier
     */
    cy?: string;

    boxShadow?: boolean;

    isButtonDisabled?: boolean;
} & SpaceProps;

export const Button = forwardRef((props: IButtonProps, ref: any) => {
    const { className, command, value, displayName, icon, style, type, isButtonDisabled, ...rest } = props;
    // #region Code Behind
    const size = getThemeSizeOption(props.size);
    const paletteColor = getThemePaletteOption(props.paletteColor);
    const shape = getThemeShapeOption(props.shape);

    const getClassNames = () => {
        return clsx({
            [props.className!]: !isEmptyOrWhitespace(props.className),
        });
    };

    const isDisabled = (): boolean => {
        // TODO CMR isDisabled needs implementing with the isDisabled flag.
        return isButtonDisabled === undefined ? false : isButtonDisabled;
    };

    const getType = (): "submit" | "reset" | "button" => {
        return !isEmptyOrWhitespace(props.type) ? props.type! : "button";
    };

    const onClick = (ev: any): void => {
        ev.stopPropagation();
        props.command.execute(props.value);
    };

    const getDataCY = (): string => {
        try {
            let regex = /[^a-z]/gi;
            let result = ((props.displayName as string) || "").replace(regex, "");
            return props.cy ?? result;
        } catch (error) {
            return "";
        }
    };

    const getBoxShadow = (): string => {
        return props.boxShadow === true ? "3px 0px 6px #00000029" : "";
    };
    // #endregion Code Behind
    return (
        <StyledButton
            {...rest}
            size={size}
            paletteColour={paletteColor}
            shape={shape}
            style={props.style}
            onClick={onClick}
            disabled={isDisabled()}
            className={getClassNames()}
            type={getType()}
            data-cy={getDataCY()}
            ref={ref}
            boxShadow={getBoxShadow()}
        >
            {props.icon}
            {props.displayName}
        </StyledButton>
    );
});
