import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { MultiSiteProjectViewModel } from "../MultiSiteProjectViewModel";
import { AppUrls } from "AppUrls";

class DeletePropertyMultiSiteProjectByIdRequest {
    Id: Guid = "";
    propertyId: Guid = "";
}

class DeletePropertyMultiSiteProjectByIdResponse {}

export class DeletePropertyMultiSiteProjectByIdEndpoint extends Endpoint<DeletePropertyMultiSiteProjectByIdRequest, DeletePropertyMultiSiteProjectByIdResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.DeleteMultiSiteProjectById);
    }

    public async HandleRequestAsync(viewModel: MultiSiteProjectViewModel): Promise<any> {
        let request = new DeletePropertyMultiSiteProjectByIdRequest();
        request.Id = viewModel.propertyMultiSiteToDeleteId;
        request.propertyId = viewModel.model.propertyId;
        return request;
    }

    public async HandleResponseAsync(response: DeletePropertyMultiSiteProjectByIdResponse): Promise<any> {
        return response;
    }
}
