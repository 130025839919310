import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, EditSelect, ICommand, RelayCommand, ThemedLoader } from "Application";
import { Modal } from "@fluentui/react";
import { P } from "Components/Primitives/TextElements/TextElements";
import AddIcon from "Assets/Icons/addRoundButtonIcon.svg";
import CloseIcon from "Assets/close_icon.svg";
import { ButtonBox, HeaderBox } from "Components/AreYouSureModal/AreYouSure.styles";
import { GetEligibleTemplateModalViewModel } from "./GetEligibleTemplateModalViewModel";
import { AddSectionModalBody, AddSectionModalHeader, AddSectionModalWrapper, ModalFooter, ModalRow } from "Views/ConditionReport/Setup/AddEdit/Modals/AddSectionModal.style";
import { SpanFourBox } from "Components/SpanBoxes";

export interface IGetEligibleTemplate {
    conditionReportId: string;
    title: string;
    isOpen: boolean;

    onConfirm: (conditionReportId: string, templateId: string) => void;
    onCancel: () => void;
}

export const GetEligibleTemplateModal: React.FC<IGetEligibleTemplate> = observer(({ title, isOpen, onCancel, onConfirm, conditionReportId }) => {
    const [viewModel] = useState<GetEligibleTemplateModalViewModel>(new GetEligibleTemplateModalViewModel(conditionReportId));

    useEffect(() => {
        let promise = viewModel.load();

        promise.then((items) => {});

        return () => {
            // Clean up after yourself
            clear();
        };
    }, []);

    useEffect(() => {
        let promise = viewModel.load();

        promise.then((items) => {});
    }, [isOpen]);

    const clear = () => {
        viewModel.clear();
    };

    const handleAddClick: ICommand = new RelayCommand(() => {
        if (viewModel.selectedTemplateId.length > 0) {
            onConfirm(conditionReportId, viewModel.selectedTemplateId);
        }
    });

    const handleCancelClick: ICommand = new RelayCommand(() => {
        handleCancel();
    });

    const handleCancel = () => {
        onCancel();
    };

    const disableSave: boolean = viewModel.selectedTemplateId.length === 0 ? true : false;

    return (
        <>
            <Modal isOpen={isOpen}>
                {viewModel.isProcessing ? (
                    <ThemedLoader isOpen={false} spinnerText={"Gett Eligible Templates..."} />
                ) : (
                    <AddSectionModalWrapper>
                        <AddSectionModalHeader>
                            <HeaderBox>
                                <img src={AddIcon} height="20px" />
                                <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                    {title}
                                </P>
                            </HeaderBox>
                            <HeaderBox style={{ cursor: "pointer" }} onClick={() => handleCancel()}>
                                <img src={CloseIcon} />
                            </HeaderBox>
                        </AddSectionModalHeader>

                        <AddSectionModalBody>
                            {viewModel.templates.length === 0 && (
                                <ModalRow>
                                    <p>There are no eligible templates. Please create a template based of this file.</p>
                                </ModalRow>
                            )}
                            {viewModel.templates.length > 0 && (
                                <SpanFourBox>
                                    <p>Select the template to create the editable document from.</p>
                                    <EditSelect
                                        displayName="Type *"
                                        command={viewModel.setTemplate}
                                        options={viewModel.getTemplatesForDropdown}
                                        value={() => viewModel.selectedTemplateId}
                                    />
                                </SpanFourBox>
                            )}
                        </AddSectionModalBody>
                        <ModalFooter>
                            <ButtonBox pt="30px">
                                <Button command={handleAddClick} displayName="Select" boxShadow={true} paletteColor="ButtonGreen" isButtonDisabled={disableSave} />
                                <Button className="last-button" command={handleCancelClick} displayName="Cancel" boxShadow={true} paletteColor="ButtonBlue" />
                            </ButtonBox>
                        </ModalFooter>
                    </AddSectionModalWrapper>
                )}
            </Modal>
        </>
    );
});
