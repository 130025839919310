import { AppUrls } from "AppUrls";
import { Endpoint, Http } from "Application";
import { ContactsListItemModel } from "../ContactsListItemModel";
import { ContactsListViewModel } from "../ContactsListViewModel";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";

class Request {
    orderBy: string = "";
    columnName: string = "";
    pageSize: number = 0;
    pageNumber: number = 1;
    filterByType: number[] | null = [];
    filterbyCustomer: Guid | null = null;
    filterByPlygeneTrained: string[] = [];
    filterByCoatingsTrained: string[] = [];

    searchString: string = "";
    public initialLoad: boolean = true;
}

export class ContactListAndRelatedResponse {
    contacts: ContactsListItemModel[] = [];
    contactCount: number = 0;
    customerTypesList: CustomerTypeDropdownItem[] = [];
    contactStatusTypes: ContactStatusDropdownItem[] = [];
}

export class GetContactListAndRelatedEndpoint extends Endpoint<Request, ContactListAndRelatedResponse> {
    private viewModel: ContactsListViewModel;

    constructor(viewModel: ContactsListViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Contact.GetContactListAndRelated);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new Request();
        request.columnName = this.viewModel.columnName;
        request.orderBy = this.viewModel.orderBy;
        request.pageSize = this.viewModel.pageSize;
        request.pageNumber = this.viewModel.pageNumber;
        request.filterByType = this.viewModel.filterByType;
        request.filterbyCustomer = this.viewModel.filterBycustomer;
        request.filterByPlygeneTrained = this.viewModel.filterByPlygeneTrained;
        request.filterByCoatingsTrained = this.viewModel.filterByCoatingsTrained;
        request.searchString = this.viewModel.searchString;
        request.initialLoad = this.viewModel.initialLoad;
        return request;
    }

    public async HandleResponseAsync(response: ContactListAndRelatedResponse): Promise<ContactListAndRelatedResponse> {
        this.viewModel.loadContacts(response.contacts);
        this.viewModel.loadCustomerTypesDropdown(response.customerTypesList);
        this.viewModel.contactCount = response.contactCount;
        this.viewModel.contactStatusTypes.replace(response.contactStatusTypes);
        return response;
    }
}
