import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ViewCustomerModel, ViewCustomerModelValidator } from "./ViewCustomerModel";
import { GetCustomerViewHeaderByIdEndpoint, GetCustomerViewHeaderByIdResponse } from "../Endpoints/GetCustomerViewHeaderById";
import { IDocumentType } from "Models/Documents/DocumentType";
import { SetCustomerStatusEndpoint, SetCustomerStatusResponse } from "../Endpoints/SetCustomerStatus";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";

export class ViewCustomerViewModel extends ViewModelBase<ViewCustomerModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public contactStatusTypes: IObservableArray<ContactStatusDropdownItem> = observable([]);
    public documentTypes: IObservableArray<IDocumentType> = observable([]);

    constructor() {
        super(new ViewCustomerModel());
        this.setValidator(new ViewCustomerModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            contactStatusTypes: observable,

            clear: action,
            getHeader: action,
            setCustomerStatus: action,

            getCustomerIdString: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.contactStatusTypes.clear();
        this.documentTypes.clear();
        this.model.clear();
    };

    public get getCustomerIdString(): string | string[] {
        return this.model.customerStatusId.toString();
    }

    public async getHeader(params: any): Promise<void> {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            if (params !== undefined) {
                this.isProcessing = true;
                const endpoint = new GetCustomerViewHeaderByIdEndpoint(params);
                await this.apiClient.sendAsync(endpoint);

                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        let response: GetCustomerViewHeaderByIdResponse = this.apiClient.Response();
                        this.model.fromResponse(response);
                        this.contactStatusTypes.replace(response.contactStatusTypes);
                        this.documentTypes.replace(response.documentTypes);

                        this.isProcessing = false;
                    });
                } // else show error message
                else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("View customer");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to get the customer details.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
    }

    public setCustomerStatus = async (value: KeyValuePair) => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false && parseInt(value.key) !== this.model.customerStatusId) {
            this.setValue("customerStatusId", parseInt(value.key));
            this.isProcessing = true;
            const endpoint = new SetCustomerStatusEndpoint();
            await this.apiClient.sendAsync(endpoint, this.model);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: SetCustomerStatusResponse = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.isProcessing = false;
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View customer");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get update the customer status.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };
}
