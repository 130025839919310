import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { CoreStoreInstance, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { TSMNotesModalModel, TSMNotesModalModelValidator } from "./TSMNotesModalModel";
import { TSMListViewModel } from "Views/TSM/TSMListViewModel";
import { TSMNotesUpsertEndpoint, TSMNotesUpsertResponse, TsmNoteListItem } from "./Endpoints/TSMNotesUpsertEndpoint";
import { TSMNotesGetListByIdEndpoint, TSMNotesGetListByIdResponse } from "./Endpoints/TSMNotesGetListByIdEndpoint";
import { DeleteTsmNoteByIdEndpoint, DeleteTsmNoteByIdResponse } from "./Endpoints/DeleteTsmNoteByIdEndpoint";

export class TSMNotesModalViewModel extends ViewModelBase<TSMNotesModalModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    public projectQuoteIdSelected: Guid = "";

    public parentViewModel: TSMListViewModel;

    public tsmNoteList: TsmNoteListItem[] = [];

    public noteInEditMode: number | null = null;

    public projectNumber: string = "";
    public projectName: string = "";

    constructor(parentViewModel: TSMListViewModel) {
        super(new TSMNotesModalModel());
        this.parentViewModel = parentViewModel;
        this.setValidator(new TSMNotesModalModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            tsmNoteList: observable,
            noteInEditMode: observable,
            projectName: observable,
            projectNumber: observable,

            upsertTsmNote: action,
            editNoteCommand: action,
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public setNewNote: ICommand = new RelayCommand((value: string) => {
        this.setValue("note", value);
    });

    public addNote: ICommand = new RelayCommand(() => {
        if (this.isModelValid()) {
            let selectedRow = this.parentViewModel.model.tsmList.find((row) => row.id === this.parentViewModel.selectedRowId);
            if (selectedRow) {
                this.model.tsmId = this.parentViewModel.tsmId;
                this.model.projectId = selectedRow.id;
            }
            this.upsertTsmNote(this.model);
        }
    });

    public saveNote = (editedModel: TSMNotesModalModel) => {
        let selectedRow = this.parentViewModel.model.tsmList.find((row) => row.id === this.parentViewModel.selectedRowId);
        let model = editedModel;
        if (selectedRow) {
            model.tsmId = this.parentViewModel.tsmId;
            model.projectId = selectedRow.id;
        }
        this.upsertTsmNote(model);
    };

    public upsertTsmNote = async (model: TSMNotesModalModel): Promise<TSMNotesUpsertResponse> => {
        const endpoint: TSMNotesUpsertEndpoint = new TSMNotesUpsertEndpoint();
        let retVal: TSMNotesUpsertResponse = new TSMNotesUpsertResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, model);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: TSMNotesUpsertResponse = this.apiClient.Response();

                    this.tsmNoteList = response.tsmNoteList;

                    this.isProcessing = false;
                    this.noteInEditMode = null;
                    this.setValue("note", "");
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("TSM Notes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to upsert the tsm note.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public LoadNoteList = async (projectId: Guid): Promise<TSMNotesGetListByIdResponse> => {
        const endpoint: TSMNotesGetListByIdEndpoint = new TSMNotesGetListByIdEndpoint(projectId);
        let retVal: TSMNotesUpsertResponse = new TSMNotesUpsertResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: TSMNotesUpsertResponse = this.apiClient.Response();

                    this.tsmNoteList = response.tsmNoteList;
                    this.projectName = response.projectDetails.projectName;
                    this.projectNumber = response.projectDetails.number;

                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("TSM Notes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the tsm note list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public deleteNoteCommand = (e: any, id: any) => {
        if (id) {
            this.deleteNoteById(id, this.parentViewModel.selectedRowId);
        }
    };

    public deleteNoteById = async (id: number, projectId: Guid): Promise<DeleteTsmNoteByIdResponse> => {
        const endpoint: DeleteTsmNoteByIdEndpoint = new DeleteTsmNoteByIdEndpoint(id, projectId);
        let retVal: DeleteTsmNoteByIdResponse = new DeleteTsmNoteByIdResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: TSMNotesUpsertResponse = this.apiClient.Response();

                    this.tsmNoteList = response.tsmNoteList;

                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("TSM Notes");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the tsm note.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public editNoteCommand = (e: any, id: any) => {
        if (id) {
            this.noteInEditMode = Number(id);
        }
    };
}
