import { makeObservable, action, observable, IObservableArray, computed, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { PropertyMasterProjectListItem } from "../Models/PropertyMasterProjectListItem";
import { StatusDropdownItem } from "Models/DropdownItem";
import { StatusEnum, StatusEnumHelper } from "Models/Project/StatusEnum";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { PropertyLinkedMasterModel } from "./PropertyLinkedMasterModel";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { GetPropertyLinkedMasterAndRelatedEndpoint, PropertyLinkedMasterAndRelatedResponse } from "./Endpoints/GetPropertyLinkedMasterAndRelatedEndpoint";
import { DeletePropertyMasterProjectByIdEndpoint } from "./Endpoints/DeletePropertyMasterProjectByIdEndpoint";

export class MasterProjectViewModel extends ViewModelBase<PropertyLinkedMasterModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public initialLoad: boolean = true;
    public isProcessing: boolean = false;

    public filterByStatus: string[] = [];
    public statusDropdownItems: StatusDropdownItem[] = StatusEnumHelper.getStatusDropdownOptions();

    public statuses: KeyValuePairExtended[] = StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open);

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "displayName";

    //
    public filterStatuses: IObservableArray<KeyValuePairExtended> = observable([]);

    // Pagination
    public customerCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageCount: number = 0;
    public pageNumber: number = 1;
    public totalCount: number = 0;

    // Deleting property

    public areYouSureModelOpen: boolean = false;
    public propertyMasterToDeleteId: Guid = "";

    private masterProjectCountCallBack: (newCount: number) => void;

    constructor(propertyId: string, masterProjectCountCallBack: (newCount: number) => void) {
        super(new PropertyLinkedMasterModel());
        this.masterProjectCountCallBack = masterProjectCountCallBack;
        this.filterStatuses.replace(StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open));
        this.statusSelectAll();
        this.model.propertyId = propertyId!;
        makeObservable(this, {
            filterByStatus: observable,
            isProcessing: observable,
            pageSize: observable,
            pageNumber: observable,
            totalCount: observable,
            statusDropdownItems: observable,
            areYouSureModelOpen: observable,
            propertyMasterToDeleteId: observable,

            clear: action,
            updateStatusFiltering: action,
            statusSelectAll: action,
            statusSelectNone: action,
            confirmDeleteProperty: action,
            deletePropertyCheck: action,

            getStatusFilter: computed,
            getStatuses: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.orderBy = SortOrderDirection.ASC;
        this.columnName = "displayName";
        this.pageNumber = 10;
        this.pageCount = 1;
        this.totalCount = 0;
    };

    public get IsBusy(): boolean {
        return this.apiClient.IsBusy;
    }

    public get getLinkedMasterProjectListItem(): PropertyMasterProjectListItem[] {
        return this.model.linkedMasterProjectsList.slice();
    }

    public get getStatuses(): KeyValuePairExtended[] {
        return this.filterStatuses.slice();
    }
    public get getStatusFilter(): string | string[] {
        return this.filterByStatus.slice();
    }

    public updateStatusFiltering = (values: string[]) => {
        this.filterByStatus = values;
    };

    public statusSelectAll = () => {
        this.filterByStatus = this.filterStatuses.map((item) => {
            return item.key.toString();
        });
    };

    public statusSelectNone = () => {
        this.filterByStatus = [];
    };

    public loadLinkedMasterProjectList(linkedMasterProjectsList: PropertyMasterProjectListItem[]) {
        this.model.linkedMasterProjectsList.clear();
        this.customerCount = 0;
        this.model.linkedMasterProjectsList.replace(linkedMasterProjectsList);
    }

    public getLinkedMasterProject = async (): Promise<PropertyLinkedMasterAndRelatedResponse> => {
        const endpoint = new GetPropertyLinkedMasterAndRelatedEndpoint();
        let retVal: PropertyLinkedMasterAndRelatedResponse = new PropertyLinkedMasterAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.model.linkedMasterProjectsList.clear();
                    this.isProcessing = false;
                    let response: PropertyLinkedMasterAndRelatedResponse = this.apiClient.Response();
                    this.loadLinkedMasterProjectList(response.masterProjects);
                    this.masterProjectCountCallBack(this.model.linkedMasterProjectsList.length);
                    this.totalCount = response.propertyCount;
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                    if (this.initialLoad === true) {
                        this.initialLoad = false;
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View linked master");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the linked master.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    // delete Property

    public deletePropertyCheck = (e: any, id: string): void => {
        this.propertyMasterToDeleteId = id;
        this.areYouSureModelOpen = true;
    };

    public confirmDeleteProperty = () => {
        this.deleteMasterProject();
    };

    public closeAreYouSureModal = () => {
        this.areYouSureModelOpen = false;
    };

    public async deleteMasterProject(): Promise<void> {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            const endpoint = new DeletePropertyMasterProjectByIdEndpoint();
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.pageCount = Math.ceil(this.customerCount / this.pageSize);
                    this.isProcessing = false;
                    this.areYouSureModelOpen = false;
                    this.getLinkedMasterProject();
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Master Project");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the master project.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    // end delete Property

    public clearFiltering: ICommand = new RelayCommand(() => {
        this.filterByStatus = [];
        this.initialLoad = true;
        this.filterStatuses.replace(StatusEnumHelper.getStatusOptionsForListView(StatusEnum.Open));
        this.statusSelectAll();
        this.getLinkedMasterProject();
    });

    public changePageNumber = (pageNumber: number) => {
        this.pageNumber = pageNumber;
        this.getLinkedMasterProject();
    };
}
