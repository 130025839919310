import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { EligibleTemplateModel } from "../EligibleTemplateModel";

class GetEligibleTemplatesForConditionReportRequest {
    public id: string = "";

    public constructor() {}
}

export class GetEligibleTemplatesForConditionReportResponse extends EligibleTemplateModel {
    public constructor() {
        super();
    }
}

export class GetEligibleTemplatesForConditionReportEndpoint extends Endpoint<GetEligibleTemplatesForConditionReportRequest, GetEligibleTemplatesForConditionReportResponse[]> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReport.GetEligibleTemplatesForConditionReport);
    }

    public async HandleRequestAsync(model: string): Promise<any> {
        let request = new GetEligibleTemplatesForConditionReportRequest();
        request.id = model;
        return request;
    }

    public async HandleResponseAsync(response: GetEligibleTemplatesForConditionReportResponse[]): Promise<GetEligibleTemplatesForConditionReportResponse[]> {
        Logger.logInformation("Response", response.length);

        //Return your model
        return response;
    }
}
