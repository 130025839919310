import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TsmNoteListItem } from "./TSMNotesUpsertEndpoint";
import { TSMProjectDetails } from "../../ModalCommon/TSMModalCommon";

class DeleteTsmNoteByIdRequest {
    public id: number = -1;
    public projectId: Guid = "";
}

export class DeleteTsmNoteByIdResponse {
    public tsmNoteList: TsmNoteListItem[] = [];
    public projectDetails: TSMProjectDetails = new TSMProjectDetails();
}

export class DeleteTsmNoteByIdEndpoint extends Endpoint<DeleteTsmNoteByIdRequest, DeleteTsmNoteByIdResponse> {
    public id: number;
    public projectId: Guid;

    constructor(id: number, projectId: Guid) {
        super();
        this.id = id;
        this.projectId = projectId;

        this.verb(Http.Delete);
        this.path(AppUrls.Server.Tsm.DeleteNoteById);
    }

    public async HandleRequestAsync(): Promise<any> {
        let request = new DeleteTsmNoteByIdRequest();
        request.id = this.id;
        request.projectId = this.projectId;

        return request;
    }

    public async HandleResponseAsync(response: DeleteTsmNoteByIdResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
