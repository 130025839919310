import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ForgotPasswordModel extends ModelBase<ForgotPasswordModel> {
    public emailAddress: string = "";

    constructor() {
        super();
        makeObservable(this, {
            emailAddress: observable,
        });
    }
}

export class ForgotPasswordValidator extends Validator<ForgotPasswordModel> {
    constructor() {
        super();
        this.ruleFor("emailAddress").notEmpty().withMessage("Please enter emailAddress").emailAddress().withMessage("Please enter a valid emailAddress").maxLength(100);
    }
}
