import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Box, ErrorExclamationView, ICommand, Input, MaskedOptions, RelayCommand, theme, themeShapeOptions, themeSizeOptions } from "Application";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { FieldLabel, InputWrapper } from "../CommonStyles";
import { IMaskInput } from "react-imask";

import CalendarSVG2 from "Assets/Icons/dateIconSharmans.svg";
import styled from "@emotion/styled";
/**
 * Edit Date interface.
 */
interface IEditDateBaseProps {
    /**
     * An optional class name for use with the button.
     */
    className?: string;
    /**
     * A command to execute.
     */
    command: ICommand;
    /**
     * A value to use with the control. Will be passed back by the command.
     */
    value: Date | undefined;
    /**
     * Text content to display as the label of the date control.
     */
    displayName?: string;
    /**
     * Text content to display in the placeholder.
     */
    placeholder?: string;
    /**
     * Text content to display in the error message.
     */
    validationMessage?: () => string;
    /**
     * Styling of the control.
     */
    style?: React.CSSProperties;
    /**
     * The size of the control - use this if using generic control.
     */
    size?: themeSizeOptions;
    /**
     * The shape of the control - use this if using the generic control.
     */
    shape?: themeShapeOptions;
    /**
     * Any JSX Element
     */
    prefix?: JSX.Element;
    /**
     * Any JSX Element
     */
    suffix?: JSX.Element;
    /**
     * The control type - password or text.
     */
    maxDate?: Date;
    cy?: string;
    initialDate?: Date;
    includeTime?: boolean;
    timePicker?: boolean;
    dateFormat?: string;
}

export const EditDate: React.FC<IEditDateBaseProps> = observer((props) => {
    // #region DatePicker Code Behind
    const [internalString, setInternalString] = React.useState<string | null>(null);
    const [internalDate, setInternalDate] = React.useState<moment.Moment | string | undefined | null>();
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    const [openCalendar, setOpenCalendar] = useState(false);
    const datetimeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (datetimeRef.current && !datetimeRef.current.contains(event.target as Node)) {
                setOpenCalendar(false);
            }
        };
        window.addEventListener("mousedown", handleOutsideClick);
        window.addEventListener("keydown", (event) => {
            if (event.keyCode === 9) {
                setOpenCalendar(false);
            }
        });
        return () => {
            window.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const a = moment(props.value);
        const b = moment(props.value)!.format(props.dateFormat || theme.dates.default);
        if (props.value !== null && props.value !== undefined) {
            setInternalDate(a);
            setInternalString(b);
        } else {
            setInternalDate(null);
            setInternalString("");
        }
    }, []);

    useEffect(() => {
        if (props.value !== null && props.value !== undefined) {
            setInternalDate(moment(props.value));
            setInternalString(moment(props.value)!.format(props.dateFormat || theme.dates.default));
        } else {
            setInternalDate(null);
            setInternalString("");
        }
    }, [props.value]);

    const onSelectDate = (date: moment.Moment | string): void => {
        props.command.execute(isNullOrUndefined(date) ? null : moment(date).toDate());
    };

    // #endregion DatePicker Code Behind

    // #region Code Behind

    const getClassNames = () => {
        return clsx({ ["drop-to-the-right"]: true, [props.className!]: !isEmptyOrWhitespace(props.className) });
    };

    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getValue = (): moment.Moment | string | undefined | null => {
        return internalDate;
    };
    const canDisplayName = (): boolean => {
        return !isEmptyOrWhitespace(props.displayName);
    };
    const updateValue = (newValue: any) => {
        setInternalString(newValue as string);
    };
    const updateDate = (newValue: any) => {
        setInternalString(newValue as string);
        // setOpenCalendar(false);
        newValue = newValue.replace(/ /g, "");
        if (newValue.length === 10) {
            const checkDate = moment(newValue, "DD/MM/YYYY");
            if (checkDate.isValid()) {
                setInternalDate(checkDate);
                props.command.execute(checkDate);
                setOpenCalendar(false);
            }
        }
    };
    const validateDate = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const checkDate = moment(internalString, "DD/MM/YYYY");
        if (checkDate.isValid()) {
            setInternalDate(checkDate);
            props.command.execute(checkDate);
            setOpenCalendar(false);
        } else {
            /*console.log("Date is not valid");
            setInternalString("");
            setInternalDate(null);
            props.command.execute(null);*/
        }
    };
    const canDisplayClear = (): boolean => {
        if (!isNullOrUndefined(props.value)) {
            return !isEmptyOrWhitespace(internalString);
        }
        return false;
    };
    const clearDateCommand = new RelayCommand(() => {
        setInternalString(null);
        setInternalDate(null);
        props.command.execute(null);
    });

    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName || "").replace(regex, "");
        return props.cy || result;
    };

    // #endregion Code Behind
    const renderInput = (dateProps: any, openCalendar: any, closeCalendar: any) => {
        return (
            <IMaskInput
                {...(MaskedOptions.Date(props.dateFormat) as any)}
                onAccept={(value, mask) => {
                    updateDate(mask.value);
                }}
                onFocus={() => setOpenCalendar(true)}
                className={getClassNames()}
                onChange={updateDate}
                placeholder={props.placeholder}
                value={internalString}
                data-cy={getDataCY()}
                disabled={props.command === undefined ? false : !props.command!.canExecute()}
            />
        );
    };
    return (
        <InputWrapper>
            <Box flexBox>
                <FieldLabel className="placeholder">{props.displayName}</FieldLabel>
                <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />
            </Box>
            <Box className={"inputbox"}>
                <div ref={datetimeRef}>
                    <DateWrapperBox>
                        <Datetime
                            open={openCalendar}
                            renderInput={renderInput}
                            className={getClassNames()}
                            onChange={onSelectDate}
                            value={getValue() as any}
                            closeOnSelect={true}
                            dateFormat={props.timePicker ? false : theme.dates.default}
                            timeFormat={props.includeTime}
                        />
                    </DateWrapperBox>
                </div>
                <Box
                    mr={1}
                    onClick={() => {
                        if (props.command === undefined || props.command!.canExecute() === false) {
                            return;
                        }

                        setOpenCalendar(!openCalendar);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <img src={CalendarSVG2} />
                </Box>
            </Box>
        </InputWrapper>
    );
});

const DateWrapperBox = styled.div`
    input {
        width: 100%;
        display: flex;
        width: 100%;
        gap: 0;
    }
`;
