import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class TSMDocumentItemModel extends ModelBase<TSMDocumentItemModel> {
    // public id: string = "";
    projectId: Guid = ""; // TODO!! CHANGE!!!!
    documentCategoryId: number = 0;
    filename: string = "";
    // uploadedBy: Guid = "";
    // dateCreated: Date = new Date();
    // isDeleted: number = 0;
    // deletedBy: Guid = "";
    blobName: string = "";
    file: File | undefined = undefined;
    fileBase64: string = "";
    fileType: string = "";

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
            projectId: observable,
            documentCategoryId: observable,
            filename: observable,
            // uploadedBy: observable,
            // dateCreated: observable,
            // isDeleted: number = 0;
            // deletedBy: observable,
            blobName: observable,
            file: observable,
        });
    }

    public clear = () => {
        this.id = "";
    };
}

export class TSMDocumentItemModelValidator extends Validator<TSMDocumentItemModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("documentCategoryId").greaterThan(0).withMessage("Select document type");
    }
}
