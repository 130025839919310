import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ProjectQuoteViewModel } from "../ProjectQuoteViewModel";
import { AppUrls } from "AppUrls";

class DeletePropertyProjectQuoteByIdRequest {
    Id: Guid = "";
    propertyId: Guid = "";
}

class DeletePropertyProjectQuoteByIdResponse {}

export class DeletePropertyProjectQuoteByIdEndpoint extends Endpoint<DeletePropertyProjectQuoteByIdRequest, DeletePropertyProjectQuoteByIdResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.DeleteProjectQuoteById);
    }

    public async HandleRequestAsync(viewModel: ProjectQuoteViewModel): Promise<any> {
        let request = new DeletePropertyProjectQuoteByIdRequest();
        request.Id = viewModel.propertyProjectQuoteToDeleteId;
        request.propertyId = viewModel.model.propertyId;
        return request;
    }

    public async HandleResponseAsync(response: DeletePropertyProjectQuoteByIdResponse): Promise<any> {
        return response;
    }
}
