import { action, makeObservable, runInAction } from "mobx";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { AddEditBaseViewModel } from "./AddEditBaseViewModel";
import { TasksModel } from "Models/Tasks/TasksModel";
import { MasterProjectTasksUpsertEndpoint } from "../Endpoints/MasterProjectTasksUpsertEndpoint";
import { ITasksRelatedResponse } from "Models/Tasks/ITasksRelatedResponse";
import { GetTasksRelatedForMasterProjectEndpoint } from "../Endpoints/GetTasksRelatedForMasterProjectEndpoint";
import { ITaskWithRelatedResponse } from "Models/Tasks/TaskWithRelatedResponse";
import { SharmanTaskTypeEnum } from "Models/Tasks/TaskTypeEnum";

export class MasterProjectTasksAddEditViewModel extends AddEditBaseViewModel {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    constructor() {
        super();
        makeObservable(this, { upsertAsync: action, getTasksRelatedForProject: action, getTasks: action });
    }

    public upsertAsync = async (masterProjectId: string): Promise<any> => {
        let retVal: TasksModel = this.model;

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: MasterProjectTasksUpsertEndpoint = new MasterProjectTasksUpsertEndpoint();
                this.isProcessing = true;
                this.setValue("sourceId", masterProjectId);
                this.setValue("taskTypeId", SharmanTaskTypeEnum.MasterProject);
                let _ = await this.apiClient.sendAsync(endpoint, this.model);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: TasksModel = this.apiClient.Response();
                        this.model.fromResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Task");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to upsert tasks.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };

    public getTasksRelatedForProject = async (masterProjectId: string): Promise<any> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: GetTasksRelatedForMasterProjectEndpoint = new GetTasksRelatedForMasterProjectEndpoint(masterProjectId);
                this.isProcessing = true;

                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: ITasksRelatedResponse = this.apiClient.Response();

                        this.processRelatedResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Tasks");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to get tasks related data.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return Promise.resolve();
    };

    public getTasks = async (complaintId: string): Promise<ITaskWithRelatedResponse> => {
        let retVal: ITaskWithRelatedResponse = {
            sharmanTask: new TasksModel(),
            taskSubTypeDropdownItem: [],
            userDropdownItem: [],
        };

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            {
                let endpoint: GetTasksRelatedForMasterProjectEndpoint = new GetTasksRelatedForMasterProjectEndpoint(complaintId);
                this.isProcessing = true;
                let _ = await this.apiClient.sendAsync(endpoint);
                if (this.apiClient.IsRequestSuccessful) {
                    runInAction(() => {
                        this.isProcessing = false;
                        let response: ITaskWithRelatedResponse = this.apiClient.Response();
                        this.processTaskWithRelatedResponse(response);
                    });
                } else {
                    runInAction(() => {
                        this.isProcessing = false;
                    });
                    this.errorStore.setHeaderText("Task");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to get task and related data.  Please try again later.");
                    this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }
        return retVal;
    };
}
