import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "../Validation";
import { AddressViewModel } from "Views/Admin/Users/AddressViewModel";

export class TestModel extends ModelBase<TestModel> {
    public firstName: string = "testing";
    public lastName: string = "";
    public age: number | null = null;
    public amount: number | null = null;
    public userName: string = "";
    public phoneNumber: string = "";
    public emailAddress: string = "";
    public password: string = "";
    public gender: string = "";
    public team: string = "";
    public teams: string[] = [];
    public terms: boolean = false;
    public addressViewModel: AddressViewModel = new AddressViewModel();
    public memo: string = "";
    public dob: Date = new Date();

    public primaryCounter: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            firstName: observable,
            lastName: observable,
            age: observable,
            amount: observable,
            password: observable,
            phoneNumber: observable,
            userName: observable,
            emailAddress: observable,
            team: observable,
            teams: observable,
            terms: observable,
            addressViewModel: observable,
            memo: observable,
            dob: observable,

            primaryCounter: observable,
        });
    }
}

export class TestModelValidator extends Validator<TestModel> {
    constructor() {
        super();

        this.ruleFor("team").notEmpty();
        this.ruleFor("teams").must({ predicate: (teams, model) => teams.length > 0, message: (value, model) => "Teams must have something" });
        this.ruleFor("firstName").notEmpty().maxLength(10);
        this.ruleFor("lastName").notEmpty().maxLength(10);
        this.ruleFor("password").notEmpty().maxLength(10);
        this.ruleFor("memo").notEmpty().maxLength(100);
        this.ruleFor("terms").equal(true);
    }
}

export class AddressModel extends ModelBase<AddressModel> {
    public addressLine1: string = "";
    public postCode: string = "";

    constructor() {
        super();
        makeObservable(this, {
            addressLine1: observable,
            postCode: observable,
        });
    }
}

export class AddressModelValidator extends Validator<AddressModel> {
    constructor() {
        super();

        this.ruleFor("postCode").notEmpty().maxLength(7);
    }
}
