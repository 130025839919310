import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConditionReportPdfAndRelated } from "../Edit/ConditionReportPdfModel";

export class CreateNewPDFReportEndpointRequest {
    public conditionReportId: string = "";
    public templateId: string = "";

    public constructor() {}
}

export class CreateNewPDFReportEndpoint extends Endpoint<CreateNewPDFReportEndpointRequest, ConditionReportPdfAndRelated> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportPdf.CreateNew);
    }

    public async HandleRequestAsync(request: CreateNewPDFReportEndpointRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: ConditionReportPdfAndRelated): Promise<ConditionReportPdfAndRelated> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
