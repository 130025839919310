import { Box } from "Application";
import styled from "@emotion/styled";
import { BaseWrapper } from "Components/BaseWrapper";

export const PropertiesListWrapper = styled(BaseWrapper)``;

export const GreyAddOccupierBox = styled(Box)`
    background-color: #ededed;
    height: 375px;
`;

export const WhiteScrollBox = styled(Box)`
    background-color: white;
    overflow-y: auto;
    height: 286px;
    margin: 2px;
`;

export const AddOccupierTopBox = styled(Box)`
    min-height: 87px;
    padding: 9px;
`;
