import React from "react";
import { observer } from "mobx-react-lite";
import { Button, ICommand, Input, RelayCommand } from "Application";
import { Modal } from "@fluentui/react";
import { ButtonBox, ActionModalBody, ActionModalHeader, ActionModalWrapper, HeaderBox, ButtonRow } from "./AddActionModal.styles";
import { P } from "Components/Primitives/TextElements/TextElements";
import ErrorIcon from "Assets/Icons/warning_icon_1.svg";
import CloseIcon from "Assets/close_icon.svg";
import AddIcon from "Assets/Icons/AddSquareIcon.svg";
import { CoreStoreInstance } from "@shoothill/core";

export interface IAddEditActionModal {
    title: string;
    inputCommand: ICommand<any>;
    inputValue: () => string | number;
    buttonText: string;
    isOpen: boolean;

    onConfirm: () => void;
    validationMessage?: () => string;
    onCancel: () => void;
}

export const AddEditActionModal: React.FC<IAddEditActionModal> = observer(({ title, inputCommand, inputValue, validationMessage, buttonText, onConfirm, onCancel, isOpen }) => {
    const handleConfirmButtonClick: ICommand = new RelayCommand(() => {
        onConfirm();
    });

    const handleCancelButtonClick: ICommand = new RelayCommand(() => {
        onCancel();
        CoreStoreInstance.HideInfoBar();
    });

    return (
        <>
            <Modal isOpen={isOpen}>
                <ActionModalWrapper>
                    <ActionModalHeader>
                        <HeaderBox>
                            <img src={AddIcon} height="20px" />
                            <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                {title}
                            </P>
                        </HeaderBox>
                        <HeaderBox style={{ cursor: "pointer" }} onClick={onCancel}>
                            <img src={CloseIcon} />
                        </HeaderBox>
                    </ActionModalHeader>

                    <ActionModalBody>
                        <Input command={inputCommand} rows={5} value={inputValue} displayName="Action*:" multiline={true} autoFill={false} validationMessage={validationMessage} />
                        <ButtonRow>
                            <ButtonBox pt="30px">
                                <Button command={handleConfirmButtonClick} displayName={buttonText} boxShadow={true} paletteColor="ButtonGreen" />
                                <Button className="last-button" command={handleCancelButtonClick} displayName="Cancel" boxShadow={true} paletteColor="ButtonBlue" />
                            </ButtonBox>
                        </ButtonRow>
                    </ActionModalBody>
                </ActionModalWrapper>
            </Modal>
        </>
    );
});
