import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { CustomerNoteDocumentItemModel } from "../NewCustomer/CustomerNotes/CustomerNoteDocumentItemModel";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { AppUrls } from "AppUrls";

class UploadNoteDocumentRequest {
    noteId: Guid = "";
    documentCategoryId: number = 0;
    fileName: string = "";
    fileBase64: string = "";
    fileType: string = "";
}

export class UploadNoteDocumentEndpoint extends Endpoint<UploadNoteDocumentRequest, IDocumentUploaded[]> {
    private model: CustomerNoteDocumentItemModel;
    constructor(model: CustomerNoteDocumentItemModel) {
        super();
        this.model = model;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.UploadNoteDocument);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new UploadNoteDocumentRequest();
        request.noteId = this.model.noteId;
        request.documentCategoryId = this.model.documentCategoryId;
        request.fileName = this.model.filename;
        request.fileBase64 = this.model.fileBase64;
        request.fileType = this.model.fileType;

        return request;
    }

    public async HandleResponseAsync(response: IDocumentUploaded[]): Promise<IDocumentUploaded[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
