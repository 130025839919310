import { AssignedUserDropdownItem } from "./AssignedUserDropdownItem";
import { CompanyWhoReportedDropdownItem } from "./CompanyWhoReportedDropdownItem";
import { ComplaintCategoryDropdownItem } from "./ComplaintCategoryDropdownItem";
import { ComplaintNatureDropdownItem } from "./ComplaintNatureDropdownItem";
import { InstallationCompanyDropdownItem } from "./InstallationCompanyDropdownItem";
import { ProjectComplaintResponse } from "./ProjectComplaintReponse";

export interface IComplaintRelatedResponse {
    reportingCompanies: CompanyWhoReportedDropdownItem[];
    installationCompanies: InstallationCompanyDropdownItem[];
    complaintAssignees: AssignedUserDropdownItem[];
    complaintNatures: ComplaintNatureDropdownItem[];
    complaintCategories: ComplaintCategoryDropdownItem[];
    projectDetails: ProjectComplaintResponse;
}

export const getBlankComplaintRelatedResponse = (): IComplaintRelatedResponse => {
    return {
        reportingCompanies: [],
        installationCompanies: [],
        complaintAssignees: [],
        complaintNatures: [],
        complaintCategories: [],
        projectDetails: {
            projectId: "",
            siteAddress: "",
            orderAmount: 0,
        },
    };
};
