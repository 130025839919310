import styled from "@emotion/styled";
import { Box, theme } from "Application";
import { P } from "Components/Primitives/TextElements/TextElements";

export const TSMModalWrapper = styled(Box)`
    width: 676px;
    @media only screen and (max-width: 767px) {
        max-width: 676px;
        width: 100%;
    }
`;

export const TSMModalGreyBox = styled(Box)`
    background-color: #f3f3f3;
    padding: 15px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .editinput {
        resize: none !important;
    }
`;

export const TSMModalLargerText = styled(P)`
    font-size: ${theme.fontStyles.labelLarge.fontSize};
    font-weight: ${theme.fontStyles.labelLarge.fontWeight};
`;

export const TSMModalTableGrid = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 0.5fr;
    margin-bottom: 50px;
`;

export const TSMTableSectionWrapper = styled(Box)`
    padding: 15px 8px;
`;

export const TSMModalListText = styled(P)`
    padding: 16px 8px 16px 0px;
`;

export const TSMListHorizontalLine = styled.hr`
    grid-column: span 4;
    margin-top: 8px;
    border-top: 1px solid #d4d4d4;
`;

export const TSMListHorizontalLineDashed = styled.hr`
    grid-column: span 4;
    border-top: 1px dashed #d4d4d4;
`;

export const DocumentModalGreyBox = styled(Box)`
    padding: 9px;
    text-align: right;
`;

export const DateWrapperBox = styled(Box)`
    .rdtPicker {
        left: 0px !important;
    }
`;

export const CustomModalWithEditableHeder = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
`;

export const CustomModalInner = styled.div`
    box-shadow: rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px;
    border-radius: 2px;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    position: relative;
    text-align: left;
    outline: transparent solid 3px;
    max-height: calc(100% - 32px);
    max-width: calc(100% - 32px);
    min-height: 176px;
    min-width: 288px;
    overflow-y: auto;
    .modalBody {
        padding: 0;
    }
`;
export const CustomModalFooter = styled.div`
    min-height: 58px;
    background-color: white;
    position: sticky;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 3px 0px 3px;
    button {
        font-family: inherit;
    }
`;
