import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ColumnResizeMode, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable, ColumnDef, getPaginationRowModel } from "@tanstack/react-table";
import {
    ButtonsWrapper,
    FilteringSectionBottom,
    FilteringSectionTop,
    ItemsPerPageWrapper,
    NextPrevText,
    PaginationButton,
    PaginationWrapper,
    TableWrapper,
} from "./SharmansTable.styles";

import Arrow from "Assets/Icons/TableSortArrow.svg";
import { EditSelect, ICommand, RelayCommand, ThemedLoader } from "Application";
import { P } from "../TextElements/TextElements";
import { DEFAULTTABLEPAGESIZES } from "Utils/Globals";
import { generateID } from "@shoothill/core";

import ArrowStart from "Assets/Icons/Pagination-start.png";
import ArrowPrevious from "Assets/Icons/Pagination-Previous.png";
import ArrowNext from "Assets/Icons/Pagination-Next.png";
import ArrowEnd from "Assets/Icons/Pagination-end.png";
import { ClearFilteringButton } from "./ClearFilteringButton";
import { ButtonImg } from "./SharmansTable.styles";

export enum SortOrderDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export enum PaginationDirection {
    NEXT = "NEXT",
    BACK = "BACK",
    START = "START",
    END = "END",
}

export interface ISharmansTableHeaderOptions {
    backgroundColour?: string;
    textColour?: string;
    fontFamily?: string[];
    fontSize?: string | number;
    borderTop?: string; // TODO should be the correct settings
    borderBottom?: string; // TODO should be the correct settings
}

export interface ISharmansTableRowOptions {
    backgroundColour?: string;
    textColour?: string;
    fontFamily?: string[];
    fontSize?: string | number;
    borderTop?: string; // TODO should be the correct settings
    borderBottom?: string; // TODO should be the correct settings
}

export interface ISharmansTable {
    // Data
    columns: ColumnDef<any, any>[];
    data: any[];

    // Styling
    showFooter?: boolean;
    showPagination?: boolean;
    tableHeaderColour?: string; // TODO Should use ISharmansTableHeaderOptions
    tableFilteringColour?: string; // TODO Should use ISharmansTableHeaderOptions

    // Pagination
    pageNumber?: number; // Current Page Number
    pageSize?: number; // Current Page size (if we have all the data)
    pageSizeOptions?: number[];

    totalPageCount?: number; // The total number of pages.
    totalRowCount?: number; // Total number of rows for this query

    initialSortColumn?: string;
    initialSortDirection?: SortOrderDirection;

    initialPage?: number; // TODO

    stickyTop?: number;

    // Filtering
    showFiltering?: boolean;
    filteringElementsTopSection?: ReactNode;
    filteringElementsBottomSection?: ReactNode;

    // Sorting
    manualSorting?: boolean;

    // Operations
    onRowClick?: (rowId: string | number) => void;

    onSortChange?: (orderedColumnId: string, orderDirection: SortOrderDirection) => void;

    onChangePage?: (change: PaginationDirection) => void;
    onChangePageNumber?: (pageNumber: number) => void; //This is for manually entering a page number
    onChangeRowPerPage?: (numberRows: number) => void;

    isProcessing?: boolean;

    clearFilteringCommand?: ICommand<any>;

    getTrClassesCallback?: (id: any) => string;
}

export const SharmansTable: React.FC<ISharmansTable> = observer((props) => {
    const [columnResizeMode, setColumnResizeMode] = useState<ColumnResizeMode>("onChange");

    const [sorting, setSorting] = React.useState<SortingState>([]);
    // Internal state, so number can be updated without making a server call - that only happens on "Enter"
    const [paginationPageNumber, setPaginationPageNumber] = useState(1);

    let pageSizeOptions: number[] = DEFAULTTABLEPAGESIZES;
    if (props.pageSizeOptions !== undefined && props.pageSizeOptions !== null && props.pageSizeOptions.length > 0) {
        pageSizeOptions = props.pageSizeOptions;
    }

    let localRowCount: string = "0";

    /*     const tableVariable = document.getElementById("table");
    (tableVariable! as any).floatThead(); */

    if (props.data.length > 0 && props.pageNumber !== undefined) {
        let a: number = props.data.length * (props.pageNumber - 1) + 1;
        let b: number = props.data.length * props.pageNumber;

        if (props.pageNumber > 1) {
            a = (props.pageNumber - 1) * (props.pageSize !== undefined ? props.pageSize! : 0) + 1;
            b = (props.pageNumber - 1) * (props.pageSize !== undefined ? props.pageSize! : 0) + props.data.length;
        }

        localRowCount = a.toString() + "-" + b.toString();
    }

    useEffect(() => {
        if (sorting != undefined && sorting.length > 0 && props.onSortChange !== undefined) {
            let columnName = sorting[0].id;
            let sortDirection = sorting[0].desc === true ? SortOrderDirection.DESC : SortOrderDirection.ASC;
            props.onSortChange(columnName, sortDirection);
        }
    }, [sorting]);

    useEffect(() => {
        if (props.initialSortColumn !== undefined && props.initialSortDirection !== undefined) {
            setSorting([
                {
                    id: props.initialSortColumn,
                    desc: props.initialSortDirection ? false : true,
                },
            ]);
        }
    }, []);

    useEffect(() => {
        setPaginationPageNumber(props.pageNumber ? props.pageNumber : 1);
    }, [props.pageNumber]);

    const onChangeRowPerPageEditSelect: ICommand = new RelayCommand((value) => {
        if (props.onChangeRowPerPage) {
            props.onChangeRowPerPage(Number(value.key));
        }
    });

    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        columnResizeMode,
        state: {
            sorting,
        },
        initialState: {
            pagination: {
                pageSize: props.pageSize ? props.pageSize : pageSizeOptions[0],
            },
        },
        pageCount: props.totalPageCount ? props.totalPageCount : 0,
        manualSorting: props.manualSorting !== undefined ? props.manualSorting : true,
        manualPagination: true,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        enableSortingRemoval: false,
    });

    const onPageNumberChangeEnter = () => {
        if (props.onChangePageNumber) {
            const pageCount = Number(table.getPageCount());
            let newPageNumber = paginationPageNumber;

            if (paginationPageNumber > pageCount) {
                newPageNumber = pageCount;
            } else if (paginationPageNumber < 1) {
                newPageNumber = 1;
            }

            setPaginationPageNumber(newPageNumber);
            props.onChangePageNumber(newPageNumber);
        }
    };

    const Pagination = (table: any) => {
        return (
            <PaginationWrapper pb="15px">
                <ItemsPerPageWrapper>
                    <span className="flex items-center gap-1">
                        <P pt="4px">Items per page: </P>
                    </span>
                    <EditSelect
                        value={() => String(props.pageSize)}
                        options={pageSizeOptions.map((option) => {
                            return { key: String(option), text: String(option) };
                        })}
                        command={onChangeRowPerPageEditSelect}
                    />
                    <P pt="4px">
                        {localRowCount} of {props.totalRowCount} items
                    </P>
                </ItemsPerPageWrapper>

                <ButtonsWrapper>
                    <PaginationButton
                        isStartOrEnd={props.pageNumber === 1}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== 1 ? props.onChangePage(PaginationDirection.START) : null)}
                        disabled={!table.getCanNextPage()}
                    >
                        <ButtonImg src={ArrowStart} height="10px" isStartOrEnd={props.pageNumber === 1} />
                    </PaginationButton>
                    <PaginationButton
                        isStartOrEnd={props.pageNumber === 1}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== 1 ? props.onChangePage(PaginationDirection.BACK) : null)}
                    >
                        <ButtonImg src={ArrowPrevious} height="10px" isStartOrEnd={props.pageNumber === 1} />
                    </PaginationButton>
                    <NextPrevText
                        pr="10px"
                        isStartOrEnd={props.pageNumber === 1}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== 1 ? props.onChangePage(PaginationDirection.BACK) : null)}
                    >
                        Previous
                    </NextPrevText>
                    <input
                        className="PaginationInput"
                        // type="number"
                        // defaultValue={table.getState().pagination.pageIndex + 1}
                        value={paginationPageNumber === 0 ? "" : paginationPageNumber}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onPageNumberChangeEnter();
                            }
                        }}
                        onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                                setPaginationPageNumber(Number(e.target.value));
                            }
                        }}
                        style={{ maxWidth: "80px", paddingLeft: "5px" }}
                    />
                    <P p="10px">of {table.getPageCount()}</P>
                    <NextPrevText
                        pl="10px"
                        isStartOrEnd={props.pageNumber === table.getPageCount()}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== table.getPageCount() ? props.onChangePage(PaginationDirection.NEXT) : null)}
                    >
                        Next
                    </NextPrevText>
                    <PaginationButton
                        isStartOrEnd={props.pageNumber === table.getPageCount()}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== table.getPageCount() ? props.onChangePage(PaginationDirection.NEXT) : null)}
                        disabled={!table.getCanNextPage()}
                    >
                        <ButtonImg src={ArrowNext} isStartOrEnd={props.pageNumber === table.getPageCount()} height="10px" />
                    </PaginationButton>
                    <PaginationButton
                        isStartOrEnd={props.pageNumber === table.getPageCount()}
                        onClick={() => (props.onChangePage != undefined && props.pageNumber !== table.getPageCount() ? props.onChangePage(PaginationDirection.END) : null)}
                        disabled={!table.getCanNextPage()}
                    >
                        <ButtonImg src={ArrowEnd} isStartOrEnd={props.pageNumber === table.getPageCount()} height="10px" />
                    </PaginationButton>
                </ButtonsWrapper>
            </PaginationWrapper>
        );
    };

    const FilteringTopSection = () => {
        return <FilteringSectionTop style={{ backgroundColor: "#fcfcfc" }}>{props.filteringElementsTopSection}</FilteringSectionTop>;
    };

    const FilteringBottomSection = () => {
        return (
            <FilteringSectionBottom style={{ position: "sticky", top: "6px", backgroundColor: props.tableFilteringColour ? props.tableFilteringColour : "#EDEDED", zIndex: 3 }}>
                {props.filteringElementsBottomSection}
                {props.clearFilteringCommand && <ClearFilteringButton clearFiltering={props.clearFilteringCommand} />}
            </FilteringSectionBottom>
        );
    };

    return (
        <TableWrapper stickyTop={props.stickyTop === undefined ? 110 : props.stickyTop}>
            <div className="p-2" style={{ width: "100%" }}>
                {props.filteringElementsTopSection && FilteringTopSection()}
                {props.filteringElementsBottomSection && FilteringBottomSection()}
                {props.isProcessing !== undefined && props.isProcessing === true ? (
                    <ThemedLoader isOpen={true} />
                ) : (
                    <>
                        <table
                            {...{
                                key: generateID(),
                                style: {
                                    width: table.getCenterTotalSize(),
                                },
                            }}
                        >
                            <thead style={{ backgroundColor: props.tableHeaderColour ? props.tableHeaderColour : "#D4D4D4" }} className="t-head">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <Fragment key={generateID()}>
                                                <th
                                                    {...{
                                                        key: header.id,
                                                        colSpan: header.colSpan,
                                                        style: {
                                                            width: header.getSize(),
                                                        },
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {header.isPlaceholder ? null : (
                                                        <div
                                                            {...{
                                                                key: generateID(),
                                                                className: header.column.getCanSort() ? "cursor-pointer select-none" : "",
                                                                onClick: header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            <div className="title-wrapper" key={generateID()}>
                                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                                <div className="arrow-box" key={generateID()}>
                                                                    {{
                                                                        // asc: " 🔼",
                                                                        // desc: " 🔽",
                                                                        asc: <img key={generateID()} src={Arrow} />,
                                                                        desc: <img key={generateID()} src={Arrow} style={{ transform: "rotate(180deg)" }} />,
                                                                    }[header.column.getIsSorted() as string] ?? null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        {...{
                                                            key: generateID(),
                                                            onMouseDown: header.getResizeHandler(),
                                                            onTouchStart: header.getResizeHandler(),
                                                            className: `resizer ${header.column.getIsResizing() ? "isResizing" : ""}`,
                                                            style: {
                                                                transform:
                                                                    columnResizeMode === "onEnd" && header.column.getIsResizing()
                                                                        ? `translateX(${table.getState().columnSizingInfo.deltaOffset}px)`
                                                                        : "",
                                                            },
                                                        }}
                                                    />
                                                </th>
                                            </Fragment>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map((row) => (
                                    <tr
                                        key={row.id}
                                        onClick={() => (props.onRowClick !== undefined ? props.onRowClick(row.original.id) : null)}
                                        className={props.getTrClassesCallback === undefined ? "" : props.getTrClassesCallback(row.original.id)}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot hidden={props.showFooter === undefined || props.showFooter === false}>
                                {table.getFooterGroups().map((footerGroup) => (
                                    <tr key={footerGroup.id}>
                                        {footerGroup.headers.map((header) => (
                                            <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</th>
                                        ))}
                                    </tr>
                                ))}
                            </tfoot>
                        </table>
                        {props.showPagination && Pagination(table)}
                    </>
                )}
            </div>
        </TableWrapper>
    );
});
