import * as Utils from "@shoothill/core";
import { BaseStore, CoreStoreInstance } from "@shoothill/core";

import { action, makeObservable, observable } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Application/Models";
import { container, singleton } from "tsyringe";
import { InitViewModel } from "Application/ViewModels/InitViewModel";
import { GlobalHistory, Logger } from "index";
import { CustomerStore } from "./CustomerStore";

export interface IAccountStore {
    init(stores: Stores, initialState: InitialState): Promise<void>;
    setIsLoggedIn(state: boolean): void;
    isInRole(role: string): boolean;
    Logout(redirect: boolean): Promise<void>;
    Logout(redirect: boolean): Promise<void>;
    getJwt(): string;
    getLoginState(jwt: string): void;
}

@singleton()
export class AccountStore extends BaseStore implements IAccountStore {
    customerStore = container.resolve(CustomerStore);
    public IsLoggedIn: boolean = false;
    public UserName: string = "";
    public DisplayName: string = "";
    public UserRoles: string[] = [];
    public initViewModel = new InitViewModel();
    public TSMId: number | null = null;
    public FirstName: string | null = null;
    public LastName: string | null = null;

    private jwt: string | null = null;
    public redirectToLogin: boolean = true;
    public constructor() {
        super();
        Logger.logDebug("Account store created");
        makeObservable(this, {
            IsLoggedIn: observable,
            UserName: observable,
            DisplayName: observable,
            UserRoles: observable,
            redirectToLogin: observable,
            //jwt: observable,

            setIsLoggedIn: action,
            Logout: action,
            getLoginState: action,
            setRedirectToLogin: action,
        });
    }

    //This gets called once from the Stores.ts file. We make sure this is called first as other stores depend on the login state
    public async init() {
        Logger.logDebug("Init account store");
        await this.initViewModel.getInitialState();
        await this.getLoginState(this.initViewModel.InitData.accountStatus.jwt as string);
    }

    public get initialState() {
        return this.initViewModel.InitData;
    }

    public setIsLoggedIn(state: boolean) {
        this.IsLoggedIn = state;
        CoreStoreInstance.SetLoggedIn(state);
    }

    public setRedirectToLogin(state: boolean) {
        this.redirectToLogin = state;
    }

    public isInRole = (role: string): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }
        return false;
    };

    public isCRUserOrAdmin = (): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes("ADMIN") || this.UserRoles.includes("CONDITIONREPORT");
        }
        return false;
    };

    // ONLY CR User
    public OnlyCRUser = (): boolean => {
        let retVal: boolean = false;
        if (this.UserRoles && this.UserRoles.length == 1) {
            retVal = this.UserRoles.includes("CONDITIONREPORT");
        }
        return retVal;
    };

    public loginRedirectToConditionReport = (): boolean => {
        let retVal: boolean = this.initialState.appSettings.conditionReportsOnly === true || this.OnlyCRUser() === true;
        return retVal;
    };

    public isCRMUserOrAdmin = (): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0 && this.initialState.appSettings.conditionReportsOnly === false) {
            return this.UserRoles.includes("ADMIN") || this.UserRoles.includes("CRM");
        }
        return false;
    };

    public isTSMOrAdmin = (): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0 && this.initialState.appSettings.conditionReportsOnly === false) {
            return this.UserRoles.includes("ADMIN") || this.UserRoles.includes("TSM");
        }
        return false;
    };

    public isCRUserOrCRMUserOrAdmin = (): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0 && this.initialState.appSettings.conditionReportsOnly === false) {
            return this.UserRoles.includes("ADMIN") || this.UserRoles.includes("CRM") || this.UserRoles.includes("CONDITIONREPORT");
        }
        return false;
    };

    public Logout = async (redirect: boolean = false): Promise<void> => {
        await Utils.deleteJWT();
        document.cookie = ".refreshtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        this.setIsLoggedIn(false);
        this.UserName = "";
        this.DisplayName = "";
        CoreStoreInstance.SetDisplayName("");
        this.UserRoles = [];
        //EN: Clear out all entries including cached items
        localStorage.clear();
        sessionStorage.clear();
        this.customerStore.clearMatrixOptions();

        if (this.redirectToLogin) {
            if (redirect) {
                window.location.href = "/";
            } else {
                GlobalHistory.push("/");
            }
        }
    };

    public getJwt = (): string => {
        return this.jwt as string;
    };

    public getLoginState = async (jwt: string | null) => {
        jwt = jwt ?? "";
        if (!jwt || jwt.length === 0) {
            this.jwt = await Utils.getJWT();
        } else {
            this.jwt = jwt;
        }

        if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
            const data = Utils.parseJwt(this.jwt);
            await Utils.setJWT(this.jwt);
            if (data === "") {
                return;
            }
            this.setIsLoggedIn(true);
            this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
            this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
            this.UserRoles = [].concat(data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || []);

            this.FirstName = data["FirstName"] as string | null;
            this.LastName = data["LastName"] as string | null;

            const tsmId: string | null = data["TSMId"] as string | null;
            this.TSMId = tsmId && tsmId.length > 0 ? parseInt(tsmId, 10) : null;
            CoreStoreInstance.SetDisplayName(this.DisplayName);
            // EN: For Debugging
            //(window as any).jwt = this.jwt;
            Logger.logDebug("Getloginstate finished", { jwt });

            if (this.customerStore.dataLoaded === false) {
                await this.customerStore.doAsync();
            }
        }
    };
}
