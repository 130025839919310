import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand, ThemedLoader } from "Application";
import { createColumnHelper } from "@tanstack/react-table";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { useNavigate, useParams } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import { ConditionReportPdfListViewModel } from "./ConditionReportPdfListViewModel";
import { formatDateTime } from "Utils/Formats";
import { BaseWrapper } from "Components/BaseWrapper";
import spyScope from "Assets/Icons/magnifyingGlass.svg";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { GetEligibleTemplateModal } from "./Modals/GetEligibleTemplateModal";
import { DownloadButton } from "Components/Primitives/Buttons/DownloadButton";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { ShowPdfModal } from "./Modals/ShowPDFModal";
import { FilteringTopSectionGenericView } from "Components/FilteringTopSectionGeneric";

export const ConditionReportPdfListView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ConditionReportPdfListViewModel());
    const [isEligibileModalOpen, setIsEligibleModalOpen] = useState<boolean>(false);
    const [isDeleteSectionOpen, setIsDeleteSectionOpen] = useState<boolean>(false);
    const [deleteSelectionId, setDeleteSelection] = useState<string>("");

    const [pdfId, setpdfId] = useState<string>("");
    const [isPdfViewerOpen, setPdfViewerOpen] = useState<boolean>(false);

    let navigate = useNavigate();

    let params = useParams();

    let conditionReportId: string = params.reportId ?? "new";

    useEffect(() => {
        viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const downloadPdfClick = (e: any, id: string) => {
        e.stopPropagation();
        let promise = viewModel.downloadPDF(id);

        promise.then((result: any) => {});
    };

    const viewPdfClick = (id: string) => {
        setpdfId(id);
        setPdfViewerOpen(true);
    };

    const cancelPdfModal = () => {
        setPdfViewerOpen(false);
        setpdfId("");
    };

    const deletePDFClick = (e: any, id: string): void => {
        e.stopPropagation();
        setDeleteSelection(id);
        setIsDeleteSectionOpen(true);
    };

    const cancelSectionDelete = () => {
        setIsDeleteSectionOpen(false);
    };

    const confirmSectionDelete = () => {
        let promise = viewModel.deletePDF(deleteSelectionId, conditionReportId);

        promise.then(() => {
            setIsDeleteSectionOpen(false);
            setDeleteSelection("");
        });
    };

    const columnHelper = createColumnHelper<any>();
    const columns = [
        columnHelper.accessor("templateNumber", {
            size: 80,
            header: () => "Template",
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("templateDate", {
            size: 60,
            header: () => "Template Created",
            cell: (info) => formatDateTime(new Date(info.renderValue())),
        }),
        columnHelper.accessor("createdDate", {
            size: 60,
            header: () => "Report Date",
            cell: (info) => formatDateTime(new Date(info.renderValue())),
        }),
        columnHelper.accessor("projectName", {
            size: 150,
            header: () => "Project Name",
            cell: (info) => info.renderValue(),
        }),
        columnHelper.accessor("siteVisitDate", {
            size: 60,
            header: () => "Site Visit Date",
            cell: (info) => info.renderValue(),
        }),
        columnHelper.accessor("id", {
            size: 60,
            header: () => <div style={{ maxWidth: "50px" }}>Actions</div>,
            cell: (info) => {
                return (
                    <div className="action-cell">
                        <div
                            style={{ maxWidth: "50px" }}
                            onClick={(e: any) => {
                                //e.stopPropagation() stops the ROW onclick firing!!!
                                e.stopPropagation();
                                viewPdfClick(info.getValue().toString());
                            }}
                        >
                            <img src={spyScope} style={{ cursor: "pointer" }} />
                        </div>

                        <DownloadButton id={info.getValue().toString()} onClick={downloadPdfClick} />

                        <DeleteButton id={info.getValue().toString()} onClick={deletePDFClick} />
                    </div>
                );
            },
        }),
    ];

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.ConditionReport.EditPdf[AppUrlIndex.Long].replace(":reportId", conditionReportId).replace(":pdfreportId", rowId.toString());
        navigate(path);
    }

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;

        viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);

                break;
            default:
            // code block
        }

        // }
    };

    const newPdfCommand: ICommand = new RelayCommand(() => {
        setIsEligibleModalOpen(true);
    });

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Back) {
            navigate(AppUrls.Client.ConditionReport.List[AppUrlIndex.Long]);
        }
    });

    const cancelModal = () => {
        setIsEligibleModalOpen(false);
    };

    const confirmModal = (conditionReportId: string, templateId: string) => {
        setIsEligibleModalOpen(false);
        const path: string = AppUrls.Client.ConditionReport.AddPdf[AppUrlIndex.Long].replace(":reportId", conditionReportId).replace(":templateId", templateId);
        navigate(path);
    };

    const updateFilteringByKeywordSearch: ICommand = new RelayCommand((searchString: string) => {
        if (searchString == "") {
            viewModel.setSearchString(searchString);
            viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);
        } else {
            viewModel.setSearchString(searchString);
            if (searchString.length >= 3) {
                viewModel.loadPdfDetailsForConditionReportAsync(conditionReportId);
            }
        }
    });

    const FilteringTopSection = (
        <FilteringTopSectionGenericView
            searchValue={viewModel.searchString}
            searchExecute={() => viewModel.executeSearch()}
            searchCommand={updateFilteringByKeywordSearch}
            timescaleValue={""}
        />
    );

    return (
        <>
            {viewModel.isProcessing === true ? (
                <ThemedLoader spinnerText={viewModel.spinnerText} isOpen={true} />
            ) : (
                <>
                    {isEligibileModalOpen === true && (
                        <GetEligibleTemplateModal
                            isOpen={isEligibileModalOpen}
                            title="Choose Template"
                            onCancel={cancelModal}
                            onConfirm={confirmModal}
                            conditionReportId={conditionReportId}
                        />
                    )}
                    {isDeleteSectionOpen === true && (
                        <AreYouSureModal
                            title="Delete PDF Data"
                            message="Are you sure you want to delete the PDF Data for this condition report?"
                            isOpen={isDeleteSectionOpen}
                            onCancel={cancelSectionDelete}
                            onConfirm={confirmSectionDelete}
                        />
                    )}
                    {isPdfViewerOpen === true && <ShowPdfModal title="Show PDF Data" isOpen={isPdfViewerOpen} onCancel={cancelPdfModal} id={pdfId} />}

                    <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText="Back to condition reports" />
                    <BaseWrapper>
                        <Box id="tablecontainer" p={3} style={{ top: "70px", position: "sticky" }}>
                            <Box flexGrow={1} textAlign="right" pb="20px">
                                <ButtonUnder
                                    paletteColor="ButtonBlue" // TODO CMR {viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                                    boxShadow={true}
                                    command={newPdfCommand}
                                    displayName={"New Condition Report PDF"}
                                />
                            </Box>
                            <SharmansTable
                                columns={columns}
                                data={viewModel.getPdfs}
                                showFooter={false}
                                onRowClick={onRowClick}
                                onSortChange={updateSorting}
                                initialSortColumn={viewModel.columnName}
                                initialSortDirection={viewModel.orderBy}
                                showPagination={true}
                                totalRowCount={viewModel.getPageItemCount}
                                onChangePage={onChangePage}
                                onChangeRowPerPage={onChangeRowPerPage}
                                totalPageCount={viewModel.pageCount}
                                pageNumber={viewModel.pageNumber}
                                pageSize={viewModel.pageSize}
                                filteringElementsTopSection={FilteringTopSection}
                            />
                        </Box>
                    </BaseWrapper>
                </>
            )}
        </>
    );
});
