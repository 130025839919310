import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";

export class TSMDocumentsModalModel extends ModelBase<TSMDocumentsModalModel> {
    //public id: string = "";

    public noteId: number | null = null;
    public tsmId: number = -1;
    public note: string = "";
    public rowVersion: string = "";
    public projectId: string = "";

    constructor() {
        super();
        makeObservable(this, {
            //id: observable
            noteId: observable,
            tsmId: observable,
            note: observable,
            rowVersion: observable,
            projectId: observable,

            clear: action,
        });
    }

    public clear = () => {
        //this.id = "";
    };
}

export class TSMDocumentsModalModelValidator extends Validator<TSMDocumentsModalModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("note").notEmpty().minLength(2).withMessage("Please add a note");
    }
}
