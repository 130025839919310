import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { LogoWithText } from "Assets/Logos/LogoWithText";
import { Box, theme } from "Application";
import AnimateHeight from "react-animate-height";
import SignInView from "./SignIn/SignInView";
import { AccountStore } from "Stores/Domain";
import { container } from "tsyringe";
import { GlobalHistory } from "index";
import { LoginHomeViewModel } from "./LoginHomeViewModel";
import { ForgotPasswordView } from "./SignIn/ForgotPassword/ForgotPasswordView";
import { Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";
import BGLogo from "Assets/Logos/BGLogo.svg";

export const Wrapper = styled.form`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent url("${BGLogo}") 0% 0% no-repeat padding-box;
    background-size: cover;
    background-position: center;
    content {
        padding: pxToRem(20);
        margin: auto;
    }
    leftContent {
        margin: auto;
        max-width: pxToRem(325);
    }
    maxHeight {
        height: 100%;
    }
    .tab {
        border: 1px solid #dcdcdc !important;
        height: 30px;
        gap: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        .selected {
            background-color: ${theme.palette.secondary.main};
            color: ${theme.palette.secondary.text};
        }
        .notSelected {
            background-color: white;
            color: black;
        }
    }
`;

/**
 * Login page view.
 */

const LoginHomeView: React.FunctionComponent = observer(() => {
    // #region Code Behind
    const accountStore = container.resolve(AccountStore);
    const [viewModel] = useState(() => new LoginHomeViewModel());
    useEffect(() => {
        if (accountStore.IsLoggedIn) {
            GlobalHistory.push("/");
        }
        if (GlobalHistory.location.pathname == "/account/forgotpassword") {
            viewModel.signUpButtonsVisible = false;
        }
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
    };

    // #endregion Code Behind

    const renderForm = () => (
        <Wrapper onSubmit={doSubmit}>
            <Box grid className={"maxHeight"} columnGap={30} rowGap={20} mc={"1fr"} tc={"1fr"} dc={"1fr"}>
                <Box grid rowGap={"20px"} dc={"1fr"} className={"leftContent"} alignContent={"flex-start"}>
                    <Box center>
                        <LogoWithText />
                    </Box>
                    {/* <Show if={viewModel.signUpButtonsVisible}>
                        <Box className={"tab"} grid dc={"1fr 1fr"} columnGap={0}>
                            <NavLink data-cy={"signIn"} to={"/account/login"} className={(navData) => clsx("tab", navData.isActive ? "selected" : "notSelected")}>
                                Sign In
                            </NavLink>
                            <NavLink data-cy={"signUp"} to={"/account/register"} className={(navData) => clsx("tab", navData.isActive ? "selected" : "notSelected")}>
                                Sign Up
                            </NavLink>
                        </Box>
                    </Show> */}
                    <AnimateHeight id="login-panel" duration={500} height={"auto"}>
                        <Routes>
                            <Route path="login" element={<SignInView loginHomeViewModel={viewModel} />} />
                            <Route path="forgotpassword/*" element={<ForgotPasswordView loginHomeViewModel={viewModel} />} />
                        </Routes>
                    </AnimateHeight>
                </Box>
            </Box>
        </Wrapper>
    );

    return <React.Fragment>{renderForm()}</React.Fragment>;
});
export default LoginHomeView;
