import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { TSMListViewModel } from "Views/TSM/TSMListViewModel";

import { TSMDocumentsModalModel, TSMDocumentsModalModelValidator } from "./TSMDocumentsModalModel";
import { GetTsmDocumentsListAndRelatedEndpoint, GetTsmDocumentsListAndRelatedResponse } from "./EndPoints/GetTsmDocumentsListAndRelatedEndpoint";
import { IDocumentType } from "Models/Documents/DocumentType";
import { TSMDocumentItemViewModel } from "./TSMDocumentItemViewModel";
import { File64, getFileTo64 } from "Utils/File";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { IUploadTsmDocumentRequest, TsmDocumentUploadEndpoint, TsmDocumentUploadResponse } from "./EndPoints/TsmDocumentUploadEndpoint";
import { DocumentDownloaderEndpoint, DocumentDownloaderRequest } from "Endpoint/DocumentDownloader";
import { DeleteTsmDocumentByIdEndpoint } from "./EndPoints/DeleteTsmDocumentByIdEndpoint";
import { AllowedFileTypes } from "Globals/GlobalSettings";

export class TSMDocumentsModalViewModel extends ViewModelBase<TSMDocumentsModalModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    public projectQuoteIdSelected: Guid = "";

    public parentViewModel: TSMListViewModel;

    public tsmDocumentList: IDocumentUploaded[] = [];

    public noteInEditMode: number | null = null;

    public projectNumber: string = "";
    public projectName: string = "";

    // Document Uploader

    public documentTypes: IDocumentType[] = [];
    public documentsToUpload: TSMDocumentItemViewModel[] = [];
    public spinnerText: string = "Loading...";
    public validateDocumentsToUpload: boolean = false;

    constructor(parentViewModel: TSMListViewModel) {
        super(new TSMDocumentsModalModel());
        this.parentViewModel = parentViewModel;
        this.setValidator(new TSMDocumentsModalModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            // tsmNoteList: observable,
            documentsToUpload: observable,
            documentTypes: observable,
            noteInEditMode: observable,
            projectName: observable,
            projectNumber: observable,
            spinnerText: observable,
            tsmDocumentList: observable,
            validateDocumentsToUpload: observable,

            addFiles: action,
            clear: action,

            getDocTypesForDropdown: computed,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public setNewNote: ICommand = new RelayCommand((value: string) => {
        this.setValue("note", value);
    });

    public setTsmId: ICommand = new RelayCommand((value: number) => {
        this.setValue("tsmId", value);
    });

    public loadDocumentsList = async (id: Guid): Promise<GetTsmDocumentsListAndRelatedResponse> => {
        const endpoint: GetTsmDocumentsListAndRelatedEndpoint = new GetTsmDocumentsListAndRelatedEndpoint(id);
        let retVal: GetTsmDocumentsListAndRelatedResponse = new GetTsmDocumentsListAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: GetTsmDocumentsListAndRelatedResponse = this.apiClient.Response();
                    this.tsmDocumentList = response.tsmDocumentList;
                    this.documentTypes = response.documentTypes;
                    this.projectName = response.projectDetails.projectName;
                    this.projectNumber = response.projectDetails.number;

                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("TSM Documents");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the document list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public addFiles = (acceptedFiles: File[]) => {
        acceptedFiles.map((file: File) => {
            const allowedFileTypes = AllowedFileTypes;
            const fileType = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if (allowedFileTypes.includes(fileType)) {
                const viewModel = new TSMDocumentItemViewModel();
                viewModel.setValue("filename", file.name);
                viewModel.setValue("documentCategoryId", 0);
                viewModel.setValue("file", file);
                this.documentsToUpload.push(viewModel);
                this.validateDocumentsToUpload = false;
            } else {
                this.errorStore.setHeaderText("Invalid Files");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne(`Invalid file type: ${fileType}. Only` + AllowedFileTypes.join() + `are allowed.`);
                this.errorStore.setErrorModalOpen(true);
            }
        });
    };

    // Files - remove file from list in modal
    public removeFileFromModalList: ICommand = new RelayCommand((index: number) => {
        const copy = [...this.documentsToUpload];
        copy.splice(index, 1);
        this.documentsToUpload = copy;
    });

    // Files - validate, then submit to server for blob storage and database
    public submitDocuments: ICommand = new RelayCommand(() => {
        if (this.documentsToUpload.length > 0) {
            let areViewModelsValid: boolean = true;

            for (let i = 0; i < this.documentsToUpload.length; i++) {
                if (this.documentsToUpload[i].isModelValid() === false) {
                    areViewModelsValid = false;
                }
            }

            if (areViewModelsValid === true) {
                this.addDocumentFile();
            }
        }
    });

    public addDocumentFile = async () => {
        this.validateDocumentsToUpload = true;
        // TODO check validation before proceeding

        if (this.isProcessing === false) {
            this.isProcessing = true;

            if (this.documentsToUpload.length > 0) {
                for (let i = 0; i < this.documentsToUpload.length; i++) {
                    this.spinnerText = "Uploading document " + (i + 1) + " of " + this.documentsToUpload.length;
                    let uploadedFile: File64 = await getFileTo64(this.documentsToUpload[i].model.file!);
                    this.documentsToUpload[i].setValue("fileBase64", uploadedFile.base64StringFile);
                    this.documentsToUpload[i].setValue("fileType", uploadedFile.fileType);
                    this.documentsToUpload[i].setValue("projectId", this.model.projectId);
                    this.documentsToUpload[i].setValue("filename", uploadedFile.fileName);

                    let request: IUploadTsmDocumentRequest = {
                        tsmId: this.model.tsmId,
                        id: undefined,
                        projectId: this.model.projectId,
                        documentCategoryId: this.documentsToUpload[i].model.documentCategoryId,
                        fileName: uploadedFile.fileName,
                        fileBase64: uploadedFile.base64StringFile,
                        fileType: uploadedFile.fileType,
                    };

                    let endpoint: TsmDocumentUploadEndpoint = new TsmDocumentUploadEndpoint();

                    let _ = await this.apiClient.sendAsync(endpoint, request);
                    if (this.apiClient.IsRequestSuccessful) {
                        runInAction(() => {
                            this.isProcessing = false;
                            let response: TsmDocumentUploadResponse = this.apiClient.Response();
                            this.tsmDocumentList = response.tsmDocumentList;
                            this.documentsToUpload = [];
                            // this.documentCountCallBack(response.length);
                        });
                    } else {
                        runInAction(() => {
                            this.isProcessing = false;
                        });
                        this.errorStore.setHeaderText("Upload Document");
                        this.errorStore.setButtonText("Close");
                        this.errorStore.setErrorMessageOne("Failed to upload the document.  Please correct the file and try again");
                        this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                        this.errorStore.setErrorModalOpen(true);
                    }
                }
            }
        }
    };

    public get getDocTypesForDropdown(): KeyValuePair<number>[] {
        let retVal: KeyValuePair<number>[] = [];

        this.documentTypes.map((type) => {
            retVal.push({ key: type.id, text: type.categoryName });
        });

        return retVal;
    }

    public downloadDocumentCommand = (e: any, id: string): void => {
        let documentToDownload = new DocumentDownloaderRequest();

        for (let i = 0; i < this.tsmDocumentList.length; i++) {
            if (this.tsmDocumentList[i].id == id) {
                documentToDownload.id = this.tsmDocumentList[i].id;
                documentToDownload.fileName = this.tsmDocumentList[i].fileName;
                documentToDownload.blobName = this.tsmDocumentList[i].blobName;
                documentToDownload.fileType = this.tsmDocumentList[i].fileType;
                documentToDownload.docType = this.tsmDocumentList[i].docType;
            }
        }
        this.downloadDocumentAsync(documentToDownload);
    };

    public async downloadDocumentAsync(documentToDownload: DocumentDownloaderRequest): Promise<void> {
        if (documentToDownload !== undefined) {
            const endpoint = new DocumentDownloaderEndpoint();

            await this.apiClient.sendAsync(endpoint, documentToDownload);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    // this.isProcessing = false;
                    let response = this.apiClient.Response();
                });
            } // else show error message
            else {
                runInAction(() => {
                    // this.isProcessing = false;
                });
            }
        }
    }

    public deleteDocumentCommand = (e: any, id: string): void => {
        if (id) {
            this.deleteDocumentAsync(id, this.model.projectId);
        }
    };

    public deleteDocumentAsync = async (id: Guid, projectId: Guid): Promise<GetTsmDocumentsListAndRelatedResponse> => {
        const endpoint: DeleteTsmDocumentByIdEndpoint = new DeleteTsmDocumentByIdEndpoint(id, projectId);
        let retVal: GetTsmDocumentsListAndRelatedResponse = new GetTsmDocumentsListAndRelatedResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response: GetTsmDocumentsListAndRelatedResponse = this.apiClient.Response();
                    this.tsmDocumentList = response.tsmDocumentList;
                    this.documentTypes = response.documentTypes;

                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("TSM Document");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the document.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };
}
