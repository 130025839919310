import marker from "Assets/Pins/sharman-pin.svg";
import L from "leaflet";

export const getSharmansPin = (): L.Icon => {
    const retVal: L.Icon = new L.Icon({
        iconUrl: marker,
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconRetinaUrl: marker,
    });

    return retVal;
};

export const getDefaultZoomLevel = (): number => {
    const retVal: number = 15;
    return retVal;
};
