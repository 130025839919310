import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ComplaintDetailsModel extends ModelBase<ComplaintDetailsModel> {
    //public id: string = "";

    constructor() {
        super();
        makeObservable(this, {
            //id: observable

            clear: action,
        });
    }

    public clear = () => {
        //this.id = "";
    };
}

export class ComplaintDetailsModelValidator extends Validator<ComplaintDetailsModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
