import styled from "@emotion/styled";
import { Box, theme } from "Application";

export const ErrorModalWrapper = styled(Box)``;

export const ErrorModalHeader = styled(Box)`
    height: 50px;
    padding: 15px;
    background-color: ${theme.palette.common.sharmansBlue};
    display: flex;
    justify-content: space-between;
`;

export const ErrorModalBody = styled(Box)`
    padding: 15px;
`;

export const HeaderBox = styled(Box)`
    display: flex;
    align-items: center;
`;

export const ButtonBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;
