import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TSMSiteVisitModalModel } from "../TSMSiteVisitModalModel";

export class UpsertRequestSiteVisitRequest {
    id: number | null = null;
    projectId: Guid = "";
    propertyId: Guid = "";
    visitDateTime: Date = new Date();
    tsmId: number = -1;
    visitTypeId: number = -1;
    note: string = "";
    contactId: Guid | null = null;
    rowVersion: string = "";
}

export class UpsertRequestSiteVisitResponse {
    id: number = -1;
    projectId: string = "";
    propertyId: string = "";
    visitDateTime: string = "";
    requestedBy: string = "";
    tsmId: number = -1;
    visitTypeId: number = -1;
    note: string = "";
    contactId: string = "";
    rowVersion: string = "";
}

export class UpsertRequestSiteVisitEndpoint extends Endpoint<UpsertRequestSiteVisitRequest, UpsertRequestSiteVisitResponse> {
    constructor() {
        super();
        this.verb(Http.Put);
        this.path(AppUrls.Server.Tsm.UpsertRequestSiteVisit);
    }

    public async HandleRequestAsync(model: TSMSiteVisitModalModel): Promise<any> {
        let request = new UpsertRequestSiteVisitRequest();

        request.id = model.siteVisitId;
        request.projectId = model.projectId;
        request.propertyId = model.propertyId;
        request.visitDateTime = model.visitDateTime;
        request.tsmId = model.tsmId;
        request.visitTypeId = model.visitTypeId;
        request.note = model.note;
        request.contactId = model.contactId;
        request.rowVersion = model.rowVersion;
        return request;
    }

    public async HandleResponseAsync(response: UpsertRequestSiteVisitResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return {};
    }
}
