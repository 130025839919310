import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable, computed } from "mobx";
import { Validator } from "Application/Validation";
import { ComplaintTypeEnum } from "Models/Complaints/ComplaintTypeEnum";
import { IDocumentType } from "Models/Documents/DocumentType";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { hyphenIfNoValue } from "Utils/Formats";

export class ComplaintsModel extends ModelBase<ComplaintsModel> {
    id: Guid = "";
    siteAddress: string = "";
    referenceId: string = "";
    orderNumber: string = "";
    installationCompany: string = "";
    projectName: string = "";
    complaintAssignee: string = "";
    reportedBy: string = "";
    nature: string = "";
    companyReportedBy: string = "";
    category: string = "";
    details: string = "";
    assessment: string = "";
    isResolved: boolean = false;
    projectId: Guid = "";
    number: string = "";
    orderValue: string = "";

    complaintType: ComplaintTypeEnum = ComplaintTypeEnum.Unknown;

    documentTypes: IDocumentType[] = [];

    public correctiveActionCount: number = 0;
    public preventativeActionCount: number = 0;
    public taskCount: number = 0;
    public documentCount: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            documentTypes: observable,
            siteAddress: observable,
            referenceId: observable,
            orderNumber: observable,
            installationCompany: observable,
            projectName: observable,
            complaintAssignee: observable,
            reportedBy: observable,
            nature: observable,
            companyReportedBy: observable,
            category: observable,
            details: observable,
            assessment: observable,
            isResolved: observable,
            number: observable,
            projectId: observable,
            orderValue: observable,

            correctiveActionCount: observable,
            preventativeActionCount: observable,
            taskCount: observable,
            documentCount: observable,
            complaintType: observable,

            getProjectNumberName: computed,

            clear: action,
        });
    }

    public clear = () => {
        this.complaintType = ComplaintTypeEnum.Unknown;
        this.correctiveActionCount = 0;
        this.preventativeActionCount = 0;
        this.taskCount = 0;
        this.documentCount = 0;
        this.number = "";
        this.projectId = "";
    };

    public get getProjectNumberName(): string {
        let retVal: string = hyphenIfNoValue(this.number) + " - " + hyphenIfNoValue(this.projectName);
        return retVal;
    }

    public get getProjectURL(): string {
        let retVal: string = "";

        if (this.complaintType === ComplaintTypeEnum.MasterProject) {
            retVal = AppUrls.Client.Work.Master.View[AppUrlIndex.Long].replace(":masterprojectid", this.projectId!.toString());
        } else {
            retVal = AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", this.projectId!.toString() + "/details");
        }
        return retVal;
    }
}

export class ComplaintsModelValidator extends Validator<ComplaintsModel> {
    constructor() {
        super();
    }
}
