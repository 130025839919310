import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { BlankModel } from "Application/Models/Domain/BlankModel";
import { CondtionReportListItemModel } from "./CondtionReportListItemModel";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ConditionReportListResponse, GetConditionReportsForListEndpoint, GetConditionReportsForListRequest } from "./Endpoints/GetConditionReportsForListEndpoint";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { ISearchablePagedSortedTable } from "Models/TableInterfaces";
import { DeleteConditionReportByIdEndpoint } from "./Endpoints/DeleteConditionReportByIdEndpoint";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { GetConditionReportListFiltersEndpoint, GetConditionReportListFiltersResponse } from "./Endpoints/GetConditionReportListFiltersEndpoint";
export class ConditionReportListViewModel extends ViewModelBase<BlankModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public initialLoad: boolean = true;

    private reports: IObservableArray<CondtionReportListItemModel> = observable([]);

    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.DESC;
    public columnName: string = "createdDate";

    // Search String
    public searchString: string = "";

    // Pagination
    public pageItemCount: number = 0;
    public totalCount: number = 0;
    public pageCount: number = 0;

    public pageSize: number = 50;
    public pageNumber: number = 1;
    public ascOrder = true;

    public resetLoginAttemptsError: string = "";
    public isTableLoading: boolean = false;

    public reportToDelete: string = "";

    public isProcessing: boolean = false;

    // Filtering
    public editors: IObservableArray<KeyValuePairExtended> = observable([]);
    public editorsForFiltering: string[] = [];

    constructor() {
        super(new BlankModel());
        makeObservable(this, {
            isProcessing: observable,
            pageItemCount: observable,
            ascOrder: observable,
            resetLoginAttemptsError: observable,
            isTableLoading: observable,
            pageSize: observable,
            pageNumber: observable,
            searchString: observable,
            //showDialog: observable,

            clear: action,
            deleteCondtionReport: action,
            editorsSelectAll: action,
            editorsSelectNone: action,
            loadConditionReportsAsync: action,
            loadFilterData: action,
            set: action,
            setIsTableLoading: action,
            setOrderAsc: action,
            setSearchString: action,

            getCanExecute: computed,
            getConditionReports: computed,
            getEditors: computed,
            getOrderAsc: computed,
            getPageItemCount: computed,
            getTotalItemCount: computed,
        });
    }

    public clear() {
        this.reports.clear();
        this.pageItemCount = 0;
        this.pageCount = 0;
        this.ascOrder = true;
        this.resetLoginAttemptsError = "";
        this.isTableLoading = false;
        this.isProcessing = false;

        this.orderBy = SortOrderDirection.DESC;
        this.columnName = "createdDate";

        // Pagination
        this.pageSize = 20;
        this.pageNumber = 1;

        this.searchString = "";
    }

    public get getEditors(): KeyValuePairExtended[] {
        return this.editors.slice();
    }

    public get getCanExecute(): boolean {
        return !this.apiClient.IsBusy;
    }

    public setSearchString(searchString: string) {
        this.searchString = searchString;
    }

    public executeSearch(): void {
        let promise = this.loadConditionReportsAsync();

        promise.then((result) => {});
    }

    public deleteCondtionReport = async (deleteSelectionId: string) => {
        if (this.isProcessing === false) {
            this.isProcessing = true;

            this.reportToDelete = deleteSelectionId;
            const endpoint: DeleteConditionReportByIdEndpoint = new DeleteConditionReportByIdEndpoint();
            await this.apiClient.sendAsync(endpoint, this);

            if (this.apiClient.IsRequestSuccessful === true) {
                let response: ConditionReportListResponse = this.apiClient.Response<ConditionReportListResponse>();

                runInAction(() => {
                    this.isProcessing = false;
                    this.totalCount = response.queryTotalCount;
                    this.pageItemCount = response.conditionReports.length;

                    this.pageCount = 0;

                    if (this.pageSize != 0) {
                        this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                    }

                    this.reports.clear();
                    this.reports.replace(response.conditionReports);
                    this.reportToDelete = "";
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the pdf data.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
                runInAction(() => {
                    this.reportToDelete = "";
                });
            }
        }
    };

    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    public get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    public newUserCommand: ICommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.ConditionReport.AddEdit[AppUrlIndex.Long].replace(":reportId", "new"));
    });

    public editUserCommand: ICommand = new RelayCommand((report: any) => {
        this.history.push(AppUrls.Client.ConditionReport.AddEdit[AppUrlIndex.Long].replace(":reportId", report.id));
    });

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value);
    }

    public async loadConditionReportsAsync(): Promise<void> {
        if (this.isProcessing === false) {
            this.isProcessing = true;
            const endpoint: GetConditionReportsForListEndpoint = new GetConditionReportsForListEndpoint();

            const requestModel: GetConditionReportsForListRequest = {
                orderBy: this.orderBy,
                columnName: this.columnName,
                pageSize: this.pageSize,
                pageNumber: this.pageNumber,
                searchString: this.searchString,
                editorsForFiltering: this.editorsForFiltering,
            };

            await this.apiClient.sendAsync(endpoint, requestModel);

            if (this.apiClient.IsRequestSuccessful === true) {
                let response = this.apiClient.Response<ConditionReportListResponse>();

                runInAction(() => {
                    this.totalCount = response.queryTotalCount;
                    this.pageItemCount = response.conditionReports.length;

                    this.pageCount = 0;

                    if (this.pageSize != 0) {
                        this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                    }

                    this.reports.clear();
                    this.reports.replace(response.conditionReports);
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load the condition report list.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
                runInAction(() => {
                    this.reportToDelete = "";
                });
            }
        }
    }

    public get getConditionReports(): CondtionReportListItemModel[] {
        return this.reports.slice();
    }

    public setIsTableLoading = (state: boolean) => {
        this.isTableLoading = state;
    };

    public get getPageItemCount(): number {
        return this.pageItemCount;
    }

    public get getTotalItemCount(): number {
        return this.totalCount;
    }

    public editorsSelectNone = async () => {
        this.editorsForFiltering = [];
        this.loadConditionReportsAsync();
    };

    public editorsSelectAll = () => {
        this.editorsForFiltering = this.editors.map((item) => {
            return item.key.toString();
        });
        this.loadConditionReportsAsync();
    };

    public updateFilteringByEditor = (values: KeyValuePair[]) => {
        this.editorsForFiltering = [];
        values.map((item) => {
            this.editorsForFiltering.push(item.key);
        });
    };

    public loadFilterData = async (): Promise<KeyValuePair[]> => {
        this.isProcessing = true;
        const endpoint = new GetConditionReportListFiltersEndpoint();
        let promise: KeyValuePair[] = [];
        await this.apiClient.sendAsync(endpoint);
        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                const response: GetConditionReportListFiltersResponse = this.apiClient.Response<GetConditionReportListFiltersResponse[]>();

                this.editors.replace(response.editors);

                if (this.initialLoad === true) {
                    let initialSelectedEditors: string[] = [];

                    this.editors.map((role) => {
                        initialSelectedEditors.push(role.key);
                    });
                    this.editorsForFiltering = initialSelectedEditors;

                    this.initialLoad = false;
                }

                promise = this.editors.slice();
                this.isProcessing = false;
            });
        } // else show error message
        else {
            this.errorStore.setHeaderText("User roles");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to get the user role list.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
            this.isProcessing = false;
        }
        return promise;
    };
}
