import styled from "@emotion/styled";
import { Box } from "Components";


export const TextAreaBox = styled(Box)`
    textarea {
        width: calc(100% - 24px) !important;
        white-space: normal !important;
        overflow: auto;
    }
`;

export const CategoryTextBox = styled(Box)`
    .editselect__option {
        padding-left: 30px;
        position: relative;
        border-bottom: 1px solid #ccc;
        &:before {
            content: "";
            position: absolute;
            left: 15px;
            top: 16px;
            width: 5px;
            height: 1px;
            background-color: #000;
        }
        &.editselect__option--is-disabled {
            padding-left: 12px;
            background-color: rgb(237, 237, 237);
            &:before {
                content: unset;
            }
            p {
                color: #000;
            }
        }
    }
`;
