import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class CustomerDocumentItemModel extends ModelBase<CustomerDocumentItemModel> {
    // public id: string = "";
    customerId: Guid = "";
    documentCategoryId: number = 0;
    filename: string = "";
    blobName: string = "";
    file: File | undefined = undefined;
    fileBase64: string = "";
    fileType: string = "";

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
            customerId: observable,
            documentCategoryId: observable,
            filename: observable,
            blobName: observable,
            file: observable,
        });
    }

    public clear = () => {
        this.id = "";
    };
}

export class CustomerDocumentItemModelValidator extends Validator<CustomerDocumentItemModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
        this.ruleFor("documentCategoryId").greaterThan(0).withMessage("Select document type");
    }
}
