import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { Navigate, Route, Routes } from "react-router";
import { FallbackPageView } from "Components/FallbackPage";
import { PropertiesListView } from "./List/PropertiesListView";
import { NewEditPropertyView } from "./NewEditProperty/NewEditPropertyView";
import { ViewPropertyView } from "./ViewProperty/ViewPropertyView";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";

export const PropertiesRoutesView: React.FC = observer(() => {
    return (
        <>
            <Routes>
                <Route
                    path="*"
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <Navigate to="list" replace />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"list/*"}
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <PropertiesListView />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"new/*"}
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <NewEditPropertyView addOrEdit={ModalType.Add} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"edit/:id"}
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <NewEditPropertyView addOrEdit={ModalType.Edit} />
                        </React.Suspense>
                    }
                />
                <Route
                    path={"view/:id/*"}
                    element={
                        <React.Suspense fallback={<FallbackPageView />}>
                            <ViewPropertyView />
                        </React.Suspense>
                    }
                />
            </Routes>
        </>
    );
});
