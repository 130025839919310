import { makeObservable, observable, runInAction, IObservableArray, action, computed } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { BlankModel } from "Application/Models/Domain/BlankModel";
import { ConditionReportTemplateListItemModel } from "./ConditionReportTemplateListItem";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { GetTemplateListEndpoint, GetTemplateListResponse } from "./Endpoints/GetTemplateListEndpoint";
import { DeleteTemplateByIdEndpoint } from "./Endpoints/DeleteTemplateByIdEndpoint";
import { DefaultPageSize } from "Globals/GlobalSettings";

export class TemplateListViewViewModel extends ViewModelBase<BlankModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;
    private templates: IObservableArray<ConditionReportTemplateListItemModel> = observable([]);
    public totalCount: number = 0;
    // Table ordering

    public orderBy: SortOrderDirection = SortOrderDirection.DESC;
    public columnName: string = "displayName";

    // Pagination
    public pageItemCount: number = 0;
    public pageCount: number = 0;
    public tableCount: number = 0;
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;

    // Filtering
    // NONE

    // Search String
    public searchString: string = "";

    public ascOrder = true;
    public isTableLoading: boolean = false;

    public templateToDeleteId: string = "";

    constructor() {
        super(new BlankModel());
        // this.setValidator(new BlankModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            tableCount: observable,
            totalCount: observable,
            ascOrder: observable,
            isTableLoading: observable,
            pageSize: observable,
            pageNumber: observable,
            searchString: observable,

            clear: action,
            deleteTemplate: action,
            loadTemplates: action,
            loadTemplatesAsync: action,
            setIsTableLoading: action,
            setOrderAsc: action,
            //ICommand updateFilteringByKeywordSearch: action,

            getOrderAsc: computed,
            getTableCount: computed,
            getTemplates: computed,
        });
    }

    public clear = () => {
        this.templates.clear();
        //this.templateToDelete = null;
        this.tableCount = 0;
        this.totalCount = 0;
        this.ascOrder = true;
        this.isTableLoading = false;
        //this.model.clear();
        this.templateToDeleteId = "";
    };

    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    public get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    public newTemplateCommand: ICommand = new RelayCommand(() => {
        this.history.push(AppUrls.Client.ConditionReport.AddEditTemplate[AppUrlIndex.Long].replace(":templateId", "new"));
    });

    public editTemplateCommand: ICommand = new RelayCommand((templateModel: ConditionReportTemplateListItemModel) => {
        this.history.push(AppUrls.Client.ConditionReport.AddEditTemplate[AppUrlIndex.Long].replace(":templateId", templateModel.id));
    });

    /*     public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value);
    } */

    public get getTemplates() {
        return this.templates.slice();
    }

    public setIsTableLoading = (state: boolean) => {
        this.isTableLoading = state;
    };

    public get getTableCount(): number {
        return this.tableCount;
    }

    public async loadTemplatesAsync(): Promise<void> {
        const endpoint = new GetTemplateListEndpoint();
        await this.apiClient.sendAsync(endpoint, this);

        if (this.apiClient.IsRequestSuccessful) {
            let response = this.apiClient.Response<GetTemplateListResponse>();
            runInAction(() => {
                this.totalCount = response.queryTotalCount;
                this.pageItemCount = response.templates.length;

                this.pageCount = 0;

                if (this.pageSize != 0) {
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                }

                this.templates.clear();
                this.templates.replace(response.templates);

                this.pageCount = Math.ceil(this.tableCount / this.pageSize);
                this.isProcessing = false;
            });
        } // else show error message
        else {
            this.errorStore.setHeaderText("Template list");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to get the template list.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public async deleteTemplate(deleteSectionId: string): Promise<void> {
        this.templateToDeleteId = deleteSectionId;
        const endpoint = new DeleteTemplateByIdEndpoint();
        await this.apiClient.sendAsync(endpoint, this);

        if (this.apiClient.IsRequestSuccessful) {
            let response = this.apiClient.Response<GetTemplateListResponse>();
            runInAction(() => {
                this.totalCount = response.queryTotalCount;
                this.pageItemCount = response.templates.length;

                this.pageCount = 0;

                if (this.pageSize != 0) {
                    this.pageCount = Math.ceil(this.totalCount / this.pageSize);
                }

                this.templates.clear();
                this.templates.replace(response.templates);

                this.pageCount = Math.ceil(this.tableCount / this.pageSize);
                this.templateToDeleteId = "";
                this.isProcessing = false;
            });
        } // else show error message
        else {
            this.errorStore.setHeaderText("Template list");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to get the template list.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }

    public executeSearch = () => {
        this.loadTemplatesAsync();
    };

    public updateFilteringByKeywordSearch: ICommand = new RelayCommand((searchString: string) => {
        this.searchString = searchString;

        if (this.searchString.length > 3) {
            this.loadTemplatesAsync();
        }
    });

    public loadTemplates(newUsers: ConditionReportTemplateListItemModel[]) {
        this.templates.clear();
        this.templates.replace(newUsers);
    }
}
