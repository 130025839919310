import { action, makeObservable, observable } from "mobx";

export class ConditionReportUser {
    public id: number = -1;
    public conditionReportId: string | null = null;
    public displayName: string = "";
    public identifier: string = "";
    public userName: string = "";
    public userId: string | null = null;

    constructor() {
        makeObservable(this, {
            id: observable,
            conditionReportId: observable,
            displayName: observable,
            identifier: observable,
            userName: observable,
            userId: observable,
            clear: action,
            load: action,
        });
    }

    public load(user: ConditionReportUser | undefined) {
        this.clear();
        if (user !== undefined) {
            this.id = user.id;
            this.conditionReportId = user.conditionReportId;
            this.displayName = user.displayName;
            this.identifier = user.identifier;
            this.userName = user.userName;
            this.userId = user.userId;
        }
    }

    public clear = () => {
        this.id = -1;
        this.conditionReportId = null;
        this.displayName = "";
        this.identifier = "";
        this.userName = "";
        this.userId = null;
    };
}
