import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class CustomerDocumentsModel extends ModelBase<CustomerDocumentsModel> {
    public customerId: Guid = "";

    // Table sorting
    public columnName: string = "";
    public orderBy: string = "";

    constructor() {
        super();
        makeObservable(this, { columnName: observable, orderBy: observable, customerId: observable });
    }

    public clear = () => {
        //    this.id = "";
    };
}

export class CustomerDocumentsModelValidator extends Validator<CustomerDocumentsModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
