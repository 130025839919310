import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { MasterProjectTaskModel } from "../MasterProjectTaskModel";

export class MasterProjectTaskUpsertEndpoint extends Endpoint<MasterProjectTaskModel, MasterProjectTaskModel> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.MasterProject.UpsertMasterProjectNewTask);
    }

    public async HandleRequestAsync(request: MasterProjectTaskModel): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: MasterProjectTaskModel): Promise<MasterProjectTaskModel> {
        return response;
    }
}
