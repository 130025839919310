import { HeaderViewModel } from "./HeaderViewModel";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router";
import { Box, Label } from "Components";
import styled from "@emotion/styled";
import { theme } from "../../Styles";
import { H5 } from "Components/Primitives/TextElements/TextElements";

interface MenuItemProps {
    text: string;
    path: string;
    canExecute?: () => boolean;
    viewModel: HeaderViewModel;
    highlightColour?: string;
}
export const MenuItem: React.FC<MenuItemProps> = observer((props) => {
    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        props.viewModel.setActiveFromLocation(location.pathname, props.path, props.text);
    }, [location]);

    const onClick = () => {
        props.viewModel.setActiveMenuItem(props.text);
        navigate(props.path);
    };

    return props.canExecute!() ? (
        <MenuItemBox className={"menuitem"}>
            <Box onClick={onClick} style={{ position: "relative", textAlign: "center", minWidth: "130px", cursor: "pointer" }}>
                <span style={{ position: "relative", zIndex: 10 }}>
                    <H5 style={{ cursor: "pointer" }} color={props.viewModel.isActiveMenuItem(props.text) ? "white" : "black"}>
                        {props.text}
                    </H5>
                </span>
                {/* {props.viewModel.activeMenuItem === props.text && <Underline className={"menuitem"} layoutId={"underline"} />} */}
                {props.viewModel.activeMenuItem === props.text && (
                    <HoverBox
                        bgColour={props.highlightColour ? props.highlightColour : "black"}
                        className={"menuitem"}
                        layoutId="hoverbox"
                        whileHover={{
                            backgroundColor: [props.highlightColour ? props.highlightColour : "black"],
                            transition: { duration: 2, repeat: Infinity },
                        }}
                    />
                )}
            </Box>
        </MenuItemBox>
    ) : null;
});
MenuItem.defaultProps = {
    canExecute: () => true,
};

interface IHoverBox {
    bgColour: string;
}

const HoverBox = styled(Box)<IHoverBox>`
    position: absolute;
    top: -30px;
    background-color: ${(props) => (props.bgColour ? props.bgColour : "black")};
    height: 70px;
    width: 100%;
`;
const MenuItemBox = styled(Box)`
    display: flex;
    align-items: center;
    .menuitem {
    }
`;

const Underline = styled(Box)`
    position: absolute;
    background-color: ${theme.palette.secondary.darker};
    height: 3px;
    width: 100%;
    top: 33px;
    z-index: 5;
`;
