/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react";
import { Main, LogoBox, ChangeLogTitle } from "./FooterView.styles";
import { Box } from "Components";
import { P } from "Components/Primitives/TextElements/TextElements";

import { ChangeLog as ChangeLogData } from "Changelog/Changelog";

// Images

import Logo from "Assets/Logos/LogoWithText.svg";
import { Modal } from "@fluentui/react";
import { ChangeLogView } from "Views/Changelog/Changelog";
import { ICommand, RelayCommand } from "Application";

interface IFooterState {
    isOpen: boolean;
}

export const Footer: React.FC = () => {
    const { currentVersion } = ChangeLogData;

    const [open, setIsOpen] = useState<boolean>(false);

    const changeLog = (
        <ChangeLogTitle onClick={() => setIsOpen(true)}>
            <strong>View change log </strong>&nbsp;(v{currentVersion})
        </ChangeLogTitle>
    );

    const copyright = <strong>&copy; Copyright {new Date().getFullYear()} HD Sharmans</strong>;

    const closeChangelog = () => {
        setIsOpen(false);
    };

    const closeChangeLog: ICommand = new RelayCommand(() => {
        closeChangelog();
    });

    return (
        <>
            <Modal isOpen={open} isBlocking={false} onDismiss={closeChangelog}>
                <ChangeLogView onClose={closeChangeLog} />
            </Modal>
            <Main>
                <LogoBox pl="12px">{<img src={Logo} height="24px" />}</LogoBox>
                <Box>
                    <Box>
                        <P color="black" pr="20px" style={{ fontSize: "8px" }}>
                            {changeLog}&nbsp;{copyright}
                        </P>
                    </Box>
                </Box>
            </Main>
        </>
    );
};
