import styled from "@emotion/styled";
import { Box, Button, theme } from "Application";

export const ModalWrapper = styled(Box)``;

export const ModalHeader = styled(Box)`
    height: 50px;
    padding: 15px;
    background-color: ${theme.palette.common.sharmansBlue};
    display: flex;
    justify-content: space-between;
`;

interface IModalBody {
    noPadding?: boolean;
}

export const ModalBody = styled(Box)<IModalBody>`
    padding: ${(props) => (props.noPadding ? "0px" : "15px")};
`;

export const HeaderBox = styled(Box)`
    display: flex;
    align-items: center;
`;

export const HeaderBoxCloseButton = styled(Button)`
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 4px;
    min-height: 0px;
    & > img {
        margin-right: 5px;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
    }
`;

export const ButtonBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StickyBar = styled(Box)`
    min-height: 58px;
    background-color: white;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
    box-shadow: 3px 0px 3px #00000029;
`;
