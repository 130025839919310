import { singleton } from "tsyringe";
import { action, makeObservable, observable } from "mobx";

@singleton()
export class ErrorStore {
    public isErrorModalOpen: boolean = false;
    public headerText: string = "Error";
    public errorMessageOne: string = "";
    public errorMessageTwo: string = "";
    public redirectURLOnClose: string = "";
    public buttonText: string = "Close";

    constructor() {
        makeObservable(this, {
            isErrorModalOpen: observable,
            headerText: observable,
            errorMessageOne: observable,
            errorMessageTwo: observable,
            redirectURLOnClose: observable,
            buttonText: observable,

            setErrorModalOpen: action,
            setHeaderText: action,
            setErrorMessageOne: action,
            setErrorMessageTwo: action,
            setRedirectURLOnClose: action,
            setButtonText: action,
        });
        (window as any).ErrorStore = this;
    }

    public setErrorModalOpen = (isOpen: boolean) => {
        this.isErrorModalOpen = isOpen;
    };

    public setHeaderText = (text: string) => {
        this.headerText = text;
    };

    public setErrorMessageOne = (text: string) => {
        this.errorMessageOne = text;
    };

    public setErrorMessageTwo = (text: string) => {
        this.errorMessageTwo = text;
    };

    public setRedirectURLOnClose = (text: string) => {
        this.redirectURLOnClose = text;
    };

    public setButtonText = (text: string) => {
        this.buttonText = text;
    };

    public clear = () => {
        // TODO when do we call this?  On modal closing?
        this.isErrorModalOpen = false;
        this.headerText = "Error";
        this.errorMessageOne = "";
        this.errorMessageTwo = "";
        this.redirectURLOnClose = "";
        this.buttonText = "Close";
    };
}
