import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class SetSectionIsDeletedRequest {
    public templateId: string = "";

    public sectionId: string = "";

    public isDeleted: boolean = false;

    public concurrencyToken: string = "";

    public constructor() {}
}

class SetSectionIsDeletedResponse {
    public success: boolean = false;
    public constructor() {}
}

export class SetSectionIsDeletedEndpoint extends Endpoint<SetSectionIsDeletedRequest, SetSectionIsDeletedResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportTemplate.SetSectionIsDeleted);
    }

    public async HandleRequestAsync(request: SetSectionIsDeletedRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: SetSectionIsDeletedResponse): Promise<boolean> {
        Logger.logInformation("Response", response);

        //Return your model
        return response.success;
    }
}
