import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class GetCorrectiveActionForViewResponse {
    actions: GenericComplaintActionListItem[] = [];
}

export class GetCorrectiveActionForViewEndpoint extends EndpointWithoutRequest<GetCorrectiveActionForViewResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.AfterSales.Complaints.GetCorrectiveActionsForView + `/${id}`);
    }

    public async HandleResponseAsync(response: GetCorrectiveActionForViewResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}

export interface GenericComplaintActionListItem {
    id: number;
    createdDate: string;
    action: string;
    author: string;
    lastUpdated: string;
    rowVersion: string;
}
