import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import styled from "@emotion/styled";
import EditIcon from "Assets/Icons/EditIconInBox.svg";

export const RedButtonBox = styled(Box)`
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    /* UI Properties */
    /* background: #ce0e2d 0% 0% no-repeat padding-box; */
    box-shadow: 0px 3px 3px #00000029;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    :hover {
        cursor: pointer;
    }

    img {
        width: 20px;
        height: 20px;
        /*         background: transparent url("img/sort.png") 0% 0% no-repeat padding-box; */
        opacity: 1;
        margin-top: 0;
        margin-left: 0;
    }

    .dash {
        margin-top: 1px;
        margin-left: 5px;

        width: 10px;
        height: 2px;
        /* UI Properties */
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 1;
    }
`;

export interface IEditButtonProps {
    id: string;
    onClick: (e: any, id: string) => void;
}

export const EditButton: React.FC<IEditButtonProps> = observer(({ id, onClick }) => {
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    const localClick = (e: any) => {
        e.stopPropagation();
        onClick(e, id);
    };

    return (
        <RedButtonBox onClick={localClick}>
            <img src={EditIcon} style={{ cursor: "pointer" }} height="100%" />
        </RedButtonBox>
    );
});
