import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand } from "Application";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { createColumnHelper } from "@tanstack/react-table";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { PropertiesListViewModel } from "./PropertiesListViewModel";
import { FilteringTopSectionGenericView } from "Components/FilteringTopSectionGeneric";
import { BooleanTickOrCrossCell, CenteredTextCell } from "Utils/CellFormatComponents";
import { AddressConcatNoPostCode, formatDate } from "Utils/Formats";
import { PropertiesListWrapper } from "../Properties.styles";
import { useNavigate } from "react-router";
import { AppUrls } from "AppUrls";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair } from "@shoothill/core";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";

export const PropertiesListView: React.FC = observer(() => {
    const [viewModel] = useState(() => PropertiesListViewModel.Instance);
    let debounceTimer: NodeJS.Timeout | null = null;
    const columnHelper = createColumnHelper<any>();
    const navigate = useNavigate();

    const [isFiltering, setIsFiltering] = useState(false);
    useEffect(() => {
        viewModel.loadPropertiesAsync();
        return () => {
            // Clean up after yourself
            // Don't clear, we want to keep this since using a singleton viewModel.clear();
            // viewModel.reset();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const columns = [
        columnHelper.accessor("address", {
            size: 700,
            header: () => "Property address",
            cell: (info) => AddressConcatNoPostCode(info.row.original.addressLine1, info.row.original.addressLine2, info.row.original.townCity, info.row.original.county),
            // footer: (info) => info.column.id,
        }),

        columnHelper.accessor("postCode", {
            size: 100,
            header: () => "Postcode",
            cell: (info) => <CenteredTextCell value={info.renderValue()} />,
            // footer: (info) => info.column.id,
        }),

        columnHelper.accessor("attendedSite", {
            // enableSorting: false,
            size: 80,
            header: () => "Attended?",
            cell: (info) => <BooleanTickOrCrossCell value={info.renderValue()} />,
            // footer: (info) => info.column.id,
        }),

        columnHelper.accessor("linkedMulti", {
            size: 30,
            header: () => "Multi",
            cell: (info) => <CenteredTextCell value={info.row.original.multiSiteProjectCount} />,
        }),

        columnHelper.accessor("master", {
            size: 30,
            header: () => "Master",
            cell: (info) => <CenteredTextCell value={info.row.original.masterProjectCount} />,
        }),

        columnHelper.accessor("quote", {
            size: 30,
            header: () => "Quote",
            cell: (info) => <CenteredTextCell value={info.row.original.projectQuoteCount} />,
        }),

        columnHelper.accessor("createdDate", {
            size: 60,
            header: () => "Date added",
            cell: (info) => <CenteredTextCell value={formatDate(new Date(info.renderValue()))} />,
            // footer: (info) => info.column.id,
        }),
    ];

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.loadPropertiesAsync();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.loadPropertiesAsync();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.loadPropertiesAsync();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.loadPropertiesAsync();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.loadPropertiesAsync();

                break;
            default:
            // code block
        }

        // }
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;

        viewModel.loadPropertiesAsync();
    };

    function onRowClick(id: string | number): void {
        const path: string = AppUrls.Client.Directory.PropertyView + id + "/details";
        navigate(path);
    }

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        switch (value) {
            case subHeadingButton.New:
                navigate(AppUrls.Client.Directory.PropertyNew);
                break;
            case subHeadingButton.Download:
                viewModel.downloadCSVAsync();
                break;

            default:
            // code block
        }
    });

    const FilteringTopSection = (
        <FilteringTopSectionGenericView
            searchValue={viewModel.searchString}
            searchCommand={viewModel.updateKeywordSearch}
            timescaleOptions={[]}
            timescaleValue={""}
            displayName="Start typing to search by property address, postcode etc... "
            isDisabled={!viewModel.getCanExecute}
        />
    );

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.loadPropertiesAsync();
            setIsFiltering(false);
        }, 1200);
    };

    const updateAttendedSiteFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateAttendedSiteFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        debounceFilter();
    });

    const attendedSiteSelectAll = (): void => {
        viewModel.attendedSiteSelectAll();
        viewModel.loadPropertiesAsync();
    };
    const attendedSiteSelectNone = (): void => {
        viewModel.attendedSiteSelectNone();
        viewModel.loadPropertiesAsync();
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <MultiEditSelect
                command={updateAttendedSiteFiltering}
                displayName="Surveyed?"
                options={viewModel.getBooleanOptionsForDropdown}
                value={() => viewModel.getAttendedSiteFilter}
                showSelectAllNone={true}
                selectAll={attendedSiteSelectAll}
                selectNone={attendedSiteSelectNone}
                isDisabled={viewModel.isProcessing}
            />
        </FilteringBottomSectionBox>
    );

    return (
        <>
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} downloadButtonText="Download as CSV" newButtonText="New property" />
            <PropertiesListWrapper>
                <H2 pb="10px">Properties</H2>
                <SharmansTable
                    columns={columns}
                    data={viewModel.getProperties}
                    filteringElementsTopSection={FilteringTopSection}
                    filteringElementsBottomSection={FilteringBottomSection}
                    onSortChange={updateSorting}
                    showPagination={true}
                    totalRowCount={viewModel.propertyCount}
                    onChangePage={onChangePage}
                    onChangePageNumber={viewModel.changePageNumber}
                    onChangeRowPerPage={onChangeRowPerPage}
                    totalPageCount={viewModel.pageCount}
                    pageNumber={viewModel.pageNumber}
                    pageSize={viewModel.pageSize}
                    onRowClick={onRowClick}
                    isProcessing={viewModel.isProcessing}
                    clearFilteringCommand={viewModel.clearFiltering}
                />
            </PropertiesListWrapper>
        </>
    );
});
