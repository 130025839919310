import { KeyValuePairExtended } from "Models/KeyValuePairExtended";

export enum ProjectStatusEnum {
    Unknown = 0,
    ConditionReportConversion = 5,
    ConditionReportCheck = 6,
    Prospect = 10,
    ProspectProceeded = 15,
    DidNotProgress = 20,
    Lost = 30,
    Quote = 40,
    ValueSeparate = 45,
    Order = 50,
    Despatched = 60,
    Master = 64,
    MasterComplete = 65,
    AftersalesOpen = 70,
    AftersalesClosed = 80,
}

export namespace ProjectStatusEnumHelper {
    export const AvailibleProjectStatusesByProjectStatus: { [key: number]: ProjectStatusEnum[] } = {
        [ProjectStatusEnum.ConditionReportConversion]: [ProjectStatusEnum.ConditionReportConversion, ProjectStatusEnum.ConditionReportCheck, ProjectStatusEnum.Prospect],
        [ProjectStatusEnum.ConditionReportCheck]: [ProjectStatusEnum.ConditionReportConversion, ProjectStatusEnum.ConditionReportCheck, ProjectStatusEnum.Prospect],
        [ProjectStatusEnum.Prospect]: [
            ProjectStatusEnum.ConditionReportConversion,
            ProjectStatusEnum.ConditionReportCheck,
            ProjectStatusEnum.Prospect,
            ProjectStatusEnum.ProspectProceeded,
            ProjectStatusEnum.DidNotProgress,
        ],
        [ProjectStatusEnum.ProspectProceeded]: [ProjectStatusEnum.Prospect, ProjectStatusEnum.ProspectProceeded, ProjectStatusEnum.DidNotProgress],
        [ProjectStatusEnum.DidNotProgress]: [ProjectStatusEnum.Prospect, ProjectStatusEnum.ProspectProceeded, ProjectStatusEnum.DidNotProgress],
        [ProjectStatusEnum.Quote]: [ProjectStatusEnum.Quote, ProjectStatusEnum.ValueSeparate],
        [ProjectStatusEnum.ValueSeparate]: [ProjectStatusEnum.Quote, ProjectStatusEnum.Lost, ProjectStatusEnum.ValueSeparate],
        [ProjectStatusEnum.Lost]: [ProjectStatusEnum.Lost, ProjectStatusEnum.ValueSeparate],
        [ProjectStatusEnum.Order]: [ProjectStatusEnum.Order],
        [ProjectStatusEnum.Master]: [ProjectStatusEnum.Master, ProjectStatusEnum.MasterComplete],
        [ProjectStatusEnum.MasterComplete]: [ProjectStatusEnum.MasterComplete],
        [ProjectStatusEnum.Despatched]: [ProjectStatusEnum.Despatched, ProjectStatusEnum.AftersalesOpen, ProjectStatusEnum.AftersalesClosed],
        [ProjectStatusEnum.AftersalesOpen]: [ProjectStatusEnum.Despatched, ProjectStatusEnum.AftersalesOpen, ProjectStatusEnum.AftersalesClosed],
        [ProjectStatusEnum.AftersalesClosed]: [ProjectStatusEnum.Despatched, ProjectStatusEnum.AftersalesOpen, ProjectStatusEnum.AftersalesClosed],
    };

    export const getText = (status: ProjectStatusEnum) => {
        switch (status) {
            case ProjectStatusEnum.ConditionReportConversion:
                return "Condition Report Conversion";

            case ProjectStatusEnum.ConditionReportCheck:
                return "Condition Report Check";

            case ProjectStatusEnum.Prospect:
                return "Prospect";

            // CMR Removed 05/10/2021
            //case ProjectStatusEnum.Qualified:
            //    return "Qualified";

            case ProjectStatusEnum.ProspectProceeded:
                return "Prospect Proceeded";

            case ProjectStatusEnum.DidNotProgress:
                return "Did Not Progress";

            case ProjectStatusEnum.Quote:
                return "Quote";

            case ProjectStatusEnum.ValueSeparate: {
                return "Value Separate";
            }

            case ProjectStatusEnum.Lost:
                return "Lost";

            case ProjectStatusEnum.Order:
                return "Order";

            case ProjectStatusEnum.Despatched:
                return "Despatched";

            case ProjectStatusEnum.Master:
                return "Master";

            case ProjectStatusEnum.MasterComplete:
                return "Master Complete";

            case ProjectStatusEnum.AftersalesOpen:
                return "Aftersales Open";

            case ProjectStatusEnum.AftersalesClosed:
                return "Aftersales Closed";

            case ProjectStatusEnum.Unknown:
            default:
                return "Unknown";
        }
    };

    export const getClassName = (status: ProjectStatusEnum) => {
        switch (status) {
            case ProjectStatusEnum.ConditionReportConversion:
                return "projectstatus-conditionreportconversion";

            case ProjectStatusEnum.ConditionReportCheck:
                return "projectstatus-conditionreportcheck";

            case ProjectStatusEnum.Prospect:
                return "projectstatus-prospect";

            // CMR Removed 05/10/2021
            //case ProjectStatusEnum.Qualified:
            //    return "projectstatus-qualified";

            case ProjectStatusEnum.ProspectProceeded:
                return "projectstatus-prospectproceeded";

            case ProjectStatusEnum.DidNotProgress:
                return "projectstatus-didnotprogress";

            case ProjectStatusEnum.Quote:
                return "projectstatus-quote";

            case ProjectStatusEnum.ValueSeparate: {
                return "projectstatus-valueseparate";
            }

            case ProjectStatusEnum.Lost:
                return "projectstatus-lost";

            case ProjectStatusEnum.Order:
                return "projectstatus-orderplaced";

            case ProjectStatusEnum.Despatched:
                return "projectstatus-despatched";

            case ProjectStatusEnum.Master:
                return "projectstatus-master";

            case ProjectStatusEnum.MasterComplete:
                return "projectstatus-mastercomplete";

            case ProjectStatusEnum.AftersalesOpen:
                return "projectstatus-aftersalesopen";

            case ProjectStatusEnum.AftersalesClosed:
                return "projectstatus-aftersalesclosed";

            case ProjectStatusEnum.Unknown:
            default:
                return "projectstatus-unknown";
        }
    };

    export const getProjectStatusOptions = (currentProjectStatus: ProjectStatusEnum, complaintsTotal: number, complaintsUnResolved: number): KeyValuePairExtended[] => {
        if (currentProjectStatus === ProjectStatusEnum.Unknown) {
            return [];
        }

        let availibleProjectStatuses: ProjectStatusEnum[] = ProjectStatusEnumHelper.AvailibleProjectStatusesByProjectStatus[currentProjectStatus];

        if (currentProjectStatus >= ProjectStatusEnum.Despatched && complaintsTotal > 0) {
            if (complaintsUnResolved > 0) {
                availibleProjectStatuses = [ProjectStatusEnum.AftersalesOpen];
            } else {
                availibleProjectStatuses = [ProjectStatusEnum.AftersalesClosed];
            }
        }

        const retVal: KeyValuePairExtended[] = availibleProjectStatuses.map((projectStatus: ProjectStatusEnum) => ({
            text: ProjectStatusEnumHelper.getText(projectStatus),
            key: projectStatus,
            class: ProjectStatusEnumHelper.getClassName(projectStatus),
        }));

        return retVal;
    };

    export const getAllProjectStatusOptions = (): KeyValuePairExtended[] => {
        const options: KeyValuePairExtended[] = [];

        for (const key in ProjectStatusEnum) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ProjectStatusEnum;

                if (item !== ProjectStatusEnum.Unknown && item !== ProjectStatusEnum.MasterComplete && item !== ProjectStatusEnum.Master) {
                    options.push({
                        key: keyAsInt.toString(),
                        text: ProjectStatusEnumHelper.getText(item),
                        class: ProjectStatusEnumHelper.getClassName(item),
                    });
                }
            }
        }

        return options;
    };

    export const getAllProjectStatuses = (): string[] => {
        const retVal: string[] = [];

        for (const key in ProjectStatusEnum) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ProjectStatusEnum;

                if (item !== ProjectStatusEnum.Unknown && item !== ProjectStatusEnum.MasterComplete && item !== ProjectStatusEnum.Master) {
                    retVal.push(keyAsInt.toString());
                }
            }
        }

        return retVal;
    };
}
