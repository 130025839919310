import { ICommand, RelayCommand } from "Application/Commands";
import { useState, useCallback } from "react";

interface ICalloutStateProps {
    // boolean value used by the Callout component
    isCalloutVisible: boolean;
    // target id is used to select the element from the DOM.
    targetId: string;
}

//This hooks abstracts and simplifies the implementation of the @fluentui/react Callout functionality

function useCallout() {
    //default state used for resetting
    const defaultCalloutState: ICalloutStateProps = { isCalloutVisible: false, targetId: "" };
    //calloutState
    const [calloutState, setCalloutState] = useState<ICalloutStateProps>(defaultCalloutState);
    const [infoCalloutId, setInfoCalloutId] = useState<string | null>(null);

    const setCalloutStateCommand: ICommand = new RelayCommand(
        (targetId: string, infoCalloutId: string) => {
            setCalloutState({ isCalloutVisible: true, targetId: targetId });
            setInfoCalloutId(infoCalloutId);
        },
        () => false === calloutState.isCalloutVisible,
    );

    const closeCalloutStateCommand: ICommand = new RelayCommand(
        () => {
            setCalloutState(defaultCalloutState);
            setInfoCalloutId(null);
        },
        () => calloutState.isCalloutVisible,
    );

    return {
        //open state used by the callout component
        calloutState,
        //id used in order to return any relevant data
        infoCalloutId,
        //command to set the active state
        setCalloutStateCommand,
        //command to reset the active state
        closeCalloutStateCommand,
    };
}

// Usage of this hook in a component would look like this:
// const { calloutState, infoCalloutId, setCalloutStateCommand, closeCalloutStateCommand } = useCallout();

export default useCallout;
