import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ComplaintStatusEnum } from "Models/Complaints/ComplaintStatusEnum";
import { AssignedUserDropdownItem } from "Models/Complaints/AssignedUserDropdownItem";
import { ComplaintNatureDropdownItem } from "Models/Complaints/ComplaintNatureDropdownItem";
import { ComplaintListItem } from "Models/Complaints/ComplaintListItem";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";

export class ComplaintGetForListRequest {
    public orderBy: SortOrderDirection = SortOrderDirection.ASC;
    public columnName: string = "";
    public pageSize: number = 100;
    public pageNumber: number = 1;

    public searchString: string = "";
    public showFrom: string = "";
    public showTo: string = "";
    public complaintStatus: ComplaintStatusEnum = ComplaintStatusEnum.Both;

    public filterByAssigned: number[] = [];
    public filterByNature: number[] = [];

    public initialLoad: boolean = true;

    public constructor() {}
}

export class ComplaintGetForListResponse {
    public complaints: ComplaintListItem[] = [];
    public complaintAssignees: AssignedUserDropdownItem[] = [];
    public complaintNatures: ComplaintNatureDropdownItem[] = [];
    public totalCount: number = 0;
    public constructor() {}
}

export class ComplaintGetForListEndpoint extends Endpoint<ComplaintGetForListRequest, ComplaintGetForListResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.GetForList);
    }

    public async HandleRequestAsync(request: ComplaintGetForListRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: ComplaintGetForListResponse): Promise<ComplaintGetForListResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
