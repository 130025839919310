import { IObservableArray, action, computed, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ViewPropertyModel, ViewPropertyModelValidator } from "./ViewPropertyModel";
import { GetPropertyDetailsByIdEndpoint, PropertyViewResponse } from "../EndPoints/GetPropertyDetailsById";
import { AddressConcatWithPostCode } from "Utils/Formats";
import { RoofSubstrateHelper } from "Models/Property/RoofSubstrateEnum";
import { MonthHelper } from "Models/MonthEnum";
import { PropertyMasterProjectListItem } from "./Models/PropertyMasterProjectListItem";
import { PropertyMultiSiteProjectListItem } from "./Models/PropertyMultiSiteProjectListItem";
import { PropertyProjectQuoteListItem } from "./Models/PropertyProjectQuoteListItem";
import { ProjectProductDropDown } from "Models/Project/ProjectProductDropDown";
import { DeleteMasterProjectByIdEndpoint, DeleteMasterProjectByIdRequest, DeleteMasterProjectResponse } from "../EndPoints/DeleteMasterProjectByIdEndpoint";
import { DeleteMultiSiteProjectByIdEndpoint } from "../EndPoints/DeleteMultiSiteProjectByIdEndpoint";
import { DeleteProjectQuoteByIdEndpoint } from "../EndPoints/DeleteProjectQuoteByIdEndpoint";

export class ViewPropertyViewModel extends ViewModelBase<ViewPropertyModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    public masterProjects: IObservableArray<PropertyMasterProjectListItem> = observable([]);
    public multiSiteProjects: IObservableArray<PropertyMultiSiteProjectListItem> = observable([]);
    public projectQuotes: IObservableArray<PropertyProjectQuoteListItem> = observable([]);
    public projectProducts: IObservableArray<ProjectProductDropDown> = observable([]);

    public projectQuoteCount: number = 0;
    public masterProjectCount: number = 0;
    public multiSiteProjectCount: number = 0;


    constructor() {
        super(new ViewPropertyModel());
        this.setValidator(new ViewPropertyModelValidator());
        makeObservable(this, {
            isProcessing: observable,

            projectQuoteCount: observable,
            masterProjectCount: observable,
            multiSiteProjectCount: observable,

            clear: action,

            deleteMasterProject: action,
            deleteMultiSiteProject: action,
            deleteProjectQuote: action,

            canShowPropertyMap: computed,
            getProperty: action,
            showAddressForGoogleMaps: computed,
        });
    }

    public get canShowPropertyMap(): boolean {
        let retVal: boolean = false;

        if(this.getValue("locationLatitude") !== null
            && this.getValue("locationLongitude") !== null
            && (this.getValue("locationLatitude") !== 0 || this.getValue("locationLongitude") !== 0)
        ) {
            return true;
        }

        return retVal;
    }

    public clear = () => {
        this.model.clear();
    };

    public async getProperty(propertyId: any): Promise<void> {
        if (propertyId !== undefined && this.isProcessing === false) {
            this.isProcessing = true;
            const endpoint = new GetPropertyDetailsByIdEndpoint(propertyId);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response<PropertyViewResponse>();
                    this.model.fromResponse(response.property);

                    this.masterProjects.replace(response.masterProjects);
                    this.multiSiteProjects.replace(response.multiSiteProjects);
                    this.projectQuotes.replace(response.projectQuotes);
                    this.projectProducts.replace(response.projectProduct);

                    this.isProcessing = false;
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("View property");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the property details.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }

    public get getAddress() {
        let retVal = "";

        retVal = AddressConcatWithPostCode(
            this.getValue("addressLine1"),
            this.getValue("addressLine2"),
            this.getValue("townCity"),
            this.getValue("county"),
            this.getValue("postCode"),
        );
        return retVal;
    }

    public get getRoofSubstrate() {
        return RoofSubstrateHelper.getText(this.getValue("roofSubstrate"));
    }

    public get getLeaseMonthAndYear() {
        if (this.getValue("endOfLeaseMonth") !== null && this.getValue("endOfLeaseYear") !== null) {
            return `${MonthHelper.getText(this.getValue("endOfLeaseMonth"))} ${this.getValue("endOfLeaseYear")}`;
        } else {
            return " ";
        }
    }

    public get showAddressForGoogleMaps(): boolean {
        return (
            this.getValue("locationLatitude") !== null &&
            this.getValue("locationLatitude") !== 0 &&
            this.getValue("locationLongitude") !== null &&
            this.getValue("locationLongitude") !== 0
        );
    }

    public get getAddressForGoogleMaps() {
        if (this.getValue("locationLatitude") !== null && this.getValue("locationLongitude") !== null) {
            return this.getValue("locationLatitude") + ", " + this.getValue("locationLongitude");
        } else {
            // return this.getValue("addressLine1") + " " + this.getValue("postCode");
            return this.getValue("postCode");
        }
    }

    public deleteMasterProject = async (deleteSelectionId: string, propertyId: string): Promise<any> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            const endpoint: DeleteMasterProjectByIdEndpoint = new DeleteMasterProjectByIdEndpoint();

            let request: DeleteMasterProjectByIdRequest = new DeleteMasterProjectByIdRequest();
            request.id = deleteSelectionId;
            request.propertyId = propertyId;

            await this.apiClient.sendAsync(endpoint, request);

            if (this.apiClient.IsRequestSuccessful === true) {
                let response: DeleteMasterProjectResponse = this.apiClient.Response<DeleteMasterProjectResponse>();

                runInAction(() => {
                    this.masterProjects.clear();
                    this.masterProjects.replace(response.masterProjects);
                    this.projectQuotes.clear();
                    this.projectQuotes.replace(response.projectQuotes);
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Master Project");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the master project.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public deleteMultiSiteProject = async (deleteSelectionId: string, propertyId: string): Promise<any> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            const endpoint: DeleteMultiSiteProjectByIdEndpoint = new DeleteMultiSiteProjectByIdEndpoint();
            let request: DeleteMasterProjectByIdRequest = new DeleteMasterProjectByIdRequest();
            request.id = deleteSelectionId;
            request.propertyId = propertyId;

            await this.apiClient.sendAsync(endpoint, request);

            if (this.apiClient.IsRequestSuccessful === true) {
                let response: PropertyMultiSiteProjectListItem[] = this.apiClient.Response<PropertyMultiSiteProjectListItem[]>();

                runInAction(() => {
                    this.multiSiteProjects.clear();
                    this.multiSiteProjects.replace(response);
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Multi Site Project");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the multi site project.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public deleteProjectQuote = async (deleteSelectionId: string, propertyId: string): Promise<any> => {
        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            const endpoint: DeleteProjectQuoteByIdEndpoint = new DeleteProjectQuoteByIdEndpoint();
            let request: DeleteMasterProjectByIdRequest = new DeleteMasterProjectByIdRequest();
            request.id = deleteSelectionId;
            request.propertyId = propertyId;

            await this.apiClient.sendAsync(endpoint, request);

            if (this.apiClient.IsRequestSuccessful === true) {
                let response: PropertyProjectQuoteListItem[] = this.apiClient.Response<PropertyProjectQuoteListItem[]>();

                runInAction(() => {
                    this.projectQuotes.clear();
                    this.projectQuotes.replace(response);
                    this.isProcessing = false;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Project Quote");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the project quote.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };
}
