import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ProjectStatusEnum } from "Models/Project/ProjectStatusEnum";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { PropertyMasterProjectListItem } from "../../Models/PropertyMasterProjectListItem";
import { MasterProjectViewModel } from "../MasterProjectViewModel";
import { AppUrls } from "AppUrls";

export class GetPropertyLinkedMasterAndRelatedRequest {
    public propertyId: Guid = "";
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public filterByStatus: ProjectStatusEnum[] = [];
    public initialLoad: boolean = true;
}

export class PropertyLinkedMasterAndRelatedResponse {
    public masterProjects: PropertyMasterProjectListItem[] = [];
    public propertyCount: number = 0;
}

export class GetPropertyLinkedMasterAndRelatedEndpoint extends Endpoint<GetPropertyLinkedMasterAndRelatedRequest, PropertyLinkedMasterAndRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path("api/property/getMasterProjectListItems");
    }

    public async HandleRequestAsync(viewModel: MasterProjectViewModel): Promise<any> {
        let request = new GetPropertyLinkedMasterAndRelatedRequest();
        request.propertyId = viewModel.model.propertyId;
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageNumber = viewModel.pageNumber;
        request.pageSize = viewModel.pageSize;

        request.filterByStatus = [];

        request.filterByStatus = viewModel.filterByStatus.map((item: string) => {
            return parseInt(item) as ProjectStatusEnum;
        });

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: PropertyLinkedMasterAndRelatedResponse): Promise<PropertyLinkedMasterAndRelatedResponse> {
        return response;
    }
}
