const semver = require("semver");

export const isPositiveInteger = (x: string) => {
    // http://stackoverflow.com/a/1019526/11236
    return /^\d+$/.test(x);
};

export const isValidNumber = (value?: string | number): boolean => {
    return value != null && value != undefined && value !== "" && !isNaN(Number(value.toString()));
};

const validateParts = (parts: string[]) => {
    return parts.every((part) => isPositiveInteger(part));
};

//AJ: https://stackoverflow.com/questions/6832596/how-to-compare-software-version-number-using-js-only-number
export const versionNumbers = (v1: string, v2: string) => {
    if (semver.gt(v1, v2)) {
        return 1;
    } else if (semver.lt(v1, v2)) {
        return -1;
    }

    return 0;
};

//export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
//    return typeof obj === "undefined" || obj === null;
//}
