import { ModelBase } from "@shoothill/core";
import { makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";

export class ResetPasswordModel extends ModelBase<ResetPasswordModel> {
    public newPassword: string = "";
    public confirmPassword: string = "";
    public token: string = "";

    constructor() {
        super();
        makeObservable(this, {
            newPassword: observable,
            confirmPassword: observable,
            token: observable,
        });
    }
}

export class ResetPasswordValidator extends Validator<ResetPasswordModel> {
    constructor() {
        super();

        this.ruleFor("newPassword").notEmpty().withMessage("Please enter your password").maxLength(100).withMessage("Please enter your password");
        this.ruleFor("confirmPassword")
            .notEmpty()
            .withMessage("Please enter your confirmPassword")
            .maxLength(100)
            .must({ predicate: (confirmPassword, model) => confirmPassword === model.newPassword, message: (value, model) => "Passwords must match" });
    }
}
