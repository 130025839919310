import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ThemedLoader } from "Application";
import { HRDashedGrey, HRSolidGrey } from "Components/HorizontalRules";
import { H3, P } from "Components/Primitives/TextElements/TextElements";
import { DetailsViewModel } from "./DetailsViewModel";
import { hyphenDateIfNoValue, hyphenIfNoValue } from "Utils/Formats";
import LocationIconBoxed from "Assets/Icons/LocationIconBoxed.svg";
import { BooleanTickOrCrossCell, newLinesToBreak } from "Utils/CellFormatComponents";
import { container } from "tsyringe";
import { CustomerStore } from "Stores/Domain";
import { PageTabWrapper } from "Styles/BaseStyles";
interface IDetailsView {
    id?: string;
}

export const DetailsView: React.FC<IDetailsView> = observer(({ id }) => {
    const customerStore = container.resolve(CustomerStore);
    const [viewModel] = useState(new DetailsViewModel());
    useEffect(() => {
        viewModel.getCustomerDetails(id);
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    return (
        <>
            {viewModel.isProcessing === true ? (
                <ThemedLoader spinnerText="Loading..." isOpen={true} />
            ) : (
                <PageTabWrapper>
                    <Box flexBox>
                        <H3 p="20px 0px">{viewModel.getCustomerAddress}</H3>
                        {/* TODO swap out for buttons? */}
                        {viewModel.showAddressForGoogleMaps && (
                            <Box p="20px 10px">
                                <a href={`https://www.google.co.uk/maps/place/${viewModel.getAddressForGoogleMaps}`} target="_blank" rel="noreferrer">
                                    <img src={LocationIconBoxed} />
                                </a>
                            </Box>
                        )}
                        {/* <img src={EyeIconBoxed} /> */}
                    </Box>
                    <Box flexBox gap="20px">
                        <P>
                            <strong>Phone: </strong> {hyphenIfNoValue(viewModel.getValue("phone"))}
                        </P>
                        <P>
                            <strong>Additional Phone: </strong> {hyphenIfNoValue(viewModel.getValue("phone2"))}
                        </P>
                        <P>
                            <strong>Email: </strong> {hyphenIfNoValue(viewModel.getValue("email"))}
                        </P>
                        <P>
                            <strong>Website: </strong> {hyphenIfNoValue(viewModel.getValue("websiteAddress"))}
                        </P>
                    </Box>
                    <HRDashedGrey />
                    <Box grid dc="1fr 1fr 1fr">
                        <Box grid dc="1fr 1fr 1fr 1fr 1fr 1fr" p="10px 0px 20px 0px">
                            <P>
                                <strong>Type: </strong>
                            </P>
                            <P>{customerStore.getCustomerTypeText(viewModel.getCustomerType)}</P>

                            {customerStore.isDataLoaded === true && customerStore.getMatrixOptionsForCustomerType(viewModel.getCustomerType)?.hasTSM === true && (
                                <>
                                    <P>
                                        <strong>Lead TSM: </strong>
                                    </P>

                                    <P>{hyphenIfNoValue(viewModel.getValue("leadTsm"))}</P>
                                </>
                            )}

                            {customerStore.isDataLoaded === true && customerStore.getMatrixOptionsForCustomerType(viewModel.getCustomerType)?.hasEORINumber === true && (
                                <>
                                    <P>
                                        <strong>EORI number: </strong>
                                    </P>
                                    <P>{hyphenIfNoValue(viewModel.getValue("eori"))}</P>
                                </>
                            )}

                            {customerStore.isDataLoaded === true && customerStore.getMatrixOptionsForCustomerType(viewModel.getCustomerType)?.hasSageRef === true && (
                                <>
                                    <P>
                                        <strong>Sage ref: </strong>
                                    </P>
                                    <P>{hyphenIfNoValue(viewModel.getValue("sageRef"))}</P>
                                </>
                            )}

                            {customerStore.isDataLoaded === true && customerStore.getMatrixOptionsForCustomerType(viewModel.getCustomerType)?.hasBDM === true && (
                                <>
                                    <P>
                                        <strong>BDM: </strong>
                                    </P>
                                    <P>{hyphenIfNoValue(viewModel.getValue("bdm"))}</P>
                                </>
                            )}

                            {customerStore.isDataLoaded === true && customerStore.getMatrixOptionsForCustomerType(viewModel.getCustomerType)?.hasTSSNumber === true && (
                                <>
                                    <P>
                                        <strong>TSS number: </strong>
                                    </P>
                                    <P>{hyphenIfNoValue(viewModel.getValue("tss"))}</P>
                                </>
                            )}

                            {customerStore.isDataLoaded === true && customerStore.getMatrixOptionsForCustomerType(viewModel.getCustomerType)?.hasReferral === true && (
                                <>
                                    <P>
                                        <strong>Referral: </strong>
                                    </P>
                                    <P>
                                        <BooleanTickOrCrossCell value={viewModel.getValue("referral")} alignLeft={true} />
                                    </P>
                                </>
                            )}
                        </Box>
                    </Box>
                    <HRDashedGrey />
                    <Box>
                        <P>
                            <strong>Customer Note: </strong> {hyphenIfNoValue(newLinesToBreak(viewModel.getValue("note")))}
                        </P>
                    </Box>
                    <HRDashedGrey />
                    <Box grid dc="1fr 1fr">
                        <Box grid dc="1fr 1fr 1fr 1fr" p="10px 0px 20px 0px">
                            <P>
                                <strong>Coatings Trained: </strong>
                            </P>
                            {viewModel.getValue("coatingsTrained") === true && viewModel.getValue("dateCoatingsTrained") === null ? (
                                <BooleanTickOrCrossCell value={true} />
                            ) : (
                                <P>{hyphenDateIfNoValue(viewModel.getValue("dateCoatingsTrained"))}</P>
                            )}
                            <P>
                                <strong>Plygene Trained: </strong>
                            </P>
                            {viewModel.getValue("plygeneTrained") === true && viewModel.getValue("datePlygeneTrained") === null ? (
                                <BooleanTickOrCrossCell value={true} />
                            ) : (
                                <P>{hyphenDateIfNoValue(viewModel.getValue("datePlygeneTrained"))}</P>
                            )}
                        </Box>
                        <Box grid dc="1fr 1fr 1fr 1fr" p="10px 0px 20px 0px">
                            <P>
                                <strong>Coatings CPD: </strong>
                            </P>
                            {viewModel.getValue("coatingsCPD") === true && viewModel.getValue("dateCoatingsCPD") === null ? (
                                <BooleanTickOrCrossCell value={true} />
                            ) : (
                                <P>{hyphenDateIfNoValue(viewModel.getValue("dateCoatingsCPD"))}</P>
                            )}
                            <P>
                                <strong>Plygene CPD: </strong>
                            </P>
                            {viewModel.getValue("plygeneCPD") === true && viewModel.getValue("datePlygeneCPD") === null ? (
                                <BooleanTickOrCrossCell value={true} />
                            ) : (
                                <P>{hyphenDateIfNoValue(viewModel.getValue("datePlygeneCPD"))}</P>
                            )}
                        </Box>
                    </Box>
                    <HRSolidGrey />
                </PageTabWrapper>
            )}
        </>
    );
});
