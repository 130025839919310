import React from "react";
import { SessionExpiredView } from "./Views/SessionExpired/SessionExpiredView";
import { Error } from "Views/Error/Error";
import { AppUrlIndex, AppUrls } from "./AppUrls";
import { Route, Route as ReactRoute, Routes, Navigate, useLocation } from "react-router-dom";
import { DebugRouter } from "./Components/DebugRouter";
import LoginHomeView from "./Views/Login/LoginHomeView";
import { DefaultLayout } from "./Views/Layouts";
import { SplitLayout } from "./Views/Layouts/SplitLayout";
import { StyleSheetView } from "Application/Examples/StyleSheetView";
import { PrivateRoute, ThemedLoader } from "./Components";
import ConfirmEmailView from "./Views/Login/SignUp/ConfirmEmail/ConfirmEmailView";
import { ResetPasswordView } from "./Views/Login/SignIn/ForgotPassword/ResetPasswordView";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";
import { ConditionReportRoutes } from "Views/ConditionReport/ConditionReportRoutes";
import { DashboardRoutes } from "Views/Dashboard/DashboardRoutes";
import { TSMRoutes } from "Views/TSM/TSMRoutes";
import { AftersalesRoutes } from "Views/Aftersales/AftersalesRoutes";
import { DirectoryRoutes } from "Views/Directory/DirectoryRoutes";
import { FallbackPageView } from "Components/FallbackPage";
import { TasksAddEditView } from "Views/Tasks/AddEdit/TasksAddEditView";
import { ReportRoutes } from "Views/Reports/ReportRoutes";

const LogoutView = React.lazy(() => import("Views/Logout/LogoutView"));
const AdminRoutes = React.lazy(() => import("./Views/Admin/AdminRoutes"));
const WorkRoutes = React.lazy(() => import("Views/Work/WorkRoutes"));

export const AppRoutes: React.FC = () => {
    const accountStore = container.resolve(AccountStore);

    let temp: string = "dashboard";
    if (accountStore.loginRedirectToConditionReport() === true) {
        temp = "conditionreport";
    }

    return (
        <DebugRouter>
            <Routes>
                <Route element={<PrivateRoute isAllowed={() => accountStore.IsLoggedIn} redirectPath={AppUrls.Client.Account.Login} />}>
                    <Route path={"/"} element={<DefaultLayout />}>
                        <Route index element={<Navigate to={temp} replace />} />

                        <Route
                            path={AppUrls.Client.Aftersales.Default[AppUrlIndex.Long]}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <AftersalesRoutes />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.Work.Tasks.AddEdit[AppUrlIndex.Long]}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <TasksAddEditView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.Work.Tasks.AddEdit[AppUrlIndex.Short]}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <TasksAddEditView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.Work.Tasks.AddEdit[2]}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <TasksAddEditView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.Work.Tasks.AddEdit[3]}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <TasksAddEditView />
                                </React.Suspense>
                            }
                        />

                        <Route
                            element={
                                <PrivateRoute
                                    isAllowed={() => accountStore.IsLoggedIn && accountStore.isCRUserOrAdmin()}
                                    redirectPath={
                                        accountStore.initialState.appSettings.conditionReportsOnly === true
                                            ? AppUrls.Client.ConditionReport.Default[AppUrlIndex.Long]
                                            : AppUrls.Client.Dashboard.Tasks[AppUrlIndex.Long]
                                    }
                                />
                            }
                        >
                            <Route
                                path={AppUrls.Client.ConditionReport.Default[AppUrlIndex.Long]}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <ConditionReportRoutes />
                                    </React.Suspense>
                                }
                            />
                        </Route>

                        <Route
                            path={AppUrls.Client.Dashboard.Default[AppUrlIndex.Long]}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <DashboardRoutes />
                                </React.Suspense>
                            }
                        />

                        <Route
                            path={AppUrls.Client.Directory.Default[AppUrlIndex.Long]}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <DirectoryRoutes />
                                </React.Suspense>
                            }
                        />

                        <Route
                            element={
                                <PrivateRoute
                                    isAllowed={() => accountStore.IsLoggedIn && accountStore.isTSMOrAdmin()}
                                    redirectPath={AppUrls.Client.Dashboard.Tasks[AppUrlIndex.Long]}
                                />
                            }
                        >
                            <Route
                                path={AppUrls.Client.TSM.Default[AppUrlIndex.Long]}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <TSMRoutes />
                                    </React.Suspense>
                                }
                            />
                        </Route>

                        <Route
                            element={
                                <PrivateRoute
                                    isAllowed={() => accountStore.IsLoggedIn && accountStore.isCRUserOrCRMUserOrAdmin()}
                                    redirectPath={AppUrls.Client.Reports.Reports[AppUrlIndex.Long]}
                                />
                            }
                        >
                            <Route
                                path={AppUrls.Client.Reports.Default[AppUrlIndex.Long]}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <ReportRoutes />
                                    </React.Suspense>
                                }
                            />
                        </Route>

                        <Route
                            path={AppUrls.Client.Work.Default[AppUrlIndex.Long]}
                            element={
                                <React.Suspense fallback={<FallbackPageView />}>
                                    <WorkRoutes />
                                </React.Suspense>
                            }
                        />

                        <Route path={AppUrls.Client.Account.SessionExpired} element={<SessionExpiredView />} />
                        <Route path={AppUrls.Client.Error} element={<Error supportEmail={"support@shoothill.com"} />} />
                        <Route path={"/stylesheet"} element={<StyleSheetView />} />
                        {/*                             <Route
                                path={AppUrls.Client.PageBuilder}
                                element={
                                    <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                        <PageBuilderView />
                                    </React.Suspense>
                                }
                            /> */}

                        <Route
                            path={"/admin/*"}
                            element={
                                <React.Suspense fallback={<ThemedLoader isOpen={true} />}>
                                    <AdminRoutes />
                                </React.Suspense>
                            }
                        />
                    </Route>
                </Route>
                <Route element={<SplitLayout />}>
                    <Route path={AppUrls.Client.ConfirmPassword} element={<ConfirmEmailView confirmEmail={true} />} />
                    <Route path={AppUrls.Client.ResetPassword} element={<ResetPasswordView />} />
                    <Route path={"/account/*"} element={<LoginHomeView />} />
                    <Route
                        path={AppUrls.Client.Account.Logout}
                        element={
                            <React.Suspense fallback={<ThemedLoader spinnerText="Logging out..." isOpen={true} />}>
                                <LogoutView />
                            </React.Suspense>
                        }
                    />
                </Route>

                <ReactRoute
                    path={AppUrls.Client.Account.Logout}
                    element={
                        <React.Suspense fallback={<ThemedLoader spinnerText="Logging out..." isOpen={true} />}>
                            <LogoutView />
                        </React.Suspense>
                    }
                />

                {/*404 Route*/}
                <ReactRoute
                    path={"*"}
                    element={
                        <main style={{ padding: "1rem" }}>
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
            </Routes>
        </DebugRouter>
    );
};
