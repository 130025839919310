import { Box, Button, ICommand } from "Application";
import { CloseIcon } from "./CloseIcon";
import styled from "@emotion/styled";

interface IFooterProps {
    onDismissCommand: ICommand;
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        margin-right: 5px;
    }
`;

export const Footer: React.FC<IFooterProps> = ({ onDismissCommand }) => {
    return (
        <Container>
            <Button displayName="Close" paletteColor="ButtonCancel" command={onDismissCommand} icon={<CloseIcon />} boxShadow />
        </Container>
    );
};
