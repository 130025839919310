import { KeyValuePair } from "@shoothill/core";

export enum Timescale {
    All = 1,
    ThreeYears = 2,
    OneYear = 3,
    ThreeMonths = 4,
    OneMonth = 5,
    OneWeek = 6,
    Today = 7,
}

export module TimeScaleHelper {
    export const getText = (value: Timescale) => {
        let retVal: string = "All";

        switch (value) {
            case Timescale.OneMonth: {
                retVal = "1 Month";
                break;
            }
            case Timescale.OneWeek: {
                retVal = "1 Week";
                break;
            }
            case Timescale.OneYear: {
                retVal = "1 Year";
                break;
            }
            case Timescale.ThreeMonths: {
                retVal = "3 Months";
                break;
            }
            case Timescale.ThreeYears: {
                retVal = "3 Years";
                break;
            }
            case Timescale.Today: {
                retVal = "Today";
                break;
            }
            case Timescale.All:
            default: {
                break;
            }
        }
        return retVal;
    };

    export const getOptionsDropdown = (): KeyValuePair[] => {
        let retVal: KeyValuePair[] = [];

        for (const key in Timescale) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as Timescale;
                retVal.push({
                    text: TimeScaleHelper.getText(item),
                    key: keyAsInt.toString(),
                });
            }
        }

        return retVal;
    };
}
