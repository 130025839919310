import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { GenericComplaintActionListItem } from "./GetCorrectiveActionForView";
import { PreventativeActionModel } from "../PreventativeAction/PreventativeActionModel";

class UpsertPreventativeActionRequest {
    id: number | null = null;
    action: string = "";
    rowVersion: string = "";
    projectComplaintId: string = "";
}

export class UpsertPreventativeActionResponse {
    actions: GenericComplaintActionListItem[] = [];
}

export class UpsertPreventativeActionEndpoint extends Endpoint<UpsertPreventativeActionRequest, UpsertPreventativeActionResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.PreventativeActionUpsert);
    }

    public async HandleRequestAsync(model: PreventativeActionModel): Promise<any> {
        let request = new UpsertPreventativeActionRequest();
        request.id = model.actionId;
        request.action = model.preventativeAction;
        request.rowVersion = model.rowVersion;
        request.projectComplaintId = model.projectComplaintId;
        return request;
    }

    public async HandleResponseAsync(response: UpsertPreventativeActionResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
