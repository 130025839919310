import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { createColumnHelper } from "@tanstack/react-table";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { Box, ICommand, RelayCommand, formatDate } from "Application";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair } from "@shoothill/core";
import { LinkedProjectQuotesViewModel } from "./LinkedProjectQuotesViewModel";
import { H5 } from "Components/Primitives/TextElements/TextElements";
import { ProjectStatusBox, ProjectStatusWrapper } from "Views/Work/Common/ProjectsList.styles";
import { ProductTypeSelectWrapper } from "Styles/BaseStyles";
import { ProjectStatusEnumHelper } from "Models/Project/ProjectStatusEnum";
import { CenteredTextCell } from "Utils/CellFormatComponents";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";

import { useNavigate } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { formatCurrency } from "Utils/Formats";
interface ILinkedProjectQuotes {
    id?: string;
    projectQuoteCountCallBack: (newCount: number) => void;
}

export const LinkedProjectQuotesView: React.FC<ILinkedProjectQuotes> = observer((props) => {
    const [viewModel] = useState(() => new LinkedProjectQuotesViewModel(props?.id, props.projectQuoteCountCallBack));
    let debounceTimer: NodeJS.Timeout | null = null;
    const [isFiltering, setIsFiltering] = useState(false);
    let navigate = useNavigate();
    useEffect(() => {
        if (props?.id) {
            viewModel.getLinkedProjectQuotes();
        }
        return () => {
            viewModel.clear();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("number", {
            size: 80,
            header: () => "Prj no",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("projectName", {
            size: 310,
            header: () => "Project name",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("postcode", {
            size: 80,
            header: () => "Postcode",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("projectProduct", {
            size: 310,
            header: () => "Product",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("createdDate", {
            header: () => "Created date",
            cell: (info) => <CenteredTextCell value={formatDate(new Date(info.renderValue()))} />,
        }),

        columnHelper.accessor("netValue", {
            header: () => "Net value",
            cell: (info) => formatCurrency(info.renderValue()),
        }),

        columnHelper.accessor("wonValue", {
            header: () => "Won value",
            cell: (info) => formatCurrency(info.renderValue()),
        }),

        columnHelper.accessor("openValue", {
            header: () => "Open value",
            cell: (info) => formatCurrency(info.renderValue()),
        }),

        columnHelper.accessor("lostValue", {
            header: () => "Lost value",
            cell: (info) => formatCurrency(info.renderValue()),
        }),

        columnHelper.accessor("overallStatus", {
            header: () => "Status",
            cell: (info) => {
                if (info.getValue() !== undefined) {
                    return (
                        <ProjectStatusBox className={ProjectStatusEnumHelper.getClassName(info.getValue())}>
                            <div>{ProjectStatusEnumHelper.getText(info.getValue())}</div>
                        </ProjectStatusBox>
                    );
                } else {
                    return "";
                }
            },
        }),
    ];

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.getLinkedProjectQuotes();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.getLinkedProjectQuotes();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.getLinkedProjectQuotes();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.getLinkedProjectQuotes();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.getLinkedProjectQuotes();

                break;
            default:
        }
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;
        viewModel.getLinkedProjectQuotes();
    };

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", rowId.toString()) + "/details";
        navigate(path);
    }

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.getLinkedProjectQuotes();
            setIsFiltering(false);
        }, 1200);
    };

    // Category filtering

    const updateCategoryFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateProductFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        debounceFilter();
    });

    const formatOptionLabel = ({ value, label, cls }: any) => <p className={getClassName(value)}>{label}</p>;

    const getClassName = (value: number) => {
        let retVal = "";
        let type = viewModel.projectProducts.find((productType) => productType.id === value);

        if (type?.parentId === 0) {
            retVal = "parent";
        } else if (type?.parentId !== 0) {
            retVal = "child";
        }
        return retVal;
    };

    const productSelectAll = (): void => {
        viewModel.productSelectAll();
        viewModel.getLinkedProjectQuotes();
    };

    const productSelectNone = (): void => {
        viewModel.productSelectNone();
        viewModel.getLinkedProjectQuotes();
    };

    //Status filtering
    const updateStatusFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateStatusFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        debounceFilter();
    });

    const statusSelectAll = (): void => {
        viewModel.statusSelectAll();
        viewModel.getLinkedProjectQuotes();
    };

    const statusSelectNone = (): void => {
        viewModel.statusSelectNone();
        viewModel.getLinkedProjectQuotes();
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <ProductTypeSelectWrapper>
                <MultiEditSelect
                    command={updateCategoryFiltering}
                    displayName="Category"
                    options={viewModel.getProductTypesForDropdown}
                    value={() => viewModel.getProductFilter}
                    showSelectAllNone={true}
                    selectAll={productSelectAll}
                    selectNone={productSelectNone}
                    formatOptionLabel={formatOptionLabel}
                    isOptionDisabled={(option) => option.class === "parent"}
                    isDisabled={viewModel.isProcessing}
                />
            </ProductTypeSelectWrapper>
            <ProjectStatusWrapper>
                <MultiEditSelect
                    command={updateStatusFiltering}
                    displayName="Filter by status"
                    options={viewModel.getStatuses}
                    value={() => viewModel.getStatusFilter}
                    showSelectAllNone={true}
                    selectAll={statusSelectAll}
                    selectNone={statusSelectNone}
                    isDisabled={viewModel.isProcessing}
                />
            </ProjectStatusWrapper>
        </FilteringBottomSectionBox>
    );

    return (
        <>
            <Box>
                <H5 pt="12px" pb="12px">
                    <strong>Project quotes linked to this customer</strong>
                </H5>
                <SharmansTable
                    columns={columns}
                    data={viewModel.getProjects}
                    filteringElementsBottomSection={FilteringBottomSection}
                    onSortChange={updateSorting}
                    tableHeaderColour="#D4D4D4"
                    tableFilteringColour="#EDEDED"
                    totalRowCount={viewModel.customerCount}
                    onChangePage={onChangePage}
                    onChangePageNumber={viewModel.changePageNumber}
                    onChangeRowPerPage={onChangeRowPerPage}
                    showPagination={true}
                    totalPageCount={viewModel.pageCount}
                    pageNumber={viewModel.pageNumber}
                    pageSize={viewModel.pageSize}
                    clearFilteringCommand={viewModel.clearFiltering}
                    onRowClick={onRowClick}
                />
            </Box>
        </>
    );
});
