import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConfirmEmailViewModel } from "../ConfirmEmail/ConfirmEmailViewModel";

class Request {
    public emailAddress: string = "";
}

class Response {
    errors: ErrorResponse[] = [];
    isSuccessful: boolean = false;
}

class ErrorResponse {
    public message: string | null = null;
    public propertyName: string | null = null;
    public statusCode: number | null = null;
    public statusDescription: string | null = null;
    public traceId: string | null = null;
    public type: number | null = null;
}

export class PostResendEmailConfirmationEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: ConfirmEmailViewModel;
    constructor(viewModel: ConfirmEmailViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Account.ResendEmailConfirmation);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        let request = new Request();

        request.emailAddress = this.viewModel.getValue("confirmEmail");

        return request;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logDebug("Response", response);

        //Return your model
        return {};
    }
}
