import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConfirmEmailViewModel } from "../ConfirmEmail/ConfirmEmailViewModel";

class Request {
    public token: string = "";
    public password: string = "";
}

class Response {
    public userEmail: string = "";
}

export class PostCreateNewPasswordEndpoint extends Endpoint<Request, Response> {
    private readonly viewModel: ConfirmEmailViewModel;
    constructor(viewModel: ConfirmEmailViewModel) {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Account.CreateNewPassword);
        this.viewModel = viewModel;
    }

    public async HandleRequestAsync(): Promise<any> {
        let request = new Request();

        request.password = this.viewModel.getValue("password");
        request.token = this.viewModel.getValue("token");

        return request;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logDebug("Response", response);
        //this.viewModel.parentViewModel.parentViewModel.loginViewModel.setValue("emailAddress", response.userEmail);
        //Return your model
        return {};
    }
}
