import { AppUrls } from "AppUrls";
import { Endpoint, Http } from "Application";
import { PropertiesListViewModel } from "../List/PropertiesListViewModel";
import { getFilenameFromContentDisposition } from "Utils/File";

class Request {
    orderBy: string = "";
    columnName: string = "";
    searchString: string = "";
    filterByAttendedSite: string[] = [];
    public initialLoad: boolean = true;
}

export class DownloadPropertyCSVEndpoint extends Endpoint<Request, Response> {
    private viewModel: PropertiesListViewModel;

    constructor(viewModel: PropertiesListViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.DownloadCSV);
        this.responseIsBlob(true);
        this.SetContentType("text/csv");
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new Request();
        request.columnName = this.viewModel.columnName;
        request.orderBy = this.viewModel.orderBy;
        request.searchString = this.viewModel.searchString;
        request.filterByAttendedSite = this.viewModel.filterByAttendedSite.map((item) => {
            return item;
        });

        request.initialLoad = this.viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: any): Promise<boolean> {
        let retVal: boolean = true;
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        //link.href = window.URL.createObjectURL(new Blob([response]));
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        //Return your model
        return retVal;
    }
}
