import React, { SyntheticEvent, useState } from "react";
import { DeletedTickCentered } from "Components/DeletedTickCentered";
import { Box, Button, ICommand } from "Application";
import styled from "@emotion/styled";
import PhoneRoundIcon from "Assets/Icons/PhoneRoundIcon.svg";
import EmailRoundIcon from "Assets/Icons/EmailRoundIcon.svg";
import { P } from "Components/Primitives/TextElements/TextElements";
import RedFlag from "Assets/Icons/RedFlagIcon.svg";
import TickRed from "Assets/Icons/TickRed.svg";
import TickGreen from "Assets/Icons/TickGreen.svg";
import { ContactStatus } from "Application/Models/Domain/Contact/ContactStatusEnum";
import { CustomerStatus } from "Application/Models/Domain/Customer/CustomerStatusEnum";

export interface IBooleanTickOrHyphenCell {
    value: boolean;
}

export const BooleanTickOrHyphenCell: React.FC<IBooleanTickOrHyphenCell> = (props) => {
    if (props.value === true) {
        return (
            <>
                <DeletedTickCentered tickOrHyphen="tick" />
            </>
        );
    }

    return <DeletedTickCentered tickOrHyphen="hyphen" />;
};

export interface IBooleanTickOrCrossCell {
    value: boolean;
    alignLeft?: boolean;
}

export const BooleanTickOrCrossCell: React.FC<IBooleanTickOrCrossCell> = (props) => {
    if (props.value === true) {
        return (
            <Box flexBox style={{ justifyContent: props.alignLeft ? "left" : "center" }}>
                <img src={TickGreen} />
            </Box>
        );
    }

    return (
        <Box flexBox style={{ justifyContent: props.alignLeft ? "left" : "center" }}>
            <img src={TickRed} />
        </Box>
    );
};
export interface IHyphenIfNoValueCell {
    value: any;
}
export const HyphenIfNoValueCell: React.FC<IHyphenIfNoValueCell> = (props) => {
    if (props.value === "" || props.value === null || props.value === undefined) {
        return (
            <Box flexBox justifyContent="center">
                <p>-</p>
            </Box>
        );
    }

    return props.value;
};

export interface ICustomerStatusButtonCell {
    status: CustomerStatus;
    command: ICommand<any>;
}

export const CustomerStatusButtonCell: React.FC<ICustomerStatusButtonCell> = (props) => {
    const buttonStyle = { minHeight: "20px", minWidth: "89px", padding: 0 };

    switch (Number(props.status)) {
        case CustomerStatus.Active:
            return (
                <CenteredCell>
                    <Button displayName="Active" value={props.status} command={props.command} style={buttonStyle} paletteColor="ButtonActive" />
                </CenteredCell>
            );
        case CustomerStatus.InActive:
            return (
                <CenteredCell>
                    <Button displayName="Inactive" value={props.status} command={props.command} style={buttonStyle} paletteColor="ButtonInactive" />
                </CenteredCell>
            );
        case CustomerStatus.Dissolved:
            return (
                <CenteredCell>
                    <Button displayName="Dissolved" value={props.status} command={props.command} style={buttonStyle} paletteColor="ButtonDissolved" />
                </CenteredCell>
            );

        default:
            return <>-</>;
    }
};

export interface IPhoneNumberWithIconCell {
    value: any;
}
export const PhoneNumberWithIconCell: React.FC<IPhoneNumberWithIconCell> = (props) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const copyToClipboard = (e: any) => {
        e.stopPropagation();
        navigator.clipboard.writeText(props.value);
        setShowConfirmation(true);
        setTimeout(() => {
            setShowConfirmation(false);
        }, 2000);
    };

    if (props.value !== "" && props.value !== null && props.value !== undefined) {
        if (showConfirmation === false) {
            return (
                <Box flexBox alignItems="center" gap={0}>
                    <img src={PhoneRoundIcon} onClick={(e: any) => copyToClipboard(e)} />
                    <P pl="10px">{props.value}</P>
                </Box>
            );
        } else {
            return (
                <Box p="3px" style={{ backgroundColor: "#337ab7", borderRadius: "10px", display: "flex", justifyContent: "center" }}>
                    <P color="white">Copied to clipboard</P>
                </Box>
            );
        }
    } else {
        return (
            <Box flexBox justifyContent="center">
                <p>-</p>
            </Box>
        );
    }
};

export interface IEmailWithIconCell {
    value: any;
}
export const EmailWithIconCell: React.FC<IEmailWithIconCell> = (props) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const copyToClipboard = (e: any) => {
        e.stopPropagation();

        navigator.clipboard.writeText(props.value);
        setShowConfirmation(true);
        setTimeout(() => {
            setShowConfirmation(false);
        }, 2000);
    };

    if (props.value !== "" && props.value !== null && props.value !== undefined) {
        if (showConfirmation === false) {
            return (
                <Box flexBox alignItems="center" gap={0}>
                    <img src={EmailRoundIcon} onClick={(e: any) => copyToClipboard(e)} />
                    <P pl="10px">{props.value}</P>
                </Box>
            );
        } else {
            return (
                <Box p="3px" style={{ backgroundColor: "#337ab7", borderRadius: "10px", display: "flex", justifyContent: "center" }}>
                    <P color="white">Copied to clipboard</P>
                </Box>
            );
        }
    } else {
        return (
            <Box flexBox justifyContent="center">
                <p>-</p>
            </Box>
        );
    }
};

export interface IContactNamePrimaryContactCell {
    name?: string;
    primaryContact?: boolean;
}
export const ContactNamePrimaryContactCell: React.FC<IContactNamePrimaryContactCell> = (props) => {
    return (
        <Box flexBox justifyContent="space-between">
            <p>{props.name}</p>
            {props.primaryContact === true && <img src={RedFlag} />}
        </Box>
    );
};

export interface ICenteredTextCell {
    value: any;
    greenText?: boolean;
    boldText?: boolean;
}

export const CenteredTextCell: React.FC<ICenteredTextCell> = (props) => {
    if (props.value === "" || props.value === null || props.value === undefined) {
        return (
            <Box flexBox justifyContent="center">
                <p>-</p>
            </Box>
        );
    }

    return (
        <Box flexBox justifyContent="center">
            <P style={{ color: props.greenText ? "#5EB459" : "", fontWeight: props.boldText ? "bold" : "normal" }}>{props.value}</P>
        </Box>
    );
};

export interface IEllipsisExpand {
    text: string;
}

export const ExpandEllipsis: React.FC<IEllipsisExpand> = (props) => {
    const [expand, setExpand] = useState(false);
    let retVal: string = "";

    if (expand) {
        retVal = props.text;
    } else {
        // If over a certain length chop off end and add ...
        if (props.text.length < 20) {
            retVal = props.text;
        } else {
            retVal = props.text.slice(0, 100) + " ...";
        }
    }

    return (
        <P
            onClick={() => {
                setExpand(!expand);
            }}
        >
            {retVal}
        </P>
    );
};

export interface IContactStatusButtonCell {
    status: ContactStatus;
    command: ICommand<any>;
}

export const ContactStatusButtonCell: React.FC<IContactStatusButtonCell> = (props) => {
    const CenteredCell = styled(Box)`
        display: flex;
        justify-content: center;
    `;

    const FlatButton = styled(Button)`
        width: 100%;
        padding: 2px 10px !important;
        min-height: 10px;
    `;

    switch (Number(props.status)) {
        case ContactStatus.Lead:
            return (
                <>
                    <CenteredCell>
                        <FlatButton displayName="Lead" value={props.status} command={props.command} paletteColor="ButtonBlue" />
                    </CenteredCell>
                </>
            );
        case ContactStatus.Trained:
            return (
                <>
                    <CenteredCell>
                        <FlatButton displayName="Trained" value={props.status} command={props.command} paletteColor="ButtonBlue" />
                    </CenteredCell>
                </>
            );
        case ContactStatus.TrainingExpired:
            return (
                <>
                    <CenteredCell>
                        <FlatButton displayName="Training Expired" value={props.status} command={props.command} paletteColor="ButtonBlue" />
                    </CenteredCell>
                </>
            );

        default:
            return <>-</>;
    }
};

export const CenteredCell = styled(Box)`
    display: flex;
    justify-content: center;
`;

export const CenteredCellDropdown = styled(Box)`
    .editselect__control {
        //position: unset;
        background-color: transparent !important;
    }

    /*     .editselect__value-container {
        position: unset;
    }  */
`;

export interface IFooterCell {
    value?: string;
    textAlignRight?: boolean;
    boldText?: boolean;
}

export const FooterCell: React.FC<IFooterCell> = (props) => {
    return (
        <Box pt="8px">
            <p style={{ textAlign: props.textAlignRight ? "right" : "left", fontWeight: props.boldText ? "bold" : "normal" }}>{props.value}</p>
        </Box>
    );
};

export const newLinesToBreak = (text: string, splitRegex = /(?:\r\n|\r|\n)/g) => {
    return text.split(splitRegex).map((line) => (
        <span>
            {line}
            <br />
        </span>
    ));
};
