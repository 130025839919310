import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { GenericComplaintActionListItem } from "./GetCorrectiveActionForView";

class DeletePreventativeActionRequest {
    public actionId: number | null = null;
    public complaintId: Guid = "";
}

export class DeletePreventativeActionResponse {
    actions: GenericComplaintActionListItem[] = [];
}

export class DeletePreventativeActionByIdEndpoint extends Endpoint<DeletePreventativeActionRequest, DeletePreventativeActionResponse> {
    public actionId: number | null = null;
    public complaintId: Guid = "";

    constructor(actionId: number, complaintId: Guid) {
        super();
        this.actionId = actionId;
        this.complaintId = complaintId;
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.DeletePreventativeAction);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new DeletePreventativeActionRequest();
        request.actionId = this.actionId;
        request.complaintId = this.complaintId;
        return request;
    }

    public async HandleResponseAsync(response: DeletePreventativeActionResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
