import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand } from "Application";
import styled from "@emotion/styled";
import { P } from "./Primitives/TextElements/TextElements";
import { LoadingSpinner } from "./LoadingSpinner";

interface IToggle {
    id: Guid;
    isVisible: boolean;
    toggleIsVisible: ICommand<any>;
    isProcessing: boolean;
}

export const HiddenVisibleToggle: React.FC<IToggle> = observer((props) => {
    //  const [viewModel] = useState(() => new HiddenVisibleToggleModel());
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    const onClickCommand = (e: any) => {
        e.stopPropagation();
        props.toggleIsVisible.execute(props.id, props.isVisible);
    };

    return (
        <>
            {props.isProcessing ? (
                <LoadingSpinner heightOfSpinner="20px" />
            ) : (
                <HiddenVisibleContainer isVisible={props.isVisible} onClick={onClickCommand}>
                    {props.isVisible === true ? (
                        <>
                            <ToggleCircle isVisible={props.isVisible} />
                            <HiddenVisibleText>Visible</HiddenVisibleText>
                        </>
                    ) : (
                        <>
                            <HiddenVisibleText>Hidden</HiddenVisibleText>
                            <ToggleCircle isVisible={props.isVisible} />
                        </>
                    )}
                </HiddenVisibleContainer>
            )}
        </>
    );
});

interface toggleProps {
    isVisible: boolean;
}

const HiddenVisibleContainer = styled(Box)<toggleProps>`
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => (props.isVisible === false ? "#921126" : "#25821F")};
    max-width: 80px;
    min-width: 75px !important;
    cursor: pointer;
    min-height: 20px;
`;

const ToggleCircle = styled(Box)<toggleProps>`
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin: 0px 2px;
    background-color: ${(props) => (props.isVisible === false ? "#CE0E2D" : "#5EB459")};
`;

const HiddenVisibleText = styled(P)`
    color: #fff;
    padding: 2px 8px;
`;
