import { ModelBase } from "@shoothill/core";
import { makeObservable } from "mobx";
import { Validator } from "Application/Validation";

export class BlankModel extends ModelBase<BlankModel> {
    constructor() {
        super();
        makeObservable(this, {});
    }
}

export class BlankModelValidator extends Validator<BlankModel> {
    constructor() {
        super();
        // Example on how to do a rule this.ruleFor("id").notEmpty();
    }
}
