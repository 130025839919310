import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand } from "Application";
import styled from "@emotion/styled";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";

// Icons

import NewIcon from "Assets/Icons/newRoundButtonIcon.svg";
import DownloadIcon from "Assets/Icons/downloadRoundButtonIcon.svg";
import BackIcon from "Assets/Icons/backButtonIcon.svg";
import EditIcon from "Assets/Icons/editButtonIcon.svg";
import SaveIcon from "Assets/Icons/FileIcon.svg";

export enum subHeadingButton {
    New = "New",
    Download = "Download",
    Download2 = "Download2",
    Back = "Back",
    Edit = "Edit",
    Save = "Save",
    ShowData = "ShowData",
}
export interface ISubHeaderView {
    buttonClick: ICommand<any>;
    newButtonText?: string;
    downloadButtonText?: string;
    download2ButtonText?: string;
    backButtonText?: string;
    editButtonText?: string;
    saveButtonText?: string;
    /* saveOnRight?: boolean; */
    showDataText?: string;
    relativePosition?: boolean;
}

export const SubHeaderView: React.FC<ISubHeaderView> = observer((props) => {
    const {
        buttonClick,
        newButtonText,
        downloadButtonText,
        download2ButtonText,
        backButtonText,
        editButtonText,
        saveButtonText,
        showDataText,
        relativePosition /* , saveOnRight  */,
    } = props;

    const isRelative: boolean = relativePosition !== undefined ? relativePosition : false;
    const isRelativeItem: any = isRelative === true ? "relative" : "absolute";
    return (
        <SubHeaderWrapper style={{ position: isRelativeItem }}>
            <LeftButtonBox>
                {props.backButtonText && (
                    <ButtonUnder
                        paletteColor="ButtonCancel"
                        icon={<img src={BackIcon} />}
                        command={buttonClick}
                        displayName={"  " + backButtonText}
                        boxShadow={true}
                        value={subHeadingButton.Back}
                    />
                )}
                {props.saveButtonText && (
                    <ButtonUnder
                        paletteColor="ButtonGreen"
                        /* icon={<img src={SaveIcon} />} */
                        command={buttonClick}
                        displayName={"  " + saveButtonText}
                        boxShadow={true}
                        value={subHeadingButton.Save}
                    />
                )}
            </LeftButtonBox>

            <RightButtonBox>
                {props.showDataText && (
                    <ButtonUnder
                        paletteColor="ButtonBlue" // TODO CMR {viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                        command={buttonClick}
                        displayName={"  " + showDataText}
                        boxShadow={true}
                        value={subHeadingButton.ShowData}
                    />
                )}
                {props.downloadButtonText && (
                    <ButtonUnder
                        paletteColor="ButtonCancel"
                        icon={<img src={DownloadIcon} />}
                        command={buttonClick}
                        displayName={"  " + downloadButtonText}
                        boxShadow={true}
                        value={subHeadingButton.Download}
                    />
                )}
                {props.download2ButtonText && (
                    <ButtonUnder
                        paletteColor="ButtonCancel"
                        icon={<img src={DownloadIcon} />}
                        command={buttonClick}
                        displayName={"  " + download2ButtonText}
                        boxShadow={true}
                        value={subHeadingButton.Download2}
                    />
                )}

                {props.newButtonText && (
                    <ButtonUnder
                        paletteColor="ButtonBlue" // TODO CMR {viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                        icon={<img src={NewIcon} />}
                        command={buttonClick}
                        displayName={"  " + newButtonText}
                        boxShadow={true}
                        value={subHeadingButton.New}
                    />
                )}

                {props.editButtonText && (
                    <ButtonUnder
                        paletteColor="ButtonBlue" // TODO CMR {viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                        icon={<img src={EditIcon} />}
                        command={buttonClick}
                        displayName={"  " + editButtonText}
                        boxShadow={true}
                        value={subHeadingButton.Edit}
                    />
                )}
            </RightButtonBox>
        </SubHeaderWrapper>
    );
});

const SubHeaderWrapper = styled(Box)`
    min-height: 40px;
    /* position: absolute; */
    top: 40;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0px 21px 0px 10px;
    display: flex;
    justify-content: space-between;
`;

export const LeftButtonBox = styled(Box)`
    display: flex;
    grid-column-gap: 2px;
    button {
        gap: 5px;
        display: flex;
        align-items: center;
    }
`;

export const RightButtonBox = styled(Box)`
    display: flex;
    grid-column-gap: 2px;
`;
