import { EndpointWithoutRequest, Http } from "Application/Helpers";
import { Logger } from "index";
import { AppUrls } from "AppUrls";
import { RoleStore } from "./RoleStore";
import { RoleMin, RoleModel } from "Views/Admin/Roles/RoleModel";
import { KeyValuePair } from "@shoothill/core";

export class GetAllRolesResponse {
    public roles: RoleMin[] = [];
}

export class GetRolesEndpoint extends EndpointWithoutRequest<GetAllRolesResponse> {
    private roleStore: RoleStore;

    constructor(roleStore: RoleStore) {
        super();
        this.roleStore = roleStore;
        this.verb(Http.Get);
        this.path(AppUrls.Server.Admin.Roles.GetRoles);
    }

    public async HandleResponseAsync(response: GetAllRolesResponse): Promise<RoleModel[]> {
        Logger.logDebug("GetRolesEndpoint Response", response);
        let roleModels: RoleModel[] = [];
        for (const role of response.roles) {
            let roleModel = new RoleModel(role.key);
            roleModel.name = role.text;
            roleModel.hideInDropdowns = role.hideInDropdowns;
            roleModels.push(roleModel);
        }
        this.roleStore.setRoles(roleModels);

        //Return your model
        Logger.logDebug("GetRolesEndpoint Response", roleModels);
        return roleModels;
    }
}
