import { observable, makeObservable } from "mobx";
import { ICommandAsync } from "./ICommandAsync";

export class RelayCommandAsync implements ICommandAsync {
    private readonly _execute: (params?: any) => Promise<unknown>;
    private readonly _canExecute?: (params?: any) => Promise<boolean>;

    constructor(execute: (...params: any[]) => Promise<unknown>, canExecute?: () => Promise<boolean>) {
        this._execute = execute;
        this._canExecute = canExecute;
        makeObservable<RelayCommandAsync, "_execute" | "_canExecute">(this, {
            _execute: observable,
            _canExecute: observable,
        });
    }

    execute = async (...params: any[]): Promise<unknown> => {
        const canExecute = await this.canExecute(...params);
        if (canExecute) return await this._execute(...params);

        return Promise.resolve(false);
    };

    canExecute = async (...params: any[]): Promise<boolean> => {
        if (this._canExecute) {
            return await this._canExecute(...params);
        }

        return Promise.resolve(true);
    };
}

/*export const useCommand = <T extends ICommand>(command: () => T) => {
    const store = useLocalStore(() => observable.box(command()));
    return store.get();
}*/
