import * as React from "react";
import { Error } from "./Views/Error/Error";
import { Logger } from "./index";

interface ErrorBoundaryProps {
    hasError: boolean;
    error: string;
    children?: React.ReactNode;
}
export class AppErrorBoundary extends React.Component<unknown, ErrorBoundaryProps> {
    private children: React.ReactNode;
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.children = props.children;
        this.state = { hasError: false, error: "" };
    }
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error.toString() };
    }
    public componentDidCatch(error: any, info: any) {
        Logger.logError("Error => " + error.toString());
    }

    public render() {
        if (this.state.hasError) {
            return <Error supportEmail={"support@shoothill.com"} error={this.state.error} />;
        }

        return this.children;
    }
}
