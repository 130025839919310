import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import styled from "@emotion/styled";
import DocIcon from "Assets/Icons/DocumentIcon.svg";
import NoteIcon from "Assets/Icons/editButtonIcon.svg";
import { P } from "./Primitives/TextElements/TextElements";

interface IProps {
    docOrNote: DocOrNote;
    count: number;
    onClickCommand: (id: Guid) => void;
    projectId: Guid;
}

export const DocNoteCountIcon: React.FC<IProps> = observer((props) => {
    const localClick = (e: any) => {
        e.stopPropagation();
        props.onClickCommand(props.projectId);
    };

    return (
        <>
            <DocNoteCountIconContainer onClick={(e: any) => localClick(e)}>
                <img src={props.docOrNote === DocOrNote.Document ? DocIcon : NoteIcon} style={{ textAlign: "center" }} />
                <DocOrNoteText>{props.count === undefined || props.count === null ? 0 : props.count}</DocOrNoteText>
            </DocNoteCountIconContainer>
        </>
    );
});

const DocNoteCountIconContainer = styled(Box)`
    background-color: #337ab7;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    padding: 3px 6.5px;
    max-width: 50px;
    border-radius: 5px;
`;

const DocOrNoteText = styled(P)`
    color: white;
`;

export enum DocOrNote {
    Document = 1,
    Note = 2,
}
