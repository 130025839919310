import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ContactsListViewModel } from "../ContactsListViewModel";
import { ContactsListItemModel } from "../ContactsListItemModel";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";

class UpdateContactRequest {
    id: Guid | null = null;
    firstName: string = "";
    lastName: string = "";
    phone: string = "";
    phone2: string = "";
    email: string = "";
    jobTitle: string = "";
    status: number = 0;
    primaryContact: boolean = false;
    marketingConsent: boolean = true;
    plygeneTrained: boolean = false;
    datePlygeneTrained: Date | null = null;
    coatingsTrained: boolean = false;
    dateCoatingsTrained: Date | null = null;

    coatingsCPD: boolean = false;
    plygeneCPD: boolean = false;
    dateCoatingsCPD: Date | null = null;
    datePlygeneCPD: Date | null = null;

    note: string = "";
    customerId: Guid = "";

    orderBy: string = "";
    columnName: string = "";
    pageSize: number = 0;
    pageNumber: number = 1;
    filterByType: number[] | string[] = [];
    filterbyCustomer: Guid | null = null;
    filterByPlygeneTrained: string[] = [];
    filterByCoatingsTrained: string[] = [];
    searchString: string = "";
}

class Response {
    contacts: ContactsListItemModel[] = [];
    contactCount: number = 0;
    customerTypesList: CustomerTypeDropdownItem[] = [];
    customerList: CustomerTypeDropdownItem[] = [];
    contactStatusTypes: ContactStatusDropdownItem[] = [];
}

export class UpdateContactEndpoint extends Endpoint<UpdateContactRequest, Response> {
    private viewModel: ContactsListViewModel;
    constructor(viewModel: ContactsListViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Put);
        this.path("/api/contact/updatecontact");
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new UpdateContactRequest();
        request.id = this.viewModel.model.id;
        request.firstName = this.viewModel.model.firstName;
        request.lastName = this.viewModel.model.lastName;
        request.phone = this.viewModel.model.phone;
        request.phone2 = this.viewModel.model.phone2;
        request.email = this.viewModel.model.email;
        request.jobTitle = this.viewModel.model.jobTitle;
        request.status = this.viewModel.model.status;
        request.primaryContact = this.viewModel.model.primaryContact;
        request.marketingConsent = this.viewModel.model.marketingConsent;
        request.plygeneTrained = this.viewModel.model.plygeneTrained;
        request.datePlygeneTrained = this.viewModel.model.datePlygeneTrained;
        request.coatingsTrained = this.viewModel.model.coatingsTrained;
        request.dateCoatingsTrained = this.viewModel.model.dateCoatingsTrained;

        request.plygeneCPD = this.viewModel.model.plygeneCPD;
        request.datePlygeneCPD = this.viewModel.model.datePlygeneCPD;
        request.coatingsCPD = this.viewModel.model.coatingsCPD;
        request.dateCoatingsCPD = this.viewModel.model.dateCoatingsCPD;

        request.note = this.viewModel.model.note;
        request.customerId = this.viewModel.model.customerId;

        request.orderBy = this.viewModel.orderBy;
        request.columnName = this.viewModel.columnName;
        request.pageSize = this.viewModel.pageSize;
        request.pageNumber = this.viewModel.pageNumber;
        request.filterByType = this.viewModel.filterByType;
        request.filterbyCustomer = this.viewModel.filterBycustomer;
        request.filterByPlygeneTrained = this.viewModel.filterByPlygeneTrained;
        request.filterByCoatingsTrained = this.viewModel.filterByCoatingsTrained;
        request.searchString = this.viewModel.searchString;
        return request;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        this.viewModel.loadContacts(response.contacts);
        this.viewModel.loadCustomerTypesDropdown(response.customerTypesList);
        this.viewModel.customers.replace(response.customerList);
        this.viewModel.contactCount = response.contactCount;
        this.viewModel.contactStatusTypes.replace(response.contactStatusTypes);
        return response;
    }
}
