import { DateTime } from "luxon";
import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";
import { ComplaintStatusEnum } from "Models/Complaints/ComplaintStatusEnum";

export class ComplaintsReportModel extends ModelBase<ComplaintsReportModel> {
    //public id: string = "";

    // Complaint Status 1 = unresolved, 2 = resolved , 3 = both
    public reportComplaintStatus: string = ComplaintStatusEnum.Both.toString();

    public reportStartDate: DateTime = DateTime.local().minus({ month: 1 }).startOf("day");
    public reportEndDate: DateTime = DateTime.local().endOf("day");

    constructor() {
        super();
        makeObservable(this, {
            //id: observable
            reportEndDate: observable,
            reportComplaintStatus: observable,
            reportStartDate: observable,
            clear: action,
        });
    }

    public clear = () => {
        this.id = "";
        this.reportComplaintStatus = ComplaintStatusEnum.Both.toString();
        this.reportStartDate = DateTime.local().minus({ month: 1 }).startOf("day");
        this.reportEndDate = DateTime.local().endOf("day");
    };
}

export class ComplaintsReportModelValidator extends Validator<ComplaintsReportModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
