import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConditionReportTemplateSection } from "../AddEdit/ConditionReportTemplateSection";

export class UpsertConditionReportTemplateSectionsRequest {
    public templateId: string = "";

    public sections: ConditionReportTemplateSection[] = [];

    public constructor() {}
}

class UpsertConditionReportTemplateSectionsResponse {
    public sections: ConditionReportTemplateSection[] = [];

    public constructor() {}
}

export class UpsertConditionReportTemplateSectionsEndpoint extends Endpoint<UpsertConditionReportTemplateSectionsRequest, UpsertConditionReportTemplateSectionsResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportTemplate.UpsertConditionReportTemplateSections);
    }

    public async HandleRequestAsync(request: UpsertConditionReportTemplateSectionsRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: UpsertConditionReportTemplateSectionsResponse): Promise<ConditionReportTemplateSection[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response.sections;
    }
}
