import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import styled from "@emotion/styled";
import DownloadIcon from "Assets/Icons/downloadIcon.svg";

export const BlueButtonBox = styled(Box)`
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    /* UI Properties */
    background: #337ab7 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 3px #00000029;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    :hover {
        cursor: pointer;
    }

    img {
        width: 10px;
        height: 8px;
        /*         background: transparent url("img/sort.png") 0% 0% no-repeat padding-box; */
        opacity: 1;
        margin-top: 4px;
        margin-left: 5px;
    }

    .dash {
        margin-top: 1px;
        margin-left: 5px;

        width: 10px;
        height: 2px;
        /* UI Properties */
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 1;
    }
`;

export interface IDownloadButtonProps {
    id: string;
    onClick: (e: any, id: string) => void;
}

export const DownloadButton: React.FC<IDownloadButtonProps> = observer(({ id, onClick }) => {
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    const localClick = (e: any) => {
        e.stopPropagation();
        onClick(e, id);
    };

    return (
        <BlueButtonBox onClick={localClick}>
            <img src={DownloadIcon} style={{ cursor: "pointer" }} />
            <div className="dash">&nbsp;</div>
        </BlueButtonBox>
    );
});
