import { KeyValuePair } from "@shoothill/core";

export enum RoofSubstrateEnum {
    Unknown = 0,
    Metal = 1,
    Asbestos = 2,
    Aluminium = 3,
    CoatedSteel = 4,
}

export module RoofSubstrateHelper {
    export const getText = (status: RoofSubstrateEnum) => {
        let retVal: string = "Unknown";
        switch (status) {
            case RoofSubstrateEnum.Metal: {
                retVal = "Metal";
                break;
            }
            case RoofSubstrateEnum.Asbestos: {
                retVal = "Asbestos";
                break;
            }
            case RoofSubstrateEnum.Aluminium: {
                retVal = "Aluminium";
                break;
            }
            case RoofSubstrateEnum.CoatedSteel: {
                retVal = "Coated Steel";
                break;
            }
            case RoofSubstrateEnum.Unknown:
            default: {
                break;
            }
        }
        return retVal;
    };

    export const getRoofSubstrateOptions = (): KeyValuePair[] => {
        // TODO Do this properly using
        let retVal: KeyValuePair[] = [];

        retVal = [
            { key: RoofSubstrateEnum.Unknown, text: "Unknown" },
            { key: RoofSubstrateEnum.Metal, text: "Metal" },
            { key: RoofSubstrateEnum.Asbestos, text: "Asbestos" },
            { key: RoofSubstrateEnum.Aluminium, text: "Aluminium" },
            { key: RoofSubstrateEnum.CoatedSteel, text: "Coated Steel" },
        ];

        return retVal;
    };
}
