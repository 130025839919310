import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate } from "react-router";
import { ConditionReportListView } from "./ConditionReport/ConditionReportListView";
import { TemplateListView } from "./Setup/TemplateListView";
import { Header, MenuItemType } from "Views/Header/Header";
import { ConditionReportAddEditView } from "./AddEdit/ConditionReportAddEditView";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ConditionReportTemplateAddEditView } from "./Setup/AddEdit/ConditionReportTemplateAddEditView";
import { ConditionReportDetailView } from "./View/ConditionReportDetailView";
import { ConditionReportPdfListView } from "./Pdf/ConditionReportPdfListView";
import { ConditionReportAddEditPDFView } from "./Pdf/Edit/ConditionReportAddEditPDFView";
import { setPageTitle } from "Application";

export const ConditionReportRoutes: React.FC = observer(() => {
    const MenuItems: MenuItemType[] = [
        { text: "Condition Report", path: AppUrls.Client.ConditionReport.List[AppUrlIndex.Long], highlightColour: "#8B008B" },
        { text: "Setup", path: AppUrls.Client.ConditionReport.Setup[AppUrlIndex.Long], highlightColour: "#8B008B" },
    ];

    useEffect(() => {
        setPageTitle("Condition Report");
    }, []);

    return (
        <>
            <Header menuItems={MenuItems} title="Condition Report" />

            <Routes>
                <Route path="*" element={<Navigate to="list" replace />} />
                <Route path={AppUrls.Client.ConditionReport.List[AppUrlIndex.Short]} element={<ConditionReportListView />} />
                <Route path={AppUrls.Client.ConditionReport.PdfList[AppUrlIndex.Short]} element={<ConditionReportPdfListView />} />

                <Route path={AppUrls.Client.ConditionReport.ViewRaw[AppUrlIndex.Short]} element={<ConditionReportDetailView />} />
                <Route path={AppUrls.Client.ConditionReport.AddEdit[AppUrlIndex.Short]} element={<ConditionReportAddEditView />} />
                <Route path={AppUrls.Client.ConditionReport.AddPdf[AppUrlIndex.Short]} element={<ConditionReportAddEditPDFView />} />
                <Route path={AppUrls.Client.ConditionReport.EditPdf[AppUrlIndex.Short]} element={<ConditionReportAddEditPDFView />} />
                <Route path={AppUrls.Client.ConditionReport.Setup[AppUrlIndex.Short]} element={<TemplateListView />} />
                <Route path={AppUrls.Client.ConditionReport.AddEditTemplate[AppUrlIndex.Short]} element={<ConditionReportTemplateAddEditView />} />
                <Route path="*" element={<Navigate to="report" replace />} />
            </Routes>
        </>
    );
});
