import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export enum ImageSourceEnum {
    Template = 0,
    Pdf = 1,
}
export interface UploadImageRequest {
    sourceId: string;
    sourceTypeId: ImageSourceEnum;
    fileName: string;
    fileBase64: string;
    fileType: string;
}

export interface BlobResponse {
    url: string;
    name: string;
    originalFileName: string;
}

export class UploadImageEndpoint extends Endpoint<UploadImageRequest, BlobResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportPdf.UploadImage);
    }

    public async HandleRequestAsync(request: UploadImageRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: BlobResponse): Promise<BlobResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
