import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, EditSelect, ICommand, RelayCommand, ThemedLoader } from "Application";
import { useParams, useNavigate } from "react-router";
import { ConditionReportAddEditPDFViewModel } from "./ConditionReportAddEditPDFViewModel";
import { H1 } from "Components/Primitives/TextElements/TextElements";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TINYMCE_APIKEY, TINYMCE_DEFAULTCSS, TINYMCE_FONTFAMILY, TINYMCE_FONTSIZES } from "Utils/Globals";
import { Editor } from "@tinymce/tinymce-react";
import { BaseWrapper } from "Components/BaseWrapper";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ShowPdfModal } from "../Modals/ShowPDFModal";
import { ConditionReportAddEditPDFWrapper } from "./ConditionReportAddEditPDFView.style";
import { KeyValuePair } from "@shoothill/core";
import { setupQuestionPlaceholderPlugin } from "Views/ConditionReport/Setup/AddEdit/Plugins/TinyMCE/QuestionPlaceholder";
import { preprocessPaste } from "Utils/html";
import { BlobResponse } from "../Endpoints/UploadImageEndpoint";
import { LoadingSpinner } from "Components/LoadingSpinner";

export const ConditionReportAddEditPDFView: React.FC = observer(() => {
    const navigate = useNavigate();
    const editorRef: any = useRef(null);
    const [viewModel] = useState(() => new ConditionReportAddEditPDFViewModel());

    const [pdfId, setpdfId] = useState<string>("");
    const [isPdfViewerOpen, setPdfViewerOpen] = useState<boolean>(false);

    let params = useParams();

    let templateId: string | undefined = params.templateId ?? undefined;
    let conditionReportId: string | undefined = params.reportId ?? undefined;
    let pdfReportId: string | undefined = params.pdfreportId ?? undefined;

    useEffect(() => {
        if (pdfReportId !== undefined) {
            // exisiting report
            let promise = viewModel.loadExisting(pdfReportId);

            promise.then((result) => {});
        } else if (conditionReportId !== undefined && templateId !== undefined) {
            // new report
            let promise = viewModel.createNewPDFDataFile(conditionReportId, templateId);

            promise.then((result) => {});
        }
        return () => {
            // Push any unsaved changes then clean up after yourself
            // Hide any error, since it is essentially coming back to nothing and does save.
            let promise = viewModel.updatePdfData(true);

            promise.then((result) => {
                viewModel.clear();
            });
        };
    }, []);

    useEffect(() => {
        if (editorRef !== null && editorRef !== undefined && editorRef.current !== null && editorRef.current !== undefined) {
            if (viewModel.isProcessing === true) {
                editorRef.current.mode.set("readonly");
            } else {
                editorRef.current.mode.set("design");
            }
        }
    }, [viewModel.isProcessing]);

    const editorHeight: number = 750;

    let editorPlugins: string[] = [
        "preview",
        "autolink",
        "directionality",
        "visualchars",
        "nonbreaking",
        "code",
        "advlist",
        "autolink",
        "lists",
        "link",
        "image",
        /*  "imagetools" /*now premium*/
        "charmap",
        "preview",
        "anchor",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "code",
        "help",
        "wordcount",
        "importcss",
        "paste",
        "questionplaceholders",
        //Premium NOT ON OUR API KEY "powerpaste",
    ];

    let toolbar: string =
        "blocks fontfamily fontsize | " +
        "bold italic underline strikethrough | image | table | fontselect fontsizeselect formatselect | alignleft aligncenter " +
        "alignright alignjustify | outdent indent |bullist numlist lists | forecolor backcolor removeformat | preview | undo redo |" +
        "code | questionplaceholders | help ";

    let contextmenu: string =
        "code | undo redo | cut copy paste | bold italic underline | fontformats | fontsizes | questionplaceholders | image imagetools | table tabledelete | tableprops tablerowprops tablecellprops ";

    const updatePdfText = (value: string) => {
        viewModel.setValue("html", value);
    };

    const onUpdate = () => {
        let promise = viewModel.updatePdfData();

        promise.then((result) => {});
    };

    const onUpdateCommand: ICommand = new RelayCommand(() => {
        onUpdate();
    });

    const onShowPdfCommand: ICommand = new RelayCommand(() => {
        let promise = viewModel.updatePdfData();

        promise.then((result) => {
            if (viewModel.model.id !== null) {
                setpdfId(viewModel.model.id);
                setPdfViewerOpen(true);
            }
        });
    });

    const onBackupCommand: ICommand = new RelayCommand(() => {
        let promise = viewModel.backupPdfData();
    });

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        if (value === subHeadingButton.Back && conditionReportId !== undefined) {
            navigate(AppUrls.Client.ConditionReport.PdfList[AppUrlIndex.Long].replace(":reportId", conditionReportId));
        } else if (value === subHeadingButton.Save && conditionReportId !== undefined) {
            onUpdateCommand.execute();
        } else if (value === subHeadingButton.ShowData && conditionReportId !== undefined) {
            onShowPdfCommand.execute();
        } else if (value === subHeadingButton.Download && conditionReportId !== undefined) {
            onBackupCommand.execute();
        }
    });

    const cancelPdfModal = () => {
        setPdfViewerOpen(false);
        setpdfId("");
    };

    const setDocumentContact: ICommand = new RelayCommand(async (value: KeyValuePair<any>) => {
        let promise = viewModel.setDocumentContact(parseInt(value.key));

        promise.then((result: string) => {});
    });

    // const images_upload_handler = (blobInfo: any, progress: any, success: any) => {};

    return (
        <>
            <SubHeaderView
                buttonClick={handleSubHeadingButtonClicks}
                backButtonText="Back to PDF Data list"
                saveButtonText="Save"
                showDataText="Show PDF"
                downloadButtonText="Backup"
            />
            <BaseWrapper>
                {isPdfViewerOpen === true && <ShowPdfModal title="Show PDF Data" isOpen={isPdfViewerOpen} onCancel={cancelPdfModal} id={pdfId} />}

                {viewModel.isLoading ? (
                    <ThemedLoader isOpen={viewModel.isLoading} spinnerText={viewModel.getSpinnerText} />
                ) : (
                    <ConditionReportAddEditPDFWrapper>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: "15px", marginTop: "10px" }}>
                            <div>
                                <H1>Condition Report PDF Setup</H1>
                            </div>
                            <div style={{ marginTop: "-20px", flex: 1, maxWidth: "320px", margin: "-20px 30px 0 30px" }}>
                                <EditSelect
                                    className="topmostmenu"
                                    displayName="Contact Details"
                                    command={setDocumentContact}
                                    options={viewModel.getContactsForDropdown}
                                    value={() => viewModel.getDocumentContact}
                                    isDisabled={viewModel.isProcessing}
                                />
                            </div>
                            <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                                {viewModel.isProcessing && (
                                    <>
                                        <div style={{ flex: 1 }}>&nbsp;</div>
                                        <div style={{ paddingRight: "15px" }}>
                                            <LoadingSpinner />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <Box>
                            {viewModel.getCss.length > 0 && (
                                <DndProvider backend={HTML5Backend}>
                                    <Editor
                                        apiKey={TINYMCE_APIKEY}
                                        onInit={(evt, editor) => (editorRef.current = editor)}
                                        value={viewModel.model.html ?? ""}
                                        onEditorChange={updatePdfText}
                                        init={{
                                            height: editorHeight,
                                            menubar: false,
                                            browser_spellcheck: true,
                                            plugins: editorPlugins,
                                            paste_strip_class_attributes: "all",
                                            paste_auto_cleanup_on_paste: true,
                                            paste_use_dialog: true,
                                            paste_text_sticky: true,
                                            paste_remove_styles_if_webkit: true,
                                            paste_remove_spans: true,
                                            paste_remove_styles: true,

                                            //  NOT ON OUR API KEY paste_merge_formats: true,
                                            //   powerpaste_html_import: "clean",
                                            //   powerpaste_googledocs_import: "clean",
                                            //  powerpaste_word_import: "clean",
                                            // powerpaste_allow_local_images: false,

                                            //images_upload_url: "api/conditionreportpdf/uploadImage",
                                            // image_upload_handler: images_upload_handler,
                                            // automatic_uploads: true,
                                            paste_data_images: false,
                                            //Premium imagetools_cors_hosts: ["sharmanscrm.co.uk", "sharmans.blob.core.windows.net", "localhost:44394"],

                                            paste_preprocess: function (plugin, args) {
                                                // From Tiny MCE Due to browser limitations, it is not possible to filter content that is dragged and dropped into the editor.

                                                if (args.content.indexOf('<img src="blob:') > -1) {
                                                    //don't allow pasting of local blobs
                                                    //alert("Please upload the image, using the meny and drag and drop with images not supported.");
                                                    args.content = "";
                                                } else {
                                                    // Remove the styling / classes / id from the pasted object.
                                                    // since paste_strip_class_attributes, paste_remove_styles, paste_remove_spans doesn't
                                                    // seem to actually do anything.
                                                    const elem = document.createElement("div");

                                                    elem.innerHTML = args.content.replaceAll("\r", " ").replaceAll("\n", "").replace("  ", " ");

                                                    for (let x: number = 0; x < elem.children.length; x++) {
                                                        const node: Element | null = elem.children[x];
                                                        if (node !== null) {
                                                            preprocessPaste(node);
                                                        }
                                                    }

                                                    args.content = elem.innerHTML;
                                                }
                                            },
                                            file_picker_types: "image",
                                            file_picker_callback: (cb, value, meta) => {
                                                const input = document.createElement("input");
                                                input.setAttribute("type", "file");
                                                input.setAttribute("accept", "image/*");

                                                input.addEventListener("change", (e: any) => {
                                                    const file = e.target.files[0];

                                                    const reader: FileReader = new FileReader();
                                                    reader.addEventListener("load", () => {
                                                        const base64: string = reader.result!.toString().split(",")[1];
                                                        let promise = viewModel.uploadImage(base64, file);

                                                        promise.then(function (result: BlobResponse) {
                                                            /* call the callback and populate the Title field with the file name */
                                                            if (result.originalFileName.length > 0 && result.url.length > 0) {
                                                                cb(result.url, { title: result.originalFileName });
                                                            }
                                                        });
                                                    });
                                                    reader.readAsDataURL(file);
                                                });

                                                input.click();
                                            },
                                            image_advtab: true,
                                            toolbar: toolbar,
                                            font_size_formats: TINYMCE_FONTSIZES,
                                            font_formats: TINYMCE_FONTFAMILY,
                                            content_style: viewModel.getCss.length > 0 ? viewModel.getCss : TINYMCE_DEFAULTCSS,
                                            contextmenu: contextmenu,
                                            setup: (editor: any) => {
                                                setupQuestionPlaceholderPlugin(editor, [], true);
                                            },
                                            //Premium imagetools_toolbar: "rotateleft rotateright | flipv fliph | editimage imageoptions",
                                        }}
                                    />
                                    <div style={{ width: "100px", marginTop: "15px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <Button
                                            mr={2}
                                            command={onUpdateCommand}
                                            paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonGreen"}
                                            displayName={"Save"}
                                            style={{ padding: "10px 60px" }}
                                        />
                                        <div style={{ marginLeft: "15px" }}>
                                            <Button
                                                mr={2}
                                                command={onShowPdfCommand}
                                                paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                                                displayName={"Show PDF Data"}
                                                style={{ padding: "10px 60px" }}
                                            />
                                        </div>
                                    </div>
                                </DndProvider>
                            )}
                        </Box>
                    </ConditionReportAddEditPDFWrapper>
                )}
            </BaseWrapper>
        </>
    );
});
