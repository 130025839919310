import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand, RelayCommand, ThemedLoader, formatDate } from "Application";
import { ViewPropertyViewModel } from "./ViewPropertyViewModel";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { Route, Routes, useNavigate, useParams } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { BaseWrapperNoMargin, BaseWrapperNoMarginTop } from "Components/BaseWrapper";
import { H2, H5, P } from "Components/Primitives/TextElements/TextElements";
import { MultiSiteProjectsTable } from "./LinkedMultiSite/MultiSiteProjects";
import { MasterProjectsTable } from "./LinkedMaster/MasterProjects";
import { ProjectQuotesTable } from "./LinkedProjectQuotes/ProjectQuotes";
import LocationIconBoxed from "Assets/Icons/LocationIconBoxed.svg";
import { PropertyDetailsView } from "./Details/DetailsView";
import { TabsWrapper } from "Components/TabsWrapper";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import "leaflet/dist/leaflet.css";
import { BackButtonNavigation, GetBackButtonText } from "Components/BackButtonNavigation";
import { container } from "tsyringe";
import { DomainStore } from "Stores/Domain/DomainStore";

export const ViewPropertyView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ViewPropertyViewModel());
    const navigate = useNavigate();
    let params = useParams();
    const domainStore = container.resolve(DomainStore);

    useEffect(() => {
        viewModel.getProperty(params.id);

        return () => {
            // Clean up after yourself
            // viewModel.clear();
        };
    }, []);

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        switch (value) {
            case subHeadingButton.Back:
                navigate(BackButtonNavigation(AppUrls.Client.Directory.Properties[AppUrlIndex.Long]));
                break;
            case subHeadingButton.Edit:
                navigate(`/directory/properties/edit/${params.id}`);
                break;

            default:
        }
    });

    const multiSiteProjectCountCallBack = (newCount: number): void => {
        viewModel.setValue("multiSiteProjectCount", newCount);
    };

    const masterProjectCountCallBack = (newCount: number): void => {
        viewModel.setValue("masterProjectCount", newCount);
    };

    const projectQuoteCountCallBack = (newCount: number): void => {
        viewModel.setValue("projectQuoteCount", newCount);
    };

    return (
        <>
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} backButtonText={"Back" + GetBackButtonText(" to properties")} editButtonText="Edit property details" />

            <BaseWrapperNoMargin>
                {viewModel.isProcessing ? (
                    <ThemedLoader isOpen={true} />
                ) : (
                    <>
                        <Box flexBox pt="50px" px={"10px"} pb="10px" style={{ alignItems: "end", gap: "10px" }}>
                            <H2 style={{ lineHeight: 1, fontSize: "18px" }}>{viewModel.getAddress}</H2>
                            {viewModel.showAddressForGoogleMaps && (
                                <Box flexBox style={{ alignItems: "start", height: "20px" }}>
                                    <a href={`https://www.google.co.uk/maps/place/${viewModel.getAddressForGoogleMaps}`} target="_blank" rel="noreferrer">
                                        <img src={LocationIconBoxed} />
                                    </a>
                                </Box>
                            )}
                            <P>
                                Created by {viewModel.getValue("createdByName")} on {formatDate(new Date(viewModel.getValue("createdDate")))}
                            </P>
                        </Box>

                        <TabsWrapper>
                            <NavLink to={"details"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Details</H5>
                            </NavLink>
                            <NavLink to={"multi"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Linked multi site ({viewModel.getValue("multiSiteProjectCount")})</H5>
                            </NavLink>
                            <NavLink to={"masterProjects"} className={(navData) => clsx("root", navData.isActive ? "current" : "default")}>
                                <H5>Linked master ({viewModel.getValue("masterProjectCount")})</H5>
                            </NavLink>
                            <NavLink to={"projectQuotes"} className={(navData) => clsx("root", navData.isActive ? "current long" : "default long")}>
                                <H5>Linked project quotes ({viewModel.getValue("projectQuoteCount")})</H5>
                            </NavLink>
                        </TabsWrapper>

                        <BaseWrapperNoMarginTop>
                            <Routes>
                                <Route path={"details"} element={<PropertyDetailsView ParentViewModel={viewModel} />} />
                                <Route path={"multi"} element={<MultiSiteProjectsTable multiSiteProjectCountCallBack={multiSiteProjectCountCallBack} propertyId={params.id!} />} />
                                <Route path={"masterProjects"} element={<MasterProjectsTable masterProjectCountCallBack={masterProjectCountCallBack} propertyId={params.id!} />} />
                                <Route path={"projectQuotes"} element={<ProjectQuotesTable projectQuotesCountCallBack={projectQuoteCountCallBack} propertyId={params.id!} />} />
                            </Routes>
                        </BaseWrapperNoMarginTop>
                    </>
                )}
            </BaseWrapperNoMargin>
        </>
    );
});
