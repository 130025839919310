import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { Box, ICommand, Label, RelayCommand, formatDate } from "Application";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import AddNoteIcon from "Assets/Icons/editButtonIcon.svg";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { ViewTasksModal } from "Views/Work/Master/View/Task/Modals/ViewTasksModal";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { useNavigate } from "react-router";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { TaskViewModel } from "./TaskViewModel";
import { CenteredTextCell, ExpandEllipsis } from "Utils/CellFormatComponents";
import moment from "moment";
import styled from "@emotion/styled";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair } from "@shoothill/core";
import { TaskListItem } from "./TaskModel";
import { PageTabWrapper } from "Styles/BaseStyles";

interface ITasks {
    id?: string;
    taskCountCallBack: (newCount: number) => void;
}

export const TasksView: React.FC<ITasks> = observer((props) => {
    const [viewModel] = useState(() => new TaskViewModel(props.id!, props.taskCountCallBack));
    const navigate = useNavigate();

    const [isFiltering, setIsFiltering] = useState(false);
    useEffect(() => {
        if (props.id) {
            viewModel.getTaskListAsync();
        }
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            const filterTimeout = setTimeout(() => {
                viewModel.getTaskListAsync();
                setIsFiltering(false);
            }, 1200);

            return () => {
                clearTimeout(filterTimeout);
            };
        }
    }, [isFiltering, viewModel]);

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("taskTypeName", {
            header: () => "Task Name",
            size: 120,
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("subTaskTypeName", {
            header: () => "SubTask Name",
            size: 70,
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("description", {
            header: () => "Description",
            size: 500,
            cell: (info) => <ExpandEllipsis text={info.getValue()} />,
        }),

        columnHelper.accessor("startDateTime", {
            header: () => "Date Set",
            size: 20,
            cell: (info) => <CenteredTextCell value={info.getValue() !== null ? formatDate(new Date(info.getValue())) : " - "} />,
        }),

        columnHelper.accessor("dueDateTime", {
            header: () => "Due date",
            size: 20,
            cell: (info: CellContext<any, any>) => {
                const dueDate = moment(info.getValue());
                const today = moment().startOf("day");
                const isPastDue = dueDate.isBefore(today, "day");
                const isComplete = info.row.original.isComplete;
                return (
                    <Box>
                        <Label style={{ color: isPastDue === true && isComplete === false ? "#CE0E2D" : "", fontWeight: isPastDue === true && isComplete === false ? 800 : 500 }}>
                            {info.getValue() !== null ? formatDate(dueDate.toDate()) : " - "}
                        </Label>
                    </Box>
                );
            },
        }),

        columnHelper.accessor("id", {
            header: () => "Actions",
            size: 20,
            cell: (info) => (
                <Box className="action-cell">
                    <EditButton id={info.getValue()} onClick={onEditClick} />
                </Box>
            ),
            enableSorting: false,
        }),
    ];

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.getTaskListAsync();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.getTaskListAsync();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.getTaskListAsync();
                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.getTaskListAsync();
                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.getTaskListAsync();
                break;
            default:
        }
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;
        viewModel.getTaskListAsync();
    };

    const onEditClick = (e: any, id: string) => {
        navigate(AppUrls.Client.Work.Tasks.AddEdit[AppUrlIndex.Short].replace(":tasktype", "customers").replace(":customerId", props.id!).replace(":taskid", id));
    };

    function onRowClick(Id: string | number): void {
        viewModel.openViewTasksModal(Id);
    }

    const openNewModal: ICommand = new RelayCommand(() => {
        navigate(AppUrls.Client.Work.Tasks.AddEdit[AppUrlIndex.Short].replace(":tasktype", "customers").replace(":customerId", props.id!).replace(":taskid", "new"));
    });

    const updateCompletedFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateCompletedFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        setIsFiltering(true);
    });

    const completedSelectAll = (): void => {
        viewModel.completedSelectAll();
        viewModel.getTaskListAsync();
    };

    const completedSelectNone = (): void => {
        viewModel.completedSelectNone();
        viewModel.getTaskListAsync();
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <MultiEditSelect
                command={updateCompletedFiltering}
                displayName="Completed"
                options={viewModel.getCompletedDropdown}
                value={() => viewModel.getCompletedFilter}
                showSelectAllNone={true}
                selectAll={completedSelectAll}
                selectNone={completedSelectNone}
                isDisabled={viewModel.isProcessing}
            />
        </FilteringBottomSectionBox>
    );

    const getTrClasses = (id: any): string => {
        let retVal: string = "";
        const today = moment().startOf("day");
        if (viewModel.tasksList.length > 0) {
            const rowInfo: TaskListItem | undefined = viewModel.tasksList.find((x) => x.id === id);

            if (rowInfo !== undefined) {
                const dueDate = moment(rowInfo.dueDateTime);
                const isPastDue = dueDate.isBefore(today, "day");

                if (isPastDue === true && rowInfo.isComplete === false) {
                    retVal = "pastdue";
                }

                // Now sort out if the next item is overdue to sort out your bottom border
                if (isPastDue === false || rowInfo.isComplete === true) {
                    const index: number = viewModel.tasksList.findIndex((x) => x.id === id);

                    if (index < viewModel.tasksList.length - 1) {
                        const nextRowInfo: TaskListItem | undefined = viewModel.tasksList.find((x) => x.id === viewModel.tasksList[index + 1].id);
                        if (nextRowInfo !== undefined) {
                            const nextDueDate = moment(nextRowInfo.dueDateTime);
                            const isNextPastDue = nextDueDate.isBefore(today, "day");

                            if (isNextPastDue === true && nextRowInfo.isComplete === false) {
                                retVal = "pastduenext";
                            }
                        }
                    }
                }
            }
        }

        return retVal;
    };

    return (
        <>
            <PageTabWrapper>
                <Box flexBox pb="20px" style={{ justifyContent: "space-between" }}>
                    <H5 pt="12px">
                        <strong>Tasks</strong>
                    </H5>
                    <ButtonUnder
                        displayName=" Add task"
                        paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                        icon={<img src={AddNoteIcon} />}
                        boxShadow={true}
                        command={openNewModal}
                    />
                </Box>
                <SharmansTable
                    columns={columns}
                    data={viewModel.tasksList.slice()}
                    filteringElementsBottomSection={FilteringBottomSection}
                    tableHeaderColour="#D4D4D4"
                    tableFilteringColour="#EDEDED"
                    onRowClick={onRowClick}
                    onSortChange={updateSorting}
                    showPagination={true}
                    totalRowCount={viewModel.totalCount}
                    onChangePage={onChangePage}
                    onChangePageNumber={viewModel.changePageNumber}
                    onChangeRowPerPage={onChangeRowPerPage}
                    totalPageCount={viewModel.pageCount}
                    pageNumber={viewModel.pageNumber}
                    pageSize={viewModel.pageSize}
                    getTrClassesCallback={getTrClasses}
                />
                {viewModel.tasksList.length === 0 && (
                    <Box flexBox justifyContent="center">
                        <P pr="15px">There are currently no tasks added to this project.</P>
                    </Box>
                )}

                {/* Tasks Info Callout */}
                <ViewTasksModal isOpen={viewModel.showViewTaskModal} closeModal={viewModel.closeViewTaskModal} viewModel={viewModel} />
            </PageTabWrapper>
        </>
    );
});
