import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { TSMListItem } from "./Endpoints/GetTSMListAndRelatedEndpoint";

export class TSMListModel extends ModelBase<TSMListModel> {
    // public id: string = "";
    public tsmList: IObservableArray<TSMListItem> = observable([]);

    constructor() {
        super();
        makeObservable(this, {
            // id: observable
        });
    }
}

export class TSMListModelValidator extends Validator<TSMListModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
