import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { GenericComplaintActionListItem } from "./GetCorrectiveActionForView";

export class GetPreventativeActionForViewResponse {
    actions: GenericComplaintActionListItem[] = [];
}

export class GetPreventativeActionForViewEndpoint extends EndpointWithoutRequest<GetPreventativeActionForViewResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.AfterSales.Complaints.GetPreventativeActionsForView + `/${id}`);
    }

    public async HandleResponseAsync(response: GetPreventativeActionForViewResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
