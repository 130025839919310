import * as React from "react";
import clsx from "clsx";
import { isEmptyOrWhitespace, isNullOrUndefined, KeyValuePair } from "@shoothill/core";
import { Box, ErrorExclamationView, ICommand } from "Application";
import Select, { components, FormatOptionLabelMeta, MenuPosition, Options } from "react-select";
import { useEffect, useState } from "react";
import { FieldLabel } from "../CommonStyles";
import { observer } from "mobx-react-lite";
import DownArrow from "Assets/Icons/EditSelectArrow.svg";
import { SelectContainer } from "./Select.style";
import AddIcon from "Assets/Icons/AddSquareIcon.svg";
// #region View Props

interface IProps {
    canClear?: boolean;
    command: ICommand;
    className?: string;
    displayName?: string;
    optionRenderer?: (item: any) => void;
    options: KeyValuePair[];
    placeholder?: string;
    style?: any;
    value: () => string | string[];
    isMulti?: boolean;
    validationMessage?: () => string;
    noHeader?: boolean;
    ref?: React.Ref<any>;
    cy?: string;
    formatOptionLabel?: ((data: any, formatOptionLabelMeta: FormatOptionLabelMeta<any>) => React.ReactNode) | undefined;
    errorIconRight?: boolean;
    isDisabled?: boolean;
    dropdownIndicator?: (props: any) => JSX.Element;
    isSearchable?: boolean;
    isOptionDisabled?: ((option: any, selectValue: Options<any>) => boolean) | undefined;
    menuPosition?: MenuPosition;
    onInputChange?: (newValue: any, actionMeta: any) => void;
    showAddButton?: boolean;
    modelOpen?: () => void;
    isPreSticky?: boolean;
}

// #endregion View Props

export const EditSelect: React.FC<IProps> = observer((props) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    // #region Code Behind
    useEffect(() => {
        //EN: Have to do this because react-select stores the entire key value pair in the value property
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key === props.value());
            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            }
        } else {
            if (props.value()) {
                const values = props.value() as any as string[];
                let arr = [];
                for (const item of values) {
                    const initValue: KeyValuePair<any> | undefined = props.options.find((option: KeyValuePair) => option.key === item);
                    if (initValue !== undefined) {
                        arr.push({ value: initValue.key, label: initValue.text });
                    }
                }
                setSelectedOption(arr);
            }
        }
    }, [props.options]);

    useEffect(() => {
        if (!props.isMulti) {
            const initValue = props.options.find((option: KeyValuePair) => option.key.toString() === props.value()) as KeyValuePair;
            if (initValue) {
                setSelectedOption({ value: initValue.key, label: initValue.text });
            }
        } else {
            const values = props.value() as any as string[];
            let arr: any[] = [];
            for (const item of values) {
                const initValue: KeyValuePair<any> | undefined = props.options.slice().find((option: KeyValuePair) => option.key === item);
                if (initValue !== undefined) {
                    arr.push({ value: initValue.key, label: initValue.text });
                }
            }
            setSelectedOption(arr);
        }
    }, [props.value()]);

    const getCanClear = (): boolean => {
        return isNullOrUndefined(props.canClear) ? false : props.canClear!;
    };

    const getClassName = (): string => {
        return clsx({
            editselect: true,
            [`editselect-${props.className}`]: !isEmptyOrWhitespace(props.className),
            "editselect-emptylabel": props.displayName === "EMPTY_LABEL",
        });
    };

    const getPlaceholder = (): string => {
        return isEmptyOrWhitespace(props.placeholder) ? "Select or type" : props.placeholder!;
    };

    const onChange = (option: any): void => {
        setSelectedOption(option);
        if (!props.isMulti) {
            props.command.execute({ key: option.value, text: option.label } as KeyValuePair);
        } else {
            const selected = option.map((a: any) => {
                return { key: a.value, text: a.label } as KeyValuePair;
            });
            props.command.execute(selected);
        }
    };

    const isDisabled = (): boolean => {
        return isNullOrUndefined(props.command.canExecute) ? false : !props.command.canExecute;
    };
    const getValidationMessage = (): string => {
        return isEmptyOrWhitespace(props.validationMessage?.() as string) ? "" : (props.validationMessage?.() as string);
    };

    const getOptions = (): any => {
        return props.options.map((item: KeyValuePair) => {
            return {
                label: item.text,
                value: item.key,
            };
        });
    };
    const getDataCY = (): string => {
        let regex = /[^a-z]/gi;
        let result = (props.displayName ?? "").replace(regex, "");
        return props.cy ?? result;
    };

    const DropdownIndicator = (dropdownProps: any): JSX.Element => {
        return props.dropdownIndicator !== undefined ? (
            props.dropdownIndicator!(dropdownProps)
        ) : (
            <components.DropdownIndicator {...dropdownProps}>
                <img src={DownArrow} style={{ transform: "rotate(180deg)" }} />
            </components.DropdownIndicator>
        );
    };

    let classList: string = props.isPreSticky === undefined || props.isPreSticky === false ? "virtualized-select-validation" : "virtualized-select-validation isPreSticky";

    const MenuList = (menuProps: any) => {
        return (
            <components.MenuList {...menuProps}>
                {props.showAddButton === true && (
                    <Box paddingBottom="5px" style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                        <a onClick={(e) => props.modelOpen && props.modelOpen()}>
                            <img src={AddIcon} />
                            Create contact
                        </a>
                    </Box>
                )}
                {menuProps.children}
            </components.MenuList>
        );
    };

    // #endregion Code Behind
    return (
        <SelectContainer className={getClassName()}>
            {!props.noHeader && (
                <Box flexBox>
                    <FieldLabel className="placeholder" style={{ color: haveError ? "red" : "" }}>
                        {props.displayName}
                    </FieldLabel>
                    {props.errorIconRight === false && <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} />}
                </Box>
            )}
            <Box flexBox style={{ ...props.style }}>
                <Select
                    ref={props.ref}
                    isClearable={getCanClear()}
                    className={classList}
                    classNamePrefix={"editselect"}
                    isDisabled={props.isDisabled}
                    onChange={onChange}
                    isMulti={props.isMulti}
                    options={getOptions()}
                    placeholder={getPlaceholder()}
                    value={selectedOption}
                    formatOptionLabel={props.formatOptionLabel}
                    data-cy={getDataCY()}
                    components={{ DropdownIndicator, MenuList }}
                    hideSelectedOptions={false}
                    isSearchable={props.isSearchable !== undefined ? props.isSearchable : true}
                    isOptionDisabled={props.isOptionDisabled}
                    menuPosition={props.menuPosition === undefined ? undefined : props.menuPosition}
                    onInputChange={props.onInputChange}
                />
                {props.errorIconRight === true && (
                    <Box flexBox style={{ alignItems: "center" }}>
                        <ErrorExclamationView haveError={haveError} validationMessage={() => getValidationMessage()} iconRight={props.errorIconRight} />
                    </Box>
                )}
            </Box>
        </SelectContainer>
    );
    // #endregion Public Interface
});

EditSelect.defaultProps = {
    displayName: "",
};
