import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate } from "react-router";
import { Header, MenuItemType } from "Views/Header/Header";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { TSMListView } from "./TSMListView";
import { setPageTitle } from "Application";

export const TSMRoutes: React.FC = observer(() => {
    // const MenuItems: MenuItemType[] = [
    //     { text: "", path: "", highlightColour: "" },
    //     { text: "", path: "", highlightColour: "" },
    // ];

    useEffect(() => {
        setPageTitle("TSM");
    }, []);

    return (
        <>
            <Header title="TSM" />

            <Routes>
                <Route path="*" element={<Navigate to="list" replace />} />
                <Route path={"list/*"} element={<TSMListView />} />
            </Routes>
        </>
    );
});
