import { KeyValuePair } from "@shoothill/core/dist/Models/KeyValuePair";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";

export enum JFFCatagoryEnum {
    Both = 1,
    ProjectOnly = 2,
    MasterOnly = 3,
}

export namespace JFFCatagoryEnumHelper {
    export const getText = (status: JFFCatagoryEnum) => {
        switch (status) {
            case JFFCatagoryEnum.MasterOnly: {
                return "Master Only";
            }
            case JFFCatagoryEnum.ProjectOnly: {
                return "Project Only";
            }
            default: {
                return "All Projects";
            }
        }
    };

    // Use this one on the Project View
    export const getOptionsDropdown = (): KeyValuePair[] => {
        let retVal: KeyValuePair[] = [];

        for (const key in JFFCatagoryEnum) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as JFFCatagoryEnum;
                retVal.push({
                    text: JFFCatagoryEnumHelper.getText(item),
                    key: keyAsInt.toString(),
                });
            }
        }

        return retVal;
    };
}
