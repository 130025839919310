import { makeObservable } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { CustomerNoteDocumentItemModel, CustomerNoteDocumentItemModelValidator } from "./CustomerNoteDocumentItemModel";

export class CustomerNoteDocumentItemViewModel extends ViewModelBase<CustomerNoteDocumentItemModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super(new CustomerNoteDocumentItemModel());
        this.setValidator(new CustomerNoteDocumentItemModelValidator());
        makeObservable(this, {});
    }

    public clear = () => {
        this.model.clear();
    };

    public setDocumentType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("documentCategoryId", Number(value.key));
    });
}
