import { AppUrls } from "AppUrls";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";

class GetComplaintTaskDetailsByIdResponse {}

export class GetComplaintTaskDetailsByIdEndpoint extends EndpointWithoutRequest<GetComplaintTaskDetailsByIdResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.MasterProject.GetMasterProjectTasksAndRelated + `/${id}`);
    }

    public async HandleResponseAsync(response: GetComplaintTaskDetailsByIdResponse): Promise<any> {
        return response;
    }
}
