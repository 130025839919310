import { useEffect, useState } from "react";
import { read, utils, WorkBook, WorkSheet } from "xlsx";
import { ExcelTableWrapper } from "../DocumentUploader.styles";

export interface ExcelViewerProps {
    file: File;
}

export const ExcelViewer: React.FC<ExcelViewerProps> = ({ file }) => {
    const [htmlContent, setHtmlContent] = useState<string>("");

    useEffect(() => {
        const reader = new FileReader();
        reader.onload = () => {
            const arrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
            const workbook: WorkBook = read(arrayBuffer, { type: "array" });
            const firstSheetName: string = workbook.SheetNames[0];
            const worksheet: WorkSheet = workbook.Sheets[firstSheetName];
            const htmlData: string = utils.sheet_to_html(worksheet);
            setHtmlContent(htmlData);
        };
        reader.readAsArrayBuffer(file);
    }, [file]);

    return <ExcelTableWrapper dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};
