import React from "react";
import { observer } from "mobx-react-lite";
import { Box, ICommand } from "Application";
import styled from "@emotion/styled";
import { P } from "../Primitives/TextElements/TextElements";
import InfoIcon from "Assets/Icons/InfoRoundIcon.svg";
import AddIcon from "Assets/Icons/AddSquareIcon.svg";
import { ScrollListItem } from "./ScrollingListWithSearch";
import { ButtonLikeImage } from "Components/Primitives/Buttons/ButtonLikeImage";

export interface IListItemContainerBox {
    item: ScrollListItem;
    infoCalloutTargetId: string;
    addCommand: ICommand;
    showInfoCommand: ICommand;
}

export const ListItemContainerBox: React.FC<IListItemContainerBox> = observer((props) => {
    const { item, infoCalloutTargetId, addCommand, showInfoCommand } = props;

    const addItem = () => {
        if (addCommand.canExecute()) {
            props.addCommand.execute(item.id);
        }
    };

    const showInfo = () => {
        if (showInfoCommand.canExecute()) {
            props.showInfoCommand.execute(infoCalloutTargetId, item.id);
        }
    };

    return (
        <ItemContainerBox>
            <Box flexBox style={{ alignItems: "center" }}>
                <P>{item.displayName}</P>
            </Box>
            <Box flexBox gap={10}>
                <ButtonLikeImage id={infoCalloutTargetId} onClick={showInfo}>
                    <img src={InfoIcon} />
                </ButtonLikeImage>
                <ButtonLikeImage onClick={addItem}>
                    <img src={AddIcon} />
                </ButtonLikeImage>
            </Box>
        </ItemContainerBox>
    );
});

export const ItemContainerBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    padding: 4px 9px;
    :hover {
        background-color: #eff5fa;
    }
`;
