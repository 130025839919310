import { ModelBase } from "@shoothill/core";
import { IObservableArray, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { PropertyMasterProjectListItem } from "../Models/PropertyMasterProjectListItem";

export class PropertyLinkedMasterModel extends ModelBase<PropertyLinkedMasterModel> {
    public id: string = "";
    public propertyId: Guid = "";
    public linkedMasterProjectsList: IObservableArray<PropertyMasterProjectListItem> = observable([]);
    constructor() {
        super();
        makeObservable(this, {
            propertyId: observable,
            linkedMasterProjectsList: observable,
        });
    }
}

export class PropertyLinkedMasterModelValidator extends Validator<PropertyLinkedMasterModel> {
    constructor() {
        super();
    }
}
