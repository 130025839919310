import { Box } from "./General";
import React from "react";

interface ICenteredProps {
    children: any;
}

export const Centered: React.FC<ICenteredProps> = (props: ICenteredProps) => {
    return (
        <Box flexBox justifyContent={"center"} alignItems={"center"} width={"100vw"} height={"100vh"}>
            <>{props.children}</>
        </Box>
    );
};
