import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { CustomerContactsViewModel } from "../NewCustomer/CustomerContacts/CustomerContactsViewModel";
import { CustomerContactsModel } from "../NewCustomer/CustomerContacts/CustomerContactsModel";
import { AppUrls } from "AppUrls";
import { SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";

class GetContactsByCustomerIdRequest {
    customerId: Guid = "";
    orderBy: SortOrderDirection = SortOrderDirection.ASC;
    columnName: string = "name";
}

class GetContactsByCustomerIdResponse {}

export class GetContactsByCustomerIdEndpoint extends Endpoint<GetContactsByCustomerIdRequest, GetContactsByCustomerIdResponse> {
    private model: CustomerContactsModel;

    constructor(model: CustomerContactsModel) {
        super();
        this.model = model;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.GetContactsByCustomerId);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new GetContactsByCustomerIdRequest();
        request.customerId = this.model.customerId;
        request.orderBy = this.model.orderBy;
        request.columnName = this.model.columnName;
        return request;
    }

    public async HandleResponseAsync(response: GetContactsByCustomerIdResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
