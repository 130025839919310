import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { ViewPropertyModel } from "../ViewProperty/ViewPropertyModel";
import { ProjectProductDropDown } from "Models/Project/ProjectProductDropDown";
import { PropertyMasterProjectListItem } from "../ViewProperty/Models/PropertyMasterProjectListItem";
import { PropertyMultiSiteProjectListItem } from "../ViewProperty/Models/PropertyMultiSiteProjectListItem";
import { PropertyProjectQuoteListItem } from "../ViewProperty/Models/PropertyProjectQuoteListItem";

export class PropertyViewResponse {
    public property: ViewPropertyModel = new ViewPropertyModel();
    public masterProjects: PropertyMasterProjectListItem[] = [];
    public multiSiteProjects: PropertyMultiSiteProjectListItem[] = [];
    public projectQuotes: PropertyProjectQuoteListItem[] = [];
    public projectProduct: ProjectProductDropDown[] = [];

    public constructor() {}
}

export class GetPropertyDetailsByIdEndpoint extends EndpointWithoutRequest<PropertyViewResponse> {
    constructor(Id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(`/api/property/getbyid/${Id}`);
    }

    public async HandleResponseAsync(response: PropertyViewResponse): Promise<PropertyViewResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
