import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TSMListViewModel } from "../TSMListViewModel";
import { ProjectProductDropDown } from "Models/Project/ProjectProductDropDown";
import { ProjectStatusDropdownItem } from "Models/DropdownItem";
import { ProjectStatusEnum } from "Models/Project/ProjectStatusEnum";

export class GetTSMListAndRelatedRequest {
    id: number = 1; // TODO!!!!! CHANGE!!!
    initialLoad: boolean = true;
    orderBy: string = "";
    columnName: string = "";
    pageSize: number = 0;
    pageNumber: number = 1;
    searchString: string = "";

    filterByProduct: number[] = [];
    filterByContractor: Guid[] = [];
    filterByStatus: ProjectStatusEnum[] = [];

    filterBySurveyed: boolean[] = [];
    filterByVisited: boolean[] = [];
    filterByVisible: boolean[] = [];
}

export class GetTSMListAndRelatedResponse {
    tsmList: TSMListItem[] = [];
    productDropdown: ProjectProductDropDown[] = [];
    statusDropdown: ProjectStatusDropdownItem[] = [];
    count: number = 0;
}

export class GetTSMListAndRelatedEndpoint extends Endpoint<GetTSMListAndRelatedRequest, GetTSMListAndRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Tsm.GetTSMListAndRelated);
    }

    public async HandleRequestAsync(viewModel: TSMListViewModel): Promise<any> {
        let request = new GetTSMListAndRelatedRequest();
        request.id = viewModel.tsmId; // TODO!!!!! CHANGE!!!
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageSize = viewModel.pageSize;
        request.pageNumber = viewModel.pageNumber;
        request.searchString = viewModel.searchString;

        request.filterByProduct = viewModel.filterByProduct.map((item) => {
            return parseInt(item);
        });

        request.filterByContractor = viewModel.filterByContractors;

        request.filterByStatus = viewModel.filterByStatus.map((item) => {
            return parseInt(item) as ProjectStatusEnum;
        });

        request.filterBySurveyed = viewModel.filterBySurveyed.map((item) => {
            let retVal = true;
            if (item === "true") {
                retVal = true;
            } else if (item === "false") {
                retVal = false;
            }
            return retVal;
        });

        request.filterByVisited = viewModel.filterByVisited.map((item) => {
            let retVal = true;
            if (item === "true") {
                retVal = true;
            } else if (item === "false") {
                retVal = false;
            }
            return retVal;
        });

        request.filterByVisible = viewModel.filterByVisible.map((item) => {
            let retVal = true;
            if (item === "true") {
                retVal = true;
            } else if (item === "false") {
                retVal = false;
            }
            return retVal;
        });

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: GetTSMListAndRelatedResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}

export interface TSMListItem {
    id: string;
    number: string;
    projectQuoteName: string;
    product: string;
    contractors: string;
    postcode: string;
    netValue: number;
    overallStatus: number;
    isSurveyedByTsm: boolean;
    siteInspection: boolean;
    propertyId: Guid;
    createdDate: string;
    docCount: number;
    noteCount: number;
    documentCount: number;
    showOnTsmTab: boolean;
    despatchDate: Date | null;
}
