import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { DropdownItem } from "Models/DropdownItem";
import { CustomerTypeDropdownItem } from "Application/Models/Domain/Customer/CustomerTypeDropdownItem";
import { IDocumentType } from "Models/Documents/DocumentType";
import { AppUrls } from "AppUrls";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";

export class GetNewEditRelatedResponse {
    public customerType: CustomerTypeDropdownItem[] = [];
    public customerTsm: DropdownItem[] = [];
    public customerBdm: DropdownItem[] = [];
    public contactStatusType: ContactStatusDropdownItem[] = [];
    public documentType: IDocumentType[] = [];

    public constructor() {}
}

export class GetNewEditRelatedEndpoint extends EndpointWithoutRequest<GetNewEditRelatedResponse> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Customer.GetNewEditRelated);
    }

    public async HandleResponseAsync(response: GetNewEditRelatedResponse): Promise<GetNewEditRelatedResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
