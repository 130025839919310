import { ModelBase, generateID } from "@shoothill/core";
import { SectionType } from "Views/ConditionReport/Common/Models/BaseClasses/SectionType";
import { makeObservable, observable, action, IObservableArray } from "mobx";
import { DefaultImageSize, DefaultImageGap } from "Globals/GlobalSettings";

export class JsonImageOptions {
    public version: number = 1;
    public maxImageWidth: number = DefaultImageSize;
    public imageGap: number = DefaultImageGap; // Deprecated

    constructor() {}
}
export class ConditionReportTemplateSection extends ModelBase<ConditionReportTemplateSection> {
    // public id: string | null = "TEMPID_" + generateID();
    public templateId: string = "";
    public name: string = "";
    public typeKey: string = "";
    public ordinal: number = 1000;
    public html: string = "";
    public parentId: string | null = null;
    public controlTypeId: number = 10;
    public concurrencyToken: string = "";
    public subSections: IObservableArray<ConditionReportTemplateSection> = observable([]);
    public sectionTypeKey: string = "";
    public jsonOptions: string = "";
    public sectionType: SectionType = SectionType.FLOW;
    public isRepeatable: boolean = false;
    public hasRepeatableElements: boolean = false;
    public repeatedGroupId: string = "";
    public commentQuestionText: string = "";
    public commentQuestionTextTypeKey: string = "";
    public commentTypeKey: string = "";

    constructor() {
        super();
        makeObservable(this, {
            //id: observable,
            name: observable,
            typeKey: observable,
            sectionTypeKey: observable,
            jsonOptions: observable,
            ordinal: observable,
            html: observable,
            controlTypeId: observable,
            /* Actions */
            clear: action,
            load: action,
            /* Computeds */
        });
    }

    public clear = () => {
        this.id = "TEMPID_" + generateID();
        this.templateId = "";
        this.name = "";
        this.typeKey = "";
        this.sectionTypeKey = "";
        this.jsonOptions = "";
        this.ordinal = 1000;
        this.html = "";
        this.parentId = null;
        this.controlTypeId = 10;
        this.concurrencyToken = "";
        this.isRepeatable = false;
        this.hasRepeatableElements = false;
        this.repeatedGroupId = "";
        this.commentQuestionText = "";
        this.commentQuestionTextTypeKey = "";
        this.commentTypeKey = "";
        this.subSections.clear();
    };

    public load = (section: ConditionReportTemplateSection) => {
        this.clear();
        this.fromResponse(section);

        // Now sort out the subsections
        this.subSections.clear();

        if (section.subSections.length > 0) {
            this.subSections.push(...section.subSections);
        }
    };
}
