import styled from "@emotion/styled";
import { Box } from "Application";

export const ConditionReportAddEditPDFWrapper = styled(Box)`
    margin-top: 35px;

    //.topmostmenu {
    .editselect__menu {
        z-index: 9999 !important;
    }
    //}
`;
