import { observable, action, makeObservable } from "mobx";
import { ICommand } from "./ICommand";

export class RelayCommand implements ICommand {
    private readonly _execute: (params?: any) => any;
    private readonly _canExecute?: (params?: any) => boolean;

    constructor(execute: (...params: any[]) => any, canExecute?: () => boolean) {
        this._execute = execute;
        this._canExecute = canExecute;
        makeObservable<RelayCommand, "_execute" | "_canExecute">(this, {
            _execute: observable,
            _canExecute: observable,
            execute: action,
            canExecute: action,
        });
    }

    execute = (...params: any[]): any => {
        const canExecute = this.canExecute(...params);
        if (canExecute) return this._execute(...params);

        return false;
    };

    canExecute = (...params: any[]): boolean => {
        if (this._canExecute) {
            return this._canExecute(...params);
        }

        return true;
    };
}
