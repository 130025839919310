import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { TasksListModel } from "../TasksListModel";

export class TaskListUpsertEndpoint extends Endpoint<TasksListModel, TasksListModel> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.MasterProject.UpsertMasterProjectNewTask);
    }

    public async HandleRequestAsync(request: TasksListModel): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: TasksListModel): Promise<TasksListModel> {
        return response;
    }
}
