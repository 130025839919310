import styled from "@emotion/styled";
import { getThemeColorOption, theme, themeColourOptions } from "Application";
import { SpaceProps, space } from "styled-system";

export type ITextElementProps = {
    color?: themeColourOptions;
} & SpaceProps;

const H1Space = styled.h1<SpaceProps>`
    ${space}
`;

export const H1 = styled(H1Space)<ITextElementProps>`
    font-size: ${theme.fontStyles.h1.fontSize};
    font-weight: ${theme.fontStyles.h1.fontWeight};
    letter-spacing: ${theme.fontStyles.h1.letterSpacing};
    line-height: ${theme.fontStyles.h1.lineHeight};
    font-family: ${theme.fontStyles.h1.fontFamily};
    color: ${(props) => (getThemeColorOption(props.color) ? getThemeColorOption(props.color) : "#ffffff")};
`;

const H2Space = styled.h2<SpaceProps>`
    ${space}
`;

export const H2 = styled(H2Space)<ITextElementProps>`
    font-size: ${theme.fontStyles.h2.fontSize};
    font-weight: ${theme.fontStyles.h2.fontWeight};
    letter-spacing: ${theme.fontStyles.h2.letterSpacing};
    line-height: ${theme.fontStyles.h2.lineHeight};
    font-family: ${theme.fontStyles.h2.fontFamily};
    color: ${(props) => (getThemeColorOption(props.color) ? getThemeColorOption(props.color) : "#ffffff")};
`;

const H3Space = styled.h2<SpaceProps>`
    ${space}
`;

export const H3 = styled(H3Space)<ITextElementProps>`
    font-size: ${theme.fontStyles.h3.fontSize};
    font-weight: ${theme.fontStyles.h3.fontWeight};
    letter-spacing: ${theme.fontStyles.h3.letterSpacing};
    line-height: ${theme.fontStyles.h3.lineHeight};
    font-family: ${theme.fontStyles.h3.fontFamily};
    color: ${(props) => (getThemeColorOption(props.color) ? getThemeColorOption(props.color) : "#ffffff")};
`;

const H4Space = styled.h4<SpaceProps>`
    ${space}
`;

export const H4 = styled(H4Space)<ITextElementProps>`
    font-size: ${theme.fontStyles.h4.fontSize};
    font-weight: ${theme.fontStyles.h4.fontWeight};
    letter-spacing: ${theme.fontStyles.h4.letterSpacing};
    line-height: ${theme.fontStyles.h4.lineHeight};
    font-family: ${theme.fontStyles.h4.fontFamily};
    color: ${(props) => (getThemeColorOption(props.color) ? getThemeColorOption(props.color) : "#ffffff")};
`;

const H5Space = styled.h5<SpaceProps>`
    ${space}
`;

export const H5 = styled(H5Space)<ITextElementProps>`
    font-size: ${theme.fontStyles.h5.fontSize};
    font-weight: ${theme.fontStyles.h5.fontWeight};
    letter-spacing: ${theme.fontStyles.h5.letterSpacing};
    line-height: ${theme.fontStyles.h5.lineHeight};
    font-family: ${theme.fontStyles.h5.fontFamily};
    color: ${(props) => (getThemeColorOption(props.color) ? getThemeColorOption(props.color) : "#ffffff")};
`;

const H6Space = styled.h6<SpaceProps>`
    ${space}
`;

export const H6 = styled(H6Space)<ITextElementProps>`
    font-size: ${theme.fontStyles.h6.fontSize};
    font-weight: ${theme.fontStyles.h6.fontWeight};
    letter-spacing: ${theme.fontStyles.h6.letterSpacing};
    line-height: ${theme.fontStyles.h6.lineHeight};
    font-family: ${theme.fontStyles.h6.fontFamily};
    color: ${(props) => (getThemeColorOption(props.color) ? getThemeColorOption(props.color) : "#ffffff")};
`;

const PSpace = styled.p<SpaceProps>`
    ${space}
`;

export const P = styled(PSpace)<ITextElementProps>`
    font-size: ${theme.fontStyles.p.fontSize};
    font-weight: ${theme.fontStyles.p.fontWeight};
    letter-spacing: ${theme.fontStyles.p.letterSpacing};
    line-height: ${theme.fontStyles.p.lineHeight};
    font-family: ${theme.fontStyles.p.fontFamily};
    color: ${(props) => (getThemeColorOption(props.color) ? getThemeColorOption(props.color) : "#ffffff")};
`;
