import { Box } from "Application";
import styled from "@emotion/styled";

export const SpanOneBox = styled(Box)`
    grid-column: span 1;
`;

export const SpanTwoBox = styled(Box)`
    grid-column: span 2;
`;

export const SpanThreeBox = styled(Box)`
    grid-column: span 3;
`;

export const SpanFourBox = styled(Box)`
    grid-column: span 4;
`;

export const SpanSixBox = styled(Box)`
    grid-column: span 6;
`;

export const SpanEightBox = styled(Box)`
    grid-column: span 8;
`;

export const SpanReactive34Box = styled(Box)`
    grid-column: span 3;

    @media screen and (max-width: 1300px) {
        grid-column: span 4;
    }
`;

export const DateBlock = styled(Box)`
    .inputbox {
        display: grid !important;
        grid-template-columns: auto 25px !important;
    }
`;

export const RightDateBlock = styled(Box)`
    .inputbox {
        display: grid !important;
        grid-template-columns: auto 25px !important;
    }
    .rdtPicker {
        right: auto !important;
        left: 0 !important;
    }
`;
