import { makeObservable, observable } from "mobx";
import { FieldType, ViewModelBase } from "@shoothill/core";
import { LoginModel, LoginModelValidator } from "./LoginModel";
import { ICommand, RelayCommand } from "Application/Commands";
import { APIClient, setPageTitle } from "Application";
import { AccountStore } from "Stores/Domain";
import { singleton, container } from "tsyringe";
import { POSTLoginEndpoint } from "./POSTLoginEndpoint";
import { AppUrlIndex, AppUrls } from "AppUrls";

@singleton()
export class LoginViewModel extends ViewModelBase<LoginModel> {
    public apiClient = new APIClient();
    public endpoint = new POSTLoginEndpoint(this);
    public signInViewActive: boolean = true;
    public signUpViewActive: boolean = false;

    public crCallBack: () => void = () => {};

    constructor(loginCRCallback: () => void) {
        super(new LoginModel());

        this.crCallBack = loginCRCallback;

        this.setValidator(new LoginModelValidator());

        makeObservable(this, {
            signInViewActive: observable,
            signUpViewActive: observable,
        });
    }

    public accountStore = container.resolve(AccountStore);

    public updateEmailCommand: ICommand = new RelayCommand((value: string) => this.updateField("emailAddress", value));
    /*public updateEmailCommand: ICommand = new RelayCommand((value: string) => {
        this.model.emailAddress = value;
    });*/

    public updatePasswordCommand: ICommand = new RelayCommand((value: string) => this.updateField("password", value));

    public rememberMeCommand: ICommand = new RelayCommand((value: string) => this.updateField("rememberMe", value));

    public loginCommand: ICommand = new RelayCommand(async () => {
        if (this.isModelValid()) {
            await this.apiClient.sendAsync(this.endpoint, this.model);

            if (this.apiClient.IsRequestSuccessful && this.accountStore.IsLoggedIn) {
                if (this.accountStore.loginRedirectToConditionReport() === true) {
                    this.crCallBack();
                    setPageTitle("Condition Report");
                } else {
                    this.history.push(AppUrls.Client.Home);
                    setPageTitle("Dashboard");
                }
            }
        }
    });

    private updateField = (fieldName: keyof FieldType<LoginModel>, value: any) => {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    };
}
