import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { CondtionReportListItemModel } from "../CondtionReportListItemModel";
import { ISearchablePagedSortedTable } from "Models/TableInterfaces";
import { ConditionReportListViewModel } from "../ConditionReportListViewModel";

class DeleteConditionReportByIdRequest implements ISearchablePagedSortedTable {
    pageSize: number = 50;
    pageNumber: number = 1;
    searchString: string = "";
    orderBy: string = "";
    columnName: string = "";
    id: string = "";
    public constructor() {}
}

export interface ConditionReportListResponse {
    queryTotalCount: number;
    conditionReports: CondtionReportListItemModel[];
}

export class DeleteConditionReportByIdEndpoint extends Endpoint<DeleteConditionReportByIdRequest, ConditionReportListResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReport.DeleteConditionReportById);
    }

    public async HandleRequestAsync(model: ConditionReportListViewModel): Promise<any> {
        let request = new DeleteConditionReportByIdRequest();
        request.columnName = model.columnName;
        request.orderBy = model.orderBy;
        request.pageSize = model.pageSize;
        request.pageNumber = model.pageNumber;
        request.id = model.reportToDelete;
        return request;
    }

    public async HandleResponseAsync(response: ConditionReportListResponse): Promise<ConditionReportListResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
