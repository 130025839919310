import { KeyValuePair, ModelBase } from "@shoothill/core";
import { makeObservable, observable, action } from "mobx";
import { Validator } from "Application/Validation";
import { TINYMCE_DEFAULTCSS } from "Utils/Globals";

export interface ConditionReportPdfAndRelated {
    conditionReportPdf: ConditionReportPdf;
    bdms: KeyValuePair<any>[];
}
export interface ConditionReportPdf {
    id: string | null;
    templateId: string;
    conditionReportId: string;
    createdDate: string;
    createdById: string;
    html: string;
    pDFUrl: string;
    pDFCreatedDate: string | null;
    pDFCreatedBy: string | null;
    isDeleted: boolean;
    deletedDate: string | null;
    deletedById: string | null;
    lastUpdatedDate: string | null;
    lastUpdatedBy: string | null;
    concurrencyToken: string;
    css: string;
    documentContactId: number | null;

    projectName: string;
    siteVisitDate: string;
    backupId: number;
}

export class ConditionReportPdfModel extends ModelBase<ConditionReportPdfModel> {
    public id: string | null = null;

    public templateId: string = "";
    public conditionReportId: string = "";
    //public createdDate: string = "";
    public createdById: string = "";
    public html: string = "";
    public pDFUrl: string = "";
    public pDFCreatedDate: string | null = null;
    public pDFCreatedBy: string | null = null;
    public isDeleted: boolean = false;
    public deletedDate: string | null = null;
    public deletedById: string | null = null;
    public lastUpdatedDate: string | null = null;
    public lastUpdatedBy: string | null = null;
    public concurrencyToken: string = "";
    public css: string = TINYMCE_DEFAULTCSS;
    public documentContactId: number | null = null;
    public projectName: string = "";
    public siteVisitDate: string = "";
    public backupId: number = 0;

    constructor() {
        super();
        makeObservable(this, {
            //id: observable,
            templateId: observable,
            conditionReportId: observable,
            //createdDate: observable,
            //createdById: observable,
            html: observable,
            pDFUrl: observable,
            pDFCreatedDate: observable,
            pDFCreatedBy: observable,
            //isDeleted: observable,
            deletedDate: observable,
            deletedById: observable,
            lastUpdatedDate: observable,
            lastUpdatedBy: observable,
            concurrencyToken: observable,
            documentContactId: observable,
            projectName: observable,
            siteVisitDate: observable,
            backupId: observable,

            clear: action,
            //fromResponseV2: action,
        });
    }

    /*     public fromResponseV2 = (model: ConditionReportPdfModel) => {
        this.id = model.id;
        this.templateId = model.templateId;
        this.concurrencyToken = model.concurrencyToken;
        this.css = model.css;
    }; */

    public clear = () => {
        this.id = null;
        this.templateId = "";
        this.conditionReportId = "";
        this.createdDate = new Date();
        this.createdById = "";
        this.html = "";
        this.pDFUrl = "";
        this.pDFCreatedDate = null;
        this.pDFCreatedBy = null;
        this.isDeleted = false;
        this.deletedDate = null;
        this.deletedById = null;
        this.lastUpdatedDate = null;
        this.lastUpdatedBy = null;
        this.concurrencyToken = "";
        this.css = TINYMCE_DEFAULTCSS;
        this.documentContactId = null;
        this.projectName = "";
        this.siteVisitDate = "";
        this.backupId = 0;
    };
}

export class ConditionReportPdfModelValidator extends Validator<ConditionReportPdfModel> {
    constructor() {
        super();
        // TODO this.ruleFor("id").notEmpty();
    }
}
