import { action, makeObservable, observable } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "../../Application";
import { AppUrls } from "AppUrls";

export type ActiveViews = "SignInView" | "SignUpView" | "ConfirmEmail" | "ForgotPasswordView" | "ResetPasswordView";
export class LoginHomeViewModel extends ViewModelBase<any> {
    public apiClient = new APIClient();
    public activeView: ActiveViews = "SignInView";
    public signUpButtonsVisible: boolean = true;

    constructor() {
        super({});
        makeObservable(this, {
            activeView: observable,
            signUpButtonsVisible: observable,
            setActiveView: action,
        });
    }

    public setActiveView = (activeView: ActiveViews) => {
        this.activeView = activeView;
    };

    public setSignUpButtonsVisible = (visible: boolean) => {
        this.signUpButtonsVisible = visible;
    };

    public navigateToSignInCommand: ICommand = new RelayCommand(() => {
        this.setSignUpButtonsVisible(true);
        this.history.replace(AppUrls.Client.Account.Login);
    });

    public navigateToSignUpCommand: ICommand = new RelayCommand(() => {
        this.setActiveView("SignUpView");
        this.setSignUpButtonsVisible(true);
        this.history.replace(AppUrls.Client.Account.Register);
    });

    public navigateToForgotPasswordCommand: ICommand = new RelayCommand(() => {
        this.setSignUpButtonsVisible(false);
        this.setActiveView("ForgotPasswordView");
        this.history.replace(AppUrls.Client.Account.ForgotPassword);
    });
}
