import { makeObservable } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { IAddedDocumentViewModel } from "Models/Documents/IAddedDocumentViewModel";
import { TSMDocumentItemModel, TSMDocumentItemModelValidator } from "./TSMDocumentItemModel";

export class TSMDocumentItemViewModel extends ViewModelBase<TSMDocumentItemModel> implements IAddedDocumentViewModel<TSMDocumentItemModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super(new TSMDocumentItemModel());
        this.setValidator(new TSMDocumentItemModelValidator());
        makeObservable(this, {});
    }

    public clear = () => {
        this.model.clear();
    };

    public setDocumentType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("documentCategoryId", Number(value.key));
    });
}
