import { AppUrls } from "AppUrls";
import { Endpoint, Http } from "Application";
import { ContactsListViewModel } from "../ContactsListViewModel";
import { getFilenameFromContentDisposition } from "Utils/File";

class Request {
    orderBy: string = "";
    columnName: string = "";
    filterByType: number[] | string[] = [];
    filterbyCustomer: Guid | null = null;
    filterByPlygeneTrained: string[] = [];
    filterByCoatingsTrained: string[] = [];
    filterByStatus: number | null = null;
    searchString: string = "";
    public initialLoad: boolean = true;
}

export class DownloadContactsCSVEndpoint extends Endpoint<Request, Response> {
    private viewModel: ContactsListViewModel;

    constructor(viewModel: ContactsListViewModel) {
        super();
        this.viewModel = viewModel;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Contact.DownloadCSV);
        this.responseIsBlob(true);
        this.SetContentType("text/csv");
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new Request();
        request.columnName = this.viewModel.columnName;
        request.orderBy = this.viewModel.orderBy;
        request.filterByType = this.viewModel.filterByType;
        request.filterbyCustomer = this.viewModel.filterBycustomer;
        request.filterByPlygeneTrained = this.viewModel.filterByPlygeneTrained;
        request.filterByCoatingsTrained = this.viewModel.filterByCoatingsTrained;
        request.searchString = this.viewModel.searchString;
        request.initialLoad = this.viewModel.initialLoad;
        return request;
    }

    public async HandleResponseAsync(response: any): Promise<boolean> {
        let retVal: boolean = true;
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        //link.href = window.URL.createObjectURL(new Blob([response]));
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        //Return your model
        return retVal;
    }
}
