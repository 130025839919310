import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { GetTSMListAndRelatedRequest, GetTSMListAndRelatedResponse } from "./GetTSMListAndRelatedEndpoint";
import { TSMListViewModel } from "../TSMListViewModel";
import { ProjectStatusEnum } from "Models/Project/ProjectStatusEnum";

class ToggleShowOnTsmTabRequest extends GetTSMListAndRelatedRequest {
    public projectId: Guid = "";
    public showOnTsmTab: boolean = true;
}

export class ToggleShowOnTsmTabResponse extends GetTSMListAndRelatedResponse {}

export class ToggleShowOnTsmTabEndpoint extends Endpoint<ToggleShowOnTsmTabRequest, ToggleShowOnTsmTabResponse> {
    public projectId: Guid = "";
    public showOnTsmTab: boolean = true;

    constructor(id: Guid, toggle: boolean) {
        super();

        this.projectId = id;
        this.showOnTsmTab = toggle;
        this.verb(Http.Put);
        this.path(AppUrls.Server.Tsm.ToggleShowOnTsmTab);
    }

    public async HandleRequestAsync(viewModel: TSMListViewModel): Promise<any> {
        let request = new ToggleShowOnTsmTabRequest();

        request.projectId = this.projectId;
        request.showOnTsmTab = this.showOnTsmTab;

        request.id = viewModel.tsmId; // TODO!!!!! CHANGE!!!
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageSize = viewModel.pageSize;
        request.pageNumber = viewModel.pageNumber;
        request.searchString = viewModel.searchString;

        request.filterByProduct = viewModel.filterByProduct.map((item) => {
            return parseInt(item);
        });

        request.filterByStatus = viewModel.filterByStatus.map((item) => {
            return parseInt(item) as ProjectStatusEnum;
        });

        request.filterBySurveyed = viewModel.filterBySurveyed.map((item) => {
            let retVal = true;
            if (item === "true") {
                retVal = true;
            } else if (item === "false") {
                retVal = false;
            }
            return retVal;
        });

        request.filterByVisited = viewModel.filterByVisited.map((item) => {
            let retVal = true;
            if (item === "true") {
                retVal = true;
            } else if (item === "false") {
                retVal = false;
            }
            return retVal;
        });

        request.filterByVisible = viewModel.filterByVisible.map((item) => {
            let retVal = true;
            if (item === "true") {
                retVal = true;
            } else if (item === "false") {
                retVal = false;
            }
            return retVal;
        });

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: ToggleShowOnTsmTabResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
