import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { CorrectiveActionViewModel } from "./CorrectiveActionViewModel";
import { SharmansTable } from "Components/Primitives/DataTable/SharmansTable";
import { createColumnHelper } from "@tanstack/react-table";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import NewIcon from "Assets/Icons/newRoundButtonIcon.svg";
import { useParams } from "react-router";
import { AddEditActionModal } from "../Modals/AddEditActionModal";
import { formatDate, formatDateTime } from "Utils/Formats";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { CenteredCell } from "Utils/CellFormatComponents";
import { PageTabWrapper } from "Styles/BaseStyles";

interface ICorrectiveActionViewProps {
    correctiveActionCountCallBack: (newCount: number) => void;
}

export const CorrectiveActionView: React.FC<ICorrectiveActionViewProps> = observer(({ correctiveActionCountCallBack }) => {
    const [viewModel] = useState(() => new CorrectiveActionViewModel());
    const columnHelper = createColumnHelper<any>();
    const params = useParams();

    useEffect(() => {
        viewModel.init(correctiveActionCountCallBack);
        if (params.complaintid) {
            viewModel.loadActionList(params.complaintid);
            viewModel.setComplaintId(params.complaintid);
        }
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const columns = [
        columnHelper.accessor("createdDate", {
            size: 100,
            header: () => "Created Date",
            cell: (info) => {
                return formatDate(new Date(info.getValue()));
            },
        }),

        columnHelper.accessor("action", {
            size: 600,
            header: () => "Action",
            cell: (info) => info.getValue(),
        }),

        columnHelper.accessor("author", {
            size: 150,
            header: () => "Author",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("lastUpdated", {
            size: 250,
            header: () => "Last Updated",
            cell: (info) => {
                return formatDateTime(new Date(info.getValue()));
            },
        }),

        columnHelper.accessor("id", {
            header: () => "Actions",
            cell: (info) => (
                <CenteredCell style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    <EditButton id={info.getValue()} onClick={viewModel.openEditActionModal} />
                    <DeleteButton id={info.getValue()} onClick={viewModel.deleteActionOpenAreYouSureModal} />
                </CenteredCell>
            ),
            enableSorting: false,
        }),
    ];

    return (
        <>
            <PageTabWrapper>
                {viewModel.isModalOpen === true && (
                    <AddEditActionModal
                        title={viewModel.addOrEditModal === ModalType.Add ? "Add corrective action" : "Edit corrective action"}
                        inputCommand={viewModel.actionInputCommand}
                        buttonText={viewModel.addOrEditModal === ModalType.Add ? "Add action" : "Save action"}
                        inputValue={() => viewModel.getValue("correctiveAction")}
                        isOpen={viewModel.isModalOpen}
                        validationMessage={() => viewModel.getError("correctiveAction")}
                        onConfirm={viewModel.upsertAction}
                        onCancel={viewModel.closeModal}
                    />
                )}
                {viewModel.isAreYouSureModalOpen === true && (
                    <AreYouSureModal
                        title={"Delete action"}
                        message={"Are you sure you want to delete this action?"}
                        isOpen={viewModel.isAreYouSureModalOpen}
                        onConfirm={viewModel.proceedWithDelete}
                        onCancel={viewModel.cancelDelete}
                    />
                )}

                <Box className="tabheader" flexBox style={{ justifyContent: "space-between" }}>
                    <H5 pt="15px">Corrective Action</H5>
                    <ButtonUnder
                        command={viewModel.openAddModal}
                        displayName=" Add corrective action"
                        paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                        boxShadow={true}
                        icon={<img src={NewIcon} />}
                    />
                </Box>
                <Box className="tabheaderbuffer"></Box>
                <Box>
                    <SharmansTable columns={columns} data={viewModel.getActions} manualSorting={false} />
                    {viewModel.getActions.slice().length === 0 && (
                        <P pt="40px" style={{ textAlign: "center" }}>
                            There are no corrective actions to display.
                        </P>
                    )}
                </Box>
            </PageTabWrapper>
        </>
    );
});
