import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, ICommand, Input, RelayCommand, ThemedLoader } from "Application";
import { TemplateEditor } from "./ConditionReportTemplateAddEdit.style";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMCE_APIKEY, TINYMCE_DEFAULTCSS, TINYMCE_FONTFAMILY, TINYMCE_FONTSIZES } from "Utils/Globals";
import { setupQuestionPlaceholderPlugin } from "./Plugins/TinyMCE/QuestionPlaceholder";
import { ControlType, ControlTypeHelpers, IExtendedSelectOption } from "Views/ConditionReport/Common/ControlType";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { ConditionReportTemplateSection } from "./ConditionReportTemplateSection";
import { generateID } from "@shoothill/core";
import { TemplateSectionViewModel } from "./TemplateSectionViewModel";
import { BlobResponse } from "Views/ConditionReport/Pdf/Endpoints/UploadImageEndpoint";
import { preprocessPaste } from "Utils/html";

export interface ITemplateSectionView {
    section: ConditionReportTemplateSection;
    isSubSection: boolean;
    templateCss: string;

    placeHolderOptions: IExtendedSelectOption[];

    repeatedSectionOptions: IExtendedSelectOption[];

    onUpdate: () => void;
    setSectionDirty: () => void;
}

export const TemplateSectionView: React.FC<ITemplateSectionView> = observer(
    ({ templateCss, placeHolderOptions, repeatedSectionOptions, isSubSection, section, setSectionDirty, onUpdate }) => {
        const editorRef: any = useRef(null);

        const [viewModel] = useState<TemplateSectionViewModel>(new TemplateSectionViewModel(section));

        const [counter, setCounter] = useState<number>(0);

        const [resetCSS, setResetCss] = useState<boolean>(false);

        useEffect(() => {
            return () => {};
        }, []);

        useEffect(() => {
            setResetCss(true);

            setTimeout(() => {
                setResetCss(false);
            }, 100);
        }, [templateCss]);

        const isQuestion: boolean = section.parentId !== null && section.subSections.length > 0; // && hasOptions(section.subSections);

        const classname: string = isQuestion === true ? "questionanswer" : "";

        const setSectionNameCommand: ICommand = new RelayCommand((value: string) => {
            section.name = value;
            setSectionDirty();
        });

        const updateSelectedSectionText = (value: string) => {
            section.html = value;
            setSectionDirty();
        };

        const onUpdateCommand: ICommand = new RelayCommand(() => {
            onUpdate();
        });

        const getSectionTypeDescription = (): string => {
            let retVal: string = "";

            switch (section.controlTypeId) {
                case ControlType.ButtonGroup: {
                    retVal =
                        "Button Group - Choose to add the options as, say checkboxes in the main text (maybe in a table), and map the options across multiple sections, which are shown / hidden depending on the single option chosen";
                    break;
                }
                case ControlType.Camera: {
                    retVal = "Camera - Images will be automatically applied from the source file.";
                    break;
                }

                case ControlType.Dropdown: {
                    retVal = "Dropdown - Add the options into the main text section";
                    break;
                }

                case ControlType.Label: {
                    retVal = "Label - This is an error...";
                    break;
                }

                case ControlType.LargeTextBox: {
                    retVal = "LargeTextBox. This is an error...";
                    break;
                }

                case ControlType.Multiselect: {
                    retVal = "Multi select - Add the options into the main text section";
                    break;
                }

                case ControlType.SketchPad: {
                    retVal = "Sketch Pad Images - Images will be automatically applied from the source file.";
                    break;
                }

                case ControlType.SmallTextBox: {
                    retVal = "Small Text Box - This is an error...";
                    break;
                }

                case ControlType.Paragraph: {
                    retVal = "General Text Area - Use this for introductions, general text, and use the placeholders to add questions and answers out of the json file.";
                    break;
                }

                case ControlType.Unknown:

                default: {
                    retVal = "Unknown.  This is an error...";
                    break;
                }
            }
            return retVal;
        };

        const showImageSection = () => {
            let retVal: boolean = false;

            if (section.controlTypeId === ControlType.Camera || section.controlTypeId === ControlType.SketchPad) {
                retVal = true;
            }
            return retVal;
        };

        const showSectionEditor = () => {
            let retVal: boolean = true;

            switch (section.controlTypeId) {
                case ControlType.Label:
                case ControlType.Dropdown:
                case ControlType.LargeTextBox:
                case ControlType.SmallTextBox:
                case ControlType.Camera:
                case ControlType.SketchPad: {
                    retVal = false;
                    break;
                }
                default: {
                    break;
                }
            }

            return retVal;
        };

        const showSubSections = () => {
            let retVal: boolean = false;

            if (section.subSections.length > 0) {
                retVal = true;
            }

            return retVal;
        };

        const editorHeight: number = isSubSection === true ? 250 : 500;

        let editorPlugins: string[] = [
            "preview",
            "autolink",
            "directionality",
            "visualchars",
            "nonbreaking",
            "code",
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
            "importcss",
            "questionplaceholders",
        ];

        let toolbar: string =
            "blocks fontfamily fontsize | " +
            "bold italic underline strikethrough | image | table | fontselect fontsizeselect formatselect | alignleft aligncenter " +
            "alignright alignjustify | outdent indent |bullist numlist lists | forecolor backcolor removeformat | preview | questionplaceholders |  undo redo " +
            "help ";

        let contextmenu: string =
            "code | undo redo | cut copy paste | bold italic underline | fontformats | fontsizes | questionplaceholders | table tabledelete | tableprops tablerowprops tablecellprops";

        let subSectionPlaceholders: IExtendedSelectOption[] = [];
        let placeHolders: IExtendedSelectOption[] = placeHolderOptions;

        if (isSubSection === false && section.subSections.length > 0) {
            if (section.sectionTypeKey.toLocaleLowerCase() === "sesiteinfo") {
                // I believe 'How was this condition report generated?' is the only section where we need to
                // do something different???

                if (section.commentQuestionText !== null && section.commentQuestionText.length > 0) {
                    let commentQuestionText: string = section.commentQuestionText;
                    if (commentQuestionText.toLocaleLowerCase() === "placeholder") {
                        commentQuestionText = section.name;
                    }
                    subSectionPlaceholders.push({
                        display: commentQuestionText + " (Comment Question)",
                        className: "",
                        value: section.commentQuestionTextTypeKey,
                    });
                    subSectionPlaceholders.push({ display: section.name + " (Comment)", className: "", value: section.commentTypeKey });
                }
            }

            for (const element of section.subSections.slice()) {
                // List all questions and answers
                subSectionPlaceholders.push({ display: element.name + " (Text)", className: "", value: element.name });
                subSectionPlaceholders.push({ display: element.name + " (Option)", className: "", value: element.typeKey });

                if (
                    element.commentQuestionText !== null &&
                    element.commentQuestionText.length > 0 &&
                    element.commentQuestionTextTypeKey !== null &&
                    element.commentQuestionTextTypeKey.length > 0
                ) {
                    let commentQuestionText: string = element.commentQuestionText;
                    if (commentQuestionText.toLocaleLowerCase() === "placeholder") {
                        commentQuestionText = element.name;
                    }

                    subSectionPlaceholders.push({
                        display: commentQuestionText + " (Comment Question)",
                        className: "",
                        value: element.commentQuestionTextTypeKey,
                    });
                    subSectionPlaceholders.push({ display: element.name + " (Comment)", className: "", value: element.commentTypeKey });
                } else if (element.controlTypeId === ControlType.ButtonGroup) {
                    if (
                        element.commentQuestionText !== null &&
                        element.commentQuestionText.length > 0 &&
                        element.commentQuestionTextTypeKey !== null &&
                        element.commentQuestionTextTypeKey.length > 0
                    ) {
                        let commentQuestionText: string = element.commentQuestionText;
                        if (commentQuestionText.toLocaleLowerCase() === "placeholder") {
                            commentQuestionText = element.name;
                        } else {
                            commentQuestionText = element.name + " - " + element.commentQuestionText;
                        }

                        subSectionPlaceholders.push({ display: commentQuestionText + " (Comment Question)", className: "", value: element.commentQuestionTextTypeKey });
                        subSectionPlaceholders.push({ display: element.name + " (Comment)", className: "", value: element.commentQuestionTextTypeKey });
                    }
                }

                // Need to check if the subsections have subsections.
                if (element.subSections.length > 0) {
                    for (const subElement of element.subSections.slice()) {
                        subSectionPlaceholders.push({ display: subElement.name + " (Text)", className: "", value: subElement.name });
                        subSectionPlaceholders.push({ display: subElement.name + " (Option)", className: "", value: subElement.typeKey });

                        if (
                            subElement.commentQuestionText !== null &&
                            subElement.commentQuestionText.length > 0 &&
                            subElement.commentQuestionTextTypeKey !== null &&
                            subElement.commentQuestionTextTypeKey.length > 0
                        ) {
                            let commentQuestionText: string = subElement.commentQuestionText;
                            if (commentQuestionText.toLocaleLowerCase() === "placeholder") {
                                commentQuestionText = subElement.name;
                            } else {
                                commentQuestionText = subElement.name + " - " + subElement.commentQuestionText;
                            }

                            subSectionPlaceholders.push({
                                display: commentQuestionText + " (Comment Question)",
                                className: "",
                                value: subElement.commentQuestionTextTypeKey,
                            });
                            subSectionPlaceholders.push({ display: commentQuestionText + " (Comment)", className: "", value: subElement.commentTypeKey });
                        } /* TODO CMR  Issue is this is not in the Template currently   else if (element.controlTypeId === ControlType.ButtonGroup) {
                            subSectionPlaceholders.push({
                                display: subElement.name + " (Comment Header)",
                                className: "",
                                value: subElement.commentQuestionTextTypeKey,
                            });
                            subSectionPlaceholders.push({ display: subElement.name + " (Comment)", className: "", value: subElement.commentTypeKey });
                        } */
                    }
                }
            }

            repeatedSectionOptions = subSectionPlaceholders;
        }

        const updateMaxImageWidthCommand: ICommand = new RelayCommand((value: string) => {
            if (value.length === 0) {
                value = "0";
            }
            viewModel.setMaxImageWidth(parseInt(value));
            setSectionDirty();
            setCounter(counter + 1);
        });

        /*         const updateImageGapCommand: ICommand = new RelayCommand((value: string) => {
            if (value.length === 0) {
                value = "0";
            }
            viewModel.setImageGap(parseInt(value));
            setSectionDirty();
            setCounter(counter + 1);
        }); */

        return (
            <>
                {resetCSS === true && <ThemedLoader spinnerText={"Loading"} isOpen={true} />}
                {resetCSS === false && (
                    <>
                        <TemplateEditor>
                            {isSubSection === false && (
                                <Input displayName="Section name" placeholder="Name of the section" command={setSectionNameCommand} value={() => section.name} />
                            )}
                            {isSubSection === true && <h2>{section.name} </h2>}
                        </TemplateEditor>
                        {isSubSection === false && (
                            <TemplateEditor>
                                <H2>Section Type : {ControlTypeHelpers.getText(section.controlTypeId)}</H2>
                            </TemplateEditor>
                        )}
                        <TemplateEditor>
                            <p>{getSectionTypeDescription()}</p>
                        </TemplateEditor>
                        {showSectionEditor() === true && (
                            <>
                                <Editor
                                    apiKey={TINYMCE_APIKEY}
                                    onInit={(evt, editor) => (editorRef.current = editor)}
                                    value={section.html ?? ""}
                                    onEditorChange={updateSelectedSectionText}
                                    init={{
                                        height: editorHeight,
                                        menubar: false,
                                        browser_spellcheck: true,
                                        plugins: editorPlugins,
                                        paste_as_plain_text: true,
                                        paste_as_text: true,
                                        paste_text_sticky: true,
                                        paste_use_dialog: false,
                                        paste_auto_cleanup_on_paste: true,
                                        paste_convert_headers_to_strong: false,
                                        paste_strip_class_attributes: "all",
                                        paste_remove_spans: true,
                                        paste_remove_styles: true,
                                        file_picker_types: "image",
                                        font_size_formats: TINYMCE_FONTSIZES,
                                        font_formats: TINYMCE_FONTFAMILY,
                                        content_style: templateCss.length > 0 ? templateCss : TINYMCE_DEFAULTCSS,

                                        paste_data_images: false,
                                        paste_preprocess: function (plugin, args) {
                                            // From Tiny MCE Due to browser limitations, it is not possible to filter content that is dragged and dropped into the editor.

                                            if (args.content.indexOf('<img src="blob:') > -1) {
                                                //don't allow pasting of local blobs
                                                //alert("Please upload the image, using the meny and drag and drop with images not supported.");
                                                args.content = "";
                                            } else {
                                                // Remove the styling / classes / id from the pasted object.
                                                // since paste_strip_class_attributes, paste_remove_styles, paste_remove_spans doesn't
                                                // seem to actually do anything.
                                                const elem = document.createElement("div");

                                                elem.innerHTML = args.content.replaceAll("\r", " ").replaceAll("\n", "").replace("  ", " ");

                                                for (let x: number = 0; x < elem.children.length; x++) {
                                                    const node: Element | null = elem.children[x];
                                                    if (node !== null) {
                                                        preprocessPaste(node);
                                                    }
                                                }
                                                args.content = elem.innerHTML;
                                            }
                                        },
                                        file_picker_callback: (cb, value, meta) => {
                                            const input = document.createElement("input");
                                            input.setAttribute("type", "file");
                                            input.setAttribute("accept", "image/*");

                                            input.addEventListener("change", (e: any) => {
                                                const file = e.target.files[0];

                                                const reader: FileReader = new FileReader();
                                                reader.addEventListener("load", () => {
                                                    const base64: string = reader.result!.toString().split(",")[1];
                                                    let promise = viewModel.uploadImage(base64, file);

                                                    promise.then(function (result: BlobResponse) {
                                                        /* call the callback and populate the Title field with the file name */
                                                        if (result.originalFileName.length > 0 && result.url.length > 0) {
                                                            cb(result.url, { title: result.originalFileName });
                                                        }
                                                    });
                                                });
                                                reader.readAsDataURL(file);
                                            });

                                            input.click();
                                        },
                                        toolbar: toolbar,
                                        contextmenu: contextmenu,
                                        setup: (editor: any) => {
                                            setupQuestionPlaceholderPlugin(editor, placeHolders);
                                        },
                                    }}
                                />
                                <div style={{ width: "100px", marginTop: "15px" }}>
                                    <Button mr={2} command={onUpdateCommand} paletteColor="ButtonGreen" displayName={"Update"} style={{ padding: "10px 60px" }} />
                                </div>
                            </>
                        )}
                        {showImageSection() === true && (
                            <TemplateEditor>
                                <p>This section will pull all the images from the appropriate section and set them up with the settings below. </p>
                                <p>
                                    Max width should be around 250 as per the original template. Note: the width of the document is 794 px (which is A4 at 96dpi). Gap is auto
                                    generated by the CSS.
                                </p>
                                <Input
                                    displayName="Max Image Width *"
                                    value={viewModel.getImageMaxWidth}
                                    command={updateMaxImageWidthCommand}
                                    /*  validationMessage={() => section.getError("imageMaxWidth")} */
                                    autoFill={false}
                                    type="number"
                                />
                                {/*                                 <p>&nbsp;</p>
                                <Input
                                    displayName="Gap between images *"
                                    value={viewModel.getImageGap}
                                    command={updateImageGapCommand}
                                    autoFill={false}
                                    type="number"
                                /> */}
                            </TemplateEditor>
                        )}
                        {showSubSections() === true && showImageSection() === false && (
                            <TemplateEditor className={classname}>
                                {section.parentId !== null && (
                                    <>
                                        <H2>Section's options</H2>
                                        <p>Fill in the text to show for the option. Leave blank and it will be ignored</p>
                                    </>
                                )}

                                {section.subSections.slice().map((subsection) => {
                                    let isSubsection: boolean = true;

                                    return (
                                        <>
                                            <TemplateEditor key={generateID()}>
                                                <TemplateSectionView
                                                    templateCss={templateCss}
                                                    section={subsection}
                                                    isSubSection={isSubsection}
                                                    placeHolderOptions={isSubsection === true ? repeatedSectionOptions : subSectionPlaceholders}
                                                    repeatedSectionOptions={repeatedSectionOptions}
                                                    onUpdate={onUpdate}
                                                    setSectionDirty={setSectionDirty}
                                                />
                                            </TemplateEditor>
                                            <hr></hr>
                                        </>
                                    );
                                })}
                            </TemplateEditor>
                        )}
                    </>
                )}
            </>
        );
    },
);
