import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { FilteringTopSectionGenericModel, FilteringTopSectionGenericModelValidator } from "./FilteringTopSectionGenericModel";

export class FilteringTopSectionGenericViewModel extends ViewModelBase<FilteringTopSectionGenericModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public searchString: string = "";

    constructor() {
        super(new FilteringTopSectionGenericModel());
        this.setValidator(new FilteringTopSectionGenericModelValidator());
        makeObservable(this, {
            searchString: observable,

            clear: action,
            setSearchString: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public setSearchString = (keyword: string) => {
        this.searchString = keyword;
    };
}
