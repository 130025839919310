import { ViewModelBase } from "@shoothill/core";
import { action, computed, makeObservable, observable } from "mobx";

export class HeaderViewModel extends ViewModelBase<any> {
    public activeMenuItem: string = "Home";

    constructor() {
        super({});
        makeObservable(this, {
            activeMenuItem: observable,
            setActiveMenuItem: action,
            isActiveMenuItem: action,
        });
    }

    public setActiveFromLocation = (location: string, menuPath: string, menuText: string): void => {
        const locationSegment = location.split("/")[1].toLowerCase();
        const pathSegment = menuPath.split("/")[1].toLowerCase();
        if (location.toLowerCase() === menuPath.toLowerCase()) {
            this.setActiveMenuItem(menuText);
        }
    };

    public setActiveMenuItem(menuItem: string): void {
        this.activeMenuItem = menuItem;
    }

    public isActiveMenuItem = (menuItem: string): boolean => {
        if (this.activeMenuItem == menuItem) {
            return true;
        } else {
            return false;
        }
    };

    // #endregion Computeds
}
