import styled from "@emotion/styled";
import { Button } from "./Button";
import { Box } from "Application";

export const ButtonUnder = styled(Button)`
    border-radius: 0px 0px 10px 10px;
    z-index: 10;
`;

export const ButtonUnderBox = styled(Box)`
    display: flex;
    justify-content: right;
`;
