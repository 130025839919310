import { makeObservable, action, observable, runInAction, IObservableArray } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ComplaintsModel, ComplaintsModelValidator } from "./ComplaintsModel";
import { GetDetailsForViewEndpoint, GetDetailsForViewResponse } from "./Endpoints/GetDetailsForView";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { ComplaintsListViewModel } from "../List/ComplaintsListViewModel";

export class ComplaintsViewModel extends ViewModelBase<ComplaintsModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public allComplaints: IObservableArray<Guid> = observable([]);
    public complaints = ComplaintsListViewModel.Instance.model.complaints;

    constructor() {
        super(new ComplaintsModel());
        this.setValidator(new ComplaintsModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public nextComplaintCommand: ICommand = new RelayCommand(() => {
        let currentIndex = this.allComplaints.indexOf(this.model.id);
        if (this.allComplaints.length > 1) {
            if (currentIndex < this.allComplaints.length - 1) {
                this.history.push(AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid", String(this.allComplaints[currentIndex + 1])));
                this.loadComplaint(this.allComplaints[currentIndex + 1]);
            } else {
                this.history.push(AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid", String(this.allComplaints[0])));
                this.loadComplaint(this.allComplaints[0]);
            }
        }
    });

    public prevComplaintCommand: ICommand = new RelayCommand(() => {
        let currentIndex = this.allComplaints.indexOf(this.model.id);
        if (this.allComplaints.length > 1) {
            if (currentIndex !== 0) {
                this.history.push(AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid", String(this.allComplaints[currentIndex - 1])));
                this.loadComplaint(this.allComplaints[currentIndex - 1]);
            } else {
                this.history.push(AppUrls.Client.Aftersales.ComplaintsView[AppUrlIndex.Long].replace(":complaintid", String(this.allComplaints[this.allComplaints.length - 1])));
                this.loadComplaint(this.allComplaints[this.allComplaints.length - 1]);
            }
        }
    });

    public loadComplaint = async (id: Guid): Promise<GetDetailsForViewResponse> => {
        const endpoint = new GetDetailsForViewEndpoint(id);
        let retVal: GetDetailsForViewResponse = new GetDetailsForViewResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            // Taken out as was stopping the table ordering from working
            // this.isProcessing = true;

            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: GetDetailsForViewResponse = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.model.complaintType = response.complaintType;
                    this.model.documentTypes = response.documentTypes;
                    this.allComplaints.replace(this.complaints.map((complaint) => complaint.id));
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the complaint details.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };
}
