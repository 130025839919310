import React from "react";
import { observer } from "mobx-react-lite";

export const LogoWithText: React.FC = observer(() => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="240.692" height="63.067" viewBox="0 0 240.692 63.067">
                <g id="Group_9462" data-name="Group 9462" transform="translate(-32.8 -10.2)">
                    <g id="Group_8" data-name="Group 8" transform="translate(32.8 10.2)">
                        <path
                            id="Path_11"
                            data-name="Path 11"
                            d="M38.1,24.981l6.985,3.792,10.578-5.588L84,37.554V30.768L55.663,15.8Z"
                            transform="translate(-27.522 -4.624)"
                            fill="#ce0e2d"
                        />
                        <path id="Path_12" data-name="Path 12" d="M78.725,36.468,50.585,21.3,43.4,25.092,78.725,43.453Z" transform="translate(-22.245 0.853)" fill="#ce0e2d" />
                        <path
                            id="Path_13"
                            data-name="Path 13"
                            d="M60.941,17.584l28.34,14.769V24.969L61.14,10.2h-.2L32.8,24.969h0l7.185,3.792Z"
                            transform="translate(-32.8 -10.2)"
                            fill="#ce0e2d"
                        />
                        <path
                            id="Path_14"
                            data-name="Path 14"
                            d="M89.281,38.084,82.1,34.292,60.941,45.469,32.8,30.7v7.384L60.941,52.853h0Z"
                            transform="translate(-32.8 10.214)"
                            fill="#ce0e2d"
                        />
                        <path
                            id="Path_15"
                            data-name="Path 15"
                            d="M78.7,37.973l-7.185-3.792L60.941,39.769,32.8,25.2v7.185L60.941,47.154Z"
                            transform="translate(-32.8 4.737)"
                            fill="#010202"
                        />
                        <path id="Path_16" data-name="Path 16" d="M67.926,37.961,32.8,19.6v7.384L60.941,41.554Z" transform="translate(-32.8 -0.84)" fill="#ce0e2d" />
                    </g>
                    <path
                        id="Path_17"
                        data-name="Path 17"
                        d="M82.673,27.189a5.717,5.717,0,0,0-4.391-1.6c-2.2,0-3.193.6-3.193,1.8s1.2,1.8,3.193,2c3.393.2,7.584,1,7.584,5.588,0,3.193-2.595,5.788-7.584,5.788-2.794,0-5.588-.4-8.183-3.193l2.2-3.193a8.261,8.261,0,0,0,5.987,2.395c1.6,0,3.193-.6,3.193-2,0-1.2-1-1.8-3.592-2-3.193-.2-7.384-1.4-7.384-5.389C70.5,23.2,74.69,22,77.884,22a9.931,9.931,0,0,1,6.786,2.2Z"
                        transform="translate(37.143 11.75)"
                        fill="#010202"
                    />
                    <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M83.791,18.6v9.58a6.748,6.748,0,0,1,5.588-2.595c4.989,0,7.185,3.393,7.185,8.582v9.38H92.173v-9.38c0-3.193-1.6-4.59-3.992-4.59a4.526,4.526,0,0,0-4.391,4.79v9.181H79.4V18.6Z"
                        transform="translate(46.404 8.365)"
                        fill="#010202"
                    />
                    <path
                        id="Path_19"
                        data-name="Path 19"
                        d="M93.79,31.281a5.084,5.084,0,0,0,5.189,5.389c6.786,0,6.786-10.777,0-10.777a5.084,5.084,0,0,0-5.189,5.389M104.368,22.5h4.191V40.062h-4.191l-.2-2.595c-1,2-3.792,3.193-5.788,3.193-5.189,0-9.181-3.193-9.181-9.38S93.192,22.1,98.381,22.1a6.7,6.7,0,0,1,5.588,2.794Z"
                        transform="translate(56.163 11.85)"
                        fill="#010202"
                    />
                    <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M104.992,22.5l.4,2c1.2-2.2,3.193-2.395,4.989-2.395a7.9,7.9,0,0,1,4.59,1.6l-2,3.792a5.146,5.146,0,0,0-3.193-1.2,4.072,4.072,0,0,0-4.391,4.391v9.38H101V22.5Z"
                        transform="translate(67.913 11.85)"
                        fill="#010202"
                    />
                    <path
                        id="Path_21"
                        data-name="Path 21"
                        d="M120.475,40.062v-9.58c0-2.395-1.2-4.391-3.592-4.391s-3.792,2.2-3.792,4.391v9.58H108.7V22.5h3.992l.4,2a5.123,5.123,0,0,1,4.59-2.395,5.053,5.053,0,0,1,5.189,3.393,6.243,6.243,0,0,1,5.987-3.193c4.989,0,7.384,2.994,7.384,8.382v9.58h-4.391v-9.58c0-2.395-1-4.391-3.393-4.391s-3.792,2-3.792,4.391v9.58h-4.191Z"
                        transform="translate(75.58 11.85)"
                        fill="#010202"
                    />
                    <path
                        id="Path_22"
                        data-name="Path 22"
                        d="M128.39,31.281a5.084,5.084,0,0,0,5.189,5.389c6.786,0,6.786-10.777,0-10.777a5.084,5.084,0,0,0-5.189,5.389M138.968,22.5h4.191V40.062h-4.191l-.2-2.595c-1,2-3.792,3.193-5.788,3.193-5.189,0-9.181-3.193-9.181-9.38s3.992-9.181,9.181-9.181a6.7,6.7,0,0,1,5.588,2.794Z"
                        transform="translate(90.617 11.85)"
                        fill="#010202"
                    />
                    <path
                        id="Path_23"
                        data-name="Path 23"
                        d="M148.672,40.062v-9.38c0-2.595-1.6-4.79-4.191-4.79-2.794,0-4.59,2.2-4.59,4.989v9.181H135.5V22.5h3.992l.2,2.2a7.112,7.112,0,0,1,5.788-2.595c4.191,0,7.384,3.193,7.384,8.582v9.38h-4.191Z"
                        transform="translate(102.268 11.85)"
                        fill="#010202"
                    />
                    <path
                        id="Path_24"
                        data-name="Path 24"
                        d="M157.874,27.189a5.717,5.717,0,0,0-4.391-1.6c-2.2,0-3.193.6-3.193,1.8s1.2,1.8,3.393,2c3.393.2,7.584,1,7.584,5.588,0,3.193-2.595,5.788-7.584,5.788-2.794,0-5.588-.4-8.183-3.193l2.2-3.193a8.261,8.261,0,0,0,5.987,2.395c1.6,0,3.193-.6,3.193-2,0-1.2-1-1.8-3.592-2-3.193-.2-7.384-1.4-7.384-5.389C145.9,23.2,150.09,22,153.284,22a9.931,9.931,0,0,1,6.786,2.2Z"
                        transform="translate(112.225 11.75)"
                        fill="#010202"
                    />
                </g>
            </svg>
        </>
    );
});
