import { makeObservable, action, runInAction, observable } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ConditionReportTemplateSection, JsonImageOptions } from "./ConditionReportTemplateSection";
import { BlankModel, BlankModelValidator } from "Application/Models/Domain/BlankModel";
import { BlobResponse, ImageSourceEnum, UploadImageEndpoint, UploadImageRequest } from "Views/ConditionReport/Pdf/Endpoints/UploadImageEndpoint";
import { A4Width, DefaultImageGap, DefaultImageSize } from "Globals/GlobalSettings";
export class TemplateSectionViewModel extends ViewModelBase<BlankModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;
    public isLoading: boolean = false;

    public spinnerText: string = "Loading...";

    public section: ConditionReportTemplateSection;

    constructor(section: ConditionReportTemplateSection) {
        super(new BlankModel());

        this.section = section;

        this.setValidator(new BlankModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            isLoading: observable,
            spinnerText: observable,

            getImageMaxWidth: action,
            setMaxImageWidth: action,
            /*             getImageGap: action,
            setImageGap: action, */
        });
    }

    getImageMaxWidth = (): string => {
        let retVal: string = DefaultImageSize.toString();

        let imageOptions: JsonImageOptions | undefined = undefined;
        if (this.section.jsonOptions === null || this.section.jsonOptions === undefined || this.section.jsonOptions.length === 0) {
            imageOptions = new JsonImageOptions();
            this.section.jsonOptions = JSON.stringify(imageOptions);
        } else {
            imageOptions = JSON.parse(this.section.jsonOptions);
        }

        if (imageOptions !== undefined) {
            retVal = imageOptions.maxImageWidth.toString();
        }

        return retVal;
    };

    setMaxImageWidth = (value: number): void => {
        if (value < 0) {
            value = 0;
        } else if (value > A4Width) {
            value = A4Width;
        }

        let imageOptions: JsonImageOptions | undefined = undefined;
        if (this.section.jsonOptions === null || this.section.jsonOptions === undefined || this.section.jsonOptions.length === 0) {
            imageOptions = new JsonImageOptions();
        } else {
            imageOptions = JSON.parse(this.section.jsonOptions);
        }

        if (imageOptions !== undefined) {
            imageOptions.maxImageWidth = value;
            this.section.jsonOptions = JSON.stringify(imageOptions);
        }
    };

    /* getImageGap = (): string => {
        let retVal: string = DefaultImageGap.toString();

        let imageOptions: JsonImageOptions | undefined = undefined;
        if (this.section.jsonOptions === null || this.section.jsonOptions === undefined || this.section.jsonOptions.length === 0) {
            imageOptions = new JsonImageOptions();
            this.section.jsonOptions = JSON.stringify(imageOptions);
        } else {
            imageOptions = JSON.parse(this.section.jsonOptions);
        }

        if (imageOptions !== undefined) {
            if (imageOptions.imageGap === undefined) {
                imageOptions.imageGap = DefaultImageGap;
            }

            retVal = imageOptions.imageGap.toString();
        }

        return retVal;
    };

    setImageGap = (value: number): void => {
        if (value < 0) {
            value = 0;
        } else if (value > A4Width) {
            value = A4Width;
        }

        let imageOptions: JsonImageOptions | undefined = undefined;
        if (this.section.jsonOptions === null || this.section.jsonOptions === undefined || this.section.jsonOptions.length === 0) {
            imageOptions = new JsonImageOptions();
        } else {
            imageOptions = JSON.parse(this.section.jsonOptions);
        }

        if (imageOptions !== undefined) {
            imageOptions.imageGap = value;
            this.section.jsonOptions = JSON.stringify(imageOptions);
        }
    }; */

    public clear = () => {};

    public uploadImage = async (base64: string, file: any): Promise<BlobResponse> => {
        let retVal: BlobResponse = {
            url: "",
            name: "",
            originalFileName: "",
        };

        if (this.isProcessing === false) {
            this.isLoading = false;
            let endpoint: UploadImageEndpoint = new UploadImageEndpoint();
            this.isProcessing = true;
            this.spinnerText = "Updating Image...";

            const request: UploadImageRequest = {
                sourceId: this.section.templateId,
                sourceTypeId: ImageSourceEnum.Template,
                fileName: file.name,
                fileBase64: base64,
                fileType: file.type,
            };
            let _ = await this.apiClient.sendAsync(endpoint, request);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let data: BlobResponse = this.apiClient.Response();
                    this.isProcessing = false;
                    retVal = data;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report Template");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to upload the image.  Please try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }

        return retVal;
    };
}
