import styled from "@emotion/styled";
import magnifyingGlass from "Assets/Icons/magnifyingGlass.svg";

export const StyledButton = styled.button`
    width: 20px;
    height: 20px;
    padding: 0px;
    background: transparent;
    transition: opacity 0.3s;
    border: none;
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
        background: transparent;
        opacity: 0.7;
    }

    img {
        width: 20px;
        height: 20px;
    }
`;

export const ViewButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <StyledButton onClick={onClick} type="button">
            <img src={magnifyingGlass} alt="preview" />
        </StyledButton>
    );
};
