import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand } from "Application";
import Icon from "Assets/close_icon.svg";

export interface IClear {
    clearFiltering: ICommand<any>;
}

export const ClearFilteringButton: React.FC<IClear> = observer((props) => {
    useEffect(() => {
        return () => {
            // Clean up after yourself
            //    viewModel.clear();
        };
    }, []);

    return (
        <>
            <Box pl="24px">
                <Button command={props.clearFiltering} icon={<img src={Icon} style={{ paddingRight: "10px" }} height="8px" />} displayName=" Clear filtering" />
            </Box>
        </>
    );
});
