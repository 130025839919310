import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { ConditionReportPdfModel } from "../Edit/ConditionReportPdfModel";

export class UpdatePdfDataRequest extends ConditionReportPdfModel {
    public constructor() {
        super();
    }
}

/* 
//THs class does not compile if I put this in
export class UpdatePdfDataResponse {
    public id: string = "";
    public templateId: string = "";
    public css: string = "";
    public concurrencyToken: string = "";

    public constructor() {

    }
} */

export class UpdatePdfDataEndpoint extends Endpoint<UpdatePdfDataRequest, ConditionReportPdfModel> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportPdf.UpdatePdfData);
    }

    public async HandleRequestAsync(model: ConditionReportPdfModel): Promise<any> {
        return model as UpdatePdfDataRequest;
    }

    public async HandleResponseAsync(response: ConditionReportPdfModel): Promise<ConditionReportPdfModel> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
