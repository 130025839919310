import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { File64 } from "Utils/File";
import { UploadJsonFileRequest } from "Views/ConditionReport/AddEdit/Endpoints/UploadJsonFileEndpoint";
import { ConditionReportTemplateModel } from "../AddEdit/CondtionReportTemplateModel";

export class UploadJSONFileForTemplateEndpoint extends Endpoint<UploadJsonFileRequest, ConditionReportTemplateModel> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReportTemplate.UploadJsonFileForTemplate);
    }

    public async HandleRequestAsync(file: File64): Promise<any> {
        let request = new UploadJsonFileRequest();
        request.fileBase64 = file.base64StringFile;
        request.fileName = file.fileName;
        request.fileType = file.fileType;
        return request;
    }

    public async HandleResponseAsync(response: ConditionReportTemplateModel): Promise<ConditionReportTemplateModel> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
