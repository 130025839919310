import styled from "@emotion/styled";
import { Box, theme } from "Application";

export const AddSectionModalWrapper = styled(Box)``;

export const AddSectionModalHeader = styled(Box)`
    height: 50px;
    padding: 15px;
    background-color: ${theme.palette.common.sharmansBlue};
    display: flex;
    justify-content: space-between;
`;

export const ViewPDFModalBody = styled(Box)`
    padding: 5px;
    min-height: 460px;
    height: auto;
    max-width: 100%; //794px;  A4 at 96dpi
    min-width: 594px;
    overflow-y: auto;
    max-height: 650px !important;
    margin: 0 !important;
    background-color: darkgray;

    .react-pdf-doc {
        .react-pdf__Page {
            max-width: 100%;
        }
        .react-pdf__Page__canvas {
            margin: 0 auto;
            width: 100% !important;
            height: 100% !important;
            background-color: darkgray;
            padding-bottom: 10px;
        }
    }
`;

export const AddSectionModalBody = styled(Box)`
    padding: 15px;
    min-height: 460px;
    height: auto;
    max-width: 1024px;

    #tippy-1 {
        position: relative;
        z-index: 1000005 !important;
    }

    .tooltip {
        position: relative;
        z-index: 1000005 !important;
    }
`;

export const EditCSSModalBody = styled(AddSectionModalBody)`
    padding: 15px 15px 0 15px;

    input.cssEditor,
    textarea.cssEditor {
        width: calc(100%) !important; // to copr with the clear button we never asked for
        overflow-y: auto;
        word-break: break-all;
        font-size: 1.1em; // Bump up textarea
        max-height: 640px;
    }
`;

export const ModalFooter = styled(Box)`
    padding: 0 15px 15px 15px;
    /*     background-color: green; */
`;

export const ModalRow = styled(Box)`
    padding: 8px 0 8px 0;
`;
