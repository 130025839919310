import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { IDocumentType } from "Models/Documents/DocumentType";

export interface IUploadTsmDocumentRequest {
    tsmId: number;
    id: Guid | undefined;
    projectId: Guid;
    documentCategoryId: number;
    fileName: string;
    fileBase64: string;
    fileType: string;
}

class UploadProjectDocumentRequest implements IUploadTsmDocumentRequest {
    public tsmId: number = -1;
    public id: Guid | undefined = undefined;
    public projectId: Guid = "";
    public documentCategoryId: number = 0;
    public fileName: string = "";
    public fileBase64: string = "";
    public fileType: string = "";

    public constructor() {}
}

export class TsmDocumentUploadResponse {
    public tsmDocumentList: IDocumentUploaded[] = [];
    public documentTypes: IDocumentType[] = [];
}

export class TsmDocumentUploadEndpoint extends Endpoint<UploadProjectDocumentRequest, TsmDocumentUploadResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Tsm.UploadTsmDocument);
    }

    public async HandleRequestAsync(model: IUploadTsmDocumentRequest): Promise<any> {
        return model;
    }

    public async HandleResponseAsync(response: TsmDocumentUploadResponse): Promise<TsmDocumentUploadResponse> {
        Logger.logInformation("Response", response);

        //Return empty model
        return response;
    }
}
