import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { CustomerDocumentsViewModel } from "../NewCustomer/CustomerDocuments/CustomerDocumentsViewModel";
import { CustomerDocumentsModel } from "../NewCustomer/CustomerDocuments/CustomerDocumentsModel";
import { AppUrls } from "AppUrls";

class GetCustomerDocumentsByCustomerIdRequest {
    customerId: Guid = "";
    orderBy: string = "DESC";
    columnName: string = "dateCreated";
}

class GetCustomerDocumentsByCustomerIdResponse {}

export class GetCustomerDocumentsByCustomerIdEndpoint extends Endpoint<GetCustomerDocumentsByCustomerIdRequest, GetCustomerDocumentsByCustomerIdResponse> {
    public model: CustomerDocumentsModel;
    constructor(model: CustomerDocumentsModel) {
        super();
        this.model = model;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.GetCustomerDocumentsByCustomerId);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new GetCustomerDocumentsByCustomerIdRequest();
        request.customerId = this.model.customerId;
        request.columnName = this.model.columnName;
        request.orderBy = this.model.orderBy;
        return request;
    }

    public async HandleResponseAsync(response: GetCustomerDocumentsByCustomerIdResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
