import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { IDocumentType } from "Models/Documents/DocumentType";

interface IDeleteProjectDocumentByIdRequest {
    id: Guid;
    projectId: Guid;
}

class DeleteTsmDocumentByIdRequest implements IDeleteProjectDocumentByIdRequest {
    public id: Guid = "";
    public projectId: Guid = "";
}

class GetTsmDocumentsListAndRelatedResponse {
    public tsmDocumentList: IDocumentUploaded[] = [];
    public documentTypes: IDocumentType[] = [];
}

export class DeleteTsmDocumentByIdEndpoint extends Endpoint<DeleteTsmDocumentByIdRequest, GetTsmDocumentsListAndRelatedResponse> {
    public id: Guid;
    public projectId: Guid;

    constructor(id: Guid, projectId: Guid) {
        super();
        this.id = id;
        this.projectId = projectId;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Tsm.DeleteTsmDocumentById);
    }

    public async HandleRequestAsync(model: IDeleteProjectDocumentByIdRequest): Promise<any> {
        let request = new DeleteTsmDocumentByIdRequest();
        request.id = this.id;
        request.projectId = this.projectId;

        return request;
    }

    public async HandleResponseAsync(response: GetTsmDocumentsListAndRelatedResponse): Promise<GetTsmDocumentsListAndRelatedResponse> {
        Logger.logInformation("Response", response);
        //Return new list
        return response;
    }
}
