import React, { ReactNode, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import styled from "@emotion/styled";

interface ITabsWrapper {
    children: ReactNode;
}

export const TabsWrapper: React.FC<ITabsWrapper> = observer((props) => {
    return (
        <>
            <Wrapper>
                <PageLink>
                    <LeadingLine />
                    {props.children}
                    <TrailingLine />
                </PageLink>
            </Wrapper>
        </>
    );
});

export const Wrapper = styled.div`
    .current {
        border-top: 1px solid #d4d4d4;
        border-left: 1px solid #d4d4d4;
        border-right: 1px solid #d4d4d4;
        min-width: 162px; // changed from 130px, in order to fit the name and count on the linked project tabs
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0px;
        background-color: #fcfcfc;
        border-radius: 2px 2px 0px 0px;

        &.long {
            min-width: 175px !important;
            max-width: 182px !important;
        }
    }

    .default {
        min-width: 140px; // changed from 130px, in order to fit the name and count on the linked project tabs
        display: flex;
        flex: 1 1 0;
        max-width: 165px;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;
        background-color: #ededed;
        border: 1px solid #d4d4d4;
        border-radius: 2px 2px 0px 0px;

        &.long {
            min-width: 175px !important;
            max-width: 182px !important;
        }

        &:hover {
            padding: 10px 0px 12px 0px;
            background-color: #fcfcfc;
        }
    }
`;

const TrailingLine = styled(Box)`
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
`;

const LeadingLine = styled(Box)`
    border-bottom: 1px solid #d4d4d4;
    width: 11px;
`;

const PageLink = styled(Box)`
    padding: 20px 0px 0px 0px;
    display: flex;
    align-items: end;
    a {
        text-decoration: none !important;
    }
`;
