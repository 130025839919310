import { ModelBase } from "@shoothill/core";
import { makeObservable, action, computed, observable } from "mobx";
import { Validator } from "Application/Validation";
import { ComplaintTypeEnum } from "Models/Complaints/ComplaintTypeEnum";

export class ComplaintDocumentsModel extends ModelBase<ComplaintDocumentsModel> {
    public complaintId: Guid = "";
    public complaintType: ComplaintTypeEnum = ComplaintTypeEnum.ProjectQuote;

    // Table sorting
    public columnName: string = "createdDate";
    public orderBy: string = "DESC";

    constructor() {
        super();
        makeObservable(this, { columnName: observable, orderBy: observable, complaintId: observable, complaintType: observable });
    }

    public clear = () => {
        //    this.id = "";
    };
}

export class ComplaintDocumentsModelValidator extends Validator<ComplaintDocumentsModel> {
    constructor() {
        super();
        //this.ruleFor("id").notEmpty();
    }
}
