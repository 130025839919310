import React from "react";

export const formatDate = (value: Date, isoCode = "en-GB"): string => {
    const formatter = new Intl.DateTimeFormat(isoCode);

    return formatter.format(value); /* 15/06/2022 */
};

export const formatTime = (value: Date, isoCode = "en-GB"): string => {
    const formatter = new Intl.DateTimeFormat(isoCode, {
        hour: "2-digit",
        minute: "2-digit",
    });

    return formatter.format(value);
};

export const formatDateTime = (value: Date, isoCode = "en-GB"): string => {
    let retVal: string = formatDate(value, isoCode) + " " + formatTime(value, isoCode);
    return retVal;
};

export const formatBooleanYesNo = (value: boolean): string => {
    let retVal: string = "No";

    if (value === true) {
        return "Yes";
    }

    return retVal;
};

export const hyphenIfNoValue = (value: any): string => {
    let retVal: string = "-";

    if (value === null || value === undefined || value === "") {
        return retVal;
    } else {
        retVal = value;
    }

    return retVal;
};

export const hyphenDateIfNoValue = (value: any): string => {
    let retVal: string = "-";

    if (value === null || value === undefined || value === "") {
        return retVal;
    } else {
        retVal = formatDate(value);
    }

    return retVal;
};

export const AddressConcatNoPostCode = (addressLine1: string, addressLine2: string, townCity: string, county: string) => {
    let retVal: string = "";

    if (addressLine1 !== "" && addressLine1 !== null) {
        retVal += addressLine1 + ", ";
    }
    if (addressLine2 !== "" && addressLine2 !== null) {
        retVal += addressLine2 + ", ";
    }
    if (townCity !== "" && townCity !== null) {
        retVal += townCity + ", ";
    }
    if (county !== "" && county !== null) {
        retVal += county;
    }

    // Trim, remove any commas from the end & replace any double commas
    // in the middle of the string with one comma
    let trimmedString = retVal.trim().replace(/,+/g, ",").replace(/,\s*$/, "");

    retVal = trimmedString;

    return retVal;
};

export const RemoveCommas = (address: string) => {
    let retVal: string = "";

    retVal = address;

    // Trim, remove any commas from the end & replace any double commas
    // in the middle of the string with one comma
    let trimmedString = retVal.trim().replace(/,+/g, ",").replace(/,\s*$/, "");

    retVal = trimmedString;

    return retVal;
};

export const AddressConcatWithPostCode = (addressLine1: string, addressLine2: string, townCity: string, county: string, postCode: string) => {
    let retVal: string = "";

    if (addressLine1 !== "" && addressLine1 !== null) {
        retVal += addressLine1 + ", ";
    }
    if (addressLine2 !== "" && addressLine2 !== null) {
        retVal += addressLine2 + ", ";
    }
    if (townCity !== "" && townCity !== null) {
        retVal += townCity + ", ";
    }
    if (county !== "" && county !== null) {
        retVal += county + ", ";
    }
    if (postCode !== "" && county !== null) {
        retVal += postCode;
    }

    return retVal;
};

export const formatCurrency = (value: number): string => {
    let retVal: string | number = "-";

    if (value === null || value === undefined || value === 0) {
        retVal = "-";
    } else {
        retVal = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 2 }).format(value);
    }

    return retVal;
};

export const truncateString = (str: string): string => {
    let maxLength = 60;
    if (str.length <= maxLength) {
        return str; // Return the original string if it's already shorter than maxLength
    }

    const truncatedLength = maxLength - 5; // Length of the string without " ..."
    const firstHalfLength = Math.ceil(truncatedLength / 2);
    const secondHalfLength = truncatedLength - firstHalfLength;

    const firstHalf = str.substr(0, firstHalfLength);
    const secondHalf = str.substr(-secondHalfLength);

    return `${firstHalf}  ...  ${secondHalf}`;
};
