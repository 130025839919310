import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Box, Button, Input, Label, setPageTitle } from "Application";
import { useParams } from "react-router";
import { Route, Routes } from "react-router-dom";
import { ConfirmEmailViewModel } from "./ConfirmEmailViewModel";
import { Centered } from "Components/Centered";
import { H4 } from "Components/Primitives/TextElements/TextElements";
import SharmansLogo from "Assets/Logos/LogoWithText.svg";
import { RedAsterisk } from "Components/RedAsterisk";

interface Props {
    confirmEmail?: boolean;
    //parentViewModel: RegisterUserViewModel;
}
/**
 * Login page view.
 */
const ConfirmEmailView: React.FunctionComponent<Props> = observer((props: Props) => {
    // #region Code Behind
    const [viewModel] = useState(() => new ConfirmEmailViewModel());
    let params = useParams();
    let token = params.token ?? null;

    useEffect(() => {
        setPageTitle("Confirm Email");
        viewModel.setValue("token", token);
    }, []);

    // #endregion Code Behind
    return (
        <Centered>
            <RenderForm viewModel={viewModel} />
            <Routes>
                <Route path={"success"} element={<RenderForm viewModel={viewModel} />} />
            </Routes>
        </Centered>
    );
});
export default ConfirmEmailView;

interface IRenderFormProps {
    viewModel: ConfirmEmailViewModel;
}

const RenderForm = observer((props: IRenderFormProps) => {
    const { viewModel } = props;

    return (
        <Box grid alignContent={"center"} dc="1fr" rowGap={"20px"} maxWidth={"300px"}>
            <>
                <Box pb="30px" style={{ textAlign: "center" }}>
                    <img src={SharmansLogo} />
                </Box>

                <H4>Please create a secure memorable password to protect your account.</H4>
                <Input
                    displayName="Create Password"
                    command={viewModel.updatePasswordCommand}
                    autoFill={false}
                    type="password"
                    value={() => viewModel.getValue("password")}
                    validationMessage={() => viewModel.getError("password")}
                    cy={"password"}
                    labelColor="black"
                    displayNameSuffix={RedAsterisk}
                />
                <Input
                    displayName="Confirm Password"
                    command={viewModel.updateConfirmPasswordCommand}
                    autoFill={false}
                    type="password"
                    value={() => viewModel.getValue("confirmPassword")}
                    validationMessage={() => viewModel.getError("confirmPassword")}
                    cy={"confirmPassword"}
                    labelColor="black"
                    displayNameSuffix={RedAsterisk}
                />
                <Button displayName="Create password" command={viewModel.createPasswordCommand} paletteColor="ButtonGreen" type="submit" cy={"createPassword"} />
            </>

            {viewModel.showRedirectMessage && <Label fontStyle="p">{viewModel.redirectToLoginMessage}</Label>}
        </Box>
    );
});

interface IRenderErrorViewProps {
    viewModel: ConfirmEmailViewModel;
}
