import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router";
import { FallbackPageView } from "Components/FallbackPage";
import { ContactsListView } from "./ContactsListView";
import { ViewCustomerView } from "../Customers/ViewCustomer/ViewCustomerView";

const ClientRoutes: React.FC = observer(() => {
    return (
        <Routes>
            <Route
                path="*"
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <Navigate to="list" replace />
                    </React.Suspense>
                }
            />
            <Route
                path={"list/*"}
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <ContactsListView />
                    </React.Suspense>
                }
            />
            <Route
                path={"customerview/:customerId/*"}
                element={
                    <React.Suspense fallback={<FallbackPageView />}>
                        <ViewCustomerView />
                    </React.Suspense>
                }
            />
        </Routes>
    );
});

export default ClientRoutes;
