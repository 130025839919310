import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { CustomerDocumentItemModel } from "../NewCustomer/CustomerDocuments/CustomerDocumentItemModel";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { AppUrls } from "AppUrls";

class UploadCustomerDocumentRequest {
    customerId: Guid = "";
    documentCategoryId: number = 0;
    fileName: string = "";
    fileBase64: string = "";
    fileType: string = "";
}

export class UploadCustomerDocumentEndpoint extends Endpoint<UploadCustomerDocumentRequest, IDocumentUploaded[]> {
    private model: CustomerDocumentItemModel;
    constructor(model: CustomerDocumentItemModel) {
        super();
        this.model = model;
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.UploadDocument);
    }

    public async HandleRequestAsync(model: any): Promise<any> {
        let request = new UploadCustomerDocumentRequest();
        request.customerId = this.model.customerId;
        request.documentCategoryId = this.model.documentCategoryId;
        request.fileName = this.model.filename;
        request.fileBase64 = this.model.fileBase64;
        request.fileType = this.model.fileType;

        return request;
    }

    public async HandleResponseAsync(response: IDocumentUploaded[]): Promise<IDocumentUploaded[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
