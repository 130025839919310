import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { IDocumentType } from "Models/Documents/DocumentType";
import { ContactStatusDropdownItem } from "Models/Contacts/ContactStatusDropdownItem";

class GetCustomerViewHeaderByIdRequest {
    Id: Guid = "";
}

export class GetCustomerViewHeaderByIdResponse {
    public id: string = "";
    public name: string = "";
    public customerStatusId: number = 1;
    public createdByName: string = "";
    public createdDate: string = "";
    public noteCount: number = 0;
    public documentCount: number = 0;
    public taskCount: number = 0;
    public contactCount: number = 0;
    public multiSiteProjectCount: number = 0;
    public masterProjectCount: number = 0;
    public projectQuoteCount: number = 0;
    public contactStatusTypes: ContactStatusDropdownItem[] = [];
    public documentTypes: IDocumentType[] = [];
    public rowVersion: string = "";
}

export class GetCustomerViewHeaderByIdEndpoint extends EndpointWithoutRequest<GetCustomerViewHeaderByIdResponse> {
    constructor(Id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(`/api/customer/getcustomerviewheaderbyid/${Id}`);
    }

    public async HandleResponseAsync(response: GetCustomerViewHeaderByIdResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
