import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";

export class GetCustomerNoteWithRelatedByIdResponse {
    public id: string | null = null;
    public note: string = "";
    public documents: IDocumentUploaded[] = [];
}

export class GetCustomerNoteWithRelatedByIdEndpoint extends EndpointWithoutRequest<GetCustomerNoteWithRelatedByIdResponse> {
    constructor(Id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(`/api/customer/getcustomernotewithrelatedbyid/${Id}`);
    }

    public async HandleResponseAsync(response: GetCustomerNoteWithRelatedByIdResponse): Promise<GetCustomerNoteWithRelatedByIdResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
