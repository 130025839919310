import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { DownloadComplaintPDFResponse } from "./DownloadProjectQuoteComplaintPDF";
import { getFilenameFromContentDisposition } from "Utils/File";
import { ComplaintsReportModel } from "../Report/ComplaintsReportModel";
import { ComplaintStatusEnum } from "Models/Complaints/ComplaintStatusEnum";

export class ReportsRequest {
    public startDate: string | null = null;
    public endDate: string | null = null;
    public complaintStatus: ComplaintStatusEnum = ComplaintStatusEnum.Both;
}

export class DownloadComplaintsBetweenDatesPDFEndpoint extends Endpoint<ReportsRequest, DownloadComplaintPDFResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.AfterSales.Complaints.DownloadComplaintsBetweenDatesPDF);
        this.responseIsBlob(true);
        this.SetContentType("application/pdf");
    }

    public async HandleRequestAsync(model: ComplaintsReportModel): Promise<any> {
        let request = new ReportsRequest();
        request.startDate = model.reportStartDate.toISODate();
        request.endDate = model.reportEndDate.toISODate();
        request.complaintStatus = parseInt(model.reportComplaintStatus);
        return request;
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        let retVal: boolean = true;
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        //Return your model
        return retVal;
    }
}
