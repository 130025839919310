import { makeObservable, observable } from "mobx";
import { EditableCopyRoute, EditableCopy, APIClient } from "Application";
import { singleton } from "tsyringe";
import { Logger } from "index";
import { GetEditableCopyEndpoint } from "./GetEditableCopyEndpoint";

@singleton()
export class EditableCopyStore {
    public editableCopies = observable<EditableCopy>([]);
    public editableCopyRoutes = observable<EditableCopyRoute>([]);
    private apiClient = new APIClient();
    private endpoint = new GetEditableCopyEndpoint(this);

    constructor() {
        Logger.logDebug("EditableCopyStore store created");
        makeObservable(this, {
            editableCopies: observable,
            editableCopyRoutes: observable,
        });
        let _ = this.init();
    }

    public init = async () => {
        await this.apiClient.sendAsync(this.endpoint);
    };
}
