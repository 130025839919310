import styled from "@emotion/styled";
import { Box } from "Application";

export const OuterBox = styled(Box)`
    border-radius: 0.125rem;
    border: 1px solid #d4d4d4;
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;
export const RightGreyBox = styled(Box)`
    background-color: #d4d4d4;
    align-items: center;
    align-content: center;
    display: flex;
    padding: 0px 9px;
    column-gap: 15px;
`;

export const LeftBox = styled(Box)`
    p {
        color: #333333;
    }
`;

export const InnerBox = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 15px;
    padding: 10px;
    width: 100%;
    .editselect {
        &__control {
            border: 1px solid #d4d4d4;
            min-height: unset;
            border-radius: 2px !important;
        }
        &__input-container {
            padding: 0;
            margin: 0;
        }
        &__value-container {
            padding: 1px 8px;
        }
        &__indicator-separator {
            display: none;
        }
        &__menu-list a {
            background-color: #d6e4f1;
            display: block;
            width: 100%;
            padding: 5px;
            text-align: center;
            font-weight: 800;
            transition: 0.3s;
            gap: 5px;
            display: flex;
            justify-content: center;
            &:hover {
                background-color: #d6e4f17a;
            }
        }
    }
`;
