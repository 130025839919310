import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { BaseWrapperNoMarginTop } from "Components/BaseWrapper";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import { Box } from "Components/General";
import { ThemedLoader } from "Components";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { TemplateListViewViewModel } from "./TemplateListViewViewModel";
import { useNavigate } from "react-router";
import UserIcon from "Assets/Icons/UserIcon.svg";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDateTime } from "Utils/Formats";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { FilteringTopSectionGenericView } from "Components/FilteringTopSectionGeneric";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { CenteredCell } from "Utils/CellFormatComponents";

export const TemplateListView: React.FC = observer(() => {
    const [viewModel] = useState(() => new TemplateListViewViewModel());
    const [isDeleteSectionOpen, setIsDeleteSectionOpen] = useState<boolean>(false);
    const [deleteSectionId, setDeleteSection] = useState<string>("");

    let navigate = useNavigate();

    useEffect(() => {
        viewModel.loadTemplatesAsync();

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.loadTemplatesAsync();
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;

        viewModel.loadTemplatesAsync();
    };

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.loadTemplatesAsync();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.loadTemplatesAsync();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.loadTemplatesAsync();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.loadTemplatesAsync();

                break;
            default:
            // code block
        }

        // }
    };

    const deleteTemplateCheck = (e: any, id: string): void => {
        setDeleteSection(id);
        setIsDeleteSectionOpen(true);
    };

    const cancelSectionDelete = () => {
        setIsDeleteSectionOpen(false);
    };

    const confirmSectionDelete = () => {
        let promise = viewModel.deleteTemplate(deleteSectionId);

        promise.then(() => {
            setIsDeleteSectionOpen(false);
            setDeleteSection("");
        });
    };

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("templateNumber", {
            header: () => "Template Number",
            cell: (info) => info.getValue(),
            // footer: (info) => info.column.id,
        }),
        columnHelper.accessor("originalFileName", {
            header: () => "Filename",
            cell: (info) => info.getValue(),
            // footer: (info) => info.column.id,
        }),
        columnHelper.accessor("createdDate", {
            header: () => "Created",
            cell: (info) => formatDateTime(new Date(info.renderValue())),
            // footer: (info) => info.column.id,
        }),

        columnHelper.accessor("createdBy", {
            header: () => "Created By",
            cell: (info) => info.getValue(),
            // footer: (info) => info.column.id,
        }),

        /*         columnHelper.accessor("jsonVersionMin", {
            header: () => "Min Json Version",
            cell: (info) => info.getValue(),
            // footer: (info) => info.column.id,
        }), */
        columnHelper.accessor("version", {
            header: () => "File Version",
            cell: (info) => info.getValue(),
            // footer: (info) => info.column.id,
        }),

        columnHelper.accessor("jsonVersionMax", {
            header: () => "Json Version",
            cell: (info) => info.getValue(),
            // footer: (info) => info.column.id,
        }),

        columnHelper.accessor("lastUpdatedDate", {
            header: () => "Last Updated",
            cell: (info) => formatDateTime(new Date(info.renderValue())),
            // footer: (info) => info.column.id,
        }),

        columnHelper.accessor("lastUpdatedBy", {
            header: () => "Updated By",
            cell: (info) => info.getValue(),
            // footer: (info) => info.column.id,
        }),

        columnHelper.accessor("id", {
            header: () => <div style={{ maxWidth: "50px" }}></div>,
            cell: (info) => {
                return (
                    <CenteredCell>
                        <DeleteButton id={info.getValue().toString()} onClick={deleteTemplateCheck} />
                    </CenteredCell>
                );
            },
            // footer: (info) => info.column.id,
        }),
    ];

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.ConditionReport.AddEditTemplate[AppUrlIndex.Long].replace(":templateId", rowId.toString());
        navigate(path);
    }

    const FilteringTopSection = (
        <FilteringTopSectionGenericView
            searchValue={viewModel.searchString}
            searchExecute={() => viewModel.executeSearch()}
            searchCommand={viewModel.updateFilteringByKeywordSearch}
            timescaleValue={""}
        />
    );

    const FilteringBottomSection = <Box grid dc={"1fr 1fr 1fr 1fr 1fr"}></Box>;

    function getDeleteMessage(): string {
        let retVal = "Are you sure you want to delete this template?  You will no longer be able to create documents with the template, existing documents will be unaffected";
        return retVal;
    }

    return (
        <BaseWrapperNoMarginTop>
            {viewModel.isProcessing ? (
                <ThemedLoader isOpen={true} />
            ) : (
                <>
                    <AreYouSureModal
                        title="Delete Template"
                        message={getDeleteMessage()}
                        isOpen={isDeleteSectionOpen}
                        onCancel={cancelSectionDelete}
                        onConfirm={confirmSectionDelete}
                    />

                    <Box id="tablecontainer">
                        <Box flexGrow={1} textAlign="right">
                            <ButtonUnder
                                displayName={"  New Template"}
                                paletteColor="ButtonBlue" // TODO CMR {viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                                boxShadow={true}
                                command={viewModel.newTemplateCommand}
                                icon={<img src={UserIcon} />}
                            />
                        </Box>

                        {viewModel.isProcessing ? (
                            <ThemedLoader isOpen={true} />
                        ) : (
                            <SharmansTable
                                columns={columns}
                                onSortChange={updateSorting}
                                data={viewModel.getTemplates}
                                showFooter={false}
                                showFiltering={false}
                                onRowClick={onRowClick}
                                initialSortColumn={viewModel.columnName}
                                initialSortDirection={viewModel.orderBy}
                                showPagination={true}
                                totalRowCount={viewModel.tableCount}
                                onChangePage={onChangePage}
                                onChangeRowPerPage={onChangeRowPerPage}
                                totalPageCount={viewModel.pageCount}
                                pageNumber={viewModel.pageNumber}
                                pageSize={viewModel.pageSize}
                                filteringElementsBottomSection={FilteringBottomSection}
                                filteringElementsTopSection={FilteringTopSection}
                            />
                        )}
                    </Box>
                </>
            )}
        </BaseWrapperNoMarginTop>
    );
});
