import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { CoreStoreInstance, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { PreventativeActionModel, PreventativeActionModelValidator } from "./PreventativeActionModel";
import { GetPreventativeActionForViewEndpoint, GetPreventativeActionForViewResponse } from "../Endpoints/GetPreventativeActionForView";
import { ModalType } from "Application/Models/Domain/AddEdditModalType";
import { UpsertPreventativeActionEndpoint, UpsertPreventativeActionResponse } from "../Endpoints/UpsertPreventativeAction";
import { DeletePreventativeActionByIdEndpoint, DeletePreventativeActionResponse } from "../Endpoints/DeletePreventativeActionById";

export class PreventativeActionViewModel extends ViewModelBase<PreventativeActionModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing = false;
    public isModalOpen: boolean = false;
    public addOrEditModal: ModalType = ModalType.Add;
    public actionToDeleteId: number = 0;
    public isAreYouSureModalOpen: boolean = false;

    public countCallBack: ((newCount: number) => void) | undefined = undefined;

    constructor() {
        super(new PreventativeActionModel());
        this.setValidator(new PreventativeActionModelValidator());
        makeObservable(this, {
            actionToDeleteId: observable,
            addOrEditModal: observable,
            isAreYouSureModalOpen: observable,
            isProcessing: observable,
            isModalOpen: observable,

            cancelDelete: action,
            closeModal: action,
            clear: action,
            // deleteAction: action,
            deleteActionOpenAreYouSureModal: action,
            init: action,
            openEditActionModal: action,
            proceedWithDelete: action,
            setComplaintId: action,

            getList: computed,
        });
    }

    public init = (countCallBack: (newCount: number) => void) => {
        this.countCallBack = countCallBack;
    };

    public clear = () => {
        this.model.clear();
    };

    public tempCommand: ICommand = new RelayCommand(() => {
        alert("Coming soon");
    });

    public loadActionList = async (id: Guid): Promise<GetPreventativeActionForViewResponse> => {
        const endpoint = new GetPreventativeActionForViewEndpoint(id);
        let retVal: GetPreventativeActionForViewResponse = new GetPreventativeActionForViewResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            // Taken out as was stopping the table ordering from working
            // this.isProcessing = true;

            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: GetPreventativeActionForViewResponse = this.apiClient.Response();
                    this.model.actions.replace(response.actions);

                    if (this.countCallBack !== undefined) {
                        this.countCallBack(response.actions.length);
                    }
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint actions");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the complaint actions.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        } else {
            //alert(this.isProcessing + " " + this.apiClient.IsBusy);
        }
        return retVal;
    };

    public get getList() {
        return this.model.actions.slice();
    }

    // Add Edit action

    public setComplaintId = (id: string) => {
        this.setValue("projectComplaintId", id);
    };

    public openAddModal: ICommand = new RelayCommand(() => {
        this.model.clear();
        this.addOrEditModal = ModalType.Add;
        this.isModalOpen = true;
    });

    public closeModal = () => {
        this.isModalOpen = false;
        this.setError("preventativeAction", "");
        CoreStoreInstance.HideInfoBar();
    };

    public actionInputCommand: ICommand = new RelayCommand((action: string) => {
        this.model.preventativeAction = action;
    });

    public upsertAction = async (): Promise<UpsertPreventativeActionResponse> => {
        const endpoint = new UpsertPreventativeActionEndpoint();
        let retVal: UpsertPreventativeActionResponse = new UpsertPreventativeActionResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false && this.isModelValid()) {
            // Taken out as was stopping the table ordering from working
            // this.isProcessing = true;

            await this.apiClient.sendAsync(endpoint, this.model);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: UpsertPreventativeActionResponse = this.apiClient.Response();
                    this.model.actions.replace(response.actions);

                    if (this.countCallBack !== undefined) {
                        this.countCallBack(response.actions.length);
                    }
                    this.isModalOpen = false;
                    this.model.clear();
                    CoreStoreInstance.HideInfoBar();
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint actions");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to save the complaint action.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    // Editing

    public openEditActionModal = (e: any, id: string) => {
        this.addOrEditModal = ModalType.Edit;

        let actionToEdit = this.model.actions.find((item) => {
            return Number(item.id) === Number(id);
        });

        if (actionToEdit) {
            this.setValue("actionId", actionToEdit.id);
            this.setValue("preventativeAction", actionToEdit.action);
            this.setValue("rowVersion", actionToEdit.rowVersion);
        }

        this.isModalOpen = true;
    };

    // Deleting

    public deleteActionOpenAreYouSureModal = (e: any, id: string) => {
        this.actionToDeleteId = Number(id);
        this.isAreYouSureModalOpen = true;
    };

    public proceedWithDelete = () => {
        this.deleteAction();
    };

    public cancelDelete = () => {
        this.isAreYouSureModalOpen = false;
    };

    public deleteAction = async (): Promise<DeletePreventativeActionResponse> => {
        const endpoint = new DeletePreventativeActionByIdEndpoint(this.actionToDeleteId, this.getValue("projectComplaintId"));
        let retVal: DeletePreventativeActionResponse = new DeletePreventativeActionResponse();

        if (this.isProcessing === false && this.apiClient.IsBusy === false) {
            // Taken out as was stopping the table ordering from working
            // this.isProcessing = true;

            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: DeletePreventativeActionResponse = this.apiClient.Response();
                    this.model.actions.replace(response.actions);

                    if (this.countCallBack !== undefined) {
                        this.countCallBack(response.actions.length);
                    }
                    this.isAreYouSureModalOpen = false;
                    this.actionToDeleteId = 0;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Complaint actions");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to delete the complaint action.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        } else {
            //alert(this.isProcessing + " " + this.apiClient.IsBusy);
        }
        return retVal;
    };
}
