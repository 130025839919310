import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { DeleteMasterProjectByIdRequest } from "./DeleteMasterProjectByIdEndpoint";
import { PropertyMultiSiteProjectListItem } from "../ViewProperty/Models/PropertyMultiSiteProjectListItem";

export class DeleteMultiSiteProjectByIdEndpoint extends Endpoint<DeleteMasterProjectByIdRequest, PropertyMultiSiteProjectListItem[]> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Property.DeleteMultiSiteProjectById);
    }

    public async HandleRequestAsync(request: DeleteMasterProjectByIdRequest): Promise<any> {
        return request;
    }

    public async HandleResponseAsync(response: PropertyMultiSiteProjectListItem[]): Promise<PropertyMultiSiteProjectListItem[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
