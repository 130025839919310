import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { DefaultPageSize } from "Globals/GlobalSettings";
import { ProjectStatusEnum } from "Models/Project/ProjectStatusEnum";
import { LinkedMasterViewModel } from "../LinkedMasterViewModel";
import { CustomerMasterProjectistItem } from "../LinkedMasterModel";
import { AppUrls } from "AppUrls";

export class GetLinkedMasterProjectsRequest {
    public customerId: Guid = "";
    public orderBy: string = "ASC";
    public columnName: string = "";
    public pageSize: number = DefaultPageSize;
    public pageNumber: number = 1;
    public filterByStatus: ProjectStatusEnum[] = [];
    public initialLoad: boolean = true;
}

export class GetLinkedMasterProjectsResponse {
    public customerMasterProjectistItem: CustomerMasterProjectistItem[] = [];
    public customerCount: number = 0;
}

export class GetLinkedMasterProjectsEndpoint extends Endpoint<GetLinkedMasterProjectsRequest, GetLinkedMasterProjectsResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.MasterProject.GetList);
    }

    public async HandleRequestAsync(viewModel: LinkedMasterViewModel): Promise<any> {
        let request = new GetLinkedMasterProjectsRequest();

        request.customerId = viewModel.model.customerId;
        request.orderBy = viewModel.orderBy;
        request.columnName = viewModel.columnName;
        request.pageNumber = viewModel.pageNumber;
        request.pageSize = viewModel.pageSize;
        request.filterByStatus = [];

        request.filterByStatus = viewModel.filterByStatus.map((item: string) => {
            return parseInt(item) as ProjectStatusEnum;
        });

        request.initialLoad = viewModel.initialLoad;

        return request;
    }

    public async HandleResponseAsync(response: GetLinkedMasterProjectsResponse): Promise<any> {
        return response;
    }
}
