import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "Components";
import { theme } from "Styles";
import { HeaderViewModel } from "./HeaderViewModel";
import { observer } from "mobx-react-lite";
import { Sidebar } from "./SideBar";
import { AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";
import { MenuItem } from "./MenuItem";

//Images

import BurgerIcon from "Assets/Icons/BurgerMenu.svg";
import Logo from "Assets/Logos/HeaderLogo.svg";
import { SideBarMenu } from "./SideBarMenu";
import { DomainStore } from "Stores/Domain/DomainStore";
import { H3 } from "Components/Primitives/TextElements/TextElements";

const HeaderBar = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: ${theme.palette.common.white};
    box-shadow: 0 0 16px -8px rgba(0, 0, 0, 0.25);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //padding: 0 24px;
    padding-right: 12px;
`;

const MenuBox = styled(Box)`
    height: 100%;
    .menuitem {
        border-left: 1px solid #e1e1e1;
    }

    .menuitem:last-of-type {
        border-right: 1px solid #e1e1e1;
    }
`;

export type MenuItemType = {
    text: string;
    path: string;
    highlightColour: string;
};

export interface IHeader {
    menuItems?: MenuItemType[];
    title?: string;
}

export const Header: React.FC<IHeader> = observer((props) => {
    const accountStore = container.resolve(AccountStore);
    const [headerViewModel] = useState(() => new HeaderViewModel());

    const isAdmin = () => accountStore.isInRole("ADMIN");
    const domainStore = container.resolve(DomainStore);

    useEffect(() => {}, []);

    return (
        <Box style={{ position: "sticky", top: 0, left: 0, zIndex: 100 }}>
            <HeaderBar>
                <HeaderLogoAndMainHeadingBox>
                    <MenuLogoBox onClick={domainStore.toggleSideBar}>
                        <img src={Logo}></img>
                        <img src={BurgerIcon} />
                    </MenuLogoBox>
                    <Box>
                        <H3 pl="12px" style={{ fontWeight: 700 }}>
                            {props.title}
                        </H3>
                    </Box>
                </HeaderLogoAndMainHeadingBox>
                <MenuBox showIf={!headerViewModel.isMobile} flexBox>
                    {props.menuItems?.map((Item, index) => {
                        return (
                            <MenuItem
                                key={"menuItem-" + index.toString()}
                                text={Item.text}
                                viewModel={headerViewModel}
                                highlightColour={Item.highlightColour}
                                path={Item.path}
                                canExecute={() => accountStore.IsLoggedIn}
                            />
                        );
                    })}

                    {/* For JW's reference - - - - - -  */}
                    {/* {accountStore.IsLoggedIn && isAdmin() && <MenuItem text={"Admin"} viewModel={headerViewModel} path={AppUrls.Client.Admin.AdminHome} />}
                    <MenuItem text={"Login"} viewModel={headerViewModel} path={AppUrls.Client.Account.Login} canExecute={() => !accountStore.IsLoggedIn} />
                    <MenuItem text={"Logout"} viewModel={headerViewModel} path={AppUrls.Client.Account.Logout} canExecute={() => accountStore.IsLoggedIn} /> */}
                </MenuBox>
                <Box showIf={headerViewModel.isMobile}>
                    <Sidebar width={300} color={"red"} left={true}>
                        <Box>Sidebar</Box>
                    </Sidebar>
                </Box>
                {/* <SideBarMenu /> */}
            </HeaderBar>
        </Box>
    );
});

const MenuLogoBox = styled(Box)`
    background-color: #d4d4d4;
    width: 91px;
    height: 51px;
    border-radius: 0px 10px 10px 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    padding: 9.2px;
    align-items: center;
    justify-items: center;
    cursor: pointer;
`;

const HeaderLogoAndMainHeadingBox = styled(Box)`
    grid-template-columns: auto auto;
    display: grid;
    align-content: center;
    align-items: center;
    justify-items: center;
`;
