import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, Input, Label } from "Application";
import { ResetPasswordViewModel } from "./ResetPasswordViewModel";
import { useParams } from "react-router";
import { Centered } from "Components/Centered";

export const ResetPasswordView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ResetPasswordViewModel());
    let params = useParams();
    let token = params.token || null;

    useEffect(() => {
        viewModel.setValue("token", token);
    }, []);

    return (
        <Centered>
            <Box showIf={!viewModel.showSuccessMessage} grid alignContent={"center"} dc="1fr" rowGap="20px" columnGap="20px" minWidth={"300px"}>
                <Label fontStyle={"h3"}>Reset your password?</Label>
                <Label fontStyle={"error"} color={"error"}>
                    {viewModel.apiClient.ValidationMessage}
                </Label>
                <Input
                    displayName={"New Password"}
                    command={viewModel.updateNewPasswordCommand}
                    value={() => viewModel.getValue("newPassword")}
                    validationMessage={() => viewModel.getError("newPassword")}
                    type={"password"}
                />
                <Input
                    displayName={"Confirm Password"}
                    command={viewModel.updateConfirmPasswordCommand}
                    value={() => viewModel.getValue("confirmPassword")}
                    validationMessage={() => viewModel.getError("confirmPassword")}
                    type={"password"}
                />
                <Button displayName="Reset Password" command={viewModel.resetPasswordCommand} paletteColor="primary" type="submit" />
                {/*<Else>
                    <Label fontStyle={"h4"}>You have successfully reset your password</Label>
                    <Label fontStyle={"h4"}>You can now go back to the login page</Label>
                    <Box onClick={() => loginHomeViewModel.navigateToSignInCommand.execute()}>
                        <Label fontStyle={"h7"}>Back to sign in</Label>
                    </Box>
                </Else>*/}
            </Box>
            <Box showIf={viewModel.showSuccessMessage}>
                <Label fontStyle={"h3"}>You have successfully reset your password</Label>
                <Label fontStyle={"h3"}>You can now go back to the login page</Label>
                <Box mt={1} onClick={() => viewModel.navigateToSignInCommand.execute()}>
                    <Label pointer fontStyle={"h6"}>
                        Back to sign in
                    </Label>
                </Box>
            </Box>
        </Centered>
    );
});
