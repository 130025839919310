import { theme } from "Application";
import styled from "@emotion/styled";
import { BaseWrapper } from "Components/BaseWrapper";

export const ProjectsListWrapper = styled(BaseWrapper)`
    .select-label {
        line-height: 20px;
    }

    .projectstatus-unknown {
        background-color: ${theme.palette.projectStatus.unknown.main};
        color: ${theme.palette.projectStatus.unknown.text};
        margin-right: 25px;
    }

    .projectstatus-conditionreportconversion {
        background-color: ${theme.palette.projectStatus.crconversion.main};
        color: ${theme.palette.projectStatus.crconversion.text};
        margin-right: 25px;
    }

    .projectstatus-conditionreportcheck {
        background-color: ${theme.palette.projectStatus.crcheck.main};
        color: ${theme.palette.projectStatus.crcheck.text};
        margin-right: 25px;
    }

    .projectstatus-prospect {
        background-color: ${theme.palette.projectStatus.prospect.main};
        color: ${theme.palette.projectStatus.prospect.text};
        margin-right: 25px;
    }

    .projectstatus-prospectproceeded {
        background-color: ${theme.palette.projectStatus.prospectprogressed.main};
        color: ${theme.palette.projectStatus.prospectprogressed.text};
        margin-right: 25px;
    }

    .projectstatus-prospectprogressed {
        background-color: ${theme.palette.projectStatus.prospectprogressed.main};
        color: ${theme.palette.projectStatus.prospectprogressed.text};
        margin-right: 25px;
    }

    .projectstatus-didnotprogress {
        background-color: ${theme.palette.projectStatus.didnotprogress.main};
        color: ${theme.palette.projectStatus.didnotprogress.text};
        margin-right: 25px;
    }

    .projectstatus-quote {
        background-color: ${theme.palette.projectStatus.quote.main};
        color: ${theme.palette.projectStatus.quote.text};
        margin-right: 25px;
    }

    .projectstatus-valueseparate {
        background-color: ${theme.palette.projectStatus.valueseparate.main};
        color: ${theme.palette.projectStatus.valueseparate.text};
        margin-right: 25px;
    }

    .projectstatus-lost {
        background-color: ${theme.palette.projectStatus.lost.main};
        color: ${theme.palette.projectStatus.lost.text};
        margin-right: 25px;
    }

    .projectstatus-order {
        background-color: ${theme.palette.projectStatus.orderplaced.main};
        color: ${theme.palette.projectStatus.orderplaced.text};
        margin-right: 25px;
    }

    .projectstatus-despatched {
        background-color: ${theme.palette.projectStatus.complete.main};
        color: ${theme.palette.projectStatus.complete.text};
        margin-right: 25px;
    }

    .projectstatus-master {
        background-color: ${theme.palette.projectStatus.master.main};
        color: ${theme.palette.projectStatus.master.text};
        margin-right: 25px;
    }

    .projectstatus-mastercomplete {
        background-color: ${theme.palette.projectStatus.mastercomplete.main};
        color: ${theme.palette.projectStatus.mastercomplete.text};
        margin-right: 25px;
    }

    .projectstatus-aftersalesopen {
        background-color: ${theme.palette.projectStatus.aftersalesopen.main};
        color: ${theme.palette.projectStatus.aftersalesopen.text};
        margin-right: 25px;
    }

    .projectstatus-aftersalesclosed {
        background-color: ${theme.palette.projectStatus.aftersalesclosed.main};
        color: ${theme.palette.projectStatus.aftersalesclosed.text};
        margin-right: 25px;
    }
`;

export const ProjectStatusBox = styled.div`
    /* width: 98px; */
    width: 100%;
    height: 25px;
    border-radius: 10px;
    justify-content: middle;
    text-align: center;
    white-space: nowrap; //Added so that Aftersales status is not wrapped

    > div {
        width: 100%;
        line-height: 25px;
    }

    &.dropdown {
        margin-left: -10px;
    }

    &.projectstatus-unknown {
        background-color: ${theme.palette.projectStatus.unknown.main};
        color: ${theme.palette.projectStatus.unknown.text};
    }

    &.projectstatus-conditionreportconversion {
        background-color: ${theme.palette.projectStatus.crconversion.main};
        color: ${theme.palette.projectStatus.crconversion.text};
    }

    &.projectstatus-conditionreportcheck {
        background-color: ${theme.palette.projectStatus.crcheck.main};
        color: ${theme.palette.projectStatus.crcheck.text};
    }

    &.projectstatus-prospect {
        background-color: ${theme.palette.projectStatus.prospect.main};
        color: ${theme.palette.projectStatus.prospect.text};
    }

    &.projectstatus-prospectprogressed,
    &.projectstatus-prospectproceeded {
        background-color: ${theme.palette.projectStatus.prospectprogressed.main};
        color: ${theme.palette.projectStatus.prospectprogressed.text};
    }

    &.projectstatus-didnotprogress {
        background-color: ${theme.palette.projectStatus.didnotprogress.main};
        color: ${theme.palette.projectStatus.didnotprogress.text};
    }

    &.projectstatus-quote {
        background-color: ${theme.palette.projectStatus.quote.main};
        color: ${theme.palette.projectStatus.quote.text};
    }

    &.projectstatus-valueseparate {
        background-color: ${theme.palette.projectStatus.valueseparate.main};
        color: ${theme.palette.projectStatus.valueseparate.text};
    }

    &.projectstatus-lost {
        background-color: ${theme.palette.projectStatus.lost.main};
        color: ${theme.palette.projectStatus.lost.text};
    }

    &.projectstatus-order {
        background-color: ${theme.palette.projectStatus.orderplaced.main};
        color: ${theme.palette.projectStatus.orderplaced.text};
    }

    &.projectstatus-despatched {
        background-color: ${theme.palette.projectStatus.complete.main};
        color: ${theme.palette.projectStatus.complete.text};
    }

    &.projectstatus-master {
        background-color: ${theme.palette.projectStatus.master.main};
        color: ${theme.palette.projectStatus.master.text};
    }

    &.projectstatus-mastercomplete {
        background-color: ${theme.palette.projectStatus.mastercomplete.main};
        color: ${theme.palette.projectStatus.mastercomplete.text};
    }

    &.projectstatus-aftersalesopen {
        background-color: ${theme.palette.projectStatus.aftersalesopen.main};
        color: ${theme.palette.projectStatus.aftersalesopen.text};
    }

    &.projectstatus-aftersalesclosed {
        background-color: ${theme.palette.projectStatus.aftersalesclosed.main};
        color: ${theme.palette.projectStatus.aftersalesclosed.text};
    }

    &.status-Open {
        background-color: ${theme.palette.status.open.main};
        color: ${theme.palette.projectStatus.aftersalesclosed.text};
    }

    &.status-Complete {
        background-color: ${theme.palette.status.complete.main};
        color: ${theme.palette.projectStatus.aftersalesclosed.text};
    }
`;

export const ProjectStatusWrapper = styled.div`
    .projectstatus-unknown,
    .projectstatus-conditionreportconversion,
    .projectstatus-conditionreportcheck,
    .projectstatus-prospect,
    .projectstatus-prospectprogressed,
    .projectstatus-prospectproceeded,
    .projectstatus-didnotprogress,
    .projectstatus-quote,
    .projectstatus-valueseparate,
    .projectstatus-lost,
    .projectstatus-order,
    .projectstatus-despatched,
    .projectstatus-master,
    .projectstatus-mastercomplete,
    .projectstatus-aftersalesopen,
    .projectstatus-aftersalesclosed,
    .status-Open,
    .status-Complete,
    .projectstatus-orderplaced {
        width: 100%;
        line-height: 20px;
        padding-left: 5px;
        margin-right: 25px;
    }

    .projectstatus-unknown {
        background-color: ${theme.palette.projectStatus.unknown.main};
        color: ${theme.palette.projectStatus.unknown.text};
    }

    .projectstatus-conditionreportconversion {
        width: 100%;
        line-height: 20px;
        padding-left: 5px;
        background-color: ${theme.palette.projectStatus.crconversion.main};
        color: ${theme.palette.projectStatus.crconversion.text};
        margin-right: 25px;
    }

    .projectstatus-conditionreportcheck {
        width: 100%;
        line-height: 20px;
        padding-left: 5px;
        background-color: ${theme.palette.projectStatus.crcheck.main};
        color: ${theme.palette.projectStatus.crcheck.text};
        margin-right: 25px;
    }

    .projectstatus-prospect {
        width: 100%;
        line-height: 20px;
        padding-left: 5px;
        background-color: ${theme.palette.projectStatus.prospect.main};
        color: ${theme.palette.projectStatus.prospect.text};
        margin-right: 25px;
    }

    .projectstatus-prospectprogressed,
    .projectstatus-prospectproceeded {
        background-color: ${theme.palette.projectStatus.prospectprogressed.main};
        color: ${theme.palette.projectStatus.prospectprogressed.text};
        margin-right: 25px;
    }

    .projectstatus-didnotprogress {
        background-color: ${theme.palette.projectStatus.didnotprogress.main};
        color: ${theme.palette.projectStatus.didnotprogress.text};
        margin-right: 25px;
    }

    .projectstatus-quote {
        background-color: ${theme.palette.projectStatus.quote.main};
        color: ${theme.palette.projectStatus.quote.text};
        margin-right: 25px;
    }

    .projectstatus-valueseparate {
        background-color: ${theme.palette.projectStatus.valueseparate.main};
        color: ${theme.palette.projectStatus.valueseparate.text};
        margin-right: 25px;
    }

    .projectstatus-lost {
        background-color: ${theme.palette.projectStatus.lost.main};
        color: ${theme.palette.projectStatus.lost.text};
        margin-right: 25px;
    }

    .projectstatus-order {
        background-color: ${theme.palette.projectStatus.orderplaced.main};
        color: ${theme.palette.projectStatus.orderplaced.text};
        margin-right: 25px;
    }

    .projectstatus-despatched {
        background-color: ${theme.palette.projectStatus.complete.main};
        color: ${theme.palette.projectStatus.complete.text};
        margin-right: 25px;
    }

    .projectstatus-master {
        background-color: ${theme.palette.projectStatus.master.main};
        color: ${theme.palette.projectStatus.master.text};
        margin-right: 25px;
    }

    .projectstatus-mastercomplete {
        background-color: ${theme.palette.projectStatus.mastercomplete.main};
        color: ${theme.palette.projectStatus.mastercomplete.text};
        margin-right: 25px;
    }

    .projectstatus-aftersalesopen {
        background-color: ${theme.palette.projectStatus.aftersalesopen.main};
        color: ${theme.palette.projectStatus.aftersalesopen.text};
        margin-right: 25px;
    }

    .projectstatus-aftersalesclosed {
        background-color: ${theme.palette.projectStatus.aftersalesclosed.main};
        color: ${theme.palette.projectStatus.aftersalesclosed.text};
        margin-right: 25px;
    }

    .status-Open {
        background-color: ${theme.palette.status.open.main};
        color: ${theme.palette.projectStatus.aftersalesclosed.text};
    }

    .status-Complete {
        background-color: ${theme.palette.status.complete.main};
        color: ${theme.palette.projectStatus.aftersalesclosed.text};
    }

    .checkbox {
        max-width: 25px;
        margin-right: -25px !important;
        background-color: transparent;
    }
`;
