import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class GetCountriesForDropdownEndpointResponse {
    public id: number = -1;
    public name: string = "";
    public key: string = "";
    public text: string = "";

    public constructor() {}
}

export class GetCountriesForDropdownEndpoint extends EndpointWithoutRequest<GetCountriesForDropdownEndpointResponse[]> {
    constructor() {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Country.GetCountriesForDropdown);
    }

    public async HandleResponseAsync(response: GetCountriesForDropdownEndpointResponse[]): Promise<GetCountriesForDropdownEndpointResponse[]> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
