import { makeObservable } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient, ICommand, RelayCommand } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { CustomerDocumentItemModel, CustomerDocumentItemModelValidator } from "./CustomerDocumentItemModel";
import { IAddedDocumentViewModel } from "Models/Documents/IAddedDocumentViewModel";

export class CustomerDocumentItemViewModel extends ViewModelBase<CustomerDocumentItemModel> implements IAddedDocumentViewModel<CustomerDocumentItemModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super(new CustomerDocumentItemModel());
        this.setValidator(new CustomerDocumentItemModelValidator());
        makeObservable(this, {});
    }

    public clear = () => {
        this.model.clear();
    };

    public setDocumentType: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.setValue("documentCategoryId", Number(value.key));
    });
}
