import { singleton } from "tsyringe";
import { action, makeObservable, observable } from "mobx";

@singleton()
export class DomainStore {
    public isSideBarClosed: boolean = false;
    public routeHistory: string[] = [];

    constructor() {
        makeObservable(this, {
            isSideBarClosed: observable,
            routeHistory: observable,

            addToRouteHistory: action,
            clearPathHistory: action,
            popLastHistoryRoute: action,
            setSideBarClosed: action,
            toggleSideBar: action,
        });
        (window as any).DomainStore = this;
    }

    public setSideBarClosed = (isOpen: boolean) => {
        this.isSideBarClosed = isOpen;
    };

    public toggleSideBar = () => {
        this.isSideBarClosed = !this.isSideBarClosed;
    };

    public addToRouteHistory = (route: string) => {
        this.routeHistory.push(route);
    };

    public popLastHistoryRoute = () => {
        this.routeHistory.pop();
    };

    public clearPathHistory = () => {
        this.routeHistory = [];
    };
}
