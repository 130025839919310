import { TestModel, TestModelValidator } from "./TestModel";
import { makeObservable, observable } from "mobx";
import { FieldType, HttpClient, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { ICommand, RelayCommand } from "../Commands";
import { IKeyState, theme } from "../index";
import { container } from "tsyringe";

//extend viewmodel base and passing your model as the generic type
export class TestViewModel extends ViewModelBase<TestModel> {
    private static _instance: TestViewModel;
    private httpClient = container.resolve(HttpClient);
    private bind = this.getContext();
    public errorMessage: string = "";
    public validMessage: string = "";

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new TestModel());
        this.setValidator(new TestModelValidator());
        makeObservable(this, {
            errorMessage: observable,
            validMessage: observable,
        });
    }

    public validateModelCommand: ICommand = new RelayCommand(async (value: string, keyState: IKeyState) => {
        this.isModelValid();
    });
    public updateFirstNameCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("firstName", value);
    });
    public updateAmountCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("amount", parseFloat(value));
    });
    public updatePhoneNumberCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("phoneNumber", value);
    });
    public updateEmailAddressCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("emailAddress", value);
    });
    public updateLastNameCommand: ICommand = new RelayCommand((value: string, keyState: IKeyState) => {
        this.updateField("lastName", value);
    });
    public updateTeamsCommand: ICommand = new RelayCommand((value: string[]) => {
        let values = value.map((item: any) => {
            return item.key;
        });
        this.updateField("teams", values);
    });
    public updateTeamCommand: ICommand = new RelayCommand((value: KeyValuePair) => {
        this.updateField("team", value.key);
    });
    public updateAgeCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("age", value);
    });
    public updateDOBCommand: ICommand = new RelayCommand((value: Date) => {
        this.updateField("dob", value);
    });
    public updateMemoCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("memo", value);
    });
    public updateTermsCommand: ICommand = new RelayCommand((value: boolean) => {
        this.updateField("terms", value);
    });

    private updateField(fieldName: keyof FieldType<TestModel>, value: any) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }

    public updateAddCommand: ICommand = new RelayCommand(
        async () => {
            this.model.primaryCounter += 1;
            this.updateField("primaryCounter", this.model.primaryCounter);
        },
        () => {
            return this.model.primaryCounter < 10;
        },
    );
    public updateSubtractCommand: ICommand = new RelayCommand(
        async () => {
            this.model.primaryCounter -= 1;
            this.updateField("primaryCounter", this.model.primaryCounter);
        },
        () => {
            return this.model.primaryCounter > 0;
        },
    );

    public getFontStyles = () => {
        let retval = [];
        for (const style in theme.fontStyles) {
            retval.push(style);
        }
        return retval;
    };

    public getPaletteColours = () => {
        let retval = [];
        for (const style in theme.palette) {
            retval.push(style);
        }
        return retval;
    };

    public getCommonColours = () => {
        let retval = [];
        for (const style in theme.palette.common) {
            retval.push(style);
        }
        return retval;
    };

    public get getTeams() {
        return [
            {
                key: "id_0",
                text: "Blackpool",
            },
            {
                key: "id_1",
                text: "Man City",
            },
            {
                key: "id_2",
                text: "Shrewsbury",
            },
            {
                key: "id_3",
                text: "Wolves",
            },
            {
                key: "id_4",
                text: "Worcester",
            },
            {
                key: "id_5",
                text: "Worthing",
            },
        ] as KeyValuePair[];
    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "";
            this.validMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
            this.validMessage = "";
        }
    };

    public goToAdmin = () => {
        this.history.push(`/admin`);
    };

    //public afterUpdate: undefined;
    //public beforeUpdate: undefined;

    afterUpdate(fieldName: keyof FieldType<TestModel>, value: any): void {
        //Do something like call an api or whatever
        //Can use this instead of mobx reaction
        return;
    }
    beforeUpdate(fieldName: keyof FieldType<TestModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        if (value === "something" && fieldName === "firstName") {
            value = "boo";
        }
        return value;
    }
}
