import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, ICommand, Input } from "Application";
import { TSMNotesModalViewModel } from "./TSMNotesModalViewModel";
import { ModalGenericWithHeader } from "Components/ModalGenericWithHeader/ModalGenericWithHeader";
import NoteIcon from "Assets/Icons/editButtonIcon.svg";
import {
    TSMListHorizontalLine,
    TSMListHorizontalLineDashed,
    TSMModalGreyBox,
    TSMModalLargerText,
    TSMModalListText,
    TSMModalTableGrid,
    TSMModalWrapper,
    TSMTableSectionWrapper,
} from "../TSMModal.styles";
import { ButtonRounded } from "Components/Primitives/Buttons/ButtonRounded";
import Tick from "Assets/Icons/SmallTickCircleIcon.svg";
import Close from "Assets/Icons/RoundCloseIcon.svg";
import { P } from "Components/Primitives/TextElements/TextElements";
import { TSMListViewModel } from "Views/TSM/TSMListViewModel";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { TSMNoteListIem } from "./TSMNoteListItem";

export interface IModalProps {
    closeModalCommand: ICommand<any>;
    isOpen: boolean;
    parentViewModel: TSMListViewModel;
}

export const TSMNotesModalView: React.FC<IModalProps> = observer((props) => {
    const [viewModel] = useState(() => new TSMNotesModalViewModel(props.parentViewModel));
    useEffect(() => {
        viewModel.LoadNoteList(props.parentViewModel.selectedRowId);
        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    return (
        <>
            <ModalGenericWithHeader
                isOpen={props.isOpen}
                closeModalCommand={props.closeModalCommand}
                headerIcon={NoteIcon}
                headerText={viewModel.projectNumber}
                headerSubText={viewModel.projectName}
                stickyBar={<Button displayName=" Close" icon={<img src={Close} />} command={props.closeModalCommand} />}
                noBodyMargin={true}
            >
                <TSMModalWrapper>
                    <TSMModalGreyBox>
                        <Box style={{ flexGrow: 1 }} pr="9px">
                            <Input
                                displayName="New note*"
                                placeholder='Please add your new note here and then click "Add note"'
                                command={viewModel.setNewNote}
                                value={() => viewModel.getValue("note")}
                                multiline={true}
                                rows={6}
                                style={{ whiteSpace: "pre-line", overflow: "auto" }}
                                validationMessage={() => viewModel.getError("note")}
                            />
                        </Box>
                        <Box pt="20px">
                            <Button displayName=" Add note" command={viewModel.addNote} icon={<img src={Tick} />} paletteColor="ButtonGreen" boxShadow={true} />
                        </Box>
                    </TSMModalGreyBox>
                    <TSMTableSectionWrapper>
                        <TSMModalLargerText pb="9px">Notes ({viewModel.tsmNoteList.length})</TSMModalLargerText>
                        <TSMModalTableGrid>
                            {/* Table header... */}
                            <P>
                                <strong>Date</strong>
                            </P>
                            <P>
                                <strong>Note</strong>
                            </P>
                            <P>
                                <strong>Author</strong>
                            </P>
                            <P>
                                <strong>Actions</strong>
                            </P>
                            <TSMListHorizontalLine />

                            {/* Pass in the items... */}
                            {viewModel.tsmNoteList.map((item, index) => {
                                return (
                                    <>
                                        <TSMNoteListIem key={index} item={item} parentViewModel={viewModel} itemSelectedForEditing={viewModel.noteInEditMode} />
                                    </>
                                );
                            })}
                        </TSMModalTableGrid>
                    </TSMTableSectionWrapper>
                </TSMModalWrapper>
            </ModalGenericWithHeader>
        </>
    );
});
