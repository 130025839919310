import { makeObservable, action, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { EditCSSModel, EditCSSModelValidator } from "./EditCSSModel";
import { GetTemplateCSSFileEndpoint } from "../../Endpoints/GetTemplateCSSFileEndpoint";
import { UpsertTemplateCSSFileEndpoint } from "../../Endpoints/UpsertTemplateCSSFileEndpoint";

export class EditCSSModalViewModel extends ViewModelBase<EditCSSModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    public isProcessing: boolean = false;

    public spinnerText: string = "Loading CSS File";

    constructor() {
        super(new EditCSSModel());
        this.setValidator(new EditCSSModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            spinnerText: observable,

            clear: action,
            loadCss: action,
            setErrorMessage: action,
            updateCss: action,
        });
    }

    public clear = () => {
        this.model.clear();
        this.errorStore.clear();
        this.isProcessing = false;
        this.spinnerText = "Loading CSS File";
    };

    public setIsDirty(arg0: boolean) {
        // might implement this later???
    }

    public setCss(value: string) {
        this.setValue("css", value);
    }

    public setErrorMessage(error: string) {
        this.errorStore.clear();

        if (error.length > 0) {
            this.errorStore.errorMessageOne = error;
            this.errorStore.buttonText = "Cancel";
            this.errorStore.headerText = "CSS error";
        }
    }

    public setTemplateId(templateId: string) {
        this.setValue("id", templateId);
    }

    public loadCss = async (): Promise<void> => {
        if (this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            this.spinnerText = "Loading CSS File";
            const endpoint = new GetTemplateCSSFileEndpoint(this.model.id);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                let response: string = this.apiClient.Response<string>();
                runInAction(() => {
                    this.setValue("css", response);
                    this.isProcessing = false;
                });
            } // Else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Template CSS Load");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to get the template css file.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    };

    public updateCss = async (): Promise<boolean> => {
        let retVal: boolean = false;

        if (this.apiClient.IsBusy === false) {
            this.isProcessing = true;
            this.spinnerText = "Saving CSS File";
            let endpoint: UpsertTemplateCSSFileEndpoint = new UpsertTemplateCSSFileEndpoint();

            let _ = await this.apiClient.sendAsync(endpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    this.isProcessing = false;
                    let response: boolean = this.apiClient.Response();
                    retVal = response;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report Template CSS");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to update the CSS file.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }

        return retVal;
    };
}
