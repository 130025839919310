import { motion, useSpring } from "framer-motion";
import styled from "@emotion/styled";
import { container } from "tsyringe";
import { DomainStore } from "../../Stores/Domain/DomainStore";
import { Logger } from "index";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";

// *******************************

// OLD SIDE BAR - DO NOT USE! (JW)

// *******************************

const domainStore = container.resolve(DomainStore);

const Container = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    touch-action: none;
    z-index: 3;
    //background-color: rgba(10, 10, 10, 0.3);
`;

const SidebarContainer = styled(motion.div)`
    position: fixed;
    background-color: ${({ color }) => `${color}`};
    width: ${({ width }: { width: number }) => `${width}px`};
    height: 100%;
    box-sizing: border-box;
    box-shadow: 16px 0 32px -16px #000;
    padding: 64px 0 0 0;
`;

const HamburgerContainer = styled(motion.div)`
    position: absolute;
    border-radius: 32px;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    right: 0;
    //margin: 24px;
    cursor: pointer;
    z-index: 3;
    pointer-events: all;
    /* -webkit-tap-highlight-color: transparent; */
`;
const Line = styled(motion.div)`
    background-color: #fff;
    width: ${({ width }: { width: any }) => `${width}px`};
    height: 2px;
    margin: 2px 14px;
    border-radius: 8px;
`;

const HamburgerButton = observer(({ x, width }: { x: number; width: number }) => {
    reaction(
        () => domainStore.isSideBarClosed,
        (sidebarOpen) => {
            domainStore.isSideBarClosed ? (x as any).set(-width) : (x as any).set(0);
        },
    );

    return (
        <HamburgerContainer
            id={"hamburger"}
            onTap={() => {
                Logger.logDebug("Sidebar is open ", domainStore.isSideBarClosed);
                domainStore.setSideBarClosed(!domainStore.isSideBarClosed);
            }}
        >
            <Line
                width={16}
                animate={{ rotate: domainStore.isSideBarClosed ? 0 : "-45deg", width: domainStore.isSideBarClosed ? "16px" : "12px" }}
                transition={{ duration: 0.5 }}
            />
            <Line width={16} animate={{ width: domainStore.isSideBarClosed ? "16px" : "20px" }} transition={{ duration: 0.5 }} />
            <Line width={16} animate={{ rotate: domainStore.isSideBarClosed ? 0 : "45deg", width: domainStore.isSideBarClosed ? "16px" : "12px" }} transition={{ duration: 0.5 }} />
        </HamburgerContainer>
    );
});

export const Sidebar = observer(({ width = 320, color = "#1c2022", children, left = true }: { width: number; color: string; children: any; left: boolean }) => {
    const x = useSpring(0, { stiffness: 400, damping: 40 });

    return (
        <Container id={"sidebar"} style={{ pointerEvents: domainStore.isSideBarClosed ? "none" : "all" }}>
            <HamburgerButton x={x as any} width={width} />
            <SidebarContainer
                color={color}
                width={width}
                transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 40,
                }}
                initial={{ x: -width }}
                style={{ x }}
            >
                {children}
            </SidebarContainer>
        </Container>
    );
});
