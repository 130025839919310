import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { CustomerDocumentsViewModel } from "./CustomerDocumentsViewModel";

import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import DocumentIcon from "Assets/Icons/DocumentIcon.svg";
import { AddDocumentModal } from "Components/Modals/AddDocumentModal";
import DocLogo from "Assets/Icons/DocLogo.svg";
import CloseIcon from "Assets/close_icon_black.svg";
import { AddSectionsBox, ButtonBox, DeleteButton, UploadedDocumentsBox } from "Components/CommonPageComponents";
import { IDocumentType } from "Models/Documents/DocumentType";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";
import { useParams } from "react-router";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";

interface IDocuments {
    customerId: Guid;
    documentTypes: IDocumentType[];
    uploadedDocuments: IDocumentUploaded[];

    documentCountCallBack: (newCount: number) => void;
}

export const CustomerDocumentsView: React.FC<IDocuments> = observer((props) => {
    const [viewModel] = useState(new CustomerDocumentsViewModel(props.customerId, props.documentCountCallBack));
    const params = useParams();

    useEffect(() => {
        if (viewModel.uploadedDocuments) {
            viewModel.updateDocumentListForEditing(props.uploadedDocuments);
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, [props.uploadedDocuments]);

    useEffect(() => {
        if (params.customerId) {
            viewModel.customerId = params.customerId;
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, [params.customerId]);

    return (
        <>
            {viewModel.showAddDocumentModal === true && <AddDocumentModal parentViewModel={viewModel} documentTypes={props.documentTypes} />}
            {viewModel.areYouSureModalOpen === true && (
                <AreYouSureModal
                    title="Delete document"
                    message="Are you sure you want to delete this document?"
                    isOpen={viewModel.areYouSureModalOpen}
                    onCancel={viewModel.closeAreYouSureModal}
                    onConfirm={viewModel.confirmDeleteDocument}
                />
            )}

            <AddSectionsBox>
                <ButtonBox>
                    <ButtonUnder
                        displayName=" Add customer document"
                        paletteColor="ButtonBlue"
                        icon={<img src={DocumentIcon} />}
                        boxShadow={true}
                        command={viewModel.openNewDocumentModal}
                    />
                </ButtonBox>

                <H5 p="20px 0px">Customer documents({viewModel.getDocuments.length})</H5>
                {viewModel.getDocuments.length > 0 ? (
                    <>
                        {viewModel.getDocuments.map((document) => {
                            return (
                                <UploadedDocumentsBox key={document.id}>
                                    <Box flexBox gap={0} style={{ alignItems: "center" }}>
                                        <img src={DocLogo} height="18px" />
                                        <P pl="10px">{document.fileName}</P>
                                    </Box>
                                    <Box flexBox style={{ justifyContent: "space-between", alignItems: "center" }}>
                                        <DeleteButton command={viewModel.openAreYouSureModal} style={{ cursor: "pointer" }} value={document.id} icon={<img src={CloseIcon} />} />
                                    </Box>
                                </UploadedDocumentsBox>
                            );
                        })}
                    </>
                ) : (
                    <>
                        <P>There currently are no documents added to this customer.</P>
                    </>
                )}
            </AddSectionsBox>
        </>
    );
});
