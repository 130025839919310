import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { NewConditionTemplate } from "./ConditionReportTemplateAddEdit.style";
import { H1, P } from "Components/Primitives/TextElements/TextElements";
import { useDropzone } from "react-dropzone";
import { ConditionReportTemplateModel } from "./CondtionReportTemplateModel";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { UploadImageBox } from "Views/ConditionReport/AddEdit/ConditionReportAddEditView.styling";

export interface IUploadTemplateProps {
    addJsonFile: (file: File) => Promise<any>;
    reloadSections: () => void;
    setNewTemplate: (value: boolean) => void;
}

export const UploadTemplateView: React.FC<IUploadTemplateProps> = observer(({ addJsonFile, reloadSections, setNewTemplate }) => {
    const errorStore = container.resolve(ErrorStore);
    useEffect(() => {
        return () => {
            // Clean up after yourself
        };
    }, []);

    function JsonDropzone() {
        const onDrop = useCallback((acceptedFiles: File[]) => {
            // Send the files back to the client
            if (acceptedFiles.length == 1) {
                let promise = addJsonFile(acceptedFiles[0]);

                promise.then((retVal: ConditionReportTemplateModel) => {
                    if (retVal.id !== null || retVal.source !== undefined) {
                        setNewTemplate(false);
                        reloadSections();
                    }
                });
            } else if (acceptedFiles.length > 1) {
                errorStore.setHeaderText("Condition Report Template");
                errorStore.setButtonText("Close");
                errorStore.setErrorMessageOne("Only one json can be uploaded at a time.");
                errorStore.setErrorModalOpen(true);
            }
        }, []);
        const { getRootProps, getInputProps } = useDropzone({
            onDrop,
            accept: { "application/json": [] },
            maxFiles: 1,
        });
        return (
            <>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <UploadImageBox>
                        <P>Click or drag a condtion report file (.JSON)</P>
                    </UploadImageBox>
                </div>
            </>
        );
    }

    return (
        <>
            <NewConditionTemplate>
                <H1>Create Template from Condition Report</H1>
                <Box>
                    <p>Click, or drop a json file on the box below to upload the condition report raw file and create a template from:</p>
                    <p>Notes: </p>
                    <div style={{ paddingLeft: "15px" }}>
                        <ul>
                            <li>Depending on the number of images and the size of the file, this can take several minutes.</li>
                            <li>Please ensure the json file contains the image data and not just the filename</li>
                        </ul>
                    </div>
                    <JsonDropzone />
                </Box>
            </NewConditionTemplate>
        </>
    );
});
