import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";
import { IComplaintWithRelatedResponse } from "Models/Complaints/ComplaintWithRelatedResponse";

export class MasterProjectComplaintGetWithRelatedEndpoint extends EndpointWithoutRequest<IComplaintWithRelatedResponse> {
    constructor(id: string) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.AfterSales.Complaints.MasterProjectComplaintGet + `/${id}`);
    }

    public async HandleResponseAsync(response: IComplaintWithRelatedResponse): Promise<IComplaintWithRelatedResponse> {
        Logger.logInformation("Response", response);
        response.complaint.orderValue = response.complaint.orderValue.toString();
        //Return your model
        return response;
    }
}
