import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

export class UploadJsonFileRequest {
    public fileBase64: string = "";
    public fileName: string = "";
    public fileType: string = "";
    public constructor() {}
}

class UploadJsonFileResponse {}

export class UploadJsonFileEndpoint extends Endpoint<UploadJsonFileRequest, UploadJsonFileResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.ConditionReport.UploadJsonFile);
        this.AllowFileUploads();
    }

    public async HandleRequestAsync(file: File): Promise<any> {
        // We need to send the data to the server as form data here as we have files.
        const request = new UploadJsonFileRequest();
        //request.fileBase64 = file.base64StringFile;
        request.fileName = file.name;
        request.fileType = file.type;

        // We need to send the data to the server as form data here as we have files.
        const formData = new FormData();

        formData.append("data", JSON.stringify(request));

        formData.append(file.name, file);

        return Promise.resolve(formData);
    }

    public async HandleResponseAsync(response: UploadJsonFileResponse): Promise<any> {
        Logger.logInformation("Response", response);

        //Return your model
        return {};
    }
}
