import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { getFilenameFromContentDisposition } from "Utils/File";
import { ReportDownloadCSVResponse } from "Views/Reports/Models/ReportDownloadCSVResponse";

export class DownloadProjectCommunicationsReportEndpoint extends EndpointWithoutRequest<ReportDownloadCSVResponse> {
    constructor(path: string) {
        super();
        this.verb(Http.Get);
        this.path(path);
        this.responseIsBlob(true);
        this.SetContentType("text/csv");
    }

    public async HandleResponseAsync(response: any): Promise<any> {
        let retVal: boolean = true;
        let contentDisposition: string = response.headers["content-disposition"];
        const filename: string = getFilenameFromContentDisposition(contentDisposition);

        // Create a link element for the file and use the filename provided.
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Download.
        link.click();

        // Having clicked the link, delete the element otherwise it will
        // remain attached to the document.
        document.body.removeChild(link);

        //Return your model
        return retVal;
    }
}
