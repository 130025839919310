import { ModelBase } from "@shoothill/core";
import { makeObservable, observable, action, IObservableArray } from "mobx";
import { Validator } from "Application/Validation";
import { TemplateSourceModel } from "Views/ConditionReport/Common/Models/Source/TemplateSourceModel";
import { ConditionReportTemplateSection } from "./ConditionReportTemplateSection";
import { IDraggableItem } from "./Section";
import { SectionConcurrenyResponse, SectionOrdinal } from "../Endpoints/UpdateSectionOrdinalsEndpoint";
import { TINYMCE_DEFAULTCSS } from "Utils/Globals";

export class ConditionReportTemplateModel extends ModelBase<ConditionReportTemplateModel> {
    public id: string | null = null;
    public version: number = 2;
    public jsonVersionMin: number = 14;
    public jsonVersionMax: number = 17;
    //public createdDate: string = ""; // IN THE BASE
    public createdBy: string = "";
    public createdByName: string = "";
    public isDeleted: boolean = false;
    public deletedDate: string | null = null;
    public deletedBy: string | null = null;
    public deletedByName: string = "";
    public lastUpdateDate: string | null = null;
    public lastUpdatedBy: string | null = null;
    public lastUpdatedByName: string = "";
    public stateId: number = 1;
    public conditionReportTemplateSourceId: number = -1;
    public source: TemplateSourceModel = new TemplateSourceModel();

    public templateNumber: string = "";

    public sections: IObservableArray<ConditionReportTemplateSection> = observable([]);

    public css: string = TINYMCE_DEFAULTCSS;

    constructor() {
        super();
        makeObservable(this, {
            css: observable,
            //id: observable,
            version: observable,
            jsonVersionMin: observable,
            jsonVersionMax: observable,

            createdBy: observable,
            createdByName: observable,
            //isDeleted: observable,
            deletedDate: observable,
            deletedBy: observable,
            deletedByName: observable,
            lastUpdateDate: observable,
            lastUpdatedBy: observable,
            lastUpdatedByName: observable,
            stateId: observable,
            conditionReportTemplateSourceId: observable,
            templateNumber: observable,
            clear: action,
            loadTemplate: action,
            setSectionText: action,
            loadSection: action,
            deleteSection: action,
            sortList: action,
            updateOrdinals: action,
            updateConcurrencyTokens: action,
            //updateRepeatedOrdinals: action,
        });
    }

    public sortList = () => {
        const sorted: ConditionReportTemplateSection[] = this.sections
            .slice()
            .sort((a: ConditionReportTemplateSection, b: ConditionReportTemplateSection) => a.ordinal - b.ordinal);
        this.sections.clear();
        this.sections.replace(sorted);
    };

    public updateConcurrencyTokens(concurrencyTokens: SectionConcurrenyResponse[]) {
        for (const element of concurrencyTokens) {
            // at the moment we don't need to worry about the sub sections since they can't be re-arranged
            let sect: ConditionReportTemplateSection | undefined = this.sections.find((a) => a.id === element.id);

            if (sect !== undefined) {
                sect.concurrencyToken = element.concurrencyToken;
            }
        }
    }

    public getNextOrdinal(): number {
        let retVal: number = 1000;

        if (this.sections.length > 0) {
            let temp: number[] = this.sections.map((section) => {
                return section.ordinal;
            });

            retVal = Math.max(...temp) + 1000;
        }

        return retVal;
    }

    public clear = () => {
        this.id = "";
        this.version = 2;
        this.jsonVersionMin = 14;
        this.jsonVersionMax = 17;
        this.createdDate = null;
        this.createdBy = "";
        this.createdByName = "";
        this.isDeleted = false;
        this.deletedDate = null;
        this.deletedBy = null;
        this.deletedByName = "";
        this.lastUpdateDate = null;
        this.lastUpdatedBy = null;
        this.lastUpdatedByName = "";
        this.stateId = 1;
        this.conditionReportTemplateSourceId = -1;
        this.source = new TemplateSourceModel();
        this.templateNumber = "";
        this.sections.clear();
    };

    deleteSection(selectedSectionId: string) {
        let temp: ConditionReportTemplateSection[] = [];
        temp = this.sections.filter((a) => a.id !== selectedSectionId);
        this.sections.clear();
        this.sections.replace(temp);
    }

    public setSectionText = (selectedSectionId: string, newText: string) => {
        const section = this.sections.find((a) => a.id === selectedSectionId);

        if (section !== undefined) {
            section.html = newText;
        }
    };

    loadTemplate(template: ConditionReportTemplateModel) {
        this.fromResponse(template);

        // Now fix the arrays and the sections

        this.source = JSON.parse(JSON.stringify(template.source));
        this.sections.clear();

        for (const element of template.sections) {
            let newSection: ConditionReportTemplateSection = new ConditionReportTemplateSection();
            newSection.load(element);
            this.sections.push(newSection);
        }
        this.sections = this.sections.sort((a: ConditionReportTemplateSection, b: ConditionReportTemplateSection) => a.ordinal - b.ordinal);
    }

    public updateOrdinals = (section: IDraggableItem[]) => {
        let ordinal: number = 1;
        for (const sect of section) {
            const section = this.sections.find((a) => a.id === sect.id);

            if (section !== null && section !== undefined) {
                section.ordinal = ordinal;
                ordinal += 100;
            }
        }

        /* Debug code        for (const element of this.sections) {
            console.log(element.name + " : " + element.ordinal);
        } */

        this.sections.replace(this.sections.slice().sort((a: ConditionReportTemplateSection, b: ConditionReportTemplateSection) => a.ordinal - b.ordinal));

        /* Debug code   for (const element of this.sections) {
            console.log(element.name + " : " + element.ordinal);
        }*/
    };

    /*  public updateRepeatedOrdinals = (sections: IDraggableItem[]) => {
        // Issue we have here is that that these will be reset in UpdateOrdinals later
        // Those are counted in 100s.
        // So we need to keep these within 100 the corresponding hasRepeateElemets record.

        if (sections.length > 0) {
            // Find an item with the list
            let record: ConditionReportTemplateSection | undefined = this.sections.slice().find((a) => a.id === sections[0].id);

            if (record !== undefined) {
                // Find the corresponding hasRepeateElemets record
                let toplevelSection: ConditionReportTemplateSection | undefined = this.sections
                    .slice()
                    .find((a) => a.hasRepeatableElements === true && a.repeatedGroupId === record?.repeatedGroupId);
                if (toplevelSection !== undefined) {
                    // reorder the dragged items based on its ordinal
                    let nextordinal: number = toplevelSection.ordinal + 1;

                    for (const element of sections) {
                        let repeated: ConditionReportTemplateSection | undefined = this.sections.slice().find((a) => a.id === element.id);

                        if (repeated !== undefined) {
                            repeated.ordinal = nextordinal++;
                        }
                    }

                    this.sections.replace(this.sections.slice().sort((a: ConditionReportTemplateSection, b: ConditionReportTemplateSection) => a.ordinal - b.ordinal));
                }
            }
        }
    }; */

    public getSectionOrdinals(): SectionOrdinal[] {
        let retVal: SectionOrdinal[] = [];

        for (const element of this.sections) {
            if (element.id !== null) {
                retVal.push({ id: element.id, ordinal: element.ordinal });
            }
        }

        return retVal;
    }

    public loadSection(section: ConditionReportTemplateSection, currentId: string, isTempId: boolean) {
        // Value we have in the list might have a temp value, so if so we need to search for that?
        let idToSearchFor: string = isTempId === true ? currentId : section.id!;

        let foundSection: ConditionReportTemplateSection | null = this.findSection(idToSearchFor);

        if (foundSection !== null) {
            foundSection.load(section);
        }
    }

    public findSection(sectionId: string): ConditionReportTemplateSection | null {
        let retVal: ConditionReportTemplateSection | null = null;

        for (const element of this.sections) {
            // Check the section, if found return it
            if (element.id === sectionId) {
                retVal = element;
                break;
            }

            // so not the same one, so check it's subsections

            if (element.subSections.length > 0) {
                for (const subElement of element.subSections) {
                    if (subElement.id === sectionId) {
                        retVal = subElement;
                        break;
                    }
                }
            }
        }

        return retVal;
    }
}

export class CondtionReportTemplateModelValidator extends Validator<ConditionReportTemplateModel> {
    constructor() {
        super();
        // TODO this.ruleFor("id").notEmpty();
    }
}
