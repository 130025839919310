import { Logger } from "index";
import { Endpoint, Http } from "Application/Helpers/BaseEndpoint";
import { ICustomerNote } from "../Common/ICustomerNote";
import { GenericNotesModel } from "Models/Notes/GenericNotesModel";
import { AppUrls } from "AppUrls";

class GetCustomerNotesByCustomerIdRequest {
    customerId: Guid = "";
    orderBy: string = "DESC";
    columnName: string = "dateCreated";
}

class GetCustomerNotesByCustomerIdResponse {
    customerNotes: ICustomerNote[] = [];
}

export class GetCustomerNotesFromCustomerIdEndpoint extends Endpoint<GetCustomerNotesByCustomerIdRequest, GetCustomerNotesByCustomerIdResponse> {
    constructor() {
        super();
        this.verb(Http.Post);
        this.path(AppUrls.Server.Customer.GetCustomerNotesByCustomerId);
    }

    public async HandleRequestAsync(model: GenericNotesModel): Promise<any> {
        let request = new GetCustomerNotesByCustomerIdRequest();
        request.customerId = model.sourceId;
        request.columnName = model.columnName;
        request.orderBy = model.orderBy;
        return request;
    }

    public async HandleResponseAsync(response: GetCustomerNotesByCustomerIdResponse): Promise<any> {
        Logger.logInformation("Response", response);
        //Return your model
        return response;
    }
}
