import styled from "@emotion/styled";

export const ButtonLikeImage = styled.button`
    transition: transform 0.2s; /* Animation */
    :hover {
        transform: scale(1.1);
    }
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
`;
