import styled from "@emotion/styled";
import { Box } from "Application";

export const NewConditionTemplate = styled(Box)`
    padding: 30px;
`;

export const EditConditionTemplateWrapper = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    padding-left: 8px;
`;

export const EditConditionTemplate = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
`;

export const EditConditionTemplateSections = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    max-width: 320px;
    padding: 5px;
    //overflow-y: scroll;

    .selected {
        background-color: #d4d4d4;
    }
`;

export const EditConditionTemplateSection = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;

    border: 1px solid lightgray;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;

    background-color: #f3f3f3;
    cursor: move;
`;

export const TemplateEditor = styled(Box)`
    padding: 15px;
    margin-bottom: 15px;

    &.questionanswer {
        margin-top: 10px;
        padding: 5px;
        border-top: 1px solid dotted #0e0e0e;
        border-bottom: 1px solid dotted #0e0e0e;
        background-color: #e0e0e0;
    }
`;

export const SectionRow = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: space-between;

    .section-title {
        font-weight: 700;
        max-width: 270px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .delete-button {
        max-width: 30px;
        img {
            margin-top: 3px;
        }
    }
`;

export const EditConditionTemplateSectionWrapper = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: Column;

    cursor: default;

    :hover {
        background-color: #d4d4d4;
        cursor: pointer;
    }
`;

export const EditConditionTemplateEditor = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
`;
