import styled from "@emotion/styled";
import { Button, theme } from "Application";

export const ChangelogPaper = styled.div`
    width: 100%;
    min-width: 300px;
    max-width: 640px;
    background: transparent linear-gradient(180deg, white 0%, white 100%) 0% 0% no-repeat padding-box;
    color: black;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: ${theme.fontStyles.changeLog.fontSize};
        }
    }

    display: flex;
    flex-direction: column;
    max-height: 90vh;

    .modal-changelog {
        color: black;
    }

    .modal-changelog-body {
    }
`;

export const ChangelogHeader = styled.div`
    background: ${theme.palette.primary.main};
    color: rgb(255, 255, 255);
    padding: 16px 20px;

    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: space-evenly;
`;

export const ChangelogBody = styled.div`
    padding: 20px;
    flex: 1 1 auto;
    overflow-y: auto;
    color: black;
    background-color: white;

    :not(:last-child) {
        margin-bottom: 10px;
    }

    .modal-changelog-body-version {
        h4 > small {
            color: ${theme.palette.red.main};
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding-left: 1em;
                text-indent: -0.7em;
            }

            li::before {
                content: "● ";
                color: ${theme.palette.red.main};
            }
        }
    }
`;

export const ChangelogHeaderTitle = styled.div`
    display: flex;
    flex: 1 0 0;
`;

export const ChangelogHeaderCloseX = styled.div`
    margin-right: -5px;
    padding: 0 5px;
    cursor: default;
    :hover {
        cursor: pointer;
        background-color: ${theme.palette.primary.lighter};
    }
`;

export const ChangelogFooter = styled.div`
    flex: 1 0 auto;
    width: 100%;
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #000000;
`;

export const ChangelogButton = styled(Button)`
    font-size: ${theme.fontStyles.changeLog.fontSize};
    background-color: ${theme.palette.primary.main};
    color: #fff;
    border: none;
    cursor: default;

    :hover {
        background-color: ${theme.palette.primary.lighter};
        cursor: pointer;
    }
`;
