import React, { useEffect, useState, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { ConditionReportTemplateAddEditViewModel } from "./ConditionReportTemplateAddEditViewModel";
import { useParams } from "react-router";
import { BaseWrapper } from "Components/BaseWrapper";
import { Button, ThemedLoader } from "Components";
import { H1, H2, P } from "Components/Primitives/TextElements/TextElements";
import { KeyValuePair } from "@shoothill/core";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import { EditConditionTemplate, EditConditionTemplateEditor, EditConditionTemplateSections, EditConditionTemplateWrapper } from "./ConditionReportTemplateAddEdit.style";
import { ConditionReportTemplateModel } from "./CondtionReportTemplateModel";
import { IDraggableItem, Section } from "./Section";
import { ConditionReportTemplateSection } from "./ConditionReportTemplateSection";
import { ICommand, RelayCommand } from "Application";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { AddSectionModal } from "./Modals/AddSectionModal";

import { ControlType } from "Views/ConditionReport/Common/ControlType";
import { TemplateSectionView } from "./TemplateSection";
import { UploadTemplateView } from "./UploadTemplate";
import { RepeatableSectionView } from "./RepeatableSection/RepeatableSection";
import { EditCSSModal } from "./Modals/EditCSSModal";

export const ConditionReportTemplateAddEditView: React.FC = observer(() => {
    const [viewModel] = useState(() => new ConditionReportTemplateAddEditViewModel());
    const [newTemplate, setNewTemplate] = useState(false);

    const [sections, setSections] = useState<IDraggableItem[]>([]);

    const [isDeleteSectionOpen, setIsDeleteSectionOpen] = useState<boolean>(false);
    const [deleteSectionId, setDeleteSection] = useState<string>("");

    const [isAddSectionOpen, setIsAddSectionOpen] = useState<boolean>(false);

    const [isEditCSSOpen, setIsEditCSSOpen] = useState<boolean>(false);

    let params = useParams();

    let templateId: string = params.templateId ?? "new";

    useEffect(() => {
        if (templateId && templateId === "new") {
            setNewTemplate(true);
        } else {
            let promise: Promise<ConditionReportTemplateModel> = viewModel.loadConditionReportTemplateAsync(templateId);

            promise.then((template: ConditionReportTemplateModel) => {
                reloadSections();
            });
        }

        return () => {
            // Clean up after yourself
            if (viewModel.isSelectedSectionDirty === true) {
                let promise = viewModel.upsertSelectedSelection();

                promise.then((reponse: any) => {
                    let promise2 = viewModel.updateDBOrdinals();

                    promise2.then((reponse: any) => {
                        viewModel.clear();
                    });
                });
            } else {
                let promise2 = viewModel.updateDBOrdinals();

                promise2.then((reponse: any) => {
                    viewModel.clear();
                });
            }
        };
    }, []);

    const onUpdate = () => {
        viewModel.setSections(sections);
        let promise = viewModel.upsertSelectedSelection();
        promise.then(() => {
            let promise2 = viewModel.updateDBOrdinals();
            promise2.then((reponse: any) => {});
        });
    };

    const moveSection = useCallback((dragIndex: number, hoverIndex: number, itemId: string) => {
        viewModel.setSelectedSection(itemId);

        const temp = (prevCards: IDraggableItem[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            });

        setSections(temp);
        viewModel.sectionsReordered();
    }, []);

    const onSectionClick = (id: string) => {
        viewModel.setSelectedSection(id);
    };

    const onRemoveSection = (id: string) => {
        viewModel.setSections(sections);
        setDeleteSection(id);

        if (viewModel.isSelectedSectionDirty === true) {
            let promise = viewModel.upsertSelectedSelection();

            promise.then((reponse: any) => {
                let promise2 = viewModel.updateDBOrdinals();

                promise2.then((reponse: any) => {
                    if (viewModel.selectedSectionId !== id) {
                        viewModel.setSelectedSection(id);
                    }

                    setIsDeleteSectionOpen(true);
                });
            });
        } else {
            if (viewModel.selectedSectionId !== id) {
                viewModel.setSelectedSection(id);
            }

            setIsDeleteSectionOpen(true);
        }
    };

    const renderSection = useCallback((card: IDraggableItem, index: number) => {
        return (
            <Section
                key={card.id}
                index={index}
                id={card.id}
                text={card.text}
                hasRepeatableElmements={card.hasRepeatableElements}
                moveCard={moveSection}
                onClickHandler={onSectionClick}
                onRemoveSection={onRemoveSection}
                additionalClasses={card.id === viewModel.selectedSectionId ? "selected" : ""}
                showDelete={true}
            />
        );
    }, []);

    const reloadSections = () => {
        let draggableItems: IDraggableItem[] = [];
        let sections: ConditionReportTemplateSection[] = viewModel.getTopLevelSections;
        for (const element of sections) {
            draggableItems.push({ id: element.id!, text: element.name, isRepeatable: element.isRepeatable, hasRepeatableElements: element.hasRepeatableElements });
        }
        setSections(draggableItems);
    };

    const cancelSectionDelete = () => {
        setIsDeleteSectionOpen(false);
    };

    const confirmSectionDelete = () => {
        let promise = viewModel.deleteSection(deleteSectionId);

        promise.then(() => {
            setIsDeleteSectionOpen(false);
            setDeleteSection("");
            reloadSections();
        });
    };

    const addSection: ICommand = new RelayCommand(() => {
        setIsAddSectionOpen(true);
    });

    const cancelSectionAdd = () => {
        setIsAddSectionOpen(false);
    };

    const confirmSectionAdd = async (
        sectionTite: string,
        sectionType: string,
        controlTypeId: ControlType,
        typeKey: string,
        options: KeyValuePair[],
        isRepeatableSection: boolean,
    ) => {
        let promise = viewModel.addNewSection(sectionTite, sectionType, controlTypeId, typeKey, options, isRepeatableSection);

        promise.then((response) => {
            setIsAddSectionOpen(false);
            reloadSections();
        });
    };

    const editCss: ICommand = new RelayCommand(() => {
        setIsEditCSSOpen(true);
    });

    const cancelEditCSS = () => {
        setIsEditCSSOpen(false);
    };

    const confirmEditCSS = async (css: string) => {
        setIsEditCSSOpen(false);

        setTimeout(() => {
            viewModel.setValue("css", css);
            reloadSections();
        }, 100);
    };

    console.log("View section length: " + sections.length);
    if (sections.length > 0) {
        console.log(sections[0].text);
    }

    viewModel.setSections(sections);

    let spinnerText: string = viewModel.getSpinnerText;

    const addJsonFile = async (file: File): Promise<ConditionReportTemplateModel> => {
        let promise = viewModel.addJsonFile(file);
        return promise;
    };

    const getDeleteMessage = () => {
        let retVal = "Are you sure you want to delete this section?";

        if (viewModel.isSectionTopLevelRepeatable(deleteSectionId) === true) {
            retVal = "Are you sure you want to delete this section?  This will also mark its releated repeatble sections deleted";
        }
        return retVal;
    };

    return (
        <BaseWrapper>
            <AreYouSureModal
                title="Template Delete Section"
                message={getDeleteMessage()}
                isOpen={isDeleteSectionOpen}
                onCancel={cancelSectionDelete}
                onConfirm={confirmSectionDelete}
            />
            {isAddSectionOpen && (
                <AddSectionModal
                    isOpen={isAddSectionOpen}
                    title="Template Add Section"
                    onCancel={cancelSectionAdd}
                    onConfirm={confirmSectionAdd}
                    templateSource={viewModel.model.source}
                />
            )}
            {isEditCSSOpen && viewModel.model.id !== null && (
                <EditCSSModal isOpen={isEditCSSOpen} title="Edit Template CSS" onCancel={cancelEditCSS} onConfirm={confirmEditCSS} templateId={viewModel.model.id} />
            )}
            {viewModel.isProcessing === true ? (
                <ThemedLoader spinnerText={spinnerText} isOpen={true} />
            ) : (
                <>{newTemplate === true && <UploadTemplateView addJsonFile={addJsonFile} reloadSections={reloadSections} setNewTemplate={setNewTemplate} />}</>
            )}
            {newTemplate === false && (
                <EditConditionTemplateWrapper>
                    <H1>Template Setup</H1>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <p>
                            <strong>Template:&nbsp;</strong> {viewModel.model.templateNumber}
                        </p>
                        <Button displayName={"Edit CSS"} command={editCss} boxShadow={true} paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"} />
                    </div>
                    <EditConditionTemplate>
                        <EditConditionTemplateSections>
                            <H2>Sections</H2>
                            <P>Click to edit a section, or drag the sections around to reorder.</P>
                            <p>&nbsp;</p>
                            <Button
                                displayName={"Add New Section"}
                                command={addSection}
                                boxShadow={true}
                                paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                            />
                            <p>&nbsp; &nbsp;</p>
                            <DndProvider backend={HTML5Backend}>
                                <div>
                                    {sections
                                        .slice()
                                        .map((section, index) =>
                                            renderSection(
                                                { id: section.id, text: section.text, isRepeatable: section.isRepeatable, hasRepeatableElements: section.hasRepeatableElements },
                                                index,
                                            ),
                                        )}
                                </div>
                            </DndProvider>
                        </EditConditionTemplateSections>

                        {sections.length === 0 && <EditConditionTemplateEditor></EditConditionTemplateEditor>}
                        {sections.length > 0 && (
                            <EditConditionTemplateEditor>
                                {viewModel.selectedSectionId.length > 0 &&
                                    viewModel.isProcessing === false &&
                                    viewModel.isSectionTopLevelRepeatable(viewModel.selectedSectionId) === false && (
                                        <div>
                                            <TemplateSectionView
                                                section={viewModel.getSelectedSection}
                                                isSubSection={false}
                                                placeHolderOptions={viewModel.getQuestionOptions()}
                                                repeatedSectionOptions={viewModel.getQuestionOptions()}
                                                setSectionDirty={viewModel.setSectionDirty}
                                                onUpdate={onUpdate}
                                                templateCss={viewModel.getCss}
                                            />
                                        </div>
                                    )}

                                {viewModel.selectedSectionId.length > 0 &&
                                    viewModel.isProcessing === false &&
                                    viewModel.isSectionTopLevelRepeatable(viewModel.selectedSectionId) === true && (
                                        <div>
                                            <RepeatableSectionView topLevelSectionId={viewModel.getSelectedSection.id!} parentViewModel={viewModel} onUpdate={onUpdate} />
                                        </div>
                                    )}
                            </EditConditionTemplateEditor>
                        )}
                    </EditConditionTemplate>
                </EditConditionTemplateWrapper>
            )}
        </BaseWrapper>
    );
});
