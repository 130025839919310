import React, { MouseEventHandler, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "@emotion/styled";
import { Box, ICommand } from "Application";
import { theme } from "Application";
import { P } from "./Primitives/TextElements/TextElements";

interface IRoundedInputsSuffix {
    text?: string;
    onClick: MouseEventHandler<HTMLDivElement>;
}

export const RoundedInputSuffix: React.FC<IRoundedInputsSuffix> = observer((props) => {
    return (
        <>
            <RoundedBox onClick={props.onClick}>
                <PButtonText>{props.text}</PButtonText>
            </RoundedBox>
        </>
    );
});

export const RoundedBox = styled(Box)`
    background-color: ${theme.palette.common.sharmansBlue};
    border-radius: 0px 10px 10px 0px;
    padding: 12px;
    height: 100%;
    cursor: pointer;
`;

export const PButtonText = styled(P)`
    color: white;
    white-space: nowrap;
    font-size: 0.625rem;
`;
