import { Logger } from "index";
import { EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { AppUrls } from "AppUrls";

class GetContactByIdResponse {}

export class GetContactByIdEndpoint extends EndpointWithoutRequest<GetContactByIdResponse> {
    constructor(id: Guid) {
        super();
        this.verb(Http.Get);
        this.path(AppUrls.Server.Customer.GetContactById + id);
    }

    public async HandleResponseAsync(response: GetContactByIdResponse): Promise<GetContactByIdResponse> {
        Logger.logInformation("Response", response);

        //Return your model
        return response;
    }
}
