import { isNullOrUndefined } from "@shoothill/core";
import { LatLngBounds, LatLngExpression, Map } from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

interface IProps {
    boundsToFit: LatLngBounds | undefined;
    center?: LatLngExpression;
    zoom?: number;
    getMapObjectCallback?: (map: Map) => void;
}

// This Hack is used since we can't use useMap directly inside the class, it has to be inside the container.
// If we don't do that, we get
// useLeafletContext() can only be used in a descendant of <MapContainer>

export const MapHack: React.FC<IProps> = ({ boundsToFit, getMapObjectCallback, center, zoom }) => {
    let map: Map = useMap();
    // console.log("map center:", map.getCenter());

    useEffect(() => {
        return () => {
            // Clean up after yourself

            if (map /* && map.remove */) {
                /* map.clearAllEventListeners();
                map.off(); */
                // Why does this crash when opening another map? map.remove();
            }
        };
    }, []);

    useEffect(() => {
        if (isNullOrUndefined(map) === false && isNullOrUndefined(boundsToFit) === false) {
            map.fitBounds(boundsToFit!);
        }
    }, [boundsToFit]);

    useEffect(() => {
        if (isNullOrUndefined(map) === false && isNullOrUndefined(center) === false) {
            // no set center map.
            map.panTo(center!);
            map.setZoom(zoom!);
        }
    }, [center, zoom]);

    /*     useEffect(() => {
        if (isNullOrUndefined(map) === false && isNullOrUndefined(zoom) === false) {
            map.setZoom(zoom!);
        }
    }, [zoom]); */

    useEffect(() => {
        if (isNullOrUndefined(getMapObjectCallback) === false) {
            getMapObjectCallback!(map);
        }
    }, [map]);

    return null;
};
