import { makeObservable, action, observable, runInAction, IObservableArray } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ViewContactModalModel, ViewContactModalModelValidator } from "./ViewContactModalModel";
import { GetContactDetailsModalByIdEndpoint } from "./Endpoints/GetContactDetailsModalById";
import { IDocumentUploaded } from "Models/Documents/IDocumentUploaded";

export class ViewContactModalViewModel extends ViewModelBase<ViewContactModalModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    public contactId: Guid = "";

    public uploadedDocuments: IObservableArray<IDocumentUploaded> = observable([]);

    constructor() {
        super(new ViewContactModalModel());
        this.setValidator(new ViewContactModalModelValidator());
        makeObservable(this, {
            contactId: observable,
            isProcessing: observable,
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };

    public setContactId = (id: Guid) => {
        this.contactId = id;
        this.getContactDetailsModalById();
    };

    public async getContactDetailsModalById(): Promise<void> {
        // this.spinnerText = "Saving...";
        this.isProcessing = true;
        if (this.contactId !== null && this.contactId !== "") {
            const endpoint = new GetContactDetailsModalByIdEndpoint(this.contactId);
            await this.apiClient.sendAsync(endpoint);

            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let response = this.apiClient.Response();
                    this.model.fromResponse(response);
                    this.uploadedDocuments.replace(response.documents);

                    this.isProcessing = false;
                    // this.spinnerText = "Loading...";
                });
            } // else show error message
            else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Contact");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load contact.  Please try again later.");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
    }
}
