import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ContactInfoModalModel, ContactInfoModalModelValidator } from "./ContactInfoModalModel";
import { GetOccupierByIdForModalEndpoint } from "./GetOccupierByIdForModal";
import { CustomerStatusHelpers } from "Application/Models/Domain/Customer/CustomerStatusEnum";

export class ContactInfoModalViewModel extends ViewModelBase<ContactInfoModalModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;

    constructor() {
        super(new ContactInfoModalModel());
        this.setValidator(new ContactInfoModalModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            getOccupierByIdAsync: action,
            customerStatusClass: computed,
            customerStatusName: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.model.clear();
    };

    public get customerStatusClass() {
        return CustomerStatusHelpers.getCustomerStatusClass(this.model.customerStatusId);
    }

    public get customerStatusName() {
        return CustomerStatusHelpers.getCustomerStatusText(this.model.customerStatusId);
    }

    public async getOccupierByIdAsync(id: string): Promise<void> {
        const endpoint = new GetOccupierByIdForModalEndpoint(id);
        await this.apiClient.sendAsync(endpoint);
        this.isProcessing = true;

        if (this.apiClient.IsRequestSuccessful) {
            runInAction(() => {
                this.isProcessing = false;
                let response = this.apiClient.Response();
                this.model.fromResponse(response);
            });
        } else {
            runInAction(() => {
                this.isProcessing = false;
            });
            this.errorStore.setHeaderText("Occupier");
            this.errorStore.setButtonText("Close");
            this.errorStore.setErrorMessageOne("Failed to get the occupier.  Please try again later.");
            this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
            this.errorStore.setErrorModalOpen(true);
        }
    }
}
