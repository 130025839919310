import { makeObservable, action, computed, observable, runInAction } from "mobx";
import { ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ComplaintDetailsModel, ComplaintDetailsModelValidator } from "./ComplaintDetailsModel";

export class ComplaintDetailsViewModel extends ViewModelBase<ComplaintDetailsModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);

    constructor() {
        super(new ComplaintDetailsModel());
        this.setValidator(new ComplaintDetailsModelValidator());
        makeObservable(this, {
            clear: action,
        });
    }

    public clear = () => {
        this.model.clear();
    };
}
