import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable } from "mobx";

export class DocumentViewerModel extends ModelBase<DocumentViewerModel> {
    public file: File | null = null;

    constructor() {
        super();
        makeObservable(this, {
            file: observable,
            clear: action,
        });
    }

    public clear = () => {
        this.file = null;
    };
}
