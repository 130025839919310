import React from "react";
import { observer } from "mobx-react-lite";
import { Box, theme } from "Application";
import { ExclamationIconSVG } from "../Assets/Icons/ExclamationIconSVG";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

interface Props {
    haveError: boolean;
    displayName?: string;
    validationMessage: () => string;
    style?: any;
    iconRight?: boolean;
}

export const ErrorExclamationView: React.FC<Props> = observer((props: Props) => {
    const errorLabel = () => {
        if (props.haveError) {
            return (
                <Box display={"flex"} ml={1} style={{ zIndex: 0 }}>
                    <Box mt={props.iconRight ? "0px" : "-3px"} style={{ zIndex: 0 }}>
                        <Tippy zIndex={1000005} content={<span>{props.validationMessage()}</span>}>
                            <ExclamationIconSVG color={"white"} style={{ outline: "none" }} backgroundcolor={theme.palette.error.main} />
                        </Tippy>
                    </Box>
                </Box>
            );
        } else {
            return null;
        }
    };

    return errorLabel();
});
