import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, ICommand, Input, RelayCommand, ThemedLoader } from "Application";
import { Modal } from "@fluentui/react";
import { AddSectionModalBody, AddSectionModalHeader, AddSectionModalWrapper, EditCSSModalBody, ModalFooter, ModalRow } from "./AddSectionModal.style";
import { P } from "Components/Primitives/TextElements/TextElements";
import AddIcon from "Assets/Icons/addRoundButtonIcon.svg";
import CloseIcon from "Assets/close_icon.svg";
import { ButtonBox, HeaderBox } from "Components/AreYouSureModal/AreYouSure.styles";
import { EditCSSModalViewModel } from "./EditCSSModalViewModel";
import StyleEditor from "react-style-editor";
export interface IEditCSSProps {
    title: string;
    isOpen: boolean;
    templateId: string | null;

    onConfirm: (css: string) => void;
    onCancel: () => void;
}

export const EditCSSModal: React.FC<IEditCSSProps> = observer(({ title, isOpen, templateId, onCancel, onConfirm }) => {
    const [viewModel] = useState<EditCSSModalViewModel>(new EditCSSModalViewModel());
    const clear = () => {
        viewModel.clear();
    };

    useEffect(() => {
        if (templateId === null) {
            // Get out of here
            onCancel();
        } else if (templateId !== viewModel.model.id) {
            viewModel.setTemplateId(templateId);
            let promise = viewModel.loadCss();

            promise.then(() => {});
        }
    }, [templateId]);

    const handleSaveClick: ICommand = new RelayCommand(async () => {
        if (viewModel.model.css.length > 0) {
            if (viewModel.isModelValid() === false) {
                viewModel.setErrorMessage("Please add some css");
            } else {
                let promise: Promise<boolean> = viewModel.updateCss();

                promise.then((result: boolean) => {
                    onConfirm(viewModel.model.css);
                    clear();
                });
            }
        }
    });

    const handleCancelClick: ICommand = new RelayCommand(() => {
        handleCancel();
    });

    const handleCancel = () => {
        clear();
        onCancel();
    };

    const setCSSCommand: ICommand = new RelayCommand((value: string) => {
        setCSS(value);
    });

    const setCSS = (value: string) => {
        if (value !== viewModel.model.css) {
            viewModel.setCss(value);
            viewModel.setErrorMessage("");
            viewModel.setIsDirty(true);
        }
    };

    const disableSave: boolean = viewModel.model.css.length === 0;

    return (
        <>
            <Modal isOpen={isOpen}>
                <AddSectionModalWrapper style={{ minWidth: "1024px" }}>
                    {viewModel.isProcessing === true ? (
                        <ThemedLoader spinnerText={viewModel.spinnerText} isOpen={true} />
                    ) : (
                        <>
                            <AddSectionModalHeader>
                                <HeaderBox>
                                    <img src={AddIcon} height="20px" />
                                    <P color="white" pl="10px" style={{ fontSize: "10px", fontWeight: 600 }}>
                                        {title}
                                    </P>
                                </HeaderBox>
                                <HeaderBox style={{ cursor: "pointer" }} onClick={() => handleCancel()}>
                                    <img src={CloseIcon} />
                                </HeaderBox>
                            </AddSectionModalHeader>

                            <EditCSSModalBody>
                                <ModalRow>
                                    <p>
                                        <strong>Note:</strong> Only edit this if you know what you are doing. There is no validation on whether this is valid CSS (copy and paste
                                        into an only checker to do that), so you could break the way the template and reports look!
                                    </p>
                                </ModalRow>
                                <StyleEditor value={viewModel.model.css} onChange={setCSS} />
                            </EditCSSModalBody>
                            <ModalFooter>
                                <ButtonBox pt="30px">
                                    <Button command={handleSaveClick} displayName="Save" boxShadow={true} paletteColor="ButtonGreen" isButtonDisabled={disableSave} />
                                    <Button
                                        className="last-button"
                                        command={handleCancelClick}
                                        displayName="Cancel"
                                        boxShadow={true}
                                        paletteColor="ButtonBlue" // TODO CMR {viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                                    />
                                </ButtonBox>
                            </ModalFooter>
                        </>
                    )}
                </AddSectionModalWrapper>
            </Modal>
        </>
    );
});
