import { ModelBase } from "@shoothill/core";
import { makeObservable, action, observable } from "mobx";
import { Validator } from "Application/Validation";

export class TasksModel extends ModelBase<TasksModel> {
    public id: string | null = null;
    public taskTypeId: number = 0;
    public sourceId: string = "";
    public subTaskTypeId: string = "";
    public assignedUserId: string = "";
    public description: string = "";
    public startDateTime: Date = new Date();
    public dueDateTime: string | null = null;
    public isUrgent: boolean = false;
    public isComplete: boolean = false;
    public completedDateTime: Date | undefined = undefined;
    public completedById?: string | null = null;

    public projectId: string = "";
    public enumId: number = 0;
    constructor() {
        super();
        makeObservable(this, {
            clear: action,

            taskTypeId: observable,
            sourceId: observable,
            subTaskTypeId: observable,
            assignedUserId: observable,
            description: observable,
            startDateTime: observable,
            dueDateTime: observable,
            isUrgent: observable,
            isComplete: observable,
            projectId: observable,
            enumId: observable,
            completedDateTime: observable,
            completedById: observable,
        });
    }

    public clear = () => {
        this.id = "";
        this.taskTypeId = 0;
        this.subTaskTypeId = "";
        this.sourceId = "";
        this.description = "";
        this.assignedUserId = "";
        this.startDateTime = new Date();
        this.dueDateTime = null;
        this.isUrgent = false;
        this.isComplete = false;
        this.completedDateTime = undefined;
        this.projectId = "";
        this.enumId = 0;
        this.completedById = "";
    };
}

export class TasksModelValidator extends Validator<TasksModel> {
    constructor() {
        super();
        this.ruleFor("subTaskTypeId").notNull().notEqual("").withMessage("Please select a task type");
        this.ruleFor("assignedUserId").notNull().notEqual("").withMessage("Please select an assignee");
    }
}
