import { makeObservable, observable, runInAction, action, computed, IObservableArray } from "mobx";
import { KeyValuePair, ViewModelBase } from "@shoothill/core";
import { APIClient } from "Application";
import { ErrorStore } from "Stores/Domain/ErrorStore";
import { container } from "tsyringe";
import { ConditionReportPdfAndRelated, ConditionReportPdfModel, ConditionReportPdfModelValidator } from "./ConditionReportPdfModel";
import { CreateNewPDFReportEndpoint, CreateNewPDFReportEndpointRequest } from "../Endpoints/CreateNewPDFReportEndpoint";
import { GetPdfByIdEndpoint, GetPdfByIdRequest } from "../Endpoints/GetPdfByIdEndpoint";
import { UpdatePdfDataEndpoint } from "../Endpoints/UpdatePdfDataEndpoint";
import { UpdateDocumentContactIdEndpoint } from "../Endpoints/UpdateDocumentContactIdEndpoint";
import { BlobResponse, ImageSourceEnum, UploadImageEndpoint, UploadImageRequest } from "../Endpoints/UploadImageEndpoint";

export class ConditionReportAddEditPDFViewModel extends ViewModelBase<ConditionReportPdfModel> {
    public apiClient = new APIClient();
    errorStore = container.resolve(ErrorStore);
    public isProcessing: boolean = false;
    public isLoading: boolean = false;

    public spinnerText: string = "Loading...";

    public bdms: IObservableArray<KeyValuePair<string>> = observable([]);

    constructor() {
        super(new ConditionReportPdfModel());
        this.apiClient.setAPITimeout(240000);
        this.setValidator(new ConditionReportPdfModelValidator());
        makeObservable(this, {
            isProcessing: observable,
            spinnerText: observable,
            isLoading: observable,

            backupPdfData: action,
            clear: action,
            createNewPDFDataFile: action,
            loadExisting: action,
            setDocumentContact: action,
            updatePdfData: action,
            uploadImage: action,

            getCss: computed,
            getDocumentContact: computed,
            getContactsForDropdown: computed,
        });
    }

    public clear = () => {
        this.isProcessing = false;
        this.model.clear();
        this.bdms.clear();
        this.spinnerText = "Loading...";
    };

    public get getDocumentContact(): string {
        const retVal = this.model.documentContactId === null ? "" : this.model.documentContactId.toString();

        return retVal;
    }

    public setDocumentContact = async (value: number): Promise<string> => {
        this.setValue("documentContactId", value);
        let retVal: string = "";
        if (this.isProcessing === false) {
            this.isLoading = false;
            let endpoint: UpdateDocumentContactIdEndpoint = new UpdateDocumentContactIdEndpoint();
            this.isProcessing = true;
            this.spinnerText = "Updating Document Contact...";
            let _ = await this.apiClient.sendAsync(endpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let data: string = this.apiClient.Response();
                    runInAction(() => {
                        this.isProcessing = false;
                        // From response is not loading the data correctly so wrote my own load
                        this.model.concurrencyToken = data;
                        this.model.documentContactId = value;
                    });

                    retVal = value.toString();
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report PDF");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to update the document contact.  Please try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public uploadImage = async (base64: string, file: any): Promise<BlobResponse> => {
        let retVal: BlobResponse = {
            url: "",
            name: "",
            originalFileName: "",
        };

        if (this.isProcessing === false) {
            this.isLoading = false;
            let endpoint: UploadImageEndpoint = new UploadImageEndpoint();
            this.isProcessing = true;
            this.spinnerText = "Updating Image...";

            const request: UploadImageRequest = {
                sourceId: this.model.id!,
                sourceTypeId: ImageSourceEnum.Pdf,
                fileName: file.name,
                fileBase64: base64,
                fileType: file.type,
            };
            let _ = await this.apiClient.sendAsync(endpoint, request);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let data: BlobResponse = this.apiClient.Response();
                    this.isProcessing = false;
                    retVal = data;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                });
                this.errorStore.setHeaderText("Condition Report PDF");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to upload the image.  Please try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }

        return retVal;
    };

    public get getSpinnerText(): string {
        return this.spinnerText;
    }

    public get getContactsForDropdown(): KeyValuePair<any>[] {
        return this.bdms.slice().slice();
    }

    public get getCss(): any {
        return this.model.css;
    }

    public createNewPDFDataFile = async (conditionReportId: string, templateId: string): Promise<ConditionReportPdfModel> => {
        let retVal: ConditionReportPdfModel = new ConditionReportPdfModel();

        if (this.isProcessing === false) {
            let endpoint: CreateNewPDFReportEndpoint = new CreateNewPDFReportEndpoint();
            this.isProcessing = true;
            this.isLoading = true;
            this.spinnerText = "Creating...";
            const endPointModel: CreateNewPDFReportEndpointRequest = new CreateNewPDFReportEndpointRequest();
            endPointModel.conditionReportId = conditionReportId;
            endPointModel.templateId = templateId;

            let _ = await this.apiClient.sendAsync(endpoint, endPointModel);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let data: ConditionReportPdfAndRelated = this.apiClient.Response();
                    runInAction(() => {
                        // From response is not loading the data correctly so wrote my own load
                        this.model.fromResponse(data.conditionReportPdf);
                        this.bdms.replace(data.bdms);
                        this.isProcessing = false;
                        this.isLoading = false;
                    });

                    retVal = this.model;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                    this.isLoading = false;
                });
                this.errorStore.setHeaderText("Condition Report PDF");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to create new pdf data.  Please try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public loadExisting = async (pdfReportId: string): Promise<ConditionReportPdfModel> => {
        let retVal: ConditionReportPdfModel = new ConditionReportPdfModel();

        if (this.isProcessing === false) {
            let endpoint: GetPdfByIdEndpoint = new GetPdfByIdEndpoint();
            this.isProcessing = true;
            this.isLoading = true;
            this.spinnerText = "Loading...";
            const endPointModel: GetPdfByIdRequest = new GetPdfByIdRequest();
            endPointModel.id = pdfReportId;

            let _ = await this.apiClient.sendAsync(endpoint, endPointModel);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let data: ConditionReportPdfAndRelated = this.apiClient.Response();
                    runInAction(() => {
                        this.model.fromResponse(data.conditionReportPdf);
                        this.bdms.replace(data.bdms);
                        this.isProcessing = false;
                        this.isLoading = false;
                    });

                    retVal = this.model;
                });
            } else {
                runInAction(() => {
                    this.isProcessing = false;
                    this.isLoading = false;
                });
                this.errorStore.setHeaderText("Condition Report PDF");
                this.errorStore.setButtonText("Close");
                this.errorStore.setErrorMessageOne("Failed to load existing pdf data.  Please try again");
                this.errorStore.setErrorMessageTwo(this.apiClient.ValidationMessage);
                this.errorStore.setErrorModalOpen(true);
            }
        }
        return retVal;
    };

    public backupPdfData = () => {
        if (this.isProcessing === false) {
            this.isProcessing = true;

            // Create a link element for the file and use the filename provided.
            const date: Date = new Date();
            const dateString: string = date.getFullYear().toString() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds();
            const filename = `cr-${this.model.id}-${this.model.conditionReportId}-${dateString}.html`;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(new Blob([this.model.html], { type: "text/plain" }));
            link.target = "_blank";
            link.setAttribute("download", filename);

            document.body.appendChild(link);

            // Download.
            link.click();

            // Having clicked the link, delete the element otherwise it will
            // remain attached to the document.
            document.body.removeChild(link);

            this.isProcessing = false;
        }
    };

    public updatePdfData = async (hideError: boolean = false): Promise<ConditionReportPdfModel> => {
        let retVal: ConditionReportPdfModel = new ConditionReportPdfModel();

        if (this.isProcessing === false) {
            let endpoint: UpdatePdfDataEndpoint = new UpdatePdfDataEndpoint();
            this.isProcessing = true;
            this.isLoading = false;
            this.spinnerText = "Saving...";

            let _ = await this.apiClient.sendAsync(endpoint, this.model);
            if (this.apiClient.IsRequestSuccessful) {
                runInAction(() => {
                    let data: ConditionReportPdfModel = this.apiClient.Response();
                    runInAction(() => {
                        this.model.fromResponse(data);
                        this.isProcessing = false;
                    });

                    retVal = data;
                });
            } else {
                const errorMsg: string = this.apiClient.ValidationMessage;
                runInAction(() => {
                    this.isProcessing = false;
                });
                if (hideError === false) {
                    this.errorStore.setHeaderText("Condition Report PDF");
                    this.errorStore.setButtonText("Close");
                    this.errorStore.setErrorMessageOne("Failed to update new pdf data. Please try again");
                    if (this.apiClient.Is401UnAuthorized === false) {
                        this.errorStore.setErrorMessageTwo(errorMsg);
                    } else {
                        this.errorStore.setErrorMessageTwo("You have been logged out of the system.  Please backup the source, refresh and log in again.");
                    }
                    this.errorStore.setErrorModalOpen(true);
                }
            }
        }

        return retVal;
    };
}
